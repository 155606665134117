import React, { useState, useEffect, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { PageSettings } from './../../config/page-settings.js';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { performLogin, getShopCredit } from "../../redux/AuthAction.js";
import { ApiKey, ApiUrl, WebUrl, Role, Theme } from "../../util/Constant";
import { useTranslation } from 'react-i18next';
import { getValidationMessage } from "../../util/Util";

/// <summary>
/// Author : Andrew
/// </summary>
const Login = props => {
    const { t, i18n } = useTranslation();
    let _history = useHistory();
    const _context = useContext(PageSettings);
    const _dispatch = useDispatch();
    const { isLoggedIn } = useSelector(state => state.authState);

    const { register, handleSubmit, errors } = useForm();

    useEffect(() => {
        _context.handleSetPageSidebar(false);
        _context.handleSetPageHeader(false);
        _context.handleSetPageContentFullWidth(true);

        return (() => {
            _context.handleSetPageSidebar(true);
            _context.handleSetPageHeader(true);
            _context.handleSetPageContentFullWidth(false);
        });
    }, []);

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        if (isLoggedIn) {
            _history.push(WebUrl._URL_MAIN);
        }
    }, [isLoggedIn]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        await _dispatch(performLogin(data.username, data.password));
    }

    /// <summary>
    /// Author : Andrew
    /// </summary>
    return (
        <div className="login">
            <div className="container login-container">
                <div className="brand-header">
                    <img
                        src={process.env.REACT_APP_NAME == Theme._EPAY ?
                            require("../../assets/img/logo/epay.png")
                            : process.env.REACT_APP_NAME == Theme._OREN ?
                                require("../../assets/img/logo/oren.png")
                                : require("../../assets/img/logo/mantap.png")} />
                    <div className="title">{process.env.REACT_APP_NAME == Theme._EPAY ? "Epay Game Reload+" : process.env.REACT_APP_NAME == Theme._OREN ? "Oren Mart" : "Mantap"}</div>
                </div>
                <form className="margin-bottom-0" onSubmit={handleSubmit(submitForm)}>
                    <div className="form-group">
                        <input name="username"
                            ref={register({ required: "PLEASE_ENTER_USERNAME" })}
                            type="text"
                            autoFocus
                            className="form-control form-control-lg"
                            placeholder="Username" />
                        {errors.username && <div className="invalid-feedback">{t(getValidationMessage(errors.username))}</div>}
                    </div>
                    <div className="form-group">
                        <input name="password"
                            ref={register({ required: "PLEASE_ENTER_PASSWORD" })}
                            type="password"
                            className="form-control form-control-lg"
                            placeholder="Password" />
                        {errors.password && <div className="invalid-feedback">{t(getValidationMessage(errors.password))}</div>}
                    </div>
                    <button className="btn btn-brand btn-block">Login</button>
                </form>
            </div>
            <div className="container mt-4">
                <div className="logo-container">
                    <div className="logo-section">
                        <label>Powered by EPay</label>
                        <div className="logo-row">
                            <img src={require("../../assets/img/logo/epay-ghl.png")} />
                        </div>
                    </div>
                    <div className="logo-section">
                        <label>Mobile Reload</label>
                        <div className="logo-row">
                            <img src={require("../../assets/img/logo/hotlink.png")} />
                            <img src={require("../../assets/img/logo/digi.png")} />
                            <img src={require("../../assets/img/logo/xpax.png")} />
                            <img src={require("../../assets/img/logo/first.png")} />
                            <img src={require("../../assets/img/logo/tunetalk.png")} />
                            <img src={require("../../assets/img/logo/umobile.png")} />
                            <img src={require("../../assets/img/logo/yes.png")} />
                            <img src={require("../../assets/img/logo/xox.png")} />
                            <img src={require("../../assets/img/logo/tron.png")} />
                            <img src={require("../../assets/img/logo/merchatrade.png")} />
                            <img src={require("../../assets/img/logo/buzzme.png")} />
                            <img src={require("../../assets/img/logo/itel.png")} />
                            <img src={require("../../assets/img/logo/kartuloveas.png")} />
                            <img src={require("../../assets/img/logo/artel.png")} />
                            <img src={require("../../assets/img/logo/speakout.png")} />
                            <img src={require("../../assets/img/logo/friendi.png")} />
                            <img src={require("../../assets/img/logo/xiddig.png")} />
                            <img src={require("../../assets/img/logo/ysmobile.png")} />
                        </div>
                    </div>
                    <div className="logo-section">
                        <label>Payment Collection</label>
                        <div className="logo-row">
                            <img src={require("../../assets/img/logo/astro.png")} />
                            <img src={require("../../assets/img/logo/yes.png")} />
                            <img src={require("../../assets/img/logo/molpay.png")} />
                            <img src={require("../../assets/img/logo/tm.png")} />
                            <img src={require("../../assets/img/logo/streamyx.png")} />
                            <img src={require("../../assets/img/logo/unifi.png")} />
                            <img src={require("../../assets/img/logo/redone.png")} />
                        </div>
                    </div>
                    <div className="logo-section">
                        <label>Online Game Reload</label>
                        <div className="logo-row">
                            <img src={require("../../assets/img/logo/cubits.png")} />
                            <img src={require("../../assets/img/logo/multigamecard.png")} />
                            <img src={require("../../assets/img/logo/cherrycredits.png")} />
                            <img src={require("../../assets/img/logo/molpoints.png")} />
                            <img src={require("../../assets/img/logo/garena.png")} />
                            <img src={require("../../assets/img/logo/offgamers.png")} />
                            <img src={require("../../assets/img/logo/cibmall.png")} />
                            <img src={require("../../assets/img/logo/acash.png")} />
                            <img src={require("../../assets/img/logo/mycard.png")} />
                        </div>
                    </div>
                    <div className="logo-section">
                        <label>e-Wallet Reload</label>
                        <div className="logo-row">
                            <img src={require("../../assets/img/logo/toucn-n-go.png")} />
                            <img src={require("../../assets/img/logo/mcash.png")} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Login);