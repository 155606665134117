import React, { useMemo, useEffect, useState } from 'react';
import { Panel, PanelBody } from 'components/panel/panel.jsx';
import { useHistory, useLocation } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import ReactTable from 'components/custom/ReactTable';
import { FilterStatus, WebUrl, ApiKey, ApiUrl, Role, AccessRight, Status, AlertTypes } from 'util/Constant';
import { navigateTo, stringIsNullOrEmpty } from "../../../util/Util";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import ApiEngine from '../../../util/ApiEngine.js';
import { showMessage, showResponseMessage } from 'redux/AppAction';
import moment from 'moment';

/// <summary>
/// Author: Wind
/// </summary>
const ManageMemberDownline = props => {
    var _history = useHistory();
    var _location = useLocation();
    var _dispatch = useDispatch();
    const { t } = useTranslation();
    const { register, handleSubmit, watch, setValue } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    let _tableColumns = [
        {
            Header: "No",
            id: "row",
            filterable: false,
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            width: 30,
            disableSortBy: true
        },
        {
            Header: "JOINED_DATE",
            accessor: "createdTime",
            Cell: ({ row }) => {
                return <div>{moment(row.original.createdTime).format('DD/MM/YYYY hh:mm:ss A')}</div>;
            },
            disableSortBy: true
        },
        {
            Header: "USERNAME",
            Cell: ({ row }) => {
                return <div>{row.original.username}</div>;
            },
            disableSortBy: true
        },
        {
            Header: "ALIAS",
            accessor: "alias",
            disableSortBy: true
        }
    ]

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        (async () => {
            try {
                if (_location.state) {
                    handleSubmit(onSubmit)();
                }
                else {
                    throw 'NO_SELECTED_MEMBER';
                }
            }
            catch (err) {
                _dispatch(showMessage({
                    type: AlertTypes._ERROR,
                    content: t(err),
                    onConfirm: () => navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_MEMBER)
                }));
            }
        })();
    }, []);

    /// <summary>
    /// Author : Wind
    /// </summary>
    const onSubmit = async (data, e) => {
        var apiLinks = ApiUrl._API_GET_MEMBER_DOWNLINE + '?ViewerId=' + _location.state.id;

        if (!stringIsNullOrEmpty(data.username)) {
            apiLinks += "&SearchText=" + data.username;
        }
        
        setApiUrl(apiLinks);
    };

    /// <summary>
    /// Author : Wind
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable columns={_tableColumns} fetchUrl={apiUrl} />, [apiUrl])

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{_location.state && (t("MANAGE_MEMBER_DOWNLINE") + ' (' + _location.state.username + ')')}</h1>
            </div>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("USERNAME") + ' / ' + t("ALIAS")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="username"
                                        name="username"
                                        type="text"
                                        ref={register} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group form-button">
                                    <button type="submit" class="btn btn-primary">{t('APPLY_FILTER')}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    ); 
}

export default ManageMemberDownline;