import React, { useState, useEffect, useRef } from 'react';
import { Panel, PanelHeader, PanelBody, PanelFooter } from 'components/panel/panel.jsx';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { stringIsNullOrEmpty, createMultiPartFormBody, getValidationMessage, navigateTo, validatePhoneNumber } from "../../../util/Util";
import { ShopSettings, Status, Role, AlertTypes, ApiUrl, ApiKey, WebUrl } from 'util/Constant';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showMessage } from 'redux/AppAction';
import ApiEngine from '../../../util/ApiEngine.js';
import NavigationButton from '../../../components/custom/NavigationButton';
import Select from "react-select";
import { useLocation } from "react-router-dom";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import DualListBox from 'react-dual-listbox';
import { Util } from 'reactstrap';
import DualListBoxItemRow from 'components/custom/DualListBoxItemRow';

/// <summary>
/// Author: Chris
/// Edit : Wind - Added WhatsAppLink, DepartmentName, AlternatePhone, ResetPasswordAfterLogin
/// </summary>
const ShopDetail = props => {
    var _history = useHistory();
    var _dispatch = useDispatch();
    var _location = useLocation();
    const { register, handleSubmit, errors, watch, unregister, setValue, triggerValidation } = useForm();
    const [regionOptions, setRegionOptions] = useState([]);
    const { t } = useTranslation();
    const [phone, setPhone] = useState('');
    const [defaultRegionId, setDefaultRegionId] = useState('');
    const [regionId, setRegionId] = useState('');
    const [renderPhone, setRenderPhone] = useState(false);
    const _phoneFieldRef = useRef(null);

    const [managerOptions, setManagerOptions] = useState([]);
    const [selectedManager, setSelectedManager] = useState([]);
    const [defaultSelectedManager, setDefaultSelectedManager] = useState([]);
    const [defaultManagerOption, setDefaultManagerOption] = useState([]);

    const [selectedShop, setSelectedShop] = useState([]);
    const [shopOption, setShopOption] = useState([]);
    const [defaultSelectedShop, setDefaultSelectedShop] = useState([]);
    const [defaultShopOptions, setDefaultShopOptions] = useState([]);

    const [canCreateMember, setCanCreateMember] = useState(false);
    const [canCreatePlayer, setCanCreatePlayer] = useState(false);
    const [canReload, setCanReload] = useState(false);
    const [canDeposit, setCanDeposit] = useState(true);
    const [isRequiredSetLimitApproval, setIsRequiredSetLimitApproval] = useState(false);
    const [passwordResetAfterLogin, setPasswordResetAfterLogin] = useState(false);
    const [isCustomerService, setIsCustomerService] = useState(false);
    const [isMainShop, setIsMainShop] = useState(false);

    const [address, setAddress] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    const [username, setUsername] = useState('');
    const [fullName, setFullName] = useState('');
    const [creditLimit, setCreditLimit] = useState('');
    const [userId, setUserId] = useState('');
    const [isAgent, setIsAgent] = useState(false);
    const [eghlStatus, setEghlStatus] = useState(true);
    const [enrollKogEvent, setEnrollKogEvent] = useState(false);

    const [alternatePhone, setAlternatePhone] = useState('');
    const [departmentName, setDepartmentName] = useState('');
    const [whatsappLink, setWhatsappLink] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [agentReload, setAgentReload] = useState(false);
    const [onlineWithdrawalShop, setOnlineWithdrawalShop] = useState(false);
    const [canClaimReward, setCanClaimReward] = useState(true);
    const [canCreatePlayerWithoutMember, setCanCreatePlayerWithoutMember] = useState(true);
    const [canBlockMember, setCanBlockMember] = useState(false);
    const [canUnblockMember, setCanUnblockMember] = useState(false);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'regionId' }, { required: "PLEASE_SELECT_REGION" });
        register({ name: 'phone' });
        register({ name: 'alternatePhone' });
        setValue("phone", "");
        setValue("alternatePhone", "");
        init();
    }, []);

    /// <summary>
    /// Author : Andrew
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(regionId) && regionOptions.length != 0) {
            var filteredManagerOptions = [];
            let filteredShopOptions = [];

            regionOptions.map(region => {
                let shopsByRegion = defaultShopOptions.filter(shop => shop.regionId == region.value);
                let managersByRegion = defaultManagerOption.filter(manager => manager.regionId == region.value);

                if (shopsByRegion.length) {
                    let options = [];
                    shopsByRegion.map(shop => {
                        options.push({
                            label: shop.label,
                            value: shop.value,
                            regionId: shop.regionId
                        });
                    });

                    filteredShopOptions.push({
                        label: region.label,
                        value: region.value,
                        options: options
                    })
                }

                if (managersByRegion.length) {
                    let options = [];
                    managersByRegion.map(manager => {
                        options.push({
                            label: manager.label,
                            value: manager.value,
                            regionId: manager.regionId
                        });
                    });

                    filteredManagerOptions.push({
                        label: region.label,
                        value: region.value,
                        options: options
                    })
                }
            });

            setShopOption(filteredShopOptions);
            setManagerOptions(filteredManagerOptions);

            setSelectedManager(regionId == defaultRegionId ? defaultSelectedManager : []);
            setSelectedShop(regionId == defaultRegionId ? defaultSelectedShop : []);
        }
    }, [regionId, defaultRegionId, regionOptions]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS + "?roleId=" + Role._MANAGER);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var managerOptionList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (user) {
                managerOptionList.push({ label: user.username + " (" + user.userFullName + ")", value: user.id, regionId: user.regionId });
            });

            setDefaultManagerOption(managerOptionList);
        }

        responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS + "?roleId=" + Role._SHOP + "&IsCustomerService=false");

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var shopOptionList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (shop) {
                shopOptionList.push({ label: shop.username + " (" + shop.shopName + ")", value: shop.id, regionId: shop.regionId });
            });

            setDefaultShopOptions(shopOptionList);
        }


        if (_location.state) {
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_ACCOUNT + "?id=" + _location.state["id"]);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setUsername(responseJson[ApiKey._API_DATA_KEY]["username"]);
                setFullName(responseJson[ApiKey._API_DATA_KEY]["userFullName"]);
                if (!stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"])) {
                    setPhone(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]);
                    setValue("phone", responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]);
                }
                setUserId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                setRegionId(responseJson[ApiKey._API_DATA_KEY]["regionId"]);
                setDefaultRegionId(responseJson[ApiKey._API_DATA_KEY]["regionId"]);
                setValue('regionId', responseJson[ApiKey._API_DATA_KEY]["regionId"]);
                setCreditLimit(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["creditLimit"]);

                setCanCreateMember(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["createMemberEnable"]);
                setCanCreatePlayer(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["createPlayerEnable"]);
                setCanReload(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["reloadEnable"]);
                setCanDeposit(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["depositEnable"]);
                setIsRequiredSetLimitApproval(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["isRequiredSetLimitApproval"]);
                setPasswordResetAfterLogin(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["passwordResetAfterLogin"]);
                setIsAgent(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["isAgentShop"]);
                setAgentReload(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["agentReload"]);
                setOnlineWithdrawalShop(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["onlineWithdrawalShop"]);
                setCanClaimReward(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["canClaimReward"]);
                setCanCreatePlayerWithoutMember(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["createPlayerWithoutMemberEnable"]);
                setCanBlockMember(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["canBlockMember"]);
                setCanUnblockMember(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["canUnblockMember"]);

                setAddress(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["address"]);
                setCity(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["city"]);
                setState(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["state"]);
                setPostalCode(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["postalCode"]);
                setLatitude(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["latitude"]);
                setLongitude(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["longitude"]);
                setIsCustomerService(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["isCustomerService"]);
                setIsMainShop(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["isMainShop"]);
                setEghlStatus(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["eghlStatus"]);
                setDepartmentName(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["departmentName"]);
                setWhatsappLink(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["whatsAppLink"]);
                if (!stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["alternatePhone"])) {
                    setAlternatePhone(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["alternatePhone"]);
                    setValue("alternatePhone", responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["alternatePhone"]);
                }

                if (!stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["referralCode"])) {
                    setReferralCode(responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["referralCode"]);
                }

                let shopManager = [];
                let managers = responseJson[ApiKey._API_DATA_KEY]["associatedManagers"];
                if (managers != null) {
                    managers.forEach(function (manager) {
                        shopManager.push(manager.managerUserId);
                    });
                    setSelectedManager(shopManager);
                    setDefaultSelectedManager(shopManager);
                }

                if (responseJson[ApiKey._API_DATA_KEY]["shopProfile"]["isCustomerService"]) {
                    let ownedShop = [];
                    let shops = responseJson[ApiKey._API_DATA_KEY]["ownedShops"];
                    if (shops != null) {
                        shops.forEach(function (shop) {
                            ownedShop.push(shop.shopUserId);
                        });
                        setSelectedShop(ownedShop);
                        setDefaultSelectedShop(ownedShop);
                    }
                }

                unregister('regionId');
            }
        }

        setRenderPhone(true);

        var responseJson = await ApiEngine.get(ApiUrl._API_GET_REGION);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let regionList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (region) {
                regionList.push({ label: region.name, value: region.id });
            });
            setRegionOptions(regionList);
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const onChangeAlernatePhoneNumber = (isValid, newNumber, countryData, fullNumber) => {
        setAlternatePhone(newNumber.toString().replace(/\s/g, '').replace(/\-/g, ''));
        let formattedNumber = fullNumber.toString().split(/[- ]+/).join('').replace("+", "");
        register({ name: "alternatePhone" }, {
            validate: (value) => (validatePhoneNumber(fullNumber, countryData["iso2"]) && validatePhoneNumber(newNumber, countryData["iso2"])) || stringIsNullOrEmpty(newNumber) || "REQUIRED_VALID_PHONE_NUMBER"
        });
        setValue("alternatePhone", stringIsNullOrEmpty(newNumber) ? '' : formattedNumber);
        triggerValidation("alternatePhone")
    }


    /// <summary>
    /// Author: Chris
    /// CK - Redirect back once completed submission
    /// </summary>
    const onSubmit = async (data) => {
        let params = {
            username: data.username,
            userFullName: data.userFullName,
            roleId: Role._SHOP,
            phoneNumber: data.phone,
            regionId: regionId,
            createPlayerEnable: canCreatePlayer,
            createMemberEnable: canCreateMember,
            reloadEnable: canReload,
            depositEnable: canDeposit,
            isRequiredSetLimitApproval: isRequiredSetLimitApproval,
            passwordResetAfterLogin: passwordResetAfterLogin,
            isAgentShop: data.isAgent,
            shopName: data.userFullName,
            address: data.address,
            postalCode: data.postalCode,
            state: data.state,
            shopManagerList: selectedManager.join(","),
            latitude: data.latitude,
            longitude: data.longitude,
            city: data.city,
            alternatePhone: data.alternatePhone,
            departmentName: departmentName,
            whatsAppLink: whatsappLink,
            referralCode: referralCode,
            agentReload: agentReload,
            onlineWithdrawalShop: onlineWithdrawalShop,
            canClaimReward: canClaimReward,
            createPlayerWithoutMemberEnable: canCreatePlayerWithoutMember,
            canBlockMember: canBlockMember,
            canUnblockMember: canUnblockMember
        };
        if (props.isCustomerService) {
            params["shopServiceList"] = selectedShop.join(",");
            params["isCustomerService"] = props.isCustomerService;
        }
        else {
            params["isMainShop"] = isMainShop;
            params["eghlStatus"] = data.eghlStatus;
        }

        if (!stringIsNullOrEmpty(userId)) {
            params["id"] = userId;
        }
        else {
            params["status"] = Status._ENABLED;
            params["password"] = data.password;
            params["creditLimit"] = data.creditLimit;
            params["enrollCurrentKogEvent"] = enrollKogEvent;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_USER_ACCOUNT, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, props.isCustomerService ? WebUrl._URL_ADMIN_MANAGE_CUSTOMER_SERVICE : WebUrl._URL_ADMIN_MANAGE_SHOP),
        }));

    };

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const onChangePhoneNumber = (isValid, newNumber, countryData, fullNumber) => {
        setPhone(newNumber.toString().replace(/\s/g, '').replace(/\-/g, ''));
        let formattedNumber = fullNumber.toString().split(/[- ]+/).join('').replace("+", "");
        register({ name: "phone" }, {
            validate: (value) => (validatePhoneNumber(fullNumber, countryData["iso2"]) && validatePhoneNumber(newNumber, countryData["iso2"])) || stringIsNullOrEmpty(newNumber) || "REQUIRED_VALID_PHONE_NUMBER"
        });
        setValue("phone", stringIsNullOrEmpty(newNumber) ? '' : formattedNumber);
        triggerValidation("phone")
    }

    return (
        <div>
            <h1 className="page-header">{_location.state ? (props.isCustomerService ? t("EDIT_CUSTOMER_SERVICE") : t("EDIT_SHOP")) :
                (props.isCustomerService ? t("CREATE_CUSTOMER_SERVICE") : t("CREATE_SHOP"))}<NavigationButton history={_history} /></h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Panel>
                    <PanelHeader noButton>{t("SHOP_DETAIL")}</PanelHeader>
                    <PanelBody>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("NAME")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="userFullName"
                                        name="userFullName"
                                        type="text"
                                        defaultValue={fullName}
                                        placeholder="Enter Name"
                                        ref={register({ required: true })} />
                                    {errors.userFullName && <div className="invalid-feedback">{t(getValidationMessage(errors.userFullName))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("USERNAME")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="username"
                                        name="username"
                                        defaultValue={username}
                                        type="text"
                                        placeholder="Enter Username"
                                        ref={register({ required: true })} />
                                    {errors.username && <div className="invalid-feedback">{t(getValidationMessage(errors.username))}</div>}
                                </div>
                            </div>
                            {!_location.state &&
                                <>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>{t("PASSWORD")}</label>
                                            <input
                                                className="form-control form-control-lg"
                                                id="password"
                                                name="password"
                                                placeholder="Enter Password"
                                                type="password"
                                                ref={register({ required: true })} />
                                            {errors.password && <div className="invalid-feedback">{t(getValidationMessage(errors.password))}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>{t("CONFIRM_PASSWORD")}</label>
                                            <input
                                                className="form-control form-control-lg"
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                placeholder="Enter Confirm Password"
                                                type="password"
                                                ref={register({
                                                    required: true,
                                                    validate: (value) => {
                                                        return value === watch('password') || 'PASSWORD_DO_NOT_MATCH'
                                                    }
                                                })} />
                                            {errors.confirmPassword && <div className="invalid-feedback">{t(getValidationMessage(errors.confirmPassword))}</div>}
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("PHONE")}</label>
                                    {renderPhone && <IntlTelInput
                                        fieldName={"phone"}
                                        preferredCountries={['my']}
                                        style={{ display: "block", width: "100%" }}
                                        containerClassName="intl-tel-input"
                                        inputClassName="form-control"
                                        ref={_phoneFieldRef}
                                        onSelectFlag={(newNumber, countryData, fullNumber, ext) => {
                                            onChangePhoneNumber(_phoneFieldRef.current.isValidNumber(phone), newNumber, countryData, fullNumber);
                                        }}
                                        onPhoneNumberChange={(isValid, newNumber, countryData, fullNumber, ext) => {
                                            onChangePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                        }}
                                        onPhoneNumberBlur={(isValid, newNumber, countryData, fullNumber, ext) => {
                                            onChangePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                        }}
                                        customPlaceholder={(placeholder, countryData) => {
                                            return placeholder.split(/[- ]+/).join('');
                                        }}
                                        defaultValue={phone}
                                        value={phone}
                                        useMobileFullscreenDropdown={false}
                                        separateDialCode
                                    />}
                                    {errors.phone && <div className="invalid-feedback">{t(getValidationMessage(errors.phone))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("REGION")}</label>
                                    <Select name="regionId" options={regionOptions}
                                        placeholder={(regionOptions.filter(option => option.value == regionId)[0] !== undefined) ? (regionOptions.filter(option => option.value == regionId)[0].label) : ""}
                                        value={regionOptions.filter(option => option.value == regionId)}
                                        onChange={(e) => {
                                            setValue("regionId", e.value);
                                            setRegionId(e.value);
                                        }} />
                                    {errors.regionId && <div className="invalid-feedback">{t(getValidationMessage(errors.regionId))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("TYPE")}</label>
                                    <select className="form-control form-control-lg" id="isAgent" name="isAgent"
                                        onChange={(e) => setIsAgent(e.target.value)}
                                        ref={register} value={isAgent}>
                                        <option value={false}>{t("SHOP")}</option>
                                        <option value={true}>{t("AGENT")}</option>
                                    </select>
                                </div>
                            </div>
                            {!_location.state &&
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>{t("CREDIT_LIMIT")}</label>
                                        <input
                                            className="form-control form-control-lg"
                                            id="creditLimit"
                                            name="creditLimit"
                                            placeholder={t("ENTER_CREDIT_LIMIT")}
                                            step={0.01}
                                            defaultValue={creditLimit}
                                            type="number"
                                            ref={register({ required: true })}
                                        />
                                        {errors.creditLimit && <div className="invalid-feedback">{t(getValidationMessage(errors.creditLimit))}</div>}
                                    </div>
                                </div>
                            }
                            {props.isCustomerService &&
                                <>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("ALTERNATE_PHONE")}</b></label>
                                            {renderPhone && <IntlTelInput
                                                fieldName={"alternatePhone"}
                                                preferredCountries={['my']}
                                                style={{ display: "block", width: "100%" }}
                                                containerClassName="intl-tel-input"
                                                inputClassName="form-control form-control-lg"
                                                ref={_phoneFieldRef}
                                                onSelectFlag={(newNumber, countryData, fullNumber, ext) => {
                                                    onChangeAlernatePhoneNumber(_phoneFieldRef.current.isValidNumber(phone), newNumber, countryData, fullNumber);
                                                }}
                                                onPhoneNumberChange={(isValid, newNumber, countryData, fullNumber, ext) => {
                                                    onChangeAlernatePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                                }}
                                                onPhoneNumberBlur={(isValid, newNumber, countryData, fullNumber, ext) => {
                                                    onChangeAlernatePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                                }}
                                                customPlaceholder={(placeholder, countryData) => {
                                                    return placeholder.split(/[- ]+/).join('');
                                                }}
                                                defaultValue={alternatePhone}
                                                value={alternatePhone}
                                                useMobileFullscreenDropdown={false}
                                                separateDialCode
                                            />}
                                            {(errors.alternatePhone) && <div className="invalid-feedback">{t(errors.alternatePhone.message)}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("DEPARTMENT_NAME")}</b></label>
                                            <input type="text" name="departmentName"
                                                defaultValue={departmentName}
                                                onChange={(e) => { setDepartmentName(e.target.value) }}
                                                className="form-control form-control-lg m-b-5" placeholder={t("ENTER_DEPARTMENT_NAME")} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("WHATSAPP_LINK")}</b></label>
                                            <input type="text" name="whatsappLink"
                                                defaultValue={whatsappLink}
                                                onChange={(e) => { setWhatsappLink(e.target.value) }}
                                                className="form-control form-control-lg m-b-5" placeholder={t("ENTER_WHATSAPP_LINK")} />
                                        </div>
                                    </div>
                                </>
                            }
                            {!props.isCustomerService &&
                                <>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("REFERRAL_CODE")}</b></label>
                                            <input type="text" name="referralCode"
                                                defaultValue={referralCode}
                                                onChange={(e) => { setReferralCode(e.target.value) }}
                                                className="form-control form-control-lg m-b-5" placeholder={t("ENTER_REFERRAL_CODE")} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("EGHL_STATUS")}</b></label><br />
                                            <div className="switcher">
                                                <input type="checkbox" name="eghlStatus" id="eghlStatus"
                                                    onChange={(e) => setEghlStatus(e.target.checked)}
                                                    value={true}
                                                    checked={eghlStatus}
                                                    ref={register} />
                                                <label htmlFor="eghlStatus"></label>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !_location.state &&
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <label><b>{t("ENROLL_KOG_EVENT")}</b></label><br />
                                                <div className="switcher">
                                                    <input type="checkbox" name="enrollKogEvent" id="enrollKogEvent"
                                                        onChange={(e) => setEnrollKogEvent(e.target.checked)}
                                                        value={enrollKogEvent}
                                                        ref={register} />
                                                    <label htmlFor="enrollKogEvent"></label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </PanelBody>
                    <PanelHeader noButton>{t("SHOP_ADDRESS")}</PanelHeader>
                    <PanelBody>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("ADDRESS")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="address"
                                        name="address"
                                        type="text"
                                        defaultValue={address}
                                        ref={register}
                                    />
                                    {errors.address && <div className="invalid-feedback">{t(getValidationMessage(errors.address))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("CITY")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="city"
                                        name="city"
                                        type="text"
                                        defaultValue={city}
                                        ref={register}
                                    />
                                    {errors.city && <div className="invalid-feedback">{t(getValidationMessage(errors.city))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("STATE")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="state"
                                        name="state"
                                        type="text"
                                        defaultValue={state}
                                        ref={register}
                                    />
                                    {errors.state && <div className="invalid-feedback">{t(getValidationMessage(errors.state))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("POSTAL_CODE")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="postalCode"
                                        name="postalCode"
                                        type="number"
                                        defaultValue={postalCode}
                                        ref={register}
                                    />
                                    {errors.postalCode && <div className="invalid-feedback">{t(getValidationMessage(errors.postalCode))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("LATITUDE")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="latitude"
                                        name="latitude"
                                        step={0.000001}
                                        type="number"
                                        defaultValue={latitude}
                                        ref={register}
                                    />
                                    {errors.latitude && <div className="invalid-feedback">{t(getValidationMessage(errors.latitude))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("LONGITUDE")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="longitude"
                                        step={0.000001}
                                        name="longitude"
                                        type="text"
                                        defaultValue={longitude}
                                        ref={register}
                                    />
                                    {errors.longitude && <div className="invalid-feedback">{t(getValidationMessage(errors.longitude))}</div>}
                                </div>
                            </div>
                            {!props.isCustomerService &&
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label><b>{t("IS_MAIN_SHOP")}</b></label><br />
                                        <div className="switcher">
                                            <input type="checkbox" name="isMainShop" id="isMainShop"
                                                onChange={(e) => setIsMainShop(e.target.checked)}
                                                value={true}
                                                checked={isMainShop}
                                                ref={register} />
                                            <label htmlFor="isMainShop"></label>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </PanelBody>
                    <PanelHeader noButton>{t("ASSIGN_MANAGERS")}</PanelHeader>
                    <PanelBody>
                        <div className="form-group">
                            <DualListBoxItemRow options={managerOptions} selected={selectedManager} />
                            <DualListBox
                                canFilter
                                selected={selectedManager}
                                options={managerOptions}
                                onChange={(e) => { setSelectedManager(e) }}
                            />
                        </div>
                    </PanelBody>
                    {props.isCustomerService && <>
                        <PanelHeader noButton>{t('ASSIGN_SHOPS')}</PanelHeader>
                        <PanelBody>
                            <div className="form-group">
                                <label>{t("SHOPS")}</label>
                                <DualListBoxItemRow options={shopOption} selected={selectedShop} />
                                <DualListBox
                                    canFilter
                                    selected={selectedShop}
                                    options={shopOption}
                                    onChange={(e) => {
                                        setSelectedShop(e);
                                    }}
                                />
                            </div>
                        </PanelBody>
                    </>
                    }
                    <PanelHeader noButton>{t("SHOP_SETTING")}</PanelHeader>
                    <PanelBody>
                        <div className="checkbox checkbox-css checkbox-inline">
                            <input id="memberCheckbox" type="checkbox" name="shopSettings" checked={canCreateMember}
                                onChange={(e) => setCanCreateMember(e.target.checked)}
                                ref={register} />
                            <label htmlFor="memberCheckbox">{t("CAN_CREATE_MEMBER")}</label>
                        </div>
                        <div className="checkbox checkbox-css checkbox-inline">
                            <input id="playerCheckbox" type="checkbox" name="shopSettings" checked={canCreatePlayer}
                                onChange={(e) => setCanCreatePlayer(e.target.checked)}
                                ref={register} />
                            <label htmlFor="playerCheckbox">{t("CAN_CREATE_PLAYER")}</label>
                        </div>
                        <div className="checkbox checkbox-css checkbox-inline">
                            <input id="reloadCheckbox" type="checkbox" name="shopSettings" checked={canReload}
                                onChange={(e) => setCanReload(e.target.checked)}
                                ref={register} />
                            <label htmlFor="reloadCheckbox">{t("CAN_RELOAD")}</label>
                        </div>
                        <div className="checkbox checkbox-css checkbox-inline">
                            <input id="depositCheckbox" type="checkbox" name="shopSettings" checked={canDeposit}
                                onChange={(e) => setCanDeposit(e.target.checked)}
                                ref={register} />
                            <label htmlFor="depositCheckbox">{t("CAN_DEPOSIT")}</label>
                        </div>
                        <div className="checkbox checkbox-css checkbox-inline">
                            <input id="setLimitApprovalCheckbox" type="checkbox" name="shopSettings" checked={isRequiredSetLimitApproval}
                                onChange={(e) => setIsRequiredSetLimitApproval(e.target.checked)}
                                ref={register} />
                            <label htmlFor="setLimitApprovalCheckbox">{t("SET_LIMIT_APPROVAL")}</label>
                        </div>
                        <div className="checkbox checkbox-css checkbox-inline">
                            <input id="resetPasswordCheckbox" type="checkbox" name="shopSettings" checked={passwordResetAfterLogin}
                                onChange={(e) => setPasswordResetAfterLogin(e.target.checked)}
                                ref={register} />
                            <label htmlFor="resetPasswordCheckbox">{t("RESET_PASSWORD_AFTER_LOGIN")}</label>
                        </div>
                        <div className="checkbox checkbox-css checkbox-inline">
                            <input id="agentReloadCheckbox" type="checkbox" name="shopSettings" checked={agentReload}
                                onChange={(e) => setAgentReload(e.target.checked)}
                                ref={register} />
                            <label htmlFor="agentReloadCheckbox">{t("CAN_RELOAD_AGENT")}</label>
                        </div>
                        <div className="checkbox checkbox-css checkbox-inline">
                            <input id="onlineWithdrawalCheckbox" type="checkbox" name="shopSettings" checked={onlineWithdrawalShop}
                                onChange={(e) => setOnlineWithdrawalShop(e.target.checked)}
                                ref={register} />
                            <label htmlFor="onlineWithdrawalCheckbox">{t("ONLINE_WITHDRAWAL")}</label>
                        </div>
                        <div className="checkbox checkbox-css checkbox-inline">
                            <input id="rewardCheckbox" type="checkbox" name="shopSettings" checked={canClaimReward}
                                onChange={(e) => setCanClaimReward(e.target.checked)}
                                ref={register} />
                            <label htmlFor="rewardCheckbox">{t("CAN_CLAIM_REWARD")}</label>
                        </div>
                        {
                            !props.isCustomerService &&
                            <>
                                <div className="checkbox checkbox-css checkbox-inline">
                                    <input id="createPlayerWithoutMemberCheckbox" type="checkbox" name="shopSettings" checked={canCreatePlayerWithoutMember}
                                        onChange={(e) => setCanCreatePlayerWithoutMember(e.target.checked)}
                                        ref={register} />
                                    <label htmlFor="createPlayerWithoutMemberCheckbox">{t("CAN_CREATE_PLAYER_WITHOUT_MEMBER")}</label>
                                </div>
                                <div className="checkbox checkbox-css checkbox-inline">
                                    <input id="blockMemberCheckbox" type="checkbox" name="shopSettings" checked={canBlockMember}
                                        onChange={(e) => setCanBlockMember(e.target.checked)}
                                        ref={register} />
                                    <label htmlFor="blockMemberCheckbox">{t("CAN_BLOCK_MEMBER")}</label>
                                </div>
                                <div className="checkbox checkbox-css checkbox-inline">
                                    <input id="unblockMemberCheckbox" type="checkbox" name="shopSettings" checked={canUnblockMember}
                                        onChange={(e) => setCanUnblockMember(e.target.checked)}
                                        ref={register} />
                                    <label htmlFor="unblockMemberCheckbox">{t("CAN_UNBLOCK_MEMBER")}</label>
                                </div>
                            </>
                        }
                        <div className="row m-t-20">
                            <div className="col-lg-2">
                                <button type="submit" className="btn btn-primary">{t("BTN_SAVE")}</button>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
            </form>
        </div>
    )
}

export default ShopDetail;