import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { ApiKey, ApiUrl, AccessRight, AlertTypes, Status } from '../../../util/Constant';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { stringIsNullOrEmpty, createFormBody } from "../../../util/Util";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useDispatch } from "react-redux";
import { showMessage } from "../../../redux/AppAction";
import ApiEngine from '../../../util/ApiEngine.js';
import { useForm } from "react-hook-form";
import VipMemberModal from '../../../components/custom/VipMemberModal';

/// <summary>
/// Author: Wind
/// </summary> 
const ManageVipMember = props => {
    const { t } = useTranslation();
    const { setValue, errors, handleSubmit, register, watch } = useForm();
    var _location = useLocation();
    let { search } = useLocation();
    var _dispatch = useDispatch();
    const _query = new URLSearchParams(search);
    const _ID = _query.get('id');
    const _NAME = _query.get('name');
    const _REGION_NAME = _query.get('region');
    const [status, setStatus] = useState(null);
    const [vipList, setVipList] = useState([]);
    const [event, setEvent] = useState({});

    /// <summary>
    /// Author: Wind
    /// </summary>
    let _vipMemberColumns = [
        {
            Header: "RANK",
            accessor: "rank",
            disableSortBy: true
        },
        {
            Header: "USERNAME",
            accessor: "username",
            disableSortBy: true
        },
        {
            Header: "STATUS",
            accessor: "status",
            disableSortBy: true,
            Cell: ({ row }) => {
                let returnClassName = "badge badge-success";
                let statusName = "ACTIVE";

                if (row.original.status == Status._DISABLED) {
                    returnClassName = "badge badge-danger";
                    statusName = "DEACTIVATE";
                }

                return <span className={returnClassName} style={{ textTransform: "uppercase" }}>{t(statusName)}</span>
            },
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._EVENT_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => { 
                                setEvent({
                                    id: _ID,
                                    name: _NAME,
                                    isEdit: true,
                                    botId: row.original.id,
                                    username: row.original.username,
                                    userId: row.original.userId,
                                    isUserBotActive: row.original.status,
                                    rank: row.original.rank
                                });
                            }}>
                                <span>{t('EDIT')}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => { updateBotStatus(row.original); }}>
                                <span className={row.original.status == Status._DISABLED ? "text-success" : "text-danger"}>
                                    {row.original.status == Status._DISABLED ? t('ACTIVE') : t('DEACTIVATE')}
                                </span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ];

    /// <summary>
    /// Author: Wind
    /// </summary> 
    useEffect(() => {
        applyFilter();
    }, []);

    /// <summary>
    /// Author : Wind
    /// </summary> 
    async function applyFilter() {
        try {
            if (!stringIsNullOrEmpty(_ID)) {
                var responseJson = await ApiEngine.get(`${ApiUrl._API_KOG_BOT_MEMBER}?eventId=${_ID}`);
                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }

                setVipList(responseJson[ApiKey._API_DATA_KEY]);
            }
        }
        catch (err) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(err)
            }));
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary> 
    async function updateBotStatus(row) {
        try {
            let params = {
                id: row.id,
                status: row.status == Status._DISABLED ? Status._ENABLED : Status._DISABLED
            }

            var responseJson = await ApiEngine.post(ApiUrl._API_KOG_BOT_MEMBER, createFormBody(params));
            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            applyFilter();
        }
        catch (err) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(err)
            }));
        }
    }

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">
                    {_NAME + ' ' + t('VIP_MEMBER') + ' (' + _REGION_NAME + ')'}
                </h1>
                <button type="button" className="btn btn-primary" onClick={() => {
                    setEvent({
                        id: _ID,
                        name: _NAME
                    });
                }}>{t('ADD_VIP')}</button>
            </div>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <ReactTable data={vipList} columns={_vipMemberColumns} />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <VipMemberModal
                selectedEvent={event}
                isEdit={event.isEdit ?? false}
                onFinish={(requiredRefresh = false) => {
                    setEvent({});

                    if (requiredRefresh) {
                        applyFilter()
                    }
                }} />
        </div>
    )
}

export default ManageVipMember;