import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from '../../components/panel/panel';
import DateTime from 'react-datetime';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, Role } from "../../util/Constant";
import { stringIsNullOrEmpty, arrayGroupByKey } from "../../util/Util";
import { setBusy, setIdle, showResponseMessage } from '../../redux/AppAction';
import ReactTable from '../../components/custom/ReactTable';
import ApiEngine from '../../util/ApiEngine.js';
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
const ShopAgentCommissionReport = props => {
    const { t } = useTranslation();
    let _dispatch = useDispatch();
    const [apiUrl, setApiUrl] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const { register, handleSubmit, setValue } = useForm();
    const [postData, setPostData] = useState({});
    const [commissionDetailData, setCommissionDetailData] = useState([]);
    const [showSummary, setShowSummary] = useState(false);

    const _TYPE_OPTION = {
        PENDING: 0,
        COMPLETE: 1,
    };

    let _tableColumns = [
        {
            Header: "DATE",
            accessor: "completedDate",
            disableSortBy: true
        },
        {
            Header: "OPERATED_SHOP",
            accessor: "shopName",
            disableSortBy: true
        },
        {
            Header: "PHONE_NUMBER",
            accessor: "phoneNumber",
            disableSortBy: true
        },
        {
            Header: "PLAYER_ID",
            accessor: "playerId",
            disableSortBy: true
        },
        {
            Header: "VENDOR",
            accessor: "vendor",
            disableSortBy: true
        },
        {
            Header: "DEPOSIT_AMOUNT",
            accessor: "rolloverAmount",
            disableSortBy: true,
            Footer: ({ page }) => {
                const totalRolloverAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.rolloverAmount);
                }, 0);

                return <span><b>{totalRolloverAmount.toFixed(2)}</b></span>
            }
        },
        {
            Header: "PROFIT",
            accessor: "profit",
            Cell: ({ row }) => {
                return row.original.profit > 0 ? <span className="text-success">{row.original.profit}</span> : <span className="text-danger">{row.original.profit}</span>;
            },
            disableSortBy: true,
            Footer: ({ page }) => {
                const totalProfit = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.profit);
                }, 0);

                return <span><b>{totalProfit.toFixed(2)}</b></span>
            },
        },
        {
            id: "action",
            Header: "ACTION",
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => { viewCommissionDetail(row.original.id) }}><span className="text-warning">{t("VIEW_DETAIL")}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ];

    let _summaryTableColumns = [
        {
            Header: t("DATE"),
            accessor: "withdrawalDate"
        },
        {
            Header: t("SHOP"),
            accessor: "shopName"
        },
        {
            Header: t("WITHDRAWAL_AMOUNT"),
            accessor: "amount"
        },
        {
            Header: t("PERCENTAGE"),
            accessor: "percentage"
        },
        {
            Header: t("PROFIT"),
            accessor: "actualAmount"
        }
    ]

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "Type": parseInt(data.type),
        };

        if (!stringIsNullOrEmpty(data.playerId)) {
            params["PlayerId"] = data.playerId;
        }

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_AGENT_COMMISSION_REPORT);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function viewCommissionDetail(id) {
        var responseJson = await ApiEngine.post(ApiUrl._API_GET_AGENT_COMMISSION_DETAIL_REPORT + "?id=" + id);
        setShowSummary(true);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setCommissionDetailData(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: true });

        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    }, []);

    return (
        <div id="page-content">
            <h1 className="page-header">{t('COMMISSION_REPORT')}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row" style={{ alignItems: 'flex-end' }}>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t('PLAYER_ID')}</label>
                                    <input className="form-control form-control-lg" ref={register} id="playerId" name="playerId" type="text" value="" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("TYPE")}</label>
                                    <select name="type" ref={register} className="form-control form-control-lg">
                                        <option value={_TYPE_OPTION.COMPLETE}>{t("COMPLETE")}</option>
                                        <option value={_TYPE_OPTION.PENDING}>{t("PENDING")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t('START_DATE')}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t('END_DATE')}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <button id="btnFilter" type="submit" className="btn btn-primary btn-block">{t('FILTER')}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    <ReactTable className="col-nowrap" columns={_tableColumns} fetchUrl={apiUrl} postData={postData} renderFooter={true} />
                </PanelBody>
            </Panel>
            <Modal isOpen={showSummary} toggle={() => setShowSummary(false)} style={{ paddingBottom: "100px" }} className="modal-lg" keyboard={true} >
                <ModalHeader toggle={() => setShowSummary(false)}>{t("SUMMARY")}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <ReactTable columns={_summaryTableColumns} data={commissionDetailData} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn btn-inverse-brand" onClick={() => setShowSummary(false)}>{t("BTN_CLOSE")}</button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ShopAgentCommissionReport;