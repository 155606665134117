import React, { useRef, useEffect, useState } from 'react';
import { Panel, PanelBody } from 'components/panel/panel.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { showResponseMessage, showMessage } from 'redux/AppAction.js';
import { AlertTypes, ApiKey, FilterStatus, Status, TransactionTypeId, CustomResponseCode, TransactionRequestStatus, FirebaseKey, TransactionType } from 'util/Constant.js';
import { ApiUrl, WebUrl } from '../../util/Constant';
import ApiEngine from '../../util/ApiEngine.js';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import ReloadDetailModal from '../../components/custom/ReloadDetailModal';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, ignoreDecimal, isObjectEmpty, printReceipt } from "../../util/Util";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useLocation, withRouter, useHistory } from "react-router-dom";
import PasswordConfirmationModal from "../../components/custom/PasswordConfirmationModal";
import AccountStatusModal from "../../components/custom/AccountStatusModal";
import AppealRequestModal from "../../components/custom/AppealRequestModal";
import MemberRolloverDetailModal from "../../components/custom/MemberRolloverDetailModal";
import { initFirebaseTransactionRequestDatabase } from '../../redux/FirebaseAction';
import { getShopCredit } from "../../redux/AuthAction.js";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import classnames from 'classnames';
import ScoreLogModal from '../../components/custom/ScoreLogModal';
import GameLogModal from '../../components/custom/GameLogModal';

/// <summary>
/// Author: Chris
/// Yong Sheng Chuan - redesign flow
/// CK - Set score verification
/// </summary>
const Reload = props => {
    const _PENDING_USER_VERIFICATION_ID = 0;
    var _dispatch = useDispatch();
    var _location = useLocation();
    var _history = useHistory();
    const { t } = useTranslation();
    const { register, handleSubmit, errors, reset, setValue, getValues } = useForm({ shouldUnregister: false });
    const [gameList, setGameList] = useState([]);
    var _userData = useSelector(state => state.authState.userData);
    var [selectedUsername, setSelectedUsername] = useState('');
    var [selectedVendor, setSelectedVendor] = useState('');
    var [selectedStatus, setSelectedStatus] = useState(false);
    var [userDetail, setUserDetail] = useState();
    const [modalVisibility, setModalVisibility] = useState(false);
    const [claimRewardModal, setClaimRewardModal] = useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState(false);
    const [setScoreParams, setSetScoreParams] = useState(null);
    const [setScoreUrl, setSetScoreUrl] = useState("");
    const [rewardAmount, setRewardAmount] = useState(0);
    const [actionType, setActionType] = useState(null)
    var [selectedRemark, setSelectedRemark] = useState("");
    const [accountDetail, setAccountDetail] = useState({});
    const _MIN_REWARD_CLAIM_AMOUNT = 5;
    const [transactionDetail, setTransactionDetail] = useState({});
    const [tempScoreDetail, setTempScoreDetail] = useState({});
    const [requestTransactionDetail, setRequestTransactionDetail] = useState({});
    var { requestCode } = useSelector(state => state.firebaseState);
    const [rolloverDetailModal, setRolloverDetailModal] = useState(false);
    const [memberNotes, setMemberNotes] = useState({});
    const [openNotesModal, setOpenNotesModal] = useState(false);
    const [notes, setNotes] = useState('');
    const [deactivateAccountDetail, setDeactivateAccountDetail] = useState({});
    const [scoreLogModalVisibility, setScoreLogModalVisibility] = useState(false);
    const [gameLogModalVisibility, setGameLogModalVisibility] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState('');

    const _BTN_TYPE = {
        ADD: "ADD",
        RELOAD: "RELOAD",
        BLOCK: "BLOCK",
        DELETE: "DELETE",
        RESET: "RESET"
    }

    const _ACTIOM_TYPE = {
        SET_SCORE: "SET_SCORE",
        CLAIM_REWARD: "CLAIM_REWARD",
        RELOAD_CREDIT: "RELOAD_CREDIT"
    }

    const _GAME_TYPE = [
        { id: '1', type: 'APP' },
        { id: '2', type: 'H5' }
    ];
    const [currentActiveTab, setCurrentActiveTab] = useState(_GAME_TYPE[0]['id']);

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (!_location.state) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t("INVALID_ACCESS"),
                onConfirm: () => navigateTo(_history, WebUrl._URL_SHOP_SEARCH),
            }));
        }
        else {
            _dispatch(getShopCredit());
            initMemberDetail();
            init();
            getMemberNotes();
        }
    }, [_location.state]);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(requestCode) && requestTransactionDetail["code"] == requestCode) {
            getTransactionRequest(requestCode);
        }
    }, [requestCode])

    /// <summary>
    /// Author: CK
    /// When listening code completed from user app
    /// </summary>
    async function getTransactionRequest(code) {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_TRANSACTION_REQUEST + "?code=" + code);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let responseData = responseJson[ApiKey._API_DATA_KEY];

            if (passwordConfirmation && responseData != null &&
                (responseData['status'] == TransactionRequestStatus._REJECTED || responseData['status'] == TransactionRequestStatus._SUCCESS)) {
                _dispatch(getShopCredit());
                initMemberDetail();
                showSetScoreMessage(responseData, responseData['amount'], true);
                passwordConfirmationCleanup();
            }
        }
        else {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(responseJson[ApiKey._API_MESSAGE_KEY])
            }));

            passwordConfirmationCleanup();
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function initMemberDetail() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_ACCOUNT + "?id=" + _location.state.id);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setUserDetail(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function init() {
        let apiUrl = ApiUrl._API_RETRIEVE_RELOAD_DETAILS + "?username=" + _location.state.username;

        if (_userData.isCustomerService) {
            apiUrl += "&memberId=" + _location.state.id;
        }

        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (responseJson[ApiKey._API_DATA_KEY].length == 0) {
                _dispatch(showMessage({
                    type: AlertTypes._ERROR,
                    content: t("NO_VENDOR"),
                    onConfirm: () => _history.goBack(),
                }));
                return;
            }

            let games = [];
            responseJson[ApiKey._API_DATA_KEY].map((game, index) => {
                let products = [{ value: game.vendor.code, label: game.vendor.name, displayName: game.vendor.shortName }];
                let mobiles = [];

                game.players.map((player, playerIndex) => {
                    mobiles.push({ value: player.username, label: player.displayUsername, status: player.status, remark: player.remark });
                })
                games.push({ index: index + 1, productOptions: products, mobileOptions: mobiles, isApp: !stringIsNullOrEmpty(game?.vendor?.appDownloadUrlAndroid) || !stringIsNullOrEmpty(game?.vendor?.appDownloadUrlIos) });
                setValue("amount[" + index + "]", "");
            })
            reset();
            setGameList(games);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getMemberNotes() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_NOTES + "?memberId=" + _location.state.id);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setMemberNotes(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    const btnHandler = (type) => {
        let options = { type: AlertTypes._WARNING, title: t('ARE_YOU_SURE'), showCancel: true };
        let useCustomAlert = false;

        switch (type) {
            case _BTN_TYPE.ADD:
                options = {
                    ...options,
                    content: t('PLEASE_CONFIRM_PLAYER_ACTION', { action: t('CREATE') }),
                    showCancel: true,
                    onConfirm: () => createPlayer()
                };
                break;
            case _BTN_TYPE.BLOCK: {
                setAccountDetail({
                    fromStatus: selectedStatus ? Status._ENABLED : Status._DISABLED,
                    toStatus: !selectedStatus ? Status._ENABLED : Status._DISABLED,
                    remark: selectedRemark,
                    btnType: _BTN_TYPE.BLOCK
                });
                useCustomAlert = true;
                break;
            }
            case _BTN_TYPE.DELETE: {
                setAccountDetail({
                    fromStatus: selectedStatus ? Status._ENABLED : Status._DISABLED,
                    toStatus: Status._DELETED,
                    remark: selectedRemark,
                    btnType: _BTN_TYPE.DELETE
                });
                useCustomAlert = true;
                break;
            }
            case _BTN_TYPE.RESET:
                options = {
                    ...options,
                    content: t('PLEASE_CONFIRM_PLAYER_ACTION', { action: t(type) }),
                    showCancel: true,
                    onConfirm: () => playerOperation(type)
                }
                break;
        }

        if (!useCustomAlert) {
            _dispatch(showMessage(options));
        }
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function createPlayer() {
        var apiUrl = ApiUrl._API_SHOP_CREATE_PLAYER + "?code=" + selectedVendor + "&MemberId=" + _location.state.id;

        var responseJson = await ApiEngine.post(apiUrl);
        let data = responseJson[ApiKey._API_DATA_KEY];

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY]),
            `Username: ${data.member}|Game: ${data.gameName}|ID: ${data.playerUsername}|Pw: ${data.password}|Download URL (iOS): ${data.downloadUrlIos}|Download URL (Android): ${data.downloadUrlAndroid}`));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            init();
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function performMemberTransaction(amount) {
        var responseJson = await ApiEngine.post(ApiUrl._API_RELOAD_CREDIT + "?amount=" + amount + "&MemberId=" + _location.state.id);
        let responseData = responseJson[ApiKey._API_DATA_KEY];

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (responseData["id"] != _PENDING_USER_VERIFICATION_ID) {
                _dispatch(getShopCredit());
                initMemberDetail();
                showSetScoreMessage(responseData, responseData["score"], true);
            }
            else {
                // Require user verification
                _dispatch(initFirebaseTransactionRequestDatabase(FirebaseKey._TRANSACTION_REQUEST + userDetail['id'], responseData['code']));
                setActionType(_ACTIOM_TYPE.RELOAD_CREDIT);
                setRequestTransactionDetail({ "code": responseData['code'], "expiredAt": responseData['expiredTime'] });
                setPasswordConfirmation(true);
            }
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function claimReward(amount) {
        var responseJson = await ApiEngine.post(ApiUrl._API_CLAIM_REWARD + "?amount=" + amount + "&MemberId=" + _location.state.id);
        let responseData = responseJson[ApiKey._API_DATA_KEY];

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (responseData["id"] != _PENDING_USER_VERIFICATION_ID) {
                _dispatch(getShopCredit());
                initMemberDetail();
                showSetScoreMessage(responseData, responseData["score"], true);
            }
            else {
                // Require user verification
                _dispatch(initFirebaseTransactionRequestDatabase(FirebaseKey._TRANSACTION_REQUEST + userDetail['id'], responseData['code']));
                setActionType(_ACTIOM_TYPE.CLAIM_REWARD);
                setRequestTransactionDetail({ "code": responseData['code'], "expiredAt": responseData['expiredTime'] });
                setPasswordConfirmation(true);
            }
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function playerOperation(type, remark = "") {
        let apiUrl = "";

        if (type == _BTN_TYPE.RESET) {
            apiUrl = ApiUrl._API_SHOP_RESET_PASSWORD + "?code=" + selectedVendor + "&playerUsername=" + selectedUsername;
        }
        else if (type == _BTN_TYPE.BLOCK) {
            apiUrl = ApiUrl._API_SHOP_SET_PLAYER_STATUS + "?code=" + selectedVendor + "&playerUsername=" + selectedUsername + "&status=" + (selectedStatus ? "false" : "true") + "&remark=" + remark;
        }
        else if (type == _BTN_TYPE.DELETE) {
            apiUrl = ApiUrl._API_SHOP_DELETE_PLAYER + "?code=" + selectedVendor + "&playerUsername=" + selectedUsername + "&remark=" + remark;
        }
        else {
            return;
        }

        var responseJson = await ApiEngine.post(apiUrl);
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            init();
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    function initSelectedData(key) {
        selectedVendor = (getValues()["product[" + key + "]"]);
        selectedUsername = (getValues()["player[" + key + "]"]);
        selectedStatus = getValues()["status[" + key + "]"].toString() == FilterStatus._ACTIVE.toString();
        selectedRemark = (getValues()["remark[" + key + "]"]);

        setSelectedUsername((getValues()["player[" + key + "]"]));
        setSelectedVendor((getValues()["product[" + key + "]"]));
        setSelectedStatus(getValues()["status[" + key + "]"].toString() == FilterStatus._ACTIVE.toString());
        setSelectedRemark((getValues()["remark[" + key + "]"]));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(setScoreUrl)) {
            setScoreConfirmation();
        }
    }, [setScoreUrl]);

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// Edited: CK - Set score confirmation 
    /// </summary>
    const submitForm = async (data, e) => {
        var validInputCount = data.amount.filter(i => !stringIsNullOrEmpty(i)).length;
        if (validInputCount > 1) {
            _dispatch(showResponseMessage(false, t("ONLY_ONE_RELOAD_ALLOWED")));
            return;
        }
        else if (validInputCount == 0) {
            _dispatch(showResponseMessage(false, t("SELECT_ONE_RELOAD_TO_PROCEED")));
            return;
        }

        var indexToUse = -1;
        data.amount.map((value, key) => {
            if (!stringIsNullOrEmpty(value) && stringIsNullOrEmpty(data.player[key] && stringIsNullOrEmpty(data.product[key])) && indexToUse < 0) {
                indexToUse = key;
            }
        });

        var scoreAmountSelected = data.amount[indexToUse];
        setTempScoreDetail({
            "code": data.product[indexToUse],
            "username": data.player[indexToUse],
            "amount": scoreAmountSelected
        });
        setSetScoreUrl(ApiUrl._API_SHOP_SET_SCORE + "?code=" + data.product[indexToUse] + "&playerUsername=" + data.player[indexToUse] + "&score=" + scoreAmountSelected + "&v=" + + Date.now());
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function setScoreHandler(setScoreUrl, setScoreParams) {
        setActionType(_ACTIOM_TYPE.SET_SCORE);

        if (setScoreParams) {
            var responseJson = await ApiEngine.post(setScoreUrl, createMultiPartFormBody(setScoreParams));
        }
        else {
            var responseJson = await ApiEngine.post(setScoreUrl);
        }

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            init();
        }

        let responseData = responseJson[ApiKey._API_DATA_KEY];

        if (responseJson[ApiKey._API_SUCCESS_KEY] && !setScoreParams) {
            if (responseData["id"] != _PENDING_USER_VERIFICATION_ID) {
                _dispatch(getShopCredit());
                showSetScoreMessage(responseData, responseData["score"], true);
            }
            else {
                // Require user verification
                _dispatch(initFirebaseTransactionRequestDatabase(FirebaseKey._TRANSACTION_REQUEST + userDetail['id'], responseData['code']));
                setRequestTransactionDetail({ "code": responseData['code'], "expiredAt": responseData['expiredTime'] });
                setPasswordConfirmation(true);
            }
        }
        else if (responseJson[ApiKey._API_SUCCESS_KEY] && setScoreUrl == ApiUrl._API_SHOP_BULK_SET_SCORE) {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t("BULK_RELOAD_PLAYER_STATUS",
                { successCount: responseData["successCount"], failCount: responseData["failCount"] })));
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function settleTransactionRequst(password) {
        try {
            var responseJson = await ApiEngine.post(ApiUrl._API_SETTLE_TRANSACTION_REQUEST + "?code=" + requestTransactionDetail['code'] + "&status=" + TransactionRequestStatus._SUCCESS + "&password=" + password);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                initMemberDetail();
                let responseData = responseJson[ApiKey._API_DATA_KEY];
                _dispatch(getShopCredit());
                showSetScoreMessage(responseData, responseData["amount"], true);
                passwordConfirmationCleanup();
            }
            // Reject to withdraw when rollover not reached
            else if (!stringIsNullOrEmpty(responseJson[ApiKey._API_CODE_KEY]) && responseJson[ApiKey._API_CODE_KEY] == CustomResponseCode._ROLLOVER_APPEAL_REQUIRED) {
                setTransactionDetail({
                    "memberId": _location.state.id,
                    "type": TransactionTypeId._SET_SCORE,
                    "code": tempScoreDetail["code"],
                    "username": tempScoreDetail["username"],
                    "amount": tempScoreDetail["amount"]
                })
            }
            else {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
        }
        catch (err) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: err
            }));
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    function setScoreConfirmation() {
        _dispatch(showMessage({
            type: AlertTypes._WARNING,
            title: t('ARE_YOU_SURE'),
            showCancel: true,
            content: t('PLEASE_CONFIRM_SET_SCORE'),
            onConfirm: () => {
                setScoreHandler(setScoreUrl, setScoreParams);
            },
            onCancel: () => passwordConfirmationCleanup()
        }));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    function passwordConfirmationCleanup() {
        setSetScoreParams(null);
        setSetScoreUrl("");
        setRewardAmount(0);
        setActionType(null);
        setPasswordConfirmation(false);
        setRequestTransactionDetail({});
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    function showSetScoreMessage(responseData, amount, showPrintButton = false) {
        let status = (responseData['status'] == undefined && responseData['id'] != _PENDING_USER_VERIFICATION_ID) || responseData['status'] == TransactionRequestStatus._SUCCESS;

        _dispatch(showMessage({
            type: status ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            title: t(status ? "SUCCESS" : "FAILED"),
            content: status ? <div>{t(actionType == _ACTIOM_TYPE.RELOAD_CREDIT ? "SET_WALLET_CREDIT_SUCCESS" :
                actionType == _ACTIOM_TYPE.CLAIM_REWARD ? "REWARD_CLAIMED" : "SET_SCORE_SUCCESS")}: <b className={amount < 0 ? "text-red" : "text-green"}>{Number(amount).toFixed(1)}</b></div>
                : responseData['remark'],
            html: status,
            confirmBtnText: t("PRINT"),
            confirmBtnBsStyle: "success",
            showCancel: showPrintButton,
            cancelBtnText: "OK",
            cancelBtnBsStyle: "primary",
            reverseButtons: !showPrintButton,
            onConfirm: () => {
                if (showPrintButton) {
                    printReceipt(`Username: ${actionType == _ACTIOM_TYPE.RELOAD_CREDIT || actionType == _ACTIOM_TYPE.CLAIM_REWARD ? _location.state.username : tempScoreDetail.username}|${actionType != _ACTIOM_TYPE.RELOAD_CREDIT && actionType != _ACTIOM_TYPE.CLAIM_REWARD ? 'Game: ' + (responseData['gameName'] ?? responseData['playerVendorGameName']) : ''}|${actionType == _ACTIOM_TYPE.RELOAD_CREDIT ? "Set Wallet" : actionType == _ACTIOM_TYPE.CLAIM_REWARD ? "Set Reward" : "Set Score"}: ${amount}`)
                }
            }
        }));
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    const toggleTab = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function submitMemberNotes() {
        try {
            let data = {
                id: memberNotes?.id,
                memberUserId: _location.state.id,
                remark: notes
            }

            var responseJson = await ApiEngine.post(ApiUrl._API_SAVE_MEMBER_NOTES, data);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setNotes('');
            setOpenNotesModal(!openNotesModal);
            getMemberNotes();
        }
        catch (err) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: err
            }));
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function userStatusHandler(userId, status, remark = "") {
        let params = {
            memberUserId: userId,
            status: status,
            remark: remark
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_SHOP_UPDATE_USER_STATUS, params);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            initMemberDetail();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    return <>
        <h1 class="page-header row">
            {t('RELOAD')} {userDetail?.status == Status._DISABLED && <span className="text-danger">{`(${t('BLOCK')})`}</span>} {_location.state.username} ({t('WALLET_CREDIT').toUpperCase()}: {userDetail && userDetail.memberProfile.totalCredit.toFixed(2)})
            {
                !stringIsNullOrEmpty(memberNotes?.remark) &&
                <p>&nbsp;{`[${memberNotes.remark}]`}</p>
            }
        </h1>
        <Panel className="reload-panel">
            <PanelBody>
                {claimRewardModal &&
                    <SweetAlert
                        type="input"
                        title={t('ENTER_AMOUNT')}
                        inputType="number"
                        step=".01"
                        validationRegex={/^[+-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$/}
                        validationMsg={t("ENTER_ONLY_NUMBER")}
                        showCancel={true}
                        required={true}
                        confirmBtnCssClass={"btn btn-primary"}
                        cancelBtnCssClass={"btn btn-danger"}
                        reverseButtons={true}
                        onConfirm={(e) => {
                            setClaimRewardModal(false);
                            setRewardAmount(e);

                            if (_ACTIOM_TYPE.CLAIM_REWARD == actionType) {
                                claimReward(e);
                            }
                            else {
                                performMemberTransaction(e);
                            }
                        }}
                        onCancel={() => { setClaimRewardModal(false); }}>
                        <p>{t('WALLET_CREDIT')} : {_ACTIOM_TYPE.CLAIM_REWARD == actionType ? userDetail.memberProfile.totalRewardPoint.toFixed(2) : userDetail.memberProfile.totalCredit.toFixed(2)}</p>
                    </SweetAlert>
                }
                {passwordConfirmation &&
                    <PasswordConfirmationModal
                        expiredAt={!isObjectEmpty(requestTransactionDetail) && requestTransactionDetail['expiredAt']}
                        memberId={_location.state.id}
                        skipPasswordCheck={true}
                        onConfirm={(password) => {
                            settleTransactionRequst(password);
                        }}
                        onCancel={() => { passwordConfirmationCleanup(); }} />
                }
                <Nav tabs style={{ backgroundColor: '#DCDCDC', width: '45%' }}>
                    {
                        _GAME_TYPE.map(item => {
                            return (
                                <NavItem>
                                    <NavLink
                                        style={{
                                            backgroundColor: currentActiveTab === item.id ? 'white' : 'transparent',
                                            borderRadius: 0,
                                            borderColor: currentActiveTab === item.id ? '#A9A9A9' : 'transparent',
                                            borderWidth: currentActiveTab === item.id ? 1 : 0
                                        }}
                                        className={classnames({
                                            active: currentActiveTab === item.id
                                        })}
                                        onClick={() => {
                                            if (currentActiveTab !== item.id) {
                                                setCurrentActiveTab(item.id)
                                            }
                                        }}
                                    >
                                        {t(item.type)}
                                    </NavLink>
                                </NavItem>
                            )
                        })
                    }
                </Nav>
                <TabContent>
                    <div class="reload-row">
                        <div class="index"><b>#</b></div>
                        <div class="product"><b>{t('PRODUCT')}</b></div>
                        <div class="amount"><b>{t('AMOUNT')}</b></div>
                        <div class="mobile"><b>{t('RELOAD_MOBILE')}</b></div>
                    </div>
                    {
                        gameList.filter(g => currentActiveTab === _GAME_TYPE[0]['id'] ? g.isApp : !g.isApp).map((game, key) => {
                            var defaultStatus = FilterStatus._ACTIVE;
                            var defaultRemark = "";
                            if (game.mobileOptions.length > 0) {
                                if (stringIsNullOrEmpty((getValues()["player[" + key + "]"])) || game.mobileOptions.filter(i => i.value == (getValues()["player[" + key + "]"])).length == 0) {
                                    defaultStatus = game.mobileOptions[0].status;
                                    defaultRemark = game.mobileOptions[0].remark;
                                }
                                else {
                                    defaultStatus = game.mobileOptions.filter(i => i.value == (getValues()["player[" + key + "]"]))[0].status;
                                    defaultRemark = game.mobileOptions.filter(i => i.value == (getValues()["player[" + key + "]"]))[0].remark;
                                }
                            }

                            return (
                                <div class="reload-row">
                                    <div class="index">{key}</div>
                                    <div class="product">
                                        <select class="form-control form-control-lg" name={"product[" + key + "]"} ref={register}>
                                            {
                                                game.productOptions.map((option, key) => {
                                                    return <option key={key} value={option.value}>{option.label + " (" + option.displayName + ")"}</option>
                                                })
                                            }
                                        </select>
                                        <input type="hidden" id={"status[" + key + "]"} name={"status[" + key + "]"} ref={register}
                                            defaultValue={defaultStatus} />
                                    </div>
                                    <div class="amount">
                                        <input type="number"
                                            onKeyDown={ignoreDecimal}
                                            name={"amount[" + key + "]"} ref={register} class="form-control form-control-lg" placeholder="0" />
                                    </div>
                                    <div class="mobile">
                                        <input type="hidden" id={"remark[" + key + "]"} name={"remark[" + key + "]"} ref={register}
                                            defaultValue={defaultRemark} />
                                        <select class="form-control form-control-lg" name={"player[" + key + "]"} ref={register} onChange={(e) => {
                                            setValue("status[" + key + "]", game.mobileOptions.filter(i => i.value == e.target.value)[0].status)
                                            setValue("remark[" + key + "]", game.mobileOptions.filter(i => i.value == e.target.value)[0].remark)
                                        }}>
                                            {
                                                game.mobileOptions.map((option, key) => {
                                                    return <option key={key} value={option.value}>{option.label}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div class="options">
                                        <button type="button" class="btn btn-lg btn-primary" onClick={() => {
                                            initSelectedData(key);
                                            btnHandler(_BTN_TYPE.ADD);
                                        }}><i class="fas fa-plus"></i></button>
                                        <button type="button" class="btn btn-lg btn-primary" onClick={() => {
                                            initSelectedData(key);
                                            setModalVisibility(true);
                                        }}><i class="fas fa-eye"></i></button>
                                        <button type="button" class="btn btn-lg btn-primary" onClick={() => {
                                            initSelectedData(key);
                                            btnHandler(_BTN_TYPE.DELETE)
                                        }}><i class="fas fa-trash"></i></button>
                                        <button type="button" class="btn btn-lg btn-primary" onClick={() => {
                                            initSelectedData(key);
                                            btnHandler(_BTN_TYPE.BLOCK)
                                        }}><i class="fas fa-ban"></i></button>
                                        <button type="button" class="btn btn-lg btn-primary" onClick={() => {
                                            initSelectedData(key);
                                            btnHandler(_BTN_TYPE.RESET)
                                        }}><i class="fas fa-key"></i></button>
                                        {
                                            _userData?.isCustomerService ?
                                                <>
                                                    <button title={t('SCORE_LOG')} type="button" class="btn btn-lg btn-primary" onClick={() => {
                                                        setSelectedPlayer({ 
                                                            username: getValues()["player[" + key + "]"],
                                                            vendorCode: getValues()["product[" + key + "]"]
                                                        });
                                                        setScoreLogModalVisibility(true);
                                                    }}><i class="fas fa-money-bill"></i></button>
                                                    <button title={t('GAME_LOG')} type="button" class="btn btn-lg btn-primary" onClick={() => {
                                                        setSelectedPlayer({ 
                                                            username: getValues()["player[" + key + "]"],
                                                            vendorCode: getValues()["product[" + key + "]"],
                                                            shopId: _userData?.userId
                                                        });
                                                        setGameLogModalVisibility(true);
                                                    }}><i class="fas fa-gamepad"></i></button>
                                                </>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </TabContent>
                <ScoreLogModal isModalVisible={scoreLogModalVisibility} player={selectedPlayer} onFinish={() => setScoreLogModalVisibility(false)} />
                <GameLogModal isModalVisible={gameLogModalVisibility} player={selectedPlayer} onFinish={() => setGameLogModalVisibility(false)} />
                <AccountStatusModal
                    visible={!isObjectEmpty(accountDetail)}
                    detail={accountDetail}
                    onFinish={(isConfirm, remark) => {
                        if (isConfirm) {
                            playerOperation(accountDetail.btnType, remark)
                        }
                        setAccountDetail({});
                    }} />
                <AppealRequestModal
                    visible={!isObjectEmpty(transactionDetail)}
                    detail={transactionDetail}
                    onFinish={(isConfirm) => {
                        setTransactionDetail({});
                    }} />
                <ReloadDetailModal
                    status={selectedStatus}
                    vendorCode={selectedVendor}
                    username={selectedUsername}
                    remark={selectedRemark}
                    onFinish={(refreshRequired, setScoreUrl = null, score = null, details = {}, rolloverAction = false) => {
                        if (refreshRequired) {
                            init();
                        }

                        setModalVisibility(false);

                        if (setScoreUrl && !stringIsNullOrEmpty(score)) {
                            setSetScoreUrl(setScoreUrl);
                            setTempScoreDetail(details);
                        }
                    }}
                    visible={modalVisibility} />
                <div class="btn-row">
                    <button type="button" onClick={() => handleSubmit(submitForm)()} class="btn btn-primary btn-reload">{t('RELOAD')}</button>
                    {userDetail && userDetail.memberProfile.totalRewardPoint >= _MIN_REWARD_CLAIM_AMOUNT && <button type="button" class="btn btn-primary btn-reload btn-claim"
                        onClick={() => {
                            setActionType(_ACTIOM_TYPE.CLAIM_REWARD);
                            setClaimRewardModal(true)
                        }}>{t('CLAIM_REWARD')}</button>}
                    <button type="button" class="btn btn-primary btn-reload"
                        onClick={() => {
                            setActionType(_ACTIOM_TYPE.RELOAD_CREDIT);
                            setClaimRewardModal(true)
                        }}>{t('WALLET_CREDIT')}
                    </button>
                    {
                        (_userData?.isCustomerService || _userData?.username.toUpperCase().includes("CM")) &&
                        <button type="button" class="btn btn-primary btn-reload"
                            onClick={() => {
                                setRolloverDetailModal(true);
                            }}>{t('CHECK_ROLLOVER')}
                        </button>
                    }
                    <button type="button" onClick={() => {
                        setOpenNotesModal(!openNotesModal);
                        setNotes(memberNotes?.remark);
                    }} class="btn btn-primary btn-reload">{t('NOTES')}</button>
                    <button type="button" onClick={() => {
                        setDeactivateAccountDetail({
                            ...userDetail,
                            fromStatus: userDetail?.status,
                            toStatus: userDetail?.status == Status._ENABLED ? Status._DISABLED : Status._ENABLED
                        })
                    }} class={`btn btn-${userDetail?.status == Status._ENABLED ? 'danger' : 'success'} btn-reload`}>{t(userDetail?.status == Status._ENABLED ? 'BLOCK_MEMBER' : 'UNBLOCK_MEMBER')}</button>
                </div>
                <AccountStatusModal
                    visible={!isObjectEmpty(deactivateAccountDetail)}
                    detail={deactivateAccountDetail}
                    onFinish={(isConfirm, remark) => {
                        if (isConfirm) {
                            userStatusHandler(deactivateAccountDetail.id, deactivateAccountDetail.toStatus, remark)
                        }
                        setDeactivateAccountDetail({});
                    }} />
                <MemberRolloverDetailModal
                    username={_location.state.username}
                    isVisible={rolloverDetailModal}
                    onFinish={() => {
                        setRolloverDetailModal(false);
                    }}
                />
                <Modal isOpen={openNotesModal}>
                    <ModalHeader>
                        <h4>{t('MEMBER_NOTES')}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-lg-12">
                                <textarea class="form-control" rows="5"
                                    value={notes}
                                    onInput={(e) => setNotes(e.target.value)}>
                                </textarea>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-info" onClick={() => setNotes(memberNotes?.remark ?? '')}>{t('BTN_RESET')}</button>
                        <button type="submit" className="btn btn-primary" onClick={() => submitMemberNotes()}>{t('BTN_SAVE')}</button>
                        <button type="button" className="btn btn-danger"
                            onClick={() => {
                                setOpenNotesModal(!openNotesModal);
                                setNotes('');
                            }}>{t('BTN_CANCEL')}</button>
                    </ModalFooter>
                </Modal>
            </PanelBody>
        </Panel>
    </>;
}

export default withRouter(Reload);