import React, { useState, useMemo, useEffect } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import DateTime from 'react-datetime';
import { ApiKey, ApiUrl, Role } from '../../../util/Constant';
import { getValidationMessage, arrayGroupByKey, stringIsNullOrEmpty } from '../../../util/Util';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import moment from 'moment';
import ApiEngine from '../../../util/ApiEngine.js';
import DualListBox from 'react-dual-listbox';

/// <summary>
/// Author: Wind
/// </summary> 
const ShopFeedbackReport = props => {
    const { t } = useTranslation();
    const { setValue, errors } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [index, setIndex] = useState('');
    const [postData, setPostData] = useState({});

    /// <summary>
    /// Author: Wind
    /// </summary>
    let _feedbackColumns = [
        {
            Header: "No",
            accessor: "index",
            filterable: false,
            disableSortBy: true,
            width: 20
        },
        {
            Header: "SHOP",
            disableSortBy: true,
            Cell: ({ row }) => {
                let rowDetail = row.original;

                return <div>
                    <b>{t("SUBMISSION_DATE_TIME")}: </b>{moment(rowDetail.createdTime).format("YYYY-MM-DD HH:mm:ss")}<br/>
                    <b>{t("SHOP")}: </b>{rowDetail.reporterName}<br/>
                    <b>{t("OCCUR_TIME")}: </b>{moment(rowDetail.occurTime).format("YYYY-MM-DD HH:mm:ss")}<br/>
                    <b>{t("LOCATION")}: </b>{rowDetail.location}
                </div>
            }
        },
        {
            Header: "FEEDBACK",
            accessor: "additional",
            disableSortBy: true,
            style: {
                wordBreak: 'break-word'
            },
            minWidth: 300
        }
    ];

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        (async () => {
            applyFilter();
        })();
    }, []);

    /// <summary>
    /// Author : Wind
    /// </summary> 
    async function applyFilter() {
        var params = {
            "StartDate": startDate,
            "EndDate": endDate,
            "v": moment.now()
        }

        if (!stringIsNullOrEmpty(index)) {
            params["Index"] = parseInt(index);
        }

        setPostData(params);
        setApiUrl(ApiUrl._API_SHOP_FEEDBACK);
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable
        columns={_feedbackColumns}
        fetchUrl={apiUrl}
        postData={postData}
        exportRequired={true} />, [apiUrl, postData])

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("SHOP_FEEDBACK")}</h1>
            </div>
            <Panel>
                <PanelBody>
                    <div className="row m-b-10">
                        <div className="col-lg-2">
                            <div className="form-group">
                                <label>{t("START_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="startDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={startDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("startDate", "");
                                        }
                                    }} />
                                {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="form-group">
                                <label>{t("END_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="endDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={endDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                            setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("endDate", "");
                                        }
                                    }} />
                                {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("JUMP_TO_NO")}</label>
                                <input
                                    type="number"
                                    name="index"
                                    className="form-control form-control-lg"
                                    onInput={(e) => setIndex(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <button type="button" className="btn btn-primary" onClick={() => { applyFilter() }}>{t('APPLY_FILTER')}</button>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            {PaginationTable}
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div>
    )
}

export default ShopFeedbackReport;