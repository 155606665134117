/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
import axios from 'axios';
import { ApiKey } from './Constant.js';
import * as AppActions from '../redux/AppAction';
import * as AuthActions from '../redux/AuthAction';
import Store from '../ApplicationStore';

const _HTTP_CODE_UNAUTHORIZED = 401;
const _HTTP_CODE_FORBIDDEN = 403;

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
async function interceptRequest(requestConfig) {
    if (requestConfig.headers.skipLoading === undefined ||
        requestConfig.headers.skipLoading === false) {
        Store.dispatch(AppActions.setBusy());
    }
    return requestConfig;
}

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
function interceptResponse(response) {
    Store.dispatch(AppActions.setPerformApiCall(response.data[ApiKey._API_SUCCESS_KEY], response.config.url));
    Store.dispatch(AppActions.setIdle());
    return response.data;
}

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
function interceptError(error) {
    var returnData = {};

    Store.dispatch(AppActions.setIdle());

    if (error.response?.status == _HTTP_CODE_UNAUTHORIZED || error.response?.status == _HTTP_CODE_FORBIDDEN) {
        Store.dispatch(AuthActions.performLogout());
        Store.dispatch(AppActions.showResponseMessage(false, error.response.status == _HTTP_CODE_UNAUTHORIZED
            ? "DUPLICATE_LOGIN_DETECTED" : "IP_FORBIDDEN"));
        returnData = {
            "success": false,
            "message": error.response.status == _HTTP_CODE_UNAUTHORIZED ? "DUPLICATE_LOGIN_DETECTED" : "IP_FORBIDDEN"
        }
    }

    return returnData;
}

const ApiEngine = axios.create({
    headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
    },
});

ApiEngine.interceptors.request.use(
    config => interceptRequest(config),
    error => interceptError(error),
);

ApiEngine.interceptors.response.use(
    response => interceptResponse(response),
    error => interceptError(error),
);

export default ApiEngine;