import React, { useState, useMemo, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { createMultiPartFormBody, stringIsNullOrEmpty } from '../../util/Util';
import { ApiUrl, ApiKey, Role, AlertTypes } from '../../util/Constant';
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import { showMessage, showResponseMessage } from "redux/AppAction";
import { useForm } from "react-hook-form";

/// <summary>
/// Author: CK
/// </summary>
const SetAgentCreditModal = ({ visible, agentDetails, onFinish, isCreditLimit = false }) => {
    var _userData = useSelector(state => state.authState.userData);
    const _dispatch = useDispatch();
    const { t } = useTranslation();
    const { register, handleSubmit, setValue, errors } = useForm();
    const [currentCredit, setCurrentCredit] = useState();
    const _FIXED_LIMIT_OPTIONS = [
        { label: "1,000", value: 1000 },
        { label: "2,000", value: 2000 },
        { label: "3,000", value: 3000 },
        { label: "5,000", value: 5000 }
    ];

    /// <summary>
    /// Author: CK
    /// get the latest credit
    /// </summary>
    useEffect(() => {
        if (visible) {
            refreshCreditLimit();
        }
    }, [visible])

    /// <summary>
    /// Author: CK
    /// </summary>
    const refreshCreditLimit = async () => {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_ACCOUNT + "?id=" + agentDetails.id);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let agentProfile = responseJson[ApiKey._API_DATA_KEY].agentProfile;
   
            setValue('credit', isCreditLimit ? agentProfile.creditLimit : agentProfile.credit);
            setValue('currentCredit', isCreditLimit ? agentProfile.creditLimit : agentProfile.credit);
            setCurrentCredit(isCreditLimit ? agentProfile.creditLimit : agentProfile.credit);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const onSubmit = async (data) => {
        let apiUrl = ApiUrl._API_UPDATE_AGENT_CREDIT;

        if (isCreditLimit) {
            apiUrl = ApiUrl._API_UPDATE_AGENT_CREDIT_LIMIT;
        }

        apiUrl += "?amount=" + data.credit + "&agentId=" + agentDetails.id;

        if (data.billNumber) {
            apiUrl += "&remark=" + data.billNumber;
        }

        var responseJson = await ApiEngine.post(apiUrl);

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        onFinish(true);
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const renderFixedLimitBtns = useMemo(() => {
        return _FIXED_LIMIT_OPTIONS.map((option, index) => {
            let parsedNumber = parseFloat(option.value);
            let parsedCurrLimit = parseFloat(currentCredit);
            var valueToSet = 0;

            if (parsedCurrLimit > parsedNumber) {
                valueToSet = (parsedCurrLimit - parsedNumber) * -1;
            }
            else {
                valueToSet = parsedNumber - parsedCurrLimit;
            }

            return (
                <button type="button" className="btn btn-warning btn-action m-r-10 m-b-10" onClick={() => {
                    setValue('credit', valueToSet);
                }}>{option.label}</button>
            );
        })
    }, [currentCredit, agentDetails]);

    return (
        <Modal isOpen={visible} toggle={onFinish}>
            <ModalHeader>{isCreditLimit ? t("SET_AGENT_CREDIT_LIMIT_FOR") : t("SET_AGENT_CREDIT_FOR")} {agentDetails.userFullName}</ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group m-b-15 ">
                        <label>{isCreditLimit ? t("CURRENT_CREDIT_LIMIT") : t("CURRENT_CREDIT")}</label>
                        <div className="input-group">
                            <input
                                disabled
                                className="form-control form-control-lg m-b-5"
                                type="number"
                                name="currentCredit"
                                defaultValue={currentCredit}
                                ref={register} />
                            <div className="input-group-append">
                                <button type="button" className="btn btn-default no-caret" onClick={() => refreshCreditLimit()}><i className="fas fa-sync-alt"></i>{t("REFRESH")}</button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group m-b-15 ">
                        <label>{isCreditLimit ? t("CREDIT_LIMIT") : t("CREDIT")}</label>
                        <input
                            placeholder={t("ENTER_CREDIT_LIMIT")}
                            className="form-control form-control-lg m-b-5"
                            type="number"
                            name="credit"
                            step={0.01}
                            ref={register({ required: 'PLEASE_ENTER_CREDIT_LIMIT' })} />
                        {errors.credit && <div className="invalid-feedback">{t(errors.credit.message)}</div>}
                    </div>
                    {
                        !isCreditLimit &&
                        <div className="form-group m-b-15 ">
                            <label>{t("BILL_NUMBER")}</label>
                            <input
                                placeholder={t("ENTER_BILL_NUMBER")}
                                className="form-control form-control-lg m-b-5"
                                type="text"
                                name="billNumber"
                                ref={register} />
                            {errors.billNumber && <div className="invalid-feedback">{t(errors.billNumber.message)}</div>}
                        </div>
                    }
                    <div className="pull-right">
                        {!isCreditLimit && renderFixedLimitBtns}
                        <button type="button" className="btn btn-default btn-action m-r-10 m-b-10" onClick={() => onFinish()}>{t('CLOSE')}</button>
                        <button type="submit" className="btn btn-primary btn-action m-b-10">{t('SUBMIT')}</button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};

export default SetAgentCreditModal;