import React from 'react';

/// <summary>
/// Author : YJ
/// </summary>
const DualListBoxItemRow = props => {
    const { options, selected } = props;
    
    /// <summary>
    /// Author : YJ
    /// </summary>
    function calculateCount(availableOptions = false) {
        let count = 0;

        try {
            if (availableOptions) {
                if (options.some(item => item.hasOwnProperty('options'))) {
                    options.map((i) => {
                        count += i.options.filter(y => !(selected ?? []).includes(y.value)).length;
                    })
                }
                else if (options.some(item => item.hasOwnProperty("value"))) {
                    count = (options ?? []).filter(y => !(selected ?? []).includes(y.value)).length;
                }
                else {
                    count = (options ?? []).filter(y => !(selected ?? []).includes(y)).length;
                }
            }
            else {
                count = (selected ?? []).length;
            }
        }
        catch(err) {
            // do nth
        }

        return count;
    }

    return (
        <div className='react-dual-listbox'>
            <div className='rdl-list-box' style={{ textAlign: "center", border: "2px solid black", color: "black", margin: "5px 0px 5px 0px", padding: "5px 0px 5px 0px", fontWeight: 900 }}>
                Available [{calculateCount(true)}]
            </div>
            <div className='rdl-actions' style={{ width: "36.6px"}}></div>
            <div className='rdl-list-box' style={{ textAlign: "center", border: "2px solid black", color: "black", margin: "5px 0px 5px 0px", padding: "5px 0px 5px 0px", fontWeight: 900 }}>
                Selected [{calculateCount(false)}]
            </div>
        </div>
    )
}

export default DualListBoxItemRow;