import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { navigateTo, createMultiPartFormBody, stringIsNullOrEmpty } from "../../../util/Util";
import { ApiKey, ApiUrl, WebUrl, AccessRight, FilterStatus, Status, AlertTypes } from "../../../util/Constant";
import NavigationButton from '../../../components/custom/NavigationButton';
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import ApiEngine from '../../../util/ApiEngine.js';
import { showResponseMessage, showMessage } from 'redux/AppAction';
import { useForm } from "react-hook-form";

/// <summary>
/// Author : YJ
/// </summary>
const ManageIPBlackList = props => {
    const { t } = useTranslation();
    let _dispatch = useDispatch();
    const [ipAddressData, setIpAddressData] = useState([]);
    const [detailModalShow, setDetailModalShow] = useState(false);
    const [modalId, setModalId] = useState('');
    var { register, unregister, handleSubmit, errors, setValue, triggerValidation } = useForm();
    const [ipAddress, setIpAddress] = useState('');

    /// <summary>
    /// Author : YJ
    /// </summary>
    let _tableColumns = useMemo(() => [
        {
            Header: "IP_ADDRESS",
            accessor: "ipAddress"
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => { setModalId(row.original.id); setDetailModalShow(true); }}><span>{t("EDIT")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                deleteHandler(row.original.id)
                            }}><span className="text-red">{t("DELETE")}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible", width: '1%' }
        }
    ], []);

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : YJ
    /// </summary>
    const init = async () => {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_IP_BLACKLIST);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setIpAddressData(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function deleteHandler(id) {
        var responseJson = await ApiEngine.post(ApiUrl._API_REMOVE_IP_BLACKLIST_BY_ID + "?id=" + id);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            init();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        if (detailModalShow) {
            detailInit();
        }
    }, [detailModalShow]);

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function detailInit() {
        if (modalId) {
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_IP_BLACKLIST_BY_ID + "?id=" + modalId);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setModalId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                setIpAddress(responseJson[ApiKey._API_DATA_KEY]["ipAddress"]);
            }
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    function modalCleanUp() {
        setIpAddress('');
        setModalId('');
        setDetailModalShow(false);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            "ipAddress": data.ipAddress
        }

        if (!stringIsNullOrEmpty(modalId)) {
            params["id"] = modalId
        }
        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_IP_BLACKLIST, createMultiPartFormBody(params));

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])))

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            modalCleanUp();
            init();
        }
    }

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_IP_BLACK_LIST")}</h1>
                {
                    checkIfPermissionExist(AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION, true) &&
                    <button type="button" onClick={() => { setDetailModalShow(true); setModalId(''); }} className="btn btn-primary" style={{ float: "right" }} >
                        <i className="fa fa-plus-circle m-r-10"></i>
                        {t("ADD_NEW_IP_BLACK_LIST")}
                    </button>
                }
            </div>
            <Panel>
                <PanelBody>
                    <ReactTable data={ipAddressData} columns={_tableColumns} />
                </PanelBody>
            </Panel>
            <Modal isOpen={detailModalShow} toggle={() => { modalCleanUp() }} size="md">
                <form onSubmit={handleSubmit(submitForm)}>
                    <ModalHeader toggle={() => { modalCleanUp()}}><h4>{t("IP_BLACKLIST_DETAIL")}</h4></ModalHeader>
                    <ModalBody>
                        <div class="form-group">
                            <label>{t("IP_ADDRESS")}</label>
                            <input type="text"
                                class="form-control form-control-lg"
                                name="ipAddress"
                                defaultValue={ipAddress}
                                ref={register({ required: "PLEASE_ENTER_IP_ADDRESS" })}
                                placeholder={t("PLEASE_ENTER_IP_ADDRESS")} />
                            {errors.ipAddress && <div className="invalid-feedback">{t(errors.ipAddress.message)}</div>}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-primary btn-action">{t("BTN_SUBMIT")}</button>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    )
}

export default ManageIPBlackList;