import React, { useMemo, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import NavigationButton from '../../../components/custom/NavigationButton';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo } from "../../../util/Util";
import { ApiKey, ApiUrl, WebUrl, AlertTypes } from "../../../util/Constant";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, setIdle, showMessage } from '../../../redux/AppAction';
import { useTranslation } from 'react-i18next';
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import ApiEngine from '../../../util/ApiEngine.js';

/// <summary>
/// Author : YJ
/// </summary>
const EditRole = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    var _location = useLocation();
    var _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, getValues } = useForm();

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState(true);
    const [controlOnlyAssignedUser, setControlOnlyAssignedUser] = useState(false);
    const [accessRightOption, setAccessRightOption] = useState({});
    const [roleId, setRoleId] = useState("");
    const [regionTrigger, setRegionTrigger] = useState(false)

    const _REGION_ARRAY = [
        { name: "agentRegion", displayName: "AGENT" },
        { name: "eastRegion", displayName: "EAST" },
        { name: "middleRegion", displayName: "MIDDLE" },
        { name: "northRegion", displayName: "NORTH" },
        { name: "southRegion", displayName: "SOUTH" }
    ]

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function init() {
        var accessOption = {};
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_ROLE_ACCESS_DATA);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            accessOption = responseJson[ApiKey._API_DATA_KEY];
        }

        if (_location.state) {
            var apiUrl = ApiUrl._API_GET_ROLE_BY_ID;
            apiUrl += "?roleId=" + _location.state["id"];
            var responseJson = await ApiEngine.get(apiUrl);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Object.keys(accessOption).map((key, value) => {
                    Object.keys(accessOption[key]).map((innerKey, innerValue) => {
                        accessOption[key][innerKey] = responseJson[ApiKey._API_DATA_KEY][innerKey[0].toLowerCase() + innerKey.substr(1).replace(/\s+/g, '')];
                    });
                });
                setName(responseJson[ApiKey._API_DATA_KEY]["roleName"]);
                setDescription(responseJson[ApiKey._API_DATA_KEY]["description"]);
                setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
                setControlOnlyAssignedUser(responseJson[ApiKey._API_DATA_KEY]["controlOnlyAssignedUser"]);
                setRoleId(responseJson[ApiKey._API_DATA_KEY]["id"]);

                _REGION_ARRAY.map((region, index) => {
                    if (responseJson[ApiKey._API_DATA_KEY][region.name] == true) {
                        setRegionTrigger(true);
                        setValue("regionTrigger", true);
                        setValue(region.name, true);
                    }
                })
            }
        }

        setAccessRightOption(accessOption);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            "roleName": data.roleName,
            "description": data.description,
            "status": data.status,
            "controlOnlyAssignedUser": data.controlOnlyAssignedUser,
        }

        if (!stringIsNullOrEmpty(roleId)) {
            params["id"] = roleId;
        }

        Object.keys(data).map((key, value) => {
            params[key] = data[key];
        });

        if (data.regionTrigger == false) {
            _REGION_ARRAY.map((region, index) => {
                params[region.name] = false;
            })
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_ROLE, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_ROLE)
        }));
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    function validateCheckbox(value) {
        var valid = true;

        if (value == true) {
            let checkedArr = [];
            checkedArr = _REGION_ARRAY.filter((region) => {
                return getValues()[region.name] == true
            })
            valid = checkedArr.length > 0;
        }

        return valid;
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    function bulkUpdatePermission(isReset = false) {
        Object.keys(accessRightOption).map((key, value) => {
            Object.keys(accessRightOption[key]).map((innerKey, innerValue) => {
                var finalPermission = "0";

                if (!isReset) {
                    var accessString = ("00" + accessRightOption[key][innerKey].toString(2));
                    accessString = accessString.substr(accessString.length - 2);
                    var canWrite = checkIfPermissionExist(innerKey, true);
                    var canRead = checkIfPermissionExist(innerKey, false);

                    finalPermission = (canWrite && canRead) ? "3" : (canWrite ? "2" : (canRead ? "1" : "0"));
                }

                setValue(innerKey[0].toLowerCase() + innerKey.substr(1).replace(/\s+/g, ''), finalPermission);
            });
        });
    }

    return (
        <div>
            <h1 className="page-header">{roleId ? t("EDIT_ROLE") : t("ADD_NEW_ROLE")}<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <h3>{t("ROLE_INFORMATION")}</h3>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("ROLE_NAME")}</b></label>
                                            <input type="text" name="roleName" className="form-control m-b-5"
                                                ref={register({
                                                    required: "PLEASE_ENTER_ROLE_NAME"
                                                })}
                                                placeholder={t("PLEASE_ENTER_ROLE_NAME")} defaultValue={name} />
                                            {errors.roleName && <div className="invalid-feedback">{t(errors.roleName.message)}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("DESCRIPTION")}</b></label>
                                            <input type="text" name="description" className="form-control m-b-5"
                                                ref={register({
                                                    required: "PLEASE_ENTER_ROLE_DESCRIPTION"
                                                })}
                                                placeholder={t("PLEASE_ENTER_ROLE_DESCRIPTION")} defaultValue={description} />
                                            {errors.description && <div className="invalid-feedback">{t(errors.description.message)}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("STATUS")}</b></label><br />
                                            <div className="switcher">
                                                <input type="checkbox" name="status" id="status"
                                                    onChange={(e) => setStatus(e.target.checked)}
                                                    value={true}
                                                    checked={status}
                                                    ref={register} />
                                                <label htmlFor="status"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("CONTROL_ONLY_ASSIGNED_USER")}</b></label><br />
                                            <div className="switcher">
                                                <input type="checkbox" name="controlOnlyAssignedUser" id="controlOnlyAssignedUser"
                                                    onChange={(e) => setControlOnlyAssignedUser(e.target.checked)}
                                                    value={true}
                                                    checked={controlOnlyAssignedUser}
                                                    ref={register} />
                                                <label htmlFor="controlOnlyAssignedUser"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <div className="checkbox checkbox-css checkbox-inline">
                                                <input
                                                    type="checkbox"
                                                    ref={register({
                                                        validate: (value) => validateCheckbox(value) || "PLEASE_CHOOSE_ONE_OF_THE_REGION"
                                                    })}
                                                    name="regionTrigger"
                                                    id="regionTrigger"
                                                    onChange={() => { setRegionTrigger(!regionTrigger) }} />
                                                <label htmlFor="regionTrigger">{t("TICK_FOR_CUSTOM_REGION")}</label>
                                            </div>
                                            {regionTrigger && <div className="row m-t-10">
                                                {
                                                    _REGION_ARRAY.map((region, index) => {
                                                        return <div class="col-lg-2" key={index}>
                                                            <div className="checkbox checkbox-css checkbox-inline">
                                                                <input
                                                                    type="checkbox"
                                                                    ref={register}
                                                                    name={region.name}
                                                                    id={region.name} />
                                                                <label htmlFor={region.name}>{t(region.displayName)}</label>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>}
                                            {errors.regionTrigger && <div className="invalid-feedback">{t(errors.regionTrigger.message)}</div>}
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <h3>{t("FUNCTION_LIST")}</h3>
                                <div className="row">
                                    {Object.keys(accessRightOption).map((key, value) => {
                                        return (<div className="col-lg-4" key={value}>
                                            <div className="card text-truncate mb-3">
                                                <div className="card-header">
                                                    <b>{t(key.toUpperCase())}</b>
                                                </div>
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-nowrap">{t("FUNCTION_NAME")}</th>
                                                                    <th className="text-nowrap">{t("ACCESS_LEVEL")}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Object.keys(accessRightOption[key]).map((innerKey, innerValue) => {
                                                                    var accessString = ("00" + accessRightOption[key][innerKey]?.toString(2));
                                                                    accessString = accessString.substr(accessString.length - 2);
                                                                    var canWrite = checkIfPermissionExist(innerKey, true);
                                                                    var canRead = checkIfPermissionExist(innerKey, false);

                                                                    return (<tr key={innerValue}>
                                                                        <td> {t(innerKey.toUpperCase().replace(/ /g, "_"))}</td>
                                                                        <td>
                                                                            <select defaultValue={accessRightOption[key][innerKey]} className="form-control" name={innerKey[0].toLowerCase() + innerKey.substr(1).replace(/\s+/g, '')} ref={register}>
                                                                                {(canWrite && canRead) && <option value="3">{t("ALL")}</option>}
                                                                                {canWrite && <option value="2">{t("CONTROL")}</option>}
                                                                                {canRead && <option value="1">{t("VIEW")}</option>}
                                                                                <option value="0">{t("NONE")}</option>
                                                                            </select>
                                                                        </td>
                                                                    </tr>)
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>);
                                    })}
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">{t("SUBMIT")}</button>
                                            <button type="button" className="btn btn-danger m-l-5" onClick={() => bulkUpdatePermission(true)}>{t("RESET")}</button>
                                            <button type="button" className="btn btn-success m-l-5" onClick={() => bulkUpdatePermission(false)}>{t("TOP_PRIVILEGE")}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    )
}

export default withRouter(EditRole);