import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import ReactTable from "../../../components/custom/ReactTable";
import { setBusy, setIdle } from '../../../redux/AppAction';
import { ApiKey, ApiUrl, Role, Status } from "../../../util/Constant";
import { stringIsNullOrEmpty, arrayGroupByKey } from "../../../util/Util";
import { useForm } from "react-hook-form";
import ApiEngine from '../../../util/ApiEngine.js';
import { useDispatch } from "react-redux";
import RegionFilterDualListBox from "../../../components/custom/RegionFilterDualListBox";

/// <summary>
/// Author : CK
/// </summary>
const ClaimRewardReport = props => {
    let _dispatch = useDispatch();
    const [postData, setPostData] = useState({});
    const { register, handleSubmit, setValue } = useForm();
    const { t } = useTranslation();
    const [apiUrl, setApiUrl] = useState('');
    const [shopOption, setShopOption] = useState([]);
    const [selectedShop, setSelectedShop] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [regions, setRegions] = useState([]);

    const _TYPE_OPTION = {
        ALL: -1,
        IN: 0,
        OUT: 1,
    };

    let _tableColumns = [
        {
            Header: t("DATE"),
            accessor: "createdTime",
            disableSortBy: true
        },
        {
            Header: t("OPERATED_SHOP"),
            accessor: "operatedShop",
            disableSortBy: true
        },
        {
            Header: t("PHONE_NUMBER"),
            accessor: "phoneNumber",
            disableSortBy: true
        },
        {
            Header: t("STATUS"),
            accessor: "status",
            Cell: ({ row }) => {
                return row.original.status ? <span className="badge badge-green">{t("SUCCESS")}</span> : <span className="badge badge-danger">{t("FAILED")}</span>;
            },
            disableSortBy: true
        },
        {
            Header: t("TYPE"),
            accessor: "type",
            disableSortBy: true
        },
        {
            Header: t("INITIAL_LIMIT"),
            accessor: "shopInitialLimit",
            Cell: ({ row }) => {
                return row.original.shopInitialLimit.toFixed(2)
            },
            Footer: ({ page }) => {
                const initialLimitTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.shopInitialLimit);
                }, 0);

                return <span><b>{initialLimitTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: t("SCORE"),
            accessor: "amount",
            Cell: ({ row }) => {
                return row.original.amount > 0 ? <span className="text-success">{row.original.amount.toFixed(2)}</span> : <span className="text-danger">{row.original.amount.toFixed(2)}</span>;
            },
            Footer: ({ page }) => {
                const scoreTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.amount);
                }, 0);

                return <span><b>{scoreTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: t("FINAL_LIMIT"),
            accessor: "shopFinalLimit",
            Cell: ({ row }) => {
                return row.original.shopFinalLimit.toFixed(2)
            },
            Footer: ({ page }) => {
                const finalLimitTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.shopFinalLimit);
                }, 0);

                return <span><b>{finalLimitTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: t("PLAYER_INITIAL_LIMIT"),
            accessor: "playerInitialCredit",
            Cell: ({ row }) => {
                return row.original.playerInitialCredit.toFixed(2)
            },
            disableSortBy: true
        },
        {
            Header: t("PLAYER_FINAL_LIMIT"),
            accessor: "playerFinalCredit",
            Cell: ({ row }) => {
                return row.original.playerFinalCredit.toFixed(2)
            },
            disableSortBy: true
        },
        {
            Header: t("IP_ADDRESS"),
            accessor: "ipAddress",
            disableSortBy: true
        }
    ]

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        register({ name: 'operatedShop' });
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: true });

        setValue('operatedShop', []);
        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
        init();
    }, []);

    /// <summary>
    /// Author : CK
    /// </summary>
    async function init() {
        await getShopOption();
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function getShopOption() {
        var apiUrl = ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP;
        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var shopListByRegion = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "regionName");
            var shopOptionsByRegion = [];
            var regionOptions = [];

            for (var key in shopListByRegion) {
                var shopList = [];

                regionOptions.push({ label: key, value: key });

                shopListByRegion[key].map(shop => {
                    shopList.push({ label: shop.username + " (" + shop.shopName + ")", value: shop.id });
                });

                shopOptionsByRegion.push({
                    label: key,
                    value: key,
                    options: shopList
                })
            }
            
            setShopOption(shopOptionsByRegion);
            setRegions(regionOptions);
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "TargetShop": data.operatedShop,
            "Type": _TYPE_OPTION.ALL,
            "Status": parseInt(data.status)
        };

        if (!stringIsNullOrEmpty(data.phoneNumber)) {
            params["PhoneNumber"] = data.phoneNumber;
        }

        if (!stringIsNullOrEmpty(data.playerId)) {
            params["PlayerId"] = data.playerId;
        }

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_CLAIM_REWARD_REPORT);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable
        className="col-nowrap"
        columns={_tableColumns}
        postData={postData}
        fetchUrl={apiUrl}
        renderFooter={true}
        exportRequired={true} />, [postData, apiUrl]);

    return (
        <div>
            <h1 className="page-header">{t("CLAIM_REWARD_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("STATUS")}</label>
                                    <select name="status" ref={register} className="form-control form-control-lg">
                                        <option value={_TYPE_OPTION.ALL}>{t("ALL")}</option>
                                        <option value={Status._ENABLED}>{t("SUCCESS")}</option>
                                        <option value={Status._DISABLED}>{t("FAILED")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("PHONE_NUMBER")}</label>
                                    <input type="text" name="phoneNumber" ref={register} className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("OPERATED_SHOP")}</label>
                                    <RegionFilterDualListBox
                                        name={"operatedShop"}
                                        ref={register}
                                        options={shopOption}
                                        selectedOptions={selectedShop}
                                        regions={regions}
                                        onChange={(e) => {
                                            setValue('operatedShop', e);
                                            setSelectedShop(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
};

export default ClaimRewardReport;