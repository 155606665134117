import React, { useState, useEffect, useMemo } from 'react';
import { Panel, PanelBody } from '../../../components/panel/panel';
import { WebUrl, ApiKey, ApiUrl, AccessRight, Role } from '../../../util/Constant';
import ReactTable from '../../../components/custom/ReactTable';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ApiEngine from '../../../util/ApiEngine.js';
import { useTranslation } from 'react-i18next';
import * as Util from '../../../util/Util';
import { useDispatch } from "react-redux";
import Select from "react-select";
import { showResponseMessage } from "../../../redux/AppAction";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useHistory } from "react-router-dom";
import NavigationButton from '../../../components/custom/NavigationButton';
import AttachShopModal from '../../../components/custom/AttachShopModal';

/// <summary>
/// Author: CK
/// </summary>
const ManageVendorApi = props => {
    const { t, i18n } = useTranslation();
    var _history = useHistory();
    var _dispatch = useDispatch();
    const [vendorList, setVendorList] = useState([]);
    const [vendorId, setVendorId] = useState(sessionStorage.getItem("manageVendorApiVendorId") ?? '');
    const [filteredIsPrimary, setFilteredIsPrimary] = useState(sessionStorage.getItem("manageVendorApiIsPrimary") ?? '');
    const [vendorApiList, setVendorApiList] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedVendorApi, setSelectedVendorApi] = useState();
    const [isAgentModalVisible, setIsAgentModalVisible] = useState(false);

    /// <summary>
    /// Author: CK
    /// </summary>
    let _vendorApiTableColumns = useMemo(() => [
        {
            Header: "VENDOR",
            accessor: "vendorName",
        },
        {
            Header: "CODE",
            accessor: "vendorShortName",
        },
        {
            Header: "ROOT_AGENT",
            accessor: "rootAgent",
        },
        {
            Header: "KEY",
            accessor: "signKey",
        },
        {
            Header: "OPCODE",
            accessor: "opcode",
        },
        {
            Header: "OPSECRET",
            accessor: "opSecret",
        },
        {
            Header: "TOTAL_SHOPS",
            accessor: "totalShop",
        },
        {
            Header: "TOTAL_AGENTS",
            accessor: "totalAgents",
        },
        {
            Header: "TYPE",
            accessor: "isPrimary",
            Cell: ({ row }) => {
                return <>
                    {
                        row.original.isPrimary ? <span className="badge badge-green">{t("PRIMARY")}</span> : <span className="badge badge-primary">{t("SECONDARY")}</span>
                    }
                    {
                        row.original.isBackup && <span className="badge badge-aqua">{t("BACKUP")}</span>
                    }
                </>;
            }
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                return row.original.status ? <span className="badge badge-green">{t("ENABLE")}</span> : <span className="badge badge-danger">{t("DISABLE")}</span>;
            }
        },
        {
            Header: "PRIORITY",
            accessor: "priority"
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._GAME_VENDOR_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                onEditStatus({ "id": row.original.id, "status": !row.original.status })
                            }}>
                                <span>{row.original.status ? t("DISABLE") : t("ENABLE")}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                _history.push({
                                    pathname: WebUrl._URL_ADMIN_VENDOR_API_DETAIL,
                                    state: { id: row.original.id }
                                })
                            }}>
                                <span>{t('EDIT_VENDOR_API_DETAILS')}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                setSelectedVendorApi(row.original);
                                toggleModal();
                            }}>
                                <span>{t('ATTACH_SHOPS')}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                setSelectedVendorApi(row.original);
                                toggleAgentModal();
                            }}>
                                <span>{t('ATTACH_AGENTS')}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ], [vendorList, vendorApiList]);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        getVendorList();
        getVendorApiList();
    }, []);

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function getVendorList() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_LIST);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const vendorList = [];
            vendorList.push({ label: t("ALL"), value: "" });

            responseJson[ApiKey._API_DATA_KEY].forEach(function (vendor) {
                vendorList.push({ label: vendor.name + " (" + vendor.shortName + ")", value: vendor.id });
            });

            setVendorList(vendorList);
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function getVendorApiList() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_API_LIST + "?isPrimary=" + filteredIsPrimary + "&vendorId=" + vendorId);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setVendorApiList(responseJson[ApiKey._API_DATA_KEY]);
        }

        saveSession();
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    function saveSession() {
        sessionStorage.setItem("manageVendorApiIsPrimary", filteredIsPrimary);
        sessionStorage.setItem("manageVendorApiVendorId", vendorId);
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function onEditStatus(data) {
        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_VENDOR_API, Util.createMultiPartFormBody(data));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            getVendorApiList();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function toggleModal() {
        if (isModalVisible) {
            setSelectedVendorApi(null);
        }

        setIsModalVisible(!isModalVisible);
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function toggleAgentModal() {
        if (isAgentModalVisible) {
            setSelectedVendorApi(null);
        }

        setIsAgentModalVisible(!isAgentModalVisible);
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function onSubmitAttachShop(attachedShops) {
        let params = {
            "apiId": selectedVendorApi.id,
            "shopIds": attachedShops.join(',')
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_ATTACH_VENDOR_API_META_SHOP, Util.createMultiPartFormBody(params));

        toggleModal();
        getVendorApiList();

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function onSubmitAttachAgent(attachedAgents) {
        let params = {
            "apiId": selectedVendorApi.id,
            "agentIds": attachedAgents.join(',')
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_ATTACH_VENDOR_API_META_AGENT, Util.createMultiPartFormBody(params));

        toggleAgentModal();
        getVendorApiList();

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    return (
        <div id="page-content">
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_API_KEYS")}</h1>
                {
                    checkIfPermissionExist(AccessRight._GAME_VENDOR_CONTROL_PERMISSION, true) &&
                    <NavigationButton history={_history} url={WebUrl._URL_ADMIN_VENDOR_API_DETAIL} buttonText={t("CREATE_API_KEYS")} />
                }
            </div>
            <Panel>
                <PanelBody>
                    <div className="row m-b-10">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("TYPE")}</label>
                                <select
                                    className="form-control form-control-lg"
                                    id="isPrimary"
                                    name="isPrimary"
                                    value={filteredIsPrimary}
                                    onInput={(e) => { setFilteredIsPrimary(e.target.value) }} >
                                    <option value={''}>{t("ALL")}</option>
                                    <option value={true}>{t("PRIMARY")}</option>
                                    <option value={false}>{t("SECONDARY")}</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label><b>{t("VENDOR")}</b></label>
                                <Select name="roleId" options={vendorList}
                                    placeholder={(vendorList.filter(option => option.value == vendorId)[0] !== undefined) ? (vendorList.filter(option => option.value == vendorId)[0].label) : ""}
                                    value={vendorList.filter(option => option.value == vendorId)}
                                    onChange={(e) => {
                                        setVendorId(e.value);
                                    }} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <button id="btnFilter" onClick={getVendorApiList} className="btn btn-primary">{t("APPLY_FILTER")}</button>
                            </div>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    <ReactTable data={vendorApiList} columns={_vendorApiTableColumns} filterable={true} />
                </PanelBody>
            </Panel>
            <AttachShopModal
                isModalVisible={isModalVisible}
                title={selectedVendorApi ? selectedVendorApi.rootAgent : ""}
                getShopListUrl={selectedVendorApi ? ApiUrl._API_AVAILABLE_API_META_SHOP_LIST + "?apiId=" + selectedVendorApi.id : null}
                getAttachedShopUrl={selectedVendorApi ? ApiUrl._API_GET_VENDOR_API_SHOP_BY_API_ID + "?apiId=" + selectedVendorApi.id : null}
                onFinish={(attachedShops) => {
                    if (attachedShops) {
                        onSubmitAttachShop(attachedShops);
                    }
                    else {
                        toggleModal();
                    }
                }} />

            <AttachShopModal
                isModalVisible={isAgentModalVisible}
                title={selectedVendorApi ? selectedVendorApi.rootAgent : ""}
                roleId={Role._AGENT}
                getShopListUrl={selectedVendorApi ? ApiUrl._API_AVAILABLE_API_META_AGENT_LIST + "?apiId=" + selectedVendorApi.id : null}
                getAttachedShopUrl={selectedVendorApi ? ApiUrl._API_GET_VENDOR_API_AGENT_BY_API_ID + "?apiId=" + selectedVendorApi.id : null}
                onFinish={(attachedAgents) => {
                    if (attachedAgents) {
                        onSubmitAttachAgent(attachedAgents);
                    }
                    else {
                        toggleAgentModal();
                    }
                }} />
        </div>
    );
}

export default ManageVendorApi;