import { AppConfiguration } from "read-appsettings-json";
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { performLogin } from "./AuthAction";
import { CommonStatus } from "../util/Constant";
import { isObjectEmpty } from "../util/Util";
import { disposeMessage, showResponseMessage } from "./AppAction";

export const _UPDATE_CONTENT = 'updateContent';
export const _UPDATE_REQUEST_CONTENT = 'updateRequestContent';

/// <summary>
/// Author: Nelson
/// </summary>
const setupFirebaseSetting = new Promise((resolve, reject) => {
    if (!firebase.apps.length) {
        firebase.initializeApp({
            apiKey: AppConfiguration.Setting().ApplicationConfig.firebaseApiKey,
            projectId: AppConfiguration.Setting().ApplicationConfig.firebaseProjectId,
            messagingSenderId: AppConfiguration.Setting().ApplicationConfig.firebaseSenderId,
            appId: AppConfiguration.Setting().ApplicationConfig.firebaseAppId,
            databaseURL: AppConfiguration.Setting().ApplicationConfig.firebaseDatabaseUrl,
        });
    }

    firebase.auth().signInWithEmailAndPassword(AppConfiguration.Setting().ApplicationConfig.firebaseDatabaseEmail, AppConfiguration.Setting().ApplicationConfig.firebaseDatabaseCode)
        .then(function () {
            resolve(firebase.database());
        })
        .catch(function (error) {
            firebase.auth().signInAnonymously()
                .then(function () {
                    resolve(firebase.database());
                })
                .catch(function (error) {
                    reject(error);
                });
        });
});

/// <summary>
/// Author: Nelson
/// </summary>
export function initFirebaseDatabase(id, extraData = {}) {
    return async dispatch => {
        setupFirebaseSetting.then(async (database) => {
            await database.ref(`${AppConfiguration.Setting().ApplicationConfig.firebaseDatabaseName}/${id}`).on('value', async (shop) => {
                var data = null;

                if (shop.val()) {
                    data = shop.val();
                }

                if (data != null) {
                    await dispatch({
                        type: _UPDATE_CONTENT,
                        dataContent: data
                    });

                    if (!isObjectEmpty(extraData) && extraData['requestId'] == data['data'] && data['status'] != CommonStatus._PENDING_STATUS) {
                        await dispatch(disposeMessage());
                        if (data['status'] == CommonStatus._APPROVE_STATUS) {
                            await dispatch(performLogin(extraData['username'], extraData['password']));
                        }
                        else if (data['status'] == CommonStatus._REJECT_STATUS) {
                            await dispatch(showResponseMessage(false, 'LOGIN_REQUEST_REJECTED'));
                        }
                        else if (data['status'] == CommonStatus._INACTIVE_STATUS) {
                            await dispatch(showResponseMessage(false, 'LOGIN_REQUEST_TIMEOUT'));
                        }
                    }
                }
            });
        });
    }
}

/// <summary>
/// Author: YJ
/// </summary>
export function initFirebaseTransactionRequestDatabase(key, code) {
    return async dispatch => {
        setupFirebaseSetting.then(async (database) => {
            await database.ref(`${AppConfiguration.Setting().ApplicationConfig.firebaseDatabaseName}/${key}`).on('value', async (request) => {
                var data = null;

                if (request.val()) {
                    data = request.val();
                }

                if (data == null) {
                    await dispatch({
                        type: _UPDATE_REQUEST_CONTENT,
                        requestCode: code
                    });
                }
            });
        });
    }
}