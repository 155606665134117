import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { ApiKey, ApiUrl, AccessRight, AlertTypes, Status, WebUrl } from '../../../util/Constant';
import { stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat, createMultiPartFormBody } from "../../../util/Util";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useDispatch } from "react-redux";
import { showMessage, showResponseMessage } from "../../../redux/AppAction";
import ApiEngine from '../../../util/ApiEngine.js';
import { useForm } from "react-hook-form";
import AttachShopModal from '../../../components/custom/AttachShopModal';
import Dropzone from 'react-dropzone';

/// <summary>
/// Author: YJ
/// </summary> 
const ManageBonus = props => {
    var _dispatch = useDispatch();
    const { t } = useTranslation();
    const { handleSubmit, register } = useForm();
    const { register: registerBonusForm, errors: errorsBonusForm, handleSubmit: handleSubmitBonusForm, setValue: setValueBonusForm } = useForm();
    const _history = useHistory();
    const [apiUrl, setApiUrl] = useState("");
    const [bonusForm, setBonusForm] = useState({});
    const [selectedBonus, setSelectedBonus] = useState({});
    const [banner, setBanner] = useState();
    const [bannerImage, setBannerImage] = useState();

    let _tableColumns = useMemo(() => [
        {
            Header: "NAME",
            accessor: "name",
            disableSortBy: true
        },
        {
            Header: "BONUS_AMOUNT",
            accessor: "amount",
            Cell: ({ row }) => {
                return <span>{numberWithCurrencyFormat(row.original.amount)}</span>;
            },
            disableSortBy: true
        },
        {
            Header: "RATE",
            accessor: "rate",
            disableSortBy: true
        },
        {
            Header: "MINIMUM_THRESHOLD",
            accessor: "minimumThreshold",
            Cell: ({ row }) => {
                return <span>{numberWithCurrencyFormat(row.original.minimumThreshold)}</span>;
            },
            disableSortBy: true
        },
        {
            Header: "MAXIMUM_THRESHOLD",
            Cell: ({ row }) => {
                return <span>{numberWithCurrencyFormat(row.original.amount * row.original.rate)}</span>;
            },
            disableSortBy: true
        },
        {
            Header: "DAILY_CLAIM_LIMIT",
            accessor: "limitCount",
            disableSortBy: true
        },
        {
            Header: "STATUS",
            accessor: "status",
            disableSortBy: true,
            Cell: ({ row }) => {
                let returnClassName = "badge badge-success";
                let statusName = "ACTIVE";

                switch (row.original.status) {
                    case Status._DISABLED:
                        returnClassName = "badge badge-warning";
                        statusName = "DEACTIVATE";
                        break;
                    case Status._DELETED:
                        returnClassName = "badge badge-danger";
                        statusName = "DELETED";
                        break;
                }
                return <span className={returnClassName} style={{ textTransform: "uppercase" }}>{t(statusName)}</span>
            },
        },
        {
            Header: "TOTAL_SHOPS",
            accessor: "totalShops",
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    {
                        row.original.status != Status._DELETED &&
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="default">
                                <i className="fas fa-cog"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={() => { setBonusForm({ ...row.original }); setBannerImage(row.original.banner); }}>
                                    <span>{t("EDIT_BONUS_DETAILS")}</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => {
                                    _dispatch(showMessage({
                                        type: AlertTypes._WARNING,
                                        title: t('ARE_YOU_SURE'),
                                        showCancel: true,
                                        content: t('ARE_YOU_SURE_YOU_WANT_TO') + t(row.original.status == Status._DISABLED ? 'ACTIVE' : 'DEACTIVATE') + " " + row.original.name + "?",
                                        onConfirm: () => {
                                            createOrUpdateBonus({
                                                ...row.original,
                                                status: row.original.status == Status._DISABLED ? Status._ENABLED : Status._DISABLED
                                            })
                                        }
                                    }));
                                }}>
                                    <span className={row.original.status == Status._DISABLED ? "text-success" : "text-warning"}>
                                        {row.original.status == Status._DISABLED ? t('ACTIVE') : t('DEACTIVATE')}
                                    </span>
                                </DropdownItem>
                                <DropdownItem onClick={() => {
                                    _dispatch(showMessage({
                                        type: AlertTypes._WARNING,
                                        title: t('ARE_YOU_SURE'),
                                        showCancel: true,
                                        content: t('ARE_YOU_SURE_YOU_WANT_TO') + t('DELETE') + " " + row.original.name + "?",
                                        onConfirm: () => {
                                            createOrUpdateBonus({
                                                ...row.original,
                                                status: Status._DELETED
                                            })
                                        }
                                    }));
                                }}>
                                    <span className={"text-danger"}>
                                        {t('DELETE')}
                                    </span>
                                </DropdownItem>
                                <DropdownItem onClick={() => {
                                    setSelectedBonus(row.original);
                                }}>
                                    <span>{t('ATTACH_SHOPS')}</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    }
                </div>
            ),
        }
    ], [apiUrl])

    /// <summary>
    /// Author: YJ
    /// </summary> 
    useEffect(() => {
        getBonusList();
    }, []);

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function getBonusList() {
        setApiUrl(ApiUrl._API_GET_BONUSES + '?&v=' + new Date());
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function createOrUpdateBonus(bonusObj) {
        try {
            var responseJson;
            let bonusId = bonusObj.id;
            delete bonusObj.id;

            if (bonusId > 0) {
                responseJson = await ApiEngine.put(`${ApiUrl._API_CREATE_OR_UPDATE_BONUSES}/${bonusId}`, createMultiPartFormBody(bonusObj));
            }
            else {
                responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_BONUSES, createMultiPartFormBody(bonusObj));
            }


            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _dispatch(showMessage({
                type: AlertTypes._SUCCESS,
                content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
                onConfirm: () => {
                    getBonusList();
                    setBonusForm({});
                    setBanner();
                    setBannerImage();
                }
            }));
        }
        catch (err) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(err)
            }));
        }
    }

    /// <summary>
    /// Author: YJ
    /// </summary> 
    const PaginationTable = useMemo(() =>
        <ReactTable
            columns={_tableColumns}
            fetchUrl={apiUrl}
            exportRequired={true} />,
        [apiUrl])

    /// <summary>
    /// Author: YJ
    /// </summary>
    const submitForm = async (data, e) => {
        setApiUrl(`${ApiUrl._API_GET_BONUSES}?status=${data.status}`);
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    const submitBonusForm = async (data, e) => {
        data = {
            ...data,
            id: bonusForm.id,
            amount: parseFloat(data.amount),
            rate: parseFloat(data.rate),
            minimumThreshold: parseFloat(data.minimumThreshold),
            limitCount: parseInt(data.limitCount),
            status: data.status ? Status._ENABLED : Status._DISABLED,
        }

        if (!stringIsNullOrEmpty(banner)) {
            data["banner"] = banner;
        }

        createOrUpdateBonus(data);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const updateImage = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setBanner(file);

            const reader = new FileReader();

            reader.onloadend = () => {
                setBannerImage(reader.result);
            }

            reader.readAsDataURL(file);
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function onSubmitAttachShop(attachedShops) {
        try {
            let params = {
                "shopIds": attachedShops.join(',')
            }

            var responseJson = await ApiEngine.post(ApiUrl._API_BONUS_ATTACH_SHOP.replace('{id}', selectedBonus.id), createMultiPartFormBody(params));

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw t(responseJson[ApiKey._API_MESSAGE_KEY]);
            }

            _dispatch(showResponseMessage(true, t(responseJson[ApiKey._API_MESSAGE_KEY])));
            getBonusList();
        }
        catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
        finally {
            setSelectedBonus({});
        }
    }

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_WELCOME_BONUS")}</h1>
                {
                    checkIfPermissionExist(AccessRight._PROMOTION_CONTROL_PERMISSION, true) &&
                    <button type="button" className="btn btn-primary" onClick={() => {
                        setBonusForm({
                            id: -1
                        });
                    }}>{t('NEW')}</button>
                }
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-lg-3 m-b-10">
                                        <label>{t("STATUS")}</label>
                                        <select name="status" ref={register} className="form-control form-control-lg">
                                            <option value={Status._ENABLED}>{t("ACTIVE")}</option>
                                            <option value={Status._DISABLED}>{t("DEACTIVATE")}</option>
                                            <option value={Status._DELETED}>{t("DELETED")}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2">
                                        <button type="submit" className="btn btn-primary">{t("APPLY_FILTER")}</button>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <Panel>
                        <PanelBody>
                            <div className="row">
                                <div className="col-lg-12">
                                    {PaginationTable}
                                </div>
                            </div>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
            <Modal isOpen={!isObjectEmpty(bonusForm)}>
                <form onSubmit={handleSubmitBonusForm(submitBonusForm)}>
                    <ModalHeader>
                        <h4>{bonusForm.id > 0 ? t('EDIT_BONUS', { name: bonusForm.name }) : t('ADD_NEW_BONUS')}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className='row'>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("NAME")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="name"
                                        name="name"
                                        type="text"
                                        placeholder={t("ENTER_NAME")}
                                        defaultValue={bonusForm?.name ?? ''}
                                        ref={registerBonusForm({ required: "PLEASE_ENTER_NAME" })} />
                                    {errorsBonusForm.name && <div className="invalid-feedback">{t(errorsBonusForm.name.message)}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("BONUS_AMOUNT")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="amount"
                                        name="amount"
                                        type="number"
                                        step=".01"
                                        placeholder={t("ENTER_BONUS_AMOUNT")}
                                        defaultValue={bonusForm?.amount ?? ''}
                                        ref={registerBonusForm({
                                            required: t('PLEASE_ENTER_BONUS_AMOUNT'),
                                            min: {
                                                value: 0,
                                                message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0')
                                            }
                                        })} />
                                    {errorsBonusForm.amount && <div className="invalid-feedback">{t(errorsBonusForm.amount.message)}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("RATE")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="rate"
                                        name="rate"
                                        type="number"
                                        step=".01"
                                        placeholder={t("ENTER_RATE")}
                                        defaultValue={bonusForm?.rate ?? ''}
                                        ref={registerBonusForm({
                                            required: t('PLEASE_ENTER_RATE'),
                                            min: {
                                                value: 0,
                                                message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0')
                                            }
                                        })} />
                                    {errorsBonusForm.rate && <div className="invalid-feedback">{t(errorsBonusForm.rate.message)}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("MINIMUM_THRESHOLD")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="minimumThreshold"
                                        name="minimumThreshold"
                                        type="number"
                                        step=".01"
                                        placeholder={t("ENTER_MINIMUM_THRESHOLD")}
                                        defaultValue={bonusForm?.minimumThreshold ?? ''}
                                        ref={registerBonusForm({
                                            required: t('PLEASE_ENTER_MINIMUM_THRESHOLD'),
                                            min: {
                                                value: 0,
                                                message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0')
                                            }
                                        })} />
                                    {errorsBonusForm.minimumThreshold && <div className="invalid-feedback">{t(errorsBonusForm.minimumThreshold.message)}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("DAILY_CLAIM_LIMIT")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="limitCount"
                                        name="limitCount"
                                        type="number"
                                        placeholder={t("ENTER_DAILY_CLAIM_LIMIT")}
                                        defaultValue={bonusForm?.limitCount ?? ''}
                                        ref={registerBonusForm({
                                            required: t('PLEASE_ENTER_DAILY_CLAIM_LIMIT'),
                                            min: {
                                                value: 0,
                                                message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0')
                                            }
                                        })} />
                                    {errorsBonusForm.limitCount && <div className="invalid-feedback">{t(errorsBonusForm.limitCount.message)}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("STATUS")}</label>
                                    <div className="col-xs-6">
                                        <div className="switcher">
                                            <input type="checkbox"
                                                id={"status"}
                                                name={"status"}
                                                defaultChecked={bonusForm?.status ?? false}
                                                ref={registerBonusForm} />
                                            <label htmlFor={"status"}></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("BANNER")}</label>
                                    <Dropzone accept={'image/*'} onDrop={acceptedFiles => updateImage(acceptedFiles)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {stringIsNullOrEmpty(bannerImage) && <h4 style={{ color: "grey" }}>{t("UPLOAD_BANNER")}</h4>}
                                                    {!stringIsNullOrEmpty(bannerImage) && <aside className="thumbsContainer">
                                                        <div className="thumb">
                                                            <div className="thumbInner">
                                                                <img
                                                                    src={bannerImage}
                                                                    className="dropzone-img"
                                                                />
                                                            </div>
                                                        </div>
                                                    </aside>}
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    <button type="button" className="mt-2 btn btn-danger"
                                        onClick={() => {
                                            setBanner();
                                            setBannerImage();
                                        }}>
                                        {t("REMOVE_IMAGE")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {
                            bonusForm.id > 0 &&
                            <div className='row'>
                                <div className="col-lg-12">
                                    <div className="form-group" style={{ wordWrap: 'break-word' }}>
                                        <text className='text-danger'>{t('PLEASE_NOTE_THAT_EDITING_BONUS_WILL_CAUSED_CURRENT_USER_WHO_HAVING_THIS_BONUS')}</text>
                                    </div>
                                </div>
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-primary">{t('BTN_SAVE')}</button>
                        <button type="button" className="btn btn-danger"
                            onClick={() => { setBonusForm({}); setBannerImage(); setBanner(); }}>{t('BTN_CANCEL')}</button>
                    </ModalFooter>
                </form>
            </Modal>
            <AttachShopModal
                isModalVisible={selectedBonus != null && !isObjectEmpty(selectedBonus)}
                title={selectedBonus?.name ?? ""}
                getShopListUrl={selectedBonus ? ApiUrl._API_BONUS_AVAILABLE_SHOPS.replace("{id}", selectedBonus.id) : null}
                getAttachedShopUrl={selectedBonus ? ApiUrl._API_BONUS_SHOPS.replace("{id}", selectedBonus.id) : null}
                onFinish={(attachedShops) => {
                    if (attachedShops) {
                        onSubmitAttachShop(attachedShops);
                    }
                    else {
                        setSelectedBonus({});
                    }
                }} />
        </div>
    )
}

export default ManageBonus;