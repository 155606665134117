import React, { useState, useEffect, useContext } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { navigateTo, createMultiPartFormBody } from "../../../util/Util";
import { ApiKey, ApiUrl, WebUrl, AccessRight, FilterStatus, Status, AlertTypes } from "../../../util/Constant";
import NavigationButton from '../../../components/custom/NavigationButton';
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import ApiEngine from '../../../util/ApiEngine.js';
import { showResponseMessage, showMessage } from 'redux/AppAction';
import * as Util from '../../../util/Util';
import AttachShopModal from '../../../components/custom/AttachShopModal';

/// <summary>
/// Author : Andrew
/// Edited : CK - Mini program shop attachment
/// </summary>
const ManageMiniProgram = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    let _dispatch = useDispatch();
    const [miniProgramData, setMiniProgramData] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedMiniProgram, setSelectedMiniProgram] = useState(null);

    /// <summary>
    /// Author: Andrew
    /// </summary>
    const _btnKeys = {
        _DISABLE: 'disable',
        _EDIT: 'edit'
    };

    /// <summary>
    /// Author: Andrew
    /// </summary>
    const btnHandler = (type, params) => {
        let options = { type: AlertTypes._WARNING, title: t("ARE_YOU_SURE"), showCancel: true };

        switch (type) {
            case _btnKeys._DISABLE:
                options = {
                    ...options, content: t("PLEASE_CONFIRM_YOU_WANT_TO") + ' ' + (params.status == Status._ENABLED ? t("DISABLE") : t("ENABLE")) + ' ' + params.name + ' ' + t("MINI_PROGRAM_QUESTION_MARK")
                    , onConfirm: () => updateStatus(params)
                }
                break;
            case _btnKeys._EDIT:

                break;
        }

        _dispatch(showMessage(options));
    }

    /// <summary>
    /// Author : Andrew
    /// </summary>
    async function updateStatus(miniProgram) {
        var param = {
            id: miniProgram.id,
            status: miniProgram.status == Status._ENABLED ? Boolean(Status._DISABLED) : Boolean(Status._ENABLED),
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_MINI_PROGRAM, Util.createMultiPartFormBody(param));
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            await fetchMiniPrograms();
        }
    }

    /// <summary>
    /// Author : Andrew
    /// </summary>
    let _tableColumns = [
        {
            Header: "IMAGE",
            accessor: "imageUrl",
            Cell: ({ row }) => {
                return <img class="mini-program-logo" src={row.original.logo} />
            }
        },
        {
            Header: "NAME",
            accessor: "name"
        },
        {
            Header: "DESCRIPTION",
            accessor: "description"
        },
        {
            Header: "NAVIGATION_KEY",
            accessor: "navigationKey"
        },
        {
            Header: "VENDOR",
            accessor: "vendorName"
        },
        {
            Header: "TOTAL_SHOPS",
            accessor: "totalShop",
            disableSortBy: true,
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                return <span>{row.original.status == 1 ? <span class="badge badge-secondary badge-green">{t("ACTIVE")}</span> : <span class="badge badge-secondary badge-danger">{t("SUSPENDED")}</span>}</span>
            },
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._MINI_PROGRAM_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_ADMIN_MINI_PROGRAM_DETAIL, row.original) }}><span>{t("EDIT")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                btnHandler(_btnKeys._DISABLE, row.original);
                            }}><span>{row.original.status == Status._ENABLED ? t("DISABLE") : t("ENABLE")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                setSelectedMiniProgram(row.original);
                                toggleModal();
                            }}>
                                <span>{t('ATTACH_SHOPS')}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible", width: '1%' }
        }
    ]

    /// <summary>
    /// Author : Andrew
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        await fetchMiniPrograms();
    }

    /// <summary>
    /// Author : Andrew
    /// </summary>      
    async function fetchMiniPrograms(data) {
        var apiUrl = ApiUrl._API_GET_MINI_PROGRAM_LIST;

        var responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setMiniProgramData(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function toggleModal() {
        if (isModalVisible) {
            setSelectedMiniProgram(null);
        }

        setIsModalVisible(!isModalVisible);
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function onSubmitAttachShop(attachedShops) {
        let params = {
            "miniProgramId": selectedMiniProgram.id,
            "shopIds": attachedShops.join(',')
        }
        
        var responseJson = await ApiEngine.post(ApiUrl._API_ATTACH_MINI_PROGRAM_SHOP, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            toggleModal();
            fetchMiniPrograms();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_MINI_PROGRAMS")}</h1>
                {
                    checkIfPermissionExist(AccessRight._MINI_PROGRAM_CONTROL_PERMISSION, true) &&
                    <NavigationButton history={_history} url={WebUrl._URL_ADMIN_MINI_PROGRAM_DETAIL} buttonText={t("ADD_NEW_MINI_PROGRAM")} />
                }
            </div>
            <Panel>
                <PanelBody>
                    <ReactTable data={miniProgramData} columns={_tableColumns} />
                </PanelBody>
            </Panel>
            <AttachShopModal
                isModalVisible={isModalVisible}
                title={selectedMiniProgram ? selectedMiniProgram.name : ''}
                getAttachedShopUrl={selectedMiniProgram ? ApiUrl._API_GET_MINI_PROGRAM_SHOP_BY_MINI_PROGRAM_ID + "?miniProgramId=" + selectedMiniProgram.id : null}
                onFinish={(attachedShops) => {
                    if (attachedShops) {
                        onSubmitAttachShop(attachedShops);
                    }
                    else {
                        toggleModal();
                    }
                }} />
        </div>
    )
}

export default ManageMiniProgram;