import React, { useMemo, useState, useEffect } from 'react';
import { Panel, PanelBody } from 'components/panel/panel.jsx';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ReactTable from 'components/custom/ReactTable';
import { useLocation, withRouter, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo } from "../../util/Util";
import { showResponseMessage, showMessage } from 'redux/AppAction.js';
import { AlertTypes, ApiKey, FilterStatus } from 'util/Constant.js';
import { ApiUrl, WebUrl } from '../../util/Constant';
import ApiEngine from '../../util/ApiEngine.js';
import { useTranslation } from 'react-i18next';
import PasswordConfirmationModal from "../../components/custom/PasswordConfirmationModal";

/// <summary>
/// Author: Chris
/// Editted: YJ (Change From Dummy UI to Functional UI)
/// </summary>
const ClaimBonus = () => {
    var _dispatch = useDispatch();
    var _location = useLocation();
    var _history = useHistory();
    const { t } = useTranslation();
    const [bonusData, setBonusData] = useState([]);
    const [username, setUsername] = useState('');
    const [memberId, setMemberId] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [selectedAction, setSelectedAction] = useState();

    /// <summary>
    /// Author: YJ
    /// </summary>
    useEffect(() => {
        if (!_location.state) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t("INVALID_ACCESS"),
                onConfirm: () => navigateTo(_history, WebUrl._URL_SHOP_SEARCH),
            }));
            return;
        }
        init();
    }, [_location.state])

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function init() {
        setUsername(_location.state.username);
        setMemberId(_location.state.id);
        await getMemberPrize();
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function getMemberPrize() {
        var getPrizeResponseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_PRIZE + "?id=" + _location.state.id + "&isPromotionId=false");
        if (getPrizeResponseJson[ApiKey._API_SUCCESS_KEY]) {
            setBonusData(getPrizeResponseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function performBonusAction() {
        var getPrizeResponseJson = await ApiEngine.post(ApiUrl._API_SET_MEMBER_PRIZE_STATUS + "?id=" + selectedId
            + "&status=" + selectedAction);
        
        if (getPrizeResponseJson[ApiKey._API_SUCCESS_KEY]) {
            await getMemberPrize();
        }
        setPasswordConfirmation(false);
        _dispatch(showResponseMessage(getPrizeResponseJson[ApiKey._API_SUCCESS_KEY], t(getPrizeResponseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    function passwordConfirmationCleanup() {
        setSelectedAction();
        setSelectedId('');
        setPasswordConfirmation(false);
    }

    return <>
        <h1 class="page-header">{t("CLAIM_CREDIT")}</h1>
        {passwordConfirmation &&
            <PasswordConfirmationModal
                memberId={memberId}
                onConfirm={() => { performBonusAction(); }}
                onCancel={() => { passwordConfirmationCleanup(); }} />
        }
        <Panel className="reload-panel">
            <PanelBody>
                <div className="form-group">
                    <div className="col-md-12"><label>{t("PHONE_NUMBER")}: {username}</label></div>
                </div>
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>{t("NO")}</th>
                            <th>{t("PROMOTION_NAME")}</th>
                            <th>{t("ENTITLED_AMOUNT")}</th>
                            <th>{t("STATUS")}</th>
                            <th>{t("CLAIMED_DATE")}</th>
                            <th>{t("PERFORMED_BY")}</th>
                            <th>{t("ACTION")}</th>
                        </tr>
                    </thead>
                    <tbody style={{ textAlign: "center" }}>
                        {
                            bonusData.map((bonus, index) => {
                                return (
                                    <tr>
                                        <td>{index}</td>
                                        <td>{bonus.promotionName}</td>
                                        <td>{bonus.prizeValue.toFixed(2)}</td>
                                        <td>{bonus.claimStatus ? 1 : 0}</td>
                                        <td>{bonus.claimTime}</td>
                                        <td>{bonus.performedByName}</td>
                                        <td>{!bonus.claimStatus && <div className="btn-group m-r-5 m-b-5">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle caret color="warning"><i className="fas fa-cog"></i></DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => {
                                                        setSelectedId(bonus.id);
                                                        setSelectedAction(true);
                                                        setPasswordConfirmation(true)
                                                    }}><i className="fas fa-check-circle"></i> {t("ACCEPT")}</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </PanelBody>
        </Panel>
    </>;
}

export default withRouter(ClaimBonus);