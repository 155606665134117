import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { SessionKey, Language } from "../../../util/Constant.js";
import Cookies from 'universal-cookie';

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
const DropdownLanguage = props => {
    const { t, i18n } = useTranslation();
    const [languageOptionVisibility, setLanguageOptionVisibility] = useState(false);
    var _cookies = new Cookies();
    const _LANGUAGE_OPTION = [
        {
            title: 'English',
            prefix: 'EN',
            value: Language._ENGLISH
        },
        {
            title: "简体中文",
            prefix: "中文",
            value: Language._CHINESE
        }
    ];

    const _LANGUAGE_MAPPING = {};
    _LANGUAGE_MAPPING[Language._ENGLISH] = "en";
    _LANGUAGE_MAPPING[Language._CHINESE] = "zh";
    _LANGUAGE_MAPPING[Language._BAHASA_MELAYU] = "ms";

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        onChangeLanguage((localStorage.getItem(SessionKey._LANGUAGE) ?? Language._ENGLISH));
    }, []);

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    function onChangeLanguage(selectedLanguage) {
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem(SessionKey._LANGUAGE, selectedLanguage);
        _cookies.set(SessionKey._LANGUAGE, _LANGUAGE_MAPPING[selectedLanguage]);
    }

		return (
            <Dropdown isOpen={languageOptionVisibility} toggle={() => setLanguageOptionVisibility(!languageOptionVisibility)} className="dropdown navbar-languager" tag="li">
                <DropdownToggle className="dropdown-toggle" tag="a">
                    <span className="las la-globe"></span>&nbsp;
                <span className="name d-sm-inline">{_LANGUAGE_OPTION.filter(i => i.value == (localStorage.getItem(SessionKey._LANGUAGE) ?? Language._ENGLISH))[0]?.prefix}</span>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
                {
                    _LANGUAGE_OPTION.map((language, index) => {
                        return <DropdownItem key={index} onClick={() => { onChangeLanguage(language.value) }}><span>{language.title}</span></DropdownItem>
                    })
                }
				</DropdownMenu>
			</Dropdown>
		);
};

export default DropdownLanguage;