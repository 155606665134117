import React, { useState, useMemo, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch } from "react-redux";
import { createMultiPartFormBody, stringIsNullOrEmpty } from '../../util/Util';
import { ApiUrl, ApiKey, Role, AlertTypes } from '../../util/Constant';
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import { showMessage, showResponseMessage } from "redux/AppAction";
import { useForm } from "react-hook-form";

/// <summary>
/// Author: CK
/// </summary>
const SetShopUpperLimitModal = ({ visible, shopLimitDetails, onFinish }) => {
    const _dispatch = useDispatch();
    const { t } = useTranslation();
    const { register, handleSubmit, setValue, errors } = useForm();
    const [currentLimit, setCurrentLimit] = useState();
    const [currentUpperLimit, setCurrentUpperLimit] = useState();

    /// <summary>
    /// Author: CK
    /// get the latest credit
    /// </summary>
    useEffect(() => {
        if (visible) {
            refreshUpperCreditLimit();
        }
    }, [visible])

    /// <summary>
    /// Author: CK
    /// </summary>
    const refreshUpperCreditLimit = async () => {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_ACCOUNT + "?id=" + shopLimitDetails.id);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setValue('currentLimit', responseJson[ApiKey._API_DATA_KEY].shopProfile.creditLimit);
            setValue('currentUpperCreditLimit', responseJson[ApiKey._API_DATA_KEY].shopProfile.shopUpperLimit);
            setCurrentLimit(responseJson[ApiKey._API_DATA_KEY].shopProfile.creditLimit);
            setCurrentUpperLimit(responseJson[ApiKey._API_DATA_KEY].shopProfile.shopUpperLimit);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const onSubmit = async (data) => {
        let apiUrl = ApiUrl._API_UPDATE_UPPER_SHOP_LIMIT + "?shopId=" + shopLimitDetails.id;

        if (!stringIsNullOrEmpty(data.upperCreditLimit)) {
            if (Number(currentLimit) > Number(data.upperCreditLimit)) {
                _dispatch(showResponseMessage(false, t("CREDIT_LIMIT_EXCEED_UPPER_LIMIT")));
                return;
            }

            apiUrl += "&shopUpperLimit=" + data.upperCreditLimit;
        }

        var responseJson = await ApiEngine.post(apiUrl);

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            onFinish(true);
        }
    }

    return (
        <Modal isOpen={visible} toggle={onFinish}>
            <ModalHeader>{t("SET_SHOP_UPPER_LIMIT_FOR")} {shopLimitDetails.userFullName}</ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group m-b-15 ">
                        <label>{t("CURRENT_CREDIT_LIMIT")}</label>
                        <div className="input-group">
                            <input
                                disabled
                                className="form-control form-control-lg m-b-5"
                                type="number"
                                name="currentLimit"
                                defaultValue={currentLimit}
                                ref={register} />
                            <div className="input-group-append">
                                <button type="button" className="btn btn-default no-caret" onClick={() => refreshUpperCreditLimit()}><i className="fas fa-sync-alt"></i>{t("REFRESH")}</button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group m-b-15 ">
                        <label>{t("CURRENT_UPPER_CREDIT_LIMIT")}</label>
                        <div className="input-group">
                            <input
                                disabled
                                className="form-control form-control-lg m-b-5"
                                type="number"
                                name="currentUpperCreditLimit"
                                defaultValue={currentUpperLimit}
                                ref={register} />
                            <div className="input-group-append">
                                <button type="button" className="btn btn-default no-caret" onClick={() => refreshUpperCreditLimit()}><i className="fas fa-sync-alt"></i>{t("REFRESH")}</button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group m-b-15 ">
                        <label>{t("UPPER_CREDIT_LIMIT")}</label>
                        <input
                            placeholder={t("ENTER_UPPER_CREDIT_LIMIT")}
                            className="form-control form-control-lg m-b-5"
                            type="number"
                            name="upperCreditLimit"
                            ref={register} />
                        <span className="f-s-14">{t("REMARK")}: {t("UPPER_LIMIT_INPUT_REMARK")}</span>
                        {errors.upperCreditLimit && <div className="invalid-feedback">{t(errors.upperCreditLimit.message)}</div>}
                    </div>
                    <div className="pull-right">
                        <button type="button" className="btn btn-default btn-action m-r-10 m-b-10" onClick={() => onFinish()}>{t('CLOSE')}</button>
                        <button type="submit" className="btn btn-primary btn-action m-b-10">{t('SUBMIT')}</button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};

export default SetShopUpperLimitModal;