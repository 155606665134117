import React, { useState, useEffect } from 'react';
import { getValidationMessage, isObjectEmpty } from '../../../util/Util';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DateTime from 'react-datetime';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { useLocation } from "react-router-dom";
import ApiEngine from "../../../util/ApiEngine";
import {
    WebUrl,
    ApiKey,
    ApiUrl,
    PrizeType,
    AlertTypes,
    EventStatus
} from "../../../util/Constant";
import { showResponseMessage, showMessage } from "../../../redux/AppAction";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

/// <summary>
/// Author: CK
/// </summary>
const KogWinner = props => {
    const { t, i18n } = useTranslation();
    const _dispatch = useDispatch();
    let { search } = useLocation();
    const _query = new URLSearchParams(search);
    const _EVENT_ID = _query.get('eventId');
    var _userId = useSelector(state => state['authState']['userData']['userId']);
    const [prizeData, setPrizeData] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState([]);

    let _prizeTableColumns = [
        {
            Header: "RANK",
            accessor: "rankPosition",
            disableSortBy: true
        },
        {
            Header: "MEMBER",
            accessor: "username",
            disableSortBy: true
        },
        {
            Header: "SCORE",
            accessor: "winAmount",
            disableSortBy: true
        },
        {
            Header: "PRIZE_NAME",
            accessor: "prizeName",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{row.original.eventPrize.prizeName}</div>
            }
        },
        {
            Header: "WORTH_AMOUNT",
            accessor: "amount",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{row.original.eventPrize.amount}</div>
            }
        },
        {
            Header: "PRIZE_IMAGE",
            accessor: "logo",
            disableSortBy: true,
            Cell: ({ row }) => {
                return row.original.eventPrize.logo ? <img class="mini-program-logo" src={row.original.eventPrize.logo} /> : <div></div>
            }
        },
        {
            Header: "PRIZE_DISTRIBUTION",
            disableSortBy: true,
            Cell: ({ row }) => {
                let returnClassName = "badge badge-warning";
                let returnString = "PENDING";

                if (row.original.isClaimed) {
                    returnClassName = "badge badge-success";
                    returnString = "DISTRIBUTED";
                }

                return <span className={returnClassName} style={{ textTransform: "uppercase" }}>{t(returnString)}</span>
            }
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    {
                        !row.original.isClaimed && selectedEvent?.status == EventStatus._END &&
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => {
                                    _dispatch(showMessage({
                                        content: t('ARE_YOU_SURE_YOU_WANT_TO') + t('DISTRIBUTE_PRIZE_FOR') + " " + row.original.username + "?",
                                        showCancel: true,
                                        required: true,
                                        inputType: 'password',
                                        validationMsg: t('INVALID_PASSWORD'),
                                        confirmBtnText: t("CONFIRM"),
                                        type: AlertTypes._INPUT,
                                        onConfirm: async (value) => {
                                            await verifyPassword(value, row.original);
                                        }
                                    }));
                                }}>
                                    <span className="text-warning">{t('DISTRIBUTE')}</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    }
                </div>
            ),
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ];

    /// <summary>
    /// Author: CK
    /// </summary> 
    useEffect(() => {
        getEvent();
    }, []);

    /// <summary>
    /// Author : CK
    /// </summary>
    async function getEvent() {
        var responseJson = await ApiEngine.get(ApiUrl._API_EVENT + "/" + _EVENT_ID);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setSelectedEvent(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary> 
    useEffect(() => {
        (async () => {
            if (selectedEvent != null) {
                getSelectectEventPrizeData();
            }
            else {
                setPrizeData([]);
            }
        })();
    }, [selectedEvent]);

    /// <summary>
    /// Author: CK
    /// </summary> 
    async function getSelectectEventPrizeData() {
        let apiUrl = ApiUrl._API_KOG_PRIZE_OWNER + "?eventId=" + selectedEvent.id;

        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setPrizeData(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    // <summary>
    /// Author: CK
    /// </summary>
    async function verifyPassword(password, rowData = {}) {
        let isSuccess = true;
        let msg = '';

        var responseJson = await ApiEngine.post(`${ApiUrl._API_VERIFY_PASSWORD}?userId=${_userId}&password=${password}`);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (!isObjectEmpty(rowData)) {
                var singleDistribute = await ApiEngine.post(`${ApiUrl._API_SINGLE_DISTRIBUTE}?eventId=${rowData.eventId}&memberId=${rowData.memberId}`);

                if (!singleDistribute[ApiKey._API_SUCCESS_KEY]) {
                    isSuccess = false;
                }

                msg = singleDistribute[ApiKey._API_MESSAGE_KEY];
            }
            else {
                var distributePrizeResponseJson = await ApiEngine.post(`${ApiUrl._API_DISTRIBUTE_PRIZE}?eventId=${selectedEvent.id}`);

                if (!distributePrizeResponseJson[ApiKey._API_SUCCESS_KEY]) {
                    isSuccess = false;
                }

                msg = distributePrizeResponseJson[ApiKey._API_MESSAGE_KEY];
            }
        }
        else {
            isSuccess = false;
            msg = responseJson[ApiKey._API_MESSAGE_KEY];
        }

        _dispatch(showMessage({
            type: isSuccess ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(msg),
            onConfirm: () => {
                if (isSuccess && !isObjectEmpty(rowData)) {
                    getSelectectEventPrizeData();
                }
            }
        }));
    }

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                {t("PRIZE_WINNER") + ' (' + selectedEvent?.name + ' ' + moment(selectedEvent?.startDate).format("YYYY-MM-DD") + ' - ' +  moment(selectedEvent?.endDate).format("YYYY-MM-DD") + ')'}
            </div>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <button className="btn btn-success btn-action" onClick={() => { getSelectectEventPrizeData(); }}>{t("REFRESH")}</button>
                            <ReactTable data={prizeData} columns={_prizeTableColumns} />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div>
    );
}

export default KogWinner;