import React, { useState, useEffect } from 'react';
import { ApiKey, ApiUrl, Role } from '../../util/Constant';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getValidationMessage, isObjectEmpty } from '../../util/Util';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import moment from 'moment';
import DateTime from 'react-datetime';
import ReactTable from "./ReactTable";

/// <summary>
/// Author: YJ
/// </summary>
const GameLogModal = props => {
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, errors, unregister, setValue, watch } = useForm();
    const [player, setPlayer] = useState('');
    const [gameLogUrl, setGameLogUrl] = useState("");

    let _scoreLogTableColumns = [
        {
            Header: t("CREATED_DATE"),
            accessor: "createdTime",
            disableSortBy: true,
        },
        {
            Header: t("GAME"),
            accessor: "gameString",
            disableSortBy: true,
        },
        {
            Header: t("BET"),
            accessor: "ticketAmount",
            Cell: ({ row }) => {
                return <span>{parseFloat(row.original.ticketAmount).toFixed(2)}</span>;
            },
            Footer: ({ page }) => {
                const betTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.ticketAmount);
                }, 0);

                return <span><b>{betTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true,
        },
        {
            Header: t("WIN"),
            accessor: "winAmount",
            Cell: ({ row }) => {
                return <span>{parseFloat(row.original.winAmount).toFixed(2)}</span>;
            },
            Footer: ({ page }) => {
                const winTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.winAmount);
                }, 0);

                return <span><b>{winTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true,
        },
        {
            Header: t("WIN_LOSE"),
            accessor: "",
            Cell: ({ row }) => {
                let winLose = row.original.winAmount - row.original.ticketAmount;
                return <span className={winLose == 0 ? "text-default" : winLose > 0 ? "text-success" : "text-danger"}>{parseFloat(winLose).toFixed(2)}</span>;
            },
            Footer: ({ page }) => {
                const pageTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.winAmount) - parseFloat(currentValue.original.ticketAmount);
                }, 0);

                return <span className={pageTotal == 0 ? "text-default" : pageTotal > 0 ? "text-success" : "text-danger"}><b>{pageTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true,
        }
    ];

    /// <summary>
    /// Author: YJ
    /// </summary>
    useEffect(() => {
        if (props.isModalVisible) {
            register({ name: 'startDate' }, { required: true });
            register({ name: 'endDate' }, { required: true });
            setPlayer(props.player)
            setValue('startDate', moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"));
            setValue('endDate', moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"));
        }
        else {
            unregister('startDate');
            unregister('endDate');
            setPlayer(null);
            setGameLogUrl("");
        }
    }, [props.isModalVisible]);

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function onSubmit() {
        setGameLogUrl(ApiUrl._API_GET_GAME_LOG + "?playerUsername=" + player.username + "&Code=" +
            player.vendorCode + "&ShopId=" + player.shopId + "&StartDate=" + watch("startDate") + "&EndDate=" + watch("endDate") + "&v=" + Date.now());
    }

    return (
        <Modal isOpen={props.isModalVisible} toggle={() => { props.onFinish() }} size="lg">
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={() => { props.onFinish() }}><h4>{t("GAME_LOG")}</h4></ModalHeader>
                <ModalBody>
                    <div className="row m-b-10">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("START_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="startDate"
                                    timeFormat={"HH:mm:ss"}
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={watch("startDate")}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("startDate", "");
                                        }
                                    }} />
                                {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("END_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="endDate"
                                    timeFormat={"HH:mm:ss"}
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={watch("endDate")}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("endDate", "");
                                        }
                                    }} />
                                {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                            </div>
                        </div>
                    </div>
                    <ReactTable fetchUrl={gameLogUrl} columns={_scoreLogTableColumns} renderFooter={true} pageMultiply={false} />
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-primary btn-action">{t("BTN_SUBMIT")}</button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default GameLogModal;