import React, { useState, useEffect } from 'react';
import { Panel, PanelBody } from '../../components/panel/panel';
import { showResponseMessage, showMessage } from '../../redux/AppAction';
import * as Util from '../../util/Util';
import { useTranslation } from 'react-i18next';
import { ApiKey, ApiUrl, AlertTypes, Status } from '../../util/Constant';
import ApiEngine from '../../util/ApiEngine';
import { useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import AccountStatusModal from '../../components/custom/AccountStatusModal';

const _BLOCK_ACTION = "BLOCK_ACTION";
const _DELETE_ACTION = "DELETE_ACTION";

/// <summary>
/// Author: CK
/// </summary>
const BlockPuk = props => {
    let _dispatch = useDispatch();
    var _location = useLocation();
    var _userData = useSelector(state => state.authState.userData);
    const { t } = useTranslation();
    const { register, handleSubmit, errors, reset } = useForm();
    const [memberData, setMemberData] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [playerList, setPlayerList] = useState([]);
    const [accountDetail, setAccountDetail] = useState({});

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        getMemberData();
    }, []);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (selectedVendor) {
            setPlayerList(selectedVendor.players);
        }
    }, [selectedVendor]);

    /// <summary>
    /// Author: CK
    /// </summary>
    async function getMemberData() {
        let apiUrl = ApiUrl._API_RETRIEVE_RELOAD_DETAILS + "?username=" + (_location.state != undefined ? _location.state.username : "");

        if (_userData.isCustomerService) {
            apiUrl += "&memberId=" + _location.state.id;
        }

        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (responseJson[ApiKey._API_DATA_KEY].length) {
                setMemberData(responseJson[ApiKey._API_DATA_KEY]);
                if (selectedVendor == null) {
                    setSelectedVendor(responseJson[ApiKey._API_DATA_KEY][0]);
                }
                else {
                    setPlayerList(responseJson[ApiKey._API_DATA_KEY].filter(vendor => vendor.vendor.id == selectedVendor.vendor.id)[0].players);
                }
            }
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    function submitBlockForm(data, action) {
        let selectedStatus = playerList.filter(player => player.username == data.playerUsername)[0].status;
        let selectedRemark = playerList.filter(player => player.username == data.playerUsername)[0].remark;

        setAccountDetail({
            vendorCode: data.vendorCode,
            playerUsername: data.playerUsername,
            selectedStatus: selectedStatus,
            fromStatus: selectedStatus ? Status._ENABLED : Status._DISABLED,
            toStatus: action == _BLOCK_ACTION ? (!selectedStatus ? Status._ENABLED : Status._DISABLED) : Status._DELETED,
            action: action,
            remark: selectedRemark
        })
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function playerOperation(selectedVendor, selectedUsername, selectedStatus, action, remark = "") {
        let apiUrl = "";

        if (action == _BLOCK_ACTION) {
            apiUrl = ApiUrl._API_SHOP_SET_PLAYER_STATUS + "?code=" + selectedVendor + "&playerUsername=" + selectedUsername + "&status=" + (selectedStatus ? "false" : "true") + "&remark=" + remark;
        }
        else if (action == _DELETE_ACTION) {
            apiUrl = ApiUrl._API_SHOP_DELETE_PLAYER + "?code=" + selectedVendor + "&playerUsername=" + selectedUsername + "&remark=" + remark;
        }
        else {
            return;
        }

        var responseJson = await ApiEngine.post(apiUrl);
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            getMemberData();
        }
    }

    return (
        <div id="page-content">
            <h1 className="page-header">{t('BLOCK_DELETE_PUK')}</h1>
            <AccountStatusModal
                visible={!Util.isObjectEmpty(accountDetail)}
                detail={accountDetail}
                onFinish={(isConfirm, remark) => {
                    if (isConfirm) {
                        playerOperation(accountDetail.vendorCode, accountDetail.playerUsername, accountDetail.selectedStatus,
                            accountDetail.action, remark)
                    }
                    setAccountDetail({});
                }} />
            <Panel>
                <PanelBody>
                    <form>
                        <div className="form-group">
                            <div className="row">
                                <label className="control-label col-md-2 d-flex align-items-center">{t('PROVIDER')}</label>
                                <div className="col-md-10">
                                    <select className="form-control form-control-lg" data-val="true"
                                        id="vendorCode"
                                        name="vendorCode"
                                        onChange={(event) => {
                                            setSelectedVendor(memberData.filter(data => data.vendor.code == event.target.value)[0]);
                                        }}
                                        ref={register({ required: true })}>
                                        {
                                            memberData.map(data => <option value={data.vendor.code}>{data.vendor.name}</option>)
                                        }
                                    </select>
                                    {errors["vendorCode"] && <div className="invalid-feedback">{t(Util.getValidationMessage(errors["vendorCode"]))}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <label className="control-label col-md-2 d-flex align-items-center">{t('NUMBER')}</label>
                                <div className="col-md-10">
                                    <select className="form-control form-control-lg"
                                        id="playerUsername"
                                        name="playerUsername"
                                        ref={register({ required: true })}>
                                        {
                                            playerList.map(player => <option value={player.username}>{player.displayUsername}</option>)
                                        }
                                    </select>
                                    {errors["playerUsername"] && <div className="invalid-feedback">{t(Util.getValidationMessage(errors["playerUsername"]))}</div>}
                                </div>
                            </div>
                        </div>
                        <button type="button" id="btn-block" className="btn btn-primary btn-action m-r-5"
                            onClick={handleSubmit((data) => submitBlockForm(data, _BLOCK_ACTION))}>{t('BLOCK')}</button>
                        <button type="button" id="btn-delete" className="btn btn-primary btn-action"
                            onClick={handleSubmit((data) => submitBlockForm(data, _DELETE_ACTION))}>{t('DELETE')}</button>
                    </form>
                </PanelBody>
            </Panel>
        </div>
    );
}

export default BlockPuk;