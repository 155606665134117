import React, { useState, useEffect } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import NavigationButton from '../../../components/custom/NavigationButton';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import { useHistory, useLocation } from "react-router-dom";
import { WebUrl, SettingType, ApiKey, ApiUrl, LanguageKey, AlertTypes } from '../../../util/Constant';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { showResponseMessage, showMessage } from "../../../redux/AppAction";
import { createMultiPartFormBody, stringIsNullOrEmpty, navigateTo } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';
import { useTranslation } from 'react-i18next';

/// <summary>
/// Author: Wind
/// CK - Fix create/update sms setting type 
/// </summary> 
const SmsDetails = props => {
    const { t, i18n } = useTranslation();
    let _history = useHistory();
    var _dispatch = useDispatch();
    var _location = useLocation();
    const { register, handleSubmit, errors, setValue, unregister } = useForm();
    const [settingName, setSettingName] = useState('');
    const [tagsList, setTagsList] = useState([]);
    const [selectedTagsValue, setSelectedTagsValue] = useState('');
    const [settingValue, setSettingValue] = useState('');
    const [smsDetailId, setSmsDetailId] = useState('');
    const [isApp, setIsApp] = useState(false);
    const [isSms, setIsSms] = useState(false);
    const [supportFcmAndSms, setSupportFcmAndSms] = useState(false);
    const [description, setDescription] = useState('');
    const [typeId, setTypeId] = useState('');
    const [smsTypeOption, setSmsTypeOption] = useState([]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        register({ name: 'typeId' }, { required: "PLEASE_SELECT_CATEGORY" });
        getSmsDetails();
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function getSmsDetails() {
        setTagsList([
            { label: "Phone Number", value: "{phonenumber}" },
            { label: "Player Id", value: "{playerid}" },
            { label: "Date Time", value: "{datetime}" },
            { label: "Score", value: "{score}" },
            { label: "LoginUrl", value: "{loginUrl}" },
            { label: "Game Password", value: "{gamepassword}" },
            { label: "Pin", value: "{pin}" },
            { label: "Reward", value: "{reward}" },
            { label: "Bonus", value: "{bonus}" },
            { label: "Shop Name", value: "{shopname}" },
            { label: "Vendor Name", value: "{vendorname}" },
            { label: "Alternate Phone", value: "{alternatephone}" },
            { label: "Department Name", value: "{departmentname}" },
            { label: "WhatsApp Link", value: "{whatsapplink}" },
            { label: "Count", value: "{count}" }
        ]);

        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SMS_SETTING_TYPE);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var smsTypeList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (smsType) {
                smsTypeList.push({ label: smsType.name, value: smsType.id });
            });

            setSmsTypeOption(smsTypeList);
        }

        if (_location.state) {
            var settingJson = await ApiEngine.get(ApiUrl._API_GET_SMS_SETTING_BY_ID+"?id="+_location.state.id);

            if (settingJson[ApiKey._API_SUCCESS_KEY]) {
                setSmsDetailId(settingJson[ApiKey._API_DATA_KEY].id);
                setSettingName(settingJson[ApiKey._API_DATA_KEY].name);
                setDescription(settingJson[ApiKey._API_DATA_KEY].description);
                setSettingValue(settingJson[ApiKey._API_DATA_KEY].value);
                setIsApp(settingJson[ApiKey._API_DATA_KEY].isApp);
                setIsSms(settingJson[ApiKey._API_DATA_KEY].isSms);
                setSupportFcmAndSms(settingJson[ApiKey._API_DATA_KEY].supportFcmAndSms);
                setTypeId(settingJson[ApiKey._API_DATA_KEY].smsSettingTypeId);
                setValue('typeId', settingJson[ApiKey._API_DATA_KEY].smsSettingTypeId);
            }
        }
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    const submitSmsDetailsForm = async (data, e) => {
        let formCollection = {
            name: data.settingName,
            value: data.settingValue,
            description: data.settingName,
            smsSettingTypeId: typeId,
            isApp: isApp,
            isSms: isSms,
            supportFcmAndSms: supportFcmAndSms
        };

        if (!stringIsNullOrEmpty(smsDetailId)) {
            formCollection.id = smsDetailId;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_SMS_SETTING, createMultiPartFormBody(formCollection));

        _dispatch(showMessage({ 
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR, 
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]), 
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_SMS_SETTINGS) 
        }));
    }

    return (
        <div>
            <h1 className="page-header">{_location.state ? t("EDIT_SMS_TEMPLATE") : t("CREATE_SMS_TEMPLATE")}<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitSmsDetailsForm)}>
                                <div className="row m-b-10">
                                    <label className="col-md-3 col-form-label"><b>{t("CATEGORY")}</b></label>
                                    <div className="col-md-4">
                                        <Select name="typeId" options={smsTypeOption}
                                            placeholder={(smsTypeOption.filter(option => option.value == typeId)[0] !== undefined) ? (smsTypeOption.filter(option => option.value == typeId)[0].label) : ""}
                                            value={smsTypeOption.filter(option => option.value == typeId)}
                                            onChange={(e) => {
                                                setValue('typeId', e.value);
                                                setTypeId(e.value);
                                            }} />
                                        {errors.typeId && <div className="invalid-feedback">{t(errors.typeId.message)}</div>}
                                    </div>
                                </div>
                                <div className="row m-b-10">
                                    <label className="col-md-3 col-form-label"><b>{t("SETTING_NAME")}</b></label>
                                    <div className="col-md-4">
                                        <input type="text" name="settingName"
                                            defaultValue={settingName}
                                            ref={register({ required: "PLEASE_ENTER_NAME" })}
                                            className="form-control form-control-lg" placeholder={t("ENTER_SETTING_NAME")} />
                                        {errors.settingName && <div className="invalid-feedback">{t(errors.settingName.message)}</div>}
                                    </div>
                                </div>
                                <div className="row m-b-10">
                                    <label className="col-md-3 col-form-label"><b>{t("SETTING_DESCRIPTION")}</b></label>
                                    <div className="col-md-4">
                                        <input type="text" name="settingDesciption"
                                            defaultValue={description}
                                            ref={register({ required: "PLEASE_ENTER_DESCRIPTION" })}
                                            className="form-control form-control-lg" placeholder={t("ENTER_SETTING_DESCRIPTION")} />
                                        {errors.settingDesciption && <div className="invalid-feedback">{t(errors.settingDesciption.message)}</div>}
                                    </div>
                                </div>
                                <div className="row m-b-10">
                                    <label className="col-md-3 col-form-label"><b>{t("SELECTABLE_TAGS")}</b></label>
                                    <div className="col-md-4">
                                        <Select options={tagsList} value={tagsList.filter(option => option.value == selectedTagsValue)}
                                            onChange={(e) => { setValue('selectedTagsValue', e.value); setSelectedTagsValue(e.value); }} />
                                    </div>
                                </div>
                                <div className="row m-b-10">
                                    <label className="col-md-3 col-form-label"></label>
                                    <div className="col-md-5">
                                        <button type="button" className="btn btn-primary" onClick={() => { setSettingValue(settingValue + selectedTagsValue); }}>{t("INSERT_TAG")}</button>
                                    </div>
                                </div>
                                <div className="row m-b-10">
                                    <label className="col-md-3 col-form-label"><b>{t("SUBJECT")}</b></label>
                                    <div className="col-md-5">
                                        <textarea
                                            className="form-control form-control-lg"
                                            rows="8"
                                            name="settingValue"
                                            ref={register({ required: "PLEASE_ENTER_SUBJECT" })}
                                            placeholder={t("ENTER_SMS_TEMPLATE_HERE")}
                                            value={settingValue}
                                            onChange={(e) => { setSettingValue(e.target.value) }} />
                                        {errors.settingValue && <div className="invalid-feedback">{t(errors.settingValue.message)}</div>}
                                    </div>
                                </div>
                                <div className="row m-b-10">
                                    <label className="col-md-3 col-form-label"><b>{t("FOR_APP")}</b></label>
                                    <div className="col-md-5">
                                        <div className="switcher">
                                            <input type="checkbox" name="isApp" id="isApp"
                                                onChange={(e) => setIsApp(e.target.checked)}
                                                value={true}
                                                checked={isApp}
                                                ref={register} />
                                            <label htmlFor="isApp"></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-b-10">
                                    <label className="col-md-3 col-form-label"><b>{t("FOR_SMS")}</b></label>
                                    <div className="col-md-5">
                                        <div className="switcher">
                                            <input type="checkbox" name="isSms" id="isSms"
                                                onChange={(e) => setIsSms(e.target.checked)}
                                                value={true}
                                                checked={isSms}
                                                ref={register} />
                                            <label htmlFor="isSms"></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-b-10">
                                    <label className="col-md-3 col-form-label"><b>{t("SUPPORT_ALL_MESSAGING")}</b></label>
                                    <div className="col-md-5">
                                        <div className="switcher">
                                            <input type="checkbox" name="supportFcmAndSms" id="supportFcmAndSms"
                                                onChange={(e) => setSupportFcmAndSms(e.target.checked)}
                                                value={true}
                                                checked={supportFcmAndSms}
                                                ref={register} />
                                            <label htmlFor="supportFcmAndSms"></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-b-10">
                                    <div className="col-md-3">
                                        <button type="submit" className="btn btn-primary">{t("SUBMIT")}</button>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    )
}

export default SmsDetails;