import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { navigateTo, createFormBody } from "../../../util/Util";
import NavigationButton from '../../../components/custom/NavigationButton';
import { ApiKey, WebUrl, ApiUrl, AccessRight, AnnouncementType, ProcessedAnnouncementStatus, AlertTypes } from "../../../util/Constant";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage, showMessage } from "../../../redux/AppAction";
import ApiEngine from '../../../util/ApiEngine.js';

/// <summary>
/// Author : Wind
/// </summary> 
const ManageAppAnnoucement = props => { 
    var _dispatch = useDispatch();
    let _history = useHistory();
    const { t } = useTranslation();
    const { register, handleSubmit } = useForm();
    const [annoucementData, setAnnoucementData] = useState([]);
    const [selectedAnnoucement, setSelectedAnnoucement] = useState();
    const [eventTypeModalVisible, setEventTypeModalVisible] = useState(false);
    const [eventTypeList, setEventTypeList] = useState([]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    let _announcementColumns = useMemo(() => [
        {
            Header: "START_DATE",
            accessor: "startDate",
            disableSortBy: true
        },
        {
            Header: "END_DATE",
            accessor: "endDate",
            disableSortBy: true
        },
        {
            Header: "TITLE",
            accessor: "title",
            disableSortBy: true
        },
        {
            Header: "MESSAGE",
            accessor: "localizations",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{row.original.localizations.map((item) => { return item.value }).join(" / ")}</div>
            }
        },
        {
            Header: "INTERVAL",
            accessor: "interval",
            disableSortBy: true
        },
        {
            Header: "TYPE",
            accessor: "announcementEventTypeId",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{row.original.announcementEventType.eventName}</div>;
            }
        },
        {
            Header: "STATUS",
            accessor: "isEnabled",
            disableSortBy: true,
            Cell: ({ row }) => {
                return row.original.isEnabled ? <span className="badge badge-green">{t("ACTIVE")}</span> : <span className="badge badge-danger">{t("DISABLE")}</span>;
            }
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._ANNOUNCEMENT_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                _history.push({
                                    pathname: WebUrl._URL_ADMIN_MANAGE_APP_ANNOUNCEMENT_DETAIL,
                                    state: { id: row.original.id }
                                })
                            }}>
                                <span>{t('EDIT')}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                onEditStatus(row.original.id)
                            }}>
                                <span>{row.original.isEnabled ? t("DISABLE") : t("ACTIVATE")}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ], [annoucementData]);

    /// <summary>
    /// Author: Wind
    /// </summary> 
    useEffect(() => {
        getAnnoucementList();
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        (async () => {
            if (eventTypeModalVisible) {
                var responseJson = await ApiEngine.get(ApiUrl._API_GET_ANNOUNCEMENT_TYPE);

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setEventTypeList(responseJson[ApiKey._API_DATA_KEY]);
                }
                else {
                    _dispatch(showMessage({
                        type: AlertTypes._ERROR,
                        content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
                        onConfirm: () => setEventTypeModalVisible(!eventTypeModalVisible)
                    }));
                }
            }
        })();
    }, [eventTypeModalVisible]);

    /// <summary>
    /// Author: Wind
    /// </summary> 
    async function getAnnoucementList() {
        var responseJson = await ApiEngine.get(ApiUrl._API_ANNOUNCEMENT_EVENT);
        
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setAnnoucementData(responseJson[ApiKey._API_DATA_KEY].data);
        }
        else {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
                onConfirm: () => { }
            }));
        }
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function onEditStatus(id) {
        var responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_ANNOUNCEMENT_EVENT_STATUS.replace('{id}', id));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && getAnnoucementList()
        }));
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    const submitEventTypeForm = async (data, e) => {
        let params = Object.keys(data.event).map(item => ({ id: parseInt(item), isEnabled: data['event'][item] }));

        var responseJson = await ApiEngine.put(ApiUrl._API_UPDATE_ANNOUNCEMENT_EVENT_TYPE_STATUS, params);

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => {
                setEventTypeModalVisible(!eventTypeModalVisible);
                setEventTypeList([]);
            }
        }));
    }

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_APP_ANNOUCEMENT")}</h1>
                {
                    checkIfPermissionExist(AccessRight._ANNOUNCEMENT_EVENT_CONTROL, true) &&
                    <div>
                        <button className="btn btn-primary" onClick={() => { setEventTypeModalVisible(!eventTypeModalVisible); }}>{t("EDIT_EVENT_TYPE")}</button>
                        &nbsp; &nbsp; &nbsp; 
                        <NavigationButton history={_history} url={WebUrl._URL_ADMIN_MANAGE_APP_ANNOUNCEMENT_DETAIL} buttonText={t("ADD_ANNOUCEMENT")} />
                    </div>
                }
            </div>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <ReactTable data={annoucementData} columns={_announcementColumns} />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <Modal isOpen={eventTypeModalVisible}>
                <form onSubmit={handleSubmit(submitEventTypeForm)}>
                    <ModalHeader>
                        <h4>{t('EDIT_ANNOUNCEMENT_EVENT_TYPE')}</h4>
                    </ModalHeader>
                    <ModalBody>
                        {
                            eventTypeList && eventTypeList.map((item) => {
                                return (
                                    <div className="form-group">
                                        <div className="row" style={{ alignItems: "center" }}>
                                            <div className="col-md-6 col-form-label">
                                                <b>{item.eventName}</b>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="switcher">
                                                    <input type="checkbox"
                                                        id={"event[" + item.id + "]"}
                                                        name={"event[" + item.id + "]"}
                                                        defaultChecked={item.isEnabled}
                                                        ref={register} />
                                                    <label htmlFor={"event[" + item.id + "]"}></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-primary">{t('BTN_SAVE')}</button>
                        <button type="button" className="btn btn-danger"
                            onClick={() => { setEventTypeModalVisible(!eventTypeModalVisible); }}>{t('BTN_CANCEL')}</button>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    );
}

export default ManageAppAnnoucement;