import React, { useState, useEffect, useMemo } from "react";
import { Panel, PanelBody } from "../../../components/panel/panel";
import { WebUrl, ApiKey, ApiUrl, AccessRight } from "../../../util/Constant";
import ReactTable from "../../../components/custom/ReactTable";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import ApiEngine from "../../../util/ApiEngine.js";
import { useTranslation } from "react-i18next";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../../components/custom/NavigationButton";

/// <summary>
/// Author: JavaChips
/// </summary>
const ManageThirdParty = () => {
	const { t } = useTranslation();
	var _history = useHistory();
	const [thirdParties, setthirdParties] = useState([]);

	/// <summary>
	/// Author: JavaChips
	/// </summary>
	let _thirdPartyColumns = useMemo(
		() => [
			{
				Header: "CODE",
				accessor: "code",
			},
			{
				Header: "NAME",
				accessor: "name",
			},
			{
				Header: "Base URL",
				accessor: "baseUrl",
			},
			{
				Header: "H5 URL",
				accessor: "h5Url",
			},
			{
				Header: "STATUS",
				accessor: "status",
				Cell: ({ row }) => {
					return row.original.status ? <span className="badge badge-green">{t("ENABLE")}</span> : <span className="badge badge-danger">{t("DISABLE")}</span>;
				},
			},
			{
				Header: "ACTION",
				Cell: ({ row }) =>
					checkIfPermissionExist(AccessRight._GAME_VENDOR_CONTROL_PERMISSION, true) && (
						<div className="btn-group m-r-5 m-b-5">
							<UncontrolledButtonDropdown>
								<DropdownToggle caret color="default">
									<i className="fas fa-cog"></i>
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem
										onClick={() => {
											_history.push({
												pathname: WebUrl._URL_THIRD_PARTY_DETAIL,
												state: { id: row.original.id },
											});
										}}
									>
										<span>{t("EDIT_THIRD_PARTY_DETAIL")}</span>
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledButtonDropdown>
						</div>
					),
				disableSortBy: true,
				width: 100,
				style: { overflow: "visible" },
			},
		],
		[thirdParties]
	);

	/// <summary>
	/// Author: JavaChips
	/// </summary>
	useEffect(() => {
		getThirdParties();
	}, []);

	/// <summary>
	/// Author: JavaChips
	/// </summary>
	async function getThirdParties() {
		var responseJson = await ApiEngine.get(ApiUrl._API_GET_THIRD_PARTY);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setthirdParties(responseJson[ApiKey._API_DATA_KEY]?.data);
		}
	}

	return (
		<div id="page-content">
			<div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
				<h1 className="page-header">{t("MANAGE_THIRD_PARTY")}</h1>
				{checkIfPermissionExist(AccessRight._GAME_VENDOR_CONTROL_PERMISSION, true) && <NavigationButton history={_history} url={WebUrl._URL_THIRD_PARTY_DETAIL} buttonText={t("CREATE_THIRD_PARTY")} />}
			</div>
			<Panel>
				<PanelBody>
					<ReactTable data={thirdParties} columns={_thirdPartyColumns} filterable={true} />
				</PanelBody>
			</Panel>
		</div>
	);
};

export default ManageThirdParty;
