import React from 'react';
import { connect } from "react-redux";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { PageSettings } from './../../config/page-settings.js';
import SidebarProfile from './sidebar-profile.jsx';
import SidebarNav from './sidebar-nav.jsx';
import { Role } from "../../util/Constant";

class Sidebar extends React.Component {
	static contextType = PageSettings;
	
	render() {
		return (
			<PageSettings.Consumer>
				{({toggleSidebarMinify, toggleMobileSidebar, pageSidebarTransparent}) => (
					<React.Fragment>
						<div id="sidebar" className={'sidebar ' + (pageSidebarTransparent ? 'sidebar-transparent' : '')}>
                            <PerfectScrollbar className="height-full" options={{ suppressScrollX: true }}>
                                {!this.context.pageSidebarSearch && this.props.authState.userData.userRoleId != Role._SHOP && (
								<SidebarProfile />
								)}
								<SidebarNav />
							</PerfectScrollbar>
						</div>
						<div className="sidebar-bg"></div>
						<div className="sidebar-mobile-dismiss" onClick={toggleMobileSidebar}></div>
					</React.Fragment>
				)}
			</PageSettings.Consumer>
		)
	}
}
const mapStateToProps = state => ({
    ...state
});

export default connect(
    mapStateToProps,
    null
)(Sidebar);