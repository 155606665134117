import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import moment from 'moment';
import { Panel, PanelBody } from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonGroup, Button } from 'reactstrap';
import ReactTable from "../../../components/custom/ReactTable";
import { setBusy, setIdle, showResponseMessage } from '../../../redux/AppAction';
import { ApiKey, ApiUrl, Role, Status, AlertTypes, TransactionType, ReportType } from "../../../util/Constant";
import { stringIsNullOrEmpty, arrayGroupByKey } from "../../../util/Util";
import { useForm } from "react-hook-form";
import ApiEngine from '../../../util/ApiEngine.js';
import { useDispatch } from "react-redux";
import RegionFilterDualListBox from "../../../components/custom/RegionFilterDualListBox";
import DualListBoxItemRow from "components/custom/DualListBoxItemRow.js";

/// <summary>
/// Author : CK
/// </summary>
const SetScoreReport = props => {
    let _dispatch = useDispatch();
    let { search } = useLocation();
    const _query = new URLSearchParams(search);
    const [postData, setPostData] = useState({});
    const { register, handleSubmit, setValue } = useForm();
    const { t } = useTranslation();
    const [apiUrl, setApiUrl] = useState('');
    const [shopOption, setShopOption] = useState([]);
    const [selectedOperatedBy, setSelectedOperatatedBy] = useState([]);
    const [selectedShop, setSelectedShop] = useState([]);
    const [vendorOption, setVendorOption] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [reportRole, setReportRole] = useState(ReportType._SHOP);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [regions, setRegions] = useState([]);

    const _TYPE_OPTION = {
        ALL: -1,
        IN: 0,
        OUT: 1,
    };

    const _TRANSACTION_TYPE_ID = {
        BONUS: 1,
        REWARD: 2,
        SET_LIMIT: 3,
        SET_SCORE: 4,
        WALLET: 5,
        SEAMLESS_SET_CREDIT: 10,
        ONLINE_WITHDRAWAL: 11,
        PASSWORD_RESET: 15
    };

    let _tableColumns = [
        {
            Header: "No",
            id: "row",
            filterable: false,
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            disableSortBy: true
        },
        {
            Header: "DATE",
            accessor: "createdTime",
            Footer: () => {
                return <span style={{ float: 'right' }}><b>TOTAL: </b></span>
            },
            disableSortBy: true,
        },
        {
            Header: "OPERATED_BY",
            accessor: "operatedBy",
            disableSortBy: true,
        },
        {
            Header: reportRole == ReportType._SHOP ? "OPERATED_SHOP" : "AGENT",
            accessor: "operatedShop",
            disableSortBy: true,
        },
        {
            Header: "PHONE_NUMBER",
            accessor: "phoneNumber",
            disableSortBy: true,
            Cell: ({ value }) => {
                return stringIsNullOrEmpty(value) ? "-" : value;
            }
        },
        {
            Header: "PLAYER_ID",
            accessor: "playerId",
            disableSortBy: true,
            Cell: ({ value }) => {
                return stringIsNullOrEmpty(value) ? "-" : value;
            }
        },
        {
            Header: "VENDOR",
            accessor: "vendor",
            disableSortBy: true,
            Cell: ({ value }) => {
                return stringIsNullOrEmpty(value) ? "-" : value;
            }
        },
        {
            Header: "TRANSACTION_TYPE",
            accessor: "transactionType",
            Cell: ({ row }) => {
                return renderTransactionType(row.original.transactionType);
            },
            disableSortBy: true
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                return row.original.status ? <span className="badge badge-green">{t("SUCCESS")}</span> : <span className="badge badge-danger">{t("FAILED")}</span>;
            },
            disableSortBy: true
        },
        {
            Header: "TYPE",
            accessor: "type",
            disableSortBy: true
        },
        {
            Header: "INITIAL_LIMIT",
            accessor: "shopInitialLimit",
            Cell: ({ row }) => {
                return row.original.shopInitialLimit.toFixed(2)
            },
            Footer: ({ page }) => {
                const initialLimitTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.shopInitialLimit);
                }, 0);

                return <span><b>{initialLimitTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "SCORE",
            accessor: "amount",
            Cell: ({ row }) => {
                return row.original.amount > 0 ? <span className="text-success">{row.original.amount.toFixed(2)}</span> : <span className="text-danger">{row.original.amount.toFixed(2)}</span>;
            },
            Footer: ({ page }) => {
                const scoreTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.amount);
                }, 0);

                return <span><b>{scoreTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "FINAL_LIMIT",
            accessor: "shopFinalLimit",
            Cell: ({ row }) => {
                return row.original.shopFinalLimit.toFixed(2)
            },
            Footer: ({ page }) => {
                const finalLimitTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.shopFinalLimit);
                }, 0);

                return <span><b>{finalLimitTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "PLAYER_INITIAL_LIMIT",
            accessor: "playerInitialCredit",
            Cell: ({ row }) => {
                return row.original.playerInitialCredit.toFixed(2)
            },
            disableSortBy: true
        },
        {
            Header: "PLAYER_FINAL_LIMIT",
            accessor: "playerFinalCredit",
            Cell: ({ row }) => {
                return row.original.playerFinalCredit.toFixed(2)
            },
            disableSortBy: true
        },
        {
            Header: "IP_ADDRESS",
            accessor: "ipAddress",
            disableSortBy: true
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                _dispatch(showResponseMessage(true, row.original.remark));
                            }}><span className="text-warning">{t("VIEW_REMARK")}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ]

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        register({ name: 'operatedBy' });
        register({ name: 'operatedShop' });
        register({ name: 'vendor' });
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: true });

        setValue('operatedBy', []);
        setValue('operatedShop', []);
        setValue('vendor', []);
        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
        init();
    }, []);

    /// <summary>
    /// Author : A
    /// </summary>
    useEffect(() => {
        (async () => {
            await getOperatedBy();

            // do not load report on first load
            if (!isFirstLoad) {
                handleSubmit(submitForm)();
            }
        })();
    }, [reportRole]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function renderTransactionType(transactionType) {
        let transactionTypeString = "";

        switch (transactionType) {
            case TransactionType._BONUS_CLAIM:
                transactionTypeString = t("BONUS");
                break;
            case TransactionType._REWARD_CLAIM:
                transactionTypeString = t("REWARD");
                break;
            case TransactionType._SET_LIMIT:
                transactionTypeString = t("LIMIT");
                break;
            case TransactionType._SET_SCORE:
                transactionTypeString = t("RELOAD");
                break;
            case TransactionType._WALLET_TRANSFER:
                transactionTypeString = t("CREDIT");
                break;
            case TransactionType._SEAMLESS_SET_CREDIT:
                transactionTypeString = t("SEAMLESS_SET_CREDIT");
                break;
            case TransactionType._ONLINE_WITHDRAWAL:
                transactionTypeString = t("ONLINE_WITHDRAWAL");
                break;
            case TransactionType._PASSWORD_RESET:
                transactionTypeString = t("PASSWORD_RESET");
                break;
        }

        return <span className="badge badge-primary">{transactionTypeString}</span>
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function init() {
        _dispatch(setBusy());
        await getVendorOption();
        _dispatch(setIdle());

        if (!stringIsNullOrEmpty(_query.get('username'))) {
            setValue('phoneNumber', _query.get('username'));
            setValue('startDate', _query.get('startDate') + " 00:00:00");
            setValue('endDate', _query.get('endDate') + " 23:59:59");
            setStartDate(_query.get('startDate') + " 00:00:00");
            setEndDate(_query.get('endDate') + " 23:59:59");
            handleSubmit(submitForm)();
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function getOperatedBy() {
        if (reportRole == ReportType._SHOP) {
            var apiUrl = ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP;
        } else {
            var apiUrl = ApiUrl._API_GET_AGENT_LIST;
        }

        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var shopListByRegion = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "regionName");
            var shopOptionsByRegion = [];
            var regionOptions = [];

            for (var key in shopListByRegion) {
                var shopList = [];

                regionOptions.push({ label: key, value: key });

                shopListByRegion[key].map(shop => {
                    shopList.push({ label: shop.username + (shop.shopName ? " (" + shop.shopName + ")" : ""), value: shop.id });
                });

                shopOptionsByRegion.push({
                    label: key,
                    value: key,
                    options: shopList
                })
            }

            setShopOption(shopOptionsByRegion);
            setRegions(regionOptions);
            setIsFirstLoad(false);
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function getVendorOption() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_LIST);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var vendorList = [];

            responseJson[ApiKey._API_DATA_KEY].forEach(function (vendor) {
                vendorList.push({ label: vendor.name + " (" + vendor.shortName + ")", value: vendor.id });
            });

            setVendorOption(vendorList);
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "OperatedBy": data.operatedBy,
            "TargetShop": data.operatedShop,
            "Vendor": data.vendor,
            "Type": parseInt(data.type),
            "Status": parseInt(data.status),
            "TransactionType": parseInt(data.transactionType),
            "ReportRoleId": parseInt(reportRole)
        };

        if (!stringIsNullOrEmpty(data.phoneNumber)) {
            params["PhoneNumber"] = data.phoneNumber;
        }

        if (!stringIsNullOrEmpty(data.playerId)) {
            params["PlayerId"] = data.playerId;
        }

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_SET_SCORE_REPORT);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const PaginationTable = useMemo(() =>
        <ReactTable
            className="col-nowrap"
            initialPageSize={500}
            columns={_tableColumns}
            fetchUrl={apiUrl}
            postData={postData}
            renderFooter={true}
            exportRequired={true} />
        , [apiUrl, postData, reportRole])

    return (
        <div>
            <h1 className="page-header d-flex justify-content-between">
                {t("SET_SCORE_REPORT")}
                <ButtonGroup className="btn-group-usertype">
                    {
                        Object.keys(ReportType).filter(i => ReportType[i] != ReportType._ALL).map((key) =>
                            <Button
                                type="button"
                                color="primary"
                                active={ReportType[key] == reportRole}
                                onClick={() => setReportRole(ReportType[key])}
                            >
                                {t(key.replace("_", ""))}
                            </Button>
                        )
                    }
                </ButtonGroup>
            </h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>{t("OPERATED_BY")}</label>
                                    <RegionFilterDualListBox
                                        canFilter
                                        name={"operatedBy"}
                                        ref={register}
                                        options={shopOption}
                                        selectedOptions={selectedOperatedBy}
                                        regions={regions}
                                        onChange={(e) => {
                                            setValue('operatedBy', e);
                                            setSelectedOperatatedBy(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>{t(reportRole == ReportType._SHOP ? "OPERATED_SHOP" : "AGENT")}</label>
                                    <RegionFilterDualListBox
                                        canFilter
                                        name={"operatedShop"}
                                        ref={register}
                                        options={shopOption}
                                        selectedOptions={selectedShop}
                                        regions={regions}
                                        onChange={(e) => {
                                            setValue('operatedShop', e);
                                            setSelectedShop(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>{t("VENDOR")}</label>
                                    <DualListBoxItemRow options={vendorOption} selected={selectedVendor} />
                                    <DualListBox
                                        canFilter
                                        name="vendor"
                                        ref={register}
                                        options={vendorOption}
                                        selected={selectedVendor}
                                        onChange={(e) => {
                                            setValue('vendor', e);
                                            setSelectedVendor(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("TYPE")}</label>
                                    <select name="type" ref={register} className="form-control form-control-lg">
                                        <option value={_TYPE_OPTION.ALL}>{t("ALL")}</option>
                                        <option value={_TYPE_OPTION.IN}>{t("IN")}</option>
                                        <option value={_TYPE_OPTION.OUT}>{t("OUT")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("STATUS")}</label>
                                    <select name="status" ref={register} className="form-control form-control-lg">
                                        <option value={_TYPE_OPTION.ALL}>{t("ALL")}</option>
                                        <option value={Status._ENABLED}>{t("SUCCESS")}</option>
                                        <option value={Status._DISABLED}>{t("FAILED")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("PLAYER_ID")}</label>
                                    <input type="text" name="playerId" ref={register} className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("PHONE_NUMBER")}</label>
                                    <input type="text" name="phoneNumber" ref={register} className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("TRANSACTION_TYPE")}</label>
                                    <select name="transactionType" ref={register} className="form-control form-control-lg">
                                        <option value={_TYPE_OPTION.ALL}>{t("ALL")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.BONUS}>{t("BONUS")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.REWARD}>{t("REWARD")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.SET_SCORE}>{t("RELOAD")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.SET_LIMIT}>{t("LIMIT")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.WALLET}>{t("CREDIT")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.SEAMLESS_SET_CREDIT}>{t("SEAMLESS_SET_CREDIT")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.ONLINE_WITHDRAWAL}>{t("ONLINE_WITHDRAWAL")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.PASSWORD_RESET}>{t("PASSWORD_RESET")}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
};

export default SetScoreReport;