import React from 'react';
import { Link } from 'react-router-dom';
import { PageSettings } from './../../config/page-settings.js';
import { connect } from "react-redux";

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
class SidebarProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profileActive: 0
        };
        this.handleProfileExpand = this.handleProfileExpand.bind(this);
    }

    handleProfileExpand(e) {
        e.preventDefault();
        this.setState(state => ({
            profileActive: !this.state.profileActive,
        }));
    }

    render() {
        return (
            <PageSettings.Consumer>
                {({ pageSidebarMinify }) => (
                    <ul className="nav">
                        <li className={"nav-profile " + (this.state.profileActive ? "expand " : "")}>
                            <Link to="/" onClick={this.handleProfileExpand}>
                                <div className="cover with-shadow"></div>
                                <div className="info">
                                    {this.props.authState.userData.username}
                                    <small>{this.props.authState.userData.userRole}</small>
                                </div>
                            </Link>
                        </li>
                    </ul>
                )}
            </PageSettings.Consumer>
        )
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(
    mapStateToProps,
    null
)(SidebarProfile);