import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { ApiKey, ApiUrl, WebUrl, AccessRight, FilterStatus, Status, AlertTypes, Role, CustomResponseCode, TransactionTypeId } from "../../../util/Constant";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useDispatch, useSelector } from "react-redux";
import ApiEngine from '../../../util/ApiEngine.js';
import ScoreLogModal from '../../../components/custom/ScoreLogModal';
import GameLogModal from '../../../components/custom/GameLogModal';
import { showResponseMessage, showMessage, setBusy, setIdle } from 'redux/AppAction';
import * as Util from '../../../util/Util';
import Select from "react-select";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReferralCodeModal from '../../../components/custom/ReferralCodeModal';
import { Link, useLocation, useHistory } from 'react-router-dom';
import AccountStatusModal from '../../../components/custom/AccountStatusModal';
import AppealRequestModal from '../../../components/custom/AppealRequestModal';
import NavigationButton from '../../../components/custom/NavigationButton';
import ReloadDetailModal from '../../../components/custom/ReloadDetailModal';

/// <summary>
/// Author : Andrew
/// </summary>
const ManagePlayer = props => {
    const { t } = useTranslation();
    let _dispatch = useDispatch();
    var _location = useLocation();
    var _history = useHistory();
    const _userData = useSelector(state => state.authState.userData);
    const { register, unregister, handleSubmit, errors, setValue } = useForm();

    const { register: registerSetScoreForm, errors: errorsSetScoreForm,
        handleSubmit: handleSubmitSetScoreForm, unregister: unregisterSetScoreForm,
        setValue: setValueSetScoreForm } = useForm();

    const [vendorOption, setVendorOption] = useState([]);
    const [shopOption, setShopOption] = useState([]);
    const [vendorCode, setVendorCode] = useState('');
    const [shopId, setShopId] = useState('');
    const [scoreShopId, setScoreShopId] = useState('');
    const [modalVisibility, setModalVisibility] = useState(false);
    const [scoreLogModalVisibility, setScoreLogModalVisibility] = useState(false);
    var [filterUsername, setFilterUsername] = useState('');
    const [selectedPlayer, setSelectedPlayer] = useState('');
    const [playerApiUrl, setPlayerApiUrl] = useState('');
    var [count, setCount] = useState(0);
    const [scoreShopOption, setScoreShopOption] = useState([]);
    const [referralCodeModalVisibility, setReferralCodeModalVisibility] = useState(false);
    const [deactivateAccountDetail, setDeactivateAccountDetail] = useState({});
    const [transactionDetail, setTransactionDetail] = useState({});
    const [agentId, setAgentId] = useState("");
    const [filterVendorList, setFilterVendorList] = useState([]);
    const [filterVendor, setFilterVendor] = useState('');
    const [gameLogModalVisibility, setGameLogModalVisibility] = useState(false);
    const [reloadPlayerDetail, setReloadPlayerDetail] = useState(null);

    /// <summary>
    /// Author : Andrew
    /// </summary>
    let _tableColumns = useMemo(() => [
        {
            Header: _location.state && !Util.stringIsNullOrEmpty(_location.state.agentId) ? t("AGENT") : t("SHOP"),
            accessor: 'shopName',
            Cell: ({ row }) => !Util.stringIsNullOrEmpty(row.original.shopName) ?
                <Link to={{ pathname: Util.stringIsNullOrEmpty(row.original.memberUsername) || row.original.isAgentPlayer ? WebUrl._URL_ADMIN_MANAGE_AGENT : WebUrl._URL_ADMIN_MANAGE_SHOP, state: row.original }}>{row.original.shopName}</Link> : '',
            disableSortBy: true
        },
        {
            Header: t("VENDOR"),
            accessor: "vendorName",
            disableSortBy: true
        },
        {
            Header: t("REGION"),
            accessor: "regionName",
            disableSortBy: true
        },
        {
            Header: t("MEMBER"),
            accessor: 'memberUsername',
            Cell: ({ row }) => !Util.stringIsNullOrEmpty(row.original.memberUsername) ?
                <Link to={{ pathname: WebUrl._URL_ADMIN_MANAGE_MEMBER, state: row.original }}>{row.original.memberUsername}</Link> : '',
            disableSortBy: true
        },
        {
            Header: t("USERNAME"),
            accessor: "username",
            disableSortBy: true
        },
        {
            Header: t("STATUS"),
            accessor: "status",
            Cell: ({ row }) => {
                return <span>{row.original.status == 1 ? <span class="badge badge-secondary badge-green">{t("ACTIVE")}</span> : <span class="badge badge-secondary badge-danger">{t("SUSPENDED")}</span>}</span>
            },
            disableSortBy: true
        },
        {
            Header: t("CREATED_DATE"),
            accessor: "createdTime",
            disableSortBy: true
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._PLAYER_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            {_userData.accessibleRegion.filter(i => i == row.original.regionId).length != 0 &&
                                <DropdownItem onClick={() => {
                                    // If Agent player, set score like shop interface
                                    if (_location.state && !Util.stringIsNullOrEmpty(_location.state.agentId)) {
                                        setReloadPlayerDetail(row.original);
                                    }
                                    else {
                                        initSetScoreModel(row.original);
                                    }
                                }}><span>{t("SET_SCORE")}</span></DropdownItem>
                            }
                            {(_userData.accessibleRegion.filter(i => i == row.original.regionId).length != 0 || row.original.status == Status._ENABLED) &&
                                <DropdownItem onClick={() => {
                                    let currentStatus = row.original.status;
                                    setDeactivateAccountDetail({
                                        ...row.original,
                                        fromStatus: currentStatus,
                                        toStatus: currentStatus == Status._ENABLED ? Status._DISABLED : Status._ENABLED
                                    });
                                }}><span>{row.original.status == Status._ENABLED ? t("BLOCK") : t("UNBLOCK")}</span></DropdownItem>
                            }
                            <DropdownItem onClick={() => {
                                let currentStatus = row.original.status;
                                onDeleteClick({
                                    ...row.original,
                                    fromStatus: currentStatus,
                                    toStatus: Status._DELETED
                                });
                            }}><span>{t("DELETE")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                resetPassword(row.original);
                            }}><span>{t("RESET_PASSWORD")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                setSelectedPlayer(row.original);
                                setScoreLogModalVisibility(true);
                            }}><span>{t("VIEW_SCORE_LOG")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                setSelectedPlayer(row.original);
                                setGameLogModalVisibility(true);
                            }}><span>{t("VIEW_GAME_LOG")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                const win = window.open(WebUrl._URL_VIEW_USER_ACTION_LOG + "?username=" + row.original.memberUsername
                                    , "_blank");
                                win.focus();
                            }}>
                                <span>{t('VIEW_USER_ACTION_LOG')}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible", width: '1%' }
        }
    ], [_userData, filterUsername]);

    /// <summary>
    /// Author : Andrew
    /// </summary>
    useEffect(() => {
        if (!_location.state || Util.stringIsNullOrEmpty(_location.state.agentId)) {
            register({ name: 'shop' }, { required: true });
        }

        register({ name: 'vendor' }, { required: true });
        init();
    }, []);

    /// <summary>
    /// Author : YOng Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (!Util.stringIsNullOrEmpty(shopId)) {
            updateAvailableVendor(shopId);
        }
    }, [shopId]);

    // <summary>
    /// Author: YJ
    /// </summary>
    async function verifyPassword(password, data) {
        var responseJson = await ApiEngine.post(`${ApiUrl._API_VERIFY_PASSWORD}?userId=${_userData['userId']}&password=${password}`);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setDeactivateAccountDetail(data);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    // <summary>
    /// Author: YJ
    /// </summary>
    async function onDeleteClick(data) {
        _dispatch(showMessage({
            content: t("VERIFY_PASSWORD_BEFORE_CONTINUE"),
            showCancel: true,
            required: true,
            inputType: 'password',
            validationMsg: t('INVALID_PASSWORD'),
            confirmBtnText: t("CONFIRM"),
            type: AlertTypes._INPUT,
            onConfirm: async (value) => {
                await verifyPassword(value, data);
            }
        }));
    }

    /// <summary>
    /// Author : YOng Sheng Chuan
    /// </summary>
    async function updateAvailableVendor(shopId) {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_LIST + (!Util.stringIsNullOrEmpty(agentId) ? "?agentId=" + agentId : "?shopId=" + shopId));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var vendorList = [];
            var filterVendors = [{ label: "All", value: "" }];

            responseJson[ApiKey._API_DATA_KEY].forEach(function (vendor) {
                vendorList.push({ label: vendor.name + " (" + vendor.shortName + ")", value: vendor.code });
                filterVendors.push({ label: vendor.name + " (" + vendor.shortName + ")", value: vendor.id });
            });

            setVendorOption(vendorList);
            setFilterVendorList(filterVendors);
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        (async () => {
            if (!Util.stringIsNullOrEmpty(agentId)) {
                await updateAvailableVendor();
            }
        })();
    }, [agentId])

    /// <summary>
    /// Author : Andrew
    /// </summary>
    async function init() {
        try {
            if (_location.state && !Util.stringIsNullOrEmpty(_location.state.agentId)) {
                setAgentId(_location.state.agentId);
            }
            else {
                _dispatch(setBusy());
                await fetchPlayers();

                var responseJson = await ApiEngine.get(ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP);

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    var shopList = [];
                    responseJson[ApiKey._API_DATA_KEY].forEach(function (shop) {
                        shopList.push({ label: shop.username + " (" + shop.shopName + ")", value: shop.id });
                    });

                    setShopOption(shopList);
                }
            }
        }
        catch (e) {
            //do nothing
        }
        finally {
            _dispatch(setIdle());
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function initSetScoreModel(data) {
        if (Util.stringIsNullOrEmpty(agentId)) {
            registerSetScoreForm({ name: 'scoreShopId' }, { required: true });
            var shopUserList = await getShopUser(data.regionId);

            if (shopUserList.filter(i => i.value == data.shopId).length > 0) {
                setValueSetScoreForm("scoreShopId", shopUserList.filter(i => i.value == data.shopId)[0].value);
                setScoreShopId(shopUserList.filter(i => i.value == data.shopId)[0].value);
            }
        }

        setSelectedPlayer(data);
        setModalVisibility(true);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function cleanupSetScoreModal() {
        unregisterSetScoreForm("scoreShopId");
        setModalVisibility(false);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function getShopUser(regionId) {
        const shopList = [];
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP + "&RegionId=" + regionId);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            responseJson[ApiKey._API_DATA_KEY].forEach(function (shop) {
                shopList.push({ label: shop.username + " (" + shop.shopName + ")", value: shop.id });
            });

            setScoreShopOption(shopList);
        }

        return shopList;
    }

    /// <summary>
    /// Author : Andrew
    /// </summary>      
    async function fetchPlayers() {
        var rawUrl = ApiUrl._API_GET_PLAYER_LIST + "?v=" + (++count);
        setCount(count);

        if (!Util.stringIsNullOrEmpty(filterUsername)) {
            rawUrl += "&Username=" + filterUsername;
        }

        if (!Util.stringIsNullOrEmpty(_location.state?.agentId)) {
            rawUrl += "&AgentId=" + _location.state.agentId;
        }

        if (!Util.stringIsNullOrEmpty(filterVendor)) {
            rawUrl += "&VendorId=" + filterVendor;
        }

        setPlayerApiUrl(rawUrl);
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function blockPlayer(data, remark = "") {
        var shopIdParam = !Util.stringIsNullOrEmpty(agentId) ? agentId : data.shopId;
        let apiUrl = ApiUrl._API_SHOP_SET_PLAYER_STATUS + "?code=" + data.vendorCode + "&playerUsername="
            + data.username + "&status=" + (FilterStatus._ACTIVE == data.status ? "false" : "true") + "&shopId=" + shopIdParam + "&remark=" + remark;

        var responseJson = await ApiEngine.post(apiUrl);

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            await fetchPlayers();
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function resetPassword(data) {
        var shopIdParam = !Util.stringIsNullOrEmpty(agentId) ? agentId : data.shopId;
        var responseJson = await ApiEngine.post(ApiUrl._API_SHOP_RESET_PASSWORD + "?code=" + data.vendorCode + "&playerUsername=" + data.username + "&shopId=" + shopIdParam);
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            await fetchPlayers();
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function deletePlayer(data, remark = "") {
        var shopIdParam = !Util.stringIsNullOrEmpty(agentId) ? agentId : data.shopId;
        let apiUrl = ApiUrl._API_SHOP_DELETE_PLAYER + "?code=" + data.vendorCode + "&playerUsername=" + data.username + "&shopId=" + shopIdParam + "&remark=" + remark;

        var responseJson = await ApiEngine.post(apiUrl);

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            await fetchPlayers();
        }
    }

    /// <summary>
    /// Author : Andrew
    /// Yong Sheng Chuan - Allow immediate filter on create
    /// </summary>
    async function onSubmit(data, e) {
        var shopIdParam = !Util.stringIsNullOrEmpty(agentId) ? agentId : shopId;
        var apiUrl = ApiUrl._API_SHOP_CREATE_PLAYER + "?code=" + vendorCode + "&shopId=" + shopIdParam;

        var responseJson = await ApiEngine.post(apiUrl);

        //if create is already success, we keep the filter first
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            filterUsername = responseJson[ApiKey._API_DATA_KEY];
            setFilterUsername(responseJson[ApiKey._API_DATA_KEY]);
        }

        //perform set credit if the value is more than 0
        if (responseJson[ApiKey._API_SUCCESS_KEY] && data.credit != 0) {
            responseJson = await ApiEngine.post(ApiUrl._API_SHOP_SET_SCORE + "?code=" + vendorCode + "&playerUsername=" + responseJson[ApiKey._API_DATA_KEY] + "&score=" + data.credit + "&shopId=" + shopIdParam);

            if (!responseJson[ApiKey._API_SUCCESS_KEY] && !Util.stringIsNullOrEmpty(responseJson[ApiKey._API_CODE_KEY]) && responseJson[ApiKey._API_CODE_KEY] == CustomResponseCode._ROLLOVER_APPEAL_REQUIRED) {
                setTransactionDetail({
                    "type": TransactionTypeId._SET_SCORE,
                    "code": vendorCode,
                    "username": responseJson[ApiKey._API_DATA_KEY],
                    "amount": data.credit,
                    "shopId": shopIdParam
                })
            }
        }

        if (responseJson[ApiKey._API_DATA_KEY]) {
            fetchPlayers();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author : Andrew
    /// </summary>
    async function onSubmitSetScore(data, e) {
        var shopIdParam = !Util.stringIsNullOrEmpty(agentId) ? agentId : data.scoreShopId;
        var responseJson = await ApiEngine.post(ApiUrl._API_SHOP_SET_SCORE + "?code=" + selectedPlayer.vendorCode + "&playerUsername=" +
            selectedPlayer.username + "&score=" + data.setScoreAmount + "&shopId=" + shopIdParam);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let score = responseJson[ApiKey._API_DATA_KEY]["score"];

            _dispatch(showMessage({
                type: AlertTypes._SUCCESS,
                title: t("SUCCESS"),
                content: <div>{t("SET_SCORE_SUCCESS")}: <b className={score < 0 ? "text-red" : "text-green"}>{Number(score).toFixed(1)}</b></div>,
                html: true
            }));

            cleanupSetScoreModal();
            await fetchPlayers();
        }
        else if (!responseJson[ApiKey._API_SUCCESS_KEY] && !Util.stringIsNullOrEmpty(responseJson[ApiKey._API_CODE_KEY]) && responseJson[ApiKey._API_CODE_KEY] == CustomResponseCode._ROLLOVER_APPEAL_REQUIRED) {
            cleanupSetScoreModal();

            setTransactionDetail({
                "type": TransactionTypeId._SET_SCORE,
                "code": selectedPlayer.vendorCode,
                "username": selectedPlayer.username,
                "amount": data.setScoreAmount,
                "shopId": shopIdParam
            })
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    function getReferralShop(data) {
        unregister('shop');
        setShopId(data[ApiKey._API_DATA_KEY]["id"]);
    }

    return (
        <div>
            <h1 className="page-header">{t("MANAGE_PLAYER")}{!Util.stringIsNullOrEmpty(agentId) ? " - [" + _location.state.userFullName + "]" : ""}{!Util.stringIsNullOrEmpty(agentId) && <NavigationButton history={_history} />}</h1>
            <AppealRequestModal
                visible={!Util.isObjectEmpty(transactionDetail)}
                detail={transactionDetail}
                onFinish={(isConfirm) => {
                    setTransactionDetail({});
                }}
            />
            <AccountStatusModal
                visible={!Util.isObjectEmpty(deactivateAccountDetail)}
                detail={deactivateAccountDetail}
                onFinish={(isConfirm, remark) => {
                    if (isConfirm) {
                        if (deactivateAccountDetail.toStatus == Status._DELETED) {
                            deletePlayer(deactivateAccountDetail, remark);
                        }
                        else {
                            blockPlayer(deactivateAccountDetail, remark);
                        }
                    }
                    setDeactivateAccountDetail({});
                }} />
            {checkIfPermissionExist(AccessRight._PLAYER_CONTROL_PERMISSION, true) &&
                <Panel>
                    {modalVisibility &&
                        <Modal isOpen={modalVisibility} toggle={() => { cleanupSetScoreModal() }}>
                            <form onSubmit={handleSubmitSetScoreForm(onSubmitSetScore)}>
                                <ModalHeader toggle={() => { cleanupSetScoreModal() }}><h4>{t("SET_SCORE")}</h4></ModalHeader>
                                <ModalBody>
                                    {Util.stringIsNullOrEmpty(agentId) && <div className="form-group m-b-15 ">
                                        <label>{t("SHOP")}</label>
                                        <Select name="scoreShopId" options={scoreShopOption}
                                            value={scoreShopOption.filter(option => option.value == scoreShopId)}
                                            placeholder=""
                                            onChange={(e) => {
                                                setValueSetScoreForm("scoreShopId", e.value);
                                                setScoreShopId(e.value);
                                            }} />
                                        {errorsSetScoreForm.scoreShopId && <div className="invalid-feedback">{t(Util.getValidationMessage(errorsSetScoreForm.scoreShopId))}</div>}
                                    </div>}
                                    <div className="form-group m-b-15 ">
                                        <label>{t("SCORE")}</label>
                                        <input
                                            className="form-control form-control-lg m-b-5"
                                            type="number"
                                            name="setScoreAmount"
                                            ref={registerSetScoreForm({ required: true })} />
                                        {errorsSetScoreForm.setScoreAmount && <div className="invalid-feedback">{t(Util.getValidationMessage(errorsSetScoreForm.setScoreAmount))}</div>}
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <button type="submit" className="btn btn-primary btn-action">{t("BTN_SUBMIT")}</button>
                                    <button type="button" className="btn btn-danger btn-action" onClick={() => cleanupSetScoreModal()}>{t("BTN_CANCEL")}</button>
                                </ModalFooter>
                            </form>
                        </Modal>
                    }
                    <PanelBody>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                {Util.stringIsNullOrEmpty(agentId) && <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>{t('SHOP')}</label>
                                        <Select name="shop" options={shopOption}
                                            placeholder={(shopOption.filter(option => option.value == shopId)[0] !== undefined) ? (shopOption.filter(option => option.value == shopId)[0].label) : ""}
                                            value={shopOption.filter(option => option.value == shopId)}
                                            onChange={(e) => {
                                                unregister('shop');
                                                setShopId(e.value);
                                            }} />
                                        {errors.shop && <div className="invalid-feedback">{t(Util.getValidationMessage(errors.shop))}</div>}
                                    </div>
                                </div>}
                                <div className="col-lg-3">
                                    <label>{t('VENDOR')}</label>
                                    <Select name="vendor" options={vendorOption}
                                        placeholder={(vendorOption.filter(option => option.value == vendorCode)[0] !== undefined) ? (vendorOption.filter(option => option.value == vendorCode)[0].label) : ""}
                                        value={vendorOption.filter(option => option.value == vendorCode)}
                                        onChange={(e) => {
                                            unregister('vendor');
                                            setVendorCode(e.value);
                                        }} />
                                    {errors.vendor && <div className="invalid-feedback">{t(Util.getValidationMessage(errors.vendor))}</div>}
                                </div>
                                <div className="col-lg-3">
                                    <label>{t('CREDIT')}</label>
                                    <input type="number" className="form-control form-control-lg" ref={register({ required: true })} name="credit" />
                                    {errors.credit && <div className="invalid-feedback">{t(Util.getValidationMessage(errors.credit))}</div>}
                                </div>
                                {Util.stringIsNullOrEmpty(agentId) && <div className="col-lg-3">
                                    <label>{t('REFERRAL_CODE')}</label>
                                    <button type="button" className="form-control form-control-lg btn-success"
                                        onClick={() => { setReferralCodeModalVisibility(!referralCodeModalVisibility); }}>
                                        {t('ENTER_REFERRAL_CODE')}</button>
                                </div>}
                            </div>
                            <button type="submit" className="btn btn-primary">{t("CREATE")}</button>
                        </form>
                    </PanelBody>
                </Panel>
            }
            <Panel>
                <PanelBody>
                    <div className="row m-b-10">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("USERNAME")}</label>
                                <input type="text" name="username" className="form-control form-control-lg" value={filterUsername} onInput={(e) => setFilterUsername(e.target.value)} />
                            </div>
                        </div>
                        {!Util.stringIsNullOrEmpty(agentId) && <div className="col-lg-3">
                            <label>{t('VENDOR')}</label>
                            <Select name="filterVendor" options={filterVendorList}
                                placeholder={(filterVendorList.filter(option => option.value == filterVendor)[0] !== undefined) ? (filterVendorList.filter(option => option.value == filterVendor)[0].label) : ""}
                                value={filterVendorList.filter(option => option.value == filterVendor)}
                                onChange={(e) => {
                                    unregister('filterVendor');
                                    setFilterVendor(e.value);
                                }} />
                        </div>}
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="form-group form-button">
                                <button type="button" class="btn btn-primary" onClick={() => fetchPlayers()}>{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    <ReactTable hiddenColumns={_location.state && !Util.stringIsNullOrEmpty(_location.state.agentId) ? ['memberUsername'] : []} fetchUrl={playerApiUrl} columns={_tableColumns} />
                </PanelBody>
            </Panel>
            <ScoreLogModal isModalVisible={scoreLogModalVisibility} player={selectedPlayer} onFinish={() => setScoreLogModalVisibility(false)} />
            <GameLogModal isModalVisible={gameLogModalVisibility} player={selectedPlayer} onFinish={() => setGameLogModalVisibility(false)} />
            <ReferralCodeModal
                onFinish={(data) => {
                    if (data != null) {
                        getReferralShop(data);
                    }
                    setReferralCodeModalVisibility(false);
                }}
                visible={referralCodeModalVisibility} />
            <ReloadDetailModal
                vendorCode={reloadPlayerDetail?.vendorCode}
                username={reloadPlayerDetail?.username}
                shopId={reloadPlayerDetail?.shopId}
                status={reloadPlayerDetail?.status}
                remark={reloadPlayerDetail?.remark}
                onFinish={async (refreshRequired, setScoreUrl = null, score = null, details = {}, rolloverAction = false) => {
                    setReloadPlayerDetail(null);
                    await fetchPlayers();
                }}
                visible={reloadPlayerDetail != null} />
        </div>
    )
}

export default ManagePlayer;