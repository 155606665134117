import React, { useState, useEffect } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastBody } from 'reactstrap';
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, AlertTypes, OnlineWithdrawalStatus, AccessRight, CommonStatus } from "../../../util/Constant";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { showResponseMessage, showMessage } from "../../../redux/AppAction";
import { getPendingOnlineWithdrawalCount } from "../../../redux/AuthAction";
import { numberWithCurrencyFormat, stringIsNullOrEmpty, isObjectEmpty } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';
import moment from 'moment';
import { checkIfPermissionExist } from 'util/PermissionChecker';
import DateTime from 'react-datetime';
import Select from "react-select";

/// <summary>
/// Author: Wind
/// </summary> 
const OnlineWithdrawalRequest = props => {
    const { t } = useTranslation();
    const _dispatch = useDispatch();
    const { register, handleSubmit, setValue, watch } = useForm();
    const _userId = useSelector(state => state['authState']['userData']['userId']);
    const _userData = useSelector(state => state['authState']['userData']);
    const _pendingOnlineWithdrawalCount = useSelector(state => state['authState']['pendingOnlineWithdrawalCount']);
    const [modalData, setModalData] = useState({});
    const [remark, setRemark] = useState('');
    const [apiUrl, setApiUrl] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [showToast, setShowToast] = useState(false);
    const _STATUS_OPTION = [
        { label: 'ALL', value: '' },
        { label: 'SYSTEM_REJECTED', value: OnlineWithdrawalStatus._SYSTEM_REJECTED },
        { label: 'REJECTED', value: OnlineWithdrawalStatus._REJECTED },
        { label: 'PENDING_APPROVAL', value: OnlineWithdrawalStatus._PENDING_APPROVAL },
        { label: 'SUCCESS', value: OnlineWithdrawalStatus._SUCCESS },
    ];
    const _REMARK_OPTION = [
        { label: '', value: '' },
        { label: t('SYSTEM_MAINTENANCE'), value: 'System Maintenance' },
        { label: t('BANK_ACCOUNT_INVALID'), value: 'Invalid Bank Account' },
        { label: t('BANK_MAINTENANCE'), value: 'Bank Maintenance' }
    ];

    let _tableColumns = [
        {
            Header: "No.",
            id: "row",
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            disableSortBy: true,
            width: '1%'
        },
        {
            Header: t("REQUEST_DATETIME"),
            disableSortBy: true,
            Cell: ({ row }) => {
                return moment(row.original.createdTime).format('M/D/YYYY hh:mm:ss A');
            },
        },
        {
            Header: t("USERNAME"),
            disableSortBy: true,
            Cell: ({ row }) => {
                return <span>{`${row.original.username} ${row.original.shopName ? '(' + row.original.shopName + ')' : ''}`} <b>{row.original.regionName}</b></span>
            },
        },
        {
            Header: t("PLAYER"),
            disableSortBy: true,
            Cell: ({ row }) => {
                return stringIsNullOrEmpty(row.original.playerUsername) ? '-' : `${row.original.playerUsername}`;
            },
        },
        {
            Header: t("AMOUNT"),
            accessor: "amount",
            disableSortBy: true,
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.amount * -1)
            },
        },
        {
            Header: t("STATUS"),
            disableSortBy: true,
            Cell: ({ row }) => {
                return renderStatus(row.original.status, row.original.statusName);
            },
        },
        {
            Header: t("HANDLED_BY"),
            disableSortBy: true,
            Cell: ({ row }) => {
                return stringIsNullOrEmpty(row.original.viewerUsername) ? '-' : `${row.original.viewerUsername} (${moment(row.original.viewedTime).format('M/D/YYYY hh:mm:ss A')})`;
            },
        },
        {
            Header: t("PROCESSED_DATETIME"),
            disableSortBy: true,
            Cell: ({ row }) => {
                return row.original.status != OnlineWithdrawalStatus._PENDING_APPROVAL ? moment(row.original.updatedTime).format('M/D/YYYY hh:mm:ss A') : '-';
            },
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._ONLINE_WITHDRAWAL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    {
                        row.original.status == OnlineWithdrawalStatus._PENDING_APPROVAL &&
                        <button type="button" title={t("TAKE")} class="btn btn-primary" onClick={() => {
                            processTransaction(row.original.id);
                        }}><i class="fas fa-handshake f-s-15"></i></button>
                    }
                    &nbsp;
                    {
                        row.original.status == OnlineWithdrawalStatus._PENDING_APPROVAL &&
                        !stringIsNullOrEmpty(row.original.viewedBy) && checkIfPermissionExist(AccessRight._REVERT_ONLINE_WITHDRAWAL_PERMISSION, true) &&
                        <button type="button" title={t("REVERT_TRANSACTION")} class="btn btn-warning" onClick={() => {
                            _dispatch(showMessage({
                                content: t("ARE_YOU_SURE_YOU_WANT_TO_THIS_ONLINE_WITHDRAWAL_BY", { user: row.original.viewerUsername, action: t('REVERT') }),
                                showCancel: true,
                                confirmBtnText: t("CONFIRM"),
                                type: AlertTypes._WARNING,
                                onConfirm: async (value) => {
                                    var responseJson = await ApiEngine.post(`${ApiUrl._API_GET_ONLINE_WITHDRAWAL_REQUEST}/unlock/${row.original.id}`);

                                    _dispatch(showMessage({
                                        type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
                                        content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
                                        onConfirm: () => {
                                            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                                                setApiUrl(`${apiUrl}&v=${new Date()}`);
                                            }
                                        }
                                    }));
                                }
                            }));
                        }}><i class="fas fa-retweet f-s-15"></i></button>
                    }
                    &nbsp;
                    {
                        row.original.status != OnlineWithdrawalStatus._PENDING_APPROVAL &&
                        <button type="button" title={t("VIEW")} class="btn btn-primary" onClick={() => {
                            setModalData(row.original);
                        }}><i class="fas fa-eye f-s-15"></i></button>
                    }
                </div>
            ),
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ];

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        setValue('status', OnlineWithdrawalStatus._PENDING_APPROVAL);
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: true });
        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
        handleSubmit(submitForm)();
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        if (showToast) {
            setTimeout(() => setShowToast(!showToast), 3000)
        }
    }, [showToast])

    /// <summary>
    /// Author: CK
    /// Auto refresh list when got new request come in
    /// </summary>
    useEffect(() => {
        if (_pendingOnlineWithdrawalCount > 0) {
            handleSubmit(submitForm)();
        }
    }, [_pendingOnlineWithdrawalCount])

    /// <summary>
    /// Author : Wind
    /// </summary>
    function renderStatus(status, statusString) {
        let spanClass = "";

        switch (status) {
            case OnlineWithdrawalStatus._SUCCESS:
                spanClass = "badge-green";
                break;
            case OnlineWithdrawalStatus._SYSTEM_REJECTED:
                spanClass = "badge-red";
                break;
            case OnlineWithdrawalStatus._PENDING_APPROVAL:
                spanClass = "badge-primary";
                break;
            case OnlineWithdrawalStatus._REJECTED:
                spanClass = "badge-red";
                break;
        }

        return <span className={"badge " + spanClass}> {t(statusString)}</span>
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const submitForm = async (data, e) => {
        let apiUrl = `${ApiUrl._API_GET_ONLINE_WITHDRAWAL_REQUEST}?username=${data.username}&status=${parseInt(data.status)}&startDate=${startDate}&endDate=${endDate}&v=${new Date()}`

        setApiUrl(apiUrl);
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    async function processTransaction(id) {
        try {
            var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_ONLINE_WITHDRAWAL_REQUEST}/${id}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            handleSubmit(submitForm)();
            setModalData(responseJson[ApiKey._API_DATA_KEY]);
        }
        catch (err) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(err),
            }));
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    async function transactionAction(isApprove, id) {
        let params = {
            approve: isApprove == CommonStatus._APPROVE_STATUS,
            remark: remark
        };
        var responseJson = await ApiEngine.post(`${ApiUrl._API_GET_ONLINE_WITHDRAWAL_REQUEST}/status/${id}`, params);

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setApiUrl(apiUrl + '&v=' + new Date());
                    setModalData({});
                    setRemark('');
                }
            }
        }));

        // Reset notification if no more transaction
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(getPendingOnlineWithdrawalCount());
        }
    }

    return (
        <div>
            <h1 className="page-header">{t('ONLINE_WITHDRAWAL_REQUEST')}</h1>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t('USERNAME')}</b></label>
                                            <input type="text" name="username" ref={register} className="form-control form-control-lg" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>{t("STATUS")}</label>
                                            <select name="status" ref={register} className="form-control form-control-lg" style={{ maxWidth: "unset" }}>
                                                {
                                                    _STATUS_OPTION.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.value}>{t(item.label)}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>{t("START_DATE")}</label>
                                            <DateTime
                                                inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                                name="startDate"
                                                ref={register}
                                                timeFormat="HH:mm:ss"
                                                dateFormat="YYYY-MM-DD"
                                                closeOnSelect={true}
                                                value={startDate}
                                                onChange={(e) => {
                                                    if (e instanceof moment) {
                                                        setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                        setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                                    }
                                                    else {
                                                        setValue("startDate", "");
                                                    }
                                                }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>{t("END_DATE")}</label>
                                            <DateTime
                                                inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                                name="endDate"
                                                ref={register}
                                                timeFormat="HH:mm:ss"
                                                dateFormat="YYYY-MM-DD"
                                                closeOnSelect={true}
                                                value={endDate}
                                                onChange={(e) => {
                                                    if (e instanceof moment) {
                                                        setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                        setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                                    }
                                                    else {
                                                        setValue("endDate", "");
                                                    }
                                                }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <ReactTable fetchUrl={apiUrl} columns={_tableColumns} initialPageSize={_userData?.isCustomerService ? 20 : 500} />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <Modal isOpen={!isObjectEmpty(modalData)} toggle={() => setModalData({})} centered size="lg" style={{ maxWidth: "1200px" }}>
                <ModalHeader>{t('PROCESS_ONLINE_WITHDRAWAL_REQUEST', { user: modalData.username })}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <span>{t("STATUS") + ': '}</span>
                                <span><b>{renderStatus(modalData.status, modalData.statusName)}</b></span>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <span>{t("REQUESTED_DATETIME") + ': '}</span>
                                <span><b>{moment(modalData.createdTime).format('YYYY-MM-DD hh:mm:ss A')}</b></span>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <span>{t("HANDLED_BY") + ': '}</span>
                                <span><b>{`${modalData.viewerUsername} ${modalData.shopName ? '(' + modalData.shopName + ')' : ''}`}</b></span>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <span>{t("USERNAME") + ': '}</span>
                                <span><b>{`${modalData.username}`} ({modalData.regionName})</b></span>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <span>{t("BANK") + ': '}</span>
                                <span><b>{modalData.bankName}</b></span>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <span>{t("HOLDER_NAME") + ': '}</span>
                                <span><b>{modalData.bankAccountHolderName}</b></span>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <span>{t("ACCOUNT_NUMBER") + ': '}</span>
                                <span><b>{modalData.bankAccountNumber?.match(/.{1,4}/g).join(' ')}</b></span>
                                &nbsp;
                                <button
                                    className='btn btn-primary btn-icon btn-circle btn-sm'
                                    style={{
                                        'padding': '0',
                                        'borderRadius': '50px'
                                    }}
                                    onClick={() => {
                                        navigator.clipboard.writeText(modalData.bankAccountNumber);
                                        setShowToast(!showToast);
                                    }}><i class="fas fa-copy"></i></button>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <span>{t("AMOUNT") + ': '}</span>
                                <span><b>{numberWithCurrencyFormat(modalData.amount * -1)}</b></span>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <span>{t("REMARK") + ': '}</span>
                                {
                                    modalData.status == OnlineWithdrawalStatus._PENDING_APPROVAL ?
                                        <Select
                                            name="remark"
                                            options={_REMARK_OPTION}
                                            value={_REMARK_OPTION.filter(option => option.value == remark)}
                                            onChange={(e) => {
                                                setRemark(e.value);
                                            }} />
                                        :
                                        <span><b>{!stringIsNullOrEmpty(modalData.remark) ? modalData.remark : "-"}</b></span>
                                }
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <h4>{t("SET_SCORE_REPORT")}</h4>
                            <table className="table table-bordered table-hover col-nowrap">
                                <tr>
                                    <th className='f-s-14'>{t('DATE')}</th>
                                    <th className='f-s-14'>{t('OPERATED_BY')}</th>
                                    <th className='f-s-14'>{t('PHONE_NUMBER')}</th>
                                    <th className='f-s-14'>{t('PLAYER_ID')}</th>
                                    <th className='f-s-14'>{t('VENDOR')}</th>
                                    <th className='f-s-14'>{t('TRANSACTION_TYPE')}</th>
                                    <th className='f-s-14'>{t('STATUS')}</th>
                                    <th className='f-s-14'>{t('AMOUNT')}</th>
                                    <th className='f-s-14'>{t('MEMBER_PRE')}</th>
                                    <th className='f-s-14'>{t('MEMBER_POST')}</th>
                                    <th className='f-s-14'>{t('PLAYER_PRE')}</th>
                                    <th className='f-s-14'>{t('PLAYER_POST')}</th>
                                </tr>
                                {
                                    modalData.refundDetail &&
                                    <tr>
                                        <td className='f-s-14'>{moment(modalData.updatedTime).format('YYYY-MM-DD hh:mm:ss A')}</td>
                                        <td className='f-s-14'>{modalData.viewerUsername} {modalData.shopName ? '(' + modalData.shopName + ')' : ''}</td>
                                        <td className='f-s-14'>{modalData.username}</td>
                                        <td className='f-s-14'>{modalData.playerUsername ?? "-"}</td>
                                        <td className='f-s-14'>{modalData.vendorGameName ?? "-"}</td>
                                        <td className='f-s-14'>
                                            <span className="badge badge-primary">
                                                {
                                                    t("REFUND_E_CREDIT")
                                                }
                                            </span>
                                        </td>
                                        <td className='f-s-14'><span className="badge badge-green">{t("SUCCESS")}</span></td>
                                        <td className='f-s-14'>{modalData.refundDetail.credit}</td>
                                        <td className='f-s-14'>{modalData.refundDetail.preCredit}</td>
                                        <td className='f-s-14'>{modalData.refundDetail.postCredit}</td>
                                        <td className='f-s-14'>{"-"}</td>
                                        <td className='f-s-14'>{"-"}</td>
                                    </tr>
                                }
                                {
                                    modalData.scoreTransactionId &&
                                    <tr>
                                        <td className='f-s-14'>{moment(modalData.createdTime).format('YYYY-MM-DD hh:mm:ss A')}</td>
                                        <td className='f-s-14'>{modalData.viewerUsername} {modalData.shopName ? '(' + modalData.shopName + ')' : ''}</td>
                                        <td className='f-s-14'>{modalData.username}</td>
                                        <td className='f-s-14'>{modalData.playerUsername ?? "-"}</td>
                                        <td className='f-s-14'>{modalData.vendorGameName ?? "-"}</td>
                                        <td className='f-s-14'>
                                            <span className="badge badge-primary">
                                                {
                                                    modalData.playerUsername ? t("E_POINT") : t("E_CREDIT")
                                                }
                                            </span>
                                        </td>
                                        <td className='f-s-14'><span className="badge badge-green">{t("SUCCESS")}</span></td>
                                        <td className='f-s-14'>{modalData.amount}</td>
                                        <td className='f-s-14'>{modalData.playerUsername ? "-" : modalData.preAmount}</td>
                                        <td className='f-s-14'>{modalData.playerUsername ? "-" : modalData.postAmount}</td>
                                        <td className='f-s-14'>{modalData.playerUsername ? modalData.preAmount : "-"}</td>
                                        <td className='f-s-14'>{modalData.playerUsername ? modalData.postAmount : "-"}</td>
                                    </tr>
                                }
                            </table>
                        </div>
                    </div>
                </ModalBody>
                {
                    modalData.status == OnlineWithdrawalStatus._PENDING_APPROVAL &&
                    <ModalFooter>
                        <button
                            type="button"
                            className="btn btn-success btn-action"
                            onClick={() => {
                                _dispatch(showMessage({
                                    content: t("ARE_YOU_SURE_YOU_WANT_TO_THIS_ONLINE_WITHDRAWAL_BY", { user: modalData.username, action: t('APPROVE') }),
                                    showCancel: true,
                                    confirmBtnText: t("CONFIRM"),
                                    type: AlertTypes._WARNING,
                                    onConfirm: async (value) => {
                                        transactionAction(CommonStatus._APPROVE_STATUS, modalData.id);
                                    }
                                }));
                            }}>
                            {t("APPROVE")}
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger btn-action"
                            onClick={() => {
                                _dispatch(showMessage({
                                    content: t("ARE_YOU_SURE_YOU_WANT_TO_THIS_ONLINE_WITHDRAWAL_BY", { user: modalData.username, action: t('REJECT') }),
                                    showCancel: true,
                                    confirmBtnText: t("CONFIRM"),
                                    type: AlertTypes._WARNING,
                                    onConfirm: async (value) => {
                                        transactionAction(CommonStatus._REJECT_STATUS, modalData.id);
                                    }
                                }));
                            }}>
                            {t("REJECT")}
                        </button>
                    </ModalFooter>
                }
                <Toast style={{ position: 'absolute', bottom: 0, alignSelf: 'center' }} isOpen={showToast} transition={{ key: "1", transitionLeaveTimeout: 3000 }}>
                    <ToastBody>
                        {t('COPIED')}
                    </ToastBody>
                </Toast>
            </Modal>
        </div>
    )
}

export default OnlineWithdrawalRequest;