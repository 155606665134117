import React, { useEffect, useState, useRef } from 'react';
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import { ApiKey, ApiUrl, AlertTypes, Role, Status, CustomResponseCode } from '../../util/Constant';
import SweetAlert from 'react-bootstrap-sweetalert';
import { stringIsNullOrEmpty, ignoreDecimal, isObjectEmpty } from '../../util/Util';
import { useDispatch, useSelector } from 'react-redux';
import { showResponseMessage, showMessage } from 'redux/AppAction.js';
import AccountStatusModal from './AccountStatusModal';

/// <summary>
/// Author: YJ
/// </summary>
const ReloadDetailModal = props => {
    const _userData = useSelector(state => state.authState.userData);
    const [modalVisibility, setModalVisibility] = useState(false);
    const { t } = useTranslation();
    const [vendorCode, setVendorCode] = useState("");
    const [shopId, setShopId] = useState("");
    const [username, setUsername] = useState("");
    const [playerEnable, setPlayerEnable] = useState(false);
    const [playerScore, setPlayerScore] = useState(false);
    const [adjustScoreAmount, setAdjustScoreAmount] = useState("");
    const _dispatch = useDispatch();
    const _inputTxtRef = useRef(null);
    const [status, setStatus] = useState();
    const [changeRequired, setChangeRequired] = useState(false);
    const [remark, setRemark] = useState("");
    const [accountDetail, setAccountDetail] = useState(false);
 
    const _BTN_TYPE = {
        RELOAD: "RELOAD",
        BLOCK: "BLOCK",
        RESET: "RESET"
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        setModalVisibility(props["visible"]);
        setVendorCode(props.vendorCode);
        setUsername(props.username);
        setShopId(props.shopId);
        setStatus(props.status);
        setRemark(props.remark);
    }, [props["visible"]]);

    /// <summary>
    /// Author: YJ
    /// </summary>
    useEffect(() => {
        if (modalVisibility) {
            init();
        }
        else {
            cleanUp(false);
        }
    }, [modalVisibility])

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_PLAYER_DETAIL + "?code=" + vendorCode + "&playerUsername=" + username);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setPlayerScore(responseJson[ApiKey._API_DATA_KEY]["scoreNum"]);
            setPlayerEnable(responseJson[ApiKey._API_DATA_KEY]["status"]);

            if (responseJson[ApiKey._API_DATA_KEY]["status"] != status) {
                setChangeRequired(true)
            }

            if (_inputTxtRef.current) {
                _inputTxtRef.current.focus();
            }
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
            cleanUp(false);
        }
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    function cleanUp(refreshRequired) {
        let processedRefresh = (refreshRequired || changeRequired);
        setChangeRequired(false);
        setVendorCode("");
        setUsername("");
        setShopId("");
        setAdjustScoreAmount("");
        setAccountDetail({});
        setRemark("");
        props.onFinish(processedRefresh);
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function btnHandler(type) {
        let apiUrl = "";
        let useCustomAlert = false;
        switch (type) {
            case _BTN_TYPE.RELOAD:
                if (stringIsNullOrEmpty(adjustScoreAmount) || (!stringIsNullOrEmpty(adjustScoreAmount) && parseFloat(playerScore) + parseFloat(adjustScoreAmount) < 0)) {
                    _dispatch(showResponseMessage(false, t("INVALID_RELOAD_AMOUNT")));
                    return;
                }

                apiUrl = ApiUrl._API_SHOP_SET_SCORE + "?code=" + vendorCode + "&playerUsername=" + username + "&score=" + adjustScoreAmount + "&v=" + + Date.now();
                if (_userData.userRoleId == Role._SHOP) {
                    let details = {
                        "code": vendorCode,
                        "username": username,
                        "amount": adjustScoreAmount
                    };

                    props.onFinish(false, apiUrl, adjustScoreAmount, details);
                    return;
                }
                else {
                    break;
                }
            case _BTN_TYPE.RESET:
                apiUrl = ApiUrl._API_SHOP_RESET_PASSWORD + "?code=" + vendorCode + "&playerUsername=" + username;
                break;
            case _BTN_TYPE.BLOCK: {
                setAccountDetail({
                    fromStatus: playerEnable ? Status._ENABLED : Status._DISABLED,
                    toStatus: !playerEnable ? Status._ENABLED : Status._DISABLED,
                    remark: remark
                });
                useCustomAlert = true;
                break;
            }
            default:
                return;
        }

        if (!stringIsNullOrEmpty(shopId)) {
            apiUrl += "&shopId=" + shopId;
        }

        if (!stringIsNullOrEmpty(apiUrl) && !useCustomAlert) {
            var responseJson = await ApiEngine.post(apiUrl);

            if (!responseJson[ApiKey._API_SUCCESS_KEY] && !stringIsNullOrEmpty(responseJson[ApiKey._API_CODE_KEY]) && responseJson[ApiKey._API_CODE_KEY] == CustomResponseCode._ROLLOVER_APPEAL_REQUIRED)
            {
                let details = {
                    "code": vendorCode,
                    "username": username,
                    "amount": adjustScoreAmount
                };
                props.onFinish(false, null, null, details, true)
            }
            else
            {
                cleanUp(responseJson[ApiKey._API_SUCCESS_KEY]);
                _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
            }
        }
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function performBlockAction(remark) {
        let apiUrl = ApiUrl._API_SHOP_SET_PLAYER_STATUS + "?code=" + vendorCode + "&playerUsername=" + username + "&status=" + (playerEnable ? "false" : "true") + "&remark=" + remark;

        if (!stringIsNullOrEmpty(shopId)) {
            apiUrl += "&shopId=" + shopId;
        }

        if (!stringIsNullOrEmpty(apiUrl)) {
            var responseJson = await ApiEngine.post(apiUrl);

            cleanUp(responseJson[ApiKey._API_SUCCESS_KEY]);
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    return (
        <div>
            <AccountStatusModal 
                visible={!isObjectEmpty(accountDetail)} 
                detail={accountDetail}
                onFinish={(isConfirm, remark) => {
                    if(isConfirm) {
                        performBlockAction(remark);
                    }
                    setAccountDetail({});
                }} />
            {modalVisibility && <SweetAlert
                type={AlertTypes._INFO}
                title={`${t('INFO')}: ${username}`}
                customButtons={<div style={{ width: "100%" }}>
                    <div className="form-group">
                        <input type="number" className="form-control form-control-lg"
                            step=".01"
                            onKeyDown={e => ignoreDecimal(e, _userData?.username?.includes("demo") || _userData?.isAdminLevel)}
                            ref={_inputTxtRef} value={adjustScoreAmount} onChange={(e) => { setAdjustScoreAmount(e.target.value) }} />
                    </div>
                    <div style={{ float: "right" }}>
                        <button className="btn btn-primary" type="button" onClick={() => { btnHandler(_BTN_TYPE.RELOAD) }}>
                            {t('RELOAD')}
                        </button>
                        <button className="btn btn-primary" type="button" onClick={() => { btnHandler(_BTN_TYPE.BLOCK) }}>
                            {playerEnable ? t('BLOCK') : t('UNBLOCK')}
                        </button>
                        <button className="btn btn-primary" type="button" onClick={() => { btnHandler(_BTN_TYPE.RESET) }}>
                            {t('RESET')}
                        </button>
                        <button className="btn btn-default" type="button" onClick={() => { cleanUp(false); }}>
                            {t("CLOSE")}
                        </button>
                    </div>
                </div>}>
                <div>{t('SCORE')}: {playerScore}</div>
                <div>{t('STATUS')}: <b className={playerEnable ? "text-green" : "text-red"}>{playerEnable ? t('ENABLED') : t('DISABLED')}</b></div>
            </SweetAlert>}
        </div>
    );
}
export default ReloadDetailModal;