import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { showResponseMessage } from "../../redux/AppAction";
import { ApiKey, ApiUrl, Status } from "../../util/Constant";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ApiEngine from '../../util/ApiEngine.js';
import { stringIsNullOrEmpty } from '../../util/Util';

/// <summary>
/// Author : Wind
/// </summary>
const ReferralCodeModal = (props) => {
    const [modalVisibility, setModalVisibility] = useState(false);
    const { register, handleSubmit, setValue, errors, reset, unregister, watch } = useForm();
    const { t, i18n } = useTranslation();
    var _dispatch = useDispatch();

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        setModalVisibility(props["visible"]);
    }, [props["visible"]]);

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        if (modalVisibility) {
            register({ name: "referralCode" }, { required: "PLEASE_ENTER_REFERRAL_CODE" });
        }
        else {
            setValue("referralCode", "");
            unregister("referralCode");
        }
    }, [modalVisibility]);

    /// <summary>
    /// Author : Wind
    /// </summary>
    const onSubmit = async (data, e) => {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SHOP_BY_REFERRAL_CODE + "?referralCode=" + data.referralCode);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            props.onFinish(responseJson);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));  
        }
    }

    return (
        <Modal isOpen={modalVisibility} toggle={() => props.onFinish()}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={() => props.onFinish()}>
                    <h4>{t('ENTER_REFERRAL_CODE')}</h4>
                </ModalHeader>
                <ModalBody>
                    <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label"><b>{t("REFERRAL_CODE")}</b></label>
                        <div className="col-md-7">
                            <textarea
                                className="form-control form-control-lg"
                                autoComplete="off"
                                rows="2"
                                id="referralCode"
                                name="referralCode"
                                placeholder={t("ENTER_REFERRAL_CODE")}
                                onChange={(e) => { setValue("referralCode", e.target.value) }} />
                            {errors.referralCode && <div className="invalid-feedback">{t(errors.referralCode.message)}</div>}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-primary">{t('BTN_GET_SHOP')}</button>
                    <button type="button" className="btn btn-white"
                        onClick={() => props.onFinish()}>{t('BTN_CLOSE')}</button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default ReferralCodeModal