import React, { useState, useEffect } from 'react';
import { ApiKey, ApiUrl, Role } from '../../util/Constant';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ApiEngine from '../../util/ApiEngine';
import { getValidationMessage, stringIsNullOrEmpty } from '../../util/Util';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import moment from 'moment';
import DateTime from 'react-datetime';
import ReactTable from "./ReactTable";
import { showResponseMessage } from "../../redux/AppAction";

/// <summary>
/// Author: Wind
/// </summary>
const KogEventLogModal = props => {
    const { t, i18n } = useTranslation();
    const _dispatch = useDispatch();
    const { register, handleSubmit, errors, unregister, setValue } = useForm();
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [member, setMember] = useState();
    const [scoreLogData, setScoreLogData] = useState([]);

    let _scoreLogTableColumns = [
        {
            Header: t("CREATED_DATE"),
            accessor: "createdTime",
            disableSortBy: true,
        },
        {
            Header: t("SCORE"),
            accessor: "amount",
            Cell: ({ row }) => {
                return row.original.amount > 0 ? <span className="text-success">{parseFloat(row.original.amount).toFixed(2)}</span> : <span className="text-danger">{parseFloat(row.original.amount).toFixed(2)}</span>;
            },
            disableSortBy: true,
        },
        {
            Header: t("PLAYER_ID"),
            accessor: "username",
            Cell: ({ row }) => {
                return <span>{stringIsNullOrEmpty(row.original.username) ? '-' : row.original.username}</span>
            },
            disableSortBy: true,
        },
        {
            Header: t("OPERATED_BY"),
            accessor: "createdBy",
            Cell: ({ row }) => {
                return <span>{stringIsNullOrEmpty(row.original.createdBy) ? '-' : row.original.createdBy}</span>
            },
            disableSortBy: true,
        }
    ];

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        if (props.isModalVisible) {
            register({ name: 'startDate' }, { required: true });
            register({ name: 'endDate' }, { required: true });
            setMember(props.member)
            setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
            setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
            setStartDate(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
            setEndDate(moment().format("YYYY-MM-DD HH:mm:ss"));
        }
        else {
            unregister('startDate');
            unregister('endDate');

            setStartDate('');
            setEndDate('');
            setMember(null);
            setScoreLogData([]);
        }
    }, [props.isModalVisible]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function onSubmit() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_KOG_DETAIL + "?MemberId=" + member.memberId + "&EventId=" +
            member.eventId + "&StartDate=" + startDate + "&EndDate=" + endDate);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setScoreLogData(responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    return (
        <Modal isOpen={props.isModalVisible} toggle={() => { props.onFinish() }} size="lg">
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={() => { props.onFinish() }}><h4>{t("SCORE_LOG") + ' (' + (member ? member.username : '') + ')'}</h4></ModalHeader>
                <ModalBody>
                    <div className="row m-b-10">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("START_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="startDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={startDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                            setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("startDate", "");
                                        }
                                    }} />
                                {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("END_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="endDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={endDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                            setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("endDate", "");
                                        }
                                    }} />
                                {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                            </div>
                        </div>
                    </div>
                    <ReactTable data={scoreLogData} columns={_scoreLogTableColumns} />
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-primary btn-action">{t("BTN_SUBMIT")}</button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default KogEventLogModal;