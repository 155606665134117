import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelHeader,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import ReactTable from "../../../components/custom/ReactTable";
import { ApiKey, ApiUrl, WebUrl, AccessRight, Role, UPPER_LIMIT_WARNING_LEVEL } from "../../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody, getValidationMessage } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';
import DualListBoxItemRow from "components/custom/DualListBoxItemRow.js";

/// <summary>
/// Author : CK
/// </summary>
const UpperLimitWarningReport = props => {
    const { t } = useTranslation();
    const { register, handleSubmit, setValue, errors } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [selectedShop, setSelectedShop] = useState([]);
    const [shopOption, setShopOption] = useState([]);
    const [regionOption, setRegionOption] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([])
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [shopAvailableList, setShopAvailableList] = useState([]);
    const [postData, setPostData] = useState({});
    const [defaultManagerOption, setDefaultManagerOption] = useState([]);
    const [managerOption, setManagerOption] = useState([]);
    const [selectedManager, setSelectedManager] = useState([]);

    let _tableColumns = [
        {
            Header: "No",
            id: "row",
            filterable: false,
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            disableSortBy: true,
            width: 35
        },
        {
            Header: "DATE",
            accessor: "date",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{moment(row.original.date).format("YYYY-MM-DD HH:mm:ss")}</div>
            }
        },
        {
            Header: "SHOP",
            accessor: "shopName",
            disableSortBy: true
        },
        {
            Header: "MANAGER",
            accessor: "managerName",
            disableSortBy: true
        },
        {
            Header: "EVENT_TYPE",
            accessor: "eventType",
            disableSortBy: true,
            width: 80,
            Cell: ({ row }) => {
                let rowValue = row.original;

                return (
                    rowValue.isApproval ?
                        <span className="badge badge-info">{t(rowValue.eventType)}</span> :
                        (rowValue.level == UPPER_LIMIT_WARNING_LEVEL._REACH_LIMIT ||
                            rowValue.level == UPPER_LIMIT_WARNING_LEVEL._BELOW_LIMIT ?
                            <span className="badge badge-warning">{t(rowValue.eventType)} {rowValue.thresholdPercentage + "%"}</span>
                            :
                            rowValue.level == UPPER_LIMIT_WARNING_LEVEL._EXCEED_LIMIT ||
                                rowValue.level == UPPER_LIMIT_WARNING_LEVEL._REACH_ZERO ? <span className="badge badge-danger">{t(rowValue.eventType)}</span>
                                : <span className="badge badge-green">{t("NORMAL")}</span>));
            }
        },
        {
            Header: "CREDIT_LIMIT",
            accessor: "shopLimit",
            Cell: ({ row }) => {
                return (row.original.isApproval ? row.original.shopLimit : row.original.shopLimit + " / " + row.original.upperLimit)
            },
            disableSortBy: true
        }
    ]

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'shops' });
        register({ name: 'manager' });
        register({ name: 'endDate' }, { required: true });

        setValue('shops', []);
        setValue('manager', []);
        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
        init();
    }, []);

    /// <summary>
    /// Author : CK
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_REGION)

        var regionList = [];
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {

            responseJson[ApiKey._API_DATA_KEY].forEach(function (region) {
                regionList.push({ label: region.name, value: region.id });
            });

            setRegionOption(regionList);
        }

        var apiUrl = ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP;

        responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let shopOptionsByRegion = [];
            regionList.map(region => {
                let shopsByRegion = responseJson[ApiKey._API_DATA_KEY].filter(shop => shop.regionId == region.value);

                if (shopsByRegion.length) {
                    let options = [];

                    shopsByRegion.map(shop => {
                        options.push({
                            label: shop.username + " (" + shop.shopName + ")",
                            value: shop.id,
                            regionId: shop.regionId
                        });
                    });

                    shopOptionsByRegion.push({
                        label: region.label,
                        value: region.value,
                        options: options
                    })
                }
            })

            setShopOption(shopOptionsByRegion);
        }

        var responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS + "?roleId=" + Role._MANAGER);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var managerOptionList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (user) {
                managerOptionList.push({ label: user.username, value: user.id, regionId: user.regionId });
            });

            setDefaultManagerOption(managerOptionList);
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    function onSelectedRegionChange(e) {
        if (selectedRegions.filter(selectedRegion => selectedRegion == e.target.value).length) {
            setSelectedRegions(selectedRegions.filter(selectedRegion => selectedRegion != e.target.value));
        }
        else {
            setSelectedRegions([...selectedRegions, e.target.value]);
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (shopOption.length) {
            let tempShopAvailableList = [];
            selectedRegions.map(selectedRegion => {
                let filteredShops = shopOption.filter(shopOption => shopOption.value == selectedRegion);

                if (filteredShops.length && filteredShops[0].options.length) {
                    filteredShops[0].options.map(filteredShop => {
                        tempShopAvailableList.push(filteredShop.value);
                    })
                }
            })

            setShopAvailableList(tempShopAvailableList);
        }
    }, [selectedRegions, shopOption]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        if (defaultManagerOption.length) {
            let availableList = [];
            selectedRegions.map(selectedRegion => {
                let filteredManagers = defaultManagerOption.filter(manager => manager.regionId == selectedRegion);

                filteredManagers.map(manager => {
                    availableList.push({ label: manager.label, value: manager.value });
                })
            })

            setManagerOption(availableList);
        }
    }, [selectedRegions]);

    /// <summary>
    /// Author : CK
    /// </summary>
    const submitForm = async (data, e) => {
        setPostData({
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "ShopIds": data.shops.join(','),
            "ManagerIds": data.manager.join(',')
        });

        setApiUrl(ApiUrl._API_GET_SHOP_LIMIT_REQUEST_LOGS);
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable columns={_tableColumns} postData={postData} fetchUrl={apiUrl} exportRequired={true} />, [postData, apiUrl])

    return (
        <div>
            <h1 className="page-header">{t("UPPER_LIMIT_WARNING_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                    {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                    {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="row m-b-10">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    {
                                        regionOption.map((region, index) => {
                                            return (
                                                <div className="checkbox checkbox-css checkbox-inline">
                                                    <input id={region.label + region.value} type="checkbox" name={region.label + region.value}
                                                        checked={selectedRegions.filter(selectedRegion => selectedRegion == region.value).length}
                                                        value={region.value}
                                                        ref={register}
                                                        onChange={onSelectedRegionChange} />
                                                    <label htmlFor={region.label + region.value}>{region.label}</label>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("MANAGER_USERNAME")}</label>
                                </div>
                            </div>
                        </div>
                        <div className="row m-b-10">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <DualListBoxItemRow options={shopOption} selected={selectedShop} />
                                    <DualListBox
                                        canFilter
                                        name="shops"
                                        options={shopOption}
                                        selected={selectedShop}
                                        available={shopAvailableList}
                                        onChange={(e) => {
                                            setValue('shops', e);
                                            setSelectedShop(e);
                                        }}
                                        ref={register}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <DualListBoxItemRow options={managerOption} selected={selectedManager} />
                                    <DualListBox
                                        canFilter
                                        name="manager"
                                        options={managerOption}
                                        selected={selectedManager}
                                        onChange={(e) => {
                                            setValue('manager', e);
                                            setSelectedManager(e);
                                        }}
                                        ref={register}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
}

export default UpperLimitWarningReport;