import React, { useState } from 'react';
import { Panel, PanelBody } from '../../components/panel/panel';
import { ApiKey, WebUrl, ApiUrl, AlertTypes, TransactionRequestStatus, TransactionTypeId, TransactionType, FirebaseKey } from '../../util/Constant';
import { isObjectEmpty, numberWithCurrencyFormat, stringIsNullOrEmpty } from '../../util/Util';
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { showMessage, showResponseMessage, disposeMessage } from 'redux/AppAction';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import QRScanner from './QrScanner';
import moment from 'moment';
import { initFirebaseTransactionRequestDatabase } from 'redux/FirebaseAction';
import { useEffect } from 'react';

/// <summary>
/// Author: YJ
/// </summary>
const TransactionRequest = props => {
    let _history = useHistory();
    let _dispatch = useDispatch();
    const { requestCode } = useSelector(state => state.firebaseState);
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, errors } = useForm();
    const [requestDetail, setRequestDetail] = useState({});
    const _HIDE_SETTLE_BUTTON_ARRAY = [TransactionRequestStatus._EXPIRED, TransactionRequestStatus._REJECTED, TransactionRequestStatus._SUCCESS];

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function searchRequest(data) {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_TRANSACTION_REQUEST + "?code=" + data.code);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setRequestDetail(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(responseJson[ApiKey._API_MESSAGE_KEY])
            }));
        }
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(requestCode) && requestDetail["code"] == requestCode && requestDetail["status"] == TransactionRequestStatus._PENDING_PASSWORD_CONFIRMATION) {
            _dispatch(disposeMessage());
            searchRequest(requestDetail);
        }
    }, [requestCode])

    /// <summary>
    /// Author: YJ
    /// </summary>
    useEffect(() => {
        if (requestDetail.status == TransactionRequestStatus._PENDING_PASSWORD_CONFIRMATION) {
            _dispatch(initFirebaseTransactionRequestDatabase(FirebaseKey._TRANSACTION_REQUEST + requestDetail.memberId, requestDetail.code));
        }
    }, [requestDetail])

    /// <summary>
    /// Author: YJ
    /// </summary>
    function showPasswordConfirmation() {
        _dispatch(showMessage({
            content: t("VERIFY_PASSWORD_BEFORE_CONTINUE"),
            showCancel: true,
            required: true,
            inputType: 'password',
            validationMsg: t('INVALID_PASSWORD'),
            confirmBtnText: t("CONFIRM"),
            type: AlertTypes._INPUT,
            onConfirm: async (value) => {
                await settleTransactionRequst(TransactionRequestStatus._SUCCESS, value);
            }
        }));
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function settleTransactionRequst(status, password = "") {
        try {
            /// required password confirmation and before settle already is pending
            if (stringIsNullOrEmpty(password) && status == TransactionRequestStatus._SUCCESS && requestDetail.status == TransactionRequestStatus._PENDING_PASSWORD_CONFIRMATION) {
                showPasswordConfirmation();
                return;
            }

            let postUrl = ApiUrl._API_SETTLE_TRANSACTION_REQUEST + "?code=" + requestDetail.code + "&status=" + status;

            if (!stringIsNullOrEmpty(password)) {
                postUrl += "&password=" + password;
            }

            var responseJson = await ApiEngine.post(postUrl);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let returnDetail = responseJson[ApiKey._API_DATA_KEY];

                /// required password confirmation
                if (returnDetail.status == TransactionRequestStatus._PENDING_PASSWORD_CONFIRMATION) {
                    showPasswordConfirmation();
                }
                else {
                    _dispatch(showMessage({
                        type: AlertTypes._SUCCESS,
                        content: t("OPERATION_SUCCESS")
                    }));
                }

                setRequestDetail(returnDetail);
            }
            else {
                if (!isObjectEmpty(responseJson[ApiKey._API_DATA_KEY] ?? {})) {
                    setRequestDetail(responseJson[ApiKey._API_DATA_KEY]);
                }
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
        }
        catch (err) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: err
            }));
        }
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    function constructStatusBadge(status, statusString) {
        let statusBadgeClass = "primary";
        switch (status) {
            case TransactionRequestStatus._SUCCESS:
                statusBadgeClass = "success";
                break;
            case TransactionRequestStatus._REJECTED:
                statusBadgeClass = "danger";
                break;
            case TransactionRequestStatus._EXPIRED:
                statusBadgeClass = "secondary";
                break;
            default:
                statusBadgeClass = "primary";
                break;
        }
        return <span className={"badge badge-" + statusBadgeClass}>{statusString}</span>
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    function constructTransactionType(transactionType) {
        let transactionTypeString = "";

        switch (transactionType) {
            case TransactionType._SET_SCORE:
                transactionTypeString = t("RELOAD");
                break;
            case TransactionType._WALLET_TRANSFER:
                transactionTypeString = t("CREDIT");
                break;
        }

        return <span className="badge badge-primary">{transactionTypeString}</span>
    }

    return (
        <div id="page-content">
            <div>
                {isObjectEmpty(requestDetail ?? {}) && <Panel>
                    <PanelBody>
                        <h4>{t('REQUEST')}</h4>
                        <hr />
                        <div className="row">
                            <div className="col-lg-12">
                                <form onSubmit={handleSubmit(searchRequest)}>
                                    <div className="row align-items-center">
                                        <div className="col-lg-8">
                                            <input autoFocus="on" className="input-search form-control form-control-lg m-b-5" name="code" ref={register} placeholder={t('ENTER_CODE')} type="text" />
                                        </div>
                                        <div className="col-lg-4">
                                            <button type="submit" className="btn btn-primary">{t('SEARCH')}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <h2 style={{ width: "100%", textAlign: "center", borderBottom: "1px solid #000", lineHeight: "0.1em", margin: "2rem 0 2rem" }}>
                            <span style={{ padding: "0 10px", backgroundColor: "white" }}>OR</span>
                        </h2>
                        <div className="row" style={{ justifyContent: "center" }}>
                            <div className="col-lg-12" style={{ maxWidth: "500px" }}>
                                <QRScanner onQrDetect={searchRequest} />
                            </div>
                        </div>
                    </PanelBody>
                </Panel>}
                <Modal centered isOpen={!isObjectEmpty(requestDetail ?? {})} toggle={() => { setRequestDetail({}) }} size="lg">
                    <ModalHeader toggle={() => { setRequestDetail({}) }}><h4>{t('REQUEST_DETAIL')} ({requestDetail.code})</h4></ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-lg-3">
                                <label>{`${t('USERNAME')}`}:</label>
                            </div>
                            <div className="col-lg-6">
                                {requestDetail.memberUsername}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <label>{`${t('TYPE')}`}:</label>
                            </div>
                            <div className="col-lg-6">
                                {constructTransactionType(requestDetail.transactionTypeName)}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <label>{`${t('AMOUNT')}`}:</label>
                            </div>
                            <div className="col-lg-6">
                                {numberWithCurrencyFormat(requestDetail.amount)}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <label>{`${t('STATUS')}`}:</label>
                            </div>
                            <div className="col-lg-6">
                                {constructStatusBadge(requestDetail.status, requestDetail.statusString)}
                            </div>
                        </div>
                        {requestDetail.transactionTypeId == TransactionTypeId._SET_SCORE && <>
                            <div className="row">
                                <div className="col-lg-3">
                                    <label>{`${t('PLAYER_USERNAME')}`}:</label>
                                </div>
                                <div className="col-lg-6">
                                    {requestDetail.playerUsername}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <label>{`${t('VENDOR')}`}:</label>
                                </div>
                                <div className="col-lg-6">
                                    {requestDetail.playerVendor}
                                </div>
                            </div>
                        </>}
                        <div className="row">
                            <div className="col-lg-3">
                                <label>{`${t('CREATED_TIME')}`}:</label>
                            </div>
                            <div className="col-lg-6">
                                {moment(requestDetail.createdTime).format("YYYY-MM-DD HH:mm:ss")}
                            </div>
                        </div>
                        {!stringIsNullOrEmpty(requestDetail.expiredTime) && <div className="row">
                            <div className="col-lg-3">
                                <label>{`${t('EXPIRE_AT')}`}:</label>
                            </div>
                            <div className="col-lg-6">
                                {moment(requestDetail.expiredTime).format("YYYY-MM-DD HH:mm:ss")}
                            </div>
                        </div>}
                    </ModalBody>
                    <ModalFooter>
                        {(!_HIDE_SETTLE_BUTTON_ARRAY.includes(requestDetail.status)) && <div className="pull-right">
                            <button type="button" style={{ marginRight: "1rem" }} className="btn btn-danger btn-action" onClick={() => { settleTransactionRequst(TransactionRequestStatus._REJECTED); }}>{t('REJECT')}</button>
                            <button type="button" className="btn btn-primary btn-action" onClick={() => { settleTransactionRequst(TransactionRequestStatus._SUCCESS); }}>{t('APPROVE')}</button>
                        </div>}
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    );
}

export default TransactionRequest;