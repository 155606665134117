import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { ApiKey, ApiUrl, AccessRight, AlertTypes, Status } from '../../../util/Constant';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { stringIsNullOrEmpty, getValidationMessage } from "../../../util/Util";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useDispatch } from "react-redux";
import { showMessage } from "../../../redux/AppAction";
import ApiEngine from '../../../util/ApiEngine.js';
import moment from 'moment';
import { useForm } from "react-hook-form";
import DateTime from 'react-datetime';
import KogEventLogModal from '../../../components/custom/KogEventLogModal';
import Select from 'react-select';

/// <summary>
/// Author: Wind
/// </summary> 
const KogEventRanking = props => {
    const { t } = useTranslation();
    const { setValue, errors, handleSubmit, register, watch } = useForm();
    var _location = useLocation();
    let { search } = useLocation();
    const _query = new URLSearchParams(search);
    const _ID = _query.get('id');
    const _NAME = _query.get('name');
    var _dispatch = useDispatch();
    const [apiUrl, setApiUrl] = useState('');
    const [member, setMember] = useState('');
    const [selectedMember, setSelectedMember] = useState();
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));
    const [modalData, setModalData] = useState();
    const [scoreLogModalVisibility, setScoreLogModalVisibility] = useState(false);
    const [eventId, setEventId] = useState();
    const [status, setStatus] = useState(null);

    const _STATUS_OPTION = [
        { label: t('ALL'), value: null },
        { label: t('QUALIFIED'), value: false },
        { label: t('DISQUALIFIED'), value: true }
    ];

    /// <summary>
    /// Author: Wind
    /// </summary>
    let _eventRankingColumns = [
        {
            Header: "RANK",
            accessor: "rankPosition",
            disableSortBy: true
        },
        {
            Header: "MEMBER",
            accessor: "username",
            disableSortBy: true
        },
        {
            Header: "SCORE",
            accessor: "winAmount",
            disableSortBy: true
        },
        {
            Header: "STATUS",
            accessor: "isQualified",
            disableSortBy: true,
            Cell: ({ row }) => {
                let returnClassName = "badge badge-success";
                let statusName = "QUALIFIED";

                if (!row.original.isQualified) {
                    returnClassName = "badge badge-danger";
                    statusName = "DISQUALIFIED";
                }

                return <span className={returnClassName} style={{ textTransform: "uppercase" }}>{t(statusName)}</span>
            },
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._EVENT_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                setSelectedMember(row.original);
                                setScoreLogModalVisibility(true);
                            }}>
                                <span>{t('VIEW_DETAIL_LOG')}</span>
                            </DropdownItem>
                            {
                                row.original.isQualified &&
                                <DropdownItem onClick={() => {
                                    setModalData(row.original);
                                }}>
                                    <span>{t('MANUAL_ADD_SCORE')}</span>
                                </DropdownItem>
                            }
                            <DropdownItem onClick={() => {
                                updateMemberQualification(row.original.memberId);
                            }}>
                                <span className={row.original.isQualified ? "text-danger" : "text-success"}>
                                    {row.original.isQualified ? t('DISQUALIFIED') : t('QUALIFIED')}
                                </span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ];

    /// <summary>
    /// Author: Wind
    /// </summary> 
    useEffect(() => {
        applyFilter();
    }, []);

    /// <summary>
    /// Author : Wind
    /// </summary> 
    async function applyFilter() {
        if (!stringIsNullOrEmpty(_ID)) {
            setApiUrl(ApiUrl._API_GET_KOG_RANKING + '?EventId=' + _ID + '&MemberUsername=' + member +
                "&StartDate=" + startDate + "&EndDate=" + endDate + 
                (status != null ? '&IncludeDisqualifiedMembers=' + status : '') + 
                '&v=' + moment.now());
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary> 
    async function updateMemberQualification(memberId) {
        var responseJson = await ApiEngine.post(ApiUrl._API_QUALIFY_KOG_DETAIL + '?eventId=' + _ID + '&memberId=' + memberId);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            applyFilter();
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable columns={_eventRankingColumns} fetchUrl={apiUrl} exportRequired={true} />, [apiUrl])

    /// <summary>
    /// Author : Wind
    /// </summary>
    async function onSubmit(data, e) {
        var responseJson = await ApiEngine.post(ApiUrl._API_GET_KOG_DETAIL + '?eventId=' + modalData.eventId +
            '&memberId=' + modalData.memberId + '&amount=' + data.setScoreAmount);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setModalData();
            applyFilter();
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    return (
        <div>
            <h1 className="page-header">
                {_NAME + ' ' + t('EVENT_RANKING')}
            </h1>
            <Panel>
                <PanelBody>
                    <div className="row m-b-10">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("MEMBER")}</label>
                                <input
                                    type="text"
                                    name="member"
                                    className="form-control form-control-lg"
                                    onInput={(e) => setMember(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("START_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="startDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={startDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                    }} />
                                {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("END_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="endDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={endDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                            setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                    }} />
                                {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("STATUS")}</label>
                                <Select
                                    name="status"
                                    options={_STATUS_OPTION}
                                    value={_STATUS_OPTION.filter(option => option.value == status)}
                                    onChange={(e) => {
                                        setStatus(e.value);
                                    }} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <button type="button" className="btn btn-primary" onClick={() => { applyFilter() }}>{t('APPLY_FILTER')}</button>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            {PaginationTable}
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <KogEventLogModal
                isModalVisible={scoreLogModalVisibility}
                member={selectedMember}
                onFinish={() => setScoreLogModalVisibility(false)} />
            <Modal isOpen={modalData != null} toggle={() => { setModalData() }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={() => { setModalData() }}><h4>{t("SET_SCORE")}</h4></ModalHeader>
                    <ModalBody>
                        <div className="form-group m-b-15 ">
                            <label>{t("CURRENT_SCORE") + ' (' + (modalData ? modalData.username : '') + ')'}</label>
                            <input
                                className="form-control form-control-lg m-b-5"
                                disabled={true}
                                type="text"
                                value={modalData ? modalData.winAmount : 0}
                                name="currentScore" />
                        </div>
                        <div className="form-group m-b-15 ">
                            <label>{t("SCORE")}</label>
                            <input
                                className="form-control form-control-lg m-b-5"
                                type="number"
                                step=".01"
                                name="setScoreAmount"
                                ref={register({ required: true, min: modalData ? -modalData.winAmount : 0 })} />
                            {errors.setScoreAmount && <div className="invalid-feedback">{t(getValidationMessage(errors.setScoreAmount))}</div>}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-primary btn-action">{t("BTN_SUBMIT")}</button>
                        <button type="button" className="btn btn-danger btn-action" onClick={() => setModalData()}>{t("BTN_CANCEL")}</button>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    )
}

export default KogEventRanking;