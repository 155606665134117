import ReactTable from 'components/custom/ReactTable';
import { Panel, PanelBody } from 'components/panel/panel';
import React, { useEffect, useMemo, useState } from 'react';
import DualListBox from 'react-dual-listbox';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ApiEngine from 'util/ApiEngine';
import { ApiKey, ApiUrl } from 'util/Constant';
import { arrayGroupByKey, numberWithCurrencyFormat } from '../../../util/Util';
import moment from 'moment';
import DateTime from 'react-datetime';
import DualListBoxItemRow from 'components/custom/DualListBoxItemRow';

/// <summary>
/// Author : A
/// </summary>
const AgentNetProfitReport = () => {
    const { t } = useTranslation();
    const [apiUrl, setApiUrl] = useState("");
    const [postData, setPostData] = useState({});
    const [agents, setAgents] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'));
    const [endDate, setEndDate] = useState(moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'));

    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            startDate: startDate,
            endDate: endDate
        }
    });

    const _COLUMNS = [
        {
            Header: "No.",
            id: "row",
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            disableSortBy: true,
            width: '1%'
        },
        {
            Header: "AGENT",
            accessor: "userFullName",
            disableSortBy: true
        },
        {
            Header: "TOTAL_DEPOSITS",
            accessor: "totalDeposit",
            disableSortBy: true,
            Cell: ({ value }) => numberWithCurrencyFormat(value, 2),
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.totalDeposit);
                }, 0);

                return <strong>{totalAmount.toFixed(2)}</strong>
            },
        },
        {
            Header: "TOTAL_WITHDRAWS",
            accessor: "totalWithdrawal",
            disableSortBy: true,
            Cell: ({ value }) => numberWithCurrencyFormat(value, 2),
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.totalWithdrawal);
                }, 0);

                return <strong>{totalAmount.toFixed(2)}</strong>
            },
        },
        {
            Header: "TOTAL_DEPOSIT_COUNT",
            accessor: "depositCount",
            disableSortBy: true
        },
        {
            Header: "TOTAL_WITHDRAW_COUNT",
            accessor: "withdrawalCount",
            disableSortBy: true
        },
        {
            Header: "TOTAL_PROFIT",
            accessor: "total_profit",
            disableSortBy: true,
            Cell: ({ row }) => numberWithCurrencyFormat(row.original.totalDeposit - row.original.totalWithdrawal, 2),
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, value) => {
                    return sum + parseFloat(value.original.totalDeposit - value.original.totalWithdrawal);
                }, 0);

                return <strong>{totalAmount.toFixed(2)}</strong>
            },
        }
    ]

    /// <summary>
    /// Author : A
    /// </summary>
    const PaginationTable = useMemo(() =>
        <ReactTable
            columns={_COLUMNS}
            fetchUrl={apiUrl}
            postData={postData}
            exportRequired={true}
            renderFooter={true}
        />
        , [apiUrl, postData])

    /// <summary>
    /// Author : A
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : A
    /// </summary>
    const submitForm = async (data, e) => {
        setPostData({
            Agents: selectedAgents,
            StartDate: startDate,
            EndDate: endDate
        });
        setApiUrl(ApiUrl._API_GET_AGENT_NETPROFIT_REPORT);
    }

    /// <summary>
    /// Author : A
    /// </summary>
    const init = async () => {
        await ApiEngine.get(ApiUrl._API_GET_AGENT_LIST)
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    var data = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "regionName");
                    var optionList = [];

                    for (var key in data) {
                        var agentList = [];

                        data[key].map(shop => {
                            agentList.push({ label: shop.username, value: shop.id });
                        });

                        optionList.push({
                            label: key,
                            value: key,
                            options: agentList
                        })
                    }

                    setAgents(optionList);
                }
            })
    }

    return (
        <div>
            <h1 className="page-header d-flex justify-content-between">
                {t("AGENT_NET_PROFIT_REPORT")}
            </h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row">
                            <div className="col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>{t("AGENT")}</label>
                                    <DualListBoxItemRow options={agents} selected={selectedAgents} />
                                    <DualListBox
                                        ref={register}
                                        canFilter
                                        name="agents"
                                        options={agents}
                                        selected={selectedAgents}
                                        onChange={(e) => {
                                            setValue('agents', e);
                                            setSelectedAgents(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        ref={register}
                                        defaultValue={moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')}
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        ref={register}
                                        defaultValue={moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')}
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">{t("SUBMIT")}</button>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
};

export default AgentNetProfitReport;