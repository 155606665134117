import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelHeader,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import ReactTable from "../../../components/custom/ReactTable";
import { ApiKey, ApiUrl, WebUrl, AccessRight, Role } from "../../../util/Constant";
import { stringIsNullOrEmpty, getValidationMessage, navigateTo } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useHistory } from 'react-router-dom';

/// <summary>
/// Author : Wind
/// Editted : CK - Sortable total count
/// </summary> 
const OverallDownlineReport = props => {
    const _SORTABLE_COLUMNS = {
        TOTAL_COUNT: -1,
        SELF_REWARD: 1,
        DOWNLINE_REWARD: 2
    }

    var _history = useHistory();
    const { t } = useTranslation();
    const { register, handleSubmit, setValue, errors } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [phone, setPhone] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));
    const [regionId, setRegionId] = useState('');
    const [regions, setRegions] = useState([]);
    const [orderByAsc, setOrderByAsc] = useState(false);
    const [orderByColumn, setOrderByColumn] = useState(_SORTABLE_COLUMNS.TOTAL_COUNT);
    const [firstTimeInit, setFirstTimeInit] = useState(true);

    let _tableColumns = [
        {
            Header: "No",
            id: "row",
            filterable: false,
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            disableSortBy: true,
            width: 50
        },
        {
            Header: "USERNAME",
            accessor: "username",
            disableSortBy: true
        },
        {
            Header: "TOTAL_DOWNLINE",
            accessor: "downlineCount",
            sortColumn: (orderByColumn == _SORTABLE_COLUMNS.TOTAL_COUNT),
            isAsc: orderByAsc,
            showSortIcon: true,
            disableSortBy: true,
            clickable: () => { sortTable(_SORTABLE_COLUMNS.TOTAL_COUNT); }
        },
        {
            Header: "SELF_EARNED_REWARD",
            accessor: "selfEarnedReward",
            disableSortBy: true,
            sortColumn: (orderByColumn == _SORTABLE_COLUMNS.SELF_REWARD),
            isAsc: orderByAsc,
            showSortIcon: true,
            clickable: () => { sortTable(_SORTABLE_COLUMNS.SELF_REWARD); }
        },
        {
            Header: "DOWNLINE_EARNED_REWARD",
            accessor: "downlineEarnedReward",
            disableSortBy: true,
            sortColumn: (orderByColumn == _SORTABLE_COLUMNS.DOWNLINE_REWARD),
            isAsc: orderByAsc,
            showSortIcon: true,
            clickable: () => { sortTable(_SORTABLE_COLUMNS.DOWNLINE_REWARD); }
        },
        {
            Header: "REGION",
            accessor: "regionName",
            disableSortBy: true
        },
        {

            id: "action",
            Header: "",
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu style={{ 'overflow-y': 'scroll' }}>
                            <DropdownItem onClick={() => {
                                navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_MEMBER_DOWNLINE, { id: row.original.userId, username: row.original.username });
                            }}
                            >
                                <span>{t("VIEW_DOWNLINE")}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ]

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        (async () => {
            var regionJsonResult = await ApiEngine.get(ApiUrl._API_GET_REGION);

            if (regionJsonResult[ApiKey._API_SUCCESS_KEY]) {
                var availableRegions = [];

                regionJsonResult[ApiKey._API_DATA_KEY].map((region) => {
                    availableRegions.push({
                        label: region['name'],
                        value: region['id']
                    });
                });

                setRegions(availableRegions);
                applyFilter();
            }
        })();
    }, [])

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        if (!firstTimeInit) {
            applyFilter();
        }
        setFirstTimeInit(false);
    }, [orderByColumn, orderByAsc])

    /// <summary>
    /// Author : Wind
    /// </summary>
    function sortTable(selectedColumn) {
        setOrderByColumn(selectedColumn);
        setOrderByAsc(!orderByAsc);
    }

    /// <summary>
    /// Author : Wind
    /// </summary> 
    async function applyFilter() {
        let apiUrl = ApiUrl._API_GET_OVERALL_DOWNLINE + '?StartDate=' + startDate + "&endDate=" + endDate + "&Username=" + phone +
            "&orderByColumn=" + orderByColumn + "&OrderByAsc=" + orderByAsc + "&v=" + Date.now();

        if (!stringIsNullOrEmpty(regionId)) {
            apiUrl += "&RegionId=" + regionId;
        }

        setApiUrl(apiUrl);
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable columns={_tableColumns} fetchUrl={apiUrl} exportRequired={true} />, [apiUrl])

    return (
        <div>
            <h1 className="page-header">{t("OVERALL_DOWNLINE_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("PHONE_NUMBER")}</label>
                                <input
                                    type="text"
                                    name="phone"
                                    className="form-control form-control-lg"
                                    onInput={(e) => setPhone(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("START_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="startDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={startDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("startDate", "");
                                        }
                                    }} />
                                {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("END_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="endDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={endDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                            setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("endDate", "");
                                        }
                                    }} />
                                {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <label className="w-50">{t("REGION")}</label>
                            <select
                                className="form-control form-control-lg col-lg-12"
                                id="region"
                                name="region"
                                value={regionId}
                                onChange={(e) => { setRegionId(e.target.value); }}>
                                <option value={''} key={-1}>{t('ALL')}</option>
                                {
                                    regions.map((region, index) => {
                                        return <option value={region['value']} key={index}>{region['label']}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <button type="button" className="btn btn-primary" onClick={() => { applyFilter() }}>{t('APPLY_FILTER')}</button>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
}


export default OverallDownlineReport;