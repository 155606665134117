import React, { useState, useEffect, useMemo } from 'react';
import { Panel, PanelHeader, PanelBody, PanelFooter } from 'components/panel/panel.jsx';
import { WebUrl, ApiUrl, ApiKey, AlertTypes } from '../../../util/Constant';
import { stringIsNullOrEmpty, createMultiPartFormBody, getValidationMessage, navigateTo, createFormBody } from "../../../util/Util";
import ReactTable from '../../../components/custom/ReactTable';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage, showMessage } from "../../../redux/AppAction";
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from "react-router-dom";
import NavigationButton from '../../../components/custom/NavigationButton';
import ApiEngine from '../../../util/ApiEngine.js';
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useForm } from 'react-hook-form';

/// <summary>
/// Author: Wind
/// </summary>
const VendorCategoryDetail = props => {
    const { t, i18n } = useTranslation();
    var _history = useHistory();
    var _location = useLocation();
    var _dispatch = useDispatch();
    const { register, handleSubmit, errors, watch, unregister, setValue, triggerValidation } = useForm();
    const [supportedLanguage, setSupportedLanguage] = useState([]);
    const [selectedVendorCategory, setSelectedVendorCategory] = useState(null);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        getSuppportedLanguageAndLoadData();
        setValue("categoryStatus", true);
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function getSuppportedLanguageAndLoadData() {
        var languageList = [];
        var langResponseJson = await ApiEngine.get(ApiUrl._API_GET_SUPPORTED_LANGUAGE);
        if (langResponseJson[ApiKey._API_SUCCESS_KEY]) {
            languageList = langResponseJson[ApiKey._API_DATA_KEY];
        }

        if (_location.state && _location.state.id) {
            var responseJson = await ApiEngine.get(ApiUrl._API_VENDOR_CATEGORY + "/" + _location.state.id);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setValue("categoryRanking", responseJson[ApiKey._API_DATA_KEY].ranking);
                setValue("categoryStatus", responseJson[ApiKey._API_DATA_KEY].status);
                setValue("displayAtDesktop", responseJson[ApiKey._API_DATA_KEY].displayAtDesktop);
                languageList = languageList.map(item => ({ ...item, value: responseJson[ApiKey._API_DATA_KEY].localizations.find(l => l.languageId == item.id).value }));
            }
        }
        
        setSupportedLanguage(languageList)
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    const onSubmit = async (data) => {
        let params = {
            name: JSON.stringify(supportedLanguage.map(item => ({ languageId: item.id, value: data[`${item.id}`] }))),
            ranking: data.categoryRanking,
            status: data.categoryStatus,
            displayAtDesktop: data.displayAtDesktop
        };

        if (_location.state && _location.state.id) {
            params["id"] = _location.state.id;
        }
        
        var responseJson = await ApiEngine.post(ApiUrl._API_VENDOR_CATEGORY, createFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_VENDOR_CATEGORY)
        }));
    };

    return (
        <div>
            <h1 className="page-header">{_location.state ? t("EDIT_VENDOR_CATEGORY") : t("CREATE_VENDOR_CATEGORY")}<NavigationButton history={_history} /></h1>
            <Panel>
                <PanelHeader noButton>{t("VENDOR_CATEGORY_DETAIL")}</PanelHeader>
                <PanelBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            {
                                supportedLanguage && supportedLanguage.map((item, index) => {
                                    return (
                                        <div className="col-lg-3" key={index}>
                                            <div className="form-group">
                                                <label>{t("NAME") + " (" + item.name + ")"}</label>
                                                <input
                                                    className="form-control form-control-lg"
                                                    id={item.id}
                                                    name={item.id}
                                                    defaultValue={item.value}
                                                    placeholder={t("ENTER_CATEGORY_NAME") + " (" + item.name + ")"}
                                                    ref={register({ required: "PLEASE_ENTER_NAME" })} />
                                                {errors[`${item.id}`] && <div className="invalid-feedback">{t(getValidationMessage(errors[`${item.id}`]))}</div>}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("RANKING")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="categoryRanking"
                                        name="categoryRanking"
                                        type="number"
                                        placeholder={t("ENTER_CATEGORY_RANKING")}
                                        ref={register({
                                            required: "PLEASE_ENTER_VALID_RANK",
                                            min: {
                                                value: 0,
                                                message: 'PLEASE_ENTER_VALID_RANK'
                                            }
                                        })} />
                                    {errors.categoryRanking && <div className="invalid-feedback">{t(getValidationMessage(errors.categoryRanking))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("DISPLAY_AT_DESKTOP")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="displayAtDesktop" id="displayAtDesktop"
                                            onChange={(e) => {
                                                setValue("displayAtDesktop", e.target.checked)
                                            }}
                                            checked={watch("displayAtDesktop")}
                                            ref={register} />
                                        <label htmlFor="displayAtDesktop"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("STATUS")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="categoryStatus" id="categoryStatus"
                                            onChange={(e) => {
                                                setValue("categoryStatus", e.target.checked)
                                            }}
                                            checked={watch("categoryStatus")}
                                            ref={register} />
                                        <label htmlFor="categoryStatus"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <button type="submit" className="btn btn-primary"> {t("BTN_SAVE")}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
        </div>
    );
}

export default VendorCategoryDetail;