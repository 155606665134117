import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import DropdownLanguage from './dropdown/language.jsx';
import DropdownProfile from './dropdown/profile.jsx';
import { PageSettings } from './../../config/page-settings.js';
import { useDispatch, useSelector } from "react-redux";
import { getShopCredit, getPendingOnlineWithdrawalCount } from "../../redux/AuthAction.js";
import { ApiUrl, Theme } from "../../util/Constant";
import { useTranslation } from 'react-i18next';

const Header = props => {
    const { t } = useTranslation();
    const { isLoggedIn, creditLimit, userData } = useSelector(state => state.authState);
    const { apiCalledResponse } = useSelector(state => state.appState);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const _dispatch = useDispatch();

    const _LIST_OF_API_TO_UPDATE_CREDIT = [
        ApiUrl._API_CLAIM_REWARD,
        ApiUrl._API_SHOP_SET_SCORE,
        ApiUrl._API_SHOP_BULK_SET_SCORE,
        ApiUrl._API_SET_MEMBER_PRIZE_STATUS
    ];

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (isLoggedIn) {
            _dispatch(getShopCredit())
        }
    }, [isLoggedIn, creditLimit]);

    /// <summary>
    /// Author : CK
    /// Get pending online withdrawal count
    /// </summary>
    useEffect(() => {
        let intervalId;

        if (isLoggedIn && (userData?.isCustomerService || userData?.onlineWithdrawalShop)) {
            if (isFirstLoad) {
                _dispatch(getPendingOnlineWithdrawalCount());

                intervalId = setInterval(async () => {
                    _dispatch(getPendingOnlineWithdrawalCount());
                }, 10000);

                setIsFirstLoad(false);
            }
        }
        else {
            setIsFirstLoad(true);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        }
    }, [isLoggedIn, userData]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (apiCalledResponse && apiCalledResponse.success && _LIST_OF_API_TO_UPDATE_CREDIT.filter(i => apiCalledResponse.url.includes(i)).length != 0) {
            _dispatch(getShopCredit())
        }
    }, [apiCalledResponse]);

    return (
        <PageSettings.Consumer>
            {({ pageHeaderLanguageBar, pageSidebar, toggleMobileSidebar }) => (
                <div id="header" className="header navbar-default">
                    <div className="navbar-header">

                        {pageSidebar && (
                            <button type="button" className="navbar-toggle pull-left" onClick={toggleMobileSidebar}>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        )}
                        <div className="brand-wrapper">
                            <img
                                src={
                                    process.env.REACT_APP_NAME == Theme._EPAY ?
                                        require("../../assets/img/logo/epay.png")
                                        : process.env.REACT_APP_NAME == Theme._OREN ?
                                            require("../../assets/img/logo/oren.png")
                                            : require("../../assets/img/logo/mantap-logo.png")}
                                alt="epay-logo" />
                            <Link to="/">{process.env.REACT_APP_NAME == Theme._EPAY ? "Epay Mobile Reload" : process.env.REACT_APP_NAME == Theme._OREN ? "Oren Mart" : "Mantap"}</Link>
                        </div>
                    </div>

                    <div class='logo-row'>
                        <img src={require("../../assets/img/logo/epay-ghl.png")} alt="epay-logo" />
                        <img src={require("../../assets/img/logo/hotlink.png")} alt="logo" />
                        <img src={require("../../assets/img/logo/digi.png")} alt="logo" />
                        <img src={require("../../assets/img/logo/xpax.png")} alt="logo" />
                        <img src={require("../../assets/img/logo/astro.png")} alt="logo" />
                        <img src={require("../../assets/img/logo/yes.png")} alt="logo" />
                        <img src={require("../../assets/img/logo/molpay.png")} alt="logo" />
                        <img src={require("../../assets/img/logo/cherrycredits.png")} alt="logo" />
                        <img src={require("../../assets/img/logo/garena.png")} alt="logo" />
                        <img src={require("../../assets/img/logo/acash.png")} alt="logo" />
                        <img src={require("../../assets/img/logo/toucn-n-go.png")} alt="logo" />
                    </div>

                    <ul className="navbar-nav navbar-right">
                        <li class='dropdown'>
                            <div class='credit-limit'><strong>{Number(creditLimit).toFixed(2)}</strong> {t('POINTS')}</div>
                        </li>

                        {pageHeaderLanguageBar && (
                            <DropdownLanguage />
                        )}

                        <DropdownProfile />
                    </ul>
                </div>
            )}
        </PageSettings.Consumer>
    )
}

export default (Header);