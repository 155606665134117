import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import {
    Panel,
    PanelHeader,
    PanelBody,
    PanelFooter,
} from "../../components/panel/panel.jsx";
import { Line } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "../../components/custom/ReactTable";
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl, RegionId, AccessRight, AlertTypes } from "../../util/Constant";
import { numberWithCurrencyFormat } from '../../util/Util';
import { useTranslation } from "react-i18next";
import { showResponseMessage, showMessage } from '../../redux/AppAction';
import { Pie } from 'react-chartjs-2';
import { checkIfPermissionExist } from "../../util/PermissionChecker.js";

/// <summary>
/// Author : Lewis
/// </summary>
const Dashboard = props => {
    var _userId = useSelector(state => state['authState']['userData']['userId']);
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(moment().toDate());
    const [endDate, setEndDate] = useState(moment().toDate());
    const _userData = useSelector(state => state.authState.userData);
    const [shopDetails, setShopDetails] = useState([]);
    const _dispatch = useDispatch();
    const [pieCharts, setPieCharts] = useState([]);
    const [pieChartColors, setPieChartColors] = useState([]);
    const [windowWidth, setWindowWidth] = useState('');
    const [transactionExist, setTransactionExist] = useState(false);
    const [welcomeBonus, setWelcomeBonus] = useState([]);

    const _FULL_HD_WIDTH = 1920;
    const _1440P_WIDTH = 2560;
    const _MOBILE_WIDTH = 768;
    const _OBJ_NAME_INDEX = 1;
    const _TOP_DETAILS_INDEX = {
        DEPOSITER: 0,
        DEPOSITS: 1,
        WITHDRAWALS: 2,
        EARNINGS: 3
    }

    const _PIE_CHART_COLOR = {
        [RegionId._AGENT]: "#84ff63",
        [RegionId._EAST]: "#CCC",
        [RegionId._MIDDLE]: "#FF6384",
        [RegionId._NORTH]: "#36A2EB",
        [RegionId._SOUTH]: "#FFCE56",
        [RegionId._ALL]: "#de63ff"
    }

    const [topDetails, setTopDetails] = useState([
        {
            field: "NUMBER_OF_DEPOSITORS",
            value: "",
            background: "#348fe2",
            classname: "admin-data-a",
            iconClassname: "fa fa-users",
        },
        {
            field: "TOTAL_DEPOSITS_TODAY",
            value: "",
            background: "#49b6d6",
            classname: "admin-data-b",
            iconClassname: "fa fa-desktop",
        },
        {
            field: "TOTAL_WITHDRAWALS_TODAY",
            value: "",
            background: "#f59c1a",
            classname: "admin-data-c",
            iconClassname: "fa fa-desktop",
        },
        {
            field: "TOTAL_EARNING_TODAY",
            value: "",
            background: "#ff5b57",
            classname: "admin-data-d",
            iconClassname: "fa fa-desktop",
        },
    ]);

    const _TABLE_COLUMNS = useMemo(
        () => [
            {
                Header: "SHOP",
                accessor: "shopName",
            },
            {
                Header: "USER_NUMBER",
                accessor: "userFullName",
            },
            {
                Header: "REGION",
                accessor: "regionName",
            },
            {
                Header: "TOTAL_MEMBER",
                accessor: "totalMember",
            },
            {
                Header: "TOTAL_APP",
                accessor: "totalApp",
            },
            {
                Header: "TOTAL_PRO_APP",
                accessor: "totalProApp",
            },
        ],
        []
    );

    const _WELCOME_BONUS_TABLE_COLUMNS = useMemo(
        () => [
            {
                Header: "BONUS",
                accessor: "name",
            },
            {
                Header: "AMOUNT_CLAIMED",
                Cell: ({ row }) => {
                    return <span >{numberWithCurrencyFormat(row.original.amount)}</span>
                },
            },
            {
                Header: "STATUS",
                Cell: ({ row }) => {
                    return <span className={"badge badge-success"}>{`${row.original.count}/${row.original.limitCount}`}</span>
                },
            }
        ],
        []
    );

    /// <summary>
    /// Author : YJ
    /// Added resize listener to handle change of screen to handle pie size
    /// </summary>
    useEffect(() => {
        init();
        updateWindowDimension();
        window.addEventListener('resize', updateWindowDimension);

        return () => {
            window.removeEventListener('resize', updateWindowDimension);
        }
    }, []);

    /// <summary>
    /// Author : YJ
    /// </summary>
    function updateWindowDimension() {
        setWindowWidth(window.innerWidth);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SHOP_MEMBER_DETAIL);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setShopDetails(responseJson[ApiKey._API_DATA_KEY]);
        }

        if (_userData.isAdminLevel) {
            responseJson = await ApiEngine.get(ApiUrl._API_GET_DAILY_TRANSACTION);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let newArr = [...topDetails];
                let totalDepositers = 0;
                let totalDeposit = 0;
                let totalWithdrawal = 0;
                let totalEarning = 0;
                let pieChartDatas = [];
                let pieChartColours = [];
                let depositerObj = { name: "DEPOSITERS" };
                let depositObj = { name: "DEPOSITS" };
                let withdrawalObj = { name: "WITHDRAWALS" };
                let earningObj = { name: "EARNING" };

                responseJson[ApiKey._API_DATA_KEY].map((data, key) => {
                    totalDepositers += data["depositers"];
                    depositerObj[data.regionName] = data["depositers"];
                    totalDeposit += data["totalDeposit"];
                    depositObj[data.regionName] = data["totalDeposit"].toFixed(2);
                    totalWithdrawal += data["totalWithdrawal"];
                    withdrawalObj[data.regionName] = data["totalWithdrawal"].toFixed(2);
                    totalEarning += data["earning"];
                    earningObj[data.regionName] = data["earning"].toFixed(2);
                    pieChartColours.push(_PIE_CHART_COLOR[data["regionId"]]);
                    setTransactionExist(true);
                })
                pieChartDatas.push(depositerObj);
                pieChartDatas.push(depositObj);
                pieChartDatas.push(withdrawalObj);
                pieChartDatas.push(earningObj);
                newArr[_TOP_DETAILS_INDEX.DEPOSITER].value = totalDepositers
                newArr[_TOP_DETAILS_INDEX.DEPOSITS].value = "RM " + numberWithCurrencyFormat(totalDeposit);
                newArr[_TOP_DETAILS_INDEX.WITHDRAWALS].value = "RM " + numberWithCurrencyFormat(totalWithdrawal);
                newArr[_TOP_DETAILS_INDEX.EARNINGS].value = "RM " + numberWithCurrencyFormat(totalEarning);
                setTopDetails(newArr);
                setPieCharts(pieChartDatas);
                setPieChartColors(pieChartColours);
            }

            var welcomeBonusResponse = await ApiEngine.get(ApiUrl._API_GET_WELCOME_BONUS_DETAIL);

            if (welcomeBonusResponse[ApiKey._API_SUCCESS_KEY]) {
                setWelcomeBonus(welcomeBonusResponse[ApiKey._API_DATA_KEY]['welcomeBonusSummaryModel']);
            }
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const renderPieCharts = useMemo(() => {
        if (transactionExist) {
            return pieCharts.map((chartData, index) => {
                let validValuesArr = Object.values(chartData).slice(_OBJ_NAME_INDEX);
                if (validValuesArr.length > 0) {
                    let valueSum = validValuesArr.reduce((a, b) => (parseFloat(a) + parseFloat(b)));
                    if (Math.abs(valueSum) > 0) {
                        return <div className={windowWidth >= _FULL_HD_WIDTH ? "col-lg-3" : "col-lg-6"}>
                            <div style={{ height: windowWidth <= _MOBILE_WIDTH ? "250px" : "unset" }}>
                                <Pie key={"pie" + index}
                                    data={{
                                        labels: Object.keys(chartData).slice(_OBJ_NAME_INDEX),
                                        datasets: [{
                                            data: Object.values(chartData).slice(_OBJ_NAME_INDEX),
                                            backgroundColor: pieChartColors,
                                            hoverBackgroundColor: pieChartColors
                                        }]
                                    }}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: windowWidth > _MOBILE_WIDTH,
                                        plugins: {
                                            labels: [
                                                {
                                                    render: 'percentage',
                                                    fontSize: windowWidth >= _1440P_WIDTH ? 18 : 12,
                                                    fontColor: "black"
                                                },
                                                {
                                                    render: 'value',
                                                    fontSize: windowWidth >= _1440P_WIDTH ? 18 : 12,
                                                    fontColor: "black",
                                                    position: 'outside'
                                                }
                                            ]
                                        }
                                    }} />
                            </div>
                            <div style={{ textAlign: "center" }}><b>{t(chartData.name)}</b></div>
                        </div>
                    }
                }
            });
        }
    }, [pieCharts, pieChartColors, t, windowWidth]);

    return (
        <div className="dashboard">
            <div className="row">
                <div className="dashboard-header col-md-12">
                    <h1 className="page-header">
                        {t('DASHBOARD')}
                    </h1>
                </div>
            </div>
            {_userData.isAdminLevel && checkIfPermissionExist(AccessRight._DASHBOARD_CONTROL_PERMISSION, false) &&
                <>
                    <div className="row">
                        {topDetails.map((obj, index) => {
                            return (
                                <div className="col-xl-3 col-md-6" key={index}>
                                    <div
                                        className={`widget widget-stats ${obj.classname}`}>
                                        <div className="stats-icon">
                                            <i
                                                className={`icon ${obj.iconClassname}`}></i>
                                        </div>
                                        <div className="stats-info">
                                            <h4>{t(obj.field)}</h4>
                                            <p>{obj.value}</p>
                                        </div>
                                        <div className="stats-link">
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {transactionExist && checkIfPermissionExist(AccessRight._DASHBOARD_CONTROL_PERMISSION, false) && <Panel>
                        <PanelHeader>{t("TRANSACTION_PIE_CHART")}</PanelHeader>
                        <PanelBody>
                            <div className="row">
                                {renderPieCharts}
                            </div>
                        </PanelBody>
                    </Panel>}
                </>
            }
            <div className="row">
                <div className="col-md-12">
                    <Panel>
                        <PanelHeader></PanelHeader>
                        <PanelBody>
                            <ReactTable
                                columns={_TABLE_COLUMNS}
                                data={shopDetails}
                            />
                        </PanelBody>
                    </Panel>
                </div>
            </div>
            {
                _userData.isAdminLevel && checkIfPermissionExist(AccessRight._DASHBOARD_CONTROL_PERMISSION, false) &&
                <div className="row">
                    <div className="col-md-12">
                        <Panel>
                            <PanelHeader>{t('WELCOME_BONUS_CLAIM_STATUS')}</PanelHeader>
                            <PanelBody>
                                <ReactTable
                                    columns={_WELCOME_BONUS_TABLE_COLUMNS}
                                    data={welcomeBonus}
                                />
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            }
        </div>
    );
}

export default Dashboard;
