import React, { useState, useEffect } from 'react';
import { ApiKey, ApiUrl } from '../../util/Constant';
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactTable from "./ReactTable";
import { showMessage } from "../../redux/AppAction";
import { EventStatus, AlertTypes, AccessRight } from '../../util/Constant';
import { checkIfPermissionExist } from 'util/PermissionChecker';
import { isObjectEmpty } from '../../util/Util';

/// <summary>
/// Author: Wind
/// </summary>
const PrizeDistributionModal = props => {
    var _userId = useSelector(state => state['authState']['userData']['userId']);
    const { t, i18n } = useTranslation();
    const _dispatch = useDispatch();
    const [prizeData, setPrizeData] = useState([]);

    let _prizeTableColumns = [
        {
            Header: "RANK",
            accessor: "rankPosition",
            disableSortBy: true
        },
        {
            Header: "MEMBER",
            accessor: "username",
            disableSortBy: true
        },
        {
            Header: "SCORE",
            accessor: "winAmount",
            disableSortBy: true
        },
        {
            Header: "PRIZE_NAME",
            accessor: "prizeName",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{row.original.eventPrize.prizeName}</div>
            }
        },
        {
            Header: "WORTH_AMOUNT",
            accessor: "amount",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{row.original.eventPrize.amount}</div>
            }
        },
        {
            Header: "PRIZE_IMAGE",
            accessor: "logo",
            disableSortBy: true,
            Cell: ({ row }) => {
                return row.original.eventPrize.logo ? <img class="mini-program-logo" src={row.original.eventPrize.logo} /> : <div></div>
            }
        },
        {
            Header: "PRIZE_DISTRIBUTION",
            disableSortBy: true,
            Cell: ({ row }) => {
                let returnClassName = "badge badge-warning";
                let returnString = "PENDING";

                if (row.original.isClaimed) {
                    returnClassName = "badge badge-success";
                    returnString = "DISTRIBUTED";
                }

                return <span className={returnClassName} style={{ textTransform: "uppercase" }}>{t(returnString)}</span>
            }
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._EVENT_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    {
                        !row.original.isClaimed && props.selectedEvent?.status == EventStatus._END &&
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => {
                                    _dispatch(showMessage({
                                        content: t('ARE_YOU_SURE_YOU_WANT_TO') + t('DISTRIBUTE_PRIZE_FOR') + " " + row.original.username + "?",
                                        showCancel: true,
                                        required: true,
                                        inputType: 'password',
                                        validationMsg: t('INVALID_PASSWORD'),
                                        confirmBtnText: t("CONFIRM"),
                                        type: AlertTypes._INPUT,
                                        onConfirm: async (value) => {
                                            await verifyPassword(value, row.original);
                                        }
                                    }));
                                }}>
                                    <span className="text-warning">{t('DISTRIBUTE')}</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    }
                </div>
            ),
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ];

    /// <summary>
    /// Author: Wind
    /// </summary> 
    useEffect(() => {
        (async () => {
            if (props.selectedEvent != null) {
                getSelectectEventPrizeData();
            }
            else {
                setPrizeData([]);
            }
        })();
    }, [props.selectedEvent]);

    /// <summary>
    /// Author: Wind
    /// </summary> 
    async function getSelectectEventPrizeData() {
        let apiUrl = ApiUrl._API_KOG_PRIZE_OWNER + "?eventId=" + props.selectedEvent.id;

        if (props.isLuckyPrize) {
            apiUrl += "&luckyPrize=true";
        }

        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setPrizeData(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    // <summary>
    /// Author: Wind
    /// </summary>
    async function verifyPassword(password, rowData = {}) {
        let isSuccess = true;
        let msg = '';

        var responseJson = await ApiEngine.post(`${ApiUrl._API_VERIFY_PASSWORD}?userId=${_userId}&password=${password}`);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (!isObjectEmpty(rowData)) {
                var singleDistribute = await ApiEngine.post(`${ApiUrl._API_SINGLE_DISTRIBUTE}?eventId=${rowData.eventId}&memberId=${rowData.memberId}`);

                if (!singleDistribute[ApiKey._API_SUCCESS_KEY]) {
                    isSuccess = false;
                }

                msg = singleDistribute[ApiKey._API_MESSAGE_KEY];
            }
            else {
                var distributePrizeResponseJson = await ApiEngine.post(`${ApiUrl._API_DISTRIBUTE_PRIZE}?eventId=${props.selectedEvent.id}`);

                if (!distributePrizeResponseJson[ApiKey._API_SUCCESS_KEY]) {
                    isSuccess = false;
                }

                msg = distributePrizeResponseJson[ApiKey._API_MESSAGE_KEY];
            }
        }
        else {
            isSuccess = false;
            msg = responseJson[ApiKey._API_MESSAGE_KEY];
        }

        _dispatch(showMessage({
            type: isSuccess ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(msg),
            onConfirm: () => {
                if (isSuccess && !isObjectEmpty(rowData)) {
                    getSelectectEventPrizeData();
                }
            }
        }));
    }

    return (
        <Modal isOpen={props.selectedEvent != null} toggle={() => { props.onFinish() }} size="lg">
            <ModalHeader toggle={() => { props.onFinish() }}>
                <h4 >
                    {t("PRIZE_WINNER") + ' (' + props.selectedEvent?.name + ')'}
                </h4>
            </ModalHeader>
            <ModalBody>
                <ReactTable data={prizeData} columns={_prizeTableColumns} />
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-success btn-action" onClick={() => { getSelectectEventPrizeData(); }}>{t("REFRESH")}</button>
                {
                    props.selectedEvent?.status == EventStatus._END &&
                    <button onClick={() => _dispatch(showMessage({
                        content: t('ARE_YOU_SURE_YOU_WANT_TO') + t('DISTRIBUTE_PRIZE_FOR') + " " + t('ALL_USER') + "?",
                        showCancel: true,
                        required: true,
                        inputType: 'password',
                        validationMsg: t('INVALID_PASSWORD'),
                        confirmBtnText: t("CONFIRM"),
                        type: AlertTypes._INPUT,
                        onConfirm: async (value) => {
                            await verifyPassword(value);
                        }
                    }))} className="btn btn-primary">{t('DISTRIBUTE_PRIZE')}</button>
                }
            </ModalFooter>
        </Modal>
    );
}

export default PrizeDistributionModal;