import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { navigateTo, createMultiPartFormBody } from "../../../util/Util";
import NavigationButton from '../../../components/custom/NavigationButton';
import { ApiKey, WebUrl, ApiUrl, AccessRight, AnnouncementType, ProcessedAnnouncementStatus, AlertTypes } from "../../../util/Constant";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage, showMessage } from "../../../redux/AppAction";
import ApiEngine from '../../../util/ApiEngine.js';
import PreviewMessageModal from '../../../components/custom/PreviewMessageModal';
import { stringIsNullOrEmpty } from '../../../util/Util';

/// <summary>
/// Author : YJ
/// </summary>
const ManageShopMessage = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    const { register, handleSubmit } = useForm();
    const [messageData, setMessageData] = useState([]);
    var _dispatch = useDispatch();
    const [modalVisibility, setModalVisibility] = useState(false);
    const [selectedMessageId, setSelectedMessageId] = useState('');
    const _INCLUDE_BROADCAST = [ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_CANCELLED, ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_DRAFT];

    let _tableColumns = useMemo(() => [
        {
            Header: "IMAGE",
            accessor: "contentImage",
            Cell: ({ row }) => {
                return !stringIsNullOrEmpty(row.original.contentImage) && <img class="announcement-img" src={row.original.contentImage} />
            },
            width: 100
        },
        {
            Header: "TITLE",
            accessor: "title"
        },
        {
            Header: "CONTENT",
            accessor: "content"
        },
        {
            Header: "TARGETED_SHOPS",
            accessor: "shopList",
            Cell: ({ row }) => {
                return row.original.shopList.join(", ");
            },
            style: {
                maxWidth: "150px"
            }
        },
        {
            Header: "DATE",
            accessor: "createdTime"
        },
        {
            Header: "TOTAL",
            accessor: "total"
        },
        {
            Header: "SENT",
            accessor: "sent"
        },
        {
            Header: "STATUS",
            accessor: "processedStatusString",
            Cell: ({ row }) => {
                let returnClassName = "";
                switch (row.original.processedStatus) {
                    case ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_DRAFT:
                        returnClassName = "badge badge-secondary";
                        break;
                    case ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_CANCELLED:
                        returnClassName = "badge badge-danger";
                        break;
                    case ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_IN_PROGRESS:
                        returnClassName = "badge badge-primary";
                        break;
                    case ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_COMPLETED:
                        returnClassName = "badge badge-success";
                        break;
                }
                return <span className={returnClassName} style={{ textTransform: "uppercase" }}>{t(row.original.processedStatusString)} {row.original.sent}/{row.original.total}</span>
            },
            minWidth: 100
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                (checkIfPermissionExist(AccessRight._ANNOUNCEMENT_CONTROL_PERMISSION, true) && row.original.processedStatus != ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_COMPLETED) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            {row.original.processedStatus == ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_DRAFT && <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_ADMIN_SHOP_MESSAGE_DETAIL, row.original) }}><span className="text-warning">{t("EDIT")}</span></DropdownItem>}
                            {_INCLUDE_BROADCAST.includes(row.original.processedStatus) &&
                                <DropdownItem onClick={() => {
                                    _dispatch(showMessage({
                                        type: AlertTypes._WARNING,
                                        content: t("BROADCAST") + "?",
                                        showCancel: true,
                                        confirmBtnText: t("CONFIRM"),
                                        onConfirm: () => setMessageStatus(row.original.id, true)
                                    }));
                                }}>
                                    <span className="text-default">{t("BROADCAST")}</span>
                                </DropdownItem>}
                            {row.original.processedStatus == ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_IN_PROGRESS &&
                                <DropdownItem onClick={() => {
                                    _dispatch(showMessage({
                                        type: AlertTypes._WARNING,
                                        content: t("CANCEL_BROADCAST") + "?",
                                        showCancel: true,
                                        confirmBtnText: t("CONFIRM"),
                                        onConfirm: () => setMessageStatus(row.original.id, false)
                                    }));
                                }}>
                                    <span className="text-default">{t("CANCEL_BROADCAST")}</span>
                                </DropdownItem>}
                            <DropdownItem onClick={() => {
                                setSelectedMessageId(row.original.id);
                                setModalVisibility(true);
                            }}><span className="text-default">{t("PREVIEW")}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ], [messageData]);

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function setMessageStatus(id, status) {
        var responseJson = await ApiEngine.post(ApiUrl._API_SET_ANNOUNCEMENT_STATUS + "?id=" + id + "&status=" + status);

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        handleSubmit(submitForm)();
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        handleSubmit(submitForm)();
    }, []);

    /// <summary>
    /// Author : YJ
    /// </summary>
    const submitForm = async (data, e) => {
        _dispatch(setBusy());
        let apiUrl = ApiUrl._API_GET_SYSTEM_MESSAGE + "?type=" + AnnouncementType._ANNOUNCEMENT_TYPE_SHOP

        if (data) {
            apiUrl += "&title=" + data.title + "&content=" + data.content;
        }

        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setMessageData(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
        _dispatch(setIdle());
    }

    return (
        <div>
            <PreviewMessageModal
                id={selectedMessageId}
                messageType={AnnouncementType._ANNOUNCEMENT_TYPE_SHOP}
                visible={modalVisibility}
                onFinish={() => { setModalVisibility(false) }} />
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("SHOP_MESSAGE_MANAGEMENT")}</h1>
                {
                    checkIfPermissionExist(AccessRight._ANNOUNCEMENT_CONTROL_PERMISSION, true) &&
                    <NavigationButton history={_history} url={WebUrl._URL_ADMIN_SHOP_MESSAGE_DETAIL} buttonText={t("ADD_NEW_SHOP_MESSAGE")} />
                }
            </div>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row">
                            <div className="col-lg-3 m-b-10">
                                <label>{t("TITLE")}</label>
                                <input type="text" name="title" ref={register} className="form-control" placeholder={t("FULL_OR_PART_OF_TITLE")} />
                            </div>
                            <div className="col-lg-3">
                                <label>{t("CONTENT")}</label>
                                <input type="text" name="content" ref={register} className="form-control" placeholder={t("FULL_OR_PART_OF_CONTENT")} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    <ReactTable data={messageData} columns={_tableColumns} filterable={true} />
                </PanelBody>
            </Panel>
		</div>
    );
}

export default ManageShopMessage;