import React, { useState, useEffect, useMemo } from 'react';
import { Panel, PanelHeader, PanelBody, PanelFooter } from 'components/panel/panel.jsx';
import { WebUrl, ApiUrl, ApiKey, AlertTypes } from '../../../util/Constant';
import { stringIsNullOrEmpty, createMultiPartFormBody, getValidationMessage, navigateTo, createFormBody } from "../../../util/Util";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage, showMessage } from "../../../redux/AppAction";
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from "react-router-dom";
import NavigationButton from '../../../components/custom/NavigationButton';
import ApiEngine from '../../../util/ApiEngine.js';
import { useForm, Controller } from 'react-hook-form';
import Dropzone from 'react-dropzone';

/// <summary>
/// Author : Wind
/// </summary> 
const RankDetail = props => {
    const { t, i18n } = useTranslation();
    var _history = useHistory();
    var _location = useLocation();
    var _dispatch = useDispatch();
    const { register, handleSubmit, errors, watch, setValue, control } = useForm();
    const [supportedLanguage, setSupportedLanguage] = useState([]);
    const [outerFrame, setOuterFrame] = useState({ file: null, image: '' });
    const [innerFrame, setInnerFrame] = useState({ file: null, image: '' });
    const [badge, setBadge] = useState({ file: null, image: '' });
    let _OUTER_FRAME_KEY = "outerFrame";
    let _INNER_FRAME_KEY = "innerFrame";
    let _BADGE_KEY = "badge";

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        (async () => {
            var languageList = [];
            var langResponseJson = await ApiEngine.get(ApiUrl._API_GET_SUPPORTED_LANGUAGE);
            if (langResponseJson[ApiKey._API_SUCCESS_KEY]) {
                languageList = langResponseJson[ApiKey._API_DATA_KEY];
            }

            if (_location.state && _location.state.id) {
                var responseJson = await ApiEngine.get(ApiUrl._API_GET_RANK + "/" + _location.state.id);
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setValue("colorCode", responseJson[ApiKey._API_DATA_KEY].colorCode);
                    setValue("minimumExperience", responseJson[ApiKey._API_DATA_KEY].minimumExperience);
                    setOuterFrame({ ...outerFrame, image: responseJson[ApiKey._API_DATA_KEY].outerFrame });
                    setInnerFrame({ ...innerFrame, image: responseJson[ApiKey._API_DATA_KEY].innerFrame });
                    setBadge({ ...badge, image: responseJson[ApiKey._API_DATA_KEY].badgeFile });
                    languageList = languageList.map(item => ({ ...item, value: responseJson[ApiKey._API_DATA_KEY].localizations.find(l => l.languageId == item.id).value }));
                    setValue("canDropRank", responseJson[ApiKey._API_DATA_KEY]["canDropRank"]);
                    setValue("canDropExp", responseJson[ApiKey._API_DATA_KEY]["canDropExp"]);
                }
            }
            else {
                setValue("canDropRank", true);
                setValue("canDropExp", true);
            }

            setSupportedLanguage(languageList)
        })();
    }, []);

    /// <summary>
    /// Author : Wind
    /// </summary>
    const imageDrop = (acceptedFiles, key) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                if (key == _OUTER_FRAME_KEY) {
                    setOuterFrame({ image: reader.result, file: file });
                }
                else if (key == _INNER_FRAME_KEY) {
                    setInnerFrame({ image: reader.result, file: file });
                }
                else if (key == _BADGE_KEY) {
                    setBadge({ image: reader.result, file: file });
                }
            }

            reader.readAsDataURL(file);
        }
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    const onSubmit = async (data) => {
        var responseJson;
        let params = {
            name: JSON.stringify(supportedLanguage.map(item => ({ languageId: item.id, value: data[`${item.id}`] }))),
            colorCode: data.colorCode,
            minimumExperience: data.minimumExperience,
            canDropRank: data.canDropRank,
            canDropExp: data.canDropExp
        };

        if (outerFrame['file']) {
            params['outerFrame'] = outerFrame['file']
        }

        if (innerFrame['file']) {
            params['innerFrame'] = innerFrame['file']
        }

        if (badge['file']) {
            params['badgeFile'] = badge['file']
        }

        if (_location.state && _location.state.id) {
            responseJson = await ApiEngine.put(ApiUrl._API_CREATE_OR_UPDATE_RANK + '/' + _location.state.id, createMultiPartFormBody(params));
        }
        else {
            responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_RANK, createMultiPartFormBody(params));
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_RANK)
        }));
    };

    return (
        <div>
            <h1 className="page-header">{_location.state ? t("EDIT_RANK") : t("CREATE_RANK")}<NavigationButton history={_history} /></h1>
            <Panel>
                <PanelHeader noButton>{t("RANK_DETAIL")}</PanelHeader>
                <PanelBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            {
                                supportedLanguage && supportedLanguage.map((item, index) => {
                                    return (
                                        <div className="col-lg-3" key={index}>
                                            <div className="form-group">
                                                <label>{t("NAME") + " (" + item.name + ")"}</label>
                                                <input
                                                    className="form-control form-control-lg"
                                                    id={item.id}
                                                    name={item.id}
                                                    defaultValue={item.value}
                                                    placeholder={t("ENTER_RANK_NAME") + " (" + item.name + ")"}
                                                    ref={register({ required: "PLEASE_ENTER_RANK_NAME" })} />
                                                {errors[`${item.id}`] && <div className="invalid-feedback">{t(getValidationMessage(errors[`${item.id}`]))}</div>}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>
                                        {t("COLOR_CODE")} <span style={{ backgroundColor: stringIsNullOrEmpty(watch('colorCode')) ? 'transparent' : watch('colorCode'), borderRadius: 20 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="colorCode"
                                        name="colorCode"
                                        placeholder={t("ENTER_COLOR_CODE")}
                                        ref={register({
                                            required: "PLEASE_ENTER_COLOR_CODE"
                                        })} />
                                    {errors.colorCode && <div className="invalid-feedback">{t(getValidationMessage(errors.colorCode))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("MINIMUM_EXPERIENCE")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        id="minimumExperience"
                                        name="minimumExperience"
                                        placeholder={t("ENTER_MINIMUM_EXPERIENCE")}
                                        ref={register({
                                            required: "PLEASE_ENTER_MINIMUM_EXPERIENCE"
                                        })} />
                                    {errors.minimumExperience && <div className="invalid-feedback">{t(getValidationMessage(errors.minimumExperience))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label><b>{t("CAN_DROP_RANK")}</b></label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="canDropRank" id="canDropRank"
                                            onChange={(e) => setValue("canDropRank", e.target.checked)}
                                            value={true}
                                            checked={watch('canDropRank')}
                                            ref={register} />
                                        <label htmlFor="canDropRank"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label><b>{t("CAN_DROP_EXP")}</b></label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="canDropExp" id="canDropExp"
                                            onChange={(e) => setValue("canDropExp", e.target.checked)}
                                            value={true}
                                            checked={watch('canDropExp')}
                                            ref={register} />
                                        <label htmlFor="canDropExp"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("OUTER_FRAME")}</label>
                                    <Dropzone accept={'image/*'} onDrop={acceptedFiles => imageDrop(acceptedFiles, _OUTER_FRAME_KEY)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {stringIsNullOrEmpty(outerFrame['image']) && <h4 style={{ color: "grey" }} >{t("UPLOAD_OUTER_FRAME")}</h4>}
                                                    {!stringIsNullOrEmpty(outerFrame['image']) && <aside className="thumbsContainer">
                                                        <div className="thumb">
                                                            <div className="thumbInner">
                                                                <img
                                                                    src={outerFrame['image']}
                                                                    className="dropzone-img"
                                                                />
                                                            </div>
                                                        </div>
                                                    </aside>}
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    <button type="button" className="mt-2 btn btn-danger"
                                        onClick={() => {
                                            setValue('outerFrame', '');
                                            setOuterFrame({ file: null, image: '' });
                                        }}>
                                        {t("REMOVE_IMAGE")}
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("INNER_FRAME")}</label>
                                    <Dropzone accept={'image/*'} onDrop={acceptedFiles => imageDrop(acceptedFiles, _INNER_FRAME_KEY)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {stringIsNullOrEmpty(innerFrame['image']) && <h4 style={{ color: "grey" }} >{t("UPLOAD_INNER_FRAME")}</h4>}
                                                    {!stringIsNullOrEmpty(innerFrame['image']) && <aside className="thumbsContainer">
                                                        <div className="thumb">
                                                            <div className="thumbInner">
                                                                <img
                                                                    src={innerFrame['image']}
                                                                    className="dropzone-img"
                                                                />
                                                            </div>
                                                        </div>
                                                    </aside>}
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    <button type="button" className="mt-2 btn btn-danger"
                                        onClick={() => {
                                            setInnerFrame({ file: null, image: '' });
                                        }}>
                                        {t("REMOVE_IMAGE")}
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("BADGE")}</label>
                                    <Dropzone accept={'image/*'} onDrop={acceptedFiles => imageDrop(acceptedFiles, _BADGE_KEY)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {stringIsNullOrEmpty(badge['image']) && <h4 style={{ color: "grey" }} >{t("UPLOAD_BADGE")}</h4>}
                                                    {!stringIsNullOrEmpty(badge['image']) && <aside className="thumbsContainer">
                                                        <div className="thumb">
                                                            <div className="thumbInner">
                                                                <img
                                                                    src={badge['image']}
                                                                    className="dropzone-img"
                                                                />
                                                            </div>
                                                        </div>
                                                    </aside>}
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    <button type="button" className="mt-2 btn btn-danger"
                                        onClick={() => {
                                            setBadge({ file: null, image: '' });
                                        }}>
                                        {t("REMOVE_IMAGE")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <button type="submit" className="btn btn-primary"> {t("BTN_SAVE")}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
        </div >
    );
}

export default RankDetail;