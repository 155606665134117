import React, { useState, useEffect } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import NavigationButton from '../../../components/custom/NavigationButton';
import { ApiKey, WebUrl, ApiUrl, AccessRight, PaymentGatewayType, ProcessedAnnouncementStatus, AlertTypes, Role } from "../../../util/Constant";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage, showMessage } from "../../../redux/AppAction";
import ApiEngine from '../../../util/ApiEngine.js';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, getValidationMessage, isObjectEmpty } from "../../../util/Util";
import { useLocation } from "react-router-dom";
import DateTime from 'react-datetime';
import moment from 'moment';
import Select from 'react-select'

/// <summary>
/// Author : Andrew
/// </summary>
const ManagePaymentGatewayDetail = props => {
    var { t, i18n } = useTranslation();
    var _history = useHistory();
    var _location = useLocation();
    var _dispatch = useDispatch();
    var { register, unregister, handleSubmit, errors, setValue, triggerValidation, watch } = useForm();

    const [paymentGatewayId, setPaymentGatewayId] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [apiSecret, setApiSecret] = useState('');
    const [callbackUrl, setCallbackUrl] = useState('');
    const [responseUrl, setResponseUrl] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [status, setStatus] = useState(true);
    const [hasOperationHour, setHasOperationHour] = useState(false);
    const [telcoList, setTelcoList] = useState([]);
    const [shopOptions, setShopOptions] = useState([]);
    const [selectedInterfaceShopId, setSelectedInterfaceShopId] = useState(null)

    /// <summary>
    /// Author : Andrew
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        if (hasOperationHour) {
            register({ name: 'startAtString' }, { required: true });
            register({ name: 'endAtString' }, { required: true });

            if (!watch('startAtString')) {
                setValue('startAtString', "08:00");
            }

            if (!watch('endAtString')) {
                setValue('endAtString', "23:30");
            }
        }
    }, [hasOperationHour]);

    /// <summary>
    /// Author : Andrew
    /// </summary>
    async function init() {
        // Get interface shops
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS + "?roleId=" + Role._SHOP);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var shopOptionList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (user) {
                shopOptionList.push({ label: user.username + " (" + user.shopName + ") - " + user.regionName, value: user.id });
            });

            setShopOptions(shopOptionList);
        }

        var responseJsonTelco = await ApiEngine.get(ApiUrl._API_GET_PAYMENT_GATEWAY_TELCO);

        if (responseJsonTelco[ApiKey._API_SUCCESS_KEY]) {
            setTelcoList(responseJsonTelco[ApiKey._API_DATA_KEY]);
        }

        if (_location.state) {
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_PAYMENT_GATEWAY_BY_ID + "?id=" + _location.state["id"]);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setPaymentGatewayId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                setApiKey(responseJson[ApiKey._API_DATA_KEY]["apiKey"]);
                setApiSecret(responseJson[ApiKey._API_DATA_KEY]["apiSecret"]);
                setCallbackUrl(responseJson[ApiKey._API_DATA_KEY]["callbackUrl"]);
                setResponseUrl(responseJson[ApiKey._API_DATA_KEY]["responseUrl"]);
                setSecretKey(responseJson[ApiKey._API_DATA_KEY]["secretKey"]);
                setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
                setHasOperationHour(responseJson[ApiKey._API_DATA_KEY]["hasOperationHour"]);
                setSelectedInterfaceShopId(responseJson[ApiKey._API_DATA_KEY]["interfaceShopId"]);
                setValue("baseUrl", responseJson[ApiKey._API_DATA_KEY]["baseUrl"]);
                setValue("title", responseJson[ApiKey._API_DATA_KEY]["title"]);
                setValue('minTopUpAmount', responseJson[ApiKey._API_DATA_KEY]["minTopUpAmount"]);
                setValue('maxTopUpAmount', responseJson[ApiKey._API_DATA_KEY]["maxTopUpAmount"]);
                setValue('typeId', responseJson[ApiKey._API_DATA_KEY]["typeId"]);
                setValue('processingFee', responseJson[ApiKey._API_DATA_KEY]["processingFee"]);

                if (!stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["startAtString"]) && !stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["endAtString"])) {
                    register({ name: 'startAtString' }, { required: true });
                    register({ name: 'endAtString' }, { required: true });
                    setValue('startAtString', responseJson[ApiKey._API_DATA_KEY]["startAtString"]);
                    setValue('endAtString', responseJson[ApiKey._API_DATA_KEY]["endAtString"]);
                }

                if (responseJson[ApiKey._API_DATA_KEY]["typeId"] == PaymentGatewayType._WINPAY) {
                    responseJsonTelco[ApiKey._API_DATA_KEY].map(item => {
                        register({ name: item.id.toString() });
                        setValue(item.id.toString(), responseJson[ApiKey._API_DATA_KEY]["paymentGatewayRates"].find(x => x.type == item.id)?.value);
                    })
                }
            }
        }
    }

    /// <summary>
    /// Author : Andrew
    /// </summary>
    const submitForm = async (data, e) => {
        let telcoRate = [];
        data['status'] = data['status'] ? true : false;
        data['interfaceShopId'] = selectedInterfaceShopId;

        if (paymentGatewayId) {
            data['id'] = paymentGatewayId;
        }

        telcoList.map(item => {
            if (data.hasOwnProperty(item.id) && !stringIsNullOrEmpty(data[item.id])) {
                telcoRate.push({ type: item.id, value: data[item.id] })
            }
        })

        if (telcoRate.length > 0) {
            data['rates'] = JSON.stringify(telcoRate);
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_PAYMENT_GATEWAY, createMultiPartFormBody(data));
        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_PAYMENT_GATEWAY)
        }));
    }

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_PAYMENT_GATEWAY")}</h1>
                {
                    checkIfPermissionExist(AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION, true) &&
                    <NavigationButton history={_history} url={WebUrl._URL_ADMIN_MANAGE_PAYMENT_GATEWAY_DETAIL} />
                }
            </div>
            <div>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Panel>
                        <PanelHeader children={t('PAYMENT_GATEWAY_INFO')} noButton="true"></PanelHeader>
                        <PanelBody>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div class="form-group">
                                        <label>{t("TITLE")}</label>
                                        <input type="text" class="form-control form-control-lg" name="title" ref={register} />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div class="form-group">
                                        <label>{t("BASE_URL")}</label>
                                        <input type="text" class="form-control form-control-lg" name="baseUrl" ref={register} />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div class="form-group">
                                        <label>{t("API_KEY")}</label>
                                        <input type="text" class="form-control form-control-lg" name="apiKey" defaultValue={apiKey} ref={register({ required: "PLEASE_ENTER_API_KEY" })} placeholder={t("PLEASE_ENTER_API_KEY")} />
                                        {errors.apiKey && <div className="invalid-feedback">{t(errors.apiKey.message)}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div class="form-group">
                                        <label>{t("API_SECRET")}</label>
                                        <input type="text" class="form-control form-control-lg" name="apiSecret" defaultValue={apiSecret} ref={register({ required: "PLEASE_ENTER_API_SECRET" })} placeholder={t("PLEASE_ENTER_API_SECRET")} />
                                        {errors.apiSecret && <div className="invalid-feedback">{t(errors.apiSecret.message)}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div class="form-group">
                                        <label>{t("CALLBACK_URL")}</label>
                                        <input type="text" class="form-control form-control-lg" name="callbackUrl" defaultValue={callbackUrl} ref={register({ required: "PLEASE_ENTER_CALLBACK_URL" })} placeholder={t("PLEASE_ENTER_CALLBACK_URL")} />
                                        {errors.callbackUrl && <div className="invalid-feedback">{t(errors.callbackUrl.message)}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div class="form-group">
                                        <label>{t("RESPONSE_URL")}</label>
                                        <input type="text" class="form-control form-control-lg" name="responseUrl" defaultValue={responseUrl} ref={register({ required: "PLEASE_ENTER_RESPONSE_URL" })} placeholder={t("PLEASE_ENTER_RESPONSE_URL")} />
                                        {errors.responseUrl && <div className="invalid-feedback">{t(errors.responseUrl.message)}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div class="form-group">
                                        <label>{t("SECRET_KEY")}</label>
                                        <input type="text" class="form-control form-control-lg" name="secretKey" defaultValue={secretKey} ref={register} />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div class="form-group">
                                        <label>{t("PAYMENT_TYPE")}</label>
                                        <select name="typeId" ref={register}
                                            className="form-control form-control-lg">
                                            <option value={PaymentGatewayType._ONLINE_BANKING_GPAY}>{t('ONLINE_BANKING')}</option>
                                            <option value={PaymentGatewayType._E_WALLET_MXPAY}>{t('E_WALLET')}</option>
                                            <option value={PaymentGatewayType._DUITNOW_GPAY}>{t('DUITNOW')}</option>
                                            <option value={PaymentGatewayType._VENDING_MACHINE}>{t('VENDING_MACHINE')}</option>
                                            <option value={PaymentGatewayType._WINPAY}>{t('WINPAY')}</option>
                                            <option value={PaymentGatewayType._KINGPAY_DUITNOW}>{t('KINGPAY_DUITNOW')}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div class="form-group">
                                        <label>{t("MIN_TOP_UP_AMOUNT")}</label>
                                        <input type="number"
                                            class="form-control form-control-lg"
                                            name="minTopUpAmount"
                                            placeholder={t("PLEASE_ENTER_MIN_TOP_UP_AMOUNT")}
                                            defaultValue={watch('minTopUpAmount')}
                                            ref={register} />
                                        {errors.minTopUpAmount && <div className="invalid-feedback">{t(errors.minTopUpAmount.message)}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div class="form-group">
                                        <label>{t("MAX_TOP_UP_AMOUNT")}</label>
                                        <input
                                            type="number"
                                            class="form-control form-control-lg"
                                            name="maxTopUpAmount"
                                            placeholder={t("PLEASE_ENTER_MAX_TOP_UP_AMOUNT")}
                                            defaultValue={watch('maxTopUpAmount')}
                                            ref={register({
                                                min: {
                                                    value: stringIsNullOrEmpty(watch('minTopUpAmount')) ? 0 :
                                                        parseFloat(watch('minTopUpAmount')),
                                                    message: 'MAX_TOP_UP_AMOUNT_LOWER_THAN_MIN'
                                                }
                                            })} />
                                        {errors.maxTopUpAmount && <div className="invalid-feedback">{t(errors.maxTopUpAmount.message)}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div class="form-group">
                                        <label>{t("INTERFACE_SHOP")}</label>
                                        <Select
                                            isSearchable={true}
                                            isClearable={true}
                                            options={shopOptions}
                                            name="interfaceShopId"
                                            value={shopOptions.filter(option => option.value == selectedInterfaceShopId)}
                                            onChange={(e) => {
                                                setSelectedInterfaceShopId(e ? e.value : null);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div class="form-group">
                                        <label>{t("PROCESSING_FEE")}</label>
                                        <input type="number"
                                            step={'0.01'}
                                            class="form-control form-control-lg"
                                            name="processingFee"
                                            defaultValue={watch('processingFee')}
                                            ref={register({
                                                min: {
                                                    value: 0,
                                                    message: 'MIN_ERROR'
                                                }
                                            })} />
                                        {errors.processingFee && <div className="invalid-feedback">{t(errors.processingFee.message)}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div class="form-group">
                                        <label>{t("STATUS")}</label>
                                        <div>
                                            <div className="switcher">
                                                <input type="checkbox" name="status" id="status"
                                                    onChange={(e) => { setStatus(e.target.checked) }}
                                                    value={true}
                                                    checked={status}
                                                    ref={register} />
                                                <label htmlFor="status"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div class="form-group">
                                        <label>{t("OPERATION_HOUR")}</label>
                                        <div>
                                            <div className="switcher">
                                                <input type="checkbox" name="hasOperationHour" id="hasOperationHour"
                                                    onChange={(e) => { setHasOperationHour(e.target.checked) }}
                                                    value={true}
                                                    checked={hasOperationHour}
                                                    ref={register} />
                                                <label htmlFor="hasOperationHour"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    hasOperationHour &&
                                    <>
                                        <div className="col-lg-3">
                                            <div class="form-group">
                                                <label>{t("START_HOUR")}</label>
                                                <DateTime
                                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                                    name="startAtString"
                                                    dateFormat={false}
                                                    timeFormat="HH:mm"
                                                    closeOnSelect={true}
                                                    value={watch('startAtString')}
                                                    onChange={(e) => {
                                                        if (e instanceof moment) {
                                                            setValue("startAtString", e.format("HH:mm"));
                                                        }
                                                        else {
                                                            setValue("startAtString", "");
                                                        }
                                                    }} />
                                                {errors.startAtString && <div className="invalid-feedback">{t(errors.startAtString.message)}</div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div class="form-group">
                                                <label>{t("END_HOUR")}</label>
                                                <DateTime
                                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                                    name="endAtString"
                                                    dateFormat={false}
                                                    timeFormat="HH:mm"
                                                    closeOnSelect={true}
                                                    value={watch('endAtString')}
                                                    onChange={(e) => {
                                                        if (e instanceof moment) {
                                                            setValue("endAtString", e.format("HH:mm"));
                                                        }
                                                        else {
                                                            setValue("endAtString", "");
                                                        }
                                                    }} />
                                                {errors.endAtString && <div className="invalid-feedback">{t(errors.endAtString.message)}</div>}
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className='row'>
                                {
                                    watch('typeId') == PaymentGatewayType._WINPAY &&
                                    telcoList.map((item, index) => {
                                        return (
                                            <div className="col-lg-3">
                                                <div class="form-group">
                                                    <label>{item.name.toUpperCase()}</label>
                                                    <input type="decimal"
                                                        class="form-control form-control-lg"
                                                        name={item.id.toString()}
                                                        placeholder={t("ENTER_RATES")}
                                                        defaultValue={''}
                                                        ref={register({
                                                            min: {
                                                                value: 0,
                                                                message: 'INVALID_AMOUNT'
                                                            }
                                                        })} />
                                                    {errors[item.id] && <div className="invalid-feedback">{t(errors[item.id].message)}</div>}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <button class="btn btn-primary">{t('BTN_SUBMIT')}</button>
                        </PanelBody>
                    </Panel>
                </form>
            </div>
        </div>
    );
}

export default ManagePaymentGatewayDetail;