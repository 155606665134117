import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelHeader,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import ReactTable from "../../../components/custom/ReactTable";
import { ApiKey, ApiUrl, WebUrl, AccessRight, Role } from "../../../util/Constant";
import { stringIsNullOrEmpty, numberWithCurrencyFormat, getValidationMessage } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';
import { useSelector } from "react-redux";

/// <summary>
/// Author : Wind
/// </summary> 
const UserExperienceReport = props => {
    var _userData = useSelector(state => state['authState']['userData']);
    const { t } = useTranslation();
    const { register, handleSubmit, setValue, errors } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [phone, setPhone] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));
    const [regionId, setRegionId] = useState('');
    const [regions, setRegions] = useState([]);
   
    const _TYPE_OPTION = {
        _UP: 1,
        _DOWN: 2
    };

    let _tableColumns = [
        {
            Header: "No",
            id: "row",
            filterable: false,
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            disableSortBy: true
        },
        {
            Header: "DATE",
            accessor: "createdTime",
            Cell: ({ row }) => {
                return <div>{moment(row.original.createdTime).format('DD/MM/YYYY hh:mm:ss A')}</div>;
            },
            disableSortBy: true
        },
        {
            Header: "USERNAME",
            Cell: ({ row }) => {
                return <div>{row.original.user.username}</div>;
            },
            disableSortBy: true
        },
        {
            Header: "FACTOR",
            accessor: "factor",
            disableSortBy: true
        },
        {
            Header: "AMOUNT",
            accessor: "amount",
            disableSortBy: true
        },
        {
            Header: "PRE_EXPERIENCE",
            accessor: "preExperience",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{numberWithCurrencyFormat(row.original.preExperience, 3)}</div>
            }
        },
        {
            Header: "EXPERIENCE",
            accessor: "experience",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{numberWithCurrencyFormat(row.original.experience, 3)}</div>
            }  
        },
        {
            Header: "POST_EXPERIENCE",
            accessor: "postExperience",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{numberWithCurrencyFormat(row.original.postExperience, 3)}</div>
            }
        },
        {
            Header: "PRE_RANK",
            Cell: ({ row }) => {
                return <div>{row.original.preRank.translatedName}</div>
            },
            disableSortBy: true
        },
        {
            Header: "POST_RANK",
            Cell: ({ row }) => {
                return <div>{row.original.postRank.translatedName}</div>
            },
            disableSortBy: true
        },
        {
            Header: "TYPE",
            Cell: ({ row }) => {
                return renderType(row.original.historyTypeId);
            },
            disableSortBy: true
        },
        {
            Header: "STATUS",
            Cell: ({ row }) => {
                return renderStatus(row.original.completed);
            },
            disableSortBy: true
        },
        {
            Header: "REMARK",
            accessor: "remark",
            disableSortBy: true
        },
    ]

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        (async () => {
            var regionJsonResult = await ApiEngine.get(ApiUrl._API_GET_REGION);

            if (regionJsonResult[ApiKey._API_SUCCESS_KEY]) {
                var availableRegions = [];

                regionJsonResult[ApiKey._API_DATA_KEY].map((region) => {
                    availableRegions.push({
                        label: region['name'],
                        value: region['id']
                    });
                });

                if (availableRegions.length > 0 && !_userData.isAdminLevel) {
                    setRegionId(availableRegions[0]['value']);
                }

                setRegions(availableRegions);
            }
        })();
    }, [])

    /// <summary>
    /// Author : Wind
    /// </summary>
    function renderType(type) {
        let typeString = "";
        let spanClass = "";

        switch (type) {
            case _TYPE_OPTION._UP:
                spanClass = "badge-green";
                typeString = t("UP");
                break;
            case _TYPE_OPTION._DOWN:
                spanClass = "badge-red";
                typeString = t("DOWN");
                break;
            default:
                spanClass = "badge-default";
                typeString = t("UNKNOWN");
        }

        return <span className={"badge " + spanClass}> {typeString}</span>
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    function renderStatus(status) {
        let statusString = "";
        let spanClass = "";

        if (status) {
            spanClass = "badge-green";
            statusString = t("SUCCESS");
        }
        else {
            spanClass = "badge-red";
            statusString = t("FAILED");
        }

        return <span className={"badge " + spanClass}> {statusString}</span>
    }

    /// <summary>
    /// Author : Wind
    /// </summary> 
    async function applyFilter() {
        let apiUrl = ApiUrl._API_GET_USER_EXPERIENCE_HISTORY + '?StartDate=' + startDate + "&endDate=" + endDate + "&Username=" + phone + "&v=" + Date.now();

        if (!stringIsNullOrEmpty(regionId)) {
            apiUrl += "&RegionId=" + regionId;
        }

        setApiUrl(apiUrl);
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable columns={_tableColumns} fetchUrl={apiUrl} exportRequired={true} />, [apiUrl])

    return (
        <div>
            <h1 className="page-header">{t("USER_EXPERIENCE_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("PHONE_NUMBER")}</label>
                                <input
                                    type="text"
                                    name="phone"
                                    className="form-control form-control-lg"
                                    onInput={(e) => setPhone(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("START_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="startDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={startDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("startDate", "");
                                        }
                                    }} />
                                {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("END_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="endDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={endDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                            setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("endDate", "");
                                        }
                                    }} />
                                {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <label className="w-50">{t("REGION")}</label>
                            <select
                                className="form-control form-control-lg col-lg-12"
                                id="region"
                                name="region"
                                value={regionId}
                                onChange={(e) => { setRegionId(e.target.value); }}>
                                {
                                    _userData.isAdminLevel &&
                                    <option value={''} key={-1}>{t('ALL')}</option>
                                }
                                {
                                    regions.map((region, index) => {
                                        return <option value={region['value']} key={index}>{region['label']}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <button type="button" className="btn btn-primary" onClick={() => { applyFilter() }}>{t('APPLY_FILTER')}</button>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
}

export default UserExperienceReport;