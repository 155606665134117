import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelBody,
} from "../../components/panel/panel.jsx";
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import ReactTable from "../../components/custom/ReactTable";
import { stringIsNullOrEmpty } from "../../util/Util";
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, Role, AlertTypes, TransactionType, TopUpStatus } from "../../util/Constant";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Toast, ToastBody } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { showResponseMessage, showMessage } from '../../redux/AppAction';
import ApiEngine from '../../util/ApiEngine.js';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const CreditTopupReport = props => {
    let _dispatch = useDispatch();
    const { register, handleSubmit, setValue } = useForm();
    const { t } = useTranslation();
    const [postData, setPostData] = useState({});
    const [apiUrl, setApiUrl] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [interfaceShop, setInterfaceShop] = useState();
    const _userData = useSelector(state => state.authState.userData);
    const [showToast, setShowToast] = useState(false);

    const _TYPE_OPTION = {
        ALL: -1,
        PENDING: 0,
        SUCCESS: 1,
        FAILED: 2
    };

    const _TRANSACTION_TYPE_ID = {
        GPAY: 6,
        EGHL: 7,
        EWALLET: 8,
        DUITNOW: 9,
        VENDING_MACHINE: 12,
        MOBILE_PIN_TOPUP: 13
    };

    let _tableColumns = [
        {
            Header: "CREATED_DATE",
            accessor: "createdDate",
            Footer: () => {
                return <span style={{ float: 'right' }}><b>TOTAL: </b></span>
            },
            disableSortBy: true,
        },
        {
            Header: "MEMBER",
            accessor: "memberUsername",
            disableSortBy: true
        },
        {
            Header: "SHOP",
            accessor: "shopUsername",
            disableSortBy: true
        },
        {
            Header: "TRANSACTION_ID",
            accessor: "transactionId",
            Cell: ({ row }) => {
                return (
                    <>
                        <span>{row.original.transactionId}</span>
                        &nbsp;
                        <button
                            className='btn btn-primary btn-icon btn-circle btn-sm'
                            style={{
                                'padding': '0',
                                'borderRadius': '50px'
                            }}
                            onClick={() => {
                                navigator.clipboard.writeText(row.original.transactionId);
                                setShowToast(!showToast);
                            }}
                        >
                            <i class="fas fa-copy"></i>
                        </button>
                    </>
                );
            },
            disableSortBy: true,
        },
        {
            Header: "TRANSACTION_TYPE",
            accessor: "typeName",
            Cell: ({ row }) => {
                return renderTransactionType(row.original.typeName);
            },
            disableSortBy: true
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                return renderStatus(row.original.status);
            },
            disableSortBy: true
        },
        // {
        //     Header: "INITIAL_CREDIT",
        //     accessor: "initialCredit",
        //     Cell: ({ row }) => {
        //         return row.original.initialCredit.toFixed(2)
        //     },
        //     Footer: ({ page }) => {
        //         const initialCreditTotal = page.reduce((sum, currentValue) => {
        //             return sum + parseFloat(currentValue.original.initialCredit);
        //         }, 0);

        //         return <span><b>{initialCreditTotal.toFixed(2)}</b></span>
        //     },
        //     disableSortBy: true
        // },
        {
            Header: "AMOUNT",
            accessor: "amount",
            Cell: ({ row }) => {
                return row.original.amount.toFixed(2)
            },
            // Footer: ({ page }) => {
            //     const amountTotal = page.reduce((sum, currentValue) => {
            //         return sum + parseFloat(currentValue.original.amount);
            //     }, 0);

            //     return <span><b>{amountTotal.toFixed(2)}</b></span>
            // },
            disableSortBy: true
        },
        // {
        //     Header: "FINAL_CREDIT",
        //     accessor: "finalCredit",
        //     Cell: ({ row }) => {
        //         return row.original.finalCredit.toFixed(2)
        //     },
        //     Footer: ({ page }) => {
        //         const finalCreditTotal = page.reduce((sum, currentValue) => {
        //             return sum + parseFloat(currentValue.original.finalCredit);
        //         }, 0);

        //         return <span><b>{finalCreditTotal.toFixed(2)}</b></span>
        //     },
        //     disableSortBy: true
        // },
        {
            Header: "PROCESSED_DATE",
            accessor: "processedDate",
            Cell: ({ row }) => {
                return !stringIsNullOrEmpty(row.original.processedDate) ? row.original.processedDate : '-'
            },
            disableSortBy: true
        },
        {
            Header: "BANK_TRANSACTION_ID",
            accessor: "bankTransactionId",
            Cell: ({ row }) => {
                return !stringIsNullOrEmpty(row.original.bankTransactionId) ? row.original.bankTransactionId : '-'
            },
            disableSortBy: true
        },
        {
            Header: "REMARK",
            accessor: "remark",
            disableSortBy: true
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                (_userData['isCustomerService'] && (row.original.status == TopUpStatus._PENDING || row.original.status == TopUpStatus._PENDING_APPROVAL || row.original.status == TopUpStatus._FAILED)) &&
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    content: t("VERIFY_PASSWORD_BEFORE_CONTINUE"),
                                    showCancel: true,
                                    required: true,
                                    inputType: 'password',
                                    validationMsg: t('INVALID_PASSWORD'),
                                    confirmBtnText: t("CONFIRM"),
                                    type: AlertTypes._INPUT,
                                    onConfirm: async (value) => {
                                        await verifyPassword(value, row.original, true);
                                    }
                                }));
                            }}><span className="text-warning">{t("APPROVE")}</span></DropdownItem>
                            {
                                row.original.status != TopUpStatus._FAILED &&
                                <DropdownItem onClick={() => {
                                    _dispatch(showMessage({
                                        content: t("VERIFY_PASSWORD_BEFORE_CONTINUE"),
                                        showCancel: true,
                                        required: true,
                                        inputType: 'password',
                                        validationMsg: t('INVALID_PASSWORD'),
                                        confirmBtnText: t("CONFIRM"),
                                        type: AlertTypes._INPUT,
                                        onConfirm: async (value) => {
                                            await verifyPassword(value, row.original, false);
                                        }
                                    }));
                                }}><span className="text-warning">{t("REJECT")}</span></DropdownItem>
                            }
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ]

     /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        if (showToast) {
            setTimeout(() => setShowToast(!showToast), 3000)
        }
    }, [showToast])

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function renderStatus(status) {
        let statusString = "";
        let spanClass = "";

        switch (status) {
            case TopUpStatus._SUCCESS.toString():
                spanClass = "badge-green";
                statusString = t("SUCCESS");
                break;
            case TopUpStatus._FAILED.toString():
                spanClass = "badge-red";
                statusString = t("FAILED");
                break;
            default:
                spanClass = "badge-default";
                statusString = t("PENDING");
        }

        return <span className={"badge " + spanClass}> {statusString}</span>
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: true });
        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
        getInterfaceShop();
        handleSubmit(submitForm)();
    }, []);

    /// <summary>
    /// Author : CK
    /// </summary>
    async function getInterfaceShop() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_INTERFACE_SHOP);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setInterfaceShop(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        var shopTarget = [];
        shopTarget.push(_userData.userId);

        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "TargetShops": shopTarget,
            "Status": parseInt(data.status),
            "Username": data.username,
            "TransactionTypeId": parseInt(data.transactionType),
            "TransactionId": data.transactionId,
            "IsShopInterface": true
        };

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_TOPUP_TRANSACTION);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function isValidDate(selectedDate) {
        return selectedDate.format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD");
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function renderTransactionType(transactionType) {
        let transactionTypeString = "";

        switch (transactionType) {
            case TransactionType._TOP_UP_GPAY:
                transactionTypeString = t("GPAY");
                break;
            case TransactionType._TOP_UP_EGHL:
                transactionTypeString = t("EGHL");
                break;
            case TransactionType._TOP_UP_E_WALLET:
                transactionTypeString = t("E_WALLET");
                break;
            case TransactionType._TOP_UP_GPAY_DUITNOW:
                transactionTypeString = t("DUITNOW");
                break;
            case TransactionType._VENDING_MACHINE:
                transactionTypeString = t("VENDING_MACHINE");
                break;
            case TransactionType._MOBILE_PIN_TOPUP:
                transactionTypeString = t("MOBILE_PIN_TOPUP");
                break;
        }

        return <span className="badge badge-primary">{transactionTypeString}</span>
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function verifyPassword(password, transactionData, isApproved) {
        var responseJson = await ApiEngine.post(`${ApiUrl._API_VERIFY_PASSWORD}?userId=${_userData['userId']}&password=${password}`);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            SetTransactionStatus(transactionData, isApproved);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function SetTransactionStatus(data, status) {
        var responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_TOPUP_TRANSACTION + "?transactionId=" + data.transactionId + "&status=" + status);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleSubmit(submitForm)();
        }
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable className="col-nowrap" initialPageSize={500} columns={_tableColumns} fetchUrl={apiUrl} postData={postData} exportRequired={true} />, [apiUrl, postData])

    return (
        <div>
            <h1 className="page-header">{t('TOP_UP_REPORT')} {(interfaceShop && interfaceShop['shopName']) ? `(${interfaceShop['shopName']} ${t('CURRENT_LIMIT')}: ${interfaceShop['credit'].toFixed(2)})` : ""}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("USERNAME")}</label>
                                    <input type="text" name="username" ref={register}
                                        className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("TRANSACTION_ID")}</label>
                                    <input type="text" name="transactionId" ref={register}
                                        className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("TRANSACTION_TYPE")}</label>
                                    <select name="transactionType" ref={register} className="form-control form-control-lg">
                                        <option value={_TYPE_OPTION.ALL}>{t("ALL")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.GPAY}>{t("GPAY")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.EGHL}>{t("EGHL")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.EWALLET}>{t("E_WALLET")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.EGHL}>{t("EGHL")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.MOBILE_PIN_TOPUP}>{t("MOBILE_PIN_TOPUP")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("STATUS")}</label>
                                    <select name="status" ref={register} className="form-control form-control-lg">
                                        <option value={_TYPE_OPTION.ALL}>{t("ALL")}</option>
                                        <option value={TopUpStatus._PENDING}>{t("PENDING")}</option>
                                        <option value={TopUpStatus._SUCCESS}>{t("SUCCESS")}</option>
                                        <option value={TopUpStatus._FAILED}>{t("FAILED")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        isValidDate={isValidDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        isValidDate={isValidDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
            <Toast style={{ position: 'fixed', bottom: '1rem', left: '50%' }} isOpen={showToast} transition={{ key: "1", transitionLeaveTimeout: 3000 }}>
                <ToastBody>
                    {t('COPIED')}
                </ToastBody>
            </Toast>
        </div>
    )
}

export default CreditTopupReport;