export const _SET_SEARCH_MEMBER_MODAL_VISIBLE = "SET_SEARCH_MEMBER_MODAL_VISIBLE";

/// <summary>
/// Author : CK
/// </summary>
export const setSearchMemberModalVisible = (isModalVisible, targetUrl = null, isSearchAgent = false) => {
    return async dispatch => {
        dispatch({
            type: _SET_SEARCH_MEMBER_MODAL_VISIBLE,
            isModalVisible: isModalVisible,
            targetUrl: targetUrl,
            isSearchAgent: isSearchAgent
        });
    }
}