import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import ReactTable from "../../../components/custom/ReactTable";
import { setBusy, setIdle, showMessage } from '../../../redux/AppAction';
import { ApiKey, ApiUrl, Role, Status, AlertTypes, ViewDataType } from "../../../util/Constant";
import { stringIsNullOrEmpty, arrayGroupByKey, numberWithCurrencyFormat } from "../../../util/Util";
import { useForm } from "react-hook-form";
import ApiEngine from '../../../util/ApiEngine.js';
import { useDispatch } from "react-redux";
import RegionFilterDualListBox from '../../../components/custom/RegionFilterDualListBox';
import DualListBoxItemRow from "components/custom/DualListBoxItemRow.js";

/// <summary>
/// Author : Wind
/// </summary>
const TurnoverReport = props => {
    var _dispatch = useDispatch();
    const { register, handleSubmit, setValue } = useForm();
    const { t } = useTranslation();
    const [apiUrl, setApiUrl] = useState('');
    const [postData, setPostData] = useState({});
    const [phone, setPhone] = useState('');
    const [playerId, setPlayerId] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [shopOption, setShopOption] = useState([]);
    const [selectedShop, setSelectedShop] = useState([]);
    const [vendorOption, setVendorOption] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState([]);
    const [regions, setRegions] = useState([]);
    const _EGHL_VENDOR_ID = -1;
    const _DATE_RANGE_TYPE = {
        firstWeek: 1,
        firstHalfMonth: 2,
        secondHalfMonth: 3,
        thisMonth: 4
    };

    let _tableColumns = [
        {
            Header: "START_DATE",
            accessor: "parsedStartDate",
            disableSortBy: true,
        },
        {
            Header: "END_DATE",
            accessor: "parsedEndDate",
            disableSortBy: true,
        },
        {
            Header: "USERNAME",
            accessor: "username",
            disableSortBy: true,
        },
        {
            Header: "PLAYER_ID",
            accessor: "playerUsername",
            disableSortBy: true
        },
        {
            Header: "VENDOR",
            accessor: "vendorName",
            disableSortBy: true
        },
        {
            Header: "TOTAL_VALID_TURNOVER",
            accessor: "sumAmount",
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.sumAmount);
                }, 0);

                return <span><b>{numberWithCurrencyFormat(totalAmount)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "TOTAL_JACKPOT",
            accessor: "totalJackpot",
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.totalJackpot);
                }, 0);

                return <span><b>{numberWithCurrencyFormat(totalAmount)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "MAX_JACKPOT",
            accessor: "maxJackpot",
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.maxJackpot);
                }, 0);

                return <span><b>{numberWithCurrencyFormat(totalAmount)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "TOTAL_BONUS",
            accessor: "totalBonus",
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.totalBonus);
                }, 0);

                return <span><b>{numberWithCurrencyFormat(totalAmount)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "MAX_BONUS",
            accessor: "maxBonus",
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.maxBonus);
                }, 0);

                return <span><b>{numberWithCurrencyFormat(totalAmount)}</b></span>
            },
            disableSortBy: true
        }
    ]

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        (async () => {
            try {
                var shopResponseJson = await ApiEngine.get(ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP);

                if (shopResponseJson[ApiKey._API_SUCCESS_KEY]) {
                    var shopListByRegion = arrayGroupByKey(shopResponseJson[ApiKey._API_DATA_KEY], "regionName");
                    var shopOptionsByRegion = [];
                    var regionOptions = [];

                    for (var key in shopListByRegion) {
                        var shopList = [];

                        shopListByRegion[key].map(shop => {
                            shopList.push({ label: shop.username + " (" + shop.shopName + ")", value: shop.id });
                        });

                        shopOptionsByRegion.push({
                            label: key,
                            value: key,
                            options: shopList
                        })

                        regionOptions.push({
                            label: key,
                            value: key
                        });
                    }

                    setRegions(regionOptions);
                    setShopOption(shopOptionsByRegion);
                }
                else {
                    throw shopResponseJson[ApiKey._API_MESSAGE_KEY];
                }

                var vendorResponseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_LIST);

                if (vendorResponseJson[ApiKey._API_SUCCESS_KEY]) {
                    var vendorList = [];

                    vendorResponseJson[ApiKey._API_DATA_KEY].forEach(function (vendor) {
                        vendorList.push({ label: vendor.name + " (" + vendor.shortName + ")", value: vendor.id });
                    });

                    vendorList.push({ label: "eGHL (eGHL)", value: _EGHL_VENDOR_ID });

                    setVendorOption(vendorList);
                }
                else {
                    throw vendorResponseJson[ApiKey._API_MESSAGE_KEY];
                }
            }
            catch (err) {
                _dispatch(showMessage({
                    type: AlertTypes._ERROR,
                    content: t(err),
                    onConfirm: () => { }
                }));
            }
        })();
    }, []);

    /// <summary>
    /// Author : Wind
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": startDate,
            "EndDate": endDate,
            "TargetShop": selectedShop,
            "Vendor": selectedVendor,
        };

        if (!stringIsNullOrEmpty(phone)) {
            params["Username"] = phone;
        }

        if (!stringIsNullOrEmpty(playerId)) {
            params["PlayerId"] = playerId;
        }

        if (!stringIsNullOrEmpty(data.viewDataType)) {
            params["ViewDataType"] = Number(data.viewDataType);
        }

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_TURNOVER_REPORT);
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    function updateDateRange(rangekey) {
        let targetStartDate = moment().startOf('month')
        let targetEndDate = moment().endOf('day');

        switch (rangekey){
            case _DATE_RANGE_TYPE.firstWeek: 
                targetEndDate = moment(targetStartDate).add(7, 'days');
                break;
            case _DATE_RANGE_TYPE.firstHalfMonth:
                targetEndDate = moment(targetStartDate).add(14, 'days');
                break;
            case _DATE_RANGE_TYPE.secondHalfMonth:
                targetStartDate = moment(targetStartDate).add(14, 'days');
                targetEndDate = moment().endOf('month');
                break;
            case _DATE_RANGE_TYPE.thisMonth:
                targetEndDate = moment().endOf('month');
                break;
            default:
                break;
        }

        setStartDate(moment(targetStartDate).format("YYYY-MM-DD HH:mm:ss"));
        setEndDate(moment(targetEndDate).format("YYYY-MM-DD 23:59:59"));
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const PaginationTable = useMemo(() =>
        <ReactTable
            className="col-nowrap"
            columns={_tableColumns}
            fetchUrl={apiUrl}
            postData={postData}
            showOverallInfo={true}
            renderFooter={true}
            exportRequired={true} />
        , [apiUrl, postData])

    return (
        <div>
            <h1 className="page-header">{t("TURNOVER_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("PHONE_NUMBER")}</label>
                                    <input type="text" name="phone" onInput={(e) => setPhone(e.target.value)} className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("PLAYER_ID")}</label>
                                    <input type="text" name="playerId" onInput={(e) => setPlayerId(e.target.value)} className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("VIEW_BY")}</label>
                                    <select name="viewDataType" ref={register} className="form-control form-control-lg">
                                        <option value={ViewDataType._ALL}>{t("ALL")}</option>
                                        <option value={ViewDataType._JACKPOT}>{t("JACKPOT")}</option>
                                        <option value={ViewDataType._BONUS}>{t("BONUS")}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row m-b-10">
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>{t("SHOP")}</label>
                                    <RegionFilterDualListBox
                                        name={"shops"}
                                        ref={register}
                                        options={shopOption}
                                        selectedOptions={selectedShop}
                                        regions={regions}
                                        onChange={(e) => {
                                            setSelectedShop(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>{t("VENDOR")}</label>
                                    <DualListBoxItemRow options={vendorOption} selected={selectedVendor} />
                                    <DualListBox
                                        canFilter
                                        name="vendors"
                                        options={vendorOption}
                                        selected={selectedVendor}
                                        onChange={(e) => {
                                            setSelectedVendor(e);
                                        }}
                                        ref={register}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>&nbsp; &nbsp;
                                <button className="btn btn-warning" onClick={() => updateDateRange(_DATE_RANGE_TYPE.firstWeek)}>{t('FIRST_WEEK')}</button>&nbsp; &nbsp;
                                <button className="btn btn-success" onClick={() => updateDateRange(_DATE_RANGE_TYPE.firstHalfMonth)}>{t('FIRST_HALF_MONTH')}</button>&nbsp; &nbsp;
                                <button className="btn btn-danger" onClick={() => updateDateRange(_DATE_RANGE_TYPE.secondHalfMonth)}>{t('SECOND_HALF_MONTH')}</button>&nbsp; &nbsp;
                                <button className="btn btn-info" onClick={() => updateDateRange(_DATE_RANGE_TYPE.thisMonth)}>{t('THIS_MONTH')}</button>&nbsp; &nbsp;
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
};

export default TurnoverReport;