import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel';
import { ApiKey, WebUrl, ApiUrl, AlertTypes, Role, SystemSettingName } from '../../util/Constant';
import { createMultiPartFormBody, navigateTo, formatNumber, getValidationMessage, stringIsNullOrEmpty, validatePhoneNumber, isObjectEmpty } from "../../util/Util";
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import IntlTelInput from 'react-intl-tel-input';
import { showMessage, showResponseMessage } from 'redux/AppAction';
import PasswordConfirmationModal from "../../components/custom/PasswordConfirmationModal";

/// <summary>
/// Author : Andrew
/// CK - Edit member details
/// </summary>
const MemberDetails = props => {
    let _history = useHistory();
    let _location = useLocation();
    let _dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const {
        register: registerEditMember,
        handleSubmit: handleSubmitEditMember,
        errors: errorsEditMember,
        setValue: setValueEditMember,
        triggerValidation: triggerValidationEditMember,
        watch: watchEditMember
    } = useForm();
    const {
        register: registerEditPassword,
        handleSubmit: handleSubmitEditPassword,
        errors: errorsEditPassword,
        setValue: setValueEditPassword,
    } = useForm();
    const [memberDetails, setMemberDetails] = useState(null);
    const [availableProduct, setAvailableProduct] = useState([]);
    const [renderPhone, setRenderPhone] = useState(false);
    const [phone, setPhone] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState(false);
    const _phoneFieldRef = useRef(null);

    /// <summary>
    /// Author : Andrew
    /// </summary>
    useEffect(() => {
        if (!_location.state || !_location.state.isMember) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t("INVALID_ACCESS"),
                onConfirm: () => navigateTo(_history, WebUrl._URL_SHOP_SEARCH),
            }));
        }
        else {
            init();
        }
    }, []);

    /// <summary>
    /// Author : Andrew
    /// </summary>
    async function init() {
        try {
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_ACCOUNT + "?id=" + (_location.state != undefined ? _location.state.data.id : ""));
            setRenderPhone(false);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setMemberDetails(responseJson[ApiKey._API_DATA_KEY]);
                setPhone(responseJson[ApiKey._API_DATA_KEY].phoneNumber);
                setRenderPhone(true);

                var responseJson = await ApiEngine.get(ApiUrl._API_RETRIEVE_RELOAD_DETAILS + "?username=" + (_location.state != undefined ? _location.state.data.username : ""));

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let products = [];
                    responseJson[ApiKey._API_DATA_KEY].map((game, index) => {
                        if (stringIsNullOrEmpty(selectedProduct)) {
                            setSelectedProduct(game.vendor.code);
                        }
                        products.push({ value: game.vendor.code, label: game.vendor.name, displayName: game.vendor.shortName });
                    })

                    setAvailableProduct(products);
                }
                else {
                    throw new Error(responseJson[ApiKey._API_SUCCESS_KEY]);
                }
            }
            else {
                throw new Error(responseJson[ApiKey._API_SUCCESS_KEY]);
            }
        }
        catch (e) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(e.message),
                onConfirm: () => navigateTo(_history, WebUrl._URL_SHOP_SEARCH),
            }));
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function editMember(data) {
        let params = {
            id: memberDetails.id,
            username: data.phone.substr(1),
            userFullName: data.phone,
            roleId: Role._MEMBER,
            phoneNumber: data.phone
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_USER_ACCOUNT, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
        }));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            init();
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function changeMemberPassword(data) {
        if (data.newPassword != data.confirmNewPassword) {
            _dispatch(showResponseMessage(false, t('ERROR_PASSWORD_NOT_MATCH')));
        }
        else {
            var apiUrl = ApiUrl._API_CHANGE_PASSWORD + "?oldPassword=" + data.oldPassword + "&newPassword=" + data.newPassword + "&targetUserId=" + memberDetails.id;
            var responseJson = await ApiEngine.post(apiUrl);

            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                resetEditPasswordForm();
            }
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    function resetEditPasswordForm() {
        setValueEditPassword("oldPassword", "");
        setValueEditPassword("newPassword", "");
        setValueEditPassword("confirmNewPassword", "");
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function resetPasswordHandler() {
        try {
            var apiUrl = ApiUrl._API_RESET_MEMBER_PASSWORD + "?userId=" + memberDetails.id;

            var responseJson = await ApiEngine.post(apiUrl);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                if (responseJson[ApiKey._API_DATA_KEY] == null || isObjectEmpty(responseJson[ApiKey._API_DATA_KEY])) {
                    _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
                }
                else {
                    let responseData = responseJson[ApiKey._API_DATA_KEY];
                    _dispatch(showMessage({
                        type: AlertTypes._WARNING,
                        title: t('ARE_YOU_SURE'),
                        content: t('RESET_PASSWORD_ATTEMPT_CHARGE', { attempt: responseData.attempt, charge: responseData.charge }),
                        showCancel: true,
                        onConfirm: () => resetPasswordWithCharges()
                    }));
                }
            }
            else {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
        }
        catch (err) {
            _dispatch(showResponseMessage(false, t(err)));
        }
        finally {
            resetEditPasswordForm();
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    async function resetPasswordWithCharges() {
        var apiUrl = ApiUrl._API_RESET_MEMBER_PASSWORD + "?userId=" + memberDetails.id + "&chargeConfirmation=true";

        var responseJson = await ApiEngine.post(apiUrl);

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            init();
        }

    }

    /// <summary>
    /// Author : Andrew
    /// </summary>
    const onChangePhoneNumber = (isValid, newNumber, countryData, fullNumber) => {
        setPhone(newNumber.toString().replace(/\s/g, '').replace(/\-/g, ''));
        let formattedNumber = fullNumber.toString().split(/[- ]+/).join('').replace("+", "");

        registerEditMember({ name: "phone" }, {
            validate: (value) => (validatePhoneNumber(fullNumber, countryData["iso2"]) && validatePhoneNumber(newNumber, countryData["iso2"])) || "REQUIRED_VALID_PHONE_NUMBER"
        });
        setValueEditMember("phone", formattedNumber);
        triggerValidationEditMember("phone")
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function createPlayer() {
        var apiUrl = ApiUrl._API_SHOP_CREATE_PLAYER + "?code=" + selectedProduct + "&MemberId=" + memberDetails.id;

        var responseJson = await ApiEngine.post(apiUrl);
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function editPhoneValidation() {
        if (watchEditMember("phone") != memberDetails.phoneNumber) {
            setPasswordConfirmation(true);
        }
        else {
            handleSubmitEditMember(editMember)();
        }
    }

    return (
        <div>
            <h1 class="page-header">{t('MEMBER_INFO')} {_location.state != undefined && _location.state.data.username}</h1>
            <div className="row">
                <div className="col-xl-6">
                    <Panel>
                        <PanelHeader children={t('EDIT_MEMBER_DETAILS')} noButton="true"></PanelHeader>
                        <PanelBody>
                            <div class="form-group row">
                                <label for="phoneNumber" class="col-sm-3 col-form-label">{t("PHONE")}</label>
                                <div class="col-sm-4">
                                    {renderPhone && <IntlTelInput
                                        fieldName={"phone"}
                                        preferredCountries={['my']}
                                        onlyCountries={['my']}
                                        defaultCountry={"my"}
                                        style={{ display: "block", width: "100%" }}
                                        containerClassName="intl-tel-input"
                                        inputClassName="form-control form-control-lg"
                                        ref={_phoneFieldRef}
                                        onSelectFlag={(newNumber, countryData, fullNumber, ext) => {
                                            onChangePhoneNumber(_phoneFieldRef.current.isValidNumber(phone), newNumber, countryData, fullNumber);
                                        }}
                                        onPhoneNumberChange={(isValid, newNumber, countryData, fullNumber, ext) => {
                                            onChangePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                        }}
                                        onPhoneNumberBlur={(isValid, newNumber, countryData, fullNumber, ext) => {
                                            onChangePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                        }}
                                        customPlaceholder={(placeholder, countryData) => {
                                            return placeholder.split(/[- ]+/).join('');
                                        }}
                                        defaultValue={phone}
                                        value={phone}
                                        useMobileFullscreenDropdown={false}
                                        separateDialCode />}
                                    {errorsEditMember.phone && <div className="invalid-feedback">{t(getValidationMessage(errorsEditMember.phone))}</div>}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">{t("TOTAL_CREDIT")}</label>
                                <div class="col-sm-4">
                                    <input type="text" readonly class="form-control-lg form-control-plaintext" defaultValue={memberDetails ? formatNumber(memberDetails["memberProfile"].totalCredit, 2) : null} />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">{t("TOTAL_REWARD_POINT")}</label>
                                <div class="col-sm-4">
                                    <input type="text" readonly class="form-control-lg form-control-plaintext" defaultValue={memberDetails ? formatNumber(memberDetails["memberProfile"].totalRewardPoint, 2) : null} />
                                </div>
                            </div>
                            <button type="button" className="btn btn-primary" onClick={() => {
                                editPhoneValidation()
                            }}>{t('SAVE_CHANGES')}</button>
                        </PanelBody>
                    </Panel>
                </div>
                <div className="col-xl-6">
                    <Panel>
                        <PanelHeader children="Reset Password" noButton="true"></PanelHeader>
                        <PanelBody>
                            <button className="btn btn-primary" onClick={() => {
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t('ARE_YOU_SURE'),
                                    content: t('PLEASE_CONFIRM_RESET_PASSWORD'),
                                    showCancel: true,
                                    onConfirm: () => resetPasswordHandler()
                                }));
                            }}>{t('RESET_PASSWORD')}</button>
                        </PanelBody>
                    </Panel>
                    <Panel>
                        <PanelHeader children={t('CREATE_PIN')} noButton="true"></PanelHeader>
                        <PanelBody>
                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">{t('VENDOR')}</label>
                                <div class="col-sm-4">
                                    <select name="product" id="product"
                                        onChange={(e) => setSelectedProduct(e.target.value)}
                                        className="form-control form-control-lg">
                                        {
                                            availableProduct.map((product, key) => {
                                                return (<option value={product.value}>{product.label + " (" + product.displayName + ")"}</option>);
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <button className="btn btn-primary" onClick={() => createPlayer()}>{t("BTN_SUBMIT")}</button>
                        </PanelBody>
                    </Panel>
                    {passwordConfirmation &&
                        <PasswordConfirmationModal
                            memberId={_location.state != undefined ? _location.state.data.id : ""}
                            onConfirm={() => {
                                handleSubmitEditMember(editMember)();
                                setPasswordConfirmation(false);
                            }}
                            onCancel={() => { setPasswordConfirmation(false); }} />
                    }
                </div>
            </div>
        </div>
    )
}

export default MemberDetails;