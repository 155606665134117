import { WebUrl, AccessRight } from "util/Constant";

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const Menu = [
	{
		path: WebUrl._URL_DASHBOARD,
		icon: "fa fa-th",
		title: "DASHBOARD",
		isDummy: false,
		permission: AccessRight._DASHBOARD_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_VENDOR_CATEGORY,
		icon: "fa fa-book",
		title: "VENDOR_CATEGORY",
		isDummy: false,
		permission: AccessRight._GAME_VENDOR_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_VENDOR,
		icon: "fa fa-gamepad",
		title: "GAME_VENDORS",
		isDummy: false,
		permission: AccessRight._GAME_VENDOR_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_VENDOR_API,
		icon: "fa fa-link",
		title: "MANAGE_APIS",
		isDummy: false,
		permission: AccessRight._GAME_VENDOR_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_SYSTEM_SETTINGS,
		icon: "fa fa-wrench",
		title: "SYSTEM_SETTINGS",
		isDummy: false,
		permission: AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_SMS_SETTINGS,
		icon: "fa fa-comments",
		title: "SMS_SETTINGS",
		isDummy: false,
		permission: AccessRight._SMS_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_NOTIFICATION_SETTINGS,
		icon: "fa fa-bell",
		title: "NOTIFICATION_SETTINGS",
		isDummy: false,
		permission: AccessRight._SMS_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: "/admin/account",
		icon: "fa fa-users",
		title: "ACCOUNTS",
		isDummy: true,
		children: [
			{ path: WebUrl._URL_ADMIN_MANAGE_ROLE, title: "ROLES", permission: AccessRight._ADMIN_SUB_ACCOUNT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_ADMIN_MANAGE_ADMIN, title: "ADMIN", permission: AccessRight._ADMIN_SUB_ACCOUNT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_ADMIN_MANAGE_MANAGER, title: "MANAGER", permission: AccessRight._MANAGER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_ADMIN_MANAGE_AGENT, title: "AGENT", permission: AccessRight._AGENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_ADMIN_MANAGE_CUSTOMER_SERVICE, title: "CUSTOMER_SERVICE", permission: AccessRight._CUSTOMER_SERVICE_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_ADMIN_MANAGE_SHOP, title: "SHOPS", permission: AccessRight._SHOP_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_ADMIN_MANAGE_MEMBER, title: "MEMBERS", permission: AccessRight._MEMBER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_ADMIN_MANAGE_PLAYER, title: "PLAYERS", permission: AccessRight._PLAYER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
		],
	},
	{
		path: "/announcement",
		icon: "fa fa-bell",
		title: "ANNOUNCEMENTS",
		isDummy: true,
		children: [
			{ path: WebUrl._URL_ADMIN_MANAGE_SYSTEM_MESSAGE, title: "SYSTEM", permission: AccessRight._ANNOUNCEMENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_ADMIN_MANAGE_SHOP_MESSAGE, title: "SHOP", permission: AccessRight._ANNOUNCEMENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_ADMIN_MANAGE_PHONE_MESSAGE, title: "CUSTOMER", permission: AccessRight._ANNOUNCEMENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_ADMIN_MANAGE_APP_ANNOUNCEMENT, title: "APP", permission: AccessRight._ANNOUNCEMENT_EVENT_CONTROL + AccessRight._READ_PERMISSION_STRING },
		],
	},
	{
		path: "/event",
		icon: "fa fa-trophy",
		title: "EVENTS",
		isDummy: true,
		children: [
			{ path: WebUrl._URL_ADMIN_MANAGE_KOG_EVENT, title: "KOG_EVENT", permission: AccessRight._EVENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_ADMIN_MANAGE_BANNER, title: "BANNER", permission: AccessRight._BANNER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
		],
	},
	{
		path: "/feedback",
		icon: "fa fa-file",
		title: "FEEDBACK",
		isDummy: true,
		children: [
			{ path: WebUrl._URL_FEEDBACK_SETTINGS, title: "FEEDBACK_SETTINGS", permission: AccessRight._FEEDBACK_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING, hideFromFeedbackAdmin: true },
			{ path: WebUrl._URL_MEMBER_FEEDBACK_REPORT, title: "MEMBER_FEEDBACK", permission: AccessRight._FEEDBACK_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_SHOP_FEEDBACK_REPORT, title: "SHOP_FEEDBACK", permission: AccessRight._FEEDBACK_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
		],
	},
	{
		path: "/report",
		icon: "fa fa-clipboard",
		title: "REPORT",
		isDummy: true,
		children: [
			{ path: WebUrl._URL_SET_LIMIT_REPORT, title: "SET_LIMIT", permission: AccessRight._SET_LIMIT_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_SET_SCORE_REPORT, title: "SET_SCORE", permission: AccessRight._SET_SCORE_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			// { path: WebUrl._URL_REWARD_REPORT, title: 'REWARD', permission: AccessRight._REWARD_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_TURNOVER_REWARD_REPORT, title: "REWARD_REPORT", permission: AccessRight._REWARD_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_CLAIM_REWARD_REPORT, title: "CLAIM_REWARD", permission: AccessRight._SET_SCORE_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_MANAGER_LIMIT_REPORT, title: "MANAGER_LIMIT", permission: AccessRight._MANAGER_LIMIT_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_MANAGER_LIMIT_TRANSACTION_REPORT, title: "MANAGER_LIMIT_TRANSACTION", permission: AccessRight._MANAGER_LIMIT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_SALES_REPORT, title: "SALES", permission: AccessRight._SALES_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_KIOSK_TIMEOUT_REPORT, title: "KIOSK_TIMEOUT", permission: AccessRight._KIOSK_TIMEOUT_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_AGENT_COMMISSION_REPORT, title: "AGENT_COMMISSION", permission: AccessRight._AGENT_COMM_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_AGENT_NETPROFIT_REPORT, title: "AGENT_NET_PROFIT", permission: AccessRight._AGENT_NETPROFIT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_TOPUP_REPORT, title: "CREDIT_TOPUP", permission: AccessRight._TOP_UP_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_CREDIT_TRANSFER_REPORT, title: "CREDIT_TRANSFER", permission: AccessRight._CREDIT_TRANSFER_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_STRIKE_REPORT, title: "STRIKE", permission: AccessRight._STRIKE_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_MANAGE_ROLLOVER_REPORT, title: "ROLLOVER", permission: AccessRight._ROLLOVER_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_MANAGE_UPPER_LIMIT_WARNING_REPORT, title: "UPPER_LIMIT_WARNING", permission: AccessRight._UPPER_LIMIT_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_USER_EXPERIENCE_REPORT, title: "USER_EXPERIENCE_REPORT", permission: AccessRight._OPERATIONAL_LOG_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_TURNOVER_REPORT, title: "TURNOVER", permission: AccessRight._TURNOVER_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_MEMBER_ECREDIT_LOG, title: "ECREDIT_TRANSFER_MEMBER", permission: AccessRight._CREDIT_TRANSFER_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_REGION_ECREDIT_LOG, title: "ECREDIT_TRANSFER_REGION", permission: AccessRight._CREDIT_TRANSFER_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_ADMIN_OVERALL_DOWNLINE_REPORT, title: "OVERALL_DOWNLINE", permission: AccessRight._OVERALL_DOWNLINE_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_ADMIN_RANK_SUMMARY_REPORT, title: "RANK_SUMMARY", permission: AccessRight._RANK_SUMMARY_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_AGENT_WINLOSS_REPORT, title: "AGENT_WIN_LOSS", permission: AccessRight._AGENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_LOTTO_SEAMLESS_SETTLEMENT_REPORT, title: "LOTTO_SEAMLESS_SETTLEMENT", permission: AccessRight._AGENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
			{ path: WebUrl._URL_USER_GROWTH_REPORT, title: "USER_GROWTH_REPORT", permission: AccessRight._USER_GROWTH_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
		],
	},
	{
		path: WebUrl._URL_ONLINE_WITHDRAWAL_REQUEST,
		icon: "fa fa-credit-card",
		title: "ONLINE_WITHDRAWAL",
		isDummy: false,
		permission: AccessRight._ONLINE_WITHDRAWAL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_ADMIN_OPERATION_LOG,
		icon: "fa fa-th",
		title: "OPERATION_LOG",
		isDummy: false,
		permission: AccessRight._OPERATIONAL_LOG_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_RANK,
		icon: "fa fa-angle-double-up",
		title: "RANK_CONFIGURATION",
		isDummy: false,
		permission: AccessRight._RANK_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_PROMOTION_MANAGE_PROMOTION,
		icon: "fa fa-gift",
		title: "BONUS",
		isDummy: false,
		permission: AccessRight._PROMOTION_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_PROMOTION_MANAGE_SPIN_PROMOTION,
		icon: "fa fa-gift",
		title: "LUCKY_SPIN",
		isDummy: false,
		permission: AccessRight._PROMOTION_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_MANAGE_WELCOME_BONUS,
		icon: "fa fa-child",
		title: "WELCOME_BONUS",
		isDummy: false,
		permission: AccessRight._PROMOTION_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_MANAGE_LUCKY_NUMBER,
		icon: "fa fa-star",
		title: "LUCKY_NUMBER",
		isDummy: false,
		permission: AccessRight._LUCKY_NUMBER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_MINI_PROGRAM,
		icon: "fa fa-cubes",
		title: "MINI_PROGRAMS",
		isDummy: false,
		permission: AccessRight._MINI_PROGRAM_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_MANAGE_THIRD_PARTY,
		icon: "fa fa-network-wired",
		title: "MANAGE_THIRD_PARTY",
		isDummy: false,
		permission: AccessRight._GAME_VENDOR_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_MANAGE_THIRD_PARTY_API,
		icon: "fa fa-link",
		title: "MANAGE_THIRD_PARTY_API",
		isDummy: false,
		permission: AccessRight._GAME_VENDOR_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_PAYMENT_GATEWAY,
		icon: "fa fa-wallet",
		title: "PAYMENT_GATEWAY",
		isDummy: false,
		permission: AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_IP_BLACKLIST,
		icon: "fa fa-ban",
		title: "IP_BLACKLIST",
		isDummy: false,
		permission: AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_SHOP_LOGIN,
		icon: "fa fa-store",
		title: "SHOP_LOGIN",
		isDummy: false,
		permission: AccessRight._SHOP_LOGIN_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_MANAGE_APPEAL_TRANSACTION,
		icon: "fa fa-gavel",
		title: "APPEAL_TRANSACTION",
		isDummy: false,
		permission: AccessRight._APPEAL_TRANSACTION_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
	{
		path: WebUrl._URL_API_LOG,
		icon: "fa fa-paper-plane",
		title: "API_LOG",
		isDummy: false,
		permission: AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
	},
];

export default Menu;
