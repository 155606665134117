import React, { useEffect, useRef } from 'react';
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import { ApiKey, ApiUrl, AlertTypes } from '../../util/Constant';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch } from 'react-redux';
import { showResponseMessage } from 'redux/AppAction.js';
import { useForm } from "react-hook-form";
import * as Util from '../../util/Util';
import Countdown from 'react-countdown';
import moment from 'moment'

/// <summary>
/// Author: CK
/// </summary>
const PasswordConfirmationModal = props => {
    const { t } = useTranslation();
    const _dispatch = useDispatch();
    const { register, handleSubmit, errors, unregister, setValue } = useForm();
    const _inputTxtRef = useRef(null);
  
    /// <summary>
    /// Author: CK
    /// </summary>
    async function verifyPassword(data) {
        if (data.password == undefined) {
            data.password = "";
        }

        if (!props.skipPasswordCheck) {
            var responseJson = await ApiEngine.post(ApiUrl._API_VERIFY_PASSWORD + "?userId=" + props.memberId + "&password=" + data.password);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                props.onConfirm(data.password);
            }
            else {
                _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
            }
        }
        else {
            props.onConfirm(data.password);
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'password' }, { required: !props.skipPasswordRequiredCheck });
        if (_inputTxtRef.current) {
            _inputTxtRef.current.focus();
        }

        return (() => {
            unregister("password");
        });
    }, [])

    /// <summary>
    /// Author: CK
    /// </summary>
    const countDownRenderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            _dispatch(showResponseMessage(false, t("TRANSACTION_REQUEST_EXPIRED")));
            props.onCancel();
            return <b>({t("EXPIRED")})</b>
        }
        else {
            // Render a countdown
            return <b>({t("EXPIRE_IN")}: {minutes}:{seconds})</b>
        }
    };

    return (
        <div>
            <SweetAlert
                type={AlertTypes._INFO}
                title={t('AUTHENTICATION_REQUIRED')}
                content={t('ENTER_PASSWORD_TO_CONFIRM')}
                customButtons={
                    <div style={{ width: "100%" }}>
                        <form onSubmit={handleSubmit(verifyPassword)}>
                            <div className="form-group">
                                <input type="password" className="form-control form-control-lg" name="password" ref={_inputTxtRef} onInput={(e) => {
                                    setValue("password", e.target.value);
                                }} />
                                {errors["password"] && <div className="invalid-feedback">{t(Util.getValidationMessage(errors["password"]))}</div>}
                            </div>
                            <div>
                                <button className="btn btn-primary" type="submit">
                                    {t("BTN_SUBMIT")}
                                </button>
                                <button className="btn btn-danger" type="button" onClick={() => { props.onCancel(); }}>
                                    {t("BTN_CANCEL")}
                                </button>
                            </div>
                            {/* {
                                props.expiredAt &&
                                <div style={{ marginTop: "10px", color: "#595959" }}>
                                    <Countdown
                                        date={props.expiredAt}
                                        renderer={countDownRenderer}
                                    />
                                </div>
                            } */}
                        </form>
                    </div>
                }>
                <div>{t('ENTER_PASSWORD_TO_CONFIRM')}</div>
            </SweetAlert>
        </div>
    );
}
export default PasswordConfirmationModal;