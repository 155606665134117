import React, { useState, useEffect, useContext } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { ApiKey, ApiUrl, WebUrl, AccessRight, FilterStatus, Status, AlertTypes, Role, TransactionTypeId } from "../../../util/Constant";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useLocation, withRouter, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ApiEngine from '../../../util/ApiEngine.js';
import { showResponseMessage, showMessage, setBusy, setIdle } from 'redux/AppAction';
import ReloadDetailModal from '../../../components/custom/ReloadDetailModal';
import ScoreLogModal from '../../../components/custom/ScoreLogModal';
import CreatePlayerModal from '../../../components/custom/CreatePlayerModal';
import AccountStatusModal from '../../../components/custom/AccountStatusModal';
import { isObjectEmpty } from '../../../util/Util';
import GameLogModal from '../../../components/custom/GameLogModal';
import AppealRequestModal from '../../../components/custom/AppealRequestModal';

/// <summary>
/// Author : Yong Sheng Chuan
/// 20200904 : Wind - Added Score log modal
/// 20201216 : YJ - Added Game Log Modal
/// </summary>
const MemberPlayerControl = props => {
    const { t } = useTranslation();
    let _dispatch = useDispatch();
    var _location = useLocation();
    const [vendorPlayerList, setVendorPlayerList] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState([]);
    const [selectedShopId, setSelectedShopId] = useState([]);
    const [selectedUsername, setSelectedUsername] = useState([]);
    const [modalVisibility, setModalVisibility] = useState(false);
    const [vendorList, setVendorList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedPlayer, setSelectedPlayer] = useState('');
    const [scoreLogModalVisibility, setScoreLogModalVisibility] = useState(false);
    const _userData = useSelector(state => state.authState.userData);
    const [selectShopModalVisibility, setSelectShopModalVisibility] = useState(false);
    const [memberId, setMemberId] = useState('');
    const [selectedRemark, setSelectedRemark] = useState("");
    const [accountDetail, setAccountDetail] = useState({});
    const [gameLogModalVisibility, setGameLogModalVisibility] = useState(false);
    const [transactionDetail, setTransactionDetail] = useState({});

    /// <summary>
    /// Author : Andrew
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : Andrew
    /// </summary>
    let _tableColumns = [
        {
            Header: t("USERNAME"),
            accessor: "username"
        },
        {
            Header: t("CREATED_SHOP"),
            accessor: "shopName",
            Cell: ({ row }) => {
                return (
                    <span>{row.original.shopName} ({row.original.playerShopName})</span>
                );
            },
        },
        {
            Header: t("CREATED_DATE"),
            accessor: "createdTime"
        },
        {
            Header: t("STATUS"),
            Cell: ({ row }) => {
                let element = "";
                switch (row.original.status) {
                    case FilterStatus._DELETED:
                        element = <span className="badge badge-danger">{`${t('DELETED')} (${row.original.updatedTime})`}</span>
                        break;
                    case FilterStatus._SUSPENDED:
                        element = <span className="badge badge-warning">{t('SUSPENDED')}</span>
                        break;
                    case FilterStatus._ACTIVE:
                        element = <span className="badge badge-success">{t('ACTIVE')}</span>
                        break;
                }
                return element;
            }
        },
        {
            Header: t("LAST_UPDATED_BY"),
            Cell: ({ row }) => {
                return <div>{row.original.status == FilterStatus._DELETED ? row.original.deletedByName : row.original.updatedByName}</div>
            }
        },
        {
            Header: t("ACTION"),
            Cell: ({ row }) => {
                return <div className="btn-group">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            {row.original.status != FilterStatus._DELETED &&
                                <>
                                    <DropdownItem onClick={() => {
                                        setSelectedUsername(row.original.username);
                                        setSelectedShopId(row.original.shopId);
                                        setSelectedVendor(vendorList[row.original.vendorId].code);
                                        setSelectedStatus(row.original.status);
                                        setSelectedRemark(row.original.remark);
                                        setModalVisibility(true);
                                    }}><span>{t("VIEW_PLAYER")}</span></DropdownItem>
                                    <DropdownItem onClick={() => {
                                        setAccountDetail({
                                            username: row.original.username,
                                            shopId: row.original.shopId,
                                            vendorCode: vendorList[row.original.vendorId].code,
                                            fromStatus: row.original.status,
                                            toStatus: Status._DELETED,
                                            remark: row.original.remark
                                        });
                                    }}><span>{t("DELETE")}</span></DropdownItem>
                                </>
                            }
                            {row.original.status == FilterStatus._DELETED &&
                                <DropdownItem onClick={() => {
                                    setAccountDetail({
                                        username: row.original.username,
                                        shopId: row.original.shopId,
                                        vendorCode: vendorList[row.original.vendorId].code,
                                        fromStatus: row.original.status,
                                        toStatus: Status._ENABLED,
                                        remark: row.original.remark
                                    });
                                }}><span>{t("RECOVER")}</span></DropdownItem>
                            }
                            <DropdownItem onClick={() => {
                                setSelectedPlayer(row.original);
                                setScoreLogModalVisibility(true);
                            }}><span>{t("VIEW_SCORE_LOG")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                setSelectedPlayer(row.original);
                                setGameLogModalVisibility(true);
                            }}><span>{t("VIEW_GAME_LOG")}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>;
            }
        }
    ];

    /// <summary>
    /// Author : Andrew
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_RETRIEVE_RELOAD_DETAILS + "?memberId=" + _location.state.id + "&username=" + _location.state.username + "&includeDeletedPlayer=true");
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setVendorPlayerList(responseJson[ApiKey._API_DATA_KEY]);

            var vendors = [];
            responseJson[ApiKey._API_DATA_KEY].map((vendor, index) => {
                vendors[vendor.vendor.id] = vendor.vendor;
            });

            setVendorList(vendors);
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function recoverPlayer(code, playerUsername, shopId) {
        var responseJson = await ApiEngine.post(ApiUrl._API_SHOP_RECOVER_PLAYER + "?&playerUsername=" + playerUsername + "&shopId=" +
            shopId + "&memberId=" + _location.state.id + "&code=" + code);
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            init();
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function deletePlayer(code, playerUsername, shopId, remark = "") {
        var responseJson = await ApiEngine.post(ApiUrl._API_SHOP_DELETE_PLAYER + "?code=" + code + "&playerUsername=" + playerUsername + "&shopId=" + shopId + "&remark=" + remark);
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            init();
        }
    }

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_PLAYER")} ({_location.state.username})</h1>
            </div>
            {
                vendorPlayerList.map((vendor, key) => {
                    return (
                        <Panel>
                            <PanelBody>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label>{vendor.vendor.name} {vendor.vendor.shortName}</label>&nbsp;&nbsp;&nbsp;
                                            <button type="button" class="btn btn-primary btn-sm m-r-5 m-b-5" style={{ "height": "auto" }}
                                            onClick={() => {
                                                setMemberId(_location.state.id);
                                                setSelectedVendor(vendor.vendor);
                                                setSelectedShopId(_location.state.memberProfile.ownerId);
                                                setSelectShopModalVisibility(!selectShopModalVisibility);
                                            }}>{t('NEW')}</button>
                                    </div>
                                </div>
                                <ReactTable data={vendor.players} columns={_tableColumns} />
                            </PanelBody>
                        </Panel>
                    );
                })
            }
            <AppealRequestModal
                visible={!isObjectEmpty(transactionDetail)}
                detail={transactionDetail}
                onFinish={(isConfirm) => {
                    setTransactionDetail({});
                }} />
            <ReloadDetailModal
                vendorCode={selectedVendor}
                username={selectedUsername}
                shopId={selectedShopId}
                status={selectedStatus}
                remark={selectedRemark}
                onFinish={(refreshRequired, setScoreUrl = null, score = null, details = {}, rolloverAction = false) => {
                    if (refreshRequired) {
                        init();
                    }
                    
                    setModalVisibility(false);

                    if (rolloverAction) {
                        setTransactionDetail({
                            "memberId": _location.state.id,
                            "type": TransactionTypeId._SET_SCORE,
                            "code": details["code"],
                            "username": details["username"],
                            "amount": details["amount"],
                            "shopId": selectedShopId
                        })
                    }

                    setSelectedUsername('');
                    setSelectedVendor('');
                    setSelectedShopId('');
                }}
                visible={modalVisibility} />
            <AccountStatusModal
                visible={!isObjectEmpty(accountDetail)}
                detail={accountDetail}
                onFinish={(isConfirm, remark) => {
                    if (isConfirm) {
                        if (accountDetail.toStatus == Status._DELETED) 
                        {
                            deletePlayer(accountDetail.vendorCode, accountDetail.username, accountDetail.shopId, remark);
                        }
                        else
                        {
                            recoverPlayer(accountDetail.vendorCode, accountDetail.username, accountDetail.shopId);
                        }
                    }
                    setAccountDetail({});
                }} />
            <ScoreLogModal isModalVisible={scoreLogModalVisibility} player={selectedPlayer} onFinish={() => setScoreLogModalVisibility(false)} />
            <GameLogModal isModalVisible={gameLogModalVisibility} player={selectedPlayer} onFinish={() => setGameLogModalVisibility(false)} />
            <CreatePlayerModal
                visible={selectShopModalVisibility}
                memberId={memberId}
                vendor={selectedVendor}
                shopId={selectedShopId}
                onFinish={(refresh) => {
                    if (refresh) {
                        init();
                    }
                    setMemberId('');
                    setSelectedVendor('');
                    setSelectedShopId('');
                    setSelectShopModalVisibility(false);
                }} />
        </div>
    )
}

export default MemberPlayerControl;