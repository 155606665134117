import React, { useState, useEffect, useMemo } from 'react';
import { Panel, PanelHeader, PanelBody, PanelFooter } from 'components/panel/panel.jsx';
import { WebUrl, ApiKey, ApiUrl, AccessRight, AlertTypes } from '../../../util/Constant';
import * as Util from '../../../util/Util';
import ReactTable from '../../../components/custom/ReactTable';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage, showMessage } from "../../../redux/AppAction";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import NavigationButton from '../../../components/custom/NavigationButton';
import ApiEngine from '../../../util/ApiEngine.js';
import { checkIfPermissionExist } from "../../../util/PermissionChecker";

/// <summary>
/// Author: Wind
/// </summary>
const ManageVendorCategory = props => {
    const { t, i18n } = useTranslation();
    var _history = useHistory();
    var _dispatch = useDispatch();
    const [vendorCategoryList, setVendorCategoryList] = useState([]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    let _vendorCategoryTableColumns = useMemo(() => [
        {
            Header: "NAME",
            accessor: "localizations",
            Cell: ({ row }) => {
                return <div>{row.original.localizations.map((item) => { return item.value }).join(" / ")}</div>
            }
        },
        {
            Header: "RANKING",
            accessor: "ranking",
        },
        {
            Header: "DISPLAY_AT_DESKTOP",
            accessor: "displayAtDesktop",
            Cell: ({ row }) => {
                return row.original.displayAtDesktop ? <span className="badge badge-green">{t("DISPLAY")}</span> : <span className="badge badge-danger">{t("HIDE")}</span>;
            }
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                return row.original.status ? <span className="badge badge-green">{t("ACTIVE")}</span> : <span className="badge badge-danger">{t("DISABLE")}</span>;
            }
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._GAME_VENDOR_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                _history.push({
                                    pathname: WebUrl._URL_ADMIN_VENDOR_CATEGORY_DETAIL,
                                    state: { id: row.original.id }
                                })
                            }}>
                                <span>{t('EDIT_VENDOR_CATEGORY')}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                onEditStatus(row.original.id, { "status": !row.original.status, name: JSON.stringify(row.original.localizations) })
                            }}>
                                <span>{row.original.status ? t("DISABLE") : t("ACTIVATE")}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t("ARE_YOU_SURE"),
                                    content: t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_VENDOR_CATEGORY"),
                                    showCancel: true,
                                    onConfirm: () => {
                                        deleteVendorCategory(row.original.id)
                                    }
                                }))
                            }}>
                                <span className="text-red">{t("DELETE")}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ], [vendorCategoryList]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        getVendorCategoryList();
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function deleteVendorCategory(id) {
        var responseJson = await ApiEngine.delete(ApiUrl._API_VENDOR_CATEGORY + "/" + id);

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && getVendorCategoryList()
        }));
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function onEditStatus(id, data) {
        var responseJson = await ApiEngine.put(ApiUrl._API_VENDOR_CATEGORY + "/" + id, Util.createFormBody(data));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && getVendorCategoryList()
        }));
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function getVendorCategoryList() {
        var responseJson = await ApiEngine.get(ApiUrl._API_VENDOR_CATEGORY);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setVendorCategoryList(responseJson[ApiKey._API_DATA_KEY].data);
        }
    }

    return (
        <div id="page-content">
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_VENDOR_CATEGORY")}</h1>
                {
                    checkIfPermissionExist(AccessRight._GAME_VENDOR_CONTROL_PERMISSION, true) &&
                    <NavigationButton history={_history} url={WebUrl._URL_ADMIN_VENDOR_CATEGORY_DETAIL} buttonText={t("CREATE_VENDOR_CATEGORY")} />
                }
            </div>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <ReactTable data={vendorCategoryList} columns={_vendorCategoryTableColumns} />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div>
    );
}

export default ManageVendorCategory;