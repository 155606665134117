import React, { useState, useEffect } from 'react';
import { ApiKey, ApiUrl } from '../../util/Constant';
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactTable from "./ReactTable";
import moment from "moment";

/// <summary>
/// Author: CK
/// </summary>
const MemberRolloverDetailModal = props => {
    var _userId = useSelector(state => state['authState']['userData']['userId']);
    const { t, i18n } = useTranslation();
    const _dispatch = useDispatch();
    const [rolloverData, setRolloverData] = useState([]);

    let _tableColumns = [
        {
            Header: "USERNAME",
            accessor: "username",
            disableSortBy: true,
            width: 100
        },
        {
            Header: "REGION",
            accessor: "regionName",
            disableSortBy: true,
            width: 50
        },
        {
            Header: "LAST_DEPOSIT",
            accessor: "depositType",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>
                    <b>{t("DEPOSIT_TYPE")}: </b>{row.original.depositType}<br/>
                    <b>{t("OPERATED_TIME")}: </b>{row.original.parsedLastDepositTime}<br/>
                    <b>{t("AMOUNT")}: </b>{row.original.lastDepositAmount}<br/>
                    <b>{t("OPERATED_BY")}: </b>{row.original.lastDepositOperatedBy}
                </div>
            }
        },
        {
            Header: "TURNOVER",
            accessor: "turnover",
            disableSortBy: true,
            width: 100
        },
        {
            Header: "LAST_WITHDRAWAL",
            accessor: "withdrawalType",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>
                    <b>{t("WITHDRAWAL_TYPE")}: </b>{row.original.withdrawalType}<br/>
                    <b>{t("OPERATED_TIME")}: </b>{row.original.parsedLastWithdrawalTime}<br/>
                    <b>{t("AMOUNT")}: </b>{row.original.lastWithdrawalAmount}<br/>
                    <b>{t("OPERATED_BY")}: </b>{row.original.lastWithdrawalOperatedBy}
                </div>
            }
        }
    ];

    /// <summary>
    /// Author: CK
    /// </summary> 
    useEffect(() => {
        (async () => {
            if (props.isVisible) {
                getMemberRolloverDetail();
            }
        })();
    }, [props.isVisible]);

    /// <summary>
    /// Author: CK
    /// </summary> 
    async function getMemberRolloverDetail() {
        var apiUrl = ApiUrl._API_GET_MEMBER_ROLLOVER_DETAIL + "?username=" + props.username + "&startDate=" + moment().subtract(60, "days").format("YYYY-MM-DD HH:mm:ss") + "&endDate=" + moment().format("YYYY-MM-DD HH:mm:ss");
        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setRolloverData([responseJson[ApiKey._API_DATA_KEY]]);
        }
    }

    return (
        <Modal isOpen={props.isVisible} toggle={() => { props.onFinish() }} size="xl">
            <ModalHeader toggle={() => { props.onFinish() }}>
                <h4 >
                    {t("ROLLOVER_DETAIL") + ' (' + props.username + ')'}
                </h4>
            </ModalHeader>
            <ModalBody>
                <ReactTable data={rolloverData} columns={_tableColumns} />
            </ModalBody>
        </Modal>
    );
}

export default MemberRolloverDetailModal;