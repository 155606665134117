import React, { useMemo, useEffect, useState } from 'react';
import { Panel, PanelHeader, PanelBody } from 'components/panel/panel.jsx';
import { useHistory, useLocation } from 'react-router-dom';
import NavigationButton from '../../../components/custom/NavigationButton';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import ReactTable from 'components/custom/ReactTable';
import { FilterStatus, WebUrl, ApiKey, ApiUrl, Role, AccessRight, AlertTypes, Status } from 'util/Constant';
import { navigateTo, createMultiPartFormBody, numberWithCurrencyFormat } from "../../../util/Util";
import { useTranslation } from 'react-i18next';
import ResetPasswordModal from '../../../components/custom/ResetPasswordModal';
import ApiEngine from '../../../util/ApiEngine.js';
import { showMessage, showResponseMessage } from '../../../redux/AppAction';
import { useDispatch, useSelector } from "react-redux";
import SetAgentCreditModal from 'components/custom/SetAgentCreditModal';
import CopyAccountDetailModal from 'components/custom/CopyAccountDetailModal';

/// <summary>
/// Author: CK
/// </summary> 
const MassAssignSettingsComponent = ({ selectedAgents, massAssignHandler }) => {
    const { t } = useTranslation();
    const { register, handleSubmit } = useForm();
    var _dispatch = useDispatch();

    const [checkAgentSettings, setCheckAgentSettings] = useState({
        canCreatePlayer: false,
        canReload: false
    });

    /// <summary>
    /// Author: CK
    /// </summary>
    const resetValues = () => {
        setCheckAgentSettings({
            canCreatePlayer: false,
            canReload: false
        });
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const onSubmit = (agentSettingsData) => {
        let selectedAgentIds = [];

        Object.keys(selectedAgents).map((key) => {
            if (selectedAgents[key]) {
                selectedAgentIds.push(key);
            }
        });

        if (selectedAgentIds.length > 0) {
            _dispatch(showMessage({
                type: AlertTypes._WARNING,
                content: t('MASS_ASSIGN_SETTINGS_WARNING'),
                showCancel: true,
                onConfirm: async () => {
                    massAssignHandler(selectedAgentIds, agentSettingsData);
                    resetValues();
                }
            }));
        }
        else {
            _dispatch(showMessage({ type: AlertTypes._WARNING, content: t('PLEASE_SELECT_A_AGENT') }));
        }
    }

    return (
        <Panel>
            <PanelHeader className='bg-white pointer-cursor' hideExpandButton hideRedoButton hideRemoveButton>
                {t("MASS_ASSIGN_AGENT_SETTINGS")}
            </PanelHeader>
            <PanelBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="checkbox checkbox-css checkbox-inline">
                        <input id="playerCheckbox" type="checkbox" name="createPlayerEnable" checked={checkAgentSettings.canCreatePlayer}
                            onChange={(e) => setCheckAgentSettings({ ...checkAgentSettings, canCreatePlayer: e.target.checked })}
                            ref={register} />
                        <label htmlFor="playerCheckbox">{t("CAN_CREATE_PLAYER")}</label>
                    </div>
                    <div className="checkbox checkbox-css checkbox-inline">
                        <input id="reloadCheckbox" type="checkbox" name="reloadEnable" checked={checkAgentSettings.canReload}
                            onChange={(e) => setCheckAgentSettings({ ...checkAgentSettings, canReload: e.target.checked })}
                            ref={register} />
                        <label htmlFor="reloadCheckbox">{t("CAN_RELOAD")}</label>
                    </div>
                    <div className="row m-t-20">
                        <div className="col-lg-3">
                            <button type="submit" className="btn btn-primary">{t("MASS_ASSIGN_SETTINGS")}</button>
                        </div>
                    </div>
                </form>
            </PanelBody>
        </Panel>
    );
}

/// <summary>
/// Author: CK
/// </summary>
const ManageAgent = props => {
    const _dispatch = useDispatch();
    var _history = useHistory();
    var _location = useLocation();
    const { t } = useTranslation();
    const { register, handleSubmit, watch, setValue } = useForm();
    const [agents, setAgents] = useState([]);
    const [isResetPasswordModalVisible, setIsResetPasswordModalVisible] = useState(false);
    const [regionOptions, setRegionOptions] = useState([]);
    const [userId, setUserId] = useState('');
    const [isSetAgentCreditModalVisible, setIsSetAgentCreditModalVisible] = useState(false);
    const [isSetAgentCreditLimitModalVisible, setIsSetAgentCreditLimitModalVisible] = useState(false);

    const [copyAgentModalVisible, setCopyAgentModalVisible] = useState(false);
    const [selectedCopyAgent, setSelectedCopyAgent] = useState("");

    const [agentDetails, setAgentDetails] = useState({
        userFullName: '',
        currentCreditLimit: 0
    });
    const [checkAll, setCheckAll] = useState(false);
    const [selectedAgents, setSelectedAgents] = useState({});
    const [agentApiUrl, setAgentApiUrl] = useState('');
    var [count, setCount] = useState(0);
    const _userData = useSelector(state => state.authState.userData);

    const _tableColumns = useMemo(() => [
        {
            id: 'checkbox',
            Header: ({ data }) => {
                return (
                    <div className="checkbox checkbox-css checkbox-inline m-b-20">
                        <input id="checkboxSelectAll" type="checkbox" onChange={() => {
                            setAgents(data);
                            setCheckAll(!checkAll);
                        }} checked={checkAll} />
                        <label htmlFor="checkboxSelectAll"></label>
                    </div>
                )
            },
            Cell: ({ row }) => {
                return (
                    <div className="checkbox checkbox-css checkbox-inline">
                        <input
                            id={`checkbox${row.id}`}
                            type="checkbox"
                            onChange={() => setSelectedAgents({ ...selectedAgents, [row.original.id]: !selectedAgents[row.original.id] })}
                            checked={selectedAgents[row.original.id]} />
                        <label htmlFor={`checkbox${row.id}`}></label>
                    </div>
                )
            },
            width: 30,
            disableSortBy: true,
        },
        {
            Header: t('NAME'),
            accessor: 'userFullName',
            disableSortBy: true
        },
        {
            Header: t('USERNAME'),
            accessor: 'username',
            disableSortBy: true
        },
        {
            Header: t('REGION'),
            accessor: 'regionName',
            disableSortBy: true
        },
        {
            Header: t('UPLINE'),
            Cell: ({ row }) => {
                return (
                    <span>{row.original.agentProfile?.uplineName}</span>
                )
            },
            disableSortBy: true
        },
        {
            Header: t('CITY'),
            accessor: 'city',
            Cell: ({ row }) => {
                return (
                    <span>{row.original.agentProfile?.city}</span>
                )
            },
            disableSortBy: true
        },
        {
            Header: t('CLOSING_DAY'),
            accessor: 'closingDay',
            Cell: ({ row }) => {
                return (
                    <span>{row.original.agentProfile?.closingDay}</span>
                )
            },
            disableSortBy: true
        },
        {
            Header: t('CREDIT'),
            accessor: 'credit',
            Cell: ({ row }) => {
                return (
                    <span className="d-flex">
                        <div className="clickable-link" onClick={() => {
                            setAgentDetails({
                                id: row.original.id,
                                userFullName: row.original.userFullName,
                                credit: row.original.agentProfile.credit
                            });
                            setIsSetAgentCreditModalVisible(true)
                        }}>
                            {numberWithCurrencyFormat(row.original.agentProfile?.credit)}
                        </div>
                        <span className="mx-2 clickable-link">/</span>
                        <div className="clickable-link" onClick={() => {
                            setAgentDetails({
                                id: row.original.id,
                                userFullName: row.original.userFullName,
                                credit: row.original.agentProfile.creditLimit
                            });
                            setIsSetAgentCreditLimitModalVisible(true)
                        }}>
                            {numberWithCurrencyFormat(row.original.agentProfile?.creditLimit)}
                        </div>
                    </span >
                )
            },
            disableSortBy: true
        },
        {
            Header: t('STATUS'),
            accessor: 'status',
            Cell: ({ row }) => {
                return (
                    <span>
                        {
                            row.original.status == FilterStatus._ACTIVE ?
                                <span className="badge badge-secondary badge-green">{t("ACTIVE")}</span> :
                                <span className="badge badge-secondary badge-danger">{t("SUSPENDED") + " (" + row.original.invalidLoginCount + ")"}</span>
                        }
                    </span>
                )
            },
            disableSortBy: true
        },
        {
            Header: t('SETTINGS'),
            Cell: ({ row }) => {
                return <>
                    {row.original.agentProfile.createPlayerEnable && <>- {t('ADD_PLAYER')}<br /></>}
                    {row.original.agentProfile.reloadEnable && <>- {t('RELOAD')}<br /></>}
                </>
            },
            disableFilters: true,
            disableSortBy: true,
        },
        {

            id: "action",
            Header: "",
            Cell: ({ row }) => {
                var suspended = row.original.status === FilterStatus._SUSPENDED;

                return (
                    checkIfPermissionExist(AccessRight._AGENT_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                            <DropdownMenu>
                                {_userData.isAdminLevel &&
                                    <DropdownItem onClick={() => {
                                        navigateTo(_history, WebUrl._URL_ADMIN_AGENT_VENDOR_API_DETAILS, row.original)
                                    }}><span>{t("VENDOR_API_DETAILS")}</span></DropdownItem>
                                }
                                <DropdownItem onClick={() => {
                                    setAgentDetails({
                                        id: row.original.id,
                                        userFullName: row.original.userFullName,
                                        currentCreditLimit: row.original.agentProfile.credit,
                                        isRequiredSetLimitApproval: row.original.agentProfile.isRequiredSetLimitApproval
                                    });
                                    setIsSetAgentCreditModalVisible(true)
                                }}><span>{t("SET_CREDIT")}</span></DropdownItem>
                                <DropdownItem onClick={() => {
                                    setUserId(row.original.id);
                                    setIsResetPasswordModalVisible(true);
                                }}><span>{t("RESET_PASSWORD")}</span></DropdownItem>
                                <DropdownItem onClick={() => {
                                    _dispatch(showMessage({
                                        type: AlertTypes._WARNING,
                                        content: `${t(!suspended ? "BLOCK_AGENT_ALERT" : "UNBLOCK_AGENT_ALERT")} ${row.original.userFullName}?`,
                                        showCancel: true,
                                        confirmBtnText: t(!suspended ? "BLOCK_AGENT" : "UNBLOCK_AGENT"),
                                        onConfirm: () => updateAgentHandler(
                                            row.original.id,
                                            (suspended) ? FilterStatus._ACTIVE : FilterStatus._SUSPENDED
                                        )
                                    }));
                                }}>
                                    <span>
                                        {t(!suspended ? "BLOCK_AGENT" : "UNBLOCK_AGENT")}
                                    </span>
                                </DropdownItem>
                                <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_ADMIN_CREATE_OR_UPDATE_AGENT, row.original) }}><span>{t("EDIT")}</span></DropdownItem>
                                <DropdownItem onClick={() => { setCopyAgentModalVisible(true); setSelectedCopyAgent(row.original.id); }}><span>{t("COPY")}</span></DropdownItem>
                                <DropdownItem onClick={() => {
                                    const win = window.open(WebUrl._URL_VIEW_USER_ACTION_LOG + "?username=" + row.original.username
                                        , "_blank");
                                    win.focus();
                                }}>
                                    <span>{t('VIEW_USER_ACTION_LOG')}</span>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => {
                                    _dispatch(showMessage({
                                        type: AlertTypes._WARNING,
                                        title: t("ARE_YOU_SURE"),
                                        content: t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_AGENT"),
                                        showCancel: true,
                                        onConfirm: () => updateAgentHandler(row.original.id, Status._DELETED)
                                    }));
                                }}><span className="text-red">{t("DELETE_AGENT")}</span></DropdownItem>
                                <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_PLAYER, { agentId: row.original.id, userFullName: row.original.userFullName }) }}><span>{t("VIEW_PLAYER")}</span></DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div >
                )
            },
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible", width: '1%' }
        }
    ], [checkAll, selectedAgents, count]);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : CK
    /// </summary>
    function saveSession() {
        var data = watch();
        sessionStorage.setItem("agentFullname", data.name);
        sessionStorage.setItem("agentUsername", data.username);
        sessionStorage.setItem("agentRegionId", data.regionId);
        sessionStorage.setItem("agentStatus", data.status);
        sessionStorage.setItem("agentCity", data.city);
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_REGION)

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var regionList = [];
            regionList.push({ label: t("ALL"), value: "" });
            responseJson[ApiKey._API_DATA_KEY].forEach(function (region) {
                regionList.push({ label: region.name, value: region.id });
            });

            setRegionOptions(regionList);
        }

        setValue("regionId", sessionStorage.getItem("agentRegionId"));
        setValue("status", sessionStorage.getItem("agentStatus"));
        handleSubmit(onSubmit)();
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const onSubmit = async (data) => {
        var rawUrl = ApiUrl._API_GET_USERS + "?RoleId=" + Role._AGENT + "&v=" + (++count);
        setCount(count);

        if (data) {
            rawUrl += "&FullName=" + data.name + "&RegionId=" + data.regionId + "&Username=" + data.username + "&Status=" + data.status + "&City=" + data.city;
        }

        saveSession();
        setAgentApiUrl(rawUrl);
        setCheckAll(false);
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const updateAgentHandler = async (userId, status) => {
        let params = {
            id: userId,
            status: status
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_USER_ACCOUNT, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleSubmit(onSubmit)();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        let checkedList = {};
        agents.map((agent) => {
            checkedList[agent.id] = checkAll;
        });

        setSelectedAgents(checkedList);
    }, [checkAll]);

    /// <summary>
    /// Author: CK
    /// </summary>
    const massAssignHandler = async (selectedAgentIds, agentSettingsData) => {
        let params = {
            ids: selectedAgentIds,
            ...agentSettingsData
        };

        var responseJson = await ApiEngine.post(`${ApiUrl._API_BULK_ASSIGN_SHOP_SETTINGS}?roleId=${Role._AGENT}`, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleSubmit(onSubmit)();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    return <>
        <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
            <h1 className="page-header">{t("MANAGE_AGENT")}</h1>
            {
                checkIfPermissionExist(AccessRight._AGENT_CONTROL_PERMISSION, true) &&
                <NavigationButton history={_history} url={WebUrl._URL_ADMIN_CREATE_OR_UPDATE_AGENT}
                    buttonText={t("ADD_NEW_AGENT")} />
            }
        </div>
        <Panel>
            <PanelBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("NAME")}</label>
                                <input className="form-control form-control-lg" id="name" name="name" type="text" defaultValue={sessionStorage.getItem("agentFullname")}
                                    placeholder={t("FULL_OR_PART_OF_THE_NAME")} ref={register} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t('USERNAME')}</label>
                                <input className="form-control form-control-lg" id="username" name="username" type="text" defaultValue={_location.state ? _location.state.shopName : sessionStorage.getItem("agentUsername")}
                                    placeholder={t("FULL_OR_PART_OF_THE_USERNAME")} ref={register} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("REGION")}</label>
                                <select name="regionId" ref={register}
                                    className="form-control form-control-lg">
                                    {regionOptions.map((region, key) => <option key={key} value={region.value}>{region.label}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t('STATUS')}</label>
                                <select className="form-control form-control-lg" id="status" name="status" ref={register}>
                                    <option value="">{t("ALL")}</option>
                                    <option value={FilterStatus._ACTIVE}>{t("ACTIVE")}</option>
                                    <option value={FilterStatus._SUSPENDED}>{t("SUSPENDED")}</option>
                                    <option value={FilterStatus._DELETED}>{t("DELETED")}</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("CITY")}</label>
                                <input className="form-control form-control-lg" id="city" name="city" type="text" defaultValue={sessionStorage.getItem("agentCity")} ref={register} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="form-group form-button">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBody>
        </Panel>
        <MassAssignSettingsComponent selectedAgents={selectedAgents} massAssignHandler={massAssignHandler} />
        <Panel>
            <PanelBody>
                <ReactTable columns={_tableColumns} fetchUrl={agentApiUrl} />
            </PanelBody>
        </Panel>
        <ResetPasswordModal
            onFinish={() => {
                setIsResetPasswordModalVisible(false);
            }}
            resetAgentPassword={true}
            targetUserId={userId}
            visible={isResetPasswordModalVisible} />
        <SetAgentCreditModal
            onFinish={(isSuccess) => {
                if (isSuccess) {
                    handleSubmit(onSubmit)();
                }

                if (isSetAgentCreditModalVisible) {
                    setIsSetAgentCreditModalVisible(false);
                }

                if (isSetAgentCreditLimitModalVisible) {
                    setIsSetAgentCreditLimitModalVisible(false);
                }
            }}
            agentDetails={agentDetails}
            visible={isSetAgentCreditModalVisible || isSetAgentCreditLimitModalVisible}
            isCreditLimit={isSetAgentCreditLimitModalVisible}
        />
        <CopyAccountDetailModal
            refId={selectedCopyAgent}
            refRole={Role._AGENT}
            onFinish={(refreshRequired) => {
                if (refreshRequired) {
                    init();
                }
                setCopyAgentModalVisible(false);

            }}
            visible={copyAgentModalVisible} />
    </>;
}

export default ManageAgent;