import React, { useState, useEffect } from 'react';
import { getValidationMessage } from '../../../util/Util';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import moment from 'moment';
import DateTime from 'react-datetime';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { useLocation } from "react-router-dom";
import ApiEngine from "../../../util/ApiEngine";
import {
    WebUrl,
    ApiKey,
    ApiUrl,
    PrizeType,
    AlertTypes
} from "../../../util/Constant";
import { showResponseMessage, showMessage } from "../../../redux/AppAction";

/// <summary>
/// Author: CK
/// </summary>
const LuckySpinWinner = props => {
    const { t, i18n } = useTranslation();
    const _dispatch = useDispatch();
    let { search } = useLocation();
    const _query = new URLSearchParams(search);
    const _PROMOTION_ID = _query.get('promotionId');
    const [promotionWinningData, setPromotionWinningData] = useState([]);
    const [promotionTitle, setPromotionTitle] = useState("");

    let _winnerTableColumns = [
        {
            Header: "DATE",
            accessor: "createdTime",
            disableSortBy: true
        },
        {
            Header: "USER",
            accessor: "user",
            disableSortBy: true
        },
        {
            Header: "PRIZE_NAME",
            accessor: "prizeName",
            disableSortBy: true
        },
        {
            Header: "AMOUNT",
            accessor: "prizeAmount",
            Cell: ({ row }) => {
                return <span className="text-success">{row.original.prizeAmount}</span>;
            },
            disableSortBy: true
        },
        {
            Header: "CLAIM",
            Cell: ({ row }) => {
                if (row.original.prizeType == PrizeType._PRIZE_TYPE_PRIZE) {
                    if (row.original.prizeClaimedAt == null && row.original.prizeClaimedBy == null) {
                        return <button type="button" class="btn btn-primary" onClick={() => {
                            _dispatch(showMessage({
                                content: t("ARE_YOU_SURE_YOU_WANT_TO_CLAIM", { user: row.original.user, prize: row.original.prizeName }),
                                showCancel: true,
                                confirmBtnText: t("CONFIRM"),
                                type: AlertTypes._WARNING,
                                onConfirm: () => {
                                    claimPrize(row.original.id);
                                }
                            }));
                        }}>{t("CLAIM")}</button>
                    }
                    else {
                        return <span>{moment(row.original.prizeClaimedAt).format('YYYY-MM-DD HH:mm:ss')}</span>
                    }
                }
                else {
                    return <span >-</span>;
                }
            },
            disableSortBy: true
        }
    ]

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        getWinningData();
    }, []);

    /// <summary>
    /// Author : CK
    /// </summary>
    async function getWinningData() {
        var responseJson = await ApiEngine.get(ApiUrl._API_PROMOTION_WINNING_DETAIL + "?promotionId=" + _PROMOTION_ID);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setPromotionWinningData(responseJson[ApiKey._API_DATA_KEY]);
            setPromotionTitle(responseJson[ApiKey._API_MESSAGE_KEY]);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author : Saitama
    /// </summary>
    async function claimPrize(spinId) {
        var responseJson = await ApiEngine.post(ApiUrl._API_CLAIM_LUCKY_SPIN_PRIZE + "?id=" + spinId);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(showMessage({
                content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
                type: AlertTypes._SUCCESS,
                onConfirm: () => {
                    getWinningData();
                }
            }));
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("LUCKY_SPIN")} - {promotionTitle}</h1>
            </div>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <ReactTable columns={_winnerTableColumns} data={promotionWinningData} exportRequired={true} />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div>
    );
}

export default LuckySpinWinner;