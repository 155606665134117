import React, { useRef, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { setSearchMemberModalVisible } from "../../redux/MemberAction";
import { useForm } from "react-hook-form";
import { showMessage } from '../../redux/AppAction';
import * as Util from '../../util/Util';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { ApiKey, ApiUrl, AlertTypes } from '../../util/Constant';
import ApiEngine from '../../util/ApiEngine';
import SweetAlert from 'react-bootstrap-sweetalert';
import PasswordConfirmationModal from './PasswordConfirmationModal';
import { getShopCredit } from 'redux/AuthAction';

/// <summary>
/// Author: CK
/// </summary>
const SearchMemberModal = props => {
    let _history = useHistory();
    let _dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, errors, unregister, setValue, watch } = useForm();
    const _searchTxtRef = useRef(null);
    const [readyState, setReadyState] = useState(false);
    const _inputTxtRef = useRef(null);
    const _userData = useSelector(state => state.authState.userData);
    const [showAgentReloadModal, setShowAgentReloadModal] = useState({});
    const [adjustScoreAmount, setAdjustScoreAmount] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState(false);
    const [getPlayerOnly, setGetPlayerOnly] = useState(false);

    /// <summary>
    /// Author: CK
    /// </summary>
    function closeModal() {
        setShowAgentReloadModal({});
        setPasswordConfirmation(false);
        setAdjustScoreAmount("");
        _dispatch(setSearchMemberModalVisible(false));
    }

    /// <summary>
    /// Author: CK
    /// Editted: YJ (Support Non Member)
    /// Editted: Wind (Support Agent)
    /// </summary>
    async function searchMemberHandler(data) {
        try {
            var responseJson;

            if (props.isSearchAgent) {
                responseJson = await ApiEngine.get(`${ApiUrl._API_GET_AGENT_LIST}?Username=${data['searchMemberInput']}`);
            }
            else {
                responseJson = await ApiEngine.get(ApiUrl._API_SEARCH_MEMBER + "?searchInput=" + data['searchMemberInput'] + "&getPlayerOnly=" + getPlayerOnly);
            }
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                if (!props.isSearchAgent) {
                    closeModal();
                    let redirectUrl = (responseJson[ApiKey._API_DATA_KEY]["isMember"]) ? props.targetUrl["mainUrl"] : props.targetUrl["alternateUrl"];

                    if (!Util.stringIsNullOrEmpty(redirectUrl)) {
                        _history.push({
                            pathname: redirectUrl,
                            state: responseJson[ApiKey._API_DATA_KEY]["data"]
                        });
                    }
                }
                else {
                    if (responseJson[ApiKey._API_DATA_KEY].length == 1) {
                        setShowAgentReloadModal(responseJson[ApiKey._API_DATA_KEY][0]);
                    }
                    else {
                        throw 'NO_DATA_FOUND';
                    }
                }
            }
            else {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
        }
        catch (err) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(err)
            }));
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (props.isModalVisible) {
            register({ name: 'searchMemberInput' }, { required: true });
            setReadyState(true);
        }
        else {
            unregister('searchMemberInput');
        }
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (_searchTxtRef.current) {
            _searchTxtRef.current.focus();
        }
    }, [readyState]);

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        if (!Util.isObjectEmpty(showAgentReloadModal) && _inputTxtRef.current) {
            _inputTxtRef.current.focus();
        }
    }, [showAgentReloadModal]);

    /// <summary>
    /// Author : Wind
    /// </summary>
    async function reloadAgentCredit() {
        try {
            let responseJson = await ApiEngine.post(`${ApiUrl._API_UPDATE_AGENT_CREDIT}?agentId=${showAgentReloadModal.id}&amount=${adjustScoreAmount}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _dispatch(showMessage({
                type: AlertTypes._SUCCESS,
                title: t("SUCCESS"),
                content: <div>{t('SET_CREDIT') + ': '}<b className={adjustScoreAmount < 0 ? "text-red" : "text-green"}>{Number(adjustScoreAmount).toFixed(1)}</b></div>,
                html: true,
                onConfirm: () => {
                    _dispatch(getShopCredit());
                    closeModal();
                },
                showCancel: true,
                confirmBtnText: t("PRINT"),
                confirmBtnBsStyle: "success",
                cancelBtnText: "OK",
                cancelBtnBsStyle: "primary",
                reverseButtons: false,
                onConfirm: () => {
                    _dispatch(getShopCredit());
                    closeModal();
                    Util.printReceipt(`Agent: ${showAgentReloadModal.username}|Set Credit: ${Number(adjustScoreAmount).toFixed(1)}`)
                }
            }));
        }
        catch (err) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(err)
            }));
        }
    }

    return (
        <>
            <Modal isOpen={props.isModalVisible} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>{t('RELOAD')}</ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmit(searchMemberHandler)}>
                        <div className="form-group m-b-15 ">
                            <label>{`${t('SEARCH_BY')} ${props.isSearchAgent ? t('AGENT_ID') : t('MEMBER_PHONE_NUMBER')}`}</label>
                            <input className="input-search form-control form-control-lg m-b-5"
                                name="searchMemberInput"
                                placeholder={props.isSearchAgent ? t('ENTER_AGENT_ID') : t('ENTER_MEMBER_PHONE_NUMBER')}
                                type="text"
                                onInput={(e) => setValue("searchMemberInput", e.target.value)}
                                ref={_searchTxtRef} />
                            {errors["searchMemberInput"] && <div className="invalid-feedback">{t(Util.getValidationMessage(errors["searchMemberInput"]))}</div>}
                        </div>
                        <div className="pull-right">
                            {
                                !props.isSearchAgent &&
                                <>
                                    <div className="checkbox checkbox-css checkbox-inline">
                                        <input id="getPlayerOnly" type="checkbox" name="getPlayerOnly" checked={getPlayerOnly}
                                            onChange={(e) => setGetPlayerOnly(e.target.checked)} />
                                        <label htmlFor="getPlayerOnly">{t("PLAYER_ONLY")}</label>
                                    </div>
                                    &nbsp;&nbsp;
                                </>
                            }
                            <button type="submit" id="btn_search" className="btn btn-primary btn-action">{t('SEARCH')}</button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
            {
                !Util.isObjectEmpty(showAgentReloadModal) &&
                <SweetAlert
                    type={AlertTypes._INFO}
                    title={`${t('INFO')}: ${showAgentReloadModal.username}`}
                    customButtons={<div style={{ width: "100%" }}>
                        <div className="form-group">
                            <input type="number" className="form-control form-control-lg"
                                step=".01"
                                onKeyDown={e => Util.ignoreDecimal(e, _userData?.username?.includes("demo") || _userData?.isAdminLevel)}
                                ref={_inputTxtRef} value={adjustScoreAmount} onChange={(e) => { setAdjustScoreAmount(e.target.value) }} />
                        </div>
                        <div style={{ float: "right" }}>
                            <button className="btn btn-primary" type="button" onClick={() => {
                                if (Util.stringIsNullOrEmpty(adjustScoreAmount)) {
                                    _dispatch(showMessage({
                                        type: AlertTypes._ERROR,
                                        content: t('INVALID_RELOAD_AMOUNT')
                                    }));
                                }
                                else {
                                    _dispatch(showMessage({
                                        type: AlertTypes._WARNING,
                                        title: t('ARE_YOU_SURE'),
                                        showCancel: true,
                                        content: t('PLEASE_CONFIRM_SET_CREDIT'),
                                        onConfirm: () => {
                                            if (adjustScoreAmount > 0) {
                                                reloadAgentCredit();
                                            }
                                            else {
                                                setPasswordConfirmation(true);
                                            }
                                        }
                                    }));
                                }
                            }}>
                                {t('RELOAD')}
                            </button>
                            <button className="btn btn-default" type="button" onClick={() => { setShowAgentReloadModal({}); }}>
                                {t("CLOSE")}
                            </button>
                        </div>
                    </div>}>
                    <div>{t('CREDIT')}: {showAgentReloadModal.credit}</div>
                </SweetAlert>
            }
            {passwordConfirmation &&
                <PasswordConfirmationModal
                    memberId={showAgentReloadModal.id}
                    skipPasswordCheck={false}
                    onConfirm={() => {
                        reloadAgentCredit();
                    }}
                    onCancel={() => { setPasswordConfirmation(false); }} />
            }
        </>
    );
};

export default SearchMemberModal;