import React, { useEffect, useState, useRef, useMemo } from 'react';
import DualListBox from 'react-dual-listbox';
import DualListBoxItemRow from './DualListBoxItemRow';

/// <summary>
/// Author : YJ
/// </summary>
const RegionFilterDualListBox = props => {
    const [selectedRegions, setSelectedRegions] = useState([]);

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        if (selectedRegions.length > 0) {
            var currentOptions = [].concat.apply([], props.options.filter(x => selectedRegions.some(region => region == x.value)).map(option => option.options));

            props.onChange(props.selectedOptions.filter(option => currentOptions.some(currentOption => currentOption.value == option)));
        }
    }, [selectedRegions]);

    return (
        <>
            <div className="row">
                {
                    (props.regions ?? []).map((region, index) => {
                        return (
                            <div className="col-lg-2" key={index + props.name}>
                                <div className="checkbox checkbox-css checkbox-inline">
                                    <input id={region.label + region.value + props.name} type="checkbox" name={region.label + region.value + props.name}
                                        checked={selectedRegions.filter(selectedRegion => selectedRegion == region.value).length}
                                        value={region.value}
                                        onChange={(e) => {
                                            if (selectedRegions.filter(selectedRegion => selectedRegion == e.target.value).length) {
                                                setSelectedRegions(selectedRegions.filter(selectedRegion => selectedRegion != e.target.value));
                                            }
                                            else {
                                                setSelectedRegions([...selectedRegions, e.target.value]);
                                            }
                                        }} />
                                    <label htmlFor={region.label + region.value + props.name}>{region.label}</label>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            <DualListBoxItemRow options={selectedRegions.length == 0 ? props.options : props.options.filter(x => selectedRegions.some(region => region == x.value))} selected={props.selectedOptions} />
            <DualListBox
                canFilter
                name={props.name}
                ref={props.ref}
                options={selectedRegions.length == 0 ? props.options : props.options.filter(x => selectedRegions.some(region => region == x.value))}
                selected={props.selectedOptions}
                onChange={(e) => {
                    props.onChange(e);
                }}
            />
        </>
    )
}

export default RegionFilterDualListBox;