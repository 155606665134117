import React from "react";
import { Route } from "react-router-dom";
import { PageSettings } from "./../../config/page-settings.js";
import SidebarNavList from "./sidebar-nav-list.jsx";
import { connect } from "react-redux";
import Menus from "./menu.jsx";
import ShopMenus from "./shop-menu.jsx";
import { LoginType, Theme } from "../../util/Constant";
import { AccessRight, Status, WebUrl } from "../../util/Constant";

class SidebarNav extends React.Component {
  static contextType = PageSettings;
  constructor(props) {
    super(props);
    this.state = {
      active: -1,
      clicked: -1
    };
  }

  playSound = async () => {
    const audio = new Audio();
    audio.src = require("./notification.mp3");

    try {
      await audio.load();
      await audio.play();
    } catch (error) {
      console.error("Error playing sound:", error);
    }
  };

  filterMenuByPermission = () => {
    var menuToUse =
      this.props.authState.userData.loginType == LoginType._LOGIN_TYPE_SHOP
        ? ShopMenus
        : JSON.parse(JSON.stringify(Menus));

    if (this.props.authState.userData.accessString !== undefined) {
      if (
        this.props.authState.userData.loginType == LoginType._LOGIN_TYPE_SHOP
      ) {
        menuToUse = ShopMenus.filter((item) => {
          if (
            item.isAgentOnly == undefined &&
            item.isCustomerServiceOnly == undefined &&
            item.isRequiredAgentReload == undefined
          ) {
            return true;
          } else {
            return (
              item.isAgentOnly == this.props.authState.userData.isShopAgent ||
              item.isCustomerServiceOnly ==
                this.props.authState.userData.isCustomerService ||
              item.isRequiredAgentReload ==
                this.props.authState.userData.agentReload ||
              item.onlineWithdrawalShop ==
                this.props.authState.userData.onlineWithdrawalShop
            );
          }
        });
      } else {
        var userPermissionData =
          this.props.authState.userData.accessString.split(";");
        var permissionArrKey = [];
        menuToUse = JSON.parse(JSON.stringify(Menus));

        for (var i = 0; i < userPermissionData.length; i++) {
          var keyValuePermission = userPermissionData[i].split(":");
          if (keyValuePermission.length == 2) {
            if (
              keyValuePermission[1][AccessRight._READ_BIT_POSITION] ==
              Status._ENABLED.toString()
            ) {
              permissionArrKey.push(
                keyValuePermission[0] + AccessRight._READ_PERMISSION_STRING
              );
            }
          }
        }
        //first filter away non use children
        for (var i = 0; i < menuToUse.length; i++) {
          if (menuToUse[i].children) {
            menuToUse[i].children = menuToUse[i].children.filter((item) => {
              let permission = item["permission"];
              let feedbackAdminFilter =
                this.props.authState.userData?.isFeedbackAdminOnly ==
                  Status._ENABLED && item["hideFromFeedbackAdmin"];

              if (feedbackAdminFilter) {
                return false;
              }
              if (permission !== undefined) {
                return (
                  permissionArrKey.filter((item) => item == permission)
                    .length != 0
                );
              } else {
                return true;
              }
            });
          }
        }

        //secondly filter away parent
        menuToUse = menuToUse.filter((item) => {
          if (!item.children || item.children.length == 0) {
            if (item.isDummy) {
              return false;
            } else {
              let permission = item.permission;
              if (permission !== undefined) {
                return (
                  permissionArrKey.filter((item) => item == permission)
                    .length != 0
                );
              } else {
                return true;
              }
            }
          } else {
            return true;
          }
        });
      }
    }

    // Set online withdrawal notification
    if (this.props.authState.pendingOnlineWithdrawalCount > 0) {
      menuToUse = menuToUse.map((i) =>
        i.path == WebUrl._URL_ONLINE_WITHDRAWAL_REQUEST
          ? { ...i, hasNotification: true }
          : i
      );

      if (this.props.authState.playSound) {
        this.playSound();
      }
    }

    return menuToUse;
  };

  handleExpand(e, i, match) {
    e.preventDefault();

    if (this.state.clicked === -1 && match) {
      this.setState((state) => ({
        active: -1,
        clicked: 1,
      }));
    } else {
      this.setState((state) => ({
        active: this.state.active === i ? -1 : i,
        clicked: 1,
      }));
    }
  }

  render() {
    var menuList = this.filterMenuByPermission();

    return (
      <ul className="nav">
        {menuList.map((menu, i) => (
          <Route
            path={menu.path}
            exact={menu.exact}
            key={i}
            children={({ match }) => (
              <SidebarNavList
                data={menu}
                key={i}
                index={i}
                expand={(e) => this.handleExpand(e, i, match)}
                active={i === this.state.active}
                clicked={this.state.clicked}
                isShop={
                  this.props.authState.userData.loginType ==
                  LoginType._LOGIN_TYPE_SHOP
                }
              />
            )}
          />
        ))}
      </ul>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, null)(SidebarNav);
