import React, { useState, useEffect, useMemo } from "react";
import {
    Panel,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import ReactTable from "../../../components/custom/ReactTable";
import { ApiKey, ApiUrl, Role, FilterStatus, AccessRight } from "../../../util/Constant";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';
import { useDispatch } from "react-redux";
import { showResponseMessage } from "redux/AppAction.js";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { checkIfPermissionExist } from "../../../util/PermissionChecker";

/// <summary>
/// Author : Wind
/// Edited : CK - Allow to view members by rank 
/// </summary> 
const RankSummaryReport = props => {
    var _dispatch = useDispatch();
    const { t } = useTranslation();
    const [rankSummary, setRankSummary] = useState([]);
    const [regionId, setRegionId] = useState('');
    const [regions, setRegions] = useState([]);
    const [apiUrl, setApiUrl] = useState('');
    const [isMemberModalVisible, setIsMemberModalVisible] = useState(false);

    let _tableColumns = [
        {
            Header: "No",
            id: "row",
            filterable: false,
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            disableSortBy: true,
            width: 50
        },
        {
            Header: "REGION",
            accessor: "regionName",
            disableSortBy: true
        },
        {
            Header: "RANK",
            accessor: "rankName",
            disableSortBy: true
        },
        {
            Header: "TOTAL_MEMBER",
            accessor: "rankCount",
            disableSortBy: true
        },
        {

            id: "action",
            Header: "",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._MEMBER_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu style={{ 'overflow-y': 'scroll' }}>
                            <DropdownItem onClick={() => {
                                getMembersByRankId(row.original.rankId);
                            }}>
                                <span>{t("VIEW_DETAIL")}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ]

    var _memberTableColumns = [
        {
            Header: 'USERNAME',
            accessor: 'username',
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original.username} ({row.original.regionName})
                    </span>
                )
            }
        },
        {
            Header: 'SHOP',
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original.shopUsername} ({row.original.shopName})
                    </span>
                )
            },
            disableSortBy: true
        },
        {
            Header: 'STATUS',
            accessor: 'status',
            Cell: ({ row }) => {
                return (
                    <span>
                        {
                            row.original.status == FilterStatus._ACTIVE ?
                                <span class="badge badge-secondary badge-green">{t("ACTIVE")}</span> :
                                <span class="badge badge-secondary badge-danger">{t("SUSPENDED")}</span>
                        }
                    </span>
                )
            },
            disableSortBy: true
        },
        {
            Header: 'TOTAL_REWARD',
            accessor: row => row.totalReward.toFixed(2)
        },
        {
            Header: 'TOTAL_CREDIT',
            accessor: row => row.totalCredit.toFixed(2)
        },
        {
            Header: 'EXPERIENCE',
            Cell: ({ row }) => {
                let experienceDetail = row.original;
                return <span>{experienceDetail ? numberWithCurrencyFormat(experienceDetail.experience, 3) + " Exp (" + experienceDetail.rankName + ")" : ""}</span>
            },
            disableSortBy: true
        },
        {
            Header: 'LAST_LOGIN',
            accessor: 'lastLoginDateString',
        }
    ];

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        applyFilter();

        (async () => {
            var regionJsonResult = await ApiEngine.get(ApiUrl._API_GET_REGION);

            if (regionJsonResult[ApiKey._API_SUCCESS_KEY]) {
                var availableRegions = [];

                regionJsonResult[ApiKey._API_DATA_KEY].map((region) => {
                    availableRegions.push({
                        label: region['name'],
                        value: region['id']
                    });
                });

                setRegions(availableRegions);
            }
        })();
    }, [])

    /// <summary>
    /// Author : Wind
    /// </summary> 
    async function applyFilter() {
        let apiUrl = ApiUrl._API_GET_RANK_SUMMARY + "?v=" + Date.now();

        if (!stringIsNullOrEmpty(regionId)) {
            apiUrl += "&RegionId=" + regionId;
        }

        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setRankSummary(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary> 
    async function getMembersByRankId(rankId) {
        setApiUrl(ApiUrl._API_GET_MEMBERS_BY_RANK_ID + "?RankId=" + rankId + "&v=" + Date.now());
        setIsMemberModalVisible(true);
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    const PaginationTable = useMemo(() =>
        <ReactTable
            className="col-nowrap"
            columns={_memberTableColumns}
            fetchUrl={apiUrl}
        />
        , [apiUrl])

    return (
        <div>
            <h1 className="page-header">{t("RANK_SUMMARY_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <div className="row">
                        <div className="col-lg-3">
                            <label>{t("REGION")}</label>
                            <select
                                className="form-control form-control-lg col-lg-12"
                                id="region"
                                name="region"
                                value={regionId}
                                onChange={(e) => { setRegionId(e.target.value); }}>
                                <option value={''} key={-1}>{t('ALL')}</option>
                                {
                                    regions.map((region, index) => {
                                        return <option value={region['value']} key={index}>{region['label']}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-lg-2">
                            <button type="button" className="btn btn-primary" onClick={() => { applyFilter() }}>{t('APPLY_FILTER')}</button>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    <ReactTable data={rankSummary} columns={_tableColumns} filterable={false} />
                </PanelBody>
            </Panel>
            <Modal isOpen={isMemberModalVisible} toggle={() => { setIsMemberModalVisible(false); }} size="xl">
                <ModalHeader toggle={() => { setIsMemberModalVisible(false); }}>
                    <h4 >
                        {t("MEMBERS")}
                    </h4>
                </ModalHeader>
                <ModalBody>
                    {PaginationTable}
                </ModalBody>
            </Modal>
        </div>
    );
}


export default RankSummaryReport;