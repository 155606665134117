import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { showResponseMessage } from "../../redux/AppAction";
import { ApiKey, ApiUrl, Status } from "../../util/Constant";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ApiEngine from '../../util/ApiEngine.js';
import { stringIsNullOrEmpty } from '../../util/Util';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const ResetPasswordModal = (props) => {
    const [modalVisibility, setModalVisibility] = useState(false);
    const { register, handleSubmit, setValue, errors, reset, unregister, watch } = useForm();
    const { t, i18n } = useTranslation();
    var _dispatch = useDispatch();
    const [targetUserId, setTargetUserId] = useState('');

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function cleanup(isSuccess) {
        if (stringIsNullOrEmpty(targetUserId)) {
            setValue("oldPassword", "");
            unregister("oldPassword");

        }
        setValue("newPassword", "");

        unregister("newPassword");
        setTargetUserId("");

        props.onFinish(isSuccess);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        setModalVisibility(props["visible"]);
        setTargetUserId(props["targetUserId"]);
    }, [props["visible"]]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (modalVisibility) {
            init();
        }
        else {
            cleanup(false);
        }
    }, [modalVisibility]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        if (stringIsNullOrEmpty(targetUserId)) {
            register({ name: "oldPassword" }, { required: "PLEASE_ENTER_OLD_PASSWORD" });
        }

        register({ name: "newPassword" }, { required: "PLEASE_ENTER_NEW_PASSWORD", minLength: { value: 6, message: "PASSWORD_MIN_LENGTH" } });
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// Edited : Wind - Added reset password param for shop
    /// </summary> 
    const onSubmit = async (data, e) => {
        var apiUrl = ApiUrl._API_CHANGE_PASSWORD + "?newPassword=" + data.newPassword ;
        if (!stringIsNullOrEmpty(targetUserId)) {
            apiUrl += "&targetUserId=" + targetUserId;
        }
        else {
            apiUrl += "&oldPassword=" + data.oldPassword;
        }

        if (props.resetShopPassword) {
            apiUrl += "&resetPassword=" + props.resetShopPassword;
        }

        var responseJson = await ApiEngine.post(apiUrl);

        setModalVisibility(!responseJson[ApiKey._API_SUCCESS_KEY]);
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    return (
        <Modal isOpen={modalVisibility} toggle={() => setModalVisibility(!modalVisibility)} style={{ paddingBottom: "100px" }} >
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={() => setModalVisibility(!modalVisibility)}>{t("CHANGE_PASSWORD")}</ModalHeader>
                <ModalBody>
                    <div>
                        {stringIsNullOrEmpty(targetUserId) &&
                            <div className="form-group">
                                <label className="login-form-label">{t("OLD_PASSWORD")}</label>
                                <div className="login-field">
                                    <input
                                        placeholder={t("ENTER_OLD_PASSWORD")}
                                        autoComplete="off"
                                        type="password"
                                        id="oldPassword"
                                        onInput={(e) => { setValue('oldPassword', e.target.value); }}
                                        className="form-control m-b-5"
                                        name="oldPassword" />
                                </div>
                                {errors.oldPassword && <div className="invalid-feedback">{t(errors.oldPassword.message)}</div>}
                            </div>
                        }
                        <div className="form-group">
                            <label className="login-form-label">{t("NEW_PASSWORD")}</label>
                            <div className="login-field">
                                <input
                                    placeholder={t("ENTER_NEW_PASSWORD")}
                                    autoComplete="new-password"
                                    type="password"
                                    id="newPassword"
                                    onInput={(e) => { setValue('newPassword', e.target.value); }}
                                    className="form-control m-b-5"
                                    name="newPassword" />
                            </div>
                            {errors.newPassword && <div className="invalid-feedback">{t(errors.newPassword.message)}</div>}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-white" onClick={() => setModalVisibility(!modalVisibility)}>{t("BTN_CLOSE")}</button>
                    <button type="submit" className="btn btn-primary">{t("BTN_SAVE")}</button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default ResetPasswordModal