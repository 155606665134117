import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import ReactTable from "../../../components/custom/ReactTable";
import { ApiKey, ApiUrl, Role } from "../../../util/Constant";
import { stringIsNullOrEmpty, getValidationMessage } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';
import DualListBoxItemRow from "components/custom/DualListBoxItemRow.js";

/// <summary>
/// Author: Chris
/// </summary>
const KioskTimeoutReport = () => {
    const { t } = useTranslation();
    const { register, handleSubmit, setValue, errors } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [shopOption, setShopOption] = useState([]);
    const [vendorOption, setVendorOption] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [selectedOperatedShop, setSelectedOperatedShop] = useState([]);
    const [selectedPlayerShop, setSelectedPlayerShop] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState([]);
    const [postData, setPostData] = useState({});

    const _TYPE_OPTION = {
        ALL: -1,
        IN: 0,
        OUT: 1,
    };

    let _tableColumns = [
        {
            Header: "DATE",
            accessor: "date",
            disableSortBy: true
        },
        {
            Header: "OPERATED_SHOP",
            accessor: "operatedShop",
            disableSortBy: true
        },
        {
            Header: "MEMBERSHIP",
            accessor: "",
            disableSortBy: true
        },
        {
            Header: "PHONE_NUMBER",
            accessor: "phone",
            disableSortBy: true
        },
        {
            Header: "PLAYER_ID",
            accessor: "playerId",
            disableSortBy: true
        },
        {
            Header: "VENDOR",
            accessor: "vendorName",
            disableSortBy: true
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                return (
                    <span>
                        {
                            row.original.status == 0 ?
                            <span class="badge badge-secondary badge-danger">{t("FAIL")}</span> :
                            <span class="badge badge-secondary badge-green">{t("SUCCESS")}</span>
                        }
                    </span>
                )
            },
            disableSortBy: true
        },
        {
            Header: "TYPE",
            accessor: "type",
            disableSortBy: true
        },
        {
            Header: "INITIAL_LIMIT",
            accessor: "initialLimit",
            Cell: ({ row }) => {
                return row.original.initialLimit.toFixed(2);
            },
            Footer: ({ page }) => {
                const initialLimit = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.initialLimit);
                }, 0);

                return <span><b>{initialLimit.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "SCORE",
            accessor: "score",
            Cell: ({ row }) => {
                return row.original.score.toFixed(2);
            },
            Footer: ({ page }) => {
                const score = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.score);
                }, 0);

                return <span><b>{score.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "FINAL_LIMIT",
            accessor: "finalLimit",
            Cell: ({ row }) => {
                return row.original.finalLimit.toFixed(2);
            },
            Footer: ({ page }) => {
                const finalLimit = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.finalLimit);
                }, 0);

                return <span><b>{finalLimit.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "PLAYER_INITIAL_LIMIT",
            accessor: "playerInitial",
            Cell: ({ row }) => {
                return row.original.playerInitial.toFixed(2);
            },
            disableSortBy: true
        },
        {
            Header: "PLAYER_FINAL_LIMIT",
            accessor: "playerFinal",
            Cell: ({ row }) => {
                return row.original.playerFinal.toFixed(2);
            },
            disableSortBy: true
        },
        {
            Header: "IP_ADDRESS",
            accessor: "ip",
            disableSortBy: true
        },
        {
            Header: "ERROR_MESSAGE",
            accessor: "message",
            disableSortBy: true
        },
    ];

    /// <summary>
    /// Author: Chris
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: true });
        register({ name: 'operatedByShops' });
        register({ name: 'playerShops' });
        register({ name: 'vendors' });

        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('operatedByShops', []);
        setValue('playerShops', []);
        setValue('vendors', []);

        init();
    }, []);

    /// <summary>
    /// Author: Chris
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_USERS}?RoleId=${Role._SHOP}`);

        if (responseJson[ApiKey._API_SUCCESS_KEY])
        {
            var userList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (user) {
                userList.push({ label: user.username + " (" + user.shopName + ")", value: user.id });
            });

            setShopOption(userList);
        }

        responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_LIST);

        if (responseJson[ApiKey._API_SUCCESS_KEY])
        {
            var vendorList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (vendor) {
                vendorList.push({ label: vendor.name + " (" + vendor.shortName +")", value: vendor.id });
            });

            setVendorOption(vendorList);
        }
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "TargetOperatedBy": data.operatedByShops,
            "TargetPlayerShop": data.playerShops,
            "TargetVendor": data.vendors,
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "Type": parseInt(data.type),
        };

        if (!stringIsNullOrEmpty(data.playerId)) {
            params["PlayerId"] = data.playerId;
        }

        if (!stringIsNullOrEmpty(data.phone)) {
            params["PhoneNumber"] = data.phone;
        }

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_KIOSK_TIMEOUT_REPORT);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable columns={_tableColumns} fetchUrl={apiUrl} renderFooter={true} postData={postData} exportRequired={true} />, [apiUrl, postData])

    return <>
        <h1 className="page-header">{t("KIOSK_TIMEOUT_REPORT")}</h1>
        <Panel>
            <PanelBody>
                <form onSubmit={handleSubmit(submitForm)}>
                    <div className="row m-b-10">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label>{t("OPERATED_BY")}</label>
                                <DualListBoxItemRow options={shopOption} selected={selectedOperatedShop} />
                                <DualListBox
                                    canFilter
                                    name="operatedByShops"
                                    options={shopOption}
                                    selected={selectedOperatedShop}
                                    onChange={(e) => {
                                        setValue('operatedByShops', e);
                                        setSelectedOperatedShop(e);
                                    }}
                                    ref={register}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label>{t("OPERATED_SHOP")}</label>
                                <DualListBoxItemRow options={shopOption} selected={selectedPlayerShop} />
                                <DualListBox
                                    canFilter
                                    name="playerShops"
                                    options={shopOption}
                                    selected={selectedPlayerShop}
                                    onChange={(e) => {
                                        setValue('playerShops', e);
                                        setSelectedPlayerShop(e);
                                    }}
                                    ref={register}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label>{t("VENDOR")}</label>
                                <DualListBoxItemRow options={vendorOption} selected={selectedVendor} />
                                <DualListBox
                                    canFilter
                                    name="vendors"
                                    options={vendorOption}
                                    selected={selectedVendor}
                                    onChange={(e) => {
                                        setValue('vendors', e);
                                        setSelectedVendor(e);
                                    }}
                                    ref={register}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label>{t("TYPE")}</label>
                                <select name="type" ref={register} className="form-control">
                                    <option value={_TYPE_OPTION.ALL}>{t("ALL")}</option>
                                    <option value={_TYPE_OPTION.IN}>{t("IN")}</option>
                                    <option value={_TYPE_OPTION.OUT}>{t("OUT")}</option>
                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label>{t("PLAYER_ID")}</label>
                                <input type="text" name="playerId" ref={register} className="form-control" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label>{t("PHONE_NUMBER")}</label>
                                <input type="text" name="phone" ref={register} className="form-control" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label>{t("START_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="startDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={startDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                            setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("startDate","");
                                        }
                                    }} />
                                {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label>{t("END_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="endDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={endDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                            setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("endDate", "");
                                        }
                                    }} />
                                {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                        </div>
                    </div>
                </form>
            </PanelBody>
        </Panel>
        <Panel>
            <PanelBody>
                {PaginationTable}
            </PanelBody>
        </Panel>
    </>;
};

export default KioskTimeoutReport;