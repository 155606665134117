import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import {
    Panel,
    PanelHeader,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import ReactTable from "../../../components/custom/ReactTable";
import { ApiKey, ApiUrl, WebUrl, AccessRight, Role } from "../../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody, arrayGroupByKey } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';
import DualListBoxItemRow from "components/custom/DualListBoxItemRow.js";

/// <summary>
/// Author : Yong Sheng Chuan
/// Editted: CK - Table returning column changes
/// </summary>
const RewardReport = props => {
    const { t } = useTranslation();
    const [apiUrl, setApiUrl] = useState('');
    const [summaryApiUrl, setSummaryApiUrl] = useState('');
    const [selectedShop, setSelectedShop] = useState([]);
    const [shopOption, setShopOption] = useState([]);
    const [detailData, setDetailData] = useState([]);
    const [showSummary, setShowSummary] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [phone, setPhone] = useState('');
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [postData, setPostData] = useState({});
    const [summaryPostData, setSummaryPostData] = useState({});
    const [recordDateSummary, setRecordDateSummary] = useState([]);
    const [showRecordDateSummary, setShowRecordDateSummary] = useState(false);

    let _summaryTableColumns = [
        {
            Header: "DATE",
            accessor: "date",
            disableSortBy: true
        },
        {
            Header: "AMOUNT",
            accessor: "amount",
            Cell: ({ row }) => {
                return row.original.amount > 0 ? <span className="text-success">{row.original.amount}</span> : <span className="text-danger">{row.original.amount}</span>;
            },
            disableSortBy: true
        }
    ]

    let _detailTableColumns = [
        {
            Header: "USERNAME",
            accessor: "username",
            disableSortBy: true
        },
        {
            Header: "SHOP",
            accessor: "shopName",
            disableSortBy: true
        },
        {
            Header: "ACCUMULATED_REWARD",
            accessor: "accumulatedReward",
            disableSortBy: true
        },
        {
            Header: "TOTAL_LOSE",
            accessor: "loseAmount",
            Cell: ({ row }) => {
                return <span className="text-danger">{row.original.loseAmount}</span>;
            },
            disableSortBy: true
        },
        {
            Header: "TOTAL_WIN",
            accessor: "winAmount",
            Cell: ({ row }) => {
                return <span className="text-success">{row.original.winAmount}</span>;
            },
            disableSortBy: true
        },
    ]

    let _tableColumns = [
        {
            Header: "PROCESS_DATE",
            accessor: "processDate",
            Cell: ({ row }) => {
                return moment(row.original.processDate, 'MM/DD/YYYY HH:mm:ss').format('MM/DD/YYYY');
            },
            disableSortBy: true
        },
        {
            Header: "RECORD_DATE",
            accessor: "recordDate",
            Cell: ({ row }) => {
                return moment(row.original.recordDate, 'MM/DD/YYYY HH:mm:ss').format('MM/DD/YYYY');
            },
            disableSortBy: true
        },
        {
            Header: "USERNAME",
            accessor: "username",
            disableSortBy: true
        },
        {
            Header: "SHOP",
            accessor: "shopName",
            disableSortBy: true
        },
        {
            Header: "REGION",
            accessor: "region",
            disableSortBy: true
        },
        {
            Header: "REWARD",
            accessor: "reward",
            Cell: ({ row }) => {
                return row.original.reward > 0 ? <span className="text-success">{row.original.reward}</span> : <span className="text-danger">{row.original.reward}</span>;
            },
            disableSortBy: true
        },
        {
            Header: "TOTAL_LOSE",
            accessor: "loseAmount",
            Cell: ({ row }) => {
                return <span className="text-danger">{row.original.loseAmount}</span>;
            },
            disableSortBy: true
        },
        {
            Header: "TOTAL_WIN",
            accessor: "winAmount",
            Cell: ({ row }) => {
                return <span className="text-success">{row.original.winAmount}</span>;
            },
            disableSortBy: true
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default">
                            <i className="fas fa-cog"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                onClick={() => {
                                    readRewardDetail(row.original.userId);
                                }}>
                                <span>{t("VIEW_DETAIL")}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
        },
    ]

    let _recordDateSummaryTableColumns = [
        {
            Header: "PROCESS_DATE",
            accessor: "processDate",
            Cell: ({ row }) => {
                return moment(row.original.processDate, 'MM/DD/YYYY HH:mm:ss').format('MM/DD/YYYY');
            },
            disableSortBy: true
        },
        {
            Header: "RECORD_DATE",
            accessor: "recordDate",
            Cell: ({ row }) => {
                return moment(row.original.recordDate, 'MM/DD/YYYY HH:mm:ss').format('MM/DD/YYYY');
            },
            Footer: () => {
                return <span style={{ float: 'right' }}><b>TOTAL: </b></span>
            },
            disableSortBy: true
        },
        {
            Header: "REWARD",
            accessor: "reward",
            Cell: ({ row }) => {
                return <span className="text-success">{row.original.reward}</span>;
            },
            Footer: ({ page }) => {
                const rewardTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.reward);
                }, 0);

                return <span><b>{rewardTotal.toFixed(6)}</b></span>
            },
            disableSortBy: true
        }
    ]

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        var apiUrl = ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP;

        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var shopListByRegion = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "regionName");
            var shopOptionsByRegion = [];

            for (var key in shopListByRegion) {
                var shopList = [];

                shopListByRegion[key].map(shop => {
                    shopList.push({ label: shop.username + " (" + shop.shopName + ")", value: shop.id });
                });

                shopOptionsByRegion.push({
                    label: key,
                    value: key,
                    options: shopList
                })
            }

            setShopOption(shopOptionsByRegion);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function readRewardDetail(memberId) {
        var data = {
            "StartDate": startDate + " 00:00:00",
            "EndDate": endDate + " 23:59:59",
            "TargetMemberId": memberId,
        };
        var responseJson = await ApiEngine.post(ApiUrl._API_GET_REWARD_DETAIL_REPORT, data);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setShowDetail(true);
            setDetailData(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function filterRewardReport(isSummary) {
        var data = {
            "Phone": phone,
            "StartDate": startDate + " 00:00:00",
            "EndDate": endDate + " 23:59:59",
            "TargetShop": selectedShop,
        };

        if (isSummary) {
            setSummaryPostData(data);

            setSummaryApiUrl(ApiUrl._API_GET_REWARD_SUMMARY_REPORT);
        }
        else {
            setPostData(data);

            setApiUrl(ApiUrl._API_GET_REWARD_REPORT);
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function getRecordDateSummary() {
        var data = {
            "Phone": phone,
            "StartDate": startDate + " 00:00:00",
            "EndDate": endDate + " 23:59:59",
            "TargetShop": selectedShop,
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_GET_REWARD_RECORD_DATE_REPORT, data);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setShowRecordDateSummary(true);
            setRecordDateSummary(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable columns={_tableColumns} postData={postData} showOverallInfo={true} fetchUrl={apiUrl} />, [postData, apiUrl])

    return (
        <div>
            <h1 className="page-header">{t("REWARD_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <div className="row m-b-10">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("START_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="startDate"
                                    timeFormat={false}
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={startDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setStartDate(e.format("YYYY-MM-DD"));
                                        }
                                        else {
                                            setStartDate("");
                                        }
                                    }} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("END_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="endDate"
                                    timeFormat={false}
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={endDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setEndDate(e.format("YYYY-MM-DD"));
                                        }
                                        else {
                                            setEndDate("");
                                        }
                                    }} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("PHONE_NUMBER")}</label>
                                <input type="text" name="phone" className="form-control form-control-lg" onInput={(e) => setPhone(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="row m-b-10">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <DualListBoxItemRow options={shopOption} selected={selectedShop} />
                                <DualListBox
                                    canFilter
                                    name="shops"
                                    options={shopOption}
                                    selected={selectedShop}
                                    onChange={(e) => {
                                        setSelectedShop(e);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <button type="button" className="btn btn-primary" onClick={() => filterRewardReport(false)}>{t('APPLY_FILTER')}</button>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    <div className="row m-b-10">
                        <div class="col">
                            <button type="button" className="btn btn-primary m-r-10" onClick={() => {
                                filterRewardReport(true);
                                setShowSummary(true)
                            }}
                            >{t('SUMMARY')}</button>
                            <button type="button" className="btn btn-primary" onClick={() => {
                                getRecordDateSummary();
                            }}
                            >{t('RECORD_DATE_SUMMARY')}</button>
                        </div>
                    </div>
                    <div className="row m-b-10">
                        <div className="col-lg-12">
                            <div className="form-group">
                                {PaginationTable}
                            </div>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <Modal isOpen={showDetail} toggle={() => setShowDetail(false)} style={{ paddingBottom: "100px" }} className="modal-lg" keyboard={true} >
                <ModalHeader toggle={() => setShowDetail(false)}>{t("REWARD_DETAIL")}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <ReactTable columns={_detailTableColumns} data={detailData} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn btn-inverse-brand" onClick={() => setShowDetail(false)}>{t("BTN_CLOSE")}</button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={showSummary} toggle={() => setShowSummary(false)} style={{ paddingBottom: "100px" }} className="modal-lg" keyboard={true} >
                <ModalHeader toggle={() => setShowSummary(false)}>{t("SUMMARY")}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <ReactTable columns={_summaryTableColumns} fetchUrl={summaryApiUrl} postData={summaryPostData} exportRequired={true} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn btn-inverse-brand" onClick={() => setShowSummary(false)}>{t("BTN_CLOSE")}</button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={showRecordDateSummary} toggle={() => setShowRecordDateSummary(false)} style={{ paddingBottom: "100px" }} className="modal-lg" keyboard={true} >
                <ModalHeader toggle={() => setShowRecordDateSummary(false)}>{t("RECORD_DATE_SUMMARY")}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <ReactTable columns={_recordDateSummaryTableColumns} data={recordDateSummary} renderFooter={true} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn btn-inverse-brand" onClick={() => setShowRecordDateSummary(false)}>{t("BTN_CLOSE")}</button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default RewardReport;