import React, { useState, useEffect, useContext } from 'react';
import DropdownLanguage from './dropdown/language.jsx';
import DropdownProfile from './dropdown/profile.jsx';
import DropdownMegaMenu from './dropdown/mega.jsx';
import { PageSettings } from './../../config/page-settings.js';
import { useDispatch, useSelector } from "react-redux";
import { ApiUrl, Theme, Role } from './../../util/Constant';
import { useTranslation } from 'react-i18next';
import { getShopCredit } from "../../redux/AuthAction.js";

const Header = props => {
    const { t } = useTranslation();
    const { isLoggedIn, creditLimit, userData } = useSelector(state => state.authState);
    const { apiCalledResponse } = useSelector(state => state.appState);
    const _dispatch = useDispatch();

    const _LIST_OF_API_TO_UPDATE_CREDIT = [
        ApiUrl._API_UPDATE_SHOP_LIMIT
    ];

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        // Get manager limit for manager user
        if (isLoggedIn && userData?.userRoleId == Role._MANAGER) {
            _dispatch(getShopCredit())
        }
    }, [isLoggedIn, creditLimit]);

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        if (userData?.userRoleId == Role._MANAGER && apiCalledResponse && apiCalledResponse.success && _LIST_OF_API_TO_UPDATE_CREDIT.filter(i => apiCalledResponse.url.includes(i)).length != 0) {
            _dispatch(getShopCredit())
        }
    }, [apiCalledResponse]);

    return (
        <PageSettings.Consumer>
            {({ toggleMobileSidebar, toggleRightSidebar, toggleMobileRightSidebar, toggleMobileTopMenu, pageHeaderLanguageBar, pageHeaderMegaMenu, pageTwoSidebar, pageTopMenu, pageSidebar }) => (
                <div id="header" className="header navbar-default">
                    <div className="navbar-header">
                        {pageTwoSidebar && (
                            <button type="button" className="navbar-toggle pull-left" onClick={toggleMobileRightSidebar}>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        )}

                        {pageHeaderMegaMenu && (
                            <button type="button" className="navbar-toggle pt-0 pb-0 mr-0" onClick={this.toggleMegaMenu}>
                                <span className="fa-stack fa-lg text-inverse">
                                    <i className="far fa-square fa-stack-2x"></i>
                                    <i className="fa fa-cog fa-stack-1x"></i>
                                </span>
                            </button>
                        )}
                        {pageTopMenu && pageSidebar && (
                            <button type="button" className="navbar-toggle pt-0 pb-0 mr-0 collapsed" onClick={toggleMobileTopMenu}>
                                <span className="fa-stack fa-lg text-inverse">
                                    <i className="far fa-square fa-stack-2x"></i>
                                    <i className="fa fa-cog fa-stack-1x"></i>
                                </span>
                            </button>
                        )}
                        {!pageSidebar && pageTopMenu && (
                            <button type="button" className="navbar-toggle" onClick={toggleMobileTopMenu}>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        )}
                        {pageSidebar && (
                            <button type="button" className="navbar-toggle pull-left" onClick={toggleMobileSidebar}>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        )}

                        <div className="brand-wrapper">
                            <img src={
                                process.env.REACT_APP_NAME == Theme._EPAY ?
                                    require("../../assets/img/logo/epay.png")
                                    : process.env.REACT_APP_NAME == Theme._OREN ?
                                        require("../../assets/img/logo/oren.png")
                                        : require("../../assets/img/logo/mantap-logo.png")}
                                alt="epay-logo"
                            />
                            <strong>
                                {
                                    process.env.REACT_APP_NAME == Theme._EPAY ?
                                        "Epay Mobile Reload" :
                                        process.env.REACT_APP_NAME == Theme._OREN ?
                                            "Oren Mart" : "Mantap"
                                }
                            </strong>
                        </div>
                    </div>

                    {pageHeaderMegaMenu && (
                        <DropdownMegaMenu collapse={this.state.collapseMegaMenu} />
                    )}

                    <ul className="navbar-nav navbar-right">
                        {
                            userData?.userRoleId == Role._MANAGER &&
                            <li class='dropdown'>
                                <div class='credit-limit'><strong>{Number(creditLimit).toFixed(2)}</strong> {t('POINTS')}</div>
                            </li>
                        }

                        {pageHeaderLanguageBar && (
                            <DropdownLanguage />
                        )}

                        <DropdownProfile />
                    </ul>
                </div>
            )}
        </PageSettings.Consumer>
    )
}

export default Header;
