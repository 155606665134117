import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import NavigationButton from '../../../components/custom/NavigationButton';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { WebUrl, ApiKey, ApiUrl, AccessRight, AlertTypes, EventStatus } from '../../../util/Constant';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useDispatch } from "react-redux";
import { showMessage } from "../../../redux/AppAction";
import ApiEngine from '../../../util/ApiEngine.js';
import moment from 'moment';
import PrizeDistributionModal from '../../../components/custom/PrizeDistributionModal';
import VipMemberModal from '../../../components/custom/VipMemberModal';

/// <summary>
/// Author: Wind
/// </summary> 
const ManageKogEvent = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    var _dispatch = useDispatch();
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [assignVip, setAssignedVip] = useState({});
    const [getEventApiUrl, setGetEventApiUrl] = useState('');

    /// <summary>
    /// Author: Wind
    /// </summary>
    let _eventColumns = useMemo(() => [
        {
            Header: "BANNER",
            accessor: "bannerImage",
            Cell: ({ row }) => {
                return row.original.bannerImage ? <img class="mini-program-logo" src={row.original.bannerImage} /> : <div></div>
            }
        },
        {
            Header: "EVENT_NAME",
            accessor: "name",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{row.original.nameLocalizations.map((item) => { return item.value }).join(" / ")}</div>
            }
        },
        {
            Header: "START_DATE",
            accessor: "startDate",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{moment(row.original.startDate).format("YYYY-MM-DD HH:mm:ss")}</div>
            }
        },
        {
            Header: "END_DATE",
            accessor: "endDate",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{moment(row.original.endDate).format("YYYY-MM-DD HH:mm:ss")}</div>
            }
        },
        {
            Header: "VENDOR",
            accessor: "vendorList",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{row.original.vendorList?.map(v => v.vendorGameName).join(", ")}</div>
            }
        },
        {
            Header: "REGION",
            accessor: "regionList",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div><b>{row.original.regionList?.map(v => v.regionName).join(", ")}</b></div>
            }
        },
        {
            Header: "STATUS",
            accessor: "status",
            disableSortBy: true,
            Cell: ({ row }) => {
                let returnClassName = "";
                switch (row.original.status) {
                    case EventStatus._PENDING:
                        returnClassName = "badge badge-secondary";
                        break;
                    case EventStatus._PAUSE:
                        returnClassName = "badge badge-warning";
                        break;
                    case EventStatus._END:
                        returnClassName = "badge badge-danger";
                        break;
                    case EventStatus._START:
                        returnClassName = "badge badge-success";
                        break;
                }
                return <span className={returnClassName} style={{ textTransform: "uppercase" }}>{t(row.original.statusString)}</span>
            },
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._EVENT_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t('ARE_YOU_SURE'),
                                    showCancel: true,
                                    content: t('ARE_YOU_SURE_YOU_WANT_TO') + t('COPY') + " " + row.original.name + "?",
                                    onConfirm: () => { copyEvent(row.original.id); }
                                }));
                            }}>
                                <span>{t('COPY')}</span>
                            </DropdownItem>
                            {
                                row.original.status != EventStatus._PENDING &&
                                <>
                                    <DropdownItem onClick={() => {
                                        const win = window.open(WebUrl._URL_ADMIN_KOG_EVENT_RANKING + "?id=" + row.original.id + "&name=" + row.original.name, "_blank");
                                        win.focus();
                                    }}>
                                        <span>{t('VIEW_EVENT_RANKING')}</span>
                                    </DropdownItem>
                                    <DropdownItem onClick={() => {
                                        setSelectedEvent(row.original);
                                    }}><span>{t("VIEW_PRIZE_WINNER")}</span>
                                    </DropdownItem>
                                </>
                            }
                            <DropdownItem onClick={() => {
                                _history.push({
                                    pathname: WebUrl._URL_ADMIN_MANAGE_KOG_EVENT_DETAIL,
                                    state: { id: row.original.id }
                                })
                            }}>
                                <span>{t('EDIT')}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t('ARE_YOU_SURE'),
                                    showCancel: true,
                                    content: t('ARE_YOU_SURE_YOU_WANT_TO') + t('DELETE') + " " + row.original.name + "?",
                                    onConfirm: () => { deleteEvent(row.original.id); }
                                }));
                            }}>
                                <span className="text-danger">{t('DELETE')}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                const win = window.open(WebUrl._URL_ADMIN_KOG_VIP_MEMBER + "?id=" + row.original.id + "&name=" + row.original.name
                                    + "&region=" + (row.original.regionList?.map(v => v.regionName).join(", ")), "_blank");
                                win.focus();
                            }}>
                                <span>{t('VIEW_VIP_MEMBER')}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ], [getEventApiUrl]);

    /// <summary>
    /// Author: Wind
    /// </summary> 
    useEffect(() => {
        getEventList();
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function getEventList() {
        setGetEventApiUrl(ApiUrl._API_EVENT + '?&v=' + moment.now());
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function deleteEvent(id) {
        var responseJson = await ApiEngine.delete(ApiUrl._API_EVENT + "/" + id);

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && getEventList()
        }));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function copyEvent(id) {
        var responseJson = await ApiEngine.post(ApiUrl._API_COPY_EVENT + "/" + id);

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && getEventList()
        }));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const PaginationTable = useMemo(() =>
        <ReactTable columns={_eventColumns} fetchUrl={getEventApiUrl} exportRequired={true} />
        , [getEventApiUrl])

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_KOG_EVENT")}</h1>
                {
                    checkIfPermissionExist(AccessRight._EVENT_CONTROL_PERMISSION, true) &&
                    <NavigationButton history={_history} url={WebUrl._URL_ADMIN_MANAGE_KOG_EVENT_DETAIL} buttonText={t("ADD_KOG_EVENT")} />
                }
            </div>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            {PaginationTable}
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <PrizeDistributionModal
                selectedEvent={selectedEvent}
                onFinish={(event) => {
                    setSelectedEvent(event);
                }} />
            <VipMemberModal
                selectedEvent={assignVip}
                onFinish={() => {
                    setAssignedVip({});
                }} />
        </div>
    )
}

export default ManageKogEvent;