import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import ReactTable from "../../../components/custom/ReactTable";
import { ApiKey, ApiUrl, Role } from "../../../util/Constant";
import { stringIsNullOrEmpty, arrayGroupByKey } from "../../../util/Util";
import { useForm } from "react-hook-form";
import ApiEngine from '../../../util/ApiEngine.js';
import DualListBoxItemRow from "components/custom/DualListBoxItemRow.js";

/// <summary>
/// Author: Nelson
/// </summary>
const ManageLuckyNumber = props => {
    const [postData, setPostData] = useState({});
    const { register, handleSubmit, setValue } = useForm();
    const { t } = useTranslation();
    const [apiUrl, setApiUrl] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [shopOption, setShopOption] = useState([]);
    const [selectedShop, setSelectedShop] = useState([]);

    const _STATUS_OPTIONS = {
        ALL: '',
        ACTIVE: true,
        INACTIVE: false
    };

    let _tableColumns = [
        {
            Header: "No",
            id: "row",
            filterable: false,
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            disableSortBy: true
        },
        {
            Header: "BET_DATE",
            accessor: "betDateTime",
            disableSortBy: true
        },
        {
            Header: "OPERATED_SHOP",
            accessor: "shopName",
            disableSortBy: true
        },
        {
            Header: "PHONE_NUMBER",
            accessor: "usernamePhoneNumber",
            disableSortBy: true
        },
        {
            Header: "PLAYER_ID",
            accessor: "playerId",
            disableSortBy: true
        },
        {
            Header: "NUMBER",
            accessor: "number",
            disableSortBy: true
        },
        {
            Header: "BET_TYPE",
            accessor: "betType",
            disableSortBy: true
        },
        {
            Header: "BET_AMOUNT",
            accessor: "amount",
            disableSortBy: true,
            Cell: ({ row }) => (
                <span>{parseFloat(row.original.amount).toFixed(2)}</span>
            )
        },
        {
            Header: "DRAW_DATE",
            accessor: "drawDate",
            disableSortBy: true
        },
        {
            Header: "STATUS",
            accessor: "status",
            disableSortBy: true,
            Cell: ({ row }) => (
                <span className={"badge " + getStatusClassName(row.original.status)}>{t(row.original.statusName)}</span>
            )
        }
    ]

    /// <summary>
    /// Author: Nelson
    /// </summary>
    useEffect(() => {
        register({ name: 'operatedShop' });
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: true });

        setValue('operatedShop', []);
        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD"));
        setValue('endDate', moment().format("YYYY-MM-DD"));
        init();
    }, []);

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async function init() {
        await getShopOption();
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async function getShopOption() {
        var apiUrl = ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP;
        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var shopListByRegion = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "regionName");
            var shopOptionsByRegion = [];

            for (var key in shopListByRegion) {
                var shopList = [];

                shopListByRegion[key].map(shop => {
                    shopList.push({ label: shop.username + " (" + shop.shopName + ")", value: shop.id });
                });

                shopOptionsByRegion.push({
                    label: key,
                    value: key,
                    options: shopList
                })
            }

            setShopOption(shopOptionsByRegion);
        }
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    function getStatusClassName(status) {
        let spanClass = "";

        switch (status) {
            case _STATUS_OPTIONS.ACTIVE:
                spanClass = "badge-green";
                break;
            case _STATUS_OPTIONS.INACTIVE:
                spanClass = "badge-red";
                break;
        }

        return spanClass;
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    const submitForm = async (data) => {
        setPostData({
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "Status": data.status,
            "PhoneNumber": data.phoneNumber ?? '',
            "PlayerId": data.playerId ?? '',
            "TargetShop": data.operatedShop
        });
        setApiUrl(ApiUrl._API_GET_LUCKY_NUMBERS);
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    const PaginationTable = useMemo(() =>
        <ReactTable
            className="col-nowrap"
            columns={_tableColumns}
            fetchUrl={apiUrl}
            postData={postData}
            renderFooter
            exportRequired />
        , [apiUrl, postData]);

    return (
        <div>
            <h1 className="page-header">{t("MANAGE_LUCKY_NUMBER")}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        ref={register}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD"));
                                                setValue("startDate", e.format("YYYY-MM-DD"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        ref={register}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD"));
                                                setValue("endDate", e.format("YYYY-MM-DD"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("PHONE_NUMBER")}</label>
                                    <input type="text" name="phoneNumber" ref={register} className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("PLAYER_ID")}</label>
                                    <input type="text" name="playerId" ref={register} className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("STATUS")}</label>
                                    <select name="status" ref={register} className="form-control form-control-lg">
                                        <option value={_STATUS_OPTIONS.ALL}>{t("ALL")}</option>
                                        <option value={_STATUS_OPTIONS.ACTIVE}>{t("ACTIVE")}</option>
                                        <option value={_STATUS_OPTIONS.INACTIVE}>{t("INACTIVE")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>{t("OPERATED_SHOP")}</label>
                                    <DualListBoxItemRow options={shopOption} selected={selectedShop} />
                                    <DualListBox
                                        canFilter
                                        name="operatedShop"
                                        ref={register}
                                        options={shopOption}
                                        selected={selectedShop}
                                        onChange={(e) => {
                                            setValue('operatedShop', e);
                                            setSelectedShop(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div >
    );
};

export default ManageLuckyNumber;