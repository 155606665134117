import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, AccessRight, AlertTypes } from "../../../util/Constant";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { showResponseMessage, showMessage } from "../../../redux/AppAction";
import { createMultiPartFormBody, getValidationMessage, createFormBody } from "../../../util/Util";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import ApiEngine from '../../../util/ApiEngine.js';

/// <summary>
/// Author: Wind
/// Edited: CK - Able to modify a specific value for each of the regions
/// </summary> 
const ManageSystemSettings = props => {
    var _dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, errors, setValue } = useForm();
    const {
        register: registerEditRegion,
        handleSubmit: handleSubmitEditRegion,
        errors: errorsEditRegion,
        watch: watchEditRegion,
        reset: resetEditRegion,
        setValue: setValueRegion
    } = useForm();
    const [systemSettingsData, setSystemSettingsData] = useState([]);
    const [showEditSettingModal, setShowEditSettingModal] = useState(false);
    const [systemSetting, setSystemSetting] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [showEditRegionModal, setShowEditRegionModal] = useState(false);
    const [regionList, setRegionList] = useState([]);
    const [targetRegion, setTargetRegion] = useState([]);

    let _systemSettingsTableColumns = useMemo(() => [
        {
            Header: "NAME",
            accessor: "name",
            disableSortBy: true,
        },
        {
            Header: "DESCRIPTION",
            accessor: "description",
            disableSortBy: true,
        },
        {
            Header: "VALUE",
            Cell: ({ row }) => {
                return (
                    row.original.settingRegionList.map(region => {
                        return <>
                            <div className="td-singleline"><b>- {region.name}</b> - <span>{region.value}</span><br /></div>
                        </>
                    })
                )
            },
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "LAST_MODIFIED",
            accessor: "updatedTime",
            disableSortBy: true,
        },
        {
            Header: "MODIFIED_BY",
            accessor: "updatedBy",
            disableSortBy: true,
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => loadSystemSettings(row.original)}><span>{t('EDIT_SETTING')}</span></DropdownItem>
                            <DropdownItem onClick={() => loadRegionSettings(row.original)}><span>{t('EDIT_REGION')}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableFilters: true,
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ], [regionList, systemSettingsData]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        getSystemSettings();
        getRegionList();
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function getSystemSettings() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SYSTEM_SETTING + "?settingName=" + filterName + "&settingValue=" + filterValue);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setSystemSettingsData(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function getRegionList() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_REGION);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const regionOption = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (region) {
                regionOption.push({ label: region.name, value: region.id });
            });
            setRegionList(regionOption);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    function loadSystemSettings(data) {
        setSystemSetting(data);
        setShowEditSettingModal(!showEditSettingModal);
    }

    /// <summary>
    /// Author : Wind
    /// Edited: CK - Set target region modified
    /// </summary>
    function loadRegionSettings(data) {
        const selectedRegion = [];
        regionList.map((region) => {
            let currentSelectedRegion = data.settingRegionList.filter(o => o.regionId == region.value);

            selectedRegion.push({
                id: currentSelectedRegion.length ? currentSelectedRegion[0].regionId : region.value,
                label: region.label,
                value: currentSelectedRegion.length ? currentSelectedRegion[0].value : '',
                status: currentSelectedRegion.length ? true : false,
            });
        });

        setTargetRegion(selectedRegion);
        resetEditRegion();
        setShowEditRegionModal(!showEditRegionModal);
        setSystemSetting(data);
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    const submitSystemSettingForm = async (data, e) => {
        let formCollection = {
            id: systemSetting.id,
            description: data.systemSettingDescription,
            value: data.systemSettingValue
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_SYSTEM_SETTING, createMultiPartFormBody(formCollection));
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            getSystemSettings();
            setShowEditSettingModal(!showEditSettingModal)
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    /// <summary>
    /// Author: Wind
    /// Edited: CK - Include region value 
    /// </summary>
    const submitRegionSettingForm = async (data, e) => {
        let params = {
            systemSettingId: systemSetting.id
        }

        let selectedRegion = Object.keys(data.region).filter(k => data.region[k]);
        let regionSetting = [];

        selectedRegion.map(region => {
            regionSetting.push({
                id: region,
                value: data.regionValue[region]
            });
        })

        params["regionSetting"] = regionSetting;

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_REGION_SETTING, params);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            getSystemSettings();
            setShowEditRegionModal(!showEditRegionModal);
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    function resetRegionValue() {
        targetRegion.map(region => {
            setValueRegion("regionValue[" + region.id + "]", systemSetting.value);
        });
    }

    return (
        <div>
            <h1 className="page-header">{t('MANAGE_SETTINGS')}</h1>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label><b>{t('NAME')}</b></label>
                                        <input type="text" className="form-control form-control-lg m-b-5" placeholder={t("FULL_OR_PART_OF_THE_NAME")} onInput={(e) => { setFilterName(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label><b>{t('VALUE')}</b></label>
                                        <input type="text" className="form-control form-control-lg m-b-5" placeholder={t("FULL_OR_PART_OF_THE_VALUE")} onInput={(e) => { setFilterValue(e.target.value) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <button className="btn btn-primary" onClick={() => { getSystemSettings(); }}>{t('APPLY_FILTER')}</button>
                                </div>
                            </div>
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <ReactTable data={systemSettingsData} columns={_systemSettingsTableColumns} globalFilterable={false} />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <div className="row">
                <Modal isOpen={showEditSettingModal}>
                    <form onSubmit={handleSubmit(submitSystemSettingForm)}>
                        <ModalHeader>
                            <h4>{t("EDIT_SETTING_INFORMATION")}</h4>
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("SETTING_NAME")}</b></label>
                                <div className="col-md-7">
                                    <input type="text"
                                        readOnly={true}
                                        defaultValue={systemSetting.name}
                                        autoComplete="off"
                                        className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("DESCRIPTION")}</b></label>
                                <div className="col-md-7">
                                    <textarea
                                        className="form-control form-control-lg"
                                        autoComplete="off"
                                        rows="2"
                                        name="systemSettingDescription"
                                        ref={register({ required: t("PLEASE_ENTER_DESCRIPTION") })}
                                        placeholder={t("ENTER_SETTING_DESCRIPTION")}
                                        defaultValue={systemSetting.description} />
                                    {errors.systemSettingDescription && <div className="invalid-feedback">{t(errors.systemSettingDescription.message)}</div>}
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("VALUE")}</b></label>
                                <div className="col-md-7">
                                    <textarea
                                        className="form-control form-control-lg"
                                        autoComplete="off"
                                        rows="4"
                                        name="systemSettingValue"
                                        ref={register({ required: t("PLEASE_ENTER_VALUE") })}
                                        placeholder={t("ENTER_SETTING_VALUE")}
                                        defaultValue={systemSetting.value} />
                                    {errors.systemSettingValue && <div className="invalid-feedback">{t(errors.systemSettingValue.message)}</div>}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button type="submit" className="btn btn-primary">{t('BTN_SAVE')}</button>
                            <button type="button" className="btn btn-danger" onClick={() => { setShowEditSettingModal(!showEditSettingModal); }}>{t('BTN_CANCEL')}</button>
                        </ModalFooter>
                    </form>
                </Modal>
                <Modal isOpen={showEditRegionModal}>
                    <form onSubmit={handleSubmitEditRegion(submitRegionSettingForm)}>
                        <ModalHeader>
                            <h4>{t('EDIT_REGION')}</h4>
                        </ModalHeader>
                        <ModalBody>
                            {targetRegion.map((region, index) => {
                                return (
                                    <div className="form-group">
                                        <div className="row" style={{ alignItems: "center" }}>
                                            <div className="col-xs-2">
                                                <b>{region.label}</b>
                                            </div>
                                            <div className="col-xs-8">
                                                <input type="text"
                                                    id={"regionValue[" + region.id + "]"}
                                                    name={"regionValue[" + region.id + "]"}
                                                    className="form-control form-control-lg"
                                                    placeholder="Value"
                                                    defaultValue={region.value}
                                                    ref={registerEditRegion({ required: watchEditRegion("region[" + region.id + "]") ?? region.status })} />
                                                {errorsEditRegion['regionValue'] && errorsEditRegion['regionValue'][region.id] &&
                                                    <div className="invalid-feedback">
                                                        {t(getValidationMessage(errorsEditRegion['regionValue'][region.id]))}
                                                    </div>}
                                            </div>
                                            <div className="col-xs-2">
                                                <div className="switcher">
                                                    <input type="checkbox"
                                                        id={"region[" + region.id + "]"}
                                                        name={"region[" + region.id + "]"}
                                                        defaultChecked={region.status}
                                                        ref={registerEditRegion} />
                                                    <label htmlFor={"region[" + region.id + "]"}></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" className="btn btn-info" onClick={resetRegionValue}>{t('BTN_RESET')}</button>
                            <button type="submit" className="btn btn-primary">{t('BTN_SAVE')}</button>
                            <button type="button" className="btn btn-danger" 
                                onClick={() => { setShowEditRegionModal(!showEditRegionModal); }}>{t('BTN_CANCEL')}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </div>
        </div>
    )
}

export default ManageSystemSettings;