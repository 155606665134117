import React, { useState, useEffect } from 'react';
import { ApiUrl } from '../../../util/Constant';
import { getValidationMessage } from '../../../util/Util';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import moment from 'moment';
import DateTime from 'react-datetime';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { useLocation } from "react-router-dom";

/// <summary>
/// Author: Wind
/// </summary>
const UserActionLog = props => {
    const { t, i18n } = useTranslation();
    const _dispatch = useDispatch();
    const { register, handleSubmit, errors, unregister, setValue } = useForm();
    let { search } = useLocation();
    const _query = new URLSearchParams(search);
    const _USERNAME = _query.get('username');
    const [logUrl, setLogUrl] = useState('');
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

    let _operationLogTableColumns = [
        {
            Header: t("MODIFIED_DATE"),
            accessor: "createdTime",
            disableSortBy: true,
            width: 100
        },
        {
            Header: t("ACTION"),
            accessor: "actionKey",
            disableSortBy: true,
            width: '10%'
        },
        {
            Header: t("VALUE"),
            accessor: "value",
            disableSortBy: true,
            style: {
                wordBreak: 'break-word'
            },
            minWidth: 350,
            width: 350
        },
        {
            Header: t("OPERATED_BY"),
            accessor: "createdByName",
            disableSortBy: true,
            width: 50
        },
        {
            Header: t("IP_ADDRESS"),
            accessor: "ipAddress",
            disableSortBy: true,
            width: 50
        },
    ];

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: true });
        setValue('startDate', moment().format("YYYY-MM-DD"));
        setValue('endDate', moment().format("YYYY-MM-DD"));
        onSubmit();
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function onSubmit() {
        var rawUrl = ApiUrl._API_GET_OPERATION_LOG + "?Username=" + _USERNAME +
            "&StartDate=" + startDate + "&EndDate=" + endDate +
            "&v=" + moment.now();
        setLogUrl(rawUrl);
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                    <h1 className="page-header">{t("USER_ACTION_LOG") + ' (' + _USERNAME + ')'}</h1>
                </div>
                <Panel>
                    <PanelBody>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        timeFormat={false}
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD"));
                                                setValue("startDate", e.format("YYYY-MM-DD"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                    {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        timeFormat={false}
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD"));
                                                setValue("endDate", e.format("YYYY-MM-DD"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                    {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('BTN_SUBMIT')}</button>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
                <Panel>
                    <div className="row">
                        <div className="col-lg-12">
                            <PanelBody>
                                <ReactTable fetchUrl={logUrl} columns={_operationLogTableColumns} initialPageSize={500} />
                            </PanelBody>
                        </div>
                    </div>
                </Panel>
            </form>
        </div>
    );
}

export default UserActionLog;