import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ApiEngine from '../../util/ApiEngine.js';
import { useTranslation } from 'react-i18next';
import { showResponseMessage } from "../../redux/AppAction";
import { ApiKey, ApiUrl, AnnouncementType } from "../../util/Constant";
import { useDispatch } from "react-redux";
import ReactTable from "./ReactTable";
import { stringIsNullOrEmpty, createMultiPartFormBody } from '../../util/Util.js';

/// <summary>
/// Author: YJ
/// </summary>
const PreviewMessageModal = props => {
    const [modalVisibility, setModalVisibility] = useState(false);
    const [messageId, setMessageId] = useState();
    const [messageType, setMessageType] = useState();
    const [messageParams, setMessageParams] = useState({});
    const { t } = useTranslation();
    const [tableData, setTableData] = useState([]);
    const [tableColumn, setTableColumn] = useState([]);
    var _dispatch = useDispatch();

    let _shopMessageColumns = [
        {
            Header: "USERNAME",
            accessor: "username",
            Footer: ({ data }) => {
                const totalCount = data.reduce((sum, currentValue) => {
                    return sum + 1;
                }, 0);

                return <span><b>{t("TOTAL")}: {totalCount}</b></span>
            },
        },
        {
            Header: "NAME",
            accessor: "userFullName"
        },
        {
            Header: "PHONE_NUMBER",
            accessor: "phoneNumber"
        },
        {
            Header: "SHOP_NAME",
            accessor: "shopName"
        },
        {
            Header: "SHOP_USERNAME",
            accessor: "shopUsername"
        }
    ];

    let _systemMessageColumns = [
        {
            Header: "USERNAME",
            accessor: "username",
            Footer: ({ data }) => {
                const totalCount = data.reduce((sum, currentValue) => {
                    return sum + 1;
                }, 0);

                return <span><b>{t("TOTAL")}: {totalCount}</b></span>
            },
        },
        {
            Header: "NAME",
            accessor: "userFullName"
        },
        {
            Header: "PHONE_NUMBER",
            accessor: "phoneNumber"
        },
        {
            Header: "ROLE",
            accessor: "roleName"
        },
        {
            Header: "REGION",
            accessor: "regionName"
        }
    ]

    /// <summary>
    /// Author: YJ
    /// </summary>
    useEffect(() => {
        if (modalVisibility) {
            init();
        }
        else {
            cleanUp();
        }
    }, [modalVisibility])

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function init() {
        let apiUrl = ApiUrl._API_PREVIEW_ANNOUNCEMENT_TARGET;
        if (!stringIsNullOrEmpty(messageId)) {
            apiUrl += "?id=" + messageId;
        }
        var responseJson = await ApiEngine.post(apiUrl, createMultiPartFormBody(messageParams));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setTableData(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(props.id)) {
            setMessageId(props.id);
        }

        if (!stringIsNullOrEmpty(props.params)) {
            setMessageParams(props.params);
        }

        setMessageType(props.messageType);

        if (props.messageType == AnnouncementType._ANNOUNCEMENT_TYPE_SHOP) {
            setTableColumn(_shopMessageColumns);
        }
        else {
            setTableColumn(_systemMessageColumns);
        }
        setModalVisibility(props["visible"]);
    }, [props["visible"]])

    /// <summary>
    /// Author: YJ
    /// </summary>
    function cleanUp() {
        setMessageId("");
        setMessageParams({});
        setMessageType("");
        setTableData([]);
        props.onFinish();
    }

    return (
        <Modal isOpen={modalVisibility} toggle={cleanUp} size={"xl"}>
            <ModalHeader toggle={cleanUp}>{t('PREVIEW_TARGET')}</ModalHeader>
            <ModalBody>
                <ReactTable data={tableData} columns={tableColumn} renderFooter={true} />
            </ModalBody>
        </Modal>
    );
}
export default PreviewMessageModal;