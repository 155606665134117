import { _UPDATE_CONTENT, _UPDATE_REQUEST_CONTENT } from './FirebaseAction.js';

const _INITIAL_STATE = {
    dataContent: {},
    requestCode: ""
};

/// <summary>
/// Author: Nelson
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case _UPDATE_CONTENT:
            return {
                ...state,
                dataContent: action.dataContent
            }
            break;
        case _UPDATE_REQUEST_CONTENT:
            return {
                ...state,
                requestCode: action.requestCode
            }
            break;
        default:
            return state;
            break;
    }
};