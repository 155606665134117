import React, { useEffect, useState, useRef } from 'react';
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import { ApiKey, ApiUrl, AlertTypes, Role, Status } from '../../util/Constant';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useForm } from 'react-hook-form';
import { stringIsNullOrEmpty, ignoreDecimal } from '../../util/Util';

/// <summary>
/// Author: YJ
/// </summary>
const AccountStatusModal = props => {
    const { register, watch } = useForm();
    const [modalVisibility, setModalVisibility] = useState(false);
    const { t } = useTranslation();
    const [detail, setDetail] = useState({});

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        setModalVisibility(props["visible"]);
        setDetail(props["detail"]);
    }, [props["visible"]]);

    /// <summary>
    /// Author: YJ
    /// </summary>
    useEffect(() => {
        if (!modalVisibility) {
            cleanUp(false);
        }
    }, [modalVisibility])

    /// <summary>
    /// Author: YJ
    /// </summary>
    function cleanUp(isConfirm) {
        props.onFinish(isConfirm, (detail.toStatus != Status._ENABLED ? watch('remark') : ""));
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    const renderMessageContent = (fromStatus, toStatus) => {
        let returnContent = null;

        if(toStatus != Status._ENABLED) {
            returnContent = (
                <div>
                    {toStatus == Status._DISABLED ? t("ARE_YOU_SURE_YOU_WANT_TO_BLOCK_THIS_USER") : t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_USER")}
                    <input className="form-control" style={{ marginTop: "10px", textAlign: "center" }} placeholder={t("REMARK")} type="text" name="remark" ref={register} />
                </div>
            )
        }
        else {
            returnContent = (
                <div>
                    {fromStatus == Status._DISABLED ? t("ARE_YOU_SURE_YOU_WANT_TO_UNBLOCK_THIS_USER") : t("ARE_YOU_SURE_YOU_WANT_TO_RECOVER_THIS_USER")}
                    <br />
                    {!stringIsNullOrEmpty(detail.remark) ? <div style={{ marginTop: "5px", fontSize: "16px", fontWeight: "700" }}>{"Remark: " + detail.remark}</div> : ""}
                </div>
            )
        }
        return returnContent;

    }

    return (
        <div className="sweet-alert-container">
            {modalVisibility && <SweetAlert
                type={AlertTypes._WARNING}
                title={t("ARE_YOU_SURE")}
                onConfirm={() => { cleanUp(true) }}
                onCancel={() => { cleanUp(false) }}
                cancelBtnBsStyle={"danger"}
                reverseButtons={true}
                allowEscape={false}
                showCancel={true}>
                <form>
                    {renderMessageContent(detail.fromStatus, detail.toStatus)}
                </form>
            </SweetAlert>}
        </div>
    );
}
export default AccountStatusModal;