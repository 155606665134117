import React from 'react';
import { PageSettings } from './config/page-settings.js';
import Header from './components/header/header.jsx';
import ShopHeader from './components/header/shop-header';
import Sidebar from './components/sidebar/sidebar.jsx';
import Content from './components/content/content.jsx';
import Footer from './components/footer/footer.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from "react-redux";
import { ApiKey, ApiUrl, LoginType, Role, SessionKey, WebUrl, Theme } from "./util/Constant";
import { updateLoginUser, resetLogin, performLogout } from "./redux/AuthAction.js";
import { fetch } from 'whatwg-fetch';
import classNames from 'classnames';
import { isObjectEmpty, stringIsNullOrEmpty } from './util/Util.js';
import SearchMemberModal from './components/custom/SearchMemberModal';
import EpayLogo from './assets/img/logo/epay.png';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.toggleSidebarMinify = (e) => {
            e.preventDefault();
            if (this.state.pageSidebarMinify) {
                this.setState(state => ({
                    pageFloatSubMenuActive: false
                }));
            }
            this.setState(state => ({
                pageSidebarMinify: !this.state.pageSidebarMinify
            }));
        }
        this.toggleMobileSidebar = (e) => {
            this.setState(state => ({
                pageSidebarToggled: !this.state.pageSidebarToggled
            }));
        }
        this.handleSetPageSidebar = (value) => {
            this.setState(state => ({
                pageSidebar: value
            }));
        }
        this.handleSetPageSidebarMinified = (value) => {
            this.setState(state => ({
                pageSidebarMinify: value
            }));
        }
        this.handleSetPageSidebarWide = (value) => {
            this.setState(state => ({
                pageSidebarWide: value
            }));
        }
        this.handleSetPageSidebarLight = (value) => {
            this.setState(state => ({
                pageSidebarLight: value
            }));
        }
        this.handleSetPageSidebarTransparent = (value) => {
            this.setState(state => ({
                pageSidebarTransparent: value
            }));
        }
        this.handleSetPageSidebarSearch = (value) => {
            this.setState(state => ({
                pageSidebarSearch: value
            }));
        }

        this.toggleRightSidebar = (e) => {
            e.preventDefault();
            this.setState(state => ({
                pageRightSidebarCollapsed: !this.state.pageRightSidebarCollapsed
            }));
        }
        this.toggleMobileRightSidebar = (e) => {
            e.preventDefault();
            this.setState(state => ({
                pageMobileRightSidebarToggled: !this.state.pageMobileRightSidebarToggled
            }));
        }
        this.handleSetPageRightSidebar = (value) => {
            this.setState(state => ({
                pageRightSidebar: value
            }));
        }

        var floatSubMenuRemove;
        var floatSubMenuCalculate;
        var floatSubMenuRemoveTime = 250;
        this.handleFloatSubMenuOnMouseOver = (e) => {
            clearTimeout(floatSubMenuRemove);
            clearTimeout(floatSubMenuCalculate);
        }
        this.handleFloatSubMenuOnMouseOut = (e) => {
            floatSubMenuRemove = setTimeout(() => {
                this.setState(state => ({
                    pageFloatSubMenuActive: false
                }));
            }, floatSubMenuRemoveTime);
        }
        this.handleSidebarOnMouseOver = (e, menu) => {
            if (this.state.pageSidebarMinify) {
                if (menu.children) {
                    var left = (document.getElementById('sidebar').offsetWidth + document.getElementById('sidebar').offsetLeft) + 'px';

                    clearTimeout(floatSubMenuRemove);
                    clearTimeout(floatSubMenuCalculate);

                    this.setState(state => ({
                        pageFloatSubMenu: menu,
                        pageFloatSubMenuActive: true,
                        pageFloatSubMenuLeft: left
                    }));

                    var offset = e.currentTarget.offsetParent.getBoundingClientRect();

                    floatSubMenuCalculate = setTimeout(() => {
                        var targetTop = offset.top;
                        var windowHeight = window.innerHeight;
                        var targetHeight = document.querySelector('.float-sub-menu-container').offsetHeight;
                        var top, bottom, arrowTop, arrowBottom, lineTop, lineBottom;

                        if ((windowHeight - targetTop) > targetHeight) {
                            top = offset.top + 'px';
                            bottom = 'auto';
                            arrowTop = '20px';
                            arrowBottom = 'auto';
                            lineTop = '20px';
                            lineBottom = 'auto';
                        } else {
                            var aBottom = (windowHeight - targetTop) - 21;
                            top = 'auto';
                            bottom = '0';
                            arrowTop = 'auto';
                            arrowBottom = aBottom + 'px';
                            lineTop = '20px';
                            lineBottom = aBottom + 'px';
                        }

                        this.setState(state => ({
                            pageFloatSubMenuTop: top,
                            pageFloatSubMenuBottom: bottom,
                            pageFloatSubMenuLineTop: lineTop,
                            pageFloatSubMenuLineBottom: lineBottom,
                            pageFloatSubMenuArrowTop: arrowTop,
                            pageFloatSubMenuArrowBottom: arrowBottom,
                            pageFloatSubMenuOffset: offset
                        }));
                    }, 0);

                } else {
                    floatSubMenuRemove = setTimeout(() => {
                        this.setState(state => ({
                            pageFloatSubMenu: '',
                            pageFloatSubMenuActive: false
                        }));
                    }, floatSubMenuRemoveTime);
                }
            }
        }
        this.handleSidebarOnMouseOut = (e) => {
            if (this.state.pageSidebarMinify) {
                floatSubMenuRemove = setTimeout(() => {
                    this.setState(state => ({
                        pageFloatSubMenuActive: false
                    }));
                }, floatSubMenuRemoveTime);
            }
        }
        this.handleFloatSubMenuClick = () => {
            if (this.state.pageSidebarMinify) {
                const windowHeight = window.innerHeight;
                const targetHeight = document.getElementById('float-sub-menu').offsetHeight;
                const targetTop = this.state.pageFloatSubMenuOffset.top;
                const top = ((windowHeight - targetTop) > targetHeight) ? targetTop : 'auto';
                const left = (this.state.pageFloatSubMenuOffset.left + document.getElementById('sidebar').offsetWidth) + 'px';
                const bottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : '0';
                const arrowTop = ((windowHeight - targetTop) > targetHeight) ? '20px' : 'auto';
                const arrowBottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : ((windowHeight - targetTop) - 21) + 'px';
                const lineTop = ((windowHeight - targetTop) > targetHeight) ? '20px' : 'auto';
                const lineBottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : ((windowHeight - targetTop) - 21) + 'px';

                this.setState(state => ({
                    pageFloatSubMenuTop: top,
                    pageFloatSubMenuLeft: left,
                    pageFloatSubMenuBottom: bottom,
                    pageFloatSubMenuLineTop: lineTop,
                    pageFloatSubMenuLineBottom: lineBottom,
                    pageFloatSubMenuArrowTop: arrowTop,
                    pageFloatSubMenuArrowBottom: arrowBottom
                }));
            }
        }

        this.handleSetPageContent = (value) => {
            this.setState(state => ({
                pageContent: value
            }));
        }
        this.handleSetPageContentClass = (value) => {
            this.setState(state => ({
                pageContentClass: value
            }));
        }
        this.handleSetPageContentFullHeight = (value) => {
            this.setState(state => ({
                pageContentFullHeight: value
            }));
        }
        this.handleSetPageContentFullWidth = (value) => {
            this.setState(state => ({
                pageContentFullWidth: value
            }));
        }
        this.handleSetPageContentInverseMode = (value) => {
            this.setState(state => ({
                pageContentInverseMode: value
            }));
        }

        this.handleSetPageHeader = (value) => {
            this.setState(state => ({
                pageHeader: value
            }));
        }
        this.handleSetPageHeaderMegaMenu = (value) => {
            this.setState(state => ({
                pageHeaderMegaMenu: value
            }));
        }
        this.handleSetPageHeaderLanguageBar = (value) => {
            this.setState(state => ({
                pageHeaderLanguageBar: value
            }));
        }

        this.handleSetPageFooter = (value) => {
            this.setState(state => ({
                pageFooter: value
            }));
        }
        this.handleSetPageTopMenu = (value) => {
            this.setState(state => ({
                pageTopMenu: value
            }));
        }
        this.toggleMobileTopMenu = (e) => {
            e.preventDefault();
            this.setState(state => ({
                pageMobileTopMenu: !this.state.pageMobileTopMenu
            }));
        }
        this.handleSetPageTwoSidebar = (value) => {
            this.setState(state => ({
                pageTwoSidebar: value
            }));
        }
        this.handleSetPageBoxedLayout = (value) => {
            if (value === true) {
                document.body.classList.add('boxed-layout');
            } else {
                document.body.classList.remove('boxed-layout');
            }
        }
        this.handleSetBodyWhiteBg = (value) => {
            if (value === true) {
                document.body.classList.add('bg-white');
            } else {
                document.body.classList.remove('bg-white');
            }
        }

        this.state = {
            pageHeader: true,
            pageheaderMegaMenu: false,
            pageHeaderLanguageBar: true,
            handleSetPageHeader: this.handleSetPageHeader,
            handleSetPageHeaderLanguageBar: this.handleSetPageHeaderLanguageBar,
            handleSetPageHeaderMegaMenu: this.handleSetPageHeaderMegaMenu,

            pageSidebar: true,
            pageSidebarWide: false,
            pageSidebarLight: false,
            pageSidebarMinify: false,
            pageSidebarToggled: false,
            pageSidebarTransparent: false,
            pageSidebarSearch: false,
            handleSetPageSidebar: this.handleSetPageSidebar,
            handleSetPageSidebarWide: this.handleSetPageSidebarWide,
            handleSetPageSidebarLight: this.handleSetPageSidebarLight,
            handleSetPageSidebarMinified: this.handleSetPageSidebarMinified,
            handleSetPageSidebarTransparent: this.handleSetPageSidebarTransparent,
            handleSetPageSidebarSearch: this.handleSetPageSidebarSearch,
            handleSidebarOnMouseOut: this.handleSidebarOnMouseOut,
            handleSidebarOnMouseOver: this.handleSidebarOnMouseOver,
            toggleSidebarMinify: this.toggleSidebarMinify,
            toggleMobileSidebar: this.toggleMobileSidebar,

            pageFloatSubMenuActive: false,
            pageFloatSubMenu: '',
            pageFloatSubMenuTop: 'auto',
            pageFloatSubMenuLeft: 'auto',
            pageFloatSubMenuBottom: 'auto',
            pageFloatSubMenuLineTop: 'auto',
            pageFloatSubMenuLineBottom: 'auto',
            pageFloatSubMenuArrowTop: 'auto',
            pageFloatSubMenuArrowBottom: 'auto',
            pageFloatSubMenuOffset: '',
            handleFloatSubMenuOnMouseOver: this.handleFloatSubMenuOnMouseOver,
            handleFloatSubMenuOnMouseOut: this.handleFloatSubMenuOnMouseOut,
            handleFloatSubMenuClick: this.handleFloatSubMenuClick,

            pageContent: true,
            pageContentClass: '',
            pageContentFullHeight: false,
            pageContentFullWidth: false,
            pageContentInverseMode: false,
            handleSetPageContent: this.handleSetPageContent,
            handleSetPageContentClass: this.handleSetPageContentClass,
            handleSetPageContentFullHeight: this.handleSetPageContentFullHeight,
            handleSetPageContentFullWidth: this.handleSetPageContentFullWidth,
            handleSetPageContentInverseMode: this.handleSetPageContentInverseMode,

            pageFooter: false,
            handleSetPageFooter: this.handleSetPageFooter,
            error: null,
            errorInfo: null,

            pageTopMenu: false,
            pageMobileTopMenu: false,
            toggleMobileTopMenu: this.toggleMobileTopMenu,
            handleSetPageTopMenu: this.handleSetPageTopMenu,

            pageTwoSidebar: false,
            handleSetPageTwoSidebar: this.handleSetPageTwoSidebar,

            pageRightSidebar: false,
            pageRightSidebarToggled: true,
            pageMobileRightSidebarToggled: false,
            toggleRightSidebar: this.toggleRightSidebar,
            toggleMobileRightSidebar: this.toggleMobileRightSidebar,
            handleSetPageRightSidebar: this.handleSetPageRightSidebar,

            handleSetBodyWhiteBg: this.handleSetBodyWhiteBg,
            handleSetPageBoxedLayout: this.handleSetPageBoxedLayout
        };
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    componentDidMount() {
        const HEART_BEAT_INTERVAL = 2000;

        if (localStorage.getItem(SessionKey._LOGIN_GUID)) {
            sessionStorage.setItem(SessionKey._LOGIN_GUID, localStorage.getItem(SessionKey._LOGIN_GUID));
        }

        setInterval(function () {
            var localGuid = localStorage.getItem(SessionKey._LOGIN_GUID);
            var sessionGuid = sessionStorage.getItem(SessionKey._LOGIN_GUID);

            if (localGuid != sessionGuid && localGuid != null && localGuid != 'null' && sessionGuid != null && sessionGuid != 'null') {
                sessionStorage.setItem(SessionKey._LOGIN_GUID, localGuid);
                window.location.href = WebUrl._URL_MAIN;
            }
        }, HEART_BEAT_INTERVAL);

        fetch(ApiUrl._API_IS_LOGGED_IN, {
            method: ApiKey._API_GET,
            headers: {
                "Accept": ApiKey._API_APPLICATION_JSON,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
            }
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                this.props.updateLoginUser(responseJson[ApiKey._API_DATA_KEY]);
            }
            else {
                this.props.resetLogin();
            }
        });
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// Edited: CK - Get shop credit limit
    /// </summary>
    componentDidUpdate() {
        try {
            if (this.props.authState.userData.loginType == LoginType._LOGIN_TYPE_SHOP) {
                document.body.classList.add('shop-layout');
            }
            else {
                document.body.classList.remove('shop-layout');
            }
        }
        catch (error) {
            // do nothing
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div className="error" style={{ "padding": "20px" }}>
                    <img src={require("./assets/img/error.png")} style={{ "maxWidth": "100px", "objectFit": "contain", "marginLeft": "auto", "marginRight": "auto" }} />
                    <h3>Something went wrong, please contact admin for help</h3>
                    <div className="m-b-30">
                        {window.navigator.userAgent}
                    </div>
                </div>
            );
        }
        else {
            return (
                <PageSettings.Provider value={this.state}>
                    <LoadingOverlay
                        active={this.props.appState.isBusy || !this.props.authState.loginStateInitialized}
                        spinner={this.props.appState.iconEnabled && process.env.REACT_APP_NAME == Theme._EPAY ?
                            <img src={EpayLogo} style={{ height: "120px", display: "block", margin: "5px", animation: "rotation 1.5s infinite linear" }} />
                            : true}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                zIndex: '10000',
                                position: 'fixed',
                            })
                        }}
                        text={this.props.appState.message}
                    >
                    </LoadingOverlay>
                    {
                        !isObjectEmpty(this.props.appState.alert) && <div className="sweet-alert-container"><SweetAlert {...this.props.appState.alert}>
                            {!stringIsNullOrEmpty(this.props.appState.alert.content) && (this.props.appState.alert.html ? this.props.appState.alert.content : this.props.appState.alert.content.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br /></span>
                            }))}
                        </SweetAlert>
                        </div>
                    }
                    {this.props.authState.loginStateInitialized && this.props.authState.isLoggedIn && this.props.authState.userData.userRoleId == Role._SHOP &&
                        <button onClick={() => { this.props.performLogout(true); }} className="emergency-button">
                            <i className="fa fa-phone"></i>
                        </button>
                    }
                    {
                        this.props.memberState.isModalVisible && this.props.authState.userData.userRoleId == Role._SHOP &&
                        <SearchMemberModal isModalVisible={this.props.memberState.isModalVisible} targetUrl={this.props.memberState.targetUrl} isSearchAgent={this.props.memberState.isSearchAgent} />
                    }
                    {
                        this.props.authState.loginStateInitialized &&
                        <div className={classNames(
                            'fade page-sidebar-fixed show page-container', {
                            'page-header-fixed': this.state.pageHeader,
                            'page-without-sidebar': !this.state.pageSidebar,
                            'page-with-right-sidebar': this.state.pageRightSidebar,
                            'page-with-wide-sidebar': this.state.pageSidebarWide,
                            'page-with-light-sidebar': this.state.pageSidebarLight,
                            'page-sidebar-minified': this.state.pageSidebarMinify,
                            'page-sidebar-toggled': this.state.pageSidebarToggled,
                            'page-with-top-menu': this.state.pageTopMenu,
                            'page-content-full-height': this.state.pageContentFullHeight,
                            'page-with-two-sidebar': this.state.pageTwoSidebar,
                            'page-right-sidebar-collapsed': this.state.pageRightSidebarCollapsed,
                            'page-right-sidebar-toggled': this.state.pageMobileRightSidebarToggled
                        })
                        }>
                            {this.state.pageHeader && (this.props.authState.userData.userRoleId == Role._SHOP ? <ShopHeader creditLimit={this.props.authState.creditLimit} /> : <Header />)}
                            {this.state.pageSidebar && (<Sidebar />)}
                            <div onClick={() => {
                                if (this.state.pageSidebarToggled) {
                                    this.toggleMobileSidebar();
                                }
                            }}>                           
                                {this.state.pageContent && (<Content />)}
                                {this.state.pageFooter && (<Footer />)}
                            </div>
                        </div>
                    }
                </PageSettings.Provider>
            )
        }
    }
}

const mapStateToProps = state => ({
    ...state,
});

export default connect(
    mapStateToProps,
    {
        updateLoginUser: updateLoginUser,
        resetLogin: resetLogin,
        performLogout: performLogout
    }
)(App);