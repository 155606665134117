import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelBody,
} from "../../components/panel/panel.jsx";
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import ReactTable from "../../components/custom/ReactTable";
import { ApiUrl } from "../../util/Constant";
import { stringIsNullOrEmpty } from "../../util/Util";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { TransactionType, Status } from '../../util/Constant';

/// <summary>
/// Author : CK
/// </summary>
const Report = props => {
    const _MAX_ROW_RETRIEVAL = 20;
    const { register, handleSubmit, setValue } = useForm();
    const { t } = useTranslation();
    const [postData, setPostData] = useState({});
    const [apiUrl, setApiUrl] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const _userData = useSelector(state => state.authState.userData);

    const _TYPE_OPTION = {
        ALL: -1,
        IN: 0,
        OUT: 1,
    };

    let _tableColumns = [
        {
            Header: "DATE",
            accessor: "createdTime",
            Footer: () => {
                return <span style={{ float: 'right' }}><b>{t('TOTAL')}: </b></span>
            },
            disableSortBy: true
        },
        {
            Header: "OPERATED_BY",
            accessor: "operatedBy",
            disableSortBy: true
        },
        {
            Header: "TARGET_SHOP",
            accessor: "operatedShop",
            disableSortBy: true
        },
        {
            Header: "PHONE_NUMBER",
            accessor: "phoneNumber",
            disableSortBy: true
        },
        {
            Header: "SERIAL_NUMBER",
            accessor: "playerId",
            disableSortBy: true
        },
        {
            Header: "VENDOR",
            accessor: "vendor",
            disableSortBy: true
        },
        {
            Header: "TRANSACTION_TYPE",
            accessor: "transactionType",
            Cell: ({ row }) => {
                return renderTransactionType(row.original.transactionType);
            },
            disableSortBy: true
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                return row.original.status ? <span className="badge badge-green">{t("SUCCESS")}</span> : <span className="badge badge-danger">{t("FAILED")}</span>;
            },
            disableSortBy: true
        },
        {
            Header: "TYPE",
            accessor: "type",
            Cell: ({ row }) => {
                return row.original.type[0];
            },
            disableSortBy: true
        },
        {
            Header: "INITIAL_LIMIT",
            accessor: "shopInitialLimit",
            Footer: ({ page }) => {
                const initialLimitTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.shopInitialLimit);
                }, 0);

                return <span><b>{initialLimitTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "AMOUNT",
            accessor: "amount",
            Cell: ({ row }) => {
                return row.original.amount > 0 ? <span className="text-success">{row.original.amount}</span> : <span className="text-danger">{row.original.amount}</span>;
            },
            Footer: ({ page }) => {
                const scoreTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.amount);
                }, 0);

                return <span><b>{scoreTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "FINAL_LIMIT",
            accessor: "shopFinalLimit",
            Footer: ({ page }) => {
                const finalLimitTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.shopFinalLimit);
                }, 0);

                return <span><b>{finalLimitTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true
        }
    ]

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: true });
        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
        handleSubmit(submitForm)();
    }, []);

    /// <summary>
    /// Author : CK
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "OperatedBy": [_userData.userId],
            "Type": parseInt(data.type),
            "TransactionType": _TYPE_OPTION.ALL,
            "Status": parseInt(data.status),
            "RowLimit": _MAX_ROW_RETRIEVAL
        };

        if (!stringIsNullOrEmpty(data.playerId)) {
            params["PlayerId"] = data.playerId;
        }

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_SET_SCORE_REPORT);
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    function isValidDate(selectedDate) {
        return selectedDate.format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD");
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    function renderTransactionType(transactionType) {
        let transactionTypeString = "";

        switch (transactionType) {
            case TransactionType._BONUS_CLAIM:
                transactionTypeString = t("BONUS");
                break;
            case TransactionType._REWARD_CLAIM:
                transactionTypeString = t("REWARD");
                break;
            case TransactionType._SET_LIMIT:
                transactionTypeString = t("LIMIT");
                break;
            case TransactionType._SET_SCORE:
                transactionTypeString = t("RELOAD");
                break;
            case TransactionType._WALLET_TRANSFER:
                transactionTypeString = t("CREDIT");
                break;
            case TransactionType._SEAMLESS_SET_CREDIT:
                transactionTypeString = t("SEAMLESS_SET_CREDIT");
                break;
            case TransactionType._ONLINE_WITHDRAWAL:
                transactionTypeString = t("ONLINE_WITHDRAWAL");
                break;
        }

        return <span className="badge badge-primary">{transactionTypeString}</span>
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable className="col-nowrap" columns={_tableColumns} fetchUrl={apiUrl} renderFooter={true}
        postData={postData} customnPageSizeOptions={[_MAX_ROW_RETRIEVAL]} initialPageSize={_MAX_ROW_RETRIEVAL} />, [apiUrl, postData])

    return (
        <div>
            <h1 className="page-header">{t('REPORT')}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("PLAYER_ID")}</label>
                                    <input type="text" name="playerId" ref={register} className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("TYPE")}</label>
                                    <select name="type" ref={register} className="form-control form-control-lg">
                                        <option value={_TYPE_OPTION.ALL}>{t("ALL")}</option>
                                        <option value={_TYPE_OPTION.IN}>{t("IN")}</option>
                                        <option value={_TYPE_OPTION.OUT}>{t("OUT")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("STATUS")}</label>
                                    <select name="status" ref={register} className="form-control form-control-lg">
                                        <option value={_TYPE_OPTION.ALL}>{t("ALL")}</option>
                                        <option value={Status._ENABLED}>{t("SUCCESS")}</option>
                                        <option value={Status._DISABLED}>{t("FAILED")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        isValidDate={isValidDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        isValidDate={isValidDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    )
}

export default Report;