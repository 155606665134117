import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { navigateTo, numberWithCurrencyFormat } from "../../../util/Util";
import NavigationButton from '../../../components/custom/NavigationButton';
import { WebUrl, ApiKey, ApiUrl, AccessRight, AlertTypes } from '../../../util/Constant';
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage, showMessage } from "../../../redux/AppAction";
import ApiEngine from '../../../util/ApiEngine.js';
import RankVendorRateModal from '../../../components/custom/RankVendorRateModal';

/// <summary>
/// Author : Wind
/// </summary>
const ManageRank = props => {
    var _history = useHistory();
    var { t, i18n } = useTranslation();
    const { register, handleSubmit, setValue } = useForm();
    var _dispatch = useDispatch();
    const [rankList, setRankList] = useState([]);
    const [isRateModalVisible, setIsRateModalVisible] = useState(false);
    let _rankColumn = useMemo(() => [
        {
            Header: "NAME",
            accessor: "localizations",
            Cell: ({ row }) => {
                return <div>{row.original.localizations.map((item) => { return item.value }).join(" / ")}</div>
            }
        },
        {
            Header: "COLOR_CODE",
            accessor: "colorCode",
            Cell: ({ row }) => {
                return <div style={{ backgroundColor: row.original.colorCode, width: '35%' }}>&nbsp;</div>
            }
        },
        {
            Header: "OUTER_FRAME",
            accessor: "outerFrame",
            Cell: ({ row }) => {
                return row.original.outerFrame ? <img class="mini-program-logo" src={row.original.outerFrame} /> : <div></div>
            }
        },
        {
            Header: "INNER_FRAME",
            accessor: "innerFrame",
            Cell: ({ row }) => {
                return row.original.innerFrame ? <img class="mini-program-logo" src={row.original.innerFrame} /> : <div></div>
            }
        },
        {
            Header: "BADGE",
            accessor: "badgeFile",
            Cell: ({ row }) => {
                return row.original.badgeFile ? <img class="mini-program-logo" src={row.original.badgeFile} /> : <div></div>
            }
        },
        {
            Header: "MINIMUM_EXPERIENCE",
            accessor: "minimumExperience",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.minimumExperience, 3)
            }
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._RANK_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                _history.push({
                                    pathname: WebUrl._URL_ADMIN_RANK_DETAIL,
                                    state: { id: row.original.id }
                                })
                            }}>
                                <span>{t('EDIT_RANK_DETAILS')}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ], [rankList]);
  
    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        (async () => {
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_RANK);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setRankList(responseJson[ApiKey._API_DATA_KEY].data);
            }
        })();
    }, []);

    return (
        <div id="page-content">
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_RANK")}</h1>
                {
                    checkIfPermissionExist(AccessRight._RANK_CONTROL_PERMISSION, true) &&
                    <div>
                        <NavigationButton history={_history} url={WebUrl._URL_ADMIN_RANK_DETAIL} buttonText={t("CREATE_RANK")} />
                        <button type="button"
                            className={"btn btn-primary m-r-10"}
                            onClick={() => {
                                setIsRateModalVisible(true);
                            }}
                        >
                            <i className={"fas fa-percent m-r-10 f-s-12"}></i>{t("RANK_VENDOR_RATE")}
                        </button>
                    </div>
                }
            </div>
            <RankVendorRateModal
                isModalVisible={isRateModalVisible}
                onFinish={() => setIsRateModalVisible(false)}
                rankList={rankList}
            />
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <ReactTable data={rankList} columns={_rankColumn} filterable={false} />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div>
    );
}

export default ManageRank;