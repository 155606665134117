import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelBody,
} from "./../../components/panel/panel.jsx";
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import ReactTable from "./../../components/custom/ReactTable";
import { setBusy, setIdle } from './../../redux/AppAction';
import { ApiKey, ApiUrl, Role, CommonStatus } from "./../../util/Constant";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "./../../util/Util";
import { showResponseMessage, showMessage } from '../../redux/AppAction';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ApiEngine from '../../util/ApiEngine.js';

/// <summary>
/// Author : CK
/// </summary>
const ShopLimitApprovalReport = props => {
    var _userData = useSelector(state => state.authState.userData);
    var _dispatch = useDispatch();
    const { register, handleSubmit, setValue } = useForm();
    const { t } = useTranslation();
    const [apiUrl, setApiUrl] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));

    let _tableColumns = [
        {
            Header: t("DATE"),
            accessor: "date",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{moment(row.original.date).format("YYYY-MM-DD HH:mm:ss")}</div>
            }
        },
        {
            Header: t("MANAGER"),
            accessor: "managerName",
            disableSortBy: true
        },
        {
            Header: t("SHOP"),
            accessor: "shopName",
            disableSortBy: true
        },
        {
            Header: t("SET_LIMIT"),
            accessor: "shopLimit",
            disableSortBy: true,
            Cell: ({ value }) => {
                return numberWithCurrencyFormat(value);
            },
        },
        {
            Header: t("STATUS"),
            accessor: "status",
            Cell: ({ row }) => {
                let rowValue = row.original;
                return rowValue.status == CommonStatus._PENDING_STATUS ? <span className="badge badge-warning">{t(rowValue.statusName)}</span>
                    : rowValue.status == CommonStatus._APPROVE_STATUS ? <span className="badge badge-green">{t(rowValue.statusName)}</span> : <span className="badge badge-danger">{t(rowValue.statusName)}</span>;
            },
            disableSortBy: true
        },
        {
            Header: t("ACTION"),
            id: "action",
            Cell: ({ row }) => (
                (row.original.status == CommonStatus._PENDING_STATUS && _userData.userRoleId == Role._SHOP) &&
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    title: t("ARE_YOU_SURE"),
                                    content: t("APPROVE_SET_SHOP_LIMIT_REQUEST_CONFIRMATION"),
                                    showCancel: true,
                                    onConfirm: async () => {
                                        await approveOrRejectLimitRequest(CommonStatus._APPROVE_STATUS);
                                    }
                                }));
                            }}><span className="text-warning">{t("APPROVE")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    title: t("ARE_YOU_SURE"),
                                    content: t("REJECT_SET_SHOP_LIMIT_REQUEST_CONFIRMATION"),
                                    showCancel: true,
                                    onConfirm: async () => {
                                        await approveOrRejectLimitRequest(CommonStatus._REJECT_STATUS);
                                    }
                                }));
                            }}><span className="text-warning">{t("REJECT")}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ]

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: true });
        register({ name: 'status' });

        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('status', "");
        handleSubmit(submitForm)();
    }, []);

    /// <summary>
    /// Author : CK
    /// </summary>
    const submitForm = async (data, e) => {
        let apiUrl = ApiUrl._API_GET_SHOP_LIMIT_REQUEST_LOGS + "?v=" + Date.now();

        if (_userData.userRoleId != Role._SHOP) {
            apiUrl += "?StartDate=" + data.startDate + "&EndDate=" + data.endDate;

            if (!stringIsNullOrEmpty(data.status)) {
                apiUrl += "&Status=" + data.status;
            }
        }

        setApiUrl(apiUrl);
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable
        className="col-nowrap"
        columns={_tableColumns}
        fetchUrl={apiUrl}
        exportRequired={true}
        initialPageSize={_userData.userRoleId == Role._SHOP ? 1 : 10}
        isHidePagination={_userData.userRoleId == Role._SHOP} />, [apiUrl]);

    /// <summary>
    /// Author : CK
    /// </summary>
    async function approveOrRejectLimitRequest(status) {
        var responseJson = await ApiEngine.post(ApiUrl._API_APPROVE_OR_REJECT_SHOP_LIMIT_REQUEST + "?status=" + status);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleSubmit(submitForm)();
        }
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    return (
        <div>
            <h1 className="page-header">{t("SHOP_LIMIT_APPROVAL_REPORT")}</h1>
            {
                _userData.userRoleId != Role._SHOP && 
                <Panel>
                    <PanelBody>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <div className="row m-b-10">
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>{t("START_DATE")}</label>
                                        <DateTime
                                            inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                            name="startDate"
                                            ref={register}
                                            timeFormat="HH:mm:ss"
                                            dateFormat="YYYY-MM-DD"
                                            closeOnSelect={true}
                                            value={startDate}
                                            onChange={(e) => {
                                                if (e instanceof moment) {
                                                    setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                    setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                                }
                                                else {
                                                    setValue("startDate", "");
                                                }
                                            }} />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>{t("END_DATE")}</label>
                                        <DateTime
                                            inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                            name="endDate"
                                            ref={register}
                                            timeFormat="HH:mm:ss"
                                            dateFormat="YYYY-MM-DD"
                                            closeOnSelect={true}
                                            value={endDate}
                                            onChange={(e) => {
                                                if (e instanceof moment) {
                                                    setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                    setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                                }
                                                else {
                                                    setValue("endDate", "");
                                                }
                                            }} />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>{t("STATUS")}</label>
                                        <select name="status" ref={register} className="form-control form-control-lg">
                                            <option value={""}>{t("ALL")}</option>
                                            <option value={CommonStatus._PENDING_STATUS}>{t("PENDING")}</option>
                                            <option value={CommonStatus._APPROVE_STATUS}>{t("SUCCESS")}</option>
                                            <option value={CommonStatus._REJECT_STATUS}>{t("FAILED")}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                                </div>
                            </div>
                        </form>
                    </PanelBody>
                </Panel>
            }
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
};

export default ShopLimitApprovalReport;