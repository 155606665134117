import React, { useState, useMemo, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { isObjectEmpty, createFormBody, stringIsNullOrEmpty } from '../../util/Util';
import { ApiUrl, ApiKey, Role, AlertTypes, Status } from '../../util/Constant';
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import { showMessage } from "redux/AppAction";
import { useForm } from "react-hook-form";

/// <summary>
/// Author: Wind
/// </summary>
const VipMemberModal = ({ selectedEvent = {}, onFinish, isEdit = false }) => {
    const _dispatch = useDispatch();
    const { t } = useTranslation();
    const { register, handleSubmit, errors } = useForm();
    const {
        register: registerMember,
        handleSubmit: handleSubmitMember,
        errors: errorsMember,
        reset: resetMember
    } = useForm();
    const [selectedVip, setSelectedVip] = useState({});

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        if (isObjectEmpty(selectedEvent)) {
            setSelectedVip({});
            resetMember('rank');
            resetMember('member');
        }
        else if (isEdit) {
            setSelectedVip({
                id: selectedEvent.userId,
                isUserBotActive: selectedEvent.isUserBotActive,
                username: selectedEvent.username,
                rank: selectedEvent.rank
            })
        }
    }, [selectedEvent])

    /// <summary>
    /// Author: Wind
    /// </summary>
    const onSubmit = async (data) => {
        try {
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS + "?RoleId=" + Role._MEMBER +
                "&Username=" + data.targetMember);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
            else if (responseJson[ApiKey._API_DATA_KEY].length != 1) {
                throw 'NO_DATA_FOUND';
            }

            setSelectedVip(responseJson[ApiKey._API_DATA_KEY][0]);
        }
        catch (err) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(err)
            }));
        }
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    const onMemberSubmit = async (data) => {
        try {
            let params = {
                UserId: selectedVip.id,
                EventId: selectedEvent.id,
                Rank: data.rank,
                Status: data.status ? Status._ENABLED : Status._DISABLED
            }

            if (!stringIsNullOrEmpty(selectedEvent.botId)) {
                params['id'] = selectedEvent.botId;
            }

            var responseJson = await ApiEngine.post(ApiUrl._API_KOG_BOT_MEMBER, createFormBody(params));

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _dispatch(showMessage({
                type: AlertTypes._SUCCESS,
                content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
                onConfirm: () => onFinish(true)
            }));
        }
        catch (err) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(err)
            }));
        }
    }

    return (
        <Modal isOpen={!isObjectEmpty(selectedEvent)} toggle={onFinish}>
            <ModalHeader>{(isEdit ? t("EDIT_VIP_MEMBER") : t("ASSIGN_VIP_MEMBER")) + ' (' + selectedEvent?.name + ')'}</ModalHeader>
            <ModalBody>
                {
                    !isEdit &&
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <div className='row' style={{ alignItems: 'center' }}>
                                <div className="col-xs-4">
                                    {t('TARGET_MEMBER')}
                                </div>
                                <div className="col-xs-6">
                                    <input
                                        type="text"
                                        id={"targetMember"}
                                        name={"targetMember"}
                                        className="form-control form-control-lg"
                                        placeholder={t('TARGET_MEMBER')}
                                        defaultValue={''}
                                        ref={register({ required: 'PLEASE_ENTER_TARGE_MEMBER' })} />
                                    {errors.targetMember && <div className="invalid-feedback">{t(errors.targetMember.message)}</div>}
                                </div>
                                <div className="col-xs-2">
                                    <button type="submit" className="btn btn-primary btn-action">{t('GET')}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                }
                {
                    !isObjectEmpty(selectedVip) &&
                    <form onSubmit={handleSubmitMember(onMemberSubmit)}>
                        <hr />
                        <div className="form-group">
                            <div className='row' style={{ alignItems: 'center' }}>
                                <div className="col-xs-4">
                                    {t('MEMBER')}
                                </div>
                                <div className="col-xs-6">
                                    <input
                                        readOnly
                                        type="text"
                                        id={"member"}
                                        name={"member"}
                                        className="form-control form-control-lg"
                                        value={selectedVip?.username}
                                        ref={registerMember} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className='row' style={{ alignItems: 'center' }}>
                                <div className="col-xs-4">
                                    {t('RANK')}
                                </div>
                                <div className="col-xs-6">
                                    <input
                                        type="number"
                                        id={"rank"}
                                        name={"rank"}
                                        className="form-control form-control-lg"
                                        placeholder={t('RANK')}
                                        defaultValue={selectedVip?.rank ?? ''}
                                        ref={registerMember({ required: 'PLEASE_ENTER_MEMBER_RANK' })} />
                                    {errorsMember.rank && <div className="invalid-feedback">{t(errorsMember.rank.message)}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className='row' style={{ alignItems: 'center' }}>
                                <div className="col-xs-4">
                                    {t('STATUS')}
                                </div>
                                <div className="col-xs-6">
                                    <div className="switcher">
                                        <input type="checkbox"
                                            id={"status"}
                                            name={"status"}
                                            defaultChecked={selectedVip?.isUserBotActive ?? true}
                                            ref={registerMember} />
                                        <label htmlFor={"status"}></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pull-right">
                            <button type="button" className="btn btn-default btn-action m-r-10 m-b-10" onClick={() => onFinish()}>{t('CLOSE')}</button>
                            <button type="submit" className="btn btn-primary btn-action m-b-10">{t('SUBMIT')}</button>
                        </div>
                    </form>
                }
            </ModalBody>
        </Modal>
    );
};

export default VipMemberModal;