import React, { useState, useMemo, useEffect } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import DateTime from 'react-datetime';
import { ApiUrl, ApiKey } from '../../../util/Constant';
import { getValidationMessage, numberWithCurrencyFormat } from '../../../util/Util';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import moment from 'moment';
import ApiEngine from '../../../util/ApiEngine.js';

/// <summary>
/// Author: Wind
/// </summary> 
const RegionEcreditTransferLog = props => {
    const { t } = useTranslation();
    const { setValue, errors } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));
    const [regionOption, setRegionOption] = useState();
    const [fromRegion, setFromRegion] = useState('');
    const [toRegion, setToRegion] = useState('');
    const [targetRegion, setTargetRegion] = useState('');
    const [eCreditLog, setECreditLog] = useState([]);
    const _EMPTY_STATE = -1;

    /// <summary>
    /// Author: Wind
    /// </summary>
    let _eCreditLog = [
        {
            Header: "FROM_REGION",
            accessor: "fromRegionName",
            disableSortBy: true,
            Footer: () => {
                return <span style={{ float: 'right' }}><b>{t('TOTAL')}: </b></span>
            },
        },
        {
            Header: "AMOUNT",
            accessor: "totalAmount",
            Cell: ({ row }) => {
                return <span className="text-success">{numberWithCurrencyFormat(row.original.totalAmount)}</span>;
            },
            disableSortBy: true,
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.totalAmount);
                }, 0);

                return <span><b className='text-primary'>{numberWithCurrencyFormat(totalAmount)}</b></span>
            },
        },
        {
            Header: "PROCESSING_FEE",
            accessor: "processingFee",
            Cell: ({ row }) => {
                return <span className="text-success">{numberWithCurrencyFormat(row.original.processingFee)}</span>;
            },
            disableSortBy: true,
            Footer: ({ page }) => {
                const processingFee = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.processingFee);
                }, 0);

                return <span><b className='text-primary'>{numberWithCurrencyFormat(processingFee)}</b></span>
            },
        },
        {
            Header: "TO_REGION",
            accessor: "toRegionName",
            disableSortBy: true
        }
    ];

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        (async () => {
            var regionJsonResult = await ApiEngine.get(ApiUrl._API_GET_REGION);

            if (regionJsonResult[ApiKey._API_SUCCESS_KEY]) {
                var availableRegions = [];

                availableRegions.push({
                    label: '',
                    value: _EMPTY_STATE
                });

                regionJsonResult[ApiKey._API_DATA_KEY].map((region) => {
                    availableRegions.push({
                        label: region['name'],
                        value: region['id']
                    });
                });

                setRegionOption(availableRegions);
            }
        })();
    }, []);

    /// <summary>
    /// Author : Wind
    /// </summary> 
    async function applyFilter() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_ECREDIT_LOG + '?IsSummary=true&FromRegion=' + (fromRegion != _EMPTY_STATE ? fromRegion : '') +
            '&ToRegion=' + (toRegion != _EMPTY_STATE ? toRegion : '') +
            '&TargetRegion=' + (targetRegion != _EMPTY_STATE ? targetRegion : '') +
            '&StartDate=' + startDate + '&EndDate=' + endDate);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setECreditLog(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("ECREDIT_TRANSFER_LOG_REGION")}</h1>
            </div>
            <Panel>
                <PanelBody>
                    <div className="row m-b-10">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label className="w-50">{t("FROM_REGION")}</label>
                                <select
                                    className="form-control form-control-lg col-lg-12"
                                    id="fromRegion"
                                    name="fromRegion"
                                    value={fromRegion}
                                    onChange={(e) => { setFromRegion(e.target.value); }}>
                                    {
                                        regionOption && regionOption.map((region, index) => {
                                            return <option value={region['value']} key={index}>{region['label']}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label className="w-50">{t("TO_REGION")}</label>
                                <select
                                    className="form-control form-control-lg col-lg-12"
                                    id="toRegion"
                                    name="toRegion"
                                    value={toRegion}
                                    onChange={(e) => { setToRegion(e.target.value); }}>
                                    {
                                        regionOption && regionOption.map((region, index) => {
                                            return <option value={region['value']} key={index}>{region['label']}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label className="w-50">{t("TARGET_REGION")}</label>
                                <select
                                    className="form-control form-control-lg col-lg-12"
                                    id="targetRegion"
                                    name="targetRegion"
                                    value={targetRegion}
                                    onChange={(e) => { setTargetRegion(e.target.value); }}>
                                    {
                                        regionOption && regionOption.map((region, index) => {
                                            return <option value={region['value']} key={index}>{region['label']}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row m-b-10">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("START_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="startDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={startDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("startDate", "");
                                        }
                                    }} />
                                {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("END_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="endDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={endDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                            setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("endDate", "");
                                        }
                                    }} />
                                {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <button type="button" className="btn btn-primary" onClick={() => { applyFilter() }}>{t('APPLY_FILTER')}</button>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <ReactTable
                                columns={_eCreditLog}
                                data={eCreditLog}
                                exportRequired={true}
                                renderFooter={true}
                            />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div>
    )
}

export default RegionEcreditTransferLog;