import React, { useEffect, useState } from "react";
import {
    useTable,
    usePagination,
    useSortBy,
    useFilters,
    useGroupBy,
    useExpanded,
    useRowSelect,
    useGlobalFilter,
} from "react-table";
import { ApiKey } from '../../util/Constant';
import { useTranslation, Translation } from 'react-i18next';
import { stringIsNullOrEmpty, createFormBody, isObjectEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { showResponseMessage, setBusy, setIdle } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import ApiEngine from '../../util/ApiEngine.js';
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import FileSaver from 'file-saver';

// Define a default UI for filtering
const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter }
}) => {

    return (
        <input style={{ width: "100%" }} className="form-control"
            value={filterValue || ""}
            onChange={e => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
        />
    );
}

/// <summary>
/// A default UI for Global Filtering
/// </summary>
const GlobalFilter = ({
    preGlobalFilteredRows, globalFilter, setGlobalFilter
}) => {
    return (
        <Translation>
            {
                (t, { i18n }) => <span>
                    <input
                        id="tableFilterSearch"
                        style={{ width: "100%" }}
                        className="form-control form-control-lg"
                        value={globalFilter || ''}
                        onChange={e => {
                            setGlobalFilter(e.target.value || undefined);
                        }}
                        placeholder={t("SEARCH_HERE")}
                    />
                </span>
            }
        </Translation>
    )
}

/// <summary>
/// table provided option for filter, global filter, minimum rows, and footer
/// </summary>
const ReactTable = ({ columns,
    data = [], filterable = false, renderFooter = false, globalFilterable = true, tableStyle, defaultSortBy,
    minRows = 0, getFilteredRecords, fetchUrl, postData, className, initialPageSize = 10, customnPageSizeOptions,
    exportRequired = false, showOverallInfo = false, pageMultiply = true, isHidePagination = false, customLoader, hiddenColumns = [], exportExternalData = [] }) => {
    const { t } = useTranslation();
    const [tableData, setTableData] = useState([]);
    const [processedPageCount, setProcessedPageCount] = useState(0);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [overallInfo, setOverallInfo] = useState({});
    const [pageRange, setPageRange] = useState([]);
    const [fetchFinishFlag, setFetchFinishFlag] = useState(false);
    const _dispatch = useDispatch();
    const _PAGE_RANGE = 6;

    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    );

    ///default page size option for select
    const pageSizeOptions = React.useMemo(() => { return customnPageSizeOptions != undefined ? customnPageSizeOptions : [10, 20, 50, 100, 200, 500] }, []);

    /// <summary>
    /// Author: YJ
    /// </summary>
    function getExportFileBlob({ columns, data, fileType, fileName }) {
        var response = null;
        if (!stringIsNullOrEmpty(fetchUrl)) {
            if (postData != null) {
                postData["StartCount"] = 0;
                postData["PageSize"] = totalRecordCount;

                response = ApiEngine.post(fetchUrl, postData);
            }
            else {
                fetchUrl += (fetchUrl.indexOf("?") != -1) ? "&" : "?";

                response = ApiEngine.get(fetchUrl + "StartCount=" + 0 + "&PageSize=" + totalRecordCount);
            }

            response.then(function (responseJson) {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    var allData = responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY];

                    if (fileType === "csv") {
                        const csvString = Papa.unparse(allData);
                        var csvData = new Blob([csvString], { type: "text/csv" });
                        FileSaver.saveAs(csvData, `${fileName}.csv`);
                    }
                    else if (fileType === "xlsx") {
                        const header = columns.map((c) => c.id);
                        const headerNames = columns.map((c) => t(c.exportValue));

                        const compatibleData = allData.map((row) => {
                            const obj = {};
                            header.forEach((col, index) => {
                                obj[headerNames[index]] = row[col];
                            });
                            return obj;
                        });

                        let wb = XLSX.utils.book_new();
                        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                            headerNames,
                        });
                        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                        XLSX.writeFile(wb, `${fileName}.xlsx`);
                    }
                    return false;
                }
            });
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        exportData,
        state: {
            globalFilter,
            pageIndex,
            pageSize,
            sortBy,
            groupBy,
            expanded,
            filters,
            selectedRowIds
        }
    } = useTable(
        {
            columns,
            data: tableData,
            defaultColumn,
            disableMultiSort: true,
            initialState: {
                pageIndex: 0,
                pageSize: initialPageSize,
                sortBy: (defaultSortBy != null) ? defaultSortBy : [],
                hiddenColumns: hiddenColumns
            },
            manualPagination: !stringIsNullOrEmpty(fetchUrl),
            pageCount: processedPageCount,
            autoResetPage: stringIsNullOrEmpty(fetchUrl),
            getExportFileBlob
        },
        useGlobalFilter,
        useFilters,
        useGroupBy,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        useExportData
    );

    /// used to get the filtered record
    useEffect(() => {
        if (getFilteredRecords != null) {
            getFilteredRecords({ page });
        }
    }, [globalFilter, filters, pageSize, pageIndex])

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (fetchUrl == null) {
            if (data.length > 0) {
                setFetchFinishFlag(true);
            }
            setTableData(data);
        }
    }, [data]);

    /// <summary>
    /// Author : YJ
    /// </summary>
    function range(start, end) {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        let start = Math.max(1, (pageIndex + 1) - Math.floor(_PAGE_RANGE / 2));
        let end = start + _PAGE_RANGE;
        if (end >= processedPageCount) {
            let diff = end - processedPageCount;
            start = Math.max(start - diff, 1);
            end = processedPageCount;
        }
        let pgRange = range(start, end);
        setPageRange(pgRange);
    }, [pageIndex, processedPageCount]);

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        if (fetchUrl == null) {
            setProcessedPageCount(Math.ceil(tableData.length / pageSize));
        }
    }, [tableData]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// used for triggering the fetch data function
    /// </summary>
    useEffect(() => {
        updateDisplay();
    }, [fetchUrl, postData, pageSize, pageIndex])

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function updateDisplay() {
        if (!stringIsNullOrEmpty(fetchUrl)) {
            setFetchFinishFlag(false);
            let startRow = pageIndex;

            if (pageMultiply) {
                startRow = pageSize * pageIndex;
            }

            if (postData != null) {
                postData["StartCount"] = startRow;
                postData["PageSize"] = pageSize;

                var responseJson = await ApiEngine.post(fetchUrl, postData);
            }
            else {
                fetchUrl += (fetchUrl.indexOf("?") != -1) ? "&" : "?";

                var responseJson = await ApiEngine.get(fetchUrl + "StartCount=" + startRow + "&PageSize=" + pageSize);
            }

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setTableData(responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY]);
                let calculatedPageCount = Math.ceil(responseJson[ApiKey._API_DATA_KEY]["totalCount"] / pageSize);
                setProcessedPageCount(calculatedPageCount);
                setTotalRecordCount(responseJson[ApiKey._API_DATA_KEY]["totalCount"]);
                setOverallInfo(responseJson[ApiKey._API_DATA_KEY]["overallInfo"] ?? {});

                if (calculatedPageCount < pageIndex) {
                    gotoPage(Math.max(calculatedPageCount - 1, 0));
                }
            }
            else {
                _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
            }

            setFetchFinishFlag(true);
        }
        else {
            setProcessedPageCount(Math.ceil(tableData.length / pageSize));
        }
    }

    function exportExternalDataToXlsx() {
        const compatibleData = data.map(item => {
            const newItem = {};
            exportExternalData.forEach(field => {
              newItem[field] = item[field];
            });
            return newItem;
          });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            exportExternalData,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `data.xlsx`);
    }

    // Render the UI for your table
    return (
        <div className="ReactTable" style={{ padding: "10px" }}>
            {
                (!isObjectEmpty(overallInfo) && showOverallInfo) && <div className="row m-b-10">
                    {Object.keys(overallInfo).map((key, index) => {
                        return <div key={index} style={{
                            fontSize: "16px", margin: "6px", boxShadow: "1px 1px 8px rgba(0,0,0,0.1)", padding: "5px 15px", marginRight: "10px"
                        }}>
                            <b>{t(key)}: <span className={"text-" + (parseFloat(overallInfo[key]) >= 0 ? "success" : "danger")}>{numberWithCurrencyFormat(Number(overallInfo[key]), 4)}</span></b>
                        </div>
                    })}
                </div>
            }
            {
                totalRecordCount > 0 && exportRequired && <div className="row rt-export-div">
                    <div className="col-lg-12">
                        <button className="btn btn-primary" onClick={() => {
                            exportData("xlsx", true);
                        }}><i className="fa fa-file-excel"></i></button>
                        <button className="btn btn-primary" onClick={() => {
                            exportData("csv", true);
                        }}><i className="fas fa-file-csv"></i></button>
                    </div>
                </div>
            }
            {
                data.length > 0 && exportExternalData.length > 0 && <div className="row rt-export-div">
                    <div className="col-lg-12">
                        <button className="btn btn-primary" onClick={() => {
                            exportExternalDataToXlsx();
                        }}><i className="fa fa-file-excel"></i></button>
                    </div>
                </div>
            }
            {globalFilterable &&
                <div className="row m-b-10">
                    <div className="col-lg-3 m-b-5">
                        <select
                            id="tablePagingSelector"
                            className="form-control form-control-lg"
                            value={pageSize}
                            onChange={e => setPageSize(Number(e.target.value))} >
                            {pageSizeOptions.map(pageSize => (
                                <option className="form-control form-control-lg" key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-6 m-b-5">
                    </div>
                    <div className="col-lg-3 m-b-5">
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                    </div>
                </div>
            }
            <div className="table-responsive">
                <table {...getTableProps()} className={"table table-bordered table-hover " + className} style={{ ...tableStyle }}>
                    <thead style={{ overflow: "auto" }} className="rt-headergroup">
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} style={{ width: column.width, minWidth: column.minWidth, ...column.style }} onClick={column.clickable}>
                                        {
                                            typeof column.render("Header").props === 'object' ?
                                                column.render("Header") :
                                                (
                                                    <div {...column.getSortByToggleProps()}>
                                                        {t(column.render("Header"))}
                                                        {column.disableSortBy == true ? null :
                                                            column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? <i className="fas fa-sort-down" style={{ marginLeft: "3px" }}></i>
                                                                    : <i className="fas fa-sort-up" style={{ marginLeft: "3px" }}></i>
                                                                : <i className="fas fa-sort" style={{ marginLeft: "3px" }}></i>}
                                                        {
                                                            page.length > 0 && column.disableSortBy && column.sortColumn &&
                                                            <>
                                                                {
                                                                    column.isAsc ?
                                                                        <i className="fas fa-sort-up" style={{ marginLeft: "3px" }}></i> :
                                                                        <i className="fas fa-sort-down" style={{ marginLeft: "3px" }}></i>
                                                                }
                                                            </>
                                                        }
                                                        {
                                                            page.length > 0 && column.showSortIcon && column.disableSortBy && !column.sortColumn &&
                                                            <i className="fas fa-sort" style={{ marginLeft: "3px" }}></i>
                                                        }
                                                    </div>
                                                )
                                        }
                                        {/* Render the columns filter UI */}
                                        <div className="rt-thead-header">{filterable && column.canFilter ? column.render("Filter") : null}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {(fetchFinishFlag || customLoader == null) && <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()} style={{ width: cell.column.width, minWidth: cell.column.minWidth, ...cell.column.style }}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                        {
                            page.length === 0
                                ? <tr><td colSpan="100%" style={{ textAlign: "center" }}>{t("NO_DATA_FOUND")}</td></tr>
                                : (minRows > 0) && [...Array((pageSize <= minRows ? (pageSize - page.length) : Math.max(0, (minRows - page.length))))].map((x, i) =>
                                    <tr>
                                        {[...Array(page[0].cells.length)].map((a, b) => (
                                            <td style={{ textAlign: "center", height: "2rem" }}></td>
                                        ))}
                                    </tr>
                                )
                        }
                    </tbody>}
                    {renderFooter && <tfoot>
                        {footerGroups.map(group => (
                            <tr {...group.getFooterGroupProps()}>
                                {group.headers.map(column => (
                                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                ))}
                            </tr>
                        ))}
                    </tfoot>}
                    {(!fetchFinishFlag && customLoader != null) && <tbody>
                        <tr>
                            <td colSpan={99} style={{ textAlign: "center" }}>{customLoader}</td>
                        </tr>
                    </tbody>}
                </table>
            </div>
            {
                !isHidePagination &&
                <div className="row rt-pagination-div">
                    <div className="col-lg-8">
                        <div className="btn-group" role="group" style={{ flexFlow: "wrap" }} aria-label="Basic example">
                            <button type="button" className="btn btn-secondary pagination-btn" style={{ maxWidth: "35px" }} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                <i className="fas fa-angle-double-left"></i>
                            </button>{" "}
                            <button type="button" className="btn btn-secondary pagination-btn" style={{ maxWidth: "35px" }} onClick={() => previousPage()} disabled={!canPreviousPage}>
                                <i className="fas fa-angle-left"></i>
                            </button>{" "}
                            {pageRange.map((pageI, key) => {
                                return (<button type="button" className={"btn btn-primary pagination-btn " + (pageIndex == pageI - 1 && "selected")} style={{ color: "black", width: "unset", maxWidth: "unset" }}
                                    onClick={() => gotoPage(pageI - 1)}>
                                    {pageI}
                                </button>)
                            })}
                            <button type="button" className="btn btn-secondary pagination-btn" style={{ maxWidth: "30px" }} onClick={() => nextPage()} disabled={!canNextPage}>
                                <i className="fas fa-angle-right"></i>
                            </button>{" "}
                            <button type="button" className="btn btn-secondary pagination-btn" style={{ maxWidth: "30px" }} onClick={() => gotoPage(processedPageCount - 1)} disabled={!canNextPage}>
                                <i className="fas fa-angle-double-right"></i>
                            </button>{" "}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="pagination-page-details">
                            <span>
                                {t("PAGE")}{" "}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{" "}
                            </span>
                            <span>
                                {t("GO_TO_PAGE")}:{" "}
                                <input
                                    id="toPageInput"
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={e => {
                                        if (!stringIsNullOrEmpty(e.target.value)) {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                            gotoPage(page);
                                        }
                                    }}
                                    style={{ width: "100px" }}
                                />
                            </span>{" "}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default ReactTable;