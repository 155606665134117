import React, { useRef, useState, useEffect } from 'react';
import { Panel, PanelBody } from '../../components/panel/panel';
import { useDispatch, useSelector } from 'react-redux';
import { showResponseMessage, showMessage } from 'redux/AppAction.js';
import { AlertTypes, ApiKey, Status, ApiUrl, Role } from '../../util/Constant';
import ApiEngine from '../../util/ApiEngine.js';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { stringIsNullOrEmpty, getValidationMessage } from "../../util/Util";
import Select from "react-select";

/// <summary>
/// Author: Wind
/// </summary>
const RegisterPlayer = props => {
    var _dispatch = useDispatch();
    const { t } = useTranslation();
    const { register, handleSubmit, errors, unregister, setValue, watch } = useForm();
    var _userData = useSelector(state => state.authState.userData);
    const [gameList, setGameList] = useState([]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        init();
        register({ name: 'vendorCode' }, { required: "PLEASE_SELECT_PRODUCT" });
    }, [])

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function init() {
        try {
            var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_SHOP_ATTACHED_VENDOR}?userId=${_userData.userId}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setGameList(responseJson[ApiKey._API_DATA_KEY].map(item => { return { value: item.actualVendorCode, label: item.vendorName + " (" + item.vendorShortName + ")" } }));
        }
        catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            if (!stringIsNullOrEmpty(data.amount) && data.amount > 0) {
                let score = 0;
                var responseJson = await ApiEngine.post(`${ApiUrl._API_SHOP_CREATE_PLAYER}?code=${data.vendorCode}`);

                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }

                let returnData = responseJson[ApiKey._API_DATA_KEY];
                var topUpResponse = await ApiEngine.post(ApiUrl._API_SHOP_SET_SCORE + "?code=" + data.vendorCode + "&playerUsername=" + returnData.playerUsername + "&score=" + data.amount + "&v=" + + Date.now());

                if (topUpResponse[ApiKey._API_SUCCESS_KEY]) {
                    score = data.amount
                }

                _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], "Vendor: " + returnData.vendorName + " (" + returnData.vendorShortCode + ") \nH/P: " + returnData.playerUsername + " \nPW: ******" + "\nCredit: " + score,
                    `Vendor: ${returnData.vendorName} (${returnData.vendorShortCode})|H/P: ${returnData.playerUsername}|Pw: ${returnData.password}|Credit: ${score}|iOS: ${returnData.downloadUrlIos}|Android: ${returnData.downloadUrlAndroid}`, false));
            }
            else {
                throw "MIN_TOP_UP_IS_REQUIRED"
            }
        }
        catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    return (
        <div id="page-content">
            <div className="row">
                <div className="offset-lg-3 offset-xl-3 col-xl-6 col-lg-6 col-md-12 ui-sortable">
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Panel>
                            <PanelBody>
                                <h4 style={{ fontWeight: "unset" }}>{t("REGISTER_A_PLAYER")}</h4>
                                <hr />
                                <div className="form-group m-b-15 ">
                                    <div className='col-lg-12 m-b-5'>
                                        <label>{t("PRODUCT")}</label>
                                        <Select
                                            name="vendorCode"
                                            options={gameList}
                                            value={gameList.filter(option => option.value == watch('vendorCode'))}
                                            onChange={(e) => {
                                                setValue("vendorCode", e.value);
                                            }} />
                                        {errors.vendorCode && <div className="invalid-feedback">{t(errors.vendorCode.message)}</div>}
                                    </div>
                                    <div className='col-lg-12'>
                                        <label>{t("TOP_UP_AMOUNT")}</label>
                                        <input className="form-control form-control-lg"
                                            ref={register}
                                            autoComplete={false}
                                            id="amount"
                                            name="amount"
                                            placeholder={t("ENTER_AMOUNT_TO_RELOAD")}
                                            type="number"
                                            onInput={(e) => {
                                                setValue("amount", e.target.value);
                                            }} />
                                        {errors.amount && <div className="invalid-feedback">{t(getValidationMessage(errors.amount))}</div>}
                                    </div>
                                </div>
                                <input type="hidden" name="type" id="txt_type" />
                                <div className="pull-right">
                                    <button type="submit" id="btn_search" className="btn btn-primary btn-action">{t("REGISTER_NOW")}</button>
                                </div>
                            </PanelBody>
                        </Panel>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default RegisterPlayer;