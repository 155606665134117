import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { showResponseMessage } from "../../redux/AppAction";
import { ApiKey, ApiUrl, Status, Role } from "../../util/Constant";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import * as Util from '../../util/Util';
import Select from "react-select";
import ApiEngine from '../../util/ApiEngine.js';
import { stringIsNullOrEmpty } from '../../util/Util';

/// <summary>
/// Author : Wind
/// </summary>
const CreatePlayerModal = (props) => {
    const [modalVisibility, setModalVisibility] = useState(false);
    const [shopOption, setShopOption] = useState([]);
    const [selectedShop, setSelectedShop] = useState('');
    const { register, handleSubmit, setValue, errors, reset, unregister, watch } = useForm();
    const { t, i18n } = useTranslation();
    var _dispatch = useDispatch();

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        setModalVisibility(props["visible"]);
    }, [props["visible"]]);

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        if (modalVisibility) {
            register({ name: 'shop' }, { required: true });
            setValue("shop", props.shopId);
            setSelectedShop(props.shopId);
            init();
        }
        else {
            setValue("shop", "");
            setSelectedShop("");
            unregister("shop");
        }
    }, [modalVisibility]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SHOP_BY_VENDOR_ID + "?vendorId=" + props.vendor.id);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var shopList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (shop) {
                shopList.push({ label: shop.username + " (" + shop.shopName + ")", value: shop.id });
            });

            setShopOption(shopList);
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const onSubmit = async (data, e) => {
        var apiUrl = ApiUrl._API_SHOP_CREATE_PLAYER + "?code=" + props.vendor.code + "&shopId=" + selectedShop + "&MemberId=" + props.memberId;

        var responseJson = await ApiEngine.post(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            props.onFinish(true);
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    return (
        <Modal isOpen={modalVisibility} toggle={() => props.onFinish()}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={() => props.onFinish()}>
                    <h4>{t('SELECT_SHOP')}</h4>
                </ModalHeader>
                <ModalBody>
                    <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label"><b>{t("SHOP")}</b></label>
                        <div className="col-md-7">
                            <Select name="shop" options={shopOption}
                                placeholder={(shopOption.filter(option => option.value == selectedShop)[0] !== undefined) ? (shopOption.filter(option => option.value == selectedShop)[0].label) : ""}
                                value={shopOption.filter(option => option.value == selectedShop)}
                                onChange={(e) => {
                                    setSelectedShop(e.value);
                                }} />
                            {errors.shop && <div className="invalid-feedback">{t(Util.getValidationMessage(errors.shop))}</div>}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-primary">{t('BTN_CREATE_PLAYER')}</button>
                    <button type="button" className="btn btn-white"
                        onClick={() => props.onFinish(false)}>{t('BTN_CLOSE')}</button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default CreatePlayerModal