import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { ApiKey, ApiUrl, WebUrl, AccessRight, Role } from "../../../util/Constant";
import { useTranslation } from 'react-i18next';
import { useLocation, withRouter, useHistory } from "react-router-dom";
import ApiEngine from '../../../util/ApiEngine.js';
import NavigationButton from '../../../components/custom/NavigationButton';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const ManageShopApi = props => {
    const { t } = useTranslation();
    const [vendorApiList, setVendorApiList] = useState([]);
    var _location = useLocation();
    let _history = useHistory();

    let _tableColumns = useMemo(() => [
        {
            Header: t("VENDOR"),
            accessor: "vendorName",
        },
        {
            Header: t("CODE"),
            accessor: "vendorShortName",
        },
        {
            Header: t("ROOT_AGENT"),
            accessor: "rootAgent",
        },
        {
            Header: t("KEY"),
            accessor: "signKey",
        },
        {
            Header: t("OPCODE"),
            accessor: "opcode",
        },
        {
            Header: t("OPSECRET"),
            accessor: "opSecret",
        },
        {
            Header: t("TYPE"),
            accessor: "isPrimary",
            Cell: ({ row }) => {
                return row.original.isPrimary ? <span className="badge badge-green">{t("PRIMARY")}</span> : <span className="badge badge-primary">{t("SECONDARY")}</span>;
            }
        },
        {
            Header: t("STATUS"),
            accessor: "status",
            Cell: ({ row }) => {
                return row.original.status ? <span className="badge badge-green">{t("ENABLE")}</span> : <span className="badge badge-danger">{t("DISABLE")}</span>;
            }
        }
    ], []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SHOP_ATTACHED_VENDOR_API + "?userId=" + _location.state.id);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setVendorApiList(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("VENDOR_API_DETAILS")}</h1>
                <NavigationButton history={_history} />
            </div>
            <Panel>
                <PanelBody>
                    <ReactTable filterable={true} columns={_tableColumns} data={vendorApiList} />
                </PanelBody>
            </Panel>
        </div>
    )
}

export default ManageShopApi;