import { _LOGIN_SUCCESS, _LOGIN_FAILED, _LOGOUT, _GET_SHOP_CREDIT, _GET_PENDING_ONLINE_WITHDRAWAL_COUNT } from './AuthAction';
import { clearPermissionData } from "../util/PermissionChecker"

const _INITIAL_STATE = {
    userData: {},
    isLoggedIn: false,
    loginMessage: "",
    loginStateInitialized: false,
    creditLimit: 0,
    pendingOnlineWithdrawalCount: 0
};

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case _LOGIN_SUCCESS:
            return {
                ...state,
                userData: action.userData,
                isLoggedIn: true,
                loginMessage: "",
                loginStateInitialized: true
            }
            break;
        case _LOGIN_FAILED:
            return {
                loginMessage: action.message,
                isLoggedIn: false,
                userData: {},
                loginStateInitialized: true
            }
            break;
        case _GET_SHOP_CREDIT:
            return {
                ...state,
                creditLimit: action.creditLimit
            };
            break;
        case _GET_PENDING_ONLINE_WITHDRAWAL_COUNT:
            return {
                ...state,
                pendingOnlineWithdrawalCount: action.count,
                playSound: action.count > state.pendingOnlineWithdrawalCount
            };
            break;
        case _LOGOUT:
            clearPermissionData();
            return {
                userData: {},
                isLoggedIn: false,
                loginMessage: "",
                loginStateInitialized: true,
                creditLimit: 0
            };
            break;
        default:
            return state;
            break;
    }
};
