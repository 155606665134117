import React, { useState, useEffect, useMemo } from 'react';
import { Panel, PanelBody } from '../../../components/panel/panel';
import { WebUrl, ApiKey, ApiUrl, AccessRight, AlertTypes } from '../../../util/Constant';
import * as Util from '../../../util/Util';
import ReactTable from '../../../components/custom/ReactTable';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage, showMessage } from "../../../redux/AppAction";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import NavigationButton from '../../../components/custom/NavigationButton';
import ApiEngine from '../../../util/ApiEngine.js';
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useForm } from 'react-hook-form';
import DualListBox from 'react-dual-listbox';
import DualListBoxItemRow from 'components/custom/DualListBoxItemRow';

/// <summary>
/// Author: CK
/// </summary>
const ManageVendor = props => {
    const { t, i18n } = useTranslation();
    var _history = useHistory();
    var _dispatch = useDispatch();
    const { register, handleSubmit, errors, watch, unregister, setValue } = useForm();
    const {
        register: registerEditAppLinks,
        handleSubmit: handleSubmitEditAppLinks,
        errors: errorsEditAppLinks,
        watch: watchEditAppLinks,
        reset: resetEditAppLinks,
        setValue: setValueAppLinks
    } = useForm();
    const [filteredName, setFilteredName] = useState('');
    const [filteredCode, setFilteredCode] = useState('');
    const [vendorList, setVendorList] = useState([]);
    const [targetVendor, setTargetVendor] = useState(null);
    const [categoryOption, setCategoryOption] = useState([]);
    const [assignedCategory, setAssignedCategory] = useState([]);
    const [showAppLinksModal, setShowAppLinksModal] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState(null);

    /// <summary>
    /// Author: CK
    /// </summary>
    let _vendorTableColumns = useMemo(() => [
        {
            Header: "LOGO",
            accessor: "imagePath",
            Cell: ({ row }) => {
                return row.original.imagePath ? <img class="mini-program-logo" src={row.original.imagePath} /> : <div></div>
            }
        },
        {
            Header: "GAME_NAME",
            accessor: "gameName",
        },
        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "SHORT_NAME",
            accessor: "shortName",
        },
        {
            Header: "API_BASE_URL",
            accessor: "baseUrl",
            style: {
                wordBreak: 'break-word'
            },
        },
        {
            Header: "CODE",
            accessor: "code",
        },
        {
            Header: "CATEGORY",
            accessor: "categories",
            Cell: ({ row }) => {
                return <div>{row.original.categories.map((item) => { return <span className="badge badge-info">{item.translatedName}</span> })}</div>;
            }
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                return row.original.status ? <span className="badge badge-green">{t("ACTIVE")}</span> : <span className="badge badge-danger">{t("DISABLE")}</span>;
            }
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._GAME_VENDOR_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                onEditStatus({ "id": row.original.id, "status": !row.original.status })
                            }}>
                                <span>{row.original.status ? t("DISABLE") : t("ACTIVATE")}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                _history.push({
                                    pathname: WebUrl._URL_ADMIN_VENDOR_DETAIL,
                                    state: { id: row.original.id }
                                })
                            }}>
                                <span>{t('EDIT_VENDOR_DETAILS')}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                setTargetVendor(row.original)
                            }}>
                                <span>{t('ASSIGN_CATEGORY')}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                setSelectedVendor(row.original)
                            }}>
                                <span>{t('EDIT_APP_DOWNLOAD_LINKS')}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ], [vendorList]);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        getVendorList();
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        (async () => {
            if (targetVendor) {
                var responseJson = await ApiEngine.get(ApiUrl._API_VENDOR_CATEGORY)

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    var categoryOptionList = [];
                    responseJson[ApiKey._API_DATA_KEY].data.filter(v => v.status == true).forEach(function (category) {
                        categoryOptionList.push({ label: category.translatedName, value: category.id });
                    });
                    setCategoryOption(categoryOptionList);
                }

                setAssignedCategory(targetVendor.categories.map(obj => { return obj.id; }))
            }
        })();
    }, [targetVendor]);

    /// <summary>
    /// Author: CK
    /// </summary>
    async function getVendorList() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_LIST + "?name=" + filteredName + "&code=" + filteredCode);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setVendorList(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function onEditStatus(data) {
        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_VENDOR, Util.createMultiPartFormBody(data));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            getVendorList();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    const onSubmit = async (data) => {
        let params = {
            categoryIds: assignedCategory
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_ASSIGNED_VENDOR_CATEGORY + "/" + targetVendor.id, Util.createFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setTargetVendor(null);
                    getVendorList()
                }
            }
        }));
    };

    /// <summary>
    /// Author: Wind
    /// </summary>
    const submitAppLinks = async (data) => {
        data['id'] = selectedVendor.id;
        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_VENDOR, Util.createFormBody(data));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setSelectedVendor(null);
                    getVendorList()
                }
            }
        }));
    };

    return (
        <div id="page-content">
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_VENDORS")}</h1>
                {
                    checkIfPermissionExist(AccessRight._GAME_VENDOR_CONTROL_PERMISSION, true) &&
                    <NavigationButton history={_history} url={WebUrl._URL_ADMIN_VENDOR_DETAIL} buttonText={t("CREATE_VENDOR")} />
                }
            </div>
            <Panel>
                <PanelBody>
                    <div className="row m-b-10">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("NAME")}</label>
                                <input
                                    className="form-control form-control-lg"
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={filteredName}
                                    placeholder={t("FULL_OR_PART_OF_THE_NAME")}
                                    onInput={(e) => { setFilteredName(e.target.value) }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("CODE")}</label>
                                <input
                                    className="form-control form-control-lg"
                                    type="text"
                                    id="code"
                                    name="code"
                                    value={filteredCode}
                                    placeholder={t("FULL_OR_PART_OF_THE_CODE")}
                                    onInput={(e) => { setFilteredCode(e.target.value) }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="form-group">
                                <button id="btnFilter" onClick={getVendorList} className="btn btn-primary">{t("APPLY_FILTER")}</button>
                            </div>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <ReactTable data={vendorList} columns={_vendorTableColumns} filterable={false} />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <Modal isOpen={targetVendor} toggle={() => { setTargetVendor(null) }} size="lg">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={() => { setTargetVendor(null) }}><h4>{t("ASSIGN_CATEGORY")} {targetVendor && " (" + targetVendor.name + ")"}</h4></ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <label>{t("VENDOR")}</label>
                            <DualListBoxItemRow options={categoryOption} selected={assignedCategory} />
                            <DualListBox
                                canFilter
                                name="shops"
                                selected={assignedCategory}
                                options={categoryOption}
                                onChange={(e) => {
                                    setAssignedCategory(e);
                                }}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-primary btn-action">{t("BTN_SUBMIT")}</button>
                    </ModalFooter>
                </form>
            </Modal>
            <Modal isOpen={selectedVendor} toggle={() => { setSelectedVendor(null) }} size="md">
                <form onSubmit={handleSubmitEditAppLinks(submitAppLinks)}>
                    <ModalHeader>
                        <h4>{t('EDIT_APP_DOWNLOAD_LINKS')} {selectedVendor && ' (' + selectedVendor.gameName + ')'}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>{t("APP_DOWNLOAD_URL_ANDROID")}</label>
                                        <input
                                            className="form-control form-control-lg"
                                            type="text"
                                            id="appDownloadUrlAndroid"
                                            name="appDownloadUrlAndroid"
                                            defaultValue={selectedVendor ? selectedVendor.appDownloadUrlAndroid : ""}
                                            placeholder={t("ENTER_APP_DOWNLOAD_URL_ANDROID")}
                                            ref={registerEditAppLinks} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>{t("APP_DOWNLOAD_URL_IOS")}</label>
                                        <input
                                            className="form-control form-control-lg"
                                            type="text"
                                            id="appDownloadUrlIos"
                                            name="appDownloadUrlIos"
                                            defaultValue={selectedVendor ? selectedVendor.appDownloadUrlIos : ""}
                                            placeholder={t("ENTER_APP_DOWNLOAD_URL_IOS")}
                                            ref={registerEditAppLinks} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-primary">{t('BTN_SAVE')}</button>
                        <button type="button" className="btn btn-danger"
                            onClick={() => { setSelectedVendor(null); }}>{t('BTN_CANCEL')}</button>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    );
}

export default ManageVendor;