import React, { useState, useEffect } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, LanguageKey, AlertTypes } from "../../../util/Constant";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { showResponseMessage, showMessage } from "../../../redux/AppAction";
import { createMultiPartFormBody, stringIsNullOrEmpty } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';
import DateTime from 'react-datetime';
import moment from 'moment';

/// <summary>
/// Author: Wind
/// </summary> 
const OperationLog = props => {
    const { t, i18n } = useTranslation();
    const [logUrl, setLogUrl] = useState('');
    var [count, setCount] = useState(0);
    const [filterAction, setFilterAction] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [filterUser, setFilterUser] = useState('');
    const [filterDate, setFilterDate] = useState('');

    let _operationLogTableColumns = [
        {
            Header: t("ID"),
            accessor: "id",
            disableSortBy: true,
        },
        {
            Header: t("ACTION"),
            accessor: "actionKey",
            disableSortBy: true,
            width: '10%'
        },
        {
            Header: t("VALUE"),
            accessor: "value",
            disableSortBy: true,
            style: {
                wordBreak: 'break-word'
            },
            minWidth: 350
        },
        {
            Header: t("MODIFIED_DATE"),
            accessor: "createdTime",
            disableSortBy: true,
        },
        {
            Header: t("MODIFIED_BY"),
            Cell: ({ row }) => {
                return (
                    <div>
                        <div className="td-singleline">{row.original.createdByName} ({row.original.createdBy})</div>
                    </div>
                );
            },
        },
        {
            Header: t("IP_ADDRESS"),
            accessor: "ipAddress",
            disableSortBy: true,
        },
    ];

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        getOperationLog();
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function getOperationLog() {
        var rawUrl = ApiUrl._API_GET_OPERATION_LOG + "?ActionKey=" + filterAction + "&Value=" + filterValue +
            "&CreatedBy=" + filterUser + "&CreatedTime=" + filterDate + "&v=" + (++count);
        setCount(count);

        setLogUrl(rawUrl);
    }

    return (
        <div>
            <h1 className="page-header">{t('OPERATION_LOG')}</h1>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label><b>{t('ACTION')}</b></label>
                                        <input type="text" className="form-control m-b-5" placeholder={t("FULL_OR_PART_ACTION")} onInput={(e) => { setFilterAction(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label><b>{t('VALUE')}</b></label>
                                        <input type="text" className="form-control m-b-5" placeholder={t("FULL_OR_PART_VALUE")} onInput={(e) => { setFilterValue(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label><b>{t('USER')}</b></label>
                                        <input type="text" className="form-control m-b-5" placeholder={t("FULL_OR_PART_USER")} onInput={(e) => { setFilterUser(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label><b>{t('DATE')}</b></label>
                                        <DateTime
                                            name="date"
                                            timeFormat={false}
                                            dateFormat="YYYY-MM-DD"
                                            value={filterDate}
                                            closeOnSelect={true}
                                            inputProps={{ placeholder: t('SELECT_DATE'), className: 'form-control form-control-lg bg-white', readOnly: true }}
                                            onChange={(e) => {
                                                if (e instanceof moment) {
                                                    setFilterDate(e.format("YYYY-MM-DD"));
                                                }
                                                else {
                                                    setFilterDate('');
                                                }
                                            }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <button className="btn btn-primary" onClick={() => { getOperationLog(); }}>{t('APPLY_FILTER')}</button>
                                </div>
                            </div>
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <ReactTable fetchUrl={logUrl} columns={_operationLogTableColumns} globalFilterable={false} />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div>
    )
}

export default OperationLog;