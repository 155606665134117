import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import ReactTable from "../../../components/custom/ReactTable";
import { setBusy, setIdle } from '../../../redux/AppAction';
import { ApiKey, ApiUrl, Role, Status } from "../../../util/Constant";
import { stringIsNullOrEmpty, arrayGroupByKey, numberWithCurrencyFormat } from "../../../util/Util";
import { useForm } from "react-hook-form";
import ApiEngine from '../../../util/ApiEngine.js';
import { useDispatch } from "react-redux";
import Select from 'react-select'
import RegionFilterDualListBox from '../../../components/custom/RegionFilterDualListBox';

/// <summary>
/// Author : YJ
/// </summary>
const RolloverReport = props => {
    const _ALL_VALUE = -1;
    const _ALL_LABEL = "ALL";
    const [postData, setPostData] = useState({});
    const { register, handleSubmit, setValue } = useForm();
    const { t } = useTranslation();
    const [apiUrl, setApiUrl] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [shopOption, setShopOption] = useState([]);
    const [selectedShop, setSelectedShop] = useState([]);
    const [typeOption, setTypeOption] = useState([]);
    const [summaryStartDate, setSummaryStartDate] = useState(moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"));
    const [summaryEndDate, setSummaryEndDate] = useState(moment().endOf('month').format("YYYY-MM-DD HH:mm:ss"));
    const [regionOption, setRegionOption] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState([]);

    let _tableColumns = [
        {
            Header: "DATE",
            accessor: "parsedTime",
            disableSortBy: true
        },
        {
            Header: "USERNAME",
            accessor: "username",
            Cell: ({ row }) => {
                return row.original.username + " [" + row.original.fullName + "]"
            },
            disableSortBy: true
        },
        {
            Header: "TYPE",
            accessor: "parsedType",
            Cell: ({ row }) => {
                return <span className="badge badge-primary">{row.original.parsedType}</span>
            },
            disableSortBy: true
        },
        {
            Header: "PRE_ROLLOVER_LEFT",
            accessor: "preRolloverLeft",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.preRolloverLeft)
            },
            disableSortBy: true
        },
        {
            Header: "ROLLOVER",
            accessor: "amount",
            Cell: ({ row }) => {
                return row.original.amount > 0 ? <span className="text-success">{numberWithCurrencyFormat(row.original.amount)}</span> : <span className="text-danger">{numberWithCurrencyFormat(row.original.amount)}</span>;
            },
            Footer: ({ page }) => {
                const rolloverTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.amount);
                }, 0);

                return <span><b>{numberWithCurrencyFormat(rolloverTotal)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "POST_ROLLOVER_LEFT",
            accessor: "postRolloverLeft",
            Cell: ({ row }) => {
                return numberWithCurrencyFormat(row.original.postRolloverLeft)
            },
            disableSortBy: true
        },
    ]

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        (async () => {
            register({ name: 'selectedShop' });
            register({ name: 'startDate' }, { required: true });
            register({ name: 'endDate' }, { required: true });

            setValue('selectedShop', []);
            setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
            setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
            await getShopOption();
            await getTypeOption();
        })();
    }, []);

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function getTypeOption() {
        var apiUrl = ApiUrl._API_GET_ROLLOVER_TRANSACTION_TYPE;
        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let tempType = [{ value: _ALL_VALUE, label: _ALL_LABEL }];
            responseJson[ApiKey._API_DATA_KEY].map((type, index) => {
                tempType.push({ value: type.id, label: type.displayName });
            });
            setTypeOption(tempType);
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function getShopOption() {
        var apiUrl = ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP;
        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var shopListByRegion = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "regionName");
            var shopOptionsByRegion = [];

            for (var key in shopListByRegion) {
                var shopList = [];

                shopListByRegion[key].map(shop => {
                    shopList.push({ label: shop.username + " (" + shop.shopName + ")", value: shop.id });
                });

                shopOptionsByRegion.push({
                    label: key,
                    value: key,
                    options: shopList
                })
            }

            setRegionOption([...new Map(responseJson[ApiKey._API_DATA_KEY].map(item =>
                [item['regionId'], item])).values()].map((region) => { return ({ label: region.regionName, value: region.regionId }) }));
            setShopOption(shopOptionsByRegion);
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "TargetShop": data.selectedShop,
            "Type": parseInt(data.type)
        };

        if (!stringIsNullOrEmpty(data.username)) {
            params["Username"] = data.username;
        }

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_ROLLOVER_REPORT);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable
        className="col-nowrap"
        columns={_tableColumns}
        postData={postData}
        fetchUrl={apiUrl}
        renderFooter={true}
        showOverallInfo={true}
        exportRequired={true} />, [postData, apiUrl]);

    return (
        <div>
            <h1 className="page-header">{t("ROLLOVER_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <div className="row">
                        <div className="col-lg-3">
                            <label>{t("REGION")}</label>
                            <Select
                                isMulti={true}
                                name="region"
                                options={regionOption}
                                value={selectedRegion}
                                onChange={(e) => {
                                    setSelectedRegion(e);
                                }} />
                        </div>
                        <div className="col-lg-3">
                            <label>{t("START_DATE")}</label>
                            <DateTime
                                inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                name="summaryStartDate"
                                timeFormat="HH:mm:ss"
                                dateFormat="YYYY-MM-DD"
                                closeOnSelect={true}
                                value={summaryStartDate}
                                onChange={(e) => {
                                    setSummaryStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                }} />
                        </div>
                        <div className="col-lg-3">
                            <label>{t("END_DATE")}</label>
                            <DateTime
                                inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                name="summaryEndDate"
                                ref={register}
                                timeFormat="HH:mm:ss"
                                dateFormat="YYYY-MM-DD"
                                closeOnSelect={true}
                                value={summaryEndDate}
                                onChange={(e) => {
                                    setSummaryEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                }} />
                        </div>
                        <div className="col-lg-2">
                            <button
                                type="button"
                                className="btn btn-primary form-control form-control-lg m-t-30"
                                onClick={() => {
                                    const win = window.open(ApiUrl._API_GET_MEMBER_ROLLOVER_SUMMARY + "?StartDate=" + summaryStartDate + "&EndDate=" + summaryEndDate +
                                        "&regionIds=" + selectedRegion.map(r => r.value).join(','), "_blank");
                                    win.focus();
                                }}
                            >
                                {t('DOWNLOAD_ROLLOVER_SUMMARY')}
                            </button>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("USERNAME")}</label>
                                    <input type="text" name="username" ref={register} className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("TYPE")}</label>
                                    <select name="type" ref={register} className="form-control form-control-lg">
                                        {typeOption.map((type, index) => {
                                            return <option key={index} value={type.value}>{type.label}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("SHOP")}</label>
                                    <RegionFilterDualListBox
                                        canFilter
                                        name={"shop"}
                                        ref={register}
                                        options={shopOption}
                                        selectedOptions={selectedShop}
                                        regions={regionOption.map(i =>  { return { label: i.label, value: i.label }})}
                                        onChange={(e) => {
                                            setValue('selectedShop', e);
                                            setSelectedShop(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
};

export default RolloverReport;