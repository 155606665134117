import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import NavigationButton from '../../../components/custom/NavigationButton';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { WebUrl, ApiKey, ApiUrl, AccessRight, Role } from '../../../util/Constant';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { navigateTo, createMultiPartFormBody } from "../../../util/Util";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useDispatch } from "react-redux";
import { showResponseMessage } from "../../../redux/AppAction";
import ApiEngine from '../../../util/ApiEngine.js';
import AttachShopModal from '../../../components/custom/AttachShopModal';

/// <summary>
/// Author: Wind
/// CK - Shop assignment
/// </summary> 
const ManageSmsSettings = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    var _dispatch = useDispatch();
    const [smsSettingsData, setSmsSettingsData] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedSmsSetting, setSelectedSmsSetting] = useState(null);

    let _smsSettingsTableColumns = useMemo(() => [
        {
            Header: "NAME",
            accessor: "name",
            disableSortBy: true,
        },
        {
            Header: "CATEGORY_NAME",
            accessor: "categoryName",
            disableSortBy: true,
        },
        {
            Header: "CONTENTS",
            accessor: "value",
            disableSortBy: true,
        },
        {
            Header: "STATUS",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <>
                    { row.original.isApp && <>- For <b>App</b><br/></> }
                    { row.original.isSms && <>- For <b>Sms</b><br/></> }
                </>
            },
        },
        {
            Header: "TOTAL_SHOPS",
            accessor: "totalShop",
            disableSortBy: true,
        },
        {
            Header: "LAST_MODIFIED",
            accessor: "updatedTime",
            disableSortBy: true,
        },
        {
            Header: t("ACTION"),
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._SMS_SETTING_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_SMS_DETAILS, row.original) }}><span>{t("EDIT_SETTING")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                toggleModal();
                                setSelectedSmsSetting(row.original);
                            }}>
                                <span>{t('ATTACH_SHOPS')}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableFilters: true,
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ], [smsSettingsData]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        getSmsSettings();
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function getSmsSettings() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SMS_SETTING);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setSmsSettingsData(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function toggleModal() {
        if (isModalVisible) {
            setSelectedSmsSetting(null);
        }

        setIsModalVisible(!isModalVisible);
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function onSubmitAttachShop(attachedShops) {
        let params = {
            "smsSettingId": selectedSmsSetting.id,
            "shopIds": attachedShops.join(',')
        }
        
        var responseJson = await ApiEngine.post(ApiUrl._API_ATTACH_SMS_SETTING_SHOP, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            toggleModal();
            getSmsSettings();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_SMS_SETTING")}</h1>
                {
                    checkIfPermissionExist(AccessRight._SMS_SETTING_CONTROL_PERMISSION, true) &&
                    <NavigationButton history={_history} url={WebUrl._URL_SMS_DETAILS} buttonText={t("ADD_SMS_SETTING")} />
                }
            </div>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <ReactTable data={smsSettingsData} columns={_smsSettingsTableColumns} globalFilterable={false} />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <AttachShopModal
                isModalVisible={isModalVisible}
                title={selectedSmsSetting ? selectedSmsSetting.name : ''}
                getAttachedShopUrl={selectedSmsSetting ? ApiUrl._API_GET_SMS_SETING_SHOP_BY_SMS_SETTING_ID + "?smsSettingId=" + selectedSmsSetting.id : null}
                onFinish={(attachedShops) => {
                    if (attachedShops) {
                        onSubmitAttachShop(attachedShops);
                    }
                    else {
                        toggleModal();
                    }
                }} />
        </div>
    )
}

export default ManageSmsSettings;