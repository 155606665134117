import ReactTable from 'components/custom/ReactTable';
import { Panel, PanelBody } from 'components/panel/panel';
import React, { useEffect, useMemo, useState } from 'react';
import DualListBox from 'react-dual-listbox';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ApiEngine from 'util/ApiEngine';
import { ApiKey, ApiUrl } from 'util/Constant';
import { arrayGroupByKey, isObjectEmpty, numberWithCurrencyFormat, stringIsNullOrEmpty, getAmountClassName } from '../../../util/Util';
import moment from 'moment';
import DateTime from 'react-datetime';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import RegionFilterDualListBox from '../../../components/custom/RegionFilterDualListBox';
import DualListBoxItemRow from 'components/custom/DualListBoxItemRow';

/// <summary>
/// Author : CK
/// </summary>
const AgentWinLossReport2 = () => {
    const { t } = useTranslation();
    const [apiUrl, setApiUrl] = useState("");
    const [postData, setPostData] = useState({});
    const [vendorOption, setVendorOption] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState([]);
    const [agents, setAgents] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'));
    const [endDate, setEndDate] = useState(moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'));
    const [viewDetails, setViewDetails] = useState({});
    const [viewUrl, setViewUrl] = useState("");
    const [modalVisibility, setModalVisibility] = useState(false);
    const [regions, setRegions] = useState([]);
    const _REPORT_TYPE = {
        slot: 1,
        live: 2,
        lotto: 3,
        fishing: 4
    };

    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            startDate: startDate,
            endDate: endDate
        }
    });

    const _COLUMNS = [
        {
            Header: "No.",
            id: "row",
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            disableSortBy: true,
            width: '1%'
        },
        {
            Header: "AGENT",
            accessor: "agentUsername",
            disableSortBy: true
        },
        {
            Header: "NAME",
            accessor: "agentName",
            disableSortBy: true
        },
        {
            Header: "CITY",
            accessor: "city",
            disableSortBy: true
        },
        {
            Header: "CLOSING_DAY",
            accessor: "closingDay",
            disableSortBy: true
        },
        {
            Header: "SLOT_TURNOVER",
            accessor: "slotTurnover",
            disableSortBy: true,
            Cell: ({ value }) => {
                return <span>{numberWithCurrencyFormat(value, 2)}</span>
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.slotTurnover);
                }, 0);

                return <strong>{numberWithCurrencyFormat(totalAmount, 2)}</strong>
            },
        },
        {
            Header: "SLOT_PLAYER_WINLOSE",
            accessor: "slotWinLoss",
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <span className={"d-flex"}>
                        <div className={`clickable-link text-decoration-underline ${getAmountClassName(row.original.slotWinLoss, 2)}`} onClick={() => {
                            setViewUrl(ApiUrl._API_GET_AGENT_WINLOSS_REPORT_DETAIL);
                            setViewDetails({
                                AgentId: row.original.agentId,
                                AgentUsername: row.original.agentUsername,
                                StartDate: startDate,
                                EndDate: endDate,
                                Vendors: selectedVendor,
                                Agents: selectedAgents,
                                type: _REPORT_TYPE.slot
                            });
                            setModalVisibility(true);
                        }}>
                            {numberWithCurrencyFormat(row.original.slotWinLoss, 2)}
                        </div>
                    </span >
                )
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.slotWinLoss);
                }, 0);

                return <strong className={getAmountClassName(totalAmount, 2)}>{totalAmount.toFixed(2)}</strong>
            },
        },
        {
            Header: "SLOT_COMMISSION",
            accessor: "slotCommission",
            disableSortBy: true,
            Cell: ({ value }) => {
                return <span className={getAmountClassName(value, 2)}>{numberWithCurrencyFormat(value, 2)}</span>
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.slotCommission);
                }, 0);

                return <strong className={getAmountClassName(totalAmount, 2)}>{totalAmount.toFixed(2)}</strong>
            },
        },
        {
            Header: "FISHING_WINLOSE",
            accessor: "fishingWinLoss",
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <span className="d-flex">
                        <div className={`clickable-link text-decoration-underline ${getAmountClassName(row.original.fishingWinLoss, 2)}`} onClick={() => {
                            setViewUrl(ApiUrl._API_GET_AGENT_WINLOSS_REPORT_DETAIL);
                            setViewDetails({
                                AgentId: row.original.agentId,
                                AgentUsername: row.original.agentUsername,
                                StartDate: startDate,
                                EndDate: endDate,
                                Vendors: selectedVendor,
                                Agents: selectedAgents,
                                type: _REPORT_TYPE.fishing
                            });
                            setModalVisibility(true);
                        }}>
                            {numberWithCurrencyFormat(row.original.fishingWinLoss, 2)}
                        </div>
                    </span >
                )
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.fishingWinLoss);
                }, 0);

                return <strong className={getAmountClassName(totalAmount, 2)}>{totalAmount.toFixed(2)}</strong>
            },
        },
        {
            Header: "FISHING_COMMISSION",
            accessor: "fishingCommission",
            disableSortBy: true,
            Cell: ({ value }) => {
                return <span className={getAmountClassName(value, 2)}>{numberWithCurrencyFormat(value, 2)}</span>
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.fishingCommission);
                }, 0);

                return <strong className={getAmountClassName(totalAmount, 2)}>{totalAmount.toFixed(2)}</strong>
            },
        },
        {
            Header: "LIVE_SLOT_WINLOSS",
            accessor: "liveSlotWinLoss",
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <span className="d-flex">
                        <div className={`clickable-link text-decoration-underline ${getAmountClassName(row.original.liveSlotWinLoss, 2)}`} onClick={() => {
                            setViewUrl(ApiUrl._API_GET_AGENT_WINLOSS_REPORT_DETAIL);
                            setViewDetails({
                                AgentId: row.original.agentId,
                                AgentUsername: row.original.agentUsername,
                                StartDate: startDate,
                                EndDate: endDate,
                                Vendors: selectedVendor,
                                Agents: selectedAgents,
                                type: _REPORT_TYPE.live
                            });
                            setModalVisibility(true);
                        }}>
                            {numberWithCurrencyFormat(row.original.liveSlotWinLoss, 2)}
                        </div>
                    </span >
                )
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.liveSlotWinLoss);
                }, 0);

                return <strong className={getAmountClassName(totalAmount, 2)}>{totalAmount.toFixed(2)}</strong>
            },
        },
        {
            Header: "LIVE_SLOT_COMMISSION",
            accessor: "liveSlotCommission",
            disableSortBy: true,
            Cell: ({ value }) => {
                return <span className={getAmountClassName(value, 2)}>{numberWithCurrencyFormat(value, 2)}</span>
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.liveSlotCommission);
                }, 0);

                return <strong className={getAmountClassName(totalAmount, 2)}>{totalAmount.toFixed(2)}</strong>
            },
        },
        {
            Header: "LOTTO_TURNOVER",
            accessor: "lottoBet",
            disableSortBy: true,
            Cell: ({ value }) => {
                return <span className={getAmountClassName(value, 2)}>{numberWithCurrencyFormat(value, 2)}</span>
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.lottoBet);
                }, 0);

                return <strong className={getAmountClassName(totalAmount, 2)}>{totalAmount.toFixed(2)}</strong>
            },
        },
        {
            Header: "STRIKE",
            accessor: "lottoStrike",
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <span className="d-flex">
                        <div className="clickable-link text-decoration-underline" onClick={() => {
                            setViewUrl(ApiUrl._API_GET_AGENT_WINLOSS_REPORT_DETAIL);
                            setViewDetails({
                                AgentId: row.original.agentId,
                                AgentUsername: row.original.agentUsername,
                                StartDate: startDate,
                                EndDate: endDate,
                                Vendors: selectedVendor,
                                Agents: selectedAgents,
                                type: _REPORT_TYPE.lotto
                            });
                            setModalVisibility(true);
                        }}>
                            {numberWithCurrencyFormat(row.original.lottoStrike, 2)}
                        </div>
                    </span >
                )
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.lottoStrike);
                }, 0);

                return <strong className={getAmountClassName(totalAmount, 2)}>{totalAmount.toFixed(2)}</strong>
            },
        },
        {
            Header: "LOTTO_COMMISSION",
            accessor: "lottoCommission",
            disableSortBy: true,
            Cell: ({ value }) => {
                return <span className={getAmountClassName(value, 2)}>{numberWithCurrencyFormat(value, 2)}</span>
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.lottoCommission);
                }, 0);

                return <strong className={getAmountClassName(totalAmount, 2)}>{totalAmount.toFixed(2)}</strong>
            },
        },
        {
            Header: "TURNOVER_COMMISSION",
            accessor: "turnoverCommission",
            disableSortBy: true,
            Cell: ({ value }) => {
                return <span className={getAmountClassName(value, 2)}>{numberWithCurrencyFormat(value, 2)}</span>
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.turnoverCommission);
                }, 0);

                return <strong className={getAmountClassName(totalAmount, 2)}>{totalAmount.toFixed(2)}</strong>
            },
        },
        {
            Header: "AGENT_TOTAL_COMMISSION",
            accessor: "agentTotalCommission",
            disableSortBy: true,
            Cell: ({ value }) => {
                return <span className={getAmountClassName(value, 2)}>{numberWithCurrencyFormat(value, 2)}</span>
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.turnoverCommission);
                }, 0);

                return <strong className={getAmountClassName(totalAmount, 2)}>{totalAmount.toFixed(2)}</strong>
            },
        },
        {
            Header: "UPLINE",
            accessor: "uplineName",
            disableSortBy: true,
            Cell: ({ value }) => {
                return <div>{stringIsNullOrEmpty(value) ? '-' : value}</div>;
            },
        },
        {
            Header: "UPLINE_SLOT_COMMISSION",
            accessor: "uplineSlotCommission",
            disableSortBy: true,
            Cell: ({ value }) => {
                return <span className={getAmountClassName(value, 2)}>{numberWithCurrencyFormat(value, 2)}</span>
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.uplineSlotCommission);
                }, 0);

                return <strong className={getAmountClassName(totalAmount, 2)}>{totalAmount.toFixed(2)}</strong>
            },
        },
        {
            Header: "UPLINE_FISHING_COMMISSION",
            accessor: "uplineFishingCommission",
            disableSortBy: true,
            Cell: ({ value }) => {
                return <span className={getAmountClassName(value, 2)}>{numberWithCurrencyFormat(value, 2)}</span>
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.uplineFishingCommission);
                }, 0);

                return <strong className={getAmountClassName(totalAmount, 2)}>{totalAmount.toFixed(2)}</strong>
            },
        },
        // {
        //     Header: "UPLINE_LIVE_SLOT_COMMISSION",
        //     accessor: "liveUplineSlotCommission",
        //     disableSortBy: true,
        //     Cell: ({ value }) => {
        //         return <span className={getAmountClassName(value, 2)}>{numberWithCurrencyFormat(value, 2)}</span>
        //     },
        //     Footer: ({ page }) => {
        //         const totalAmount = page.reduce((sum, currentValue) => {
        //             return sum + parseFloat(currentValue.original.liveUplineSlotCommission);
        //         }, 0);

        //         return <strong className={getAmountClassName(totalAmount, 2)}>{totalAmount.toFixed(2)}</strong>
        //     },
        // },
        {
            Header: "UPLINE_LOTTO_COMMISSION",
            accessor: "uplineLottoCommission",
            disableSortBy: true,
            Cell: ({ value }) => {
                return <span className={getAmountClassName(value, 2)}>{numberWithCurrencyFormat(value, 2)}</span>
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.uplineLottoCommission);
                }, 0);

                return <strong className={getAmountClassName(totalAmount, 2)}>{totalAmount.toFixed(2)}</strong>
            },
        },
        // {
        //     Header: "UPLINE_TURNOVER_COMMISSION",
        //     accessor: "uplineTurnoverCommission",
        //     disableSortBy: true,
        //     Cell: ({ value }) => {
        //         return <span className={getAmountClassName(value, 2)}>{numberWithCurrencyFormat(value, 2)}</span>
        //     },
        //     Footer: ({ page }) => {
        //         const totalAmount = page.reduce((sum, currentValue) => {
        //             return sum + parseFloat(currentValue.original.uplineTurnoverCommission);
        //         }, 0);

        //         return <strong className={getAmountClassName(totalAmount, 2)}>{totalAmount.toFixed(2)}</strong>
        //     },
        // },
        {
            Header: "TOTAL_UPLINE_COMMISSION",
            accessor: "uplineCommission",
            disableSortBy: true,
            Cell: ({ value }) => {
                return <span className={getAmountClassName(value, 2)}>{numberWithCurrencyFormat(value, 2)}</span>
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.uplineCommission);
                }, 0);

                return <strong className={getAmountClassName(totalAmount, 2)}>{totalAmount.toFixed(2)}</strong>
            },
        },
    ]

    const _VIEW_DETAIL_COLUMNS = [
        {
            Header: "No.",
            id: "row",
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            disableSortBy: true,
            width: '1%'
        },
        {
            Header: "PLAYER",
            accessor: "playerUsername",
            disableSortBy: true
        },
        {
            Header: "VENDOR",
            accessor: "vendorName",
            disableSortBy: true,
        },
        {
            Header: "AMOUNT",
            accessor: "amount",
            disableSortBy: true,
            Cell: ({ value }) => {
                return <span className={value >= 0 ? "" : "text-danger"}>{numberWithCurrencyFormat(value, 2)}</span>
            },
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.amount);
                }, 0);

                return <strong>{numberWithCurrencyFormat(totalAmount, 2)}</strong>
            },
        },
    ]

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        (async () => {
            await getVendorOption();
            await getAgentOption();
        })();
    }, []);

    /// <summary>
    /// Author : CK
    /// </summary>
    async function getVendorOption() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_LIST);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var vendorList = [];

            responseJson[ApiKey._API_DATA_KEY].forEach(function (vendor) {
                vendorList.push({ label: vendor.name + " (" + vendor.shortName + ")", value: vendor.id });
            });

            setVendorOption(vendorList);
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function getAgentOption() {
        await ApiEngine.get(ApiUrl._API_GET_AGENT_LIST)
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    var data = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "regionName");
                    var optionList = [];
                    var regionOptions = [];

                    for (var key in data) {
                        var agentList = [];

                        data[key].map(shop => {
                            agentList.push({ label: shop.username, value: shop.id });
                        });

                        optionList.push({
                            label: key,
                            value: key,
                            options: agentList
                        })

                        regionOptions.push({
                            label: key,
                            value: key
                        });
                    }

                    setRegions(regionOptions);
                    setAgents(optionList);
                }
            })
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    function constructModalHeader() {
        let header = "";

        if (viewDetails["type"] == _REPORT_TYPE.lotto) {
            header = t("LOTTO");
        }
        else if (viewDetails["type"] == _REPORT_TYPE.slot) {
            header = t("SLOT");
        }
        else {
            header = t("LIVE");
        }

        return header + " - " + viewDetails["AgentUsername"];
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    const PaginationTable = useMemo(() =>
        <ReactTable
            columns={_COLUMNS}
            fetchUrl={apiUrl}
            postData={postData}
            exportRequired={true}
            renderFooter={true}
        />
        , [apiUrl, postData]);

    /// <summary>
    /// Author : CK
    /// </summary>
    const ViewDetailPaginationTable = useMemo(() =>
        <ReactTable
            columns={_VIEW_DETAIL_COLUMNS}
            fetchUrl={viewUrl}
            postData={viewDetails}
            exportRequired={false}
            renderFooter={true}
        />
        , [viewUrl, viewDetails]);

    /// <summary>
    /// Author : CK
    /// </summary>
    const submitForm = async (data, e) => {
        setPostData({
            StartDate: startDate,
            EndDate: endDate,
            Vendors: selectedVendor,
            Agents: selectedAgents,
            FilterName: data.filterName,
            City: data.city
        });
        setApiUrl(ApiUrl._API_GET_AGENT_WINLOSS_REPORT_2);
    }

    return (
        <div>
            <h1 className="page-header d-flex justify-content-between">
                {t("AGENT_WIN_LOSS_REPORT")}
            </h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        ref={register}
                                        defaultValue={moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')}
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        ref={register}
                                        defaultValue={moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')}
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>{t("AGENT")}</label>
                                    <RegionFilterDualListBox
                                        canFilter
                                        name={"agents"}
                                        ref={register}
                                        options={agents}
                                        selectedOptions={selectedAgents}
                                        regions={regions}
                                        onChange={(e) => {
                                            setValue('agents', e);
                                            setSelectedAgents(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>{t("VENDOR")}</label>
                                    <DualListBoxItemRow options={vendorOption} selected={selectedVendor} />
                                    <DualListBox
                                        canFilter
                                        name="vendor"
                                        ref={register}
                                        options={vendorOption}
                                        selected={selectedVendor}
                                        onChange={(e) => {
                                            setValue('vendor', e);
                                            setSelectedVendor(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>{t("NAME")}</label>
                                    <input className="form-control form-control-lg" id="filterName" name="filterName" type="text" ref={register} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>{t("CITY")}</label>
                                    <input className="form-control form-control-lg" id="city" name="city" type="text" ref={register} />
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">{t("SUBMIT")}</button>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>

            <Modal isOpen={modalVisibility} centered toggle={() => { setModalVisibility(false) }} size="lg">
                <ModalHeader toggle={() => { setModalVisibility(false) }}>{constructModalHeader()}</ModalHeader>
                <ModalBody>
                    {ViewDetailPaginationTable}
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary" onClick={() => setModalVisibility(false)}>{t("BTN_CLOSE")}</button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default AgentWinLossReport2;