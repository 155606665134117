import React, { useState, useEffect } from "react";
import moment from 'moment';
import {
    Panel,
    PanelBody,
} from "../../components/panel/panel.jsx";
import { useTranslation } from 'react-i18next';
import ReactTable from "../../components/custom/ReactTable";
import { stringIsNullOrEmpty } from "../../util/Util";
import { ApiKey, ApiUrl, Role, Status, AlertTypes } from "../../util/Constant";
import { showResponseMessage } from "redux/AppAction.js";
import { useDispatch } from "react-redux";
import ApiEngine from "util/ApiEngine.js";

/// <summary>
/// Author : Wind
/// </summary>
const PlayerCreationReport = props => {
    var _dispatch = useDispatch();
    const { t } = useTranslation();
    const [playerList, setPlayerList] = useState([]);

    let _tableColumns = [
        {
            Header: t("DATE"),
            accessor: "createdAt",
            disableSortBy: true
        },
        {
            Header: t("VENDOR"),
            Cell: ({ row }) => {
                return `${row.original.vendorName} (${row.original.vendorShortName})`;
            },
            disableSortBy: true
        },
        {
            Header: t("USERNAME"),
            accessor: "username",
            disableSortBy: true
        },
    ];

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        (async () => {
            try {
                var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_SHOP_CREATED_PLAYER}?StartCount=0&PageSize=10`);

                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }

                setPlayerList(responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY]);
            }
            catch (err) {
                _dispatch(showResponseMessage(false, err))
            }
        })();
    }, []);

    return (
        <div>
            <h1 className="page-header">{t("PLAYER_CREATION_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <ReactTable data={playerList} columns={_tableColumns} isHidePagination={true} />
                </PanelBody>
            </Panel>
        </div>
    );
}

export default PlayerCreationReport;