import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { WebUrl, ApiUrl, ApiKey } from '../../util/Constant';
import { stringIsNullOrEmpty, navigateTo } from '../../util/Util';
import { useTranslation } from 'react-i18next';
import { showResponseMessage } from "../../redux/AppAction";
import { useHistory } from "react-router-dom";
import QrScanner from 'react-qr-scanner'
import classNames from 'classnames';

/// <summary>
/// Author: YJ
/// </summary>
const QRScanner = props => {
    let _history = useHistory();
    const { t } = useTranslation();
    const _dispatch = useDispatch();

    const [isQrScannerFunctionable, setIsQrScannerFunctionable] = useState(false);
    const [isScanned, setIsScanned] = useState(false);
    const [qrErrorMsg, setQrErrorMsg] = useState('');

    const _DELAY_IN_MS = 500;

    /// <summary>
    /// Author: YJ
    /// </summary>
    useEffect(() => {
        var isIosNotSafari = !(/iP(ad|hone|od).+Version\/[\d\.]+.*Safari/i.test(navigator.userAgent)) && (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);

        if (isIosNotSafari) {
            _dispatch(showResponseMessage(false, t("IF_IOS_BUT_NOT_SAFARI")));
            setIsQrScannerFunctionable(false);
        }
        else {
            setIsQrScannerFunctionable(true);
        }

        return () => {
            setIsQrScannerFunctionable(false);
            setIsScanned(false);
            setQrErrorMsg('');
        }
    }, []);

    /// <summary>
    /// Author: YJ
    /// </summary>
    function handleError(error) {
        if (error.name === 'NotAllowedError') {
            error = "Camera access permission is required to use QR Scanner."
        }
        else if (error.name === 'NotFoundError') {
            error = "No camera found on your device."
        }
        else if (error.name === 'NotSupportedError') {
            error = "HTTPS required to use QR Scanner"
        }
        else if (error.name === 'NotReadableError') {
            error = "Another application is using your device camera. Please close the other application and refresh this page."
        }
        else if (error.name === 'OverconstrainedError') {
            error = "No suitable camera found on your device."
        }
        else if (error.name === 'StreamApiNotSupportedError') {
            error = "QR Scanner is not supported on your browser."
        }

        setQrErrorMsg(error);
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function qrDetectedHandler(decodedQr) {
        if (!stringIsNullOrEmpty(decodedQr) && !isScanned) {
            setIsScanned(true);
            await props.onQrDetect({ code: decodedQr.text });
            setIsScanned(false);
        }
    }

    return (
        <div>
            <div className="standard-container m-b-15">
                {
                    isQrScannerFunctionable && !qrErrorMsg &&
                    <div className="qr-wrapper">
                        <QrScanner
                            facingMode="rear"
                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                            className="qr-scanner-container"
                            delay={_DELAY_IN_MS}
                            onError={handleError}
                            onScan={qrDetectedHandler}
                        />
                        <div className="qr-scanner-overlay">
                            <div className={classNames("scan-area", {
                                "scanned": isScanned
                            })}></div>
                        </div>
                    </div>
                }
                {
                    qrErrorMsg && <p className="text-center">{qrErrorMsg}</p>
                }
            </div>
        </div >
    );
};

export default QRScanner;
