import { AlertTypes } from 'util/Constant.js';
import i18n from '../localization/i18n';
import { stringIsNullOrEmpty, printReceipt } from './../util/Util';

export const _BUSY = 'busy';
export const _IDLE = 'idle';
export const _API_CALLED = 'setApiCalled';

export const _ALERT_TYPES = {
    SET_ALERT: 'SetAlert',
    NONE: 'AlertNone',
}

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
export const setBusy = (iconEnabled, message) => {
    return async dispatch => {
        dispatch({
            type: _BUSY,
            message: message,
            iconEnabled: iconEnabled
        });
    }
}

/// <summary>
/// Author : Yong Sheng Chuan
/// this function is to only update user status to logout
/// </summary>
export const setIdle = () => {
    return async dispatch => {
        dispatch({
            type: _IDLE
        });
    }
}

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
export const setPerformApiCall = (status, url) => {
    return async dispatch => {
        dispatch({
            type: _API_CALLED,
            data: { success: status, url: url}
        });
    }
}

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
export const showResponseMessage = (status, message, print, translateRequired = true) => {
    return dispatch => {
        var messageToShow = message;
        
        if (translateRequired) {
            messageToShow = i18n.t(message);
        }

        let printFunction = {};

        if (status && !stringIsNullOrEmpty(print)) {
            printFunction = {
                showCancel: true,
                cancelBtnText: i18n.t("PRINT"),
                onCancel: () => printReceipt(print),
                cancelBtnBsStyle: "success"
            }
        }

        dispatch(showMessage({ type: (status ? AlertTypes._SUCCESS : AlertTypes._ERROR), content: messageToShow, ...printFunction }));
    }
}

/// <summary>
/// Author: Chris
/// </summary>
export const showMessage = (alertProps) => {
    return dispatch => {
        const customConfirm = alertProps.onConfirm;
        alertProps.onConfirm = async (...args) => {
            disposeMessage()(await dispatch);
            if (customConfirm) {
                customConfirm(...args);
            }
        }
    
        const customCancel = alertProps.onCancel;
        alertProps.onCancel = async (...args) => {
            disposeMessage()(await dispatch);
            if (customCancel) {
                customCancel(...args);
            }
        }
    
        alertProps.cancelBtnBsStyle = alertProps.cancelBtnBsStyle ?? 'danger';
        alertProps.title = alertProps.title ?? "";
        alertProps.allowEscape = false;
        alertProps.reverseButtons = alertProps.reverseButtons ?? true;
    
        dispatch({ type: _ALERT_TYPES.SET_ALERT, alert: alertProps });
    }
}

/// <summary>
/// Author: Chris
/// </summary>
export const disposeMessage = () => {
    return dispatch => {
        dispatch({ type: _ALERT_TYPES.NONE });
    }
}