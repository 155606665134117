import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { ApiKey, ApiUrl, WebUrl, AccessRight, CommonStatus, TransactionTypeId, AlertTypes } from "../../../util/Constant";
import NavigationButton from '../../../components/custom/NavigationButton';
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import DateTime from 'react-datetime';
import Select from 'react-select';
import ApiEngine from '../../../util/ApiEngine.js';
import { showResponseMessage, showMessage } from 'redux/AppAction';
import moment from 'moment';
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from '../../../util/Util';

/// <summary>
/// Author : YJ
/// </summary>
const ManageAppealTransaction = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    const { register, handleSubmit, watch, setValue } = useForm();
    const [appealTransactionUrl, setAppealTransactionUrl] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('month').format("YYYY-MM-DD HH:mm:ss"));
    const [status, setStatus] = useState(CommonStatus._PENDING_STATUS);
    var _dispatch = useDispatch();
    const _STATUS_OPTION = [
        { label: t("PENDING"), value: CommonStatus._PENDING_STATUS },
        { label: t("APPROVED"), value: CommonStatus._APPROVE_STATUS },
        { label: t("REJECTED"), value: CommonStatus._REJECT_STATUS }
    ]

    let _tableColumns = useMemo(() => [
        {
            Header: "DATE",
            accessor: "updatedTime",
            disableSortBy: true
        },
        {
            Header: "MEMBER_USERNAME",
            accessor: "username",
            disableSortBy: true
        },
        {
            Header: "TYPE",
            accessor: "transactionTypeId",
            Cell: ({ row }) => {
                let transactionTypeString = "";

                switch (row.original.transactionTypeId) {
                    case TransactionTypeId._SET_SCORE:
                        transactionTypeString = t("SCORE");
                        break;
                    case TransactionTypeId._WALLET_TRANSFER:
                        transactionTypeString = t("WALLET");
                        break;
                    default:
                        transactionTypeString = "INVALID";
                        break;
                }

                return <span className="badge badge-primary">{transactionTypeString}</span>
            },
            disableSortBy: true
        },
        {
            Header: "PLAYER_USERNAME",
            accessor: "playerUsername",
            Cell: ({ row }) => {
                return <span>{!stringIsNullOrEmpty(row.original.playerUsername) ? row.original.playerUsername : "-"}</span>
            },
            disableSortBy: true
        },
        {
            Header: "AMOUNT",
            accessor: "amount",
            Cell: ({ row }) => {
                return <span>{numberWithCurrencyFormat(row.original.amount)}</span>
            },
            disableSortBy: true
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                let className = "secondary";
                let text = "PENDING"
                if (row.original.status == CommonStatus._APPROVE_STATUS)
                {
                    className = "success";
                    text = "APPROVED";
                }
                else if(row.original.status == CommonStatus._REJECT_STATUS)
                {
                    className = "danger";
                    text = "REJECTED";
                }
                else 
                {
                    className = "secondary";
                    text = "PENDING";
                }
                return <span class={"badge badge-secondary badge-" + className}>{t(text)}</span>
            },
            disableSortBy: true
        },
        {
            Header: "APPROVED_BY",
            accessor: "approvedByUsername",
            disableSortBy: true
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                (checkIfPermissionExist(AccessRight._APPEAL_TRANSACTION_CONTROL_PERMISSION, true) && row.original.status == CommonStatus._PENDING_STATUS) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => { 
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t("ARE_YOU_SURE"),
                                    content: t("ARE_YOU_SURE_YOU_WANT_TO_APPROVE_THIS_TRANSACTION"),
                                    showCancel: true,
                                    onConfirm: () => processAppealTransaction(row.original.id, CommonStatus._APPROVE_STATUS)
                                }));
                             }}><span className="text-success">{t("APPROVE")}</span></DropdownItem>
                            <DropdownItem onClick={() => { 
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t("ARE_YOU_SURE"),
                                    content: t("ARE_YOU_SURE_YOU_WANT_TO_REJECT_THIS_TRANSACTION"),
                                    showCancel: true,
                                    onConfirm: () => processAppealTransaction(row.original.id, CommonStatus._REJECT_STATUS)
                                }));
                             }}><span className="text-danger">{t("REJECT")}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ], [appealTransactionUrl]);

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function processAppealTransaction(transactionId, status) {
        try {
            let responseJson = await ApiEngine.post(ApiUrl._API_PROCESS_APPEAL_TRANSACTION + "?transactionId=" + transactionId + "&status=" + status);
            
            if (responseJson[ApiKey._API_SUCCESS_KEY])
            {
                handleSubmit(submitForm)();
            }
            
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
        catch(err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        handleSubmit(submitForm)();
    }, []);

    /// <summary>
    /// Author : YJ
    /// </summary>
    const submitForm = async (data, e) => {
        var rawUrl = ApiUrl._API_GET_APPEAL_TRANSACTION + "?v=" + Date.now();

        rawUrl += "&StartDate=" + startDate + "&EndDate=" + endDate + "&Status=" + status + "&Username=" + data.username;

        setAppealTransactionUrl(rawUrl);
    }

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_APPEAL_TRANSACTION")}</h1>
            </div>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("USERNAME")}</label>
                                    <input type="text" name="username" ref={register} className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label><b>{t("STATUS")}</b></label>
                                    <Select name="status" options={_STATUS_OPTION}
                                        placeholder={(_STATUS_OPTION.filter(option => option.value == status)[0] !== undefined) ? (_STATUS_OPTION.filter(option => option.value == status)[0].label) : ""}
                                        value={_STATUS_OPTION.filter(option => option.value == status)}
                                        onChange={(e) => {
                                            setStatus(e.value);
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel className="panel-react-table">
                <PanelBody>
                    <ReactTable fetchUrl={appealTransactionUrl} columns={_tableColumns} />
                </PanelBody>
            </Panel>
        </div>
    )
}

export default ManageAppealTransaction;