import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { WebUrl, ApiUrl, ApiKey, AlertTypes } from "../../../util/Constant";
import * as Util from "../../../util/Util";
import { showMessage } from "../../../redux/AppAction";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import NavigationButton from "../../../components/custom/NavigationButton";
import ApiEngine from "../../../util/ApiEngine.js";

/// <summary>
/// Author: CK
/// </summary>
const ThirdPartyDetail = () => {
	let _history = useHistory();
	let _location = useLocation();
	let _dispatch = useDispatch();
	const { register, handleSubmit, errors, setValue } = useForm();
	const { t } = useTranslation();
	const [selectedThirdParty, setSelectedThirdParty] = useState(null);
	
	/// <summary>
	/// Author: CK
	/// </summary>
	useEffect(() => {
		init();
	}, []);

	/// <summary>
	/// Author: Yong Sheng Chuan
	/// </summary>
	async function init() {
		if (_location.state && _location.state.id) {
			await getThirdPartyDetails(_location.state.id);
		}
	}

	/// <summary>
	/// Author: CK
	/// </summary>
	async function getThirdPartyDetails(id) {
		var responseJson = await ApiEngine.get(ApiUrl._API_GET_THIRD_PARTY + "/" + id);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let data = responseJson[ApiKey._API_DATA_KEY];

			setSelectedThirdParty(data);
			setValue("name", data.name);
			setValue("code", data.code);
			setValue("baseUrl", data.baseUrl);
			setValue("h5Url", data.h5Url);
			setValue("status", data.status);
		}
	}

	/// <summary>
	/// Author: CK
	/// Edited: A
	/// </summary>
	async function onSubmit(data) {
		if (selectedThirdParty) {
			data["id"] = selectedThirdParty.id;
		}

		var responseJson = await ApiEngine.post(ApiUrl._API_GET_THIRD_PARTY, Util.createMultiPartFormBody(data));
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setSelectedThirdParty(responseJson[ApiKey._API_DATA_KEY]);
		}

		_dispatch(
			showMessage({
				type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
				content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
				onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && Util.navigateTo(_history, WebUrl._MANAGE_THIRD_PARTY),
			})
		);
	}

	return (
		<div id="page-content">
			<form onSubmit={handleSubmit(onSubmit)}>
				<h1 className="page-header">
					{selectedThirdParty ? t("EDIT_THIRD_PARTY_DETAIL") : t("CREATE_THIRD_PARTY")}
					<NavigationButton history={_history} />
				</h1>
				<Panel>
					<PanelHeader noButton>{t("THIRD_PARTY_DETAIL")}</PanelHeader>
					<PanelBody>
						<div className="row">
							<div className="col-lg-3">
								<div className="form-group">
									<label>Name</label>
									<input className="form-control form-control-lg" type="text" id="name" name="name" defaultValue={selectedThirdParty?.name ?? ""} placeholder="Enter Name" ref={register({ required: "INPUT_FIELD_REQUIRED" })} />
									{errors["name"] && <div className="invalid-feedback">{t(errors["name"].message)}</div>}
								</div>
							</div>
							<div className="col-lg-3">
								<div className="form-group">
									<label>Code</label>
									<input className="form-control form-control-lg" type="text" id="code" name="code" defaultValue={selectedThirdParty?.code ?? ""} placeholder="Enter Code" ref={register({ required: "INPUT_FIELD_REQUIRED" })} />
									{errors["code"] && <div className="invalid-feedback">{t(errors["code"].message)}</div>}
								</div>
							</div>
							<div className="col-lg-3">
								<div className="form-group">
									<label>Base URL</label>
									<input className="form-control form-control-lg" type="text" id="baseUrl" name="baseUrl" defaultValue={selectedThirdParty?.baseUrl ?? ""} placeholder="Enter Base URL" ref={register({ required: "INPUT_FIELD_REQUIRED" })} />
									{errors["baseUrl"] && <div className="invalid-feedback">{t(errors["baseUrl"].message)}</div>}
								</div>
							</div>
							<div className="col-lg-3">
								<div className="form-group">
									<label>H5 URL</label>
									<input className="form-control form-control-lg" type="text" id="h5Url" name="h5Url" defaultValue={selectedThirdParty?.h5Url ?? ""} placeholder="Enter H5 Url" ref={register({ required: "INPUT_FIELD_REQUIRED" })} />
									{errors["h5Url"] && <div className="invalid-feedback">{t(errors["h5Url"].message)}</div>}
								</div>
							</div>
							<div className="col-lg-3">
								<div className="form-group">
									<label>{t("STATUS")}</label>
									<select className="form-control form-control-lg" id="status" name="status" ref={register()}>
										<option value={1}>{t("ACTIVE")}</option>
										<option value={0}>{t("DISABLE")}</option>
									</select>
								</div>
							</div>
						</div>
					</PanelBody>
					<hr className="my-0" />
					<PanelBody>
						<button type="submit" className="btn btn-primary">
							{selectedThirdParty ? t("BTN_SAVE") : t("CREATE")}
						</button>
					</PanelBody>
				</Panel>
			</form>
		</div>
	);
};

export default ThirdPartyDetail;
