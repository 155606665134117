import React, { useState, useEffect } from 'react';
import { ApiUrl } from '../../../util/Constant';
import { getValidationMessage } from '../../../util/Util';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import moment from 'moment';
import DateTime from 'react-datetime';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { useLocation } from "react-router-dom";

/// <summary>
/// Author: Wind
/// </summary>
const ApiLog = props => {
    const { t, i18n } = useTranslation();
    const _dispatch = useDispatch();
    const { register, handleSubmit, errors, unregister, setValue } = useForm();
    const [logUrl, setLogUrl] = useState('');
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

    let _apiLogTableColumns = [
        {
            Header: t("CREATED_DATE"),
            accessor: "createdAt",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{moment(row.original.createdAt).format('DD/MM/YYYY hh:mm:ss A')}</div>;
            },
        },
        {
            Header: t("USERNAME"),
            accessor: "username",
            disableSortBy: true
        },
        {
            Header: t("URI"),
            accessor: "uri",
            disableSortBy: true,
            style: {
                wordBreak: 'break-word'
            },
            minWidth: 350,
            width: 350
        },
        {
            Header: t("STATUS"),
            accessor: "status",
            disableSortBy: true
        },
        {
            Header: t("METHOD"),
            accessor: "method",
            disableSortBy: true
        },
        {
            Header: t("REQUEST_HEADER"),
            accessor: "requestHeader",
            disableSortBy: true,
            style: {
                wordBreak: 'break-word'
            },
            minWidth: 350,
            width: 350
        },
        {
            Header: t("REQUEST_BODY"),
            accessor: "requestBody",
            disableSortBy: true,
            style: {
                wordBreak: 'break-word'
            },
            minWidth: 350,
            width: 350
        },
        {
            Header: t("RESPONSE_CONTENT"),
            accessor: "responseContent",
            disableSortBy: true,
            style: {
                wordBreak: 'break-word'
            },
            minWidth: 350,
            width: 350
        },
        {
            Header: t("RESPONSE_MESSAGE"),
            accessor: "responseMessage",
            disableSortBy: true
        },
        {
            Header: t("DURATION"),
            accessor: "duration",
            disableSortBy: true
        },
    ];

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: true });
        setValue('startDate', moment().format("YYYY-MM-DD"));
        setValue('endDate', moment().format("YYYY-MM-DD"));
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    const onSubmit = async (data, e) => {
        var rawUrl = ApiUrl._API_GET_API_LOG + "?Username=" + data.username +
            "&StartDate=" + startDate + "&EndDate=" + endDate +
            "&Uri=" + data.uri + "&RequestPayLoad=" + data.requestPayload +
            "&ResponsePayLoad=" + data.responsePayload +
            "&Status=" + data.status +
            "&v=" + moment.now();
        setLogUrl(rawUrl);
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                    <h1 className="page-header">{t("API_LOG")}</h1>
                </div>
                <Panel>
                    <PanelBody>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        timeFormat={false}
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD"));
                                                setValue("startDate", e.format("YYYY-MM-DD"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                    {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        timeFormat={false}
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD"));
                                                setValue("endDate", e.format("YYYY-MM-DD"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                    {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("URI")}</label>
                                    <input
                                        type="text"
                                        name="uri"
                                        ref={register}
                                        className="form-control form-control-lg"
                                        placeholder={t("FULL_OR_PART_OF_THE_URI")} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("REQUEST_PAYLOAD")}</label>
                                    <input
                                        type="text"
                                        name="requestPayload"
                                        ref={register}
                                        className="form-control form-control-lg"
                                        placeholder={t("FULL_OR_PART_OF_THE_REQUEST_PAYLOAD")} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("RESPONSE_PAYLOAD")}</label>
                                    <input
                                        type="text"
                                        name="responsePayload"
                                        ref={register}
                                        className="form-control form-control-lg"
                                        placeholder={t("FULL_OR_PART_OF_THE_RESPONSE_PAYLOAD")} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("USERNAME")}</label>
                                    <input
                                        type="text"
                                        name="username"
                                        ref={register}
                                        className="form-control form-control-lg"
                                        placeholder={t("USERNAME")} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("STATUS")}</label>
                                    <input
                                        type="text"
                                        name="status"
                                        ref={register}
                                        className="form-control form-control-lg"
                                        placeholder={t("STATUS")} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('BTN_SUBMIT')}</button>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
                <Panel>
                    <div className="row">
                        <div className="col-lg-12">
                            <PanelBody>
                                <ReactTable fetchUrl={logUrl} columns={_apiLogTableColumns} initialPageSize={10} />
                            </PanelBody>
                        </div>
                    </div>
                </Panel>
            </form>
        </div>
    );
}

export default ApiLog;