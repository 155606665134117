import React, { useRef, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import NavigationButton from '../../../components/custom/NavigationButton';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, getValidationMessage } from "../../../util/Util";
import { ApiKey, ApiUrl, Role, AnnouncementType, WebUrl, AlertTypes } from "../../../util/Constant";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showResponseMessage, showMessage } from '../../../redux/AppAction';
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../../util/ApiEngine.js';
import 'react-intl-tel-input/dist/main.css';

/// <summary>
/// Author : YJ
/// </summary>
const PhoneMessageDetail = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    let _location = useLocation();
    let _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue } = useForm();

    const [messageId, setMessageId] = useState('');
    const [content, setContent] = useState();
    const [title, setTitle] = useState();
    const [status, setStatus] = useState(false);
    const [phoneOptions, setPhoneOptions] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [alertRequired, setAlertRequired] = useState(false);
    const _submitBtnRef = useRef(null);

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function init() {
        if (_location.state) {
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_SYSTEM_MESSAGE_BY_ID + "?id=" + _location.state.id);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setContent(responseJson[ApiKey._API_DATA_KEY]["content"]);
                setTitle(responseJson[ApiKey._API_DATA_KEY]["title"]);
                setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
                setMessageId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                let phones = [];

                responseJson[ApiKey._API_DATA_KEY]["phoneMessages"].map((phone) => {
                    phones.push({ label: phone.phone, value: phone.phone });
                })

                setPhoneOptions(phones.join(','));
            }
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        if (status) {
            _dispatch(showMessage({
                type: AlertTypes._WARNING,
                content: t("IT_WILL_BROADCAST_DIRECTLY_ARE_YOU_SURE") + "?",
                showCancel: true,
                confirmBtnText: t("CONFIRM"),
                onConfirm: () => { setIsConfirm(true); _submitBtnRef.current.click(); },
                onCancel: () => { setIsConfirm(false); }
            }));
        }
    }, [alertRequired])

    /// <summary>
    /// Author : YJ
    /// </summary>
    const submitForm = async (data, e) => {
        if (!isConfirm && status) {
            setAlertRequired(!alertRequired);
            return;
        }

        let params = {
            "phones": data.phone,
            "title": data.title,
            "content": data.content,
            "systemMessageTypeId": AnnouncementType._ANNOUNCEMENT_TYPE_PHONE,
            "status": status
        }

        if (!stringIsNullOrEmpty(messageId)) {
            params["id"] = messageId;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_SYSTEM_MESSAGE, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_PHONE_MESSAGE)
        }));
    }

    return (
        <div>
            <h1 className="page-header">{t("ADD_NEW_CUSTOMER_MESSAGE")}<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <div className="panel panel-inverse">
                        <div className="panel-heading ui-sortable-handle">
                            <h4 className="panel-title">{t("MESSAGE_DETAILS")}</h4>
                        </div>
                        <div className="panel-body">
                            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(submitForm)(); }}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-lg-3 col-form-label"><b>{t("PHONE")}</b></label>
                                            <div className="col-lg-5">
                                                <textarea class="form-control" rows="5"
                                                    ref={register({
                                                        required: true
                                                    })}
                                                    style={{ marginTop: "10px" }}
                                                    name="phone"
                                                    defaultValue={phoneOptions}>
                                                </textarea>
                                                {errors.phone && <div className="invalid-feedback">{t(getValidationMessage(errors.phone))}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-lg-3 col-form-label"><b>{t("TITLE")}</b></label>
                                            <div className="col-lg-5">
                                                <input type="text" name="title" className="form-control m-b-5"
                                                    ref={register({
                                                        required: t("PLEASE_ENTER_TITLE")
                                                    })}
                                                    placeholder={t("PLEASE_ENTER_TITLE")} defaultValue={title} />
                                                {errors.title && <div className="invalid-feedback">{t(errors.title.message)}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-lg-3 col-form-label"><b>{t("CONTENT")}</b></label>
                                            <div className="col-lg-5">
                                                <textarea class="form-control" rows="5"
                                                    ref={register({
                                                        required: t("PLEASE_ENTER_CONTENT")
                                                    })}
                                                    style={{ marginTop: "10px" }}
                                                    name="content"
                                                    defaultValue={content}>
                                                </textarea>
                                                {errors.content && <div className="invalid-feedback">{t(errors.content.message)}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-lg-3 col-form-label"><b>{t("BROADCAST")}</b></label>
                                            <div className="col-lg-5">
                                                <div className="switcher">
                                                    <input type="checkbox" name="status" id="status"
                                                        onChange={(e) => {
                                                            setStatus(e.target.checked);
                                                            setIsConfirm(!e.target.checked);
                                                        }}
                                                        value={status}
                                                        checked={status}
                                                        ref={register} />
                                                    <label htmlFor="status"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button type="submit" ref={_submitBtnRef} style={{ margin: "5px" }} className="btn btn-primary">{t("SUBMIT")}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(PhoneMessageDetail);