import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, Status, Role, WebUrl, AlertTypes } from "../../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, imagePathToFileObject } from "../../../util/Util";
import { useLocation } from "react-router-dom";
import { showResponseMessage } from '../../../redux/AppAction';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../../util/ApiEngine.js';
import NavigationButton from '../../../components/custom/NavigationButton';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import DualListBox from 'react-dual-listbox';
import { showMessage } from 'redux/AppAction';
import Dropzone from 'react-dropzone';

/// <summary>
/// Author : Andrew
/// Edited : CK - Add vendor Id 
/// </summary>
const MiniProgramDetail = props => {
    const { t } = useTranslation();
    var _location = useLocation();
    var _dispatch = useDispatch();
    var _history = useHistory();
    const { register, unregister, handleSubmit, errors, setValue, triggerValidation } = useForm();

    const [miniProgramId, setMiniProgramId] = useState('');
    const [status, setStatus] = useState(true);
    const [autoAddPlayer, setAutoAddPlayer] = useState(true);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isNewLogo, setIsNewLogo] = useState(false);
    const [logoFile, setLogoFile] = useState();
    const [logoImage, setLogoImage] = useState();
    const [navigationKey, setNavigationKey] = useState('');
    const [vendorList, setVendorList] = useState([]);
    const [ageTncRequired, setAgeTncRequired] = useState(true);
    const [ageTncDescription, setAgeTncDescription] = useState('');
    const [programUrl, setProgramUrl] = useState('');

    /// <summary>
    /// Author : Andrew
    /// </summary>
    const imageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setLogoFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setIsNewLogo(true);
                setLogoImage(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }

    /// <summary>
    /// Author : Andrew
    /// Edited : CK - Response error handling
    /// </summary>
    const submitForm = async (data, e) => {
        if (stringIsNullOrEmpty(logoImage)) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t("PLEASE_UPLOAD_LOGO_IMAGE")
            }));
        }
        else {
            if (logoFile) {
                data["logo"] = logoFile;
                data["isNewLogo"] = isNewLogo;
            }

            if (!stringIsNullOrEmpty(miniProgramId)) {
                data["id"] = miniProgramId;
            }

            var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_MINI_PROGRAM, createMultiPartFormBody(data));

            _dispatch(showMessage({
                type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
                content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
                onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_MINI_PROGRAM)
            }));
        }
    }

    /// <summary>
    /// Author : Andrew
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : Andrew
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_LIST);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setVendorList(responseJson[ApiKey._API_DATA_KEY]);
        }

        if (_location.state) {
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_MINI_PROGRAM_BY_ID + "?id=" + _location.state["id"]);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setMiniProgramId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                setName(responseJson[ApiKey._API_DATA_KEY]["name"]);
                setDescription(responseJson[ApiKey._API_DATA_KEY]["description"]);
                setNavigationKey(responseJson[ApiKey._API_DATA_KEY]["navigationKey"]);
                setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
                setLogoImage(responseJson[ApiKey._API_DATA_KEY]["logo"]);
                setAutoAddPlayer(responseJson[ApiKey._API_DATA_KEY]["autoAddPlayer"]);
                setAgeTncRequired(responseJson[ApiKey._API_DATA_KEY]["ageTncRequired"]);
                setAgeTncDescription(responseJson[ApiKey._API_DATA_KEY]['ageTncDescription']);
                setProgramUrl(responseJson[ApiKey._API_DATA_KEY]['programUrl']);

                if (responseJson[ApiKey._API_DATA_KEY]["vendorId"] > 0) {
                    setValue("vendorId", responseJson[ApiKey._API_DATA_KEY]["vendorId"]);
                }
            }
        }
    }

    return (
        <div>
            <h1 className="page-header">{!stringIsNullOrEmpty(miniProgramId) ? t("EDIT_MINI_PROGRAM") : t("ADD_MINI_PROGRAM")}<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Panel>
                            <PanelHeader children={t('MINI_PROGRAM_INFO')} noButton="true"></PanelHeader>
                            <PanelBody>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>{t("NAME")}</label>
                                            <input type="text" class="form-control form-control-lg" name="name" defaultValue={name} ref={register({ required: "PLEASE_ENTER_NAME" })} placeholder={t("PLEASE_ENTER_NAME")} />
                                            {errors.name && <div className="invalid-feedback">{t(errors.name.message)}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>{t("DESCRIPTION")}</label>
                                            <input type="text" class="form-control form-control-lg" name="description" defaultValue={description} ref={register} placeholder={t("PLEASE_ENTER_DESCRIPTION")} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>{t("NAVIGATION_KEY")}</label>
                                            <input type="text" class="form-control form-control-lg" name="navigationKey" defaultValue={navigationKey} ref={register({ required: "PLEASE_ENTER_NAVIGATION_KEY" })} placeholder={t("PLEASE_ENTER_NAVIGATION_KEY")} />
                                            {errors.navigationKey && <div className="invalid-feedback">{t(errors.navigationKey.message)}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>{t("VENDOR")}</label>
                                            <select
                                                className="form-control form-control-lg"
                                                id="vendorId"
                                                name="vendorId"
                                                ref={register}>
                                                <option value={''}>-</option>
                                                {
                                                    vendorList.map(vendor => {
                                                        return (
                                                            <option value={vendor.id.toString()}>{vendor.name} ({vendor.shortName})</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>{t("PROGRAM_URL")}</label>
                                            <input type="text" class="form-control form-control-lg" name="programUrl" defaultValue={programUrl} ref={register} placeholder={t("PLEASE_ENTER_PROGRAM_URL")} />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>{t("STATUS")}</label>
                                            <div>
                                                <div className="switcher">
                                                    <input type="checkbox" name="status" id="status"
                                                        onChange={(e) => setStatus(e.target.checked)}
                                                        value={true}
                                                        checked={status}
                                                        ref={register} />
                                                    <label htmlFor="status"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>{t("AUTO_ADD_PLAYER")}</label>
                                            <div>
                                                <div className="switcher">
                                                    <input type="checkbox" name="autoAddPlayer" id="autoAddPlayer"
                                                        onChange={(e) => setAutoAddPlayer(e.target.checked)}
                                                        value={true}
                                                        checked={autoAddPlayer}
                                                        ref={register} />
                                                    <label htmlFor="autoAddPlayer"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>{t("AGE_TERM_CONDITION")}</label>
                                            <div>
                                                <div className="switcher">
                                                    <input type="checkbox" name="ageTncRequired" id="ageTncRequired"
                                                        onChange={(e) => setAgeTncRequired(e.target.checked)}
                                                        value={true}
                                                        checked={ageTncRequired}
                                                        ref={register} />
                                                    <label htmlFor="ageTncRequired"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 row">
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <label>{t("LOGO")}</label>
                                                <Dropzone accept={'image/*'} onDrop={acceptedFiles => imageDrop(acceptedFiles)}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section>
                                                            <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                {stringIsNullOrEmpty(logoImage) && <h4 style={{ color: "grey" }} >{t("UPLOAD_LOGO")}</h4>}
                                                                {!stringIsNullOrEmpty(logoImage) && <aside className="thumbsContainer">
                                                                    <div className="thumb">
                                                                        <div className="thumbInner">
                                                                            <img
                                                                                src={logoImage}
                                                                                className="dropzone-img"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </aside>}
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                                <button type="button" className="mt-2 btn btn-danger"
                                                    onClick={() => {
                                                        setLogoImage("");
                                                        setLogoFile();
                                                    }}>
                                                    {t("REMOVE_IMAGE")}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <label>{t("AGE_TERM_CONDITION_DESCRIPTION")}</label>
                                            <textarea
                                                placeholder={t("PLEASE_ENTER_AGE_TNC_DESCRIPTION")}
                                                class="form-control form-control-lg" 
                                                name="ageTncDescription" 
                                                defaultValue={ageTncDescription} 
                                                ref={register}
                                                style={{ resize: "none", minHeight: "200px" }}
                                                aria-label="With textarea">
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-primary">{t("BTN_SUBMIT")}</button>
                            </PanelBody>
                        </Panel>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default MiniProgramDetail;