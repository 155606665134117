import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { navigateTo, createMultiPartFormBody } from "../../../util/Util";
import NavigationButton from '../../../components/custom/NavigationButton';
import { ApiKey, WebUrl, ApiUrl, CommonStatus, AlertTypes } from "../../../util/Constant";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage, showMessage } from "../../../redux/AppAction";
import ApiEngine from '../../../util/ApiEngine.js';
import moment from 'moment';
import DateTime from 'react-datetime';

/// <summary>
/// Author : Wind
/// </summary>
const ManageShopLogin = props => {
    var { t, i18n } = useTranslation();
    const { register, handleSubmit, setValue } = useForm();
    var _dispatch = useDispatch();
    const [loginRequest, setLoginRequest] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));

    let _tableColumns = [
        {
            Header: t("SHOP"),
            Cell: ({ row }) => {
                return (
                    <div>
                        <div className="td-singleline">{row.original.shopUser} ({row.original.shopName})</div>
                    </div>
                );
            },
            disableSortBy: true
        },
        {
            Header: t("REQUEST_TIME"),
            accessor: "requestTime",
            disableSortBy: true
        },
        {
            Header: t("STATUS"),
            accessor: "status",
            Cell: ({ row }) => {
                return renderStatus(row.original.status);
            },
            disableSortBy: true
        },
        {
            Header: t("IP_ADDRESS"),
            accessor: "ipAddress",
            disableSortBy: true
        },
        {
            Header: t("USER_AGENT"),
            accessor: "userAgent",
            disableSortBy: true
        },
        {
            Header: t("OPERATED_BY"),
            Cell: ({ row }) => {
                return (
                    <div>
                        <div className="td-singleline">{row.original.operatedBy} ({row.original.operatedByName})</div>
                    </div>
                );
            },
            disableSortBy: true
        },
        {
            Header: t("OPERATED_TIME"),
            accessor: "updatedTime",
            disableSortBy: true
        },
        {
            Header: t("ACTION"),
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row }) => (
                row.original.status == CommonStatus._PENDING_STATUS &&
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t("ARE_YOU_SURE"),
                                    content: t("ARE_YOU_SURE_YOU_WANT_TO_APPROVE_SHOP_LOGIN") + '(' + row.original.shopUser + ')',
                                    showCancel: true,
                                    onConfirm: () => updateRequest(row.original.id, CommonStatus._APPROVE_STATUS)
                                }));
                            }}><span className="text-green">{t('APPROVE')}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t("ARE_YOU_SURE"),
                                    content: t("ARE_YOU_SURE_YOU_WANT_TO_REJECT_SHOP_LOGIN") + '(' + row.original.shopUser + ')',
                                    showCancel: true,
                                    onConfirm: () => updateRequest(row.original.id, CommonStatus._REJECT_STATUS)
                                }));
                            }}><span className="text-red">{t('REJECT')}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            width: 100,
            style: { overflow: "visible" }
        }
    ];

    /// <summary>
    /// Author : Wind
    /// </summary>      
    async function updateRequest(requestId, status) {
        var responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_SHOP_LOGIN_REQUEST + "?Id=" + requestId + "&Status=" + status);

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleSubmit(submitForm)();
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        register({ name: 'status' });
        register({ name: 'startDate' });
        register({ name: 'endDate' });

        setValue('status', CommonStatus._PENDING_STATUS);
        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));

        handleSubmit(submitForm)();
    }, []);

    /// <summary>
    /// Author : Wind
    /// </summary>
    function renderStatus(status) {
        let statusString = "";
        let badgeString = "";

        switch (status) {
            case CommonStatus._PENDING_STATUS:
                statusString = t("PENDING");
                badgeString = "badge badge-primary";
                break;
            case CommonStatus._APPROVE_STATUS:
                statusString = t("APPROVED");
                badgeString = "badge badge-success";
                break;
            case CommonStatus._REJECT_STATUS:
                statusString = t("REJECTED");
                badgeString = "badge badge-danger";
                break;
            default:
                statusString = t("EXPIRED");
                badgeString = "badge badge-secondary";
                break;
        }

        return <span className={badgeString}>{statusString}</span>
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    const submitForm = async (data, e) => {
        let filterStatus = "";
        if (data.status != CommonStatus._ALL_STATUS) {
            filterStatus = "&Status=" + data.status
        }
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SHOP_LOGIN_REQUEST + "?StartDate=" + data.startDate +
            "&EndDate=" + data.endDate + filterStatus);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setLoginRequest(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    return (
        <div>
            <h1 className="page-header">{t('MANAGE_SHOP_LOGIN')}</h1>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>{t("STATUS")}</label>
                                            <select name="status" ref={register} className="form-control form-control-lg">
                                                <option value={CommonStatus._ALL_STATUS}>{t("ALL")}</option>
                                                <option value={CommonStatus._PENDING_STATUS}>{t("PENDING")}</option>
                                                <option value={CommonStatus._APPROVE_STATUS}>{t("APPROVED")}</option>
                                                <option value={CommonStatus._REJECT_STATUS}>{t("REJECTED")}</option>
                                                <option value={CommonStatus._INACTIVE_STATUS}>{t("EXPIRED")}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>{t("START_DATE")}</label>
                                            <DateTime
                                                inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                                name="startDate"
                                                ref={register}
                                                timeFormat="HH:mm:ss"
                                                dateFormat="YYYY-MM-DD"
                                                closeOnSelect={true}
                                                value={startDate}
                                                onChange={(e) => {
                                                    if (e instanceof moment) {
                                                        setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                        setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                                    }
                                                    else {
                                                        setValue("startDate", "");
                                                    }
                                                }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>{t("END_DATE")}</label>
                                            <DateTime
                                                inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                                name="endDate"
                                                ref={register}
                                                timeFormat="HH:mm:ss"
                                                dateFormat="YYYY-MM-DD"
                                                closeOnSelect={true}
                                                value={endDate}
                                                onChange={(e) => {
                                                    if (e instanceof moment) {
                                                        setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                        setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                                    }
                                                    else {
                                                        setValue("endDate", "");
                                                    }
                                                }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <button className="btn btn-primary" type="submit">{t('APPLY_FILTER')}</button>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <Panel>
                <PanelBody>
                    <ReactTable data={loginRequest} columns={_tableColumns} globalFilterable={false} />
                </PanelBody>
            </Panel>
        </div>
    );
}

export default ManageShopLogin;