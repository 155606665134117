import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { showResponseMessage, showMessage } from "../../redux/AppAction";
import { ApiKey, ApiUrl, AlertTypes, Role } from "../../util/Constant";
import { useForm } from "react-hook-form";
import * as Util from '../../util/Util';
import Select from "react-select";
import ApiEngine from '../../util/ApiEngine.js';
import { stringIsNullOrEmpty, getValidationMessage } from '../../util/Util';
import { useDispatch, useSelector } from "react-redux";

/// <summary>
/// Author : CK
/// </summary>
const CreateEWalletTransactionModal = (props) => {
    const _EWALLET_TYPE = [
        { title: 'Touch N Go', key: 101 },
        { title: 'GrabPay', key: 102 },
        { title: 'ShopeePay', key: 103 },
        { title: 'Boost', key: 104 },
        { title: 'DuitNow', key: 105 }
    ]

    var _userId = useSelector(state => state['authState']['userData']['userId']);
    const [modalVisibility, setModalVisibility] = useState(false);
    const { register, handleSubmit, errors } = useForm();
    const [mxPayHtml, setMxPayHtml] = useState("");
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(_EWALLET_TYPE[0]['key']);
    const { t, i18n } = useTranslation();
    var _dispatch = useDispatch();

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        setModalVisibility(props["visible"]);
    }, [props["visible"]]);

    /// <summary>
    /// Author : CK
    /// </summary>
    const onSubmit = async (data, e) => {
        try {
            _dispatch(showMessage({
                content: t("VERIFY_PASSWORD_BEFORE_CONTINUE"),
                showCancel: true,
                required: true,
                inputType: 'password',
                validationMsg: t('INVALID_PASSWORD'),
                confirmBtnText: t("CONFIRM"),
                type: AlertTypes._INPUT,
                onConfirm: async (value) => {
                    var responseJson = await ApiEngine.post(`${ApiUrl._API_VERIFY_PASSWORD}?userId=${_userId}&password=${value}`);

                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        let apiUrl = ApiUrl._API_CREATE_EWALLET_TRANSACTION + "?phoneNumber=" + data.phoneNumber + "&transferProvider=" + selectedPaymentMethod.toString() + "&amount=" + data.amount;
                        responseJson = await ApiEngine.post(apiUrl);
                        setMxPayHtml(responseJson);
                    }
                    else {
                        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
                    }
                }
            }));
        }
        catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    return (
        <Modal isOpen={modalVisibility} size="lg">
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={() => props.onFinish()}>
                    <h4>{t('CREATE_EWALLET_TRANSACTION')}</h4>
                </ModalHeader>
                <ModalBody>
                    <div className="form-group row m-b-15">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("PHONE_NUMBER")}</label>
                                <input
                                    ref={register({ required: true })}
                                    type="text"
                                    name="phoneNumber"
                                    className="form-control form-control-lg"
                                />
                                {errors.phoneNumber && <div className="invalid-feedback">{t(getValidationMessage(errors.phoneNumber))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("AMOUNT")}</label>
                                <input
                                    ref={register({ required: true })}
                                    name="amount"
                                    type={'number'}
                                    step={'any'}
                                    className="form-control form-control-lg"
                                />
                                {errors.amount && <div className="invalid-feedback">{t(getValidationMessage(errors.amount))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label>{t("PAYMENT_METHOD")}</label>
                                <div className="container">
                                    <div className="row">
                                        {
                                            _EWALLET_TYPE.map((item) => {
                                                return (
                                                    <div className="col-md-3 form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="transferProvider"
                                                            id={"transferProvider[" + item.key + "]"}
                                                            value={item.key}
                                                            checked={selectedPaymentMethod == item.key}
                                                            onChange={(e) => { setSelectedPaymentMethod(e.target.value); }} />
                                                        <label className="form-check-label" for={"transferProvider[" + item.key + "]"}>
                                                            {item.title}
                                                        </label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <iframe
                            width="100%"
                            height="500px"
                            srcdoc={mxPayHtml}
                        >
                        </iframe>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-primary">{t('SUBMIT')}</button>
                    <button type="button" className="btn btn-white"
                        onClick={() => {
                            setMxPayHtml("");
                            props.onFinish();
                        }}>{t('BTN_CLOSE')}</button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default CreateEWalletTransactionModal