import React, { useState, useEffect } from 'react';
import { ApiKey, ApiUrl, AlertTypes, AccessRight } from '../../util/Constant';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { stringIsNullOrEmpty, createMultiPartFormBody, createFormBody } from '../../util/Util';
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";

/// <summary>
/// Author: CK
/// </summary>
const RankVendorRateModal = ({
    isModalVisible = false,
    onFinish,
    rankList = [],
}) => {
    const { t, i18n } = useTranslation();
    const _dispatch = useDispatch();
    const { register, handleSubmit, setValue, watch, reset } = useForm();
    const [vendorList, setVendorList] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState();
    const [addedVendorList, setAddedVendorList] = useState([]);
    const [defaultRankVendorRates, setDefaultRankVendorRates] = useState([]);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const _INPUT_ARRAY_SIZE = 3;
    const _RATE_TYPE_INDEX = 0;
    const _RANK_ID_INDEX = 1;
    const _VENDOR_ID_INDEX = 2;
    const _RATE_INPUT_KEY = {
        SelfWinlossRate: "SW",
        SelfTurnoverRate: "ST",
        DownlineWinlossRate: "DW",
        DownlineTurnoverRate: "DT",
    }
    const _RATE_INPUT_OPTIONS = [
        {
            label: t('SELF_WINLOSS_RATE') + " (%)",
            key: _RATE_INPUT_KEY["SelfWinlossRate"],
            variableName: "SelfWinlossRate"
        },
        {
            label: t('SELF_TURNOVER_RATE') + " (%)",
            key: _RATE_INPUT_KEY["SelfTurnoverRate"],
            variableName: "SelfTurnoverRate"
        },
        {
            label: t('DOWNLINE_WINLOSS_RATE') + " (%)",
            key: _RATE_INPUT_KEY["DownlineWinlossRate"],
            variableName: "DownlineWinlossRate"
        },
        {
            label: t('DOWNLINE_TURNOVER_RATE') + " (%)",
            key: _RATE_INPUT_KEY["DownlineTurnoverRate"],
            variableName: "DownlineTurnoverRate"
        }
    ]

    /// <summary>   
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (isModalVisible) {
            init();
        }
        else {
            setIsFirstLoad(true);
            setAddedVendorList([]);
            setDefaultRankVendorRates([]);
            reset();
        }
    }, [isModalVisible]);

    /// <summary>   
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (vendorList.length > 0) {
            setSelectedVendor(vendorList[0]['id']);
        }
    }, [vendorList]);

    /// <summary>   
    /// Author: CK
    /// </summary>
    useEffect(() => {
        // Change selected vendor 
        let remainingVendorList = vendorList.filter(i => !addedVendorList.some(j => j.id == i.id));
        setSelectedVendor(remainingVendorList.length > 0 ? remainingVendorList[0].id.toString() : "");
    }, [addedVendorList]);

    /// <summary>   
    /// Author: CK
    /// Set default value for the commission rate
    /// </summary>
    useEffect(() => {
        if (isFirstLoad && defaultRankVendorRates.length > 0) {
            defaultRankVendorRates.map(rate => {
                setValue(`${_RATE_INPUT_KEY["SelfWinlossRate"]}_${rate.rankId}_${rate.vendorId}`, Number(rate.selfWinlossRate));
                setValue(`${_RATE_INPUT_KEY["SelfTurnoverRate"]}_${rate.rankId}_${rate.vendorId}`, Number(rate.selfTurnoverRate));
                setValue(`${_RATE_INPUT_KEY["DownlineWinlossRate"]}_${rate.rankId}_${rate.vendorId}`, Number(rate.downlineWinlossRate));
                setValue(`${_RATE_INPUT_KEY["DownlineTurnoverRate"]}_${rate.rankId}_${rate.vendorId}`, Number(rate.downlineTurnoverRate));
            });

            setIsFirstLoad(false);
        }
    }, [defaultRankVendorRates]);

    /// <summary>
    /// Author : CK
    /// </summary>
    async function init() {
        try {
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_LIST);
            let currentVendorList = [];

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                currentVendorList = responseJson[ApiKey._API_DATA_KEY];
                setVendorList(currentVendorList);
            }

            responseJson = await ApiEngine.get(ApiUrl._API_RANK_VENDOR_RATE);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let rankVendorRates = responseJson[ApiKey._API_DATA_KEY];
                setAddedVendorList([...currentVendorList.filter(i => rankVendorRates.some(j => j.vendorId == i.id))]);
                setDefaultRankVendorRates(rankVendorRates);
            }
        }
        catch (err) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(err),
            }));
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function deleteRankVendorRate(vendorId) {
        _dispatch(showMessage({
            type: AlertTypes._WARNING,
            showCancel: true,
            content: t("DELETE_RANK_VENDOR_RATE_CONFIRMATION"),
            onConfirm: async () => {
                try {
                    var responseJson = await ApiEngine.delete(ApiUrl._API_RANK_VENDOR_RATE + "/" + vendorId);
            
                    if (responseJson[ApiKey._API_SUCCESS_KEY], ApiUrl._API_RANK_VENDOR_RATE + "/" + vendorId) {
                        let currentAddedVendorList = [...addedVendorList];
                        setAddedVendorList([...currentAddedVendorList.filter(i => i.id != vendorId)]);

                        _dispatch(showMessage({
                            type: AlertTypes._SUCCESS,
                            content: t("OPERATION_SUCCESS")
                        }));
                    }
                    else {
                        throw responseJson[ApiKey._API_MESSAGE_KEY];
                    }
                }
                catch (err) {
                    _dispatch(showMessage({
                        type: AlertTypes._ERROR,
                        content: t(err),
                    }));
                }
            }
        }));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function onSubmit(data) {
        _dispatch(showMessage({
            type: AlertTypes._WARNING,
            showCancel: true,
            content: t("EDIT_RANK_VENDOR_RATE_CONFIRMATION"),
            onConfirm: async () => {
                try {
                    let rankVendorRateData = [];

                    for (var key in data) {
                        let inputValues = key.split("_");

                        // Valid input value when the splitted size is 3
                        if (inputValues.length == _INPUT_ARRAY_SIZE) {
                            // Search if the rank vendor rate alr existed
                            let selectedRankVendorRate = rankVendorRateData.find(i => i.RankId == inputValues[_RANK_ID_INDEX] && i.VendorId == inputValues[_VENDOR_ID_INDEX]);

                            // If rank vendor existed, update the value of the selected rate type
                            if (selectedRankVendorRate) {
                                let variableName = _RATE_INPUT_OPTIONS.find(i => i.key == inputValues[_RATE_TYPE_INDEX]).variableName;
                                selectedRankVendorRate[variableName] = Number(data[key]);
                            }
                            else {
                                selectedRankVendorRate = {
                                    RankId: Number(inputValues[_RANK_ID_INDEX]),
                                    VendorId: Number(inputValues[_VENDOR_ID_INDEX])
                                };

                                _RATE_INPUT_OPTIONS.map(rateType => {
                                    if (rateType.key == inputValues[_RATE_TYPE_INDEX]) {
                                        selectedRankVendorRate[rateType.variableName] = Number(data[key]);
                                    }
                                    else {
                                        selectedRankVendorRate[rateType.variableName] = 0;
                                    }
                                })
                            }

                            // Set the updated value of Rank by Vendor rate to the params array
                            rankVendorRateData = [...rankVendorRateData.filter(i => !(i.RankId == selectedRankVendorRate.RankId && i.VendorId == selectedRankVendorRate.VendorId)), selectedRankVendorRate];
                        }
                    }

                    let params = {
                        RankVendorRates: rankVendorRateData
                    }

                    var responseJson = await ApiEngine.post(ApiUrl._API_RANK_VENDOR_RATE, params);

                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        _dispatch(showMessage({
                            type: AlertTypes._SUCCESS,
                            content: t("OPERATION_SUCCESS")
                        }));
                    }
                    else {
                        throw responseJson[ApiKey._API_MESSAGE_KEY];
                    }
                }
                catch (err) {
                    _dispatch(showMessage({
                        type: AlertTypes._ERROR,
                        content: t(err),
                    }));
                }
            }
        }));
    }

    return (
        <Modal isOpen={isModalVisible} toggle={() => { onFinish() }} size="xl">
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={() => { onFinish() }}><h4>{t("RANK_VENDOR_RATE")}</h4></ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="form-group">
                                <label>{t("VENDOR")}</label>
                                <div className="row-space-between">
                                    <select
                                        style={{ flex: 1 }}
                                        className="form-control form-control-lg"
                                        id="vendorId"
                                        name="vendorId"
                                        value={selectedVendor}
                                        onChange={(event) => {
                                            setSelectedVendor(event.target.value);
                                        }}
                                    >
                                        {
                                            vendorList.filter(i => !addedVendorList.some(j => j.id == i.id)).map(vendor => {
                                                return (
                                                    <option value={vendor.id.toString()}>{!stringIsNullOrEmpty(vendor.gameName) ? vendor.gameName : `${vendor.name} (${vendor.shortName})`}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <button type="button"
                                        className={"btn btn-primary m-l-10"}
                                        onClick={() => {
                                            if (selectedVendor) {
                                                let selectedVendorObj = vendorList.find(i => i.id == selectedVendor);

                                                if (selectedVendor && !addedVendorList.find(i => i.id == selectedVendor)) {
                                                    setAddedVendorList([...addedVendorList, selectedVendorObj]);
                                                }
                                            }
                                        }}
                                    >
                                        <i className={"fas fa-plus-circle m-r-10 f-s-12"}></i>{t("ADD")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'table-responsive'}>
                        {
                            rankList.length == 0 ?
                                <div className="m-t-30">
                                    <p className="text-center">{t('EMPTY_RANK_PLEASE_ADD_TO_PROCEED')}</p>
                                </div>
                                :
                                <table className={'table table-excel-compact table-bordered table-rank-vendor'}>
                                    <thead>
                                        <tr>
                                            <th />
                                            <th />
                                            <th />
                                            {
                                                rankList.map((rank, index) => {
                                                    return <th className={'text-center'} key={index}>
                                                        {t(rank['translatedName'])}
                                                        <input
                                                            placeholder={t('MASS_UPDATE')}
                                                            className={'form-control'}
                                                            type={'number'}
                                                            step={'any'}
                                                            defaultValue={''}
                                                            onInput={(e) => {
                                                                addedVendorList.map(vendor => {
                                                                    _RATE_INPUT_OPTIONS.map(inputType => {
                                                                        setValue(`${inputType.key}_${rank.id}_${vendor.id}`, Number(e.target.value));
                                                                    })
                                                                })
                                                            }} />
                                                    </th>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            addedVendorList.map((vendor, index) => {
                                                return (
                                                    <tr key={vendor.id}>
                                                        <th width="35px">
                                                            <button
                                                                type="button"
                                                                className="btn btn-default btn-icon btn-circle"
                                                                onClick={() => {
                                                                    deleteRankVendorRate(vendor.id);
                                                                }}
                                                            ><i className={"fas fa-trash-alt f-s-15"}></i>
                                                            </button>
                                                        </th>
                                                        <th width="135px">{!stringIsNullOrEmpty(vendor.gameName) ? vendor.gameName : `${vendor.name} (${vendor.shortName})`}</th>
                                                        <td width="280px">
                                                            {
                                                                _RATE_INPUT_OPTIONS.map(item => {
                                                                    return (
                                                                        <div className="table-item-col">
                                                                            <label>{item.label}</label>
                                                                            <input
                                                                                placeholder={t('MASS_UPDATE')}
                                                                                className={'form-control'}
                                                                                type={'number'}
                                                                                step={'any'}
                                                                                defaultValue={''}
                                                                                onInput={(e) => {
                                                                                    rankList.map(rank => {
                                                                                        setValue(`${item.key}_${rank.id}_${vendor.id}`, Number(e.target.value));
                                                                                    })
                                                                                }} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </td>
                                                        {
                                                            rankList.map((rank, index) => {
                                                                return (
                                                                    <td key={rank.id}>
                                                                        {
                                                                            _RATE_INPUT_OPTIONS.map(item => {
                                                                                return (
                                                                                    <input
                                                                                        key={`${item.key}_${rank.id}_${vendor.id}`}
                                                                                        id={`${item.key}_${rank.id}_${vendor.id}`}
                                                                                        name={`${item.key}_${rank.id}_${vendor.id}`}
                                                                                        value={watch(`${item.key}_${rank.id}_${vendor.id}`)}
                                                                                        ref={register}
                                                                                        defaultValue={0}
                                                                                        className={`form-control package-input input-reference`}
                                                                                        type={'number'}
                                                                                        step={'any'}
                                                                                        onBlur={(e) => {
                                                                                            setValue(`${item.key}_${rank.id}_${vendor.id}`, Number(e.target.value));
                                                                                        }}
                                                                                    />
                                                                                )
                                                                            })
                                                                        }
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    {
                        checkIfPermissionExist(AccessRight._RANK_CONTROL_PERMISSION, true) &&
                        <button type="submit" className="btn btn-primary btn-action">{t("BTN_SUBMIT")}</button>
                    }
                </ModalFooter>
            </form >
        </Modal >
    );
}

export default RankVendorRateModal;