import { setSearchMemberModalVisible } from "../../redux/MemberAction";
import { WebUrl, AccessRight } from "../../util/Constant";

/// <summary>
/// Author : Andrew
/// </summary>
const Menu = [
    {
        path: WebUrl._URL_SHOP_SEARCH, title: 'NAV_SEARCH', isDummy: false
    },
    {
        title: "NAV_RELOAD",
        action: setSearchMemberModalVisible(true, { mainUrl: WebUrl._URL_SHOP_RELOAD, alternateUrl: WebUrl._URL_SHOP_PLAYER_RELOAD }),
        isDispatch: true,
        isDummy: false
    },
    {
        title: "NAV_AGENT_RELOAD",
        action: setSearchMemberModalVisible(true, null, true),
        isDispatch: true,
        isDummy: false,
        isRequiredAgentReload: true
    },
    {
        title: "NAV_NEW_MEMBERSHIP",
        path: WebUrl._URL_SHOP_REGISTER_MEMBER,
        isDummy: false
    },
    {
        title: "NAV_NEW_PLAYER",
        path: WebUrl._URL_SHOP_REGISTER_PLAYER,
        isDummy: false
    },
    {
        title: "NAV_RELOAD_PIN_COMM",
        path: WebUrl._URL_SHOP_AGENT_COMMISSION_REPORT,
        isDummy: false,
        isAgentOnly: true
    },
    {
        title: "NAV_RELOAD_PIN",
        path: WebUrl._URL_SHOP_TRANSACTION_REQUEST,
        isDummy: false
    },
    {
        title: "NAV_CREDIT", isDummy: true
    },
    {
        title: "NAV_BLOCK_DELETE_PUK",
        action: setSearchMemberModalVisible(true, { mainUrl: WebUrl._URL_SHOP_BLOCK_PUK, alternateUrl: "" }),
        isDispatch: true,
        isDummy: false
    },
    {
        title: "NAV_RELOAD_INTERNATIONAL",
        action: setSearchMemberModalVisible(true, { mainUrl: WebUrl._URL_SHOP_CLAIM_BONUS, alternateUrl: WebUrl._URL_SHOP_CLAIM_BONUS }),
        isDispatch: true,
        isDummy: false
    },
    {
        title: "NAV_RELOAD_BATCH", 
        path: WebUrl._URL_SHOP_LIMIT_APPROVAL_REPORT,
        isDummy: false,
    },
    {
        path: WebUrl._URL_ONLINE_WITHDRAWAL_REQUEST, 
        title: 'ONLINE_WITHDRAWAL', 
        isDummy: false,
        isCustomerServiceOnly: true,
        onlineWithdrawalShop: true
    },
    {
        title: "NAV_USER_INFO", isDummy: true
    },
    {
        title: "NAV_BANK_IN", isDummy: true
    },
    {
        title: "NAV_TRANSACTION_HISTORY",
        isCustomerServiceOnly: false,
        isDummy: true
    },
    {
        title: "NAV_TRANSACTION_HISTORY",
        path: WebUrl._URL_SHOP_CREDIT_TOPUP_REPORT,
        isDummy: false,
        isCustomerServiceOnly: true
    },
    {
        title: "NAV_REPORT",
        path: WebUrl._URL_SHOP_REPORT,
        isDummy: false
    },
    {
        title: "NAV_REPORT_USER_SALES",
        path: WebUrl._URL_SHOP_CREDIT_TRANSFER_REPORT,
        isDummy: false,
        isCustomerServiceOnly: true
    },
    {
        title: "NAV_REPORT_USER_SALES",
        isDummy: true,
        isCustomerServiceOnly: false
    },
    {
        title: "PLAYER_CREATION_REPORT", 
        path: WebUrl._URL_SHOP_PLAYER_CREATION_REPORT,
        isDummy: false,
    },
    {
        title: "NAV_OUTGOING_MESSAGE", isDummy: true
    },
    {
        title: "NAV_SMS_COMMAND", isDummy: true
    },
    {
        title: "NAV_SMS_FEES", isDummy: true
    },
    {
        title: "NAV_CHANGE_PASSWORD", isDummy: true
    },
    {
        title: "NAV_TRACKING_PIN", isDummy: true
    },
    {
        title: "NAV_PIN", isDummy: true
    },
    {
        title: "NAV_PAY_BILL", isDummy: true
    },
]

export default Menu;
