import React, { useState, useMemo, useEffect } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import DateTime from 'react-datetime';
import { ApiUrl, ApiKey, Role, AlertTypes, Status } from '../../../util/Constant';
import { getValidationMessage, arrayGroupByKey, stringIsNullOrEmpty, createFormBody, isObjectEmpty } from '../../../util/Util';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import moment from 'moment';
import ApiEngine from '../../../util/ApiEngine.js';
import DualListBox from 'react-dual-listbox';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from "react-redux";
import { showResponseMessage, showMessage } from 'redux/AppAction';
import { useSelector } from 'react-redux';

/// <summary>
/// Author: Wind
/// Edited: CK - Reply feedback functionality
/// </summary> 
const MemberFeedbackReport = props => {
    let _dispatch = useDispatch();
    const { t } = useTranslation();
    const { setValue, errors, register, handleSubmit } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [member, setMember] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [index, setIndex] = useState('');
    const [postData, setPostData] = useState({});
    const [selectedFeedback, setSelectedFeedback] = useState({});

    /// <summary>
    /// Author: Wind
    /// </summary>
    let _feedbackColumns = [
        {
            Header: "No",
            accessor: "index",
            filterable: false,
            disableSortBy: true,
            width: 20
        },
        {
            Header: "MEMBER",
            disableSortBy: true,
            Cell: ({ row }) => {
                let rowDetail = row.original;

                return <div>
                    <b>{t("SUBMISSION_DATE_TIME")}: </b>{moment(rowDetail.createdTime).format("YYYY-MM-DD HH:mm:ss")}<br />
                    <b>{t("USERNAME")}: </b>{rowDetail.reporterName} ({rowDetail.reporterOwnerName})<br />
                    <b>{t("OCCUR_TIME")}: </b>{moment(rowDetail.occurTime).format("YYYY-MM-DD HH:mm:ss")}<br />
                    <b>{t("LOCATION")}: </b>{rowDetail.location}
                </div>
            }
        },
        {
            Header: "FEEDBACK_CATEGORY",
            accessor: "text",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{row.original.text}</div>
            },
            width: 100
        },
        {
            Header: "COMMENT",
            accessor: "additional",
            disableSortBy: true,
            style: {
                wordBreak: 'break-word'
            },
            minWidth: 350
        },
        {
            Header: "REPLY",
            disableSortBy: true,
            style: {
                wordBreak: 'break-word'
            },
            Cell: ({ row }) => {
                let rowDetail = row.original;

                if (stringIsNullOrEmpty(rowDetail.reply)) {
                    return <span className="d-flex">
                        <div className="clickable-link" onClick={() => {
                            setSelectedFeedback(rowDetail);
                        }}>
                            {t('REPLY_FEEDBACK')}
                        </div>
                    </span >
                }
                else {
                    return <div>
                        <b>{t("REPLIED_AT")}: </b>{moment(rowDetail.updatedTime).format("YYYY-MM-DD HH:mm:ss")}<br />
                        <b>{t("REPLIED_BY")}: </b>{rowDetail.updatedByName}<br />
                        {rowDetail.reply}
                    </div>
                }
            },
            minWidth: 50
        }
    ];

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        (async () => {
            applyFilter();
        })();
    }, []);

    /// <summary>
    /// Author : Wind
    /// </summary> 
    async function applyFilter() {
        var params = {
            "IsShopFeedback": false,
            "StartDate": startDate,
            "EndDate": endDate,
            "Username": member,
            "v": moment.now()
        }

        if (!stringIsNullOrEmpty(index)) {
            params["Index"] = parseInt(index);
        }

        setPostData(params);
        setApiUrl(ApiUrl._API_MEMBER_FEEDBACK);
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const replyFeedbackConfirmation = async (data) => {
        _dispatch(showMessage({
            type: AlertTypes._WARNING,
            title: t("ARE_YOU_SURE"),
            content: t("REPLY_FEEDBACK_CONFIRMATION"),
            showCancel: true,
            onConfirm: () => {
                replyFeedback(data.reply);
            }
        }));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const replyFeedback = async (replyMessage) => {
        var responseJson = await ApiEngine.post(ApiUrl._API_FEEDBACK_REPLY + "/" + selectedFeedback.id, createFormBody({ reply: replyMessage }));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            applyFilter();
            setSelectedFeedback({});
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const PaginationTable = useMemo(() =>
        <ReactTable
            columns={_feedbackColumns}
            fetchUrl={apiUrl}
            postData={postData}
            exportRequired={true} />
        , [apiUrl, postData])

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MEMBER_FEEDBACK")}</h1>
            </div>
            <Panel>
                <PanelBody>
                    <div className="row m-b-10">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("MEMBER")}</label>
                                <input
                                    type="text"
                                    name="member"
                                    className="form-control form-control-lg"
                                    onInput={(e) => setMember(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("START_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="startDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={startDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("startDate", "");
                                        }
                                    }} />
                                {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("END_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="endDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={endDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                            setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("endDate", "");
                                        }
                                    }} />
                                {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("JUMP_TO_NO")}</label>
                                <input
                                    type="number"
                                    name="index"
                                    className="form-control form-control-lg"
                                    onInput={(e) => setIndex(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <button type="button" className="btn btn-primary" onClick={() => { applyFilter() }}>{t('APPLY_FILTER')}</button>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            {PaginationTable}
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <Modal isOpen={!isObjectEmpty(selectedFeedback)} centered toggle={() => { setSelectedFeedback({}) }} size="lg">
                <form onSubmit={handleSubmit(replyFeedbackConfirmation)}>
                    <ModalHeader toggle={() => { setSelectedFeedback({}) }}>{t("FEEDBACK")}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-lg-4">
                                <b>{t("SUBMISSION_DATE_TIME")}</b>
                            </div>
                            <div className="col-lg-8">
                                : {moment(selectedFeedback.createdTime).format("YYYY-MM-DD HH:mm:ss")}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <b>{t("USERNAME")}</b>
                            </div>
                            <div className="col-lg-8">
                                : {selectedFeedback.reporterName} ({selectedFeedback.reporterOwnerName})
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <b>{t("OCCUR_TIME")}</b>
                            </div>
                            <div className="col-lg-8">
                                : {moment(selectedFeedback.occurTime).format("YYYY-MM-DD HH:mm:ss")}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <b>{t("LOCATION")}</b>
                            </div>
                            <div className="col-lg-8">
                                : {selectedFeedback.location}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <b>{t("FEEDBACK_CATEGORY")}</b>
                            </div>
                            <div className="col-lg-8">
                                : {selectedFeedback.text}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <b>{t("COMMENT")}</b>
                            </div>
                            <div className="col-lg-8">
                                : {selectedFeedback.additional}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label>{t("REPLY")}</label>
                                    <textarea
                                        className="form-control form-control-lg"
                                        rows="5"
                                        ref={register({ required: true, maxLength: 400 })}
                                        id="reply"
                                        name="reply"
                                    >
                                    </textarea>
                                    {errors.reply && <div className="invalid-feedback">{t(getValidationMessage(errors.reply))}</div>}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-primary btn-action">{t("BTN_SUBMIT")}</button>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    )
}

export default MemberFeedbackReport;