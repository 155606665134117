import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import NavigationButton from '../../../components/custom/NavigationButton';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { WebUrl, ApiKey, ApiUrl, AccessRight, Role, AlertTypes } from '../../../util/Constant';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { navigateTo, createMultiPartFormBody, createFormBody, stringIsNullOrEmpty, isObjectEmpty } from "../../../util/Util";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useDispatch } from "react-redux";
import { showResponseMessage, showMessage } from "../../../redux/AppAction";
import ApiEngine from '../../../util/ApiEngine.js';
import AttachShopModal from '../../../components/custom/AttachShopModal';

/// <summary>
/// Author: Wind
/// </summary> 
const ManageNotificationSettings = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    var _dispatch = useDispatch();
    const [notificationList, setNotificationList] = useState([]);
    const [selectedNotification, setSelectedNotification] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);

    /// <summary>
    /// Author: Wind
    /// </summary>
    let _notificationColumns = useMemo(() => [
        {
            Header: "DESCRIPTION",
            accessor: "description",
            disableSortBy: true
        },
        {
            Header: "CATEGORY",
            accessor: "notificationTypeName",
            disableSortBy: true
        },
        {
            Header: "CONTENT",
            accessor: "localizations",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{row.original.localizations.map((item) => { return item.value }).join(" / ")}</div>
            }
        },
        {
            Header: "STATUS",
            accessor: "status",
            disableSortBy: true,
            Cell: ({ row }) => {
                return row.original.status ? <span className="badge badge-green">{t("ACTIVE")}</span> : <span className="badge badge-danger">{t("DISABLE")}</span>;
            }
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                _history.push({
                                    pathname: WebUrl._URL_NOTIFICATION_DETAILS,
                                    state: { id: row.original.id }
                                })
                            }}>
                                <span>{t('EDIT')}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                onEditStatus(row.original.id, { "status": !row.original.status, name: JSON.stringify(row.original.localizations) })
                            }}>
                                <span>{row.original.status ? t("DISABLE") : t("ACTIVATE")}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                setSelectedNotification(row.original);
                                toggleModal();
                            }}>
                                <span>{t('ATTACH_SHOPS')}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ], [notificationList]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        getNotificationList();
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function getNotificationList() {
        var responseJson = await ApiEngine.get(ApiUrl._API_NOTIFICATION_TEMPLATE);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setNotificationList(responseJson[ApiKey._API_DATA_KEY].data);
        }
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function onEditStatus(id, data) {
        var responseJson = await ApiEngine.put(ApiUrl._API_NOTIFICATION_TEMPLATE + "/" + id, createFormBody(data));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && getNotificationList()
        }));
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function toggleModal() {
        if (isModalVisible) {
            setSelectedNotification(null);
        }

        setIsModalVisible(!isModalVisible);
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    async function onSubmitAttachShop(attachedShops) {
        let params = {
            shopIds: attachedShops
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_NOTIFICATION_ATTACHED_SHOP + '/' + selectedNotification.id, createFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setSelectedNotification();
            getNotificationList();
        }

        toggleModal();
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_NOTIFICATION_SETTING")}</h1>
                {
                    checkIfPermissionExist(AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION, true) &&
                    <NavigationButton history={_history} url={WebUrl._URL_NOTIFICATION_DETAILS} buttonText={t("ADD_NOTIFICATION")} />
                }
            </div>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <ReactTable data={notificationList} columns={_notificationColumns} />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <AttachShopModal
                isModalVisible={isModalVisible}
                title={selectedNotification ? selectedNotification.name : ""}
                getAttachedShopUrl={selectedNotification ? ApiUrl._API_NOTIFICATION_ATTACHED_SHOP + "/" + selectedNotification.id : null}
                onFinish={(attachedShops) => {
                    if (attachedShops) {
                        onSubmitAttachShop(attachedShops);
                    }
                    else {
                        toggleModal();
                    }
                }} />
        </div>
    )
}

export default ManageNotificationSettings;