import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import ReactTable from "../../../components/custom/ReactTable";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { setBusy, setIdle, showResponseMessage } from '../../../redux/AppAction';
import { ApiKey, ApiUrl, Role } from "../../../util/Constant";
import { stringIsNullOrEmpty, arrayGroupByKey } from "../../../util/Util";
import { useForm } from "react-hook-form";
import ApiEngine from '../../../util/ApiEngine.js';
import { useDispatch } from "react-redux";
import RegionFilterDualListBox from "../../../components/custom/RegionFilterDualListBox";
import DualListBoxItemRow from "components/custom/DualListBoxItemRow.js";

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const AgentCommissionReport = props => {
    let _dispatch = useDispatch();
    const [postData, setPostData] = useState({});
    const { register, handleSubmit, setValue } = useForm();
    const { t } = useTranslation();
    const [apiUrl, setApiUrl] = useState('');
    const [shopOption, setShopOption] = useState([]);
    const [selectedOperatedBy, setSelectedOperatatedBy] = useState([]);
    const [selectedShop, setSelectedShop] = useState([]);
    const [vendorOption, setVendorOption] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState([]);
    const [commissionDetailData, setCommissionDetailData] = useState([]);
    const [showSummary, setShowSummary] = useState(false);
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [regions, setRegions] = useState([]);

    const _TYPE_OPTION = {
        PENDING: 0,
        COMPLETE: 1,
    };

    let _tableColumns = [
        {
            Header: "DATE",
            accessor: "completedDate",
            disableSortBy: true
        },
        {
            Header: "OPERATED_SHOP",
            accessor: "shopName",
            disableSortBy: true
        },
        {
            Header: "PHONE_NUMBER",
            accessor: "phoneNumber",
            disableSortBy: true
        },
        {
            Header: "Player_ID",
            accessor: "playerId",
            disableSortBy: true
        },
        {
            Header: "VENDOR",
            accessor: "vendor",
            Footer: ({ page }) => {
                return <span><b>{t("TOTAL")}:</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "DEPOSIT_AMOUNT",
            accessor: "rolloverAmount",
            Cell: ({ row }) => {
                return row.original.rolloverAmount.toFixed(2);
            },
            Footer: ({ page }) => {
                const totalRolloverAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.rolloverAmount);
                }, 0);

                return <span><b>{totalRolloverAmount.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "PROFIT",
            accessor: "profit",
            Cell: ({ row }) => {
                return row.original.profit > 0 ? <span className="text-success">{row.original.profit.toFixed(2)}</span> : <span className="text-danger">{row.original.profit.toFixed(2)}</span>;
            },
            Footer: ({ page }) => {
                const totalProfit = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.profit);
                }, 0);

                return <span><b>{totalProfit.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "ACTION",
            id: "ACTION",
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => { viewCommissionDetail(row.original.id) }}><span className="text-warning">{t("VIEW_DETAIL")}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ];

    let _summaryTableColumns = [
        {
            Header: "DATE",
            accessor: "withdrawalDate"
        },
        {
            Header: "SHOP",
            accessor: "shopName"
        },
        {
            Header: "WITHDRAWAL_AMOUNT",
            accessor: "amount"
        },
        {
            Header: "PERCENTAGE",
            accessor: "percentage"
        },
        {
            Header: "PROFIT",
            accessor: "actualAmount"
        }
    ]

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'operatedBy' });
        register({ name: 'operatedShop' });
        register({ name: 'vendor' });
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: true });

        setValue('operatedBy', []);
        setValue('operatedShop', []);
        setValue('vendor', []);
        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        _dispatch(setBusy());
        await getShopOption();
        await getVendorOption();
        _dispatch(setIdle());
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function getShopOption() {
        var apiUrl = ApiUrl._API_GET_USERS + "?RoleId=" + Role._SHOP;
        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var shopListByRegion = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "regionName");
            var shopOptionsByRegion = [];
            var regionOptions = [];

            for (var key in shopListByRegion) {
                var shopList = [];

                regionOptions.push({ label: key, value: key });

                shopListByRegion[key].map(shop => {
                    if (shop.isShopAgent) {
                        shopList.push({ label: shop.username + " (" + shop.shopName + ")", value: shop.id });
                    }
                });

                shopOptionsByRegion.push({
                    label: key,
                    value: key,
                    options: shopList
                })
            }

            setShopOption(shopOptionsByRegion);
            setRegions(regionOptions);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function getVendorOption() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_LIST);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var vendorList = [];

            responseJson[ApiKey._API_DATA_KEY].forEach(function (vendor) {
                vendorList.push({ label: vendor.name + " (" + vendor.shortName +")", value: vendor.id });
            });

            setVendorOption(vendorList);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "OperatedBy": data.operatedBy,
            "TargetShop": data.operatedShop,
            "Vendor": data.vendor,
            "Type": parseInt(data.type),
        };

        if (!stringIsNullOrEmpty(data.phoneNumber)) {
            params["PhoneNumber"] = data.phoneNumber;
        }

        if (!stringIsNullOrEmpty(data.playerId)) {
            params["PlayerId"] = data.playerId;
        }

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_AGENT_COMMISSION_REPORT);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function viewCommissionDetail(id) {
        var responseJson = await ApiEngine.post(ApiUrl._API_GET_AGENT_COMMISSION_DETAIL_REPORT + "?id=" + id);
        setShowSummary(true);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setCommissionDetailData(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable
        className="col-nowrap"
        columns={_tableColumns}
        fetchUrl={apiUrl}
        postData={postData}
        renderFooter={true}
        exportRequired={true} />, [apiUrl, postData]);

    return (
        <div>
            <h1 className="page-header">{t("AGENT_COMMISSION_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>{t("OPERATED_BY")}</label>
                                    <RegionFilterDualListBox
                                        name={"operatedBy"}
                                        ref={register}
                                        options={shopOption}
                                        selectedOptions={selectedOperatedBy}
                                        regions={regions}
                                        onChange={(e) => {
                                            setValue('operatedBy', e);
                                            setSelectedOperatatedBy(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>{t("OPERATED_SHOP")}</label>
                                    <RegionFilterDualListBox
                                        name={"operatedShop"}
                                        ref={register}
                                        options={shopOption}
                                        selectedOptions={selectedShop}
                                        regions={regions}
                                        onChange={(e) => {
                                            setValue('operatedShop', e);
                                            setSelectedShop(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>{t("VENDOR")}</label>
                                    <DualListBoxItemRow options={vendorOption} selected={selectedVendor} />
                                    <DualListBox
                                        canFilter
                                        name="vendor"
                                        ref={register}
                                        options={vendorOption}
                                        selected={selectedVendor}
                                        onChange={(e) => {
                                            setValue('vendor', e);
                                            setSelectedVendor(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("TYPE")}</label>
                                    <select name="type" ref={register} className="form-control form-control-lg">
                                        <option value={_TYPE_OPTION.COMPLETE}>{t("COMPLETE")}</option>
                                        <option value={_TYPE_OPTION.PENDING}>{t("PENDING")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("PLAYER_ID")}</label>
                                    <input type="text" name="playerId" ref={register} className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("PHONE_NUMBER")}</label>
                                    <input type="text" name="phoneNumber" ref={register} className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>

            <Modal isOpen={showSummary} toggle={() => setShowSummary(false)} style={{ paddingBottom: "100px" }} className="modal-lg" keyboard={true} >
                <ModalHeader toggle={() => setShowSummary(false)}>{t("SUMMARY")}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <ReactTable columns={_summaryTableColumns} data={commissionDetailData} renderFooter={true} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn btn-inverse-brand" onClick={() => setShowSummary(false)}>{t("BTN_CLOSE")}</button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default AgentCommissionReport;