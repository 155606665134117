import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { navigateTo, createMultiPartFormBody } from "../../../util/Util";
import NavigationButton from '../../../components/custom/NavigationButton';
import { ApiKey, WebUrl, ApiUrl, AccessRight, PaymentGatewayType } from "../../../util/Constant";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage, showMessage } from "../../../redux/AppAction";
import ApiEngine from '../../../util/ApiEngine.js';
import AttachShopModal from '../../../components/custom/AttachShopModal';

/// <summary>
/// Author : Andrew
/// </summary>
const ManagePaymentGateway = props => {
    var { t, i18n } = useTranslation();
    var _history = useHistory();
    var _dispatch = useDispatch();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [paymentGatewayTypeId, setPaymentGatewayTypeId] = useState('');
    const [paymentGatewayId, setPaymentGatewayId] = useState('');
    const [paymentGateways, setPaymentGateways] = useState([]);
    const [apiKey, setApiKey] = useState("");

    /// <summary>
    /// Author : Andrew
    /// </summary>
    useEffect(() => {
        getPaymentGateways();
    }, []);

    /// <summary>
    /// Author : Andrew
    /// </summary>
    async function onSubmitAttachShop(attachedShops) {
        let params = {
            "paymentGatewayId": paymentGatewayId,
            "shopIds": attachedShops.join(',')
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_ATTACH_PAYMENT_GATEWAY_SHOP, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            toggleModal();
            getPaymentGateways();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    let _tableColumns = useMemo(() => [
        {
            Header: "TITLE",
            accessor: "title",
        },
        {
            Header: "PAYMENT_TYPE",
            Cell: ({ row }) => {
                return row.original.typeId == PaymentGatewayType._ONLINE_BANKING_GPAY ? t('ONLINE_BANKING') :
                    row.original.typeId == PaymentGatewayType._DUITNOW_GPAY ? t('DUITNOW') :
                    row.original.typeId == PaymentGatewayType._WINPAY ? t("MOBILE_PIN_TOPUP") :
                    row.original.typeId == PaymentGatewayType._VENDING_MACHINE ? t("VENDING_MACHINE") : 
                    row.original.typeId == PaymentGatewayType._KINGPAY_DUITNOW ? t("KINGPAY_DUITNOW") : t('E_WALLET');
            }
        },
        {
            Header: "BASE_URL",
            accessor: "baseUrl",
            style: {
                wordBreak: 'break-word'
            },
            minWidth: 200
        },
        {
            Header: "API_KEY",
            accessor: "apiKey",
        },
        {
            Header: "API_SECRET",
            accessor: "apiSecret",
            style: {
                wordBreak: 'break-word'
            },
            minWidth: 250
        },
        {
            Header: "CALLBACK_URL",
            accessor: "callbackUrl",
            style: {
                wordBreak: 'break-word'
            },
            minWidth: 200
        },
        {
            Header: "RESPONSE_URL",
            accessor: "responseUrl",
            style: {
                wordBreak: 'break-word'
            },
            minWidth: 200
        },
        {
            Header: "STATUS",
            Cell: ({ row }) => {
                let element = "";
                switch (row.original.status) {
                    case false:
                        element = <span className="badge badge-warning">{t('INACTIVE')}</span>
                        break;
                    case true:
                        element = <span className="badge badge-success">{t('ACTIVE')}</span>
                        break;
                }
                return element;
            }
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_ADMIN_CREATE_OR_UPDATE_PAYMENT_GATEWAY, row.original) }}><span>{t('EDIT')}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                setPaymentGatewayTypeId(row.original.typeId);
                                setApiKey(row.original.apiKey);
                                setPaymentGatewayId(row.original.id);
                                toggleModal();
                            }}>
                                <span>{t('ATTACH_SHOPS')}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableFilters: true,
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ], []);

    /// <summary>
    /// Author: Andrew
    /// </summary>
    async function toggleModal() {
        if (isModalVisible) {
            setPaymentGatewayId(null);
        }

        setIsModalVisible(!isModalVisible);
    }

    /// <summary>
    /// Author: Andrew
    /// </summary>
    async function getPaymentGateways() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_PAYMENT_GATEWAY);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setPaymentGateways(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_PAYMENT_GATEWAY")}</h1>
                {
                    checkIfPermissionExist(AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION, true) &&
                    <NavigationButton history={_history} url={WebUrl._URL_ADMIN_CREATE_OR_UPDATE_PAYMENT_GATEWAY} buttonText={t("NEW")} />
                }
            </div>
            <div>
                <Panel>
                    <PanelBody>
                        <ReactTable data={paymentGateways} columns={_tableColumns} globalFilterable={false} />
                    </PanelBody>
                </Panel>
            </div>
            <AttachShopModal
                isModalVisible={isModalVisible}
                title={apiKey}
                getShopListUrl={ApiUrl._API_AVAILABLE_PAYMENT_GATEWAY_SHOP_LIST + "?paymentGatewayId=" + paymentGatewayId + "&typeId=" + paymentGatewayTypeId}
                getAttachedShopUrl={ApiUrl._API_GET_PAYMENT_GATEWAY_SHOPS_BY_ID + "?paymentGatewayId=" + paymentGatewayId}
                onFinish={(attachedShops) => {
                    if (attachedShops) {
                        onSubmitAttachShop(attachedShops);
                    }
                    else {
                        toggleModal();
                    }
                }} />
        </div>
    );
}

export default ManagePaymentGateway;