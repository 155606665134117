import { _SET_SEARCH_MEMBER_MODAL_VISIBLE } from './MemberAction';

const _INITIAL_STATE = {
    isModalVisible: false,
    targetUrl: null,
    isSearchAgent: false
};

/// <summary>
/// Author : CK
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case _SET_SEARCH_MEMBER_MODAL_VISIBLE:
            return {
                ...state,
                isModalVisible: action.isModalVisible,
                targetUrl: action.targetUrl,
                isSearchAgent: action.isSearchAgent
            };
            break;
        default:
            return state;
            break;
    }
};
