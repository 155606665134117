import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel';
import NavigationButton from '../../../components/custom/NavigationButton';
import { AlertTypes, ApiKey, ApiUrl, PackagePayRateType, WebUrl } from 'util/Constant';
import LottoPayrateTable from 'components/custom/LottoPayrateTable';
import { useTranslation } from 'react-i18next';
import { TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import classNames from 'classnames';
import ApiEngine from 'util/ApiEngine';
import { useForm } from "react-hook-form";
import * as Util from '../../../util/Util';
import { showMessage } from 'redux/AppAction';
import ReactTable from 'components/custom/ReactTable';

/// <summary>
/// Author : A
/// </summary>
const AgentVendorApiDetail = () => {
    let _history = useHistory();
    let _location = useLocation();
    let _dispatch = useDispatch();

    const { register, watch } = useForm();
    const { t } = useTranslation();

    const normalPayrateTable = useRef();
    const gdPayrateTable = useRef();

    const _TABS = {
        NORMAL: "NORMAL",
        GD: "GD"
    }

    const [activeTab, setActiveTab] = useState(_TABS.NORMAL);
    const [normalPackage, setNormalPackage] = useState([]);
    const [gdPackage, setGdPackage] = useState([]);
    const [agentUserId, setAgentUserId] = useState(_location.state?.id);

    const [vendors, setVendors] = useState([]);
    const [agentPackage, setAgentPackage] = useState(null);
    const [packageWarning, setPackageWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState([]);

    /// <summary>
    /// Author : A
    /// </summary>
    useEffect(() => {
        init();
    }, [agentUserId]);

    const _COLUMNS = useMemo(() => [
        {
            Header: t("VENDOR"),
            accessor: "name",
            disableSortBy: true,
        },
        {
            Header: t("CODE"),
            accessor: "code",
            disableSortBy: true,
        },
        {
            Header: t("ROOT_AGENT"),
            accessor: "rootAgent",
            disableSortBy: true,
        },
        {
            Header: t("KEY"),
            accessor: "signKey",
            disableSortBy: true,
        },
        {
            Header: t("OPCODE"),
            accessor: "opcode",
            disableSortBy: true,
        },
        {
            Header: t("OPSECRET"),
            accessor: "opSecret",
            disableSortBy: true,
        },
        {
            Header: t("TYPE"),
            accessor: "isPrimary",
            disableSortBy: true,
            width: '1%',
            Cell: ({ row }) => {
                return row.original.isPrimary ? <span className="badge badge-green">{t("PRIMARY")}</span> : <span className="badge badge-primary">{t("SECONDARY")}</span>;
            }
        },
        {
            Header: t("STATUS"),
            accessor: "status",
            disableSortBy: true,
            width: '1%',
            Cell: ({ row }) => {
                return row.original.status ? <span className="badge badge-green">{t("ENABLE")}</span> : <span className="badge badge-danger">{t("DISABLE")}</span>;
            }
        }
    ], []);

    /// <summary>
    /// Author : A
    /// </summary>
    const init = () => {
        ApiEngine.get(`${ApiUrl._API_GET_AGENT_VENDORS}?agentId=${agentUserId}`)
            .then((response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    let data = response[ApiKey._API_DATA_KEY];
                    setVendors(data);
                    if (data.filter(v => v.isAgentPlayer).length > 0) {
                        getLottoPackage();
                    }
                }
            });
    }

    /// <summary>
    /// Author : A
    /// </summary>
    const getLottoPackage = async () => {
        var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_AGENT_LOTTO_PACKAGE}?agentId=${agentUserId}&vendorApiMetaId=${watch('vendorApiMetaId')}`);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const lottoPackages = responseJson[ApiKey._API_DATA_KEY];
            if (lottoPackages) {
                setAgentPackage(lottoPackages.user.customPayRate);
                setNormalPackage(Object.values(lottoPackages.normal));
                setGdPackage([lottoPackages.gd.basePayRate]);
            }
        } else {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(responseJson[ApiKey._API_MESSAGE_KEY])
            }));
        }
    }

    /// <summary>
    /// Author : A
    /// </summary>
    const saveLottoPackage = async () => {
        let normalPayRateData = await normalPayrateTable.current.getPayrate();

        if (!normalPayRateData.success) {
            setWarningMessage(normalPayRateData.message);
            setPackageWarning(true);
            return;
        } else {
            setWarningMessage([]);
            setPackageWarning(false);
        }

        let data = {
            id: agentPackage.id,
            ...normalPayRateData.data.customPayrate
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_AGENT_LOTTO_PACKAGE, Util.createMultiPartFormBody(data));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
            // onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && Util.navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_AGENT)
        }));
    }

    return (
        <>
            <div id="page-content">
                <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                    <h1 className="page-header">{_location.state?.username} - {t("VENDOR_API_DETAILS")}</h1>
                    <NavigationButton history={_history} />
                </div>
                <Panel>
                    <PanelBody>
                        <ReactTable disableSortBy className="p-0" columns={_COLUMNS} data={vendors} />
                    </PanelBody>
                    {
                        vendors.filter(v => v.isAgentPlayer).length > 0 &&
                        <>
                            <PanelHeader noButton>{t("AGENT_PLAYER")} {t("VENDOR")}</PanelHeader>
                            <PanelBody>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <select
                                                ref={register}
                                                name="vendorApiMetaId"
                                                className="form-control form-control-lg"
                                                onChange={getLottoPackage}
                                            >
                                                {
                                                    vendors.filter(v => v.isAgentPlayer).map((v) => <option value={v.vendorApiMetaId}>{v.name}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <Nav pills className="mb-2">
                                    {
                                        Object.values(_TABS).map(tab =>
                                            <NavItem key={`tab${tab}`}>
                                                <NavLink
                                                    className={classNames({ active: activeTab == tab })}
                                                    onClick={() => setActiveTab(tab)}
                                                >
                                                    {tab}
                                                </NavLink>
                                            </NavItem>
                                        )
                                    }
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId={_TABS.NORMAL}>
                                        <LottoPayrateTable
                                            ref={normalPayrateTable}
                                            baseReadonly
                                            customPayRate
                                            package={normalPackage.length > 0 && normalPackage}
                                            userPackage={agentPackage && agentPackage}
                                        />
                                    </TabPane>
                                    <TabPane tabId={_TABS.GD}>
                                        <LottoPayrateTable
                                            ref={gdPayrateTable}
                                            baseReadonly
                                            package={gdPackage.length > 0 && gdPackage}
                                        />
                                    </TabPane>
                                </TabContent>
                                <button type="button" onClick={() => saveLottoPackage()} className="btn btn-primary mt-2">{t("BTN_SAVE")}</button>
                            </PanelBody>
                        </>
                    }
                </Panel>
            </div>
            <Modal isOpen={packageWarning} centered toggle={() => setPackageWarning(false)}>
                <ModalBody style={{ maxHeight: 800, overflowY: 'auto' }}>
                    <p>{t("PLEASE_CHECK_THE_FOLLOWING_WARNINGS")}</p>
                    <table className='table table-bordered table-striped mb-0'>
                        <thead>
                            <tr>
                                <th>{t("DESCRIPTION")}</th>
                                <th>{t("WARNING")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                warningMessage.map((wm) =>
                                    <tr>
                                        <td>{wm.description}</td>
                                        <td>
                                            {Util.numberWithCurrencyFormat(wm.message)}
                                            <i class="fa fa-arrow-right mx-2" />
                                            <span className='text-red'>{Util.numberWithCurrencyFormat(wm.value)}</span>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </ModalBody>
            </Modal>
        </>
    );
};

export default AgentVendorApiDetail;