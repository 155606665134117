import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelBody,
} from "../../components/panel/panel.jsx";
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import ReactTable from "../../components/custom/ReactTable";
import { stringIsNullOrEmpty } from "../../util/Util";
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, Role, Status, AlertTypes } from "../../util/Constant";

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const CreditTrasnferReport = props => {
    const { register, handleSubmit, setValue } = useForm();
    const { t } = useTranslation();
    const [apiUrl, setApiUrl] = useState('');
    const [postData, setPostData] = useState();
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));

    const _TRANSFER_TYPE_ID = {
        ALL: -1,
        TO_CREDIT: 1,
        TO_SCORE: 2,
        TRANSFER_SCORE: 3
    };

    let _tableColumns = [
        {
            Header: t("DATE"),
            accessor: "date",
            disableSortBy: true
        },
        {
            Header: t("MEMBER_ID"),
            accessor: "username",
            disableSortBy: true
        },
        {
            Header: t("INITIAL_VENDOR"),
            accessor: "initialVendor",
            disableSortBy: true
        },
        {
            Header: t("TARGET_VENDOR"),
            accessor: "targetVendor",
            disableSortBy: true
        },
        {
            Header: t("TRANSFER_TYPE"),
            accessor: "transferType",
            Cell: ({ row }) => {
                return renderTransferType(row.original.transferType);
            },
            disableSortBy: true
        },
        {
            Header: t("AMOUNT"),
            accessor: "amount",
            disableSortBy: true
        },
        {
            Header: t("STATUS"),
            accessor: "status",
            Cell: ({ row }) => {
                return row.original.status ? <span className="badge badge-green">{t("SUCCESS")}</span> : <span className="badge badge-danger">{t("FAILED")}</span>;
            },
            disableSortBy: true
        },
        {
            Header: "MEMBER_INITIAL_CREDIT",
            accessor: "memberInitialCredit",
            disableSortBy: true
        },
        {
            Header: "MEMBER_FINAL_CREDIT",
            accessor: "memberFinalCredit",
            disableSortBy: true
        },
        {
            Header: t("PLAYER_ID"),
            accessor: "initialPlayerGameId",
            disableSortBy: true
        },
        {
            Header: t("TARGET_PLAYER_ID"),
            accessor: "targetPlayerGameId",
            disableSortBy: true
        },
    ];

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'member' });
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: true });

        setValue('member', "");
        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function renderTransferType(transferType) {
        let transferTypeString = "";

        switch (parseInt(transferType)) {
            case _TRANSFER_TYPE_ID.TO_CREDIT:
                transferTypeString = t("TO_CREDIT");
                break;
            case _TRANSFER_TYPE_ID.TO_SCORE:
                transferTypeString = t("TO_SCORE");
                break;
            default:
                transferTypeString = t("TRANSFER_SCORE");
                break;
        }

        return <span className="badge badge-primary">{transferTypeString}</span>
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "Status": parseInt(data.status),
            "Type": parseInt(data.transferType)
        };

        if (!stringIsNullOrEmpty(data.member)) {
            params["MemberId"] = data.member;
        }

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_CREDIT_TRANSFER_REPORT);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable className="col-nowrap" columns={_tableColumns} fetchUrl={apiUrl} postData={postData} exportRequired={true} />, [apiUrl, postData])

    return (
        <div>
            <h1 className="page-header">{t("CREDIT_TRANSFER_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("MEMBER_ID")}</label>
                                    <input type="text" name="member" ref={register}
                                        className="form-control form-control-lg" placeholder={t("ENTER_MEMBER_ID")} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("STATUS")}</label>
                                    <select name="status" ref={register} className="form-control form-control-lg">
                                        <option value={_TRANSFER_TYPE_ID.ALL}>{t("ALL")}</option>
                                        <option value={Status._ENABLED}>{t("SUCCESS")}</option>
                                        <option value={Status._DISABLED}>{t("FAILED")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("TRANSFER_TYPE")}</label>
                                    <select name="transferType" ref={register} className="form-control form-control-lg">
                                        <option value={_TRANSFER_TYPE_ID.ALL}>{t("ALL")}</option>
                                        <option value={_TRANSFER_TYPE_ID.TO_CREDIT}>{t("TO_CREDIT")}</option>
                                        <option value={_TRANSFER_TYPE_ID.TO_SCORE}>{t("TO_SCORE")}</option>
                                        <option value={_TRANSFER_TYPE_ID.TRANSFER_SCORE}>{t("TRANSFER_SCORE")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
}

export default CreditTrasnferReport;