import React, { useState, useEffect, useRef } from 'react';
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import { ApiKey, ApiUrl, Role, AlertTypes } from '../../util/Constant';
import { useDispatch } from 'react-redux';
import { showMessage, showResponseMessage } from 'redux/AppAction.js';
import { useForm } from "react-hook-form";
import { createMultiPartFormBody, stringIsNullOrEmpty, validatePhoneNumber, getValidationMessage } from "../../util/Util";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import IntlTelInput from 'react-intl-tel-input/dist/components/IntlTelInput';

/// <summary>
/// Author: YJ
/// </summary>
const CopyAccountDetailModal = props => {
    const { t } = useTranslation();
    var _dispatch = useDispatch();
    const { register, handleSubmit, errors, triggerValidation, setValue, getValues, watch } = useForm();
    const [modalVisibility, setModalVisibility] = useState(false);
    const [refId, setRefId] = useState('');
    const [refRole, setRefRole] = useState('');
    const _phoneFieldRef = useRef(null);
    const [enrollKogEvent, setEnrollKogEvent] = useState(false);

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        setModalVisibility(props["visible"]);
        setRefId(props.refId);
        setRefRole(props.refRole);

        if (props.refRole == Role._AGENT) {
            register({ name: 'phone' }, { required: true });
            setValue('phone', '');
        }
    }, [props["visible"]]);

    /// <summary>
    /// Author: YJ
    /// </summary>
    function cleanUp(refreshRequired) {
        setRefId("");
        setRefRole("");
        props.onFinish(refreshRequired);
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    const onSubmit = async (data) => {
        if (data.password != data.confirmPassword) {
            _dispatch(showResponseMessage(false, t("CONFIRM_PASSWORD_AND_PASSWORD_IS_NOT_TALLY")));
            return;
        }

        let params = {
            refId: refId,
            username: data.username,
            roleId: refRole,
            password: data.password,
            userFullName: data.userFullName
        };

        if (refRole == Role._SHOP) {
            params["shopName"] = data.userFullName;
            
            if (!props.isCustomerService) {
                params["enrollCurrentKogEvent"] = enrollKogEvent;
            }
        } else if (refRole == Role._AGENT) {
            params["phoneNumber"] = data.phone;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_COPY_USER_ACCOUNT, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && cleanUp(true)
        }));
    };

    /// <summary>
    /// Author : A
    /// </summary>
    const getTitle = () => {
        let title = "";
        switch (refRole) {
            case Role._SHOP:
                title = t("SHOP")
                break;
            case Role._MANAGER:
                title = t("MANAGER")
                break;
            case Role._AGENT:
                title = t("AGENT")
                break;
        }
        return title;
    }

    /// <summary>
    /// Author : A
    /// </summary>
    const onChangePhoneNumber = (isValid, newNumber, countryData, fullNumber) => {
        // setPhone(newNumber.toString().replace(/\s/g, '').replace(/\-/g, ''));
        let formattedNumber = fullNumber.toString().split(/[- ]+/).join('').replace("+", "");
        register({ name: "phone" }, {
            validate: (value) => (validatePhoneNumber(fullNumber, countryData["iso2"]) && validatePhoneNumber(newNumber, countryData["iso2"])) || stringIsNullOrEmpty(newNumber) || "REQUIRED_VALID_PHONE_NUMBER"
        });
        setValue("phone", stringIsNullOrEmpty(newNumber) ? '' : formattedNumber);
        triggerValidation("phone")
    }

    return (
        <Modal isOpen={modalVisibility} centered toggle={() => { cleanUp(false) }} size="lg">
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={() => { cleanUp(false) }}>{t("COPY")} {getTitle()}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("NAME")}</label>
                                <input
                                    className="form-control form-control-lg"
                                    id="userFullName"
                                    name="userFullName"
                                    type="text"
                                    placeholder={t("ENTER_NAME")}
                                    ref={register({ required: "PLEASE_ENTER_NAME" })} />
                                {errors.userFullName && <div className="invalid-feedback">{t(errors.userFullName.message)}</div>}
                            </div>
                        </div>
                        {
                            props.refRole == Role._AGENT &&
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("PHONE")}</label>
                                    <IntlTelInput
                                        fieldName={"phone"}
                                        preferredCountries={['my']}
                                        style={{ display: "block", width: "100%" }}
                                        containerClassName="intl-tel-input"
                                        inputClassName="form-control form-control-lg"
                                        ref={_phoneFieldRef}
                                        onSelectFlag={(newNumber, countryData, fullNumber, ext) => {
                                            onChangePhoneNumber(_phoneFieldRef.current.isValidNumber(getValues("form")), newNumber, countryData, fullNumber);
                                        }}
                                        onPhoneNumberChange={(isValid, newNumber, countryData, fullNumber, ext) => {
                                            onChangePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                        }}
                                        onPhoneNumberBlur={(isValid, newNumber, countryData, fullNumber, ext) => {
                                            onChangePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                        }}
                                        customPlaceholder={(placeholder, countryData) => {
                                            return placeholder.split(/[- ]+/).join('');
                                        }}
                                        defaultValue={''}
                                        useMobileFullscreenDropdown={false}
                                        separateDialCode
                                    />
                                    {errors.phone && <div className="invalid-feedback">{t(getValidationMessage(errors.phone))}</div>}
                                </div>
                            </div>
                        }
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("USERNAME")}</label>
                                <input
                                    disabled={refRole == Role._AGENT}
                                    value={refRole == Role._AGENT ? `A${watch('phone')?.substring(1)}` : watch('username')}
                                    autoComplete="new-password"
                                    className="form-control form-control-lg"
                                    id="username"
                                    name="username"
                                    type="text"
                                    placeholder={t("ENTER_USERNAME")}
                                    ref={register({ required: "PLEASE_ENTER_USERNAME" })} />
                                {errors.username && <div className="invalid-feedback">{t(errors.username.message)}</div>}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("PASSWORD")}</label>
                                <input
                                    autoComplete="new-password"
                                    className="form-control form-control-lg"
                                    id="password"
                                    name="password"
                                    placeholder={t("ENTER_PASSWORD")}
                                    type="password"
                                    ref={register({ required: "PLEASE_ENTER_PASSWORD" })} />
                                {errors.password && <div className="invalid-feedback">{t(errors.password.message)}</div>}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("CONFIRM_PASSWORD")}</label>
                                <input
                                    autoComplete="new-password"
                                    className="form-control form-control-lg"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    placeholder={t("ENTER_CONFIRM_PASSWORD")}
                                    type="password"
                                    ref={register({ required: "PLEASE_ENTER_CONFIRM_PASSWORD" })} />
                                {errors.confirmPassword && <div className="invalid-feedback">{t(errors.confirmPassword.message)}</div>}
                            </div>
                        </div>
                        {
                            refRole == Role._SHOP && !props.isCustomerService &&
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label><b>{t("ENROLL_KOG_EVENT")}</b></label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="enrollKogEvent" id="enrollKogEvent"
                                            onChange={(e) => setEnrollKogEvent(e.target.checked)}
                                            checked={enrollKogEvent}
                                            ref={register} />
                                        <label htmlFor="enrollKogEvent"></label>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-primary btn-action">{t("BTN_SUBMIT")}</button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default CopyAccountDetailModal;