import React, { useState, useMemo, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { createMultiPartFormBody, stringIsNullOrEmpty } from '../../util/Util';
import { ApiUrl, ApiKey, Role, AlertTypes } from '../../util/Constant';
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import { showMessage, showResponseMessage } from "redux/AppAction";
import { useForm } from "react-hook-form";

/// <summary>
/// Author: CK
/// </summary>
const SetManagerLimitModal = ({ visible, managerLimitDetails, onFinish }) => {
    var _userData = useSelector(state => state.authState.userData);
    const _dispatch = useDispatch();
    const { t } = useTranslation();
    const { register, handleSubmit, setValue, errors } = useForm();
    const [currentLimit, setCurrentLimit] = useState();
    const _FIXED_LIMIT_OPTIONS = [
        { label: "1,000", value: 1000 },
        { label: "2,500", value: 2500 },
        { label: "5,000", value: 5000 },
        { label: "10,000", value: 10000 },
        { label: "15,000", value: 15000 },
        { label: "20,000", value: 20000 },
    ];

    /// <summary>
    /// Author: CK
    /// get the latest credit
    /// </summary>
    useEffect(() => {
        if (visible) {
            refreshCreditLimit();
        }
    }, [visible])

    /// <summary>
    /// Author: CK
    /// </summary>
    const refreshCreditLimit = async () => {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_ACCOUNT + "?id=" + managerLimitDetails.id);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setValue('currentCreditLimit', responseJson[ApiKey._API_DATA_KEY].managerProfile.creditLimit);
            setCurrentLimit(responseJson[ApiKey._API_DATA_KEY].managerProfile.creditLimit);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const onSubmit = async (data) => {
        try {
            let params = {
                userId: managerLimitDetails.id,
                creditLimit: parseFloat(data.creditLimit),
                remark: data.remark
            };

            var responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_MANAGER_LIMIT, params);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _dispatch(showMessage({
                type: AlertTypes._SUCCESS,
                content: t('OPERATION_SUCCESS'),
                onConfirm: () => {
                    onFinish(true);
                }
            }))
        }
        catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const renderFixedLimitBtns = useMemo(() => {
        return _FIXED_LIMIT_OPTIONS.map((option, index) => {
            let parsedNumber = parseFloat(option.value);
            let parsedCurrLimit = parseFloat(currentLimit);
            var valueToSet = 0;

            if (parsedCurrLimit > parsedNumber) {
                valueToSet = (parsedCurrLimit - parsedNumber) * -1;
            }
            else {
                valueToSet = parsedNumber - parsedCurrLimit;
            }

            return (
                <button type="button" className="btn btn-warning btn-action m-r-10 m-b-10" onClick={() => {
                    setValue('creditLimit', valueToSet);
                }}>{option.label}</button>
            );
        })
    }, [currentLimit, managerLimitDetails]);

    return (
        <Modal isOpen={visible} toggle={onFinish}>
            <ModalHeader>{t("SET_LIMIT_FOR")} {managerLimitDetails.userFullName}</ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group m-b-15 ">
                        <label>{t("CURRENT_CREDIT_LIMIT")}</label>
                        <div className="input-group">
                            <input
                                disabled
                                className="form-control form-control-lg m-b-5"
                                type="number"
                                name="currentCreditLimit"
                                defaultValue={currentLimit}
                                ref={register} />
                            <div className="input-group-append">
                                <button type="button" className="btn btn-default no-caret" onClick={() => refreshCreditLimit()}><i className="fas fa-sync-alt"></i>{t("REFRESH")}</button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group m-b-15 ">
                        <label>{t("CREDIT_LIMIT")}</label>
                        <input
                            placeholder={t("ENTER_CREDIT_LIMIT")}
                            className="form-control form-control-lg m-b-5"
                            type="number"
                            name="creditLimit"
                            step={0.01}
                            ref={register({ required: 'PLEASE_ENTER_CREDIT_LIMIT' })} />
                        {errors.creditLimit && <div className="invalid-feedback">{t(errors.creditLimit.message)}</div>}
                    </div>
                    <div className="form-group m-b-15 ">
                        <label>{t("BILL_NUMBER")}</label>
                        <input
                            placeholder={t("ENTER_BILL_NUMBER")}
                            className="form-control form-control-lg m-b-5"
                            type="text"
                            name="remark"
                            ref={register} />
                        {errors.remark && <div className="invalid-feedback">{t(errors.remark.message)}</div>}
                    </div>
                    <div className="pull-right">
                        {renderFixedLimitBtns}
                        <button type="button" className="btn btn-default btn-action m-r-10 m-b-10" onClick={() => onFinish()}>{t('CLOSE')}</button>
                        <button type="submit" className="btn btn-primary btn-action m-b-10">{t('SUBMIT')}</button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};

export default SetManagerLimitModal;