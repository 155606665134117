import React, { useState, useEffect, useRef } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import NavigationButton from '../../../components/custom/NavigationButton';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import { useHistory, useLocation } from "react-router-dom";
import { WebUrl, SettingType, ApiKey, ApiUrl, LanguageKey, AlertTypes, NotificationTag } from '../../../util/Constant';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { showResponseMessage, showMessage } from "../../../redux/AppAction";
import { createMultiPartFormBody, stringIsNullOrEmpty, navigateTo, createFormBody } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';
import { useTranslation } from 'react-i18next';

/// <summary>
/// Author: Wind
/// </summary> 
const NotificationDetails = props => {
    const { t, i18n } = useTranslation();
    let _history = useHistory();
    var _dispatch = useDispatch();
    var _location = useLocation();
    const { register, handleSubmit, errors, setValue, unregister, watch } = useForm();
    const [notificationTypeOption, setNotificationTypeOption] = useState([]);
    const [selectedTagsValue, setSelectedTagsValue] = useState('');
    const [supportedLanguage, setSupportedLanguage] = useState([]);
    const [notificationTypeId, setNotificationTypeId] = useState();
    const [cursorPosition, setCursorPosition] = useState();
    const [currentInput, setCurrentInput] = useState();
    const formRef = useRef();
    const _TAG_OPTIONS = [
        { label: NotificationTag._NOTIFICATION_TEMPLATE_TAG_EXPERIENCE, value: '{' + NotificationTag._NOTIFICATION_TEMPLATE_TAG_EXPERIENCE + '}' },
        { label: NotificationTag._NOTIFICATION_TEMPLATE_TAG_INACTIVE_PERIOD, value: '{' + NotificationTag._NOTIFICATION_TEMPLATE_TAG_INACTIVE_PERIOD + '}' },
        { label: NotificationTag._NOTIFICATION_TEMPLATE_TAG_RANK, value: '{' + NotificationTag._NOTIFICATION_TEMPLATE_TAG_RANK + '}' },
        { label: NotificationTag._NOTIFICATION_TEMPLATE_TAG_RANK_IMAGE, value: '{' + NotificationTag._NOTIFICATION_TEMPLATE_TAG_RANK_IMAGE + '}' }
    ];

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        (async () => {
            register({ name: 'notificationTypeId' }, { required: "PLEASE_SELECT_CATEGORY" });
            setValue('notificationStatus', true);

            try {
                let typeResponseJson = await ApiEngine.get(ApiUrl._API_GET_NOTIFICATION_TYPE);
                if (typeResponseJson[ApiKey._API_SUCCESS_KEY]) {
                    let tempCategory = [];
                    typeResponseJson[ApiKey._API_DATA_KEY].map((type, index) => {
                        tempCategory.push({ label: type.name, value: type.id });
                    })
                    setNotificationTypeOption(tempCategory);
                }
                else {
                    throw typeResponseJson[ApiKey._API_MESSAGE_KEY];
                }

                var languageList = [];
                var langResponseJson = await ApiEngine.get(ApiUrl._API_GET_SUPPORTED_LANGUAGE);
                if (langResponseJson[ApiKey._API_SUCCESS_KEY]) {
                    languageList = langResponseJson[ApiKey._API_DATA_KEY];
                }
                else {
                    throw langResponseJson[ApiKey._API_MESSAGE_KEY];
                }

                if (_location.state && _location.state.id) {
                    var responseJson = await ApiEngine.get(ApiUrl._API_NOTIFICATION_TEMPLATE + "/" + _location.state.id);
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setNotificationTypeId(responseJson[ApiKey._API_DATA_KEY].notificationTypeId);
                        setValue("notificationTypeId", responseJson[ApiKey._API_DATA_KEY].notificationTypeId);
                        setValue("notificationDesciption", responseJson[ApiKey._API_DATA_KEY].description);
                        setValue("notificationStatus", responseJson[ApiKey._API_DATA_KEY].status);
                        languageList = languageList.map(item => ({ ...item, value: responseJson[ApiKey._API_DATA_KEY].localizations.find(l => l.languageId == item.id).value }));
                    }
                    else {
                        throw responseJson[ApiKey._API_MESSAGE_KEY];
                    }
                }

                setSupportedLanguage(languageList);
            }
            catch(err) {
                _dispatch(showMessage({
                    type: AlertTypes._ERROR,
                    content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
                    onConfirm: () => { }
                }));
            }
        })();
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary> 
    function updatedSelectedContent(selectedContent) {
        let newLangData = [...supportedLanguage];
        let selectedIndex = newLangData.findIndex(l => l.id == selectedContent);

        newLangData[selectedIndex].isChecked = !newLangData[selectedIndex].isChecked;
        setSupportedLanguage(newLangData);
    }

    /// <summary>
    /// Author: Wind
    /// </summary> 
    function insertTagIntoContent() {
        if (currentInput) {
            let textBeforeCursorPosition = watch(currentInput).substring(0, cursorPosition);
            let textAfterCursorPosition = watch(currentInput).substring(cursorPosition, watch(currentInput).length);

            setValue(currentInput, textBeforeCursorPosition + selectedTagsValue + textAfterCursorPosition);
        }
        else {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t('PLEASE_SELECT_ONE_OF_THE_CONTENT_TO_INSERT_TAG'),
                onConfirm: () => { }
            }));
        }
    }

    /// <summary>
    /// Author: Wind
    /// </summary> 
    const submitNotificationForm = async (data, e) => {
        let params = {
            name: JSON.stringify(supportedLanguage.map(item => ({ languageId: item.id, value: data[`${item.id}`] }))),
            description: data.notificationDesciption,
            notificationTypeId: data.notificationTypeId,
            status: data.notificationStatus,
        };

        var responseJson;

        if (_location.state && _location.state.id) {
            responseJson = await ApiEngine.put(ApiUrl._API_NOTIFICATION_TEMPLATE + '/' + _location.state.id, createFormBody(params));
        }
        else {
            responseJson = await ApiEngine.post(ApiUrl._API_NOTIFICATION_TEMPLATE, createFormBody(params));
        }

        
        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_NOTIFICATION_SETTINGS)
        }));
    }

    /// <summary>
    /// Author: Wind
    /// </summary> 
    const handleInputCursor = (e) => {
        setCursorPosition(e.target.selectionStart);
    }

    /// <summary>
    /// Author: Wind
    /// </summary> 
    const handleCurrentInput = (e, id) => {
        setCurrentInput(id);
        handleInputCursor(e);
    }

    return (
        <div>
            <h1 className="page-header">{_location.state ? t("EDIT_NOTIFICATION_TEMPLATE") : t("CREATE_NOTIFICATION_TEMPLATE")}<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitNotificationForm)}>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>{t("CATEGORY")}</label>
                                            <Select 
                                                name="notificationTypeId"
                                                options={notificationTypeOption}
                                                value={notificationTypeOption.filter(option => option.value == notificationTypeId)}
                                                onChange={(e) => {
                                                    setNotificationTypeId(e.value);
                                                    setValue('notificationTypeId', e.value);
                                                }} />
                                            {errors.notificationTypeId && <div className="invalid-feedback">{t(errors.notificationTypeId.message)}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>{t("NOTIFICATION_DESCRIPTION")}</label>
                                            <input
                                                type="text"
                                                name="notificationDesciption"
                                                ref={register({ required: "PLEASE_ENTER_DESCRIPTION" })}
                                                className="form-control form-control-lg"
                                                placeholder={t("ENTER_NOTIFICATION_DESCRIPTION")} />
                                            {errors.notificationDesciption && <div className="invalid-feedback">{t(errors.notificationDesciption.message)}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>{t("STATUS")}</label><br />
                                            <div className="switcher">
                                                <input
                                                    type="checkbox"
                                                    name="notificationStatus"
                                                    id="notificationStatus"
                                                    onChange={(e) => {
                                                        setValue("notificationStatus", e.target.checked)
                                                    }}
                                                    checked={watch("notificationStatus")}
                                                    ref={register} />
                                                <label htmlFor="notificationStatus"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>{t("SELECTABLE_TAGS")}</label>
                                            <Select
                                                options={_TAG_OPTIONS}
                                                value={_TAG_OPTIONS.filter(option => option.value == selectedTagsValue)}
                                                onChange={(e) => { setSelectedTagsValue(e.value); }} />
                                            <br />
                                            <button type="button" className="btn btn-primary" onClick={() => {
                                                !stringIsNullOrEmpty(selectedTagsValue) && insertTagIntoContent()
                                            }}>{t("INSERT_TAG")}</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" ref={formRef}>
                                    {
                                        supportedLanguage && supportedLanguage.map((item, index) => {
                                            return (
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label>
                                                            {t("CONTENT") + " (" + item.name + ")"}
                                                        </label>
                                                        <textarea 
                                                            className="form-control form-control-lg"
                                                            rows="8"
                                                            id={item.id}
                                                            name={item.id}
                                                            defaultValue={item.value}
                                                            ref={register({ required: "PLEASE_ENTER_CONTENT" })}
                                                            placeholder={t("ENTER_NOTIFICATION_TEMPLATE") + " (" + item.name + ")"}
                                                            onClick={(e) => handleCurrentInput(e, item.id)}
                                                            onFocus={(e) => handleCurrentInput(e, item.id)}
                                                            onKeyUp={(e) => handleInputCursor(e)} />
                                                        {errors[`${item.id}`] && <div className="invalid-feedback">{t(errors[`${item.id}`].message)}</div>}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <button type="submit" className="btn btn-primary"> {t("BTN_SAVE")}</button>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    )
}

export default NotificationDetails;