import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm, useFormContext } from "react-hook-form";
import { PackagePayRateType } from 'util/Constant';

/// <summary>
/// Author : A
/// </summary>
const LottoPayrateTable = forwardRef((props, ref) => {
    const { register, setValue, getValues, triggerValidation, errors } = useForm();
    const [agentPackage, setAgentPackage] = useState();

    const _FIRST_PRIZE = "First";
    const _STARTER_PRIZE = "Starter";
    const _PAY_RATE_KEY = "PayRate";

    const _PAY_RATE_TYPE = {
        Base: {
            Key: "Base",
            Value: PackagePayRateType._BASE
        },
        Custom: {
            Key: "Custom",
            Value: PackagePayRateType._CUSTOM
        }
    }

    const _EAT_TYPE = {
        Big: "B",
        Small: "S",
        D4A: "SA",
        D4SB: "SB",
        D4SC: "SC",
        D4SD: "SD",
        D4SE: "SE",
        D4C: "C",
        D5: "5D",
        D6: "6D",
        D2: "2D",
        D3: "3D"
    };

    const _PAYOUT_STRUCTURE = {
        "1st Prize / A": {
            label: "First",
            type: "A",
            eatType: [
                _EAT_TYPE.Big, _EAT_TYPE.Small, _EAT_TYPE.D4A, _EAT_TYPE.D4C, _EAT_TYPE.D5, _EAT_TYPE.D6, _EAT_TYPE.D2, _EAT_TYPE.D3
            ]
        },
        "2nd Prize / B":
        {
            label: "Second",
            type: "SB",
            eatType: [
                _EAT_TYPE.Big, _EAT_TYPE.Small, _EAT_TYPE.D4SB, _EAT_TYPE.D4C, _EAT_TYPE.D5, _EAT_TYPE.D6, _EAT_TYPE.D2, _EAT_TYPE.D3
            ]
        },
        "3rd Prize / C":
        {
            label: "Third",
            type: "SC",
            eatType: [
                _EAT_TYPE.Big, _EAT_TYPE.Small, _EAT_TYPE.D4SC, _EAT_TYPE.D4C, _EAT_TYPE.D5, _EAT_TYPE.D6, _EAT_TYPE.D2, _EAT_TYPE.D3
            ]
        },
        "4th Prize / D": {
            label: "Fourth",
            type: "SD",
            eatType: [
                _EAT_TYPE.D5, _EAT_TYPE.D6, _EAT_TYPE.D2, _EAT_TYPE.D3
            ]
        },
        "5th Prize / E":
        {
            label: "Fifth",
            type: "SE",
            eatType: [
                _EAT_TYPE.D5, _EAT_TYPE.D6, _EAT_TYPE.D2, _EAT_TYPE.D3
            ]
        },
        "6th Prize":
        {
            label: "Sixth",
            eatType: [
                _EAT_TYPE.D5
            ]
        },
        "Starter / ABC":
        {
            label: "Starter",
            type: "C",
            eatType: [
                _EAT_TYPE.Big, _EAT_TYPE.D4SD, _EAT_TYPE.D2, _EAT_TYPE.D3
            ]
        },
        "Consolation / AE":
        {
            label: "Consolation",
            type: "B",
            eatType: [
                _EAT_TYPE.Big, _EAT_TYPE.D4SE, _EAT_TYPE.D2, _EAT_TYPE.D3
            ]
        }
    };

    /// <summary>
    /// Author : A
    /// </summary>
    useEffect(() => {
        if (props.package?.length > 0) {
            let basePayrate = props.package.find(p => p.payRateTypeId == _PAY_RATE_TYPE.Base.Value);
            Object.keys(basePayrate).map((key) => {
                if (key.endsWith(_PAY_RATE_KEY)) {
                    setValue(`${_PAY_RATE_TYPE.Base.Key}${key[0].toUpperCase() + key.substring(1)}`, basePayrate[key]);
                }
            });

            let customPayrate = props.package.find(p => p.payRateTypeId == _PAY_RATE_TYPE.Custom.Value);
            if (customPayrate) {
                // User package only needed when editing agent
                // Else use custom payrate to set as reference limit
                if (!props.userPackage) {
                    Object.keys(customPayrate).map((key) => {
                        if (key.endsWith(_PAY_RATE_KEY)) {
                            setValue(`${_PAY_RATE_TYPE.Custom.Key}${key[0].toUpperCase() + key.substring(1)}`, customPayrate[key]);
                        }
                    })
                } else {
                    Object.keys(props.userPackage).map((key) => {
                        if (key.endsWith(_PAY_RATE_KEY)) {
                            register(`${_PAY_RATE_TYPE.Custom.Key}${key[0].toUpperCase() + key.substring(1)}`, { max: props.userPackage[key] });
                        }
                    });
                }
            }

            if (props.userPackage) {
                Object.keys(props.userPackage).map((key) => {
                    if (key.endsWith(_PAY_RATE_KEY)) {
                        setValue(`${_PAY_RATE_TYPE.Custom.Key}${key[0].toUpperCase() + key.substring(1)}`, props.userPackage[key]);
                    }
                })
            }
        }
    }, [props.package, props.userPackage]);

    /// <summary>
    /// Author : A
    /// </summary>
    useImperativeHandle(ref, () => ({
        async getPayrate() {
            let result = {
                success: false,
                data: null
            };

            result.success = await triggerValidation();
            let payrates = getValues();

            if (!result.success) {
                Object.keys(errors).map((k) => {
                    errors[k].value = parseFloat(payrates[k]);
                    errors[k].description = k.replace(_PAY_RATE_KEY, '').replace(_PAY_RATE_TYPE.Custom.Key, '');
                })
                result.message = Object.values(errors);
            }

            let payrateObj = {
                basePayrate: {},
                customPayrate: {}
            };

            Object.entries(payrates).map((pr) => {
                if (pr[0].startsWith(_PAY_RATE_TYPE.Base.Key)) {
                    payrateObj.basePayrate[pr[0].replace(_PAY_RATE_TYPE.Base.Key, "")] = Number(pr[1]);
                } else {
                    payrateObj.customPayrate[pr[0].replace(_PAY_RATE_TYPE.Custom.Key, "")] = Number(pr[1]);
                }
            });

            result.data = payrateObj;

            return result;
        }
    }))

    /// <summary>
    /// Author : A
    /// </summary>
    const updatePayRate = (type, prize, eatType, value) => {
        var payRateValue = value;

        var cValue = Number(parseFloat(payRateValue > 0 ? payRateValue / 3 : 0).toFixed(2));
        var sdOrSeValue = Number(parseFloat(payRateValue > 0 ? payRateValue / 10 : 0).toFixed(2));
        var bValue = Number(parseFloat(payRateValue > 0 ? payRateValue / 23 : 0).toFixed(2));

        switch (eatType) {
            case _EAT_TYPE.D4A:
                setValue(`${type}D4SBSecondPayRate`, payRateValue);
                setValue(`${type}D4SCThirdPayRate`, payRateValue);

                setValue(`${type}D4CFirstPayRate`, cValue);
                setValue(`${type}D4CSecondPayRate`, cValue);
                setValue(`${type}D4CThirdPayRate`, cValue);

                setValue(`${type}D4SDStarterPayRate`, sdOrSeValue);
                setValue(`${type}D4SEConsolationPayRate`, sdOrSeValue);
                break;
            case _EAT_TYPE.D5:
                if (prize == _FIRST_PRIZE) {
                    setValue(`${type}D5SecondPayRate`, Number(parseFloat(payRateValue / 3).toFixed(2)));
                    setValue(`${type}D5ThirdPayRate`, Number(parseFloat(payRateValue / 5).toFixed(2)));
                    setValue(`${type}D5FourthPayRate`, Number(parseFloat(payRateValue / 30).toFixed(2)));
                    setValue(`${type}D5FifthPayRate`, Number(parseFloat(payRateValue / 750).toFixed(2)));
                    setValue(`${type}D5SixthPayRate`, Number(parseFloat(payRateValue / 3000).toFixed(2)));
                }
                break;
            case _EAT_TYPE.D6:
                if (prize == _FIRST_PRIZE) {
                    setValue(`${type}D6SecondPayRate`, Number(parseFloat(payRateValue * 0.03).toFixed(2)));
                    setValue(`${type}D6ThirdPayRate`, Number(parseFloat(payRateValue * 0.003).toFixed(2)));
                    setValue(`${type}D6FourthPayRate`, Number(parseFloat(payRateValue * 0.0003).toFixed(2)));
                    setValue(`${type}D6FifthPayRate`, Number(parseFloat(payRateValue / 25000).toFixed(2)));

                }
                break;
            case _EAT_TYPE.D3:
                if (prize == _FIRST_PRIZE) {
                    setValue(`${type}D3SBSecondPayRate`, payRateValue);
                    setValue(`${type}D3SCThirdPayRate`, payRateValue);
                    setValue(`${type}D3SDFourthPayRate`, sdOrSeValue);
                    setValue(`${type}D3SEFifthPayRate`, sdOrSeValue);

                    setValue(`${type}D3CFirstStarterPayRate`, cValue);
                    setValue(`${type}D3CSecondStarterPayRate`, cValue);
                    setValue(`${type}D3CThirdStarterPayRate`, cValue);

                    setValue(`${type}D3BConsolationPayRate`, bValue);
                }
                break;
            case _EAT_TYPE.D2:
                if (prize == _FIRST_PRIZE) {
                    setValue(`${type}D2SBSecondPayRate`, payRateValue);
                    setValue(`${type}D2SCThirdPayRate`, payRateValue);
                    setValue(`${type}D2SDFourthPayRate`, sdOrSeValue);
                    setValue(`${type}D2SEFifthPayRate`, sdOrSeValue);

                    setValue(`${type}D2CFirstStarterPayRate`, cValue);
                    setValue(`${type}D2CSecondStarterPayRate`, cValue);
                    setValue(`${type}D2CThirdStarterPayRate`, cValue);

                    setValue(`${type}D2BConsolationPayRate`, bValue);
                }
                break;
        }
    }

    /// <summary>
    /// Author : A
    /// </summary>
    const InputField = (payRateType, val, ps) => {
        let name = `${payRateType}${val}${_PAYOUT_STRUCTURE[ps].label}${_PAY_RATE_KEY}`;

        if (val == "D2" || val == "D3") {
            name = `${payRateType}${val}${_PAYOUT_STRUCTURE[ps].type}${_PAYOUT_STRUCTURE[ps].label}${_PAY_RATE_KEY}`;
        }

        let placeholder = name.replace(payRateType, '');
        let objKey = placeholder.charAt(0).toLowerCase() + placeholder.slice(1);
        let validationObj = {};

        if (props.package.length > 0) {
            let customPackage = props.package.find(p => p.payRateTypeId == _PAY_RATE_TYPE.Custom.Value);

            if (props.userPackage && payRateType == _PAY_RATE_TYPE.Custom.Key) {
                validationObj.max = {
                    value: customPackage[objKey],
                    message: customPackage[objKey]
                };
            }
        }

        return <input
            type="number"
            step="any"
            key={name}
            name={name}
            readOnly={(props.baseReadonly && payRateType == _PAY_RATE_TYPE.Base.Key) ? true : false}
            tabindex={(props.baseReadonly && payRateType == _PAY_RATE_TYPE.Base.Key) && -1}
            placeholder={`${payRateType} Payrate`} className='form-control'
            style={{ display: 'block', pointerEvents: ((props.baseReadonly && payRateType == _PAY_RATE_TYPE.Base.Key) ? "none" : "auto") }}
            onInput={(e) => updatePayRate(payRateType, _PAYOUT_STRUCTURE[ps].label, _EAT_TYPE[val], e.target.value)}
            ref={register(validationObj)}
            autoComplete="off"
        />;
    }

    return (
        <div className='table-responsive'>
            <table className='table table-bordered table-lotto-payrate m-b-0'>
                <thead>
                    <tr>
                        <th rowSpan={2}></th>
                        <th colSpan={8}>4D</th>
                        <th rowSpan={2}>5D</th>
                        <th rowSpan={2}>6D</th>
                        <th rowSpan={2}>2D</th>
                        <th rowSpan={2}>3D</th>
                    </tr>
                    <tr>
                        <th>B</th>
                        <th>S</th>
                        <th>SA</th>
                        <th>SB</th>
                        <th>SC</th>
                        <th>SD</th>
                        <th>SE</th>
                        <th>C</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(_PAYOUT_STRUCTURE).map((ps) => {
                            return <tr>
                                <th>{ps}</th>
                                {
                                    Object.keys(_EAT_TYPE).map((et) => {
                                        if ((_EAT_TYPE[et] == _EAT_TYPE.D3 || _EAT_TYPE[et] == _EAT_TYPE.D2) && _PAYOUT_STRUCTURE[ps].label == _STARTER_PRIZE) {
                                            return <th>
                                                {
                                                    [`${et}CFirst`, `${et}CSecond`, `${et}CThird`].map((val) => {
                                                        return <>
                                                            {InputField(_PAY_RATE_TYPE.Base.Key, val, ps)}
                                                            {props.customPayRate && InputField(_PAY_RATE_TYPE.Custom.Key, val, ps)}
                                                        </>
                                                    })
                                                }
                                            </th>
                                        } else {
                                            return <th>
                                                {
                                                    _PAYOUT_STRUCTURE[ps].eatType.indexOf(_EAT_TYPE[et]) >= 0 &&
                                                    <>
                                                        {InputField(_PAY_RATE_TYPE.Base.Key, et, ps)}
                                                        {props.customPayRate && InputField(_PAY_RATE_TYPE.Custom.Key, et, ps)}
                                                    </>
                                                }
                                            </th>
                                        }
                                    })
                                }
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    );
});

export default LottoPayrateTable;