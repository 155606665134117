import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { navigateTo } from "../../../util/Util";
import { ApiKey, ApiUrl, WebUrl, AccessRight, Role, Status, AlertTypes } from "../../../util/Constant";
import NavigationButton from '../../../components/custom/NavigationButton';
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import ResetPasswordModal from '../../../components/custom/ResetPasswordModal';
import { useDispatch } from "react-redux";
import ApiEngine from '../../../util/ApiEngine.js';
import { showResponseMessage, showMessage } from 'redux/AppAction';
import { createMultiPartFormBody } from "../../../util/Util";

/// <summary>
/// Author : YJ
/// </summary>
const ManageAdmin = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    const { register, handleSubmit } = useForm();
    const [adminApiUrl, setAdminApiUrl] = useState('');
    var [count, setCount] = useState(0);
    const [userId, setUserId] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    var _dispatch = useDispatch();

    let _tableColumns = useMemo(() => [
        {
            Header: t("USERNAME"),
            accessor: "username",
            disableSortBy: true
        },
        {
            Header: t("FULLNAME"),
            accessor: "userFullName",
            disableSortBy: true
        },
        {
            Header: t("ROLES"),
            accessor: "roleName",
            disableSortBy: true
        },
        {
            Header: t("STATUS"),
            accessor: "status",
            Cell: ({ row }) => {
                return <span>{row.original.status == 1 ? <span class="badge badge-secondary badge-green">{t("ACTIVE")}</span> : <span class="badge badge-secondary badge-danger">{t("SUSPENDED") + " (" + row.original.invalidLoginCount + ")"}</span>}</span>
            },
            minWidth: 100,
            disableSortBy: true
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._ADMIN_SUB_ACCOUNT_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_ADMIN_ADMIN_DETAIL, row.original) }}><span className="text-warning">{t("EDIT")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t("ARE_YOU_SURE"),
                                    content: row.original.status == Status._ENABLED ? t("ARE_YOU_SURE_YOU_WANT_TO_BLOCK_THIS_USER") : t("ARE_YOU_SURE_YOU_WANT_TO_UNBLOCK_THIS_USER"),
                                    showCancel: true,
                                    onConfirm: () => userStatusHandler(row.original.id, row.original.status == Status._ENABLED ? Status._DISABLED : Status._ENABLED)
                                }));
                            }}><span className="text-warning">{row.original.status == Status._ENABLED ? t("BLOCK") : t("UNBLOCK")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t("ARE_YOU_SURE"),
                                    content: t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_USER"),
                                    showCancel: true,
                                    onConfirm: () => userStatusHandler(row.original.id, Status._DELETED)
                                }));
                            }}><span className="text-red">{t("DELETE")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t("ARE_YOU_SURE"),
                                    content: t(row.original.isAbleViewLastMessage ? "CONFIRM_TO_BLOCK_VIEW_LAST_MESSAGE" : "CONFIRM_TO_ENABLE_VIEW_LAST_MESSAGE"),
                                    showCancel: true,
                                    onConfirm: async () => await viewLastMessageSetup(row.original.id, !row.original.isAbleViewLastMessage)
                                }));
                            }}>
                                <span> className="text-warning"{t(row.original.isAbleViewLastMessage ? "BLOCK_TO_VIEW_LAST_MESSAGE" : "ENABLE_TO_VIEW_LAST_MESSAGE")}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                setUserId(row.original.id);
                                setIsModalVisible(true);
                            }}><span>{t("RESET_PASSWORD")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                const win = window.open(WebUrl._URL_VIEW_USER_ACTION_LOG + "?username=" + row.original.username
                                    , "_blank");
                                win.focus();
                            }}>
                                <span>{t('VIEW_USER_ACTION_LOG')}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ], [adminApiUrl]);

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        handleSubmit(submitForm)();
    }, []);

    /// <summary>
    /// Author : YJ
    /// </summary>
    const submitForm = async (data, e) => {
        var rawUrl = ApiUrl._API_GET_USERS + "?RoleId=" + Role._ADMIN_SUB_ACCOUNT + "&v=" + (++count);
        setCount(count);

        if (data) {
            rawUrl += "&FullName=" + data.name + "&RegionId=" + data.region + "&Username=" + data.username;
        }

        setAdminApiUrl(rawUrl);
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function userStatusHandler(userId, status) {
        let params = {
            id: userId,
            status: status,
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_USER_ACCOUNT, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleSubmit(submitForm)();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>      
    async function viewLastMessageSetup(id, isAbleViewLastMsg) {
        var responseJson = await ApiEngine.post(`${ApiUrl._API_CREATE_OR_UPDATE_USER_ACCOUNT}`, createMultiPartFormBody({ id: id, isAbleViewLastMessage: isAbleViewLastMsg }));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleSubmit(submitForm)();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
    }

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_ADMIN")}</h1>
                {
                    checkIfPermissionExist(AccessRight._ADMIN_SUB_ACCOUNT_CONTROL_PERMISSION, true) &&
                    <NavigationButton history={_history} url={WebUrl._URL_ADMIN_ADMIN_DETAIL} buttonText={t("ADD_NEW_ADMIN")} />
                }
            </div>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("NAME")}</label>
                                    <input type="text" name="name" ref={register} className="form-control form-control-lg" placeholder={t("FULL_OR_PART_OF_THE_NAME")} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("USERNAME")}</label>
                                    <input type="text" name="username" ref={register} className="form-control form-control-lg" placeholder={t("FULL_OR_PART_OF_THE_USERNAME")} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel className="panel-react-table">
                <PanelBody>
                    <ReactTable fetchUrl={adminApiUrl} columns={_tableColumns} />
                </PanelBody>
            </Panel>

            <ResetPasswordModal
                onFinish={() => {
                    setIsModalVisible(false);
                }}
                targetUserId={userId}
                visible={isModalVisible} />
        </div>
    )
}

export default ManageAdmin;