import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    WebUrl,
    ApiKey,
    ApiUrl,
    AccessRight,
    Status,
    ProcessedAnnouncementStatus
} from "../../../util/Constant";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
// components
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Toast,
    ToastBody
} from "reactstrap";
import {
    Panel,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import NavigationButton from "../../../components/custom/NavigationButton";
import ReactTable from "../../../components/custom/ReactTable";
import { showResponseMessage } from "../../../redux/AppAction";
import ApiEngine from "../../../util/ApiEngine";
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import { stringIsNullOrEmpty } from "../../../util/Util";

/// <summary>
/// Author : Lewis
/// </summary>
const ManagePromotion = (props) => {
    const { t, i18n } = useTranslation();
    const { register, handleSubmit } = useForm();
    const _history = useHistory();
    const _dispatch = useDispatch();
    const [promotions, setPromotions] = useState([]);
    const [showWinningDetail, setShowWinningDetail] = useState(false);
    const [promotionWinningData, setPromotionWinningData] = useState([]);
    const [apiUrl, setApiUrl] = useState("");
    const [pageData, setPageData] = useState([]);
    const [countData, setCountData] = useState([]);
    var _detailPromotionUrl = props.isSpinPromotion ? WebUrl._URL_PROMOTION_MANAGE_SPIN_PROMOTION_DETAIL : WebUrl._URL_PROMOTION_MANAGE_PROMOTION_DETAIL;
    const _LOADER_SIZE = 30;
    const [showToast, setShowToast] = useState('');

    let _winnerTableColumns = [
        {
            Header: "DATE",
            accessor: "createdTime",
            disableSortBy: true
        },
        {
            Header: "USER",
            accessor: "user",
            disableSortBy: true
        },
        {
            Header: "PRIZE_NAME",
            accessor: "prizeName",
            disableSortBy: true
        },
        {
            Header: "AMOUNT",
            accessor: "prizeAmount",
            Cell: ({ row }) => {
                return <span className="text-success">{row.original.prizeAmount}</span>;
            },
            disableSortBy: true
        }
    ]

    /// <summary>
    /// Author: Nelson
    /// </summary>
    const _tableColumns = useMemo(
        () => [
            {
                Header: "DESCRIPTION",
                accessor: "description",
                Cell: ({ row }) => {
                    var description = 'Title: ' + row.original.title + ' <br/>';
                    description += 'Message: ' + row.original.content + ' <br/>';
                    description += 'Start Date: ' + row.original.startDateString + ' <br/>';
                    description += 'End Date:' + row.original.endDateString;
                    return <span dangerouslySetInnerHTML={{ __html: description }}></span>
                },
                minWidth: 200
            },
            {
                Header: "MODE",
                accessor: "mode",
            },
            {
                Header: "SHOPS",
                accessor: "listOfShopsInString",
                minWidth: 400,
                Cell: ({ row }) => {
                    return <span><b>{row.original.region ? row.original.region.name + " - " : ""}</b>{row.original.listOfShopsInString}</span>
                }
            },
            {
                Header: "CREATED_DATE",
                accessor: "createdTime",
            },
            {
                Header: "MODIFIED_DATE",
                accessor: "updatedTime",
            },
            {
                Header: "STATUS",
                Cell: ({ row }) => {
                    if (countData.length > 0) {
                        let returnClassName = "";
                        let rowData = countData.find(x => x.promotionId == row.original.id);

                        switch (row.original.processedStatus) {
                            case ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_DRAFT:
                                returnClassName = "badge badge-secondary";
                                break;
                            case ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_CANCELLED:
                            case ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_BROADCAST_CANCELLED:
                                returnClassName = "badge badge-danger";
                                break;
                            case ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_IN_PROGRESS:
                                returnClassName = "badge badge-primary";
                                break;
                            case ProcessedAnnouncementStatus._PROCESSED_ANNOUNCEMENT_COMPLETED:
                                returnClassName = "badge badge-success";
                                break;
                        }
                        return <span className={returnClassName} style={{ textTransform: "uppercase" }}>{t(row.original.processedStatusString)} {rowData?.totalSentCount}/{rowData?.totalNumberOfSentCount}</span>
                    }
                    else {
                        return <ClipLoader size={_LOADER_SIZE} color={"#2554C7"} />
                    }
                },
                minWidth: 100
            },
            {
                Header: "CLAIM_INFORMATION",
                Cell: ({ row }) => {
                    if (countData.length > 0) {
                        let rowData = countData.find(x => x.promotionId == row.original.id);

                        return (
                            <div>
                                <div className="td-singleline">{t("TOTAL_CLAIMED")} : {rowData?.totalClaimCount}</div>
                                <div className="td-singleline">{t("AMOUNT")}: {parseFloat(rowData?.totalClaimAmount).toFixed(2)}</div>
                            </div>
                        );
                    }
                    else {
                        return <ClipLoader size={_LOADER_SIZE} color={"#2554C7"} />
                    }
                },
                minWidth: 100
            },
            {
                Header: "ACTION",
                Cell: ({ row }) => (
                    <div className="btn-group m-r-5 m-b-5">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="default">
                                <i className="fas fa-cog"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={() => {
                                        _history.push({
                                            pathname: _detailPromotionUrl,
                                            state: { id: row.original.id }
                                        })
                                    }}>
                                    <span>{t("EDIT_BONUS_DETAILS")}</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => {
                                    setPromotionStatus(row.original.id, row.original.status == Status._ENABLED ?
                                        Status._DISABLED : Status._ENABLED)
                                }}>
                                    <span>{row.original.status == Status._ENABLED ? t("DEACTIVATE") : t("ACTIVATE")}</span>
                                </DropdownItem>
                                {props.isSpinPromotion &&
                                    <>
                                        <DropdownItem onClick={() => { getWinningData(row.original.id) }}>
                                            <span>{t("VIEW")}</span>
                                        </DropdownItem>
                                        <DropdownItem onClick={() => {
                                            navigator.clipboard.writeText(`${row.original.region.domain}/lucky-spin-winner?promotionId=${row.original.id}`);
                                            setShowToast(`${row.original.region.domain}/lucky-spin-winner?promotionId=${row.original.id}`);
                                        }}>
                                            <span>{t("COPY_WINNER_LIST_URL")}</span>
                                        </DropdownItem>
                                    </>
                                }
                                {
                                    row.original.status == Status._ENABLED && !props.isSpinPromotion && <DropdownItem
                                        onClick={() => {
                                            broadcastJackpotList(row.original.id)
                                        }}>
                                        <span>{t("BROADCAST")}</span>
                                    </DropdownItem>
                                }
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div>
                ),
            },
        ], [countData]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        (async () => {
            try {
                if (pageData.length > 0) {
                    let responseJson = await ApiEngine.get(ApiUrl._API_GET_PROMOTION_DETAILS_COUNT + '?idString=' +
                        pageData.map((item) => { return item.original.id }).join(','), { headers: { "skipLoading": true } });

                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setCountData(responseJson[ApiKey._API_DATA_KEY]);
                    }
                }
            }
            catch (err) {
                /// do nothing
            }
        })();
    }, [pageData]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(showToast)) {
            setTimeout(() => setShowToast(''), 3000)
        }
    }, [showToast])

    /// <summary>
    /// Author: Nelson
    /// </summary>
    const submitForm = async (data, e) => {
        setApiUrl(ApiUrl._API_GET_PROMOTIONS + "?Name=" + data.title + "&Message=" + data.content + "&isSpinPromotion=" + props.isSpinPromotion);
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    useEffect(() => {
        handleSubmit(submitForm)();
    }, []);

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function setPromotionStatus(id, status) {
        var responseJson = await ApiEngine.post(ApiUrl._API_SET_PROMOTION_STATUS + "?id=" + id + "&status=" + status);
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleSubmit(submitForm)();
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function broadcastJackpotList(id) {
        var responseJson = await ApiEngine.post(ApiUrl._API_BROADCAST_MEMBER_PRIZE + "?promotionId=" + id);

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function getWinningData(id) {
        var responseJson = await ApiEngine.get(ApiUrl._API_PROMOTION_WINNING_DETAIL + "?promotionId=" + id);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setShowWinningDetail(true);
            setPromotionWinningData(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    const PaginationTable = useMemo(() =>
        <ReactTable
            columns={_tableColumns}
            fetchUrl={apiUrl}
            exportRequired={true}
            getFilteredRecords={({ page }) => setPageData(page)} />,
        [apiUrl, countData])

    return (
        <>
            <div className="manage-promotion">
                <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                    <h1 className="page-header">{t("MANAGE_BONUS")}</h1>
                    {
                        checkIfPermissionExist(AccessRight._PROMOTION_CONTROL_PERMISSION, true) &&
                        <NavigationButton history={_history} url={_detailPromotionUrl} buttonText={t("NEW")} />
                    }
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <Panel>
                            <PanelBody>
                                <form onSubmit={handleSubmit(submitForm)}>
                                    <div className="row">
                                        <div className="col-lg-3 m-b-10">
                                            <label>{t("TITLE")}</label>
                                            <input type="text" name="title" ref={register} className="form-control form-control-lg" placeholder={t("FULL_OR_PART_OF_TITLE")} />
                                        </div>
                                        <div className="col-lg-3">
                                            <label>{t("CONTENT")}</label>
                                            <input type="text" name="content" ref={register} className="form-control form-control-lg" placeholder={t("FULL_OR_PART_OF_CONTENT")} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-2">
                                            <button type="submit" className="btn btn-primary">{t("APPLY_FILTER")}</button>
                                        </div>
                                    </div>
                                </form>
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <Panel>
                            <PanelBody>
                                <div className="row">
                                    <div className="col-lg-12">
                                        {PaginationTable}
                                    </div>
                                </div>
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
            <Modal isOpen={showWinningDetail} toggle={() => setShowWinningDetail(false)} style={{ paddingBottom: "100px" }} className="modal-lg" keyboard={true} >
                <ModalHeader toggle={() => setShowWinningDetail(false)}>{t("SUMMARY")}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <ReactTable columns={_winnerTableColumns} data={promotionWinningData} exportRequired={true} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn btn-inverse-brand" onClick={() => setShowWinningDetail(false)}>{t("BTN_CLOSE")}</button>
                </ModalFooter>
            </Modal>
            <Toast style={{ position: 'fixed', bottom: '1rem', left: '50%' }} isOpen={!stringIsNullOrEmpty(showToast)} transition={{ key: "1", transitionLeaveTimeout: 3000 }}>
                <ToastBody>
                    {`${t('COPIED')} ${showToast}`}
                </ToastBody>
            </Toast>
        </>
    );
}

export default ManagePromotion;
