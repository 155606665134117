import { createStore, applyMiddleware, combineReducers } from "redux";
import appReducer from "./redux/AppReducer";
import authReducer from "./redux/AuthReducer";
import memberReducer from "./redux/MemberReducer";
import thunk from 'redux-thunk';
import firebaseReducer from "./redux/FirebaseReducer";

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const store = createStore(combineReducers({
    authState: authReducer,
    appState: appReducer,
    memberState: memberReducer,
    firebaseState: firebaseReducer
}), applyMiddleware(thunk));

export default store;