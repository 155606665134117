import React, { useEffect, useState, useRef } from 'react';
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import { ApiKey, ApiUrl, AlertTypes, TransactionTypeId } from '../../util/Constant';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { showResponseMessage, setBusy, setIdle } from '../../redux/AppAction';
import { createMultiPartFormBody, stringIsNullOrEmpty } from '../../util/Util';

/// <summary>
/// Author: YJ
/// </summary>
const AppealRequestModal = props => {
    var _dispatch = useDispatch();
    const { register, watch } = useForm();
    const [modalVisibility, setModalVisibility] = useState(false);
    const { t } = useTranslation();
    const [detail, setDetail] = useState({});

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        setModalVisibility(props["visible"]);
        setDetail(props["detail"]);
    }, [props["visible"]]);

    /// <summary>
    /// Author: YJ
    /// </summary>
    useEffect(() => {
        if (!modalVisibility) {
            props.onFinish(false);
        }
    }, [modalVisibility]);

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function requestAppeal() {
        try {
            let params = {
                "memberId": detail.memberId ?? "",
                "amount": detail.amount,
                "transactionTypeId": detail.type
            };

            if (detail["type"] == TransactionTypeId._SET_SCORE) {
                params["code"] = detail.code;
                params["username"] = detail.username;
            }

            if(!stringIsNullOrEmpty(detail["shopId"]))
            {
                params["shopId"] = detail["shopId"];
            }

            let responseJson = await ApiEngine.post(ApiUrl._API_REQUEST_APPEAL_TRANSACTION, createMultiPartFormBody(params));
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])))

            props.onFinish(responseJson[ApiKey._API_SUCCESS_KEY]);
        }
        catch(err) {
            props.onFinish(false);
            _dispatch(showResponseMessage(false, err))
        }
    }

    return (
        <div>
            {modalVisibility && <SweetAlert
                type={AlertTypes._WARNING}
                title={t("FAILED_ROLLOVER_DOES_NOT_REACH")}
                customButtons={<div style={{ width: "100%" }}>
                    <div style={{ textAlign : "center" }}>
                        <button className="btn btn-primary" type="button" onClick={() => { requestAppeal() }}>
                            {t('CONFIRM')}
                        </button>
                        <button className="btn btn-danger" type="button" onClick={() => { props.onFinish(false) }}>
                            {t('CLOSE')}
                        </button>
                    </div>
                </div>}
                allowEscape={false}>
                    {t("DO_YOU_WANT_TO_APPEAL_THIS_TRANSACTION")}
            </SweetAlert>}
        </div>
    );
}
export default AppealRequestModal;