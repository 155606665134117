import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { WebUrl, ApiUrl, ApiKey, AlertTypes } from "../../../util/Constant";
import * as Util from "../../../util/Util";
import { showMessage } from "../../../redux/AppAction";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import NavigationButton from "../../../components/custom/NavigationButton";
import ApiEngine from "../../../util/ApiEngine.js";
import moment from "moment";
import DateTime from 'react-datetime';

/// <summary>
/// Author: CK
/// </summary>
const ThirdPartyApiDetail = () => {
	let _history = useHistory();
	let _location = useLocation();
	let _dispatch = useDispatch();
	const { register, handleSubmit, errors, setValue } = useForm();
	const { t } = useTranslation();
	const [selectedThirdPartyApi, setSelectedThirdPartyApi] = useState(null);
	const [thirdPartyList, setThirdPartyList] = useState([]);
	const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'));
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'));

	/// <summary>
	/// Author: CK
	/// </summary>
	useEffect(() => {
		init();
	}, []);

	/// <summary>
	/// Author: CK
	/// </summary>
	async function init() {
		var responseJson = await ApiEngine.get(ApiUrl._API_GET_THIRD_PARTY);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setThirdPartyList(responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY]);
		}

		if (_location.state && _location.state.id) {
			await getThirdPartyApiDetails(_location.state.id);
		}
	}

	/// <summary>
	/// Author: CK
	/// </summary>
	async function getThirdPartyApiDetails(id) {
		var responseJson = await ApiEngine.get(ApiUrl._API_GET_THIRD_PARTY_API + "/" + id);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let data = responseJson[ApiKey._API_DATA_KEY];

			setSelectedThirdPartyApi(data);
			setValue("name", data.name);
			setValue("thirdPartyProgramId", data.thirdPartyProgramId);
			setValue("apiKey", data.apiKey);
			setValue("apiSecret", data.apiSecret);
			setValue("clientKey", data.clientKey);
			setValue("clientSecret", data.clientSecret);
			setValue("status", data.status);
			setValue("eventKey", data.eventKey);
			setStartDate(data.startDate);
			setEndDate(data.endDate);
		}
	}

	/// <summary>
	/// Author: CK
	/// Edited: A
	/// </summary>
	async function onSubmit(data) {
		if (selectedThirdPartyApi) {
			data["id"] = selectedThirdPartyApi.id;
		}

		data["startDate"] = startDate;
		data["endDate"] = endDate;

		var responseJson = await ApiEngine.post(ApiUrl._API_GET_THIRD_PARTY_API, Util.createMultiPartFormBody(data));
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setSelectedThirdPartyApi(responseJson[ApiKey._API_DATA_KEY]);
		}

		_dispatch(
			showMessage({
				type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
				content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
				onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && Util.navigateTo(_history, WebUrl._URL_MANAGE_THIRD_PARTY_API),
			})
		);
	}

	return (
		<div id="page-content">
			<form onSubmit={handleSubmit(onSubmit)}>
				<h1 className="page-header">
					{selectedThirdPartyApi ? t("EDIT_THIRD_PARTY_API_DETAIL") : t("CREATE_THIRD_PARTY_API")}
					<NavigationButton history={_history} />
				</h1>
				<Panel>
					<PanelHeader noButton>{t("THIRD_PARTY_API_DETAIL")}</PanelHeader>
					<PanelBody>
						<div className="row">
							<div className="col-lg-3">
								<div className="form-group">
									<label>{t("THIRD_PARTY")}</label>
									<select
										className="form-control form-control-lg"
										id="thirdPartyProgramId"
										name="thirdPartyProgramId"
										ref={register({ required: "INPUT_FIELD_REQUIRED" })}>
										{
											thirdPartyList.map(thirdParty => {
												return (
													<option value={thirdParty.id.toString()}>{thirdParty.name} ({thirdParty.code})</option>
												)
											})
										}
									</select>
									{errors["thirdPartyProgramId"] && <div className="invalid-feedback">{t(Util.getValidationMessage(errors["thirdPartyProgramId"]))}</div>}
								</div>
							</div>
							<div className="col-lg-3">
								<label className="w-100">{t("START_DATE")}</label>
								<DateTime
									value={startDate}
									dateFormat="YYYY-MM-DD"
									closeOnSelect={true}
									timeFormat="HH:mm:ss"
									inputProps={{
										readOnly: true,
										className: 'form-control form-control-lg',
										style: { backgroundColor: 'transparent', cursor: 'pointer' },
									}}
									isValidDate={(e) => {
										return e.isBefore(moment(endDate).add(1, "day").format("YYYY-MM-DD HH:mm:ss"));
									}}
									onChange={(e) => {
										if (moment(e).diff(moment(endDate), 'seconds') > 0) {
											setEndDate(moment(e).format("YYYY-MM-DD HH:mm:ss"));
										}
										setStartDate(moment(e).format("YYYY-MM-DD HH:mm:ss"))
									}} />
							</div>
							<div className="col-lg-3">
								<label className="w-100">{t("END_DATE")}</label>
								<DateTime
									value={endDate}
									dateFormat="YYYY-MM-DD"
									timeFormat="HH:mm:ss"
									closeOnSelect={true}
									inputProps={{
										readOnly: true,
										className: 'form-control form-control-lg',
										style: { backgroundColor: 'transparent', cursor: 'pointer' },
									}}
									isValidDate={(e) => {
										return e.isAfter(moment(startDate).subtract(1, "day").format("YYYY-MM-DD HH:mm:ss"));
									}}
									onChange={(e) => {
										if (moment(e).diff(moment(startDate), 'seconds') < 0) {
											setStartDate(moment(e).format("YYYY-MM-DD HH:mm:ss"));
										}
										setEndDate(moment(e).format("YYYY-MM-DD HH:mm:ss"))
									}} />
							</div>
							<div className="col-lg-3">
								<div className="form-group">
									<label>Name</label>
									<input className="form-control form-control-lg" type="text" id="name" name="name" defaultValue={selectedThirdPartyApi?.name ?? ""} placeholder="Enter Name" ref={register({ required: "INPUT_FIELD_REQUIRED" })} />
									{errors["name"] && <div className="invalid-feedback">{t(errors["name"].message)}</div>}
								</div>
							</div>
							<div className="col-lg-3">
								<div className="form-group">
									<label>Event Key</label>
									<input className="form-control form-control-lg" type="text" id="eventKey" name="eventKey" defaultValue={selectedThirdPartyApi?.eventKey ?? ""} placeholder="Enter Event Key" ref={register({ required: "INPUT_FIELD_REQUIRED" })} />
									{errors["eventKey"] && <div className="invalid-feedback">{t(errors["eventKey"].message)}</div>}
								</div>
							</div>
							<div className="col-lg-3">
								<div className="form-group">
									<label>API Key</label>
									<input className="form-control form-control-lg" type="text" id="apiKey" name="apiKey" defaultValue={selectedThirdPartyApi?.apiKey ?? ""} placeholder="Enter API Key" ref={register({ required: "INPUT_FIELD_REQUIRED" })} />
									{errors["apiKey"] && <div className="invalid-feedback">{t(errors["apiKey"].message)}</div>}
								</div>
							</div>
							<div className="col-lg-3">
								<div className="form-group">
									<label>API Secret</label>
									<input className="form-control form-control-lg" type="text" id="apiSecret" name="apiSecret" defaultValue={selectedThirdPartyApi?.apiSecret ?? ""} placeholder="Enter API Secret" ref={register({ required: "INPUT_FIELD_REQUIRED" })} />
									{errors["apiSecret"] && <div className="invalid-feedback">{t(errors["apiSecret"].message)}</div>}
								</div>
							</div>
							<div className="col-lg-3">
								<div className="form-group">
									<label>Client API Key</label>
									<input className="form-control form-control-lg" type="text" id="clientKey" name="clientKey" defaultValue={selectedThirdPartyApi?.clientKey ?? ""} placeholder="Enter Client API Key" ref={register} />
								</div>
							</div>
							<div className="col-lg-3">
								<div className="form-group">
									<label>Client API Secret</label>
									<input className="form-control form-control-lg" type="text" id="clientSecret" name="clientSecret" defaultValue={selectedThirdPartyApi?.clientSecret ?? ""} placeholder="Enter Client API Secret" ref={register} />
								</div>
							</div>
							<div className="col-lg-3">
								<div className="form-group">
									<label>{t("STATUS")}</label>
									<select className="form-control form-control-lg" id="status" name="status" ref={register()}>
										<option value={1}>{t("ACTIVE")}</option>
										<option value={0}>{t("DISABLE")}</option>
									</select>
								</div>
							</div>
						</div>
					</PanelBody>
					<hr className="my-0" />
					<PanelBody>
						<button type="submit" className="btn btn-primary">
							{selectedThirdPartyApi ? t("BTN_SAVE") : t("CREATE")}
						</button>
					</PanelBody>
				</Panel>
			</form>
		</div>
	);
};

export default ThirdPartyApiDetail;
