import React, { useState, useEffect } from 'react';
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import { ApiKey, ApiUrl, Role, AlertTypes, Status } from '../../util/Constant';
import { useDispatch } from 'react-redux';
import { showMessage, showResponseMessage } from 'redux/AppAction.js';
import { useForm } from "react-hook-form";
import { createMultiPartFormBody, stringIsNullOrEmpty, navigateTo } from "../../util/Util";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from 'react-dropzone';
import Select from 'react-select';

/// <summary>
/// Author: Wind
/// </summary>
const PrizeModal = props => {
    const { t } = useTranslation();
    var _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue } = useForm();
    const [modalVisibility, setModalVisibility] = useState(false);
    const [rowData, setRowData] = useState(null);
    const [prizeLogoFile, setPrizeLogoFile] = useState();
    const [prizeLogoImage, setPrizeLogoImage] = useState('');
    const [prizeTypeOption, setPrizeTypeOption] = useState([]);
    const [prizeTypeId, setPrizeTypeId] = useState([]);

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        setModalVisibility(props["visible"]);
        setRowData(props.rowData);
        setPrizeTypeId(props.rowData && props.rowData.prizeTypeId);
        setPrizeLogoImage(props.rowData && props.rowData.logo);
        getEventPrizeType();
    }, [props["visible"]]);

    /// <summary>
    /// Author: Wind
    /// </summary> 
    async function getEventPrizeType() {
        let typeResponseJson = await ApiEngine.get(ApiUrl._API_EVENT_PRIZE_TYPE);
        if (typeResponseJson[ApiKey._API_SUCCESS_KEY]) {
            let tempCategory = [];
            typeResponseJson[ApiKey._API_DATA_KEY].map((type, index) => {
                tempCategory.push({ label: type.name, value: type.id });
            })
            setPrizeTypeOption(tempCategory);

            if (!props.rowData){
                setPrizeTypeId(tempCategory[0].value);
            }
        }
    }

    /// <summary>
    /// Author: Wind
    /// </summary> 
    function cleanUp(refreshRequired) {
        props.onFinish(refreshRequired);
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    const onSubmit = async (data) => {
        var responseJson;
        let params = {
            eventId: props.eventId,
            name: data.name,
            prizeName: data.prizeName,
            amount: data.worth,
            quantity: data.quantity,
            rank: data.rank,
            prizeTypeId: prizeTypeId,
            luckyPrize: props.isLuckyPrize 
        };

        if (!stringIsNullOrEmpty(prizeLogoFile)) {
            params["logo"] = prizeLogoFile;
        }

        if (rowData) {
            responseJson = await ApiEngine.put(ApiUrl._API_EVENT_PRIZE + '/' + props.rowData.id, createMultiPartFormBody(params));
        }
        else {
            responseJson = await ApiEngine.post(ApiUrl._API_EVENT_PRIZE, createMultiPartFormBody(params));
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    cleanUp(responseJson[ApiKey._API_SUCCESS_KEY]);
                }
            }
        }));
    };

    /// <summary>
    /// Author : Wind
    /// </summary> 
    const imageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setPrizeLogoFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPrizeLogoImage(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }

    return (
        <Modal isOpen={modalVisibility} centered toggle={() => { cleanUp(false) }} size="lg">
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={() => { cleanUp(false) }}>{rowData ? t("EDIT_PRIZE") : t("ADD_PRIZE")}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("NAME")}</label>
                                <input
                                    id="name"
                                    name="name"
                                    ref={register({ required: 'PLEASE_ENTER_PRIZE_NAME' })}
                                    className="form-control form-control-lg"
                                    placeholder={t("PLEASE_ENTER_NAME")}
                                    defaultValue={rowData?.name ?? ''} />
                                {errors.name && <div className="invalid-feedback">{t(errors.name.message)}</div>}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("PRIZE_NAME")}</label>
                                <input
                                    id="prizeName"
                                    name="prizeName"
                                    ref={register({ required: 'PLEASE_ENTER_PRIZE_NAME' })}
                                    className="form-control form-control-lg"
                                    placeholder={t("PLEASE_ENTER_PRIZE_NAME")}
                                    defaultValue={rowData?.prizeName ?? ''} />
                                {errors.prizeName && <div className="invalid-feedback">{t(errors.prizeName.message)}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("PRIZE_TYPE")}</label>
                                <Select
                                    name="prizeTypeId"
                                    options={prizeTypeOption}
                                    value={prizeTypeOption.filter(option => option.value == prizeTypeId)}
                                    onChange={(e) => {
                                        setPrizeTypeId(e.value);
                                        setValue('prizeTypeId', e.value);
                                    }} />
                                {errors.notificationTypeId && <div className="invalid-feedback">{t(errors.notificationTypeId.message)}</div>}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("RANK")}</label>
                                <input
                                    id="rank"
                                    name="rank"
                                    ref={register({
                                        required: t('PLEASE_ENTER_RANK'),
                                        min: {
                                            value: 0,
                                            message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0')
                                        }
                                    })}
                                    className="form-control form-control-lg"
                                    placeholder={t("PLEASE_ENTER_RANK")}
                                    defaultValue={rowData?.rank ?? ''} />
                                {errors.rank && <div className="invalid-feedback">{t(errors.rank.message)}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("WORTH_AMOUNT")}</label>
                                <input
                                    id="worth"
                                    name="worth"
                                    type="number"
                                    step=".01"
                                    ref={register({
                                        required: t('PLEASE_ENTER_PRIZE_WORTH'),
                                        min: {
                                            value: 0,
                                            message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0')
                                        }
                                    })}
                                    className="form-control form-control-lg"
                                    placeholder={t("PLEASE_ENTER_PRIZE_WORTH")}
                                    defaultValue={rowData?.amount ?? ''} />
                                {errors.worth && <div className="invalid-feedback">{t(errors.worth.message)}</div>}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("QUANTITY")}</label>
                                <input
                                    id="quantity"
                                    name="quantity"
                                    ref={register({
                                        required: t('PLEASE_ENTER_QUANTITY'),
                                        min: {
                                            value: 0,
                                            message: t('PLEASE_ENSURE_VALUE_IS_NOT_LESSER_THAN_0')
                                        }
                                    })}
                                    className="form-control form-control-lg"
                                    placeholder={t("PLEASE_ENTER_QUANTITY")}
                                    defaultValue={rowData?.quantity ?? ''} />
                                {errors.quantity && <div className="invalid-feedback">{t(errors.quantity.message)}</div>}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>{t("PRIZE_IMAGE")}</label>
                                <Dropzone accept={'image/*'} onDrop={acceptedFiles => imageDrop(acceptedFiles)}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                {stringIsNullOrEmpty(prizeLogoImage) && <h4 style={{ color: "grey" }} >{t("UPLOAD_PRIZE_IMAGE")}</h4>}
                                                {!stringIsNullOrEmpty(prizeLogoImage) && <aside className="thumbsContainer">
                                                    <div className="thumb">
                                                        <div className="thumbInner">
                                                            <img
                                                                src={prizeLogoImage}
                                                                className="dropzone-img"
                                                            />
                                                        </div>
                                                    </div>
                                                </aside>}
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                                <button type="button" className="mt-2 btn btn-danger"
                                    onClick={() => {
                                        setPrizeLogoImage("");
                                        setPrizeLogoFile();
                                    }}>
                                    {t("REMOVE_IMAGE")}
                                </button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-primary btn-action">{t("BTN_SUBMIT")}</button>
                    <button type="button" className="btn btn-danger" onClick={() => { cleanUp(false); }}>{t('BTN_CANCEL')}</button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default PrizeModal;