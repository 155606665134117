import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useHistory, Link } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { navigateTo, createMultiPartFormBody, isObjectEmpty, stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../../util/Util";
import { ApiKey, ApiUrl, WebUrl, AccessRight, FilterStatus, Role, Status, AlertTypes } from "../../../util/Constant";
import NavigationButton from '../../../components/custom/NavigationButton';
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ApiEngine from '../../../util/ApiEngine.js';
import ResetPasswordModal from '../../../components/custom/ResetPasswordModal';
import CopyAccountDetailModal from '../../../components/custom/CopyAccountDetailModal';
import SetManagerLimitModal from '../../../components/custom/SetManagerLimitModal';
import SetManagerUpperLimitModal from '../../../components/custom/SetManagerUpperLimitModal';
import { showResponseMessage, showMessage } from 'redux/AppAction';

/// <summary>
/// Author : Andrew
/// </summary>
const ManageManager = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    let _dispatch = useDispatch();
    const { register, handleSubmit, watch, setValue } = useForm();
    const [regionOptions, setRegionOptions] = useState([]);
    const [roleOption, setRoleOption] = useState([]);
    const [targetRoleId, setTargetRoleId] = useState('');
    const [userId, setUserId] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [limitModal, setLimitModal] = useState({});
    const [upperLimitModal, setUpperLimitModal] = useState({});
   
    const [managerApiUrl, setManagerApiUrl] = useState('');
    var [count, setCount] = useState(0);
    const [selectedManagerId, setSelectedManagerId] = useState('');
    const [modalVisibility, setModalVisibility] = useState(false);
    const [promotionModalVisibility, setPromotionModalVisibility] = useState(false);
    const _userData = useSelector(state => state.authState.userData);

    /// <summary>
    /// Author : Andrew
    /// </summary>
    let _tableColumns = useMemo(() => [
        {
            Header: "FULLNAME",
            accessor: "userFullName",
            disableSortBy: true
        },
        {
            Header: "USERNAME",
            accessor: "username",
            disableSortBy: true
        },
        {
            Header: "REGION",
            accessor: "regionName",
            disableSortBy: true
        },
        {
            Header: "CREDIT_LIMIT",
            Cell: ({ row }) => {
                let data = row.original.managerProfile;
                return (
                    checkIfPermissionExist(AccessRight._MANAGER_LIMIT_CONTROL_PERMISSION, true) ?
                        <>
                            <span className="d-flex">
                                <div className="clickable-link" onClick={() => {
                                    setLimitModal(row.original);
                                }}>
                                    {numberWithCurrencyFormat(data.creditLimit)}
                                </div>
                                <>
                                    <span className="mx-2 clickable-link">/</span>
                                    <div className="clickable-link" onClick={() => {
                                        setUpperLimitModal(row.original);
                                    }}>
                                        {numberWithCurrencyFormat(data.limitThreshold)}
                                    </div>
                                </>
                                &nbsp;&nbsp;
                                {
                                    data.creditLimit != data.limitThreshold &&
                                    <button type="button" title={t("RESET")} className="btn btn-warning btn-icon btn-circle btn-sm" onClick={() => {
                                        _dispatch(showMessage({
                                            type: AlertTypes._WARNING,
                                            title: t("ARE_YOU_SURE"),
                                            content: t("ARE_YOU_SURE_YOU_WANT_RESET_CREDIT_LIMIT_TO_UPPER_CREDIT_LIMIT"),
                                            showCancel: true,
                                            onConfirm: () => resetCreditLimit(row.original.id)
                                        }));
                                    }}>
                                        <i className="fas fa-undo f-s-10"></i>
                                    </button>
                                }
                            </span >
                        </> :
                        <span class="badge badge-secondary badge-default">{`${data.creditLimit} / ${data.limitThreshold}`}</span>
                )
            },
            disableSortBy: true
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                return <span>{row.original.status == 1 ? <span class="badge badge-secondary badge-green">{t("ACTIVE")}</span> : <span class="badge badge-secondary badge-danger">{t("SUSPENDED") + " (" + row.original.invalidLoginCount + ")"}</span>}</span>
            },
            disableSortBy: true
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._MANAGER_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                navigateTo(_history, WebUrl._URL_ADMIN_MANAGER_DETAIL, row.original);
                            }}><span>{t("EDIT")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                setUserId(row.original.id);
                                setIsModalVisible(true);
                            }}><span>{t("RESET_PASSWORD")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                setSelectedManagerId(row.original.id);
                                setModalVisibility(true);
                            }}><span>{t("COPY")}</span>
                            </DropdownItem>
                            {_userData.isAdminLevel && checkIfPermissionExist(AccessRight._ADMIN_SUB_ACCOUNT_CONTROL_PERMISSION, true) && roleOption.length != 0 &&
                                <DropdownItem onClick={() => {
                                    setUserId(row.original.id);
                                    setPromotionModalVisibility(true);
                                }}><span>{t("PROMOTE_USER")}</span></DropdownItem>
                            }
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t("ARE_YOU_SURE"),
                                    content: row.original.status == Status._ENABLED ? t("ARE_YOU_SURE_YOU_WANT_TO_BLOCK_THIS_USER") : t("ARE_YOU_SURE_YOU_WANT_TO_UNBLOCK_THIS_USER"),
                                    showCancel: true,
                                    onConfirm: () => userStatusHandler(row.original.id, row.original.status == Status._ENABLED ? Status._DISABLED : Status._ENABLED)
                                }));
                            }}><span className="text-warning">{row.original.status == Status._ENABLED ? t("BLOCK") : t("UNBLOCK")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t("ARE_YOU_SURE"),
                                    content: t(row.original.isAbleViewLastMessage ? "CONFIRM_TO_BLOCK_VIEW_LAST_MESSAGE" : "CONFIRM_TO_ENABLE_VIEW_LAST_MESSAGE"),
                                    showCancel: true,
                                    onConfirm: async () => await viewLastMessageSetup(row.original.id, !row.original.isAbleViewLastMessage)
                                }));
                            }}>
                                <span className="text-warning">{t(row.original.isAbleViewLastMessage ? "BLOCK_TO_VIEW_LAST_MESSAGE" : "ENABLE_TO_VIEW_LAST_MESSAGE")}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t("ARE_YOU_SURE"),
                                    content: t("ARE_YOU_SURE_YOU_WANT_DELETE_THIS_MANAGER"),
                                    showCancel: true,
                                    onConfirm: () => userStatusHandler(row.original.id, Status._DELETED)
                                }));
                            }}><span className="text-red">{t("DELETE")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                const win = window.open(WebUrl._URL_VIEW_USER_ACTION_LOG + "?username=" + row.original.username
                                    , "_blank");
                                win.focus();
                            }}>
                                <span>{t('VIEW_USER_ACTION_LOG')}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible", width: '1%' }
        }
    ], [_userData, roleOption, managerApiUrl]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function saveSession() {
        var data = watch();
        sessionStorage.setItem("managerFullname", data.fullName);
        sessionStorage.setItem("managerUsername", data.username);
        sessionStorage.setItem("managerRegionId", data.regionId);
        sessionStorage.setItem("managerStatus", data.status);
    }

    /// <summary>
    /// Author : Andrew
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : Wind
    /// </summary>
    useEffect(() => {
        if (!isObjectEmpty(limitModal)) {
            refreshLimit();
        }
    }, [limitModal]);

    /// <summary>
    /// Author : Wind
    /// </summary>
    async function refreshLimit() {
        try {
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_ACCOUNT + "?id=" + limitModal.id);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setValue('currentCreditLimit', responseJson[ApiKey._API_DATA_KEY]['managerProfile']['creditLimit']);
            setValue('currentLimitThreshold', responseJson[ApiKey._API_DATA_KEY]['managerProfile']['limitThreshold']);
        }
        catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    async function resetCreditLimit(userId) {
        try {
            let params = {
                userId: userId,
                reset: true
            }
            var responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_MANAGER_LIMIT, params);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            handleSubmit(filterManagers)();
        }
        catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        await getRoleData();

        var responseJson = await ApiEngine.get(ApiUrl._API_GET_REGION);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var regionList = [];
            regionList.push({ label: t("ALL"), value: "" });
            responseJson[ApiKey._API_DATA_KEY].forEach(function (region) {
                regionList.push({ label: region.name, value: region.id });
            });

            setRegionOptions(regionList);
        }

        setValue("regionId", sessionStorage.getItem("managerRegionId"));
        setValue("status", sessionStorage.getItem("managerStatus"));
        handleSubmit(filterManagers)();
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>      
    async function getRoleData() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS_ROLE);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setRoleOption(responseJson[ApiKey._API_DATA_KEY]);
            if (responseJson[ApiKey._API_DATA_KEY].length > 0) {
                setTargetRoleId(responseJson[ApiKey._API_DATA_KEY][0].id);
            }
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>      
    async function promoteUser() {
        let params = {
            id: userId,
            roleId: targetRoleId,
            isSubAccount: true
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_USER_ACCOUNT, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setPromotionModalVisibility(false);
            init();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author : Andrew
    /// </summary>      
    async function fetchManagers(data) {
        var rawUrl = ApiUrl._API_GET_USERS + "?RoleId=" + Role._MANAGER + "&v=" + (++count);
        setCount(count);

        if (data) {
            rawUrl += "&FullName=" + data.fullName + "&RegionId=" + data.regionId + "&Username=" + data.username + "&Status=" + data.status;
        }

        setManagerApiUrl(rawUrl);
    }

    /// <summary>
    /// Author : Andrew
    /// </summary>
    async function filterManagers(data, e) {
        saveSession();
        fetchManagers(data);
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function userStatusHandler(userId, status) {
        let params = {
            id: userId,
            status: status,
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_USER_ACCOUNT, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleSubmit(filterManagers());
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>      
    async function viewLastMessageSetup(id, isAbleViewLastMsg) {
        var responseJson = await ApiEngine.post(`${ApiUrl._API_CREATE_OR_UPDATE_USER_ACCOUNT}`, createMultiPartFormBody({ id: id, isAbleViewLastMessage: isAbleViewLastMsg }));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleSubmit(filterManagers)();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
    }

    return (
        <div>
            <CopyAccountDetailModal
                refId={selectedManagerId}
                refRole={Role._MANAGER}
                onFinish={(refreshRequired) => {
                    if (refreshRequired) {
                        handleSubmit(filterManagers());
                    }
                    setModalVisibility(false);

                }}
                visible={modalVisibility} />
            <Modal isOpen={promotionModalVisibility} centered toggle={() => { setPromotionModalVisibility(false) }}>
                <ModalHeader toggle={() => { setPromotionModalVisibility(false) }}>{t("USER_PROMOTION")}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label>{t("NAME")}</label>
                                <select
                                    className="form-control form-control-lg"
                                    id="promoteTargetRole"
                                    name="promoteTargetRole"
                                    value={targetRoleId}
                                    onChange={(e) => setTargetRoleId(e.target.value)}>
                                    {
                                        roleOption.map(role => {
                                            return (
                                                <option value={role.id}>{role.roleName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary btn-action" onClick={() =>
                        _dispatch(showMessage({
                            type: AlertTypes._WARNING,
                            title: t("ARE_YOU_SURE"),
                            content: t("ARE_YOU_SURE_YOU_WANT_TO_PROMO_THIS_MANAGER"),
                            showCancel: true,
                            onConfirm: () => {
                                promoteUser()
                            }
                        }))
                    }>{t("BTN_SUBMIT")}</button>
                </ModalFooter>
            </Modal>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_MANAGER")}</h1>
                {
                    checkIfPermissionExist(AccessRight._MANAGER_CONTROL_PERMISSION, true) &&
                    <NavigationButton history={_history} url={WebUrl._URL_ADMIN_MANAGER_DETAIL} buttonText={t("ADD_NEW_MANAGER")} />
                }
            </div>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(filterManagers)}>
                        <div className="row m-b-10">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("FULLNAME")}</label>
                                    <input type="text" name="fullName" ref={register} defaultValue={sessionStorage.getItem("managerFullname")}
                                        className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("USERNAME")}</label>
                                    <input type="text" name="username" ref={register} defaultValue={sessionStorage.getItem("managerUsername")}
                                        className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("REGION")}</label>
                                    <select name="regionId" ref={register}
                                        className="form-control form-control-lg">
                                        {regionOptions.map((region) => <option value={region.value}>{region.label}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("STATUS")}</label>
                                    <select name="status" ref={register}
                                        class="form-control form-control-lg">
                                        <option value="">{t('ALL')}</option>
                                        <option value={FilterStatus._ACTIVE}>{t('ACTIVE')}</option>
                                        <option value={FilterStatus._SUSPENDED}>{t('SUSPENDED')}</option>
                                        <option value={FilterStatus._DELETED}>{t('DELETED')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group form-button">
                                    <button type="submit" class="btn btn-primary">{t('APPLY_FILTER')}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel className="panel-react-table">
                <PanelBody>
                    <ReactTable fetchUrl={managerApiUrl} columns={_tableColumns} />
                </PanelBody>
            </Panel>
            <ResetPasswordModal
                onFinish={() => {
                    setIsModalVisible(false);
                }}
                targetUserId={userId}
                visible={isModalVisible}
            />
            <SetManagerLimitModal
                onFinish={(isSuccess) => {
                    if (isSuccess) {
                        handleSubmit(filterManagers)();
                    }

                    setLimitModal({});
                }}
                managerLimitDetails={limitModal}
                visible={!isObjectEmpty(limitModal)}
            />
             <SetManagerUpperLimitModal
                onFinish={(isSuccess) => {
                    if (isSuccess) {
                        handleSubmit(filterManagers)();
                    }

                    setUpperLimitModal({});
                }}
                managerLimitDetails={upperLimitModal}
                visible={!isObjectEmpty(upperLimitModal)}
            />
        </div>
    )
}

export default ManageManager;