import React from 'react';
import { Panel, PanelBody } from '../../components/panel/panel';
import { ApiKey, WebUrl, ApiUrl, AlertTypes, Theme } from '../../util/Constant';
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { showMessage } from 'redux/AppAction';


/// <summary>
/// Author: CK
/// </summary>
const Search = props => {
    let _history = useHistory();
    let _dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, errors } = useForm();

    /// <summary>
    /// Author: Andrew
    /// </summary>
    async function searchMember(data) {
        var responseJson = await ApiEngine.get(ApiUrl._API_SEARCH_MEMBER + "?searchInput=" + data['searchMemberInput']);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _history.push({
                pathname: WebUrl._URL_SHOP_MEMBER_DETAILS,
                state: responseJson[ApiKey._API_DATA_KEY]
            });
        }
        else {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(responseJson[ApiKey._API_MESSAGE_KEY])
            }));
        }
    }

    return (
        <div id="page-content">
            <div className="offset-lg-3 offset-xl-4 col-xl-4 col-lg-6 col-md-12 ui-sortable">
                <Panel>
                    <PanelBody>
                        <h4>{t('SEARCH')}</h4>
                        <hr />
                        <form onSubmit={handleSubmit(searchMember)}>
                            <div className="form-group m-b-15 ">
                                <label>{`${t('RELOAD')} ${t('MEMBER_PHONE_NUMBER')}`}</label>
                                <input autoFocus="on" className="input-search form-control form-control-lg m-b-5" name="searchMemberInput" ref={register} placeholder={t('ENTER_MEMBER_PHONE_NUMBER')} type="text" />
                            </div>
                            <div className="pull-right">
                                <button type="submit" id="btn_search" className="btn btn-primary btn-action">{t('SEARCH')}</button>
                            </div>
                        </form>
                    </PanelBody>
                </Panel>
            </div>
            <div className="mt-4">
                <div className="logo-section">
                    <label>{t('POWERED_BY_EPAY', { name: process.env.REACT_APP_NAME })}</label>
                    <div className="logo-row">
                        <img src={require("../../assets/img/logo/epay-ghl.png")} />
                    </div>
                </div>
                <div className="logo-section">
                    <label>{t('MOBILE_RELOAD')}</label>
                    <div className="logo-row">
                        <img src={require("../../assets/img/logo/hotlink.png")} />
                        <img src={require("../../assets/img/logo/digi.png")} />
                        <img src={require("../../assets/img/logo/xpax.png")} />
                        <img src={require("../../assets/img/logo/first.png")} />
                        <img src={require("../../assets/img/logo/tunetalk.png")} />
                        <img src={require("../../assets/img/logo/umobile.png")} />
                        <img src={require("../../assets/img/logo/yes.png")} />
                        <img src={require("../../assets/img/logo/xox.png")} />
                        <img src={require("../../assets/img/logo/tron.png")} />
                        <img src={require("../../assets/img/logo/merchatrade.png")} />
                        <img src={require("../../assets/img/logo/buzzme.png")} />
                        <img src={require("../../assets/img/logo/itel.png")} />
                        <img src={require("../../assets/img/logo/kartuloveas.png")} />
                        <img src={require("../../assets/img/logo/artel.png")} />
                        <img src={require("../../assets/img/logo/speakout.png")} />
                        <img src={require("../../assets/img/logo/friendi.png")} />
                        <img src={require("../../assets/img/logo/xiddig.png")} />
                        <img src={require("../../assets/img/logo/ysmobile.png")} />
                    </div>
                </div>
                <div className="logo-section">
                    <label>{t('PAYMENT_COLLECTION')}</label>
                    <div className="logo-row">
                        <img src={require("../../assets/img/logo/astro.png")} />
                        <img src={require("../../assets/img/logo/yes.png")} />
                        <img src={require("../../assets/img/logo/molpay.png")} />
                        <img src={require("../../assets/img/logo/tm.png")} />
                        <img src={require("../../assets/img/logo/streamyx.png")} />
                        <img src={require("../../assets/img/logo/unifi.png")} />
                        <img src={require("../../assets/img/logo/redone.png")} />
                    </div>
                </div>
                <div className="logo-section">
                    <label>{t('ONLINE_GAME_RELOAD')}</label>
                    <div className="logo-row">
                        <img src={require("../../assets/img/logo/cubits.png")} />
                        <img src={require("../../assets/img/logo/multigamecard.png")} />
                        <img src={require("../../assets/img/logo/cherrycredits.png")} />
                        <img src={require("../../assets/img/logo/molpoints.png")} />
                        <img src={require("../../assets/img/logo/garena.png")} />
                        <img src={require("../../assets/img/logo/offgamers.png")} />
                        <img src={require("../../assets/img/logo/cibmall.png")} />
                        <img src={require("../../assets/img/logo/acash.png")} />
                        <img src={require("../../assets/img/logo/mycard.png")} />
                    </div>
                </div>
                <div className="logo-section">
                    <label>{t('EWALLET_RELOAD')}</label>
                    <div className="logo-row">
                        <img src={require("../../assets/img/logo/toucn-n-go.png")} />
                        <img src={require("../../assets/img/logo/mcash.png")} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Search;