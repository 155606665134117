import React, { useState, useEffect } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { useDispatch, useSelector } from "react-redux";
import { showResponseMessage } from "../../../redux/AppAction";
import ApiEngine from '../../../util/ApiEngine.js';
import { useTranslation } from 'react-i18next';
import { ApiKey, WebUrl, ApiUrl, AccessRight } from "../../../util/Constant";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';

/// <summary>
/// Author : YJ
/// </summary>
const ViewAnnouncement = props => {
    const { t } = useTranslation();
    const [messageData, setMessageData] = useState([]);
    const _userData = useSelector(state => state.authState.userData);
    const _dispatch = useDispatch();
    const [viewModal, setViewModal] = useState(false);
    const [viewData, setViewData] = useState([]);

    let _tableColumns = [
        {
            Header: t("TITLE"),
            accessor: "title"
        },
        {
            Header: t("CONTENT"),
            accessor: "content",
            style: {
                width: "150px",
                maxWidth: "150px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
            }
        },
        {
            Header: t("DATE"),
            accessor: "createdTime"
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => { setViewModal(!viewModal); setViewData(row.original) }}><span className="text-primary">{t("VIEW")}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ]

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        init();
    }, [])

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SYSTEM_MESSAGE + "?userId=" + _userData.userId);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setMessageData(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    return (
        <div>
            <h1 className="page-header">{t("MY_ANNOUNCEMENT")}</h1>
            <Panel>
                <PanelBody>
                    <ReactTable data={messageData} columns={_tableColumns} />
                </PanelBody>
            </Panel>

            <Modal isOpen={viewModal} centered size={"md"} toggle={() => { setViewModal(!viewModal) }}>
                <ModalHeader toggle={() => { setViewModal(!viewModal) }}>{viewData.title}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-lg-12">
                            <textarea class="form-control" rows="5"
                                name="content"
                                value={viewData.content}
                                disabled={true}
                                defaultValue={viewData.content}>
                            </textarea>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-danger" onClick={() => { setViewModal(!viewModal) }}>{t("CLOSE")}</button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ViewAnnouncement;