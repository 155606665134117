import React from 'react';
import { stringIsNullOrEmpty } from "../../util/Util";

/// <summary>
/// Author : Yong Sheng Chuan
/// Used to create the navigation button
/// It will form different button based on the existence of url
/// </summary>
const NavigationButton = (props) => {
    if (!stringIsNullOrEmpty(props.url)) {
        return (
            <button type="button"
                className={props.class ? props.class : "btn btn-primary"}
                style={props.style ? props.style : { float: "right" }}
                onClick={() => { props.history.push(props.url) }}
            >
                <i className={props.icon ? props.icon : "fa fa-plus-circle m-r-10"}></i>{props.buttonText}
            </button>
        );
    }
    else {
        return (
            <button type="button"
                className={props.class ? props.class : "btn btn-inverse"}
                style={props.style ? props.style : { float: "right" }}
                onClick={() => { props.history.goBack() }}
            >
                <i className={props.icon ? props.icon : "fas fa-long-arrow-alt-left"}></i>{props.buttonText}
            </button>
        );
    }
}

export default NavigationButton