import React, { useState, useEffect, useRef } from 'react';
import { Panel, PanelHeader, PanelBody, PanelFooter } from 'components/panel/panel.jsx';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { stringIsNullOrEmpty, createMultiPartFormBody, getValidationMessage, navigateTo, validatePhoneNumber } from "../../../util/Util";
import { ShopSettings, Status, Role, AlertTypes, ApiUrl, ApiKey, WebUrl, SystemSettingName } from 'util/Constant';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showResponseMessage, showMessage } from 'redux/AppAction';
import ApiEngine from '../../../util/ApiEngine.js';
import NavigationButton from '../../../components/custom/NavigationButton';
import Select from "react-select";
import { useLocation } from "react-router-dom";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReferralCodeModal from '../../../components/custom/ReferralCodeModal';

/// <summary>
/// Author: Yong Sheng Chuan
/// Edited: CK - Change member card ID reward reduction
/// </summary>
const MemberDetail = props => {
    var _history = useHistory();
    var _dispatch = useDispatch();
    var _location = useLocation();
    const { register, handleSubmit, errors, watch, unregister, setValue, triggerValidation } = useForm();
    const [regionOptions, setRegionOptions] = useState([]);
    const { t } = useTranslation();
    const [phone, setPhone] = useState('');
    const [regionId, setRegionId] = useState('');
    const [renderPhone, setRenderPhone] = useState(false);
    const _phoneFieldRef = useRef(null);

    const [shopOption, setShopOption] = useState([]);
    const [shopId, setShopId] = useState('');
    const [userId, setUserId] = useState('');
    const [referralCodeModalVisibility, setReferralCodeModalVisibility] = useState(false);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'regionId' }, { required: "PLEASE_SELECT_REGION" });
        register({ name: 'shopId' }, { required: "PLEASE_SELECT_SHOP" });
        register({ name: 'phone' }, { required: "PLEASE_ENTER_PHONE" });
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(regionId)) {
            getShopUser(regionId);
        }
    }, [regionId]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function getShopUser(regionId) {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP + "&RegionId=" + regionId);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const shopList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (shop) {
                shopList.push({ label: shop.username + " (" + shop.shopName + ")", value: shop.id });
            });

            setShopOption(shopList);
        }
    }
    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        if (_location.state) {
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_ACCOUNT + "?id=" + _location.state["id"]);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                if (!stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"])) {
                    setPhone(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]);
                    setValue("phone", responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]);
                }

                setUserId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                setRegionId(responseJson[ApiKey._API_DATA_KEY]["regionId"]);
                setValue('regionId', responseJson[ApiKey._API_DATA_KEY]["regionId"]);
                setShopId(responseJson[ApiKey._API_DATA_KEY]["memberProfile"]["ownerId"]);
                setValue('shopId', responseJson[ApiKey._API_DATA_KEY]["memberProfile"]["ownerId"]);
            }
        }

        setRenderPhone(true);

        var responseJson = await ApiEngine.get(ApiUrl._API_GET_REGION);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const regionList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (region) {
                regionList.push({ label: region.name, value: region.id });
            });

            setRegionOptions(regionList);
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    const onSubmit = async (data) => {
        let params = {
            username: data.phone.substr(1),
            userFullName: data.phone,
            roleId: Role._MEMBER,
            phoneNumber: data.phone,
            regionId: regionId,
            ownerId: data.shopId
        };

        if (!stringIsNullOrEmpty(userId)) {
            params["id"] = userId;
        }
        else {
            var randomPassword = Math.floor(100000 + Math.random() * 900000);
            params["status"] = Status._ENABLED;
            params["password"] = randomPassword;
            params["pin"] = randomPassword;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_USER_ACCOUNT, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_MEMBER)
        }));
    };

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const onChangePhoneNumber = (isValid, newNumber, countryData, fullNumber) => {
        setPhone(newNumber.toString().replace(/\s/g, '').replace(/\-/g, ''));
        let formattedNumber = fullNumber.toString().split(/[- ]+/).join('').replace("+", "");

        register({ name: "phone" }, {
            validate: (value) => validatePhoneNumber(formattedNumber, countryData.iso2, true) || "REQUIRED_VALID_PHONE_NUMBER"
        });
        setValue("phone", formattedNumber);
        triggerValidation("phone")
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    function getReferralShop(data) {
        setValue("regionId", data[ApiKey._API_DATA_KEY]["regionId"]);
        setRegionId(data[ApiKey._API_DATA_KEY]["regionId"]);
        setValue("shopId", data[ApiKey._API_DATA_KEY]["id"]);
        setShopId(data[ApiKey._API_DATA_KEY]["id"]);
    }

    return (
        <div>
            <h1 className="page-header">{_location.state ? t("EDIT_MEMBER") : t("CREATE_MEMBER")}<NavigationButton history={_history} /></h1>
            <Panel>
                <PanelHeader noButton>{t("MEMBER_DETAIL")}</PanelHeader>
                <PanelBody>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("PHONE")}</label>
                                {renderPhone && <IntlTelInput
                                    fieldName={"phone"}
                                    preferredCountries={['my']}
                                    onlyCountries={['my']}
                                    defaultCountry={"my"}
                                    style={{ display: "block", width: "100%" }}
                                    containerClassName="intl-tel-input"
                                    inputClassName="form-control"
                                    ref={_phoneFieldRef}
                                    onSelectFlag={(newNumber, countryData, fullNumber, ext) => {
                                        onChangePhoneNumber(_phoneFieldRef.current.isValidNumber(phone), newNumber, countryData, fullNumber);
                                    }}
                                    onPhoneNumberChange={(isValid, newNumber, countryData, fullNumber, ext) => {
                                        onChangePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                    }}
                                    onPhoneNumberBlur={(isValid, newNumber, countryData, fullNumber, ext) => {
                                        onChangePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                    }}
                                    customPlaceholder={(placeholder, countryData) => {
                                        return placeholder.split(/[- ]+/).join('');
                                    }}
                                    defaultValue={phone}
                                    value={phone}
                                    useMobileFullscreenDropdown={false}
                                    separateDialCode
                                />}
                                {errors.phone && <div className="invalid-feedback">{t(getValidationMessage(errors.phone))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("REGION")}</label>
                                <Select name="regionId" options={regionOptions}
                                    placeholder={(regionOptions.filter(option => option.value == regionId)[0] !== undefined) ? (regionOptions.filter(option => option.value == regionId)[0].label) : ""}
                                    value={regionOptions.filter(option => option.value == regionId)}
                                    onChange={(e) => {
                                        setValue("regionId", e.value);
                                        setRegionId(e.value);
                                    }} />
                                {errors.regionId && <div className="invalid-feedback">{t(getValidationMessage(errors.regionId))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("SHOP")}</label>
                                <Select name="shopId" options={shopOption}
                                    placeholder={(shopOption.filter(option => option.value == shopId)[0] !== undefined) ? (shopOption.filter(option => option.value == shopId)[0].label) : ""}
                                    value={shopOption.filter(option => option.value == shopId)}
                                    onChange={(e) => {
                                        setValue("shopId", e.value);
                                        setShopId(e.value);
                                    }} />
                                {errors.shopId && <div className="invalid-feedback">{t(getValidationMessage(errors.shopId))}</div>}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row">
                            <div className="col-lg-6">
                                {!_location.state &&
                                    <button type="button" className="btn btn-success"
                                        onClick={() => { setReferralCodeModalVisibility(!referralCodeModalVisibility); }}>
                                        {t('ENTER_REFERRAL_CODE')}</button>}
                                &nbsp; &nbsp;
                                <button type="button" className="btn btn-primary" onClick={() => {
                                    handleSubmit(onSubmit)();
                                }}> {t("BTN_SAVE")}</button>
                            </div>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <ReferralCodeModal
                onFinish={(data) => {
                    if (data != null) {
                        getReferralShop(data);
                    }
                    setReferralCodeModalVisibility(false);
                }}
                visible={referralCodeModalVisibility} />
        </div>
    )
}

export default MemberDetail;