import React, { useState, useMemo, useEffect } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import DateTime from 'react-datetime';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ApiKey, ApiUrl, AccessRight, WebUrl, Status, AlertTypes, BannerType } from '../../../util/Constant';
import { getValidationMessage } from '../../../util/Util';
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import moment from 'moment';
import NavigationButton from '../../../components/custom/NavigationButton';
import ApiEngine from '../../../util/ApiEngine.js';
import { showMessage } from "../../../redux/AppAction";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

/// <summary>
/// Author: Wind
/// </summary> 
const ManageBanner = props => {
    const { t } = useTranslation();
    var _dispatch = useDispatch();
    let _history = useHistory();
    const { setValue, errors } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));

    /// <summary>
    /// Author: Wind
    /// </summary>
    let _bannerColumns = [
        {
            Header: "No",
            id: "row",
            filterable: false,
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            disableSortBy: true,
            width: 50
        },
        {
            Header: "TYPE",
            accessor: "bannerType",
            disableSortBy: true,
            Cell: ({ value }) => {
                return Object.values(BannerType).find(bt => bt.key == value)?.value ?? "-";
            }
        },
        {
            Header: "BANNER",
            accessor: "image",
            disableSortBy: true,
            Cell: ({ row }) => {
                return row.original.image ? <img class="mini-program-logo" src={row.original.image} /> : <div></div>
            }
        },
        {
            Header: "START_DATE",
            accessor: "startDate",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{moment(row.original.startDate).format("YYYY-MM-DD HH:mm:ss")}</div>
            }
        },
        {
            Header: "END_DATE",
            accessor: "endDate",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{moment(row.original.endDate).format("YYYY-MM-DD HH:mm:ss")}</div>
            }
        },
        {
            Header: "TITLE",
            accessor: "title",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{row.original.titleLocalizations.map((item) => { return item.value }).join(" / ")}</div>
            }
        },
        {
            Header: "DESCRIPTION",
            accessor: "description",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{row.original.descriptionLocalizations.map((item) => { return item.value }).join(" / ")}</div>
            }
        },
        {
            Header: "SHOP",
            accessor: "bannerShops",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div style={{ lineHeight: 1 }}>{row.original.bannerShops?.map(v => v.shopUsername).join(", ")}</div>
            }
        },
        {
            Header: "STATUS",
            accessor: "status",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <span className={row.original.status == Status._ENABLED ? "badge badge-success" : "badge badge-danger"}
                    style={{ textTransform: "uppercase" }}>
                    {row.original.status == Status._ENABLED ? t('ACTIVE') : t('DISABLE')}
                </span>
            }
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._EVENT_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                _history.push({
                                    pathname: WebUrl._URL_ADMIN_MANAGE_BANNER_DETAIL,
                                    state: { id: row.original.id }
                                })
                            }}>
                                <span>{t('EDIT')}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t('ARE_YOU_SURE'),
                                    showCancel: true,
                                    content: t('ARE_YOU_SURE_YOU_WANT_TO') + t('DELETE') + " " + row.original.title + "?",
                                    onConfirm: () => { deleteBanner(row.original.id); }
                                }));
                            }}>
                                <span className="text-danger">{t('DELETE')}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ];

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        setApiUrl(ApiUrl._API_BANNER + '?StartDate=' + startDate + "&endDate=" + endDate);
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function deleteBanner(id) {
        var responseJson = await ApiEngine.delete(ApiUrl._API_BANNER + "/" + id);

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && applyFilter()
        }));
    }

    /// <summary>
    /// Author : Wind
    /// </summary> 
    async function applyFilter() {
        setApiUrl(ApiUrl._API_BANNER + '?StartDate=' + startDate + "&endDate=" + endDate +
            "&searchTerm=" + searchTerm + "&v=" + moment.now());
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable columns={_bannerColumns} fetchUrl={apiUrl} />, [apiUrl])

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_BANNER")}</h1>
                {
                    checkIfPermissionExist(AccessRight._BANNER_CONTROL_PERMISSION, true) &&
                    <div>
                        <NavigationButton history={_history} url={WebUrl._URL_ADMIN_MANAGE_BANNER_DETAIL} buttonText={t("ADD_BANNER")} />
                    </div>
                }
            </div>
            <Panel>
                <PanelBody>
                    <div className="row m-b-10">
                        <div className="col-lg-2">
                            <div className="form-group">
                                <label>{t("TITLE_OR_DESCRIPTION")}</label>
                                <input
                                    type="text"
                                    name="member"
                                    className="form-control form-control-lg"
                                    onInput={(e) => setSearchTerm(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="form-group">
                                <label>{t("START_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="startDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={startDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("startDate", "");
                                        }
                                    }} />
                                {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="form-group">
                                <label>{t("END_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="endDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={endDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                            setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("endDate", "");
                                        }
                                    }} />
                                {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <button type="button" className="btn btn-primary" onClick={() => { applyFilter() }}>{t('APPLY_FILTER')}</button>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            {PaginationTable}
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div>
    )
}

export default ManageBanner;