import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel';
import { WebUrl, ApiUrl, ApiKey, AlertTypes, PackageType } from '../../../util/Constant';
import * as Util from '../../../util/Util';
import { showMessage } from "../../../redux/AppAction";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import NavigationButton from '../../../components/custom/NavigationButton';
import LottoPayrateTable from '../../../components/custom/LottoPayrateTable';
import ApiEngine from '../../../util/ApiEngine.js';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';

/// <summary>
/// Author: CK
/// </summary>
const VendorApiDetail = props => {
    let _history = useHistory();
    let _location = useLocation();
    let _dispatch = useDispatch();
    const { register, handleSubmit, errors, reset, setValue, watch } = useForm();
    const { t, i18n } = useTranslation();
    const [selectedVendorApi, setSelectedVendorApi] = useState(null);
    const [vendorList, setVendorList] = useState([]);

    const [normalPackage, setNormalPackage] = useState([]);
    const [gdPackage, setGdPackage] = useState([]);

    const normalPayrateTable = useRef();
    const gdPayrateTable = useRef();

    const _TABS = {
        NORMAL: "NORMAL",
        GD: "GD"
    }

    const [activeTab, setActiveTab] = useState(_TABS.NORMAL);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        // Agent player and custom package can only enable one
        if (watch("agentPlayer")) {
            setValue('lottoPackage', false);
        }
    }, [watch("agentPlayer")]);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        // Agent player and custom package can only enable one
        if (watch("lottoPackage")) {
            setValue('agentPlayer', false);
        }
    }, [watch("lottoPackage")]);

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_LIST);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setVendorList(responseJson[ApiKey._API_DATA_KEY]);
        }

        if (_location.state && _location.state.id) {
            await getVendorApiDetails(_location.state.id);
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function getVendorApiDetails(id) {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_API_BY_ID + "?id=" + id);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let vendorApiDetails = responseJson[ApiKey._API_DATA_KEY];
            setSelectedVendorApi(vendorApiDetails);
            setValue('vendorId', vendorApiDetails.vendorId);
            setValue('isPrimary', vendorApiDetails.isPrimary);
            setValue('status', vendorApiDetails.status);
            setValue('agentPlayer', vendorApiDetails.agentPlayer);
            setValue('isBackup', vendorApiDetails.isBackup);
            setValue('priority', vendorApiDetails.priority);
            setValue('isSeamless', vendorApiDetails.isSeamless);
            setValue('lottoPackage', vendorApiDetails.lottoPackage);

            if (vendorApiDetails.agentPlayer || vendorApiDetails.lottoPackage) {
                getLottoPackages(id);
            }
        }
    }

    /// <summary>
    /// Author : A
    /// </summary>
    async function getLottoPackages(id) {
        await ApiEngine.get(ApiUrl._API_GET_VENDOR_LOTTO_PACKAGE_BY_API.replace("{id}", id))
            .then((response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    let lottoPackages = response[ApiKey._API_DATA_KEY];
                    setNormalPackage(lottoPackages.filter(p => p.typeId == PackageType._NORMAL));
                    setGdPackage(lottoPackages.filter(p => p.typeId == PackageType._GD));
                }
            })
    }

    /// <summary>
    /// Author: CK
    /// Edited: A
    /// </summary>
    async function onSubmit(data) {
        if (data['agentPlayer'] || data['lottoPackage']) {
            let normalPayRateData = await normalPayrateTable.current.getPayrate();
            let gdPayRateData = await gdPayrateTable.current.getPayrate();

            let packageData = JSON.stringify({
                normal: {
                    basePayrate: normalPayRateData.data.basePayrate,
                    customPayrate: normalPayRateData.data.customPayrate
                },
                gd: {
                    basePayrate: gdPayRateData.data.basePayrate
                }
            });

            data = {
                ...data,
                package: packageData
            };
        }

        if (selectedVendorApi) {
            data['id'] = selectedVendorApi.id;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_VENDOR_API, Util.createMultiPartFormBody(data));
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setSelectedVendorApi(responseJson[ApiKey._API_DATA_KEY]);
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && Util.navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_VENDOR_API)
        }));
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function generateCode() {
        setValue('seamlessOpCode', Util.generateRandomAlphanumeric(12));
        setValue('seamlessOpSecret', Util.generateRandomAlphanumeric(12));
    }

    return (
        <div id="page-content">
            <form onSubmit={handleSubmit(onSubmit)}>
                <h1 className="page-header">{selectedVendorApi ? t("EDIT_VENDOR_API_DETAILS") : t("CREATE_VENDOR_API")}
                    <NavigationButton history={_history} />
                </h1>
                <Panel>
                    <PanelHeader noButton>
                        {t("VENDOR_API_DETAILS")}
                    </PanelHeader>
                    <PanelBody>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("VENDOR")}</label>
                                    <select
                                        className="form-control form-control-lg"
                                        id="vendorId"
                                        name="vendorId"
                                        ref={register({ required: true })}>
                                        {
                                            vendorList.map(vendor => {
                                                return (
                                                    <option value={vendor.id.toString()}>{vendor.name} ({vendor.shortName})</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {errors["vendorId"] && <div className="invalid-feedback">{t(Util.getValidationMessage(errors["vendorId"]))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("ROOT_AGENT")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        id="rootAgent"
                                        name="rootAgent"
                                        defaultValue={selectedVendorApi ? selectedVendorApi.rootAgent : ""}
                                        placeholder={t("PLEASE_ENTER_ROOT_AGENT")}
                                        ref={register({ required: "PLEASE_ENTER_ROOT_AGENT" })}
                                    />
                                    {errors["rootAgent"] && <div className="invalid-feedback">{t(errors["rootAgent"].message)}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("KEY")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        id="signKey"
                                        name="signKey"
                                        defaultValue={selectedVendorApi ? selectedVendorApi.signKey : ""}
                                        placeholder={t("ENTER_KEY")}
                                        ref={register()} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("OPCODE")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        id="opcode"
                                        name="opcode"
                                        defaultValue={selectedVendorApi ? selectedVendorApi.opcode : ""}
                                        placeholder={t("ENTER_OPCODE")}
                                        ref={register()} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("OPSECRET")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        id="opSecret"
                                        name="opSecret"
                                        defaultValue={selectedVendorApi ? selectedVendorApi.opSecret : ""}
                                        placeholder={t("ENTER_OPSECRET")}
                                        ref={register()} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("TYPE")}</label>
                                    <select
                                        className="form-control form-control-lg"
                                        id="isPrimary"
                                        name="isPrimary"
                                        ref={register({ required: true })}>
                                        <option value={true}>{t("PRIMARY")}</option>
                                        <option value={false}>{t("SECONDARY")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("STATUS")}</label>
                                    <select
                                        className="form-control form-control-lg"
                                        id="status"
                                        name="status"
                                        ref={register({ required: true })}>
                                        <option value={true}>{t("ACTIVE")}</option>
                                        <option value={false}>{t("DISABLE")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("VENDOR_CODE")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        id="vendorCode"
                                        name="vendorCode"
                                        defaultValue={selectedVendorApi ? selectedVendorApi.vendorCode : ""}
                                        placeholder={t('ENTER_VENDOR_CODE')}
                                        ref={register}
                                    />
                                    {errors["vendorCode"] && <div className="invalid-feedback">{t(Util.getValidationMessage(errors["vendorCode"]))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("BASE_URL")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        id="baseUrl"
                                        name="baseUrl"
                                        defaultValue={selectedVendorApi ? selectedVendorApi.baseUrl : ""}
                                        placeholder={t("ENTER_BASE_URL")}
                                        ref={register}
                                    />
                                    {errors["baseUrl"] && <div className="invalid-feedback">{t(Util.getValidationMessage(errors["baseUrl"]))}</div>}
                                </div>
                                <div className='m-b-5' style={{ flex: 1 }}>
                                    <label>{t("BACKUP")}</label>
                                    <div className="switcher d-block d-flex">
                                        <input
                                            class="form-control form-control-lg"
                                            type="checkbox"
                                            id="isBackup"
                                            name="isBackup"
                                            value={true}
                                            ref={register} />
                                        <label htmlFor="isBackup"></label>
                                    </div>
                                </div>
                                <div className='m-b-5' style={{ flex: 1 }}>
                                    <label>{t("PRIORITY")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="number"
                                        id="priority"
                                        name="priority"
                                        defaultValue={selectedVendorApi ? selectedVendorApi.priority : ""}
                                        placeholder={t("PRIORITY")}
                                        ref={register({ required: true })}
                                    />
                                    {errors["priority"] && <div className="invalid-feedback">{t(Util.getValidationMessage(errors["priority"]))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <label>{t("ADDITIONAL_SETTING")}</label>
                                <textarea
                                    class="form-control form-control-lg"
                                    name="additionalSetting"
                                    defaultValue={selectedVendorApi ? selectedVendorApi.additionalSetting : ""}
                                    ref={register}
                                    style={{ resize: "none", minHeight: "200px" }}
                                    aria-label="With textarea">
                                </textarea>
                            </div>
                            <div className="col-lg-2">
                                <div className='m-b-5' style={{ flex: 1 }}>
                                    <label>{t("IS_SEAMLESS")}</label>
                                    <div className="switcher d-block d-flex">
                                        <input
                                            class="form-control form-control-lg"
                                            type="checkbox"
                                            id="isSeamless"
                                            name="isSeamless"
                                            value={true}
                                            ref={register} />
                                        <label htmlFor="isSeamless"></label>
                                        {
                                            watch('isSeamless') &&
                                            <button type="button" className="btn-success btn-sm p-5 m-l-10" onClick={() => { generateCode(); }}>{t("GENERATE_CODE")}</button>
                                        }
                                    </div>
                                </div>
                                {
                                    watch('isSeamless') &&
                                    <>
                                        <div className="form-group">
                                            <label>{t("SEAMLESS_OPCODE")}</label>
                                            <input
                                                className="form-control form-control-lg"
                                                type="text"
                                                id="seamlessOpCode"
                                                name="seamlessOpCode"
                                                defaultValue={selectedVendorApi ? selectedVendorApi.seamlessOpCode : ""}
                                                placeholder={t("ENTER_SEAMLESS_OPCODE")}
                                                ref={register({ required: watch('isSeamless') })}
                                            />
                                            {errors["seamlessOpCode"] && <div className="invalid-feedback">{t(Util.getValidationMessage(errors["seamlessOpCode"]))}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label>{t("SEAMLESS_OPSECRET")}</label>
                                            <input
                                                className="form-control form-control-lg"
                                                type="text"
                                                id="seamlessOpSecret"
                                                name="seamlessOpSecret"
                                                defaultValue={selectedVendorApi ? selectedVendorApi.seamlessOpSecret : ""}
                                                placeholder={t("ENTER_SEAMLESS_OPSECRET")}
                                                ref={register({ required: watch('isSeamless') })}
                                            />
                                            {errors["seamlessOpSecret"] && <div className="invalid-feedback">{t(Util.getValidationMessage(errors["seamlessOpSecret"]))}</div>}
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="col-lg-2">
                                <div className="d-flex">
                                    <div style={{ flex: 1 }}>
                                        <label>{t("AGENT_PLAYER")}</label>
                                        <div className="switcher d-block">
                                            <input
                                                class="form-control form-control-lg"
                                                type="checkbox"
                                                id="agentPlayer"
                                                name="agentPlayer"
                                                value={true}
                                                onChange={(e) => { (watch('agentPlayer') == "true" && selectedVendorApi) && getLottoPackages(selectedVendorApi.id) }}
                                                ref={register} />
                                            <label htmlFor="agentPlayer"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="d-flex">
                                    <div style={{ flex: 1 }}>
                                        <label>{t("LOTTO_PACKAGE")}</label>
                                        <div className="switcher d-block">
                                            <input
                                                class="form-control form-control-lg"
                                                type="checkbox"
                                                id="lottoPackage"
                                                name="lottoPackage"
                                                value={true}
                                                onChange={(e) => { (watch('lottoPackage') == "true" && selectedVendorApi) && getLottoPackages(selectedVendorApi.id) }}
                                                ref={register} />
                                            <label htmlFor="lottoPackage"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PanelBody>
                    {
                        (watch('agentPlayer') || watch('lottoPackage')) &&
                        <>
                            <PanelHeader noButton>
                                {t("LOTTO_PACKAGE_DETAILS")}
                            </PanelHeader>
                            <PanelBody>
                                <div>
                                    <Nav pills className="mb-2">
                                        {
                                            Object.values(_TABS).map(tab =>
                                                <NavItem key={`tab${tab}`}>
                                                    <NavLink
                                                        className={classNames({ active: activeTab == tab })}
                                                        onClick={() => setActiveTab(tab)}
                                                    >
                                                        {tab}
                                                    </NavLink>
                                                </NavItem>
                                            )
                                        }
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId={_TABS.NORMAL}>
                                            <LottoPayrateTable ref={normalPayrateTable} customPayRate package={normalPackage} />
                                        </TabPane>
                                        <TabPane tabId={_TABS.GD}>
                                            <LottoPayrateTable ref={gdPayrateTable} package={gdPackage} />
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </PanelBody>
                        </>
                    }
                    <hr className="my-0" />
                    <PanelBody>
                        <button type="submit" className="btn btn-primary">{selectedVendorApi ? t("BTN_SAVE") : t("CREATE")}</button>
                    </PanelBody>
                </Panel>
            </form>
        </div >
    );
}

export default VendorApiDetail;