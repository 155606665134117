import React, { useState, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import DateTime from 'react-datetime';
import { ApiUrl } from '../../../util/Constant';
import { getValidationMessage, numberWithCurrencyFormat } from '../../../util/Util';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import moment from 'moment';

/// <summary>
/// Author: Wind
/// </summary> 
const MemberEcreditTransferLog = props => {
    const { t } = useTranslation();
    const { setValue, errors } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));
    const [sender, setSender] = useState('');
    const [receiver, setRecevier] = useState('');

    /// <summary>
    /// Author: Wind
    /// </summary>
    let _eCreditLog = [
        {
            Header: "DATE",
            accessor: "updatedTime",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{moment(row.original.updatedTime).format("YYYY-MM-DD HH:mm:ss")}</div>
            }
        },
        {
            Header: "AMOUNT",
            accessor: "amount",
            Cell: ({ row }) => {
                return <span className="text-success">{numberWithCurrencyFormat(row.original.amount)}</span>;
            },
            disableSortBy: true
        },
        {
            Header: "PROCESSING_FEE",
            accessor: "processingFee",
            Cell: ({ row }) => {
                return <span className="text-success">{numberWithCurrencyFormat(row.original.processingFee)}</span>;
            },
            disableSortBy: true
        },
        {
            Header: "SENDER",
            accessor: "senderName",
            disableSortBy: true
        },
        {
            Header: "SENDER_PRE_CREDIT",
            accessor: "preSenderCredit",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{numberWithCurrencyFormat(row.original.preSenderCredit)}</div>;
            },
        },
        {
            Header: "SENDER_POST_CREDIT",
            accessor: "postSenderCredit",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{numberWithCurrencyFormat(row.original.postSenderCredit)}</div>;
            },
        },
        {
            Header: "RECEIVER",
            accessor: "receiverName",
            disableSortBy: true
        },
        {
            Header: "RECEIVER_PRE_CREDIT",
            accessor: "preReceiverCredit",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{numberWithCurrencyFormat(row.original.preReceiverCredit)}</div>;
            },
        },
        {
            Header: "RECEIVER_POST_CREDIT",
            accessor: "postReceiverCredit",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{numberWithCurrencyFormat(row.original.postReceiverCredit)}</div>;
            },
        },
    ];

    /// <summary>
    /// Author : Wind
    /// </summary> 
    async function applyFilter() {
        setApiUrl(ApiUrl._API_GET_ECREDIT_LOG + '?FromUsername=' + sender + '&ToUsername=' + receiver +
            '&StartDate=' + startDate + '&EndDate=' + endDate + '&v=' + moment.now());
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable columns={_eCreditLog} fetchUrl={apiUrl} exportRequired={true} />, [apiUrl])

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("ECREDIT_TRANSFER_LOG_MEMBER")}</h1>
            </div>
            <Panel>
                <PanelBody>
                    <div className="row m-b-10">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("SENDER")}</label>
                                <input
                                    type="text"
                                    name="sender"
                                    className="form-control form-control-lg"
                                    onInput={(e) => setSender(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("RECEIVER")}</label>
                                <input
                                    type="text"
                                    name="receiver"
                                    className="form-control form-control-lg"
                                    onInput={(e) => setRecevier(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("START_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="startDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={startDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("startDate", "");
                                        }
                                    }} />
                                {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("END_DATE")}</label>
                                <DateTime
                                    inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                    name="endDate"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="YYYY-MM-DD"
                                    closeOnSelect={true}
                                    value={endDate}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                            setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                        else {
                                            setValue("endDate", "");
                                        }
                                    }} />
                                {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <button type="button" className="btn btn-primary" onClick={() => { applyFilter() }}>{t('APPLY_FILTER')}</button>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            {PaginationTable}
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div>
    )
}

export default MemberEcreditTransferLog;