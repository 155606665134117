import React, { useState, useEffect, useMemo } from 'react';
import { Panel, PanelBody } from '../../../components/panel/panel';
import { WebUrl, ApiKey, ApiUrl, AccessRight, Status } from '../../../util/Constant';
import ReactTable from '../../../components/custom/ReactTable';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ApiEngine from '../../../util/ApiEngine.js';
import { useTranslation } from 'react-i18next';
import * as Util from '../../../util/Util';
import { useDispatch } from "react-redux";
import Select from "react-select";
import { showResponseMessage } from "../../../redux/AppAction";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useHistory } from "react-router-dom";
import NavigationButton from '../../../components/custom/NavigationButton';
import AttachShopModal from '../../../components/custom/AttachShopModal';

/// <summary>
/// Author: CK
/// </summary>
const ManageThirdPartyApi = props => {
    const { t, i18n } = useTranslation();
    var _history = useHistory();
    var _dispatch = useDispatch();
    const [thirdPartyApiList, setThirdPartyApiList] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedThirdPartyApi, setSelectedThirdPartyApi] = useState();
    const [isAgentModalVisible, setIsAgentModalVisible] = useState(false);

    /// <summary>
    /// Author: CK
    /// </summary>
    let _thirdPartyApiTableColumns = useMemo(() => [
        {
            Header: "THIRD_PARTY",
            accessor: "thirdPartyProgramName",
        },
        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "START_DATE",
            accessor: "startDate",
        },
        {
            Header: "END_DATE",
            accessor: "endDate",
        },
        {
            Header: "EVENT_KEY",
            accessor: "eventKey",
        },
        {
            Header: "KEY",
            accessor: "apiKey",
        },
        {
            Header: "OPSECRET",
            accessor: "apiSecret",
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                return row.original.status ? <span className="badge badge-green">{t("ENABLE")}</span> : <span className="badge badge-danger">{t("DISABLE")}</span>;
            }
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._GAME_VENDOR_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                onEditStatus({ "id": row.original.id, "status": row.original.status == Status._ENABLED ? Status._DISABLED : Status._ENABLED })
                            }}>
                                <span>{row.original.status ? t("DISABLE") : t("ENABLE")}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                _history.push({
                                    pathname: WebUrl._URL_THIRD_PARTY_API_DETAIL,
                                    state: { id: row.original.id }
                                })
                            }}>
                                <span>{t('EDIT_THIRD_PARTY_API_DETAIL')}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                setSelectedThirdPartyApi(row.original);
                                toggleModal();
                            }}>
                                <span>{t('ATTACH_SHOPS')}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ], [thirdPartyApiList]);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        getThirdPartyApiList();
    }, []);

    /// <summary>
    /// Author: CK
    /// </summary>
    async function getThirdPartyApiList() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_THIRD_PARTY_API);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setThirdPartyApiList(responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function onEditStatus(data) {
        var responseJson = await ApiEngine.post(ApiUrl._API_GET_THIRD_PARTY_API, Util.createMultiPartFormBody(data));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            getThirdPartyApiList();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function toggleModal() {
        if (isModalVisible) {
            setSelectedThirdPartyApi(null);
        }

        setIsModalVisible(!isModalVisible);
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function onSubmitAttachShop(attachedShops) {
        let params = {
            "programApiId": selectedThirdPartyApi.id,
            "shopIds": attachedShops.join(',')
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_ATTACH_THIRD_PARTY_API_SHOP, Util.createMultiPartFormBody(params));

        toggleModal();
        getThirdPartyApiList();

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    return (
        <div id="page-content">
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_THIRD_PARTY_API")}</h1>
                {
                    checkIfPermissionExist(AccessRight._GAME_VENDOR_CONTROL_PERMISSION, true) &&
                    <NavigationButton history={_history} url={WebUrl._URL_THIRD_PARTY_API_DETAIL} buttonText={t("CREATE_THIRD_PARTY_API")} />
                }
            </div>
            <Panel>
                <PanelBody>
                    <ReactTable data={thirdPartyApiList} columns={_thirdPartyApiTableColumns} filterable={true} />
                </PanelBody>
            </Panel>
            <AttachShopModal
                isModalVisible={isModalVisible}
                title={selectedThirdPartyApi ? selectedThirdPartyApi.name : ""}
                getShopListUrl={selectedThirdPartyApi ? ApiUrl._API_AVAILABLE_THIRD_PARTY_API_SHOP_LIST + "?id=" + selectedThirdPartyApi.id : null}
                getAttachedShopUrl={selectedThirdPartyApi ? ApiUrl._API_ATTACHED_THIRD_PARTY_API_SHOP_LIST + "?id=" + selectedThirdPartyApi.id : null}
                onFinish={(attachedShops) => {
                    if (attachedShops) {
                        onSubmitAttachShop(attachedShops);
                    }
                    else {
                        toggleModal();
                    }
                }} />
        </div>
    );
}

export default ManageThirdPartyApi;