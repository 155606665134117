import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import { Panel, PanelBody } from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import ReactTable from "../../../components/custom/ReactTable";
import { ApiKey, ApiUrl, Role, AlertTypes, ReportType } from "../../../util/Constant";
import { stringIsNullOrEmpty, getValidationMessage, arrayGroupByKey } from "../../../util/Util";
import ApiEngine from '../../../util/ApiEngine.js';
import { useDispatch, useSelector } from 'react-redux';
import { showResponseMessage, showMessage } from 'redux/AppAction.js';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonGroup, Button } from 'reactstrap';
import RegionFilterDualListBox from "../../../components/custom/RegionFilterDualListBox";

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const SetLimitReport = props => {
    const { t } = useTranslation();
    const { register, handleSubmit, setValue, errors } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [selectedShop, setSelectedShop] = useState([]);
    const [shopOption, setShopOption] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [postData, setPostData] = useState({});
    const [reportRole, setReportRole] = useState(ReportType._SHOP);
    const _userData = useSelector(state => state.authState.userData);
    const [regions, setRegions] = useState([]);
    var _dispatch = useDispatch();

    const _TYPE_OPTION = {
        ALL: -1,
        IN: 0,
        OUT: 1,
    };

    let _tableColumns = [
        {
            Header: "No",
            id: "row",
            filterable: false,
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            disableSortBy: true
        },
        {
            Header: "DATE",
            accessor: "date",
            disableSortBy: true,
            Footer: () => {
                return <span style={{ float: 'right' }}><b>{t('TOTAL')}: </b></span>
            },
        },
        {
            Header: reportRole == ReportType._SHOP ? "SHOP" : "AGENT",
            accessor: "shopUser",
            disableSortBy: true
        },
        {
            Header: "INITIAL_LIMIT",
            accessor: "preLimit",
            Cell: ({ row }) => {
                return row.original.preLimit.toFixed(2)
            },
            disableSortBy: true
        },
        {
            Header: "AMOUNT",
            accessor: "amount",
            Cell: ({ row }) => {
                return row.original.amount > 0 ? <span className="text-success">{row.original.amount.toFixed(2)}</span> : <span className="text-danger">{row.original.amount.toFixed(2)}</span>;
            },
            disableSortBy: true,
            Footer: ({ page }) => {
                const totalAmount = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.amount);
                }, 0);

                return <span><b>{totalAmount.toFixed(2)}</b></span>
            },
        },
        {
            Header: "FINAL_LIMIT",
            accessor: "postLimit",
            Cell: ({ row }) => {
                return row.original.postLimit.toFixed(2)
            },
            disableSortBy: true
        },
        {
            Header: "MODIFIED_BY",
            accessor: "modifiedUser",
            disableSortBy: true
        },
        {
            Header: "TYPE",
            accessor: "type",
            disableSortBy: true
        },
        {
            Header: "REMARK",
            accessor: "remark",
            disableSortBy: true
        },
        {
            Header: t("ACTION"),
            Cell: ({ row }) => (
                _userData.isAdminLevel &&
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => { onEditStatusConfirmation(row.original.id) }}><span>{t("DELETE")}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableFilters: true,
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ]

    /// <summary>
    /// Author: Wind
    /// </summary>
    function onEditStatusConfirmation(rowId) {
        _dispatch(showMessage({
            type: AlertTypes._WARNING,
            title: t('ARE_YOU_SURE'),
            showCancel: true,
            content: t('PLEASE_CONFIRM_INVALID_SET_LIMIT'),
            onConfirm: () => { onEditStatus(rowId) }
        }));
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function onEditStatus(rowId) {
        var responseJson = await ApiEngine.post(ApiUrl._API_DISABLE_SET_LIMIT_DATA + "?rowId=" + rowId);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleSubmit(submitForm)();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'shops' });
        register({ name: 'endDate' }, { required: true });

        setValue('shops', []);
        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    }, []);

    /// <summary>
    /// Author : A
    /// </summary>
    useEffect(() => {
        init();
    }, [reportRole]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        if (reportRole == ReportType._SHOP) {
            var apiUrl = ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP;
        } else {
            var apiUrl = ApiUrl._API_GET_AGENT_LIST;
        }

        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var shopListByRegion = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "regionName");
            var shopOptionsByRegion = [];
            var regionOptions = [];

            for (var key in shopListByRegion) {
                var shopList = [];

                regionOptions.push({ label: key, value: key });

                shopListByRegion[key].map(shop => {
                    shopList.push({ label: shop.username + (shop.shopName ? " (" + shop.shopName + ")" : ""), value: shop.id });
                });

                shopOptionsByRegion.push({
                    label: key,
                    value: key,
                    options: shopList
                })
            }

            setShopOption(shopOptionsByRegion);
            setRegions(regionOptions);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "TargetShop": data.shops,
            "Type": parseInt(data.type),
            "ReportRoleId": parseInt(reportRole)
        };

        if (!stringIsNullOrEmpty(data.name)) {
            params["CreatedByUser"] = data.name;
        }

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_SET_SCORE_LIMIT_REPORT);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable columns={_tableColumns} fetchUrl={apiUrl} postData={postData} exportRequired={true} renderFooter={true} />, [apiUrl, postData])

    return (
        <div>
            <h1 className="page-header d-flex justify-content-between">
                {t("SET_LIMIT_REPORT")}
                <ButtonGroup className="btn-group-usertype">
                    {
                        Object.keys(ReportType).filter(i => ReportType[i] != ReportType._ALL).map((key) =>
                            <Button
                                type="button"
                                color="primary"
                                active={ReportType[key] == reportRole}
                                onClick={() => setReportRole(ReportType[key])}
                            >
                                {t(key.replace("_", ""))}
                            </Button>
                        )
                    }
                </ButtonGroup>
            </h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("MODIFIED_BY")}</label>
                                    <input type="text" name="name" ref={register} className="form-control form-control-lg" placeholder={t("FULL_OR_PART_OF_THE_NAME")} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("TYPE")}</label>
                                    <select name="type" ref={register} className="form-control form-control-lg">
                                        <option value={_TYPE_OPTION.ALL}>{t("ALL")}</option>
                                        <option value={_TYPE_OPTION.IN}>{t("IN")}</option>
                                        <option value={_TYPE_OPTION.OUT}>{t("OUT")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                    {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                    {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{reportRole == ReportType._SHOP ? t("SHOP") : t("AGENT")}</label>
                                    <RegionFilterDualListBox
                                        name={"shops"}
                                        ref={register}
                                        options={shopOption}
                                        selectedOptions={selectedShop}
                                        regions={regions}
                                        onChange={(e) => {
                                            setValue('shops', e);
                                            setSelectedShop(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
};

export default SetLimitReport;