import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useForm } from 'react-hook-form';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ApiKey, ApiUrl, AccessRight, AlertTypes, Status } from '../../../util/Constant';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { createFormBody, isObjectEmpty, getValidationMessage } from "../../../util/Util";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useDispatch } from "react-redux";
import { showMessage } from "../../../redux/AppAction";
import ApiEngine from '../../../util/ApiEngine.js';
import Select from 'react-select';

/// <summary>
/// Author: Wind
/// </summary> 
const ManageFeedbackSettings = props => {
    const { t } = useTranslation();
    var _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, unregister, watch, control } = useForm();
    const [feedbackCategory, setFeedbackCategory] = useState([]);
    const [feedbackOption, setFeedbackOption] = useState([]);
    const [supportedLanguage, setSupportedLanguage] = useState([]);
    const [categoryOption, setCategoryOption] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [modalVisibility, setModalVisibility] = useState({
        modalType: 0,
        isOpen: false,
        data: {}
    });
    const _CATEGORY_MODAL = 1;
    const _OPTION_MODAL = 2;
    let _feedbackCategoryColumn = useMemo(() => [
        {
            Header: "NAME",
            accessor: "name",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{row.original.nameLocalizations.map((item) => { return item.value }).join(" / ")}</div>
            }
        },
        {
            Header: "STATUS",
            accessor: "status",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <span className={row.original.status == Status._ENABLED ? "badge badge-success" : "badge badge-danger"}
                    style={{ textTransform: "uppercase" }}>
                    {row.original.status == Status._ENABLED ? t('ACTIVE') : t('DISABLE')}
                </span>
            }
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._EVENT_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                openModal(_CATEGORY_MODAL, row.original)
                            }}>
                                <span>{t('EDIT')}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                let newStatus = row.original.status == Status._ENABLED ? Status._DISABLED : Status._ENABLED;
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t('ARE_YOU_SURE'),
                                    showCancel: true,
                                    content: t('ARE_YOU_SURE_YOU_WANT_TO') +
                                        (row.original.status == Status._ENABLED ? t('DISABLE') : t('ENABLE')) +
                                        ' ' + row.original.name + '?',
                                    onConfirm: () => {
                                        updateStatus(_CATEGORY_MODAL,
                                            row.original.id, newStatus,
                                            JSON.stringify(row.original.nameLocalizations));
                                    }
                                }));
                            }}><span className={row.original.status == Status._ENABLED ? "text-warning" : "text-success"}>
                                    {row.original.status == Status._ENABLED ? t("DISABLE") : t("ENABLE")}
                                </span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t('ARE_YOU_SURE'),
                                    showCancel: true,
                                    content: t('ARE_YOU_SURE_YOU_WANT_TO_DELETE') +
                                        ' ' + row.original.name + '?',
                                    onConfirm: () => {
                                        deleteRow(_CATEGORY_MODAL, row.original.id);
                                    }
                                }));
                            }}><span className={"text-danger"}>
                                    {t("DELETE")}
                                </span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ], [feedbackCategory]);

    let _feedbackOptionColumn = useMemo(() => [
        {
            Header: "NAME",
            accessor: "text",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <div>{row.original.textLocalizations.map((item) => { return item.value }).join(" / ")}</div>
            }
        },
        {
            Header: "FEEDBACK_CATEGORY",
            accessor: "categoryId",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <span className="badge badge-info">{row.original.categoryName}</span>
            }
        },
        {
            Header: "STATUS",
            accessor: "status",
            disableSortBy: true,
            Cell: ({ row }) => {
                return <span className={row.original.status == Status._ENABLED ? "badge badge-success" : "badge badge-danger"}
                    style={{ textTransform: "uppercase" }}>
                    {row.original.status == Status._ENABLED ? t('ACTIVE') : t('DISABLE')}
                </span>
            }
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._EVENT_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                openModal(_OPTION_MODAL, row.original);
                            }}>
                                <span>{t('EDIT')}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                let newStatus = row.original.status == Status._ENABLED ? Status._DISABLED : Status._ENABLED;
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t('ARE_YOU_SURE'),
                                    showCancel: true,
                                    content: t('ARE_YOU_SURE_YOU_WANT_TO') +
                                        (row.original.status == Status._ENABLED ? t('DISABLE') : t('ENABLE')) +
                                        ' ' + row.original.text + '?',
                                    onConfirm: () => {
                                        updateStatus(_OPTION_MODAL,
                                            row.original.id, newStatus,
                                            JSON.stringify(row.original.textLocalizations), row.original.categoryId);
                                    }
                                }));
                            }}><span className={row.original.status == Status._ENABLED ? "text-warning" : "text-success"}>
                                    {row.original.status == Status._ENABLED ? t("DISABLE") : t("ENABLE")}
                                </span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t('ARE_YOU_SURE'),
                                    showCancel: true,
                                    content: t('ARE_YOU_SURE_YOU_WANT_TO_DELETE') +
                                        ' ' + row.original.text + '?',
                                    onConfirm: () => {
                                        deleteRow(_OPTION_MODAL, row.original.id);
                                    }
                                }));
                            }}><span className={"text-danger"}>
                                    {t("DELETE")}
                                </span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ], [feedbackOption]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        getFeedbackCategory();
        getFeedbackOption();
        getSupportedLanguage();
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function getSupportedLanguage() {
        var langResponseJson = await ApiEngine.get(ApiUrl._API_GET_SUPPORTED_LANGUAGE);

        if (langResponseJson[ApiKey._API_SUCCESS_KEY]) {
            setSupportedLanguage(langResponseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function getFeedbackCategory() {
        var responseJson = await ApiEngine.get(ApiUrl._API_FEEDBACK_CATEGORY + '?IncludeDisabled=true');

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let tempCategory = [];
            responseJson[ApiKey._API_DATA_KEY].filter(x => x.status == Status._ENABLED).map((type, index) => {
                tempCategory.push({ label: type.name, value: type.id });
            })

            setFeedbackCategory(responseJson[ApiKey._API_DATA_KEY]);
            setCategoryOption(tempCategory);
        }
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function getFeedbackOption() {
        var responseJson = await ApiEngine.get(ApiUrl._API_FEEDBACK_OPTION);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setFeedbackOption(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function updateStatus(type, rowId, newStatus, name, categoryId = 0) {
        let params = {
            status: newStatus,
            [`${type == _OPTION_MODAL ? 'text' : 'name'}`]: name
        }

        if (type == _OPTION_MODAL) {
            params['categoryId'] = categoryId;
        }

        var responseJson = await ApiEngine.put((type == _CATEGORY_MODAL ?
            ApiUrl._API_FEEDBACK_CATEGORY : ApiUrl._API_FEEDBACK_OPTION) + '/' + rowId, createFormBody(params));

        showResponse(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY],
            type, false);
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function deleteRow(type, rowId) {
        var responseJson = await ApiEngine.delete((type == _CATEGORY_MODAL ?
            ApiUrl._API_FEEDBACK_CATEGORY : ApiUrl._API_FEEDBACK_OPTION) + '/' + rowId);

        showResponse(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY],
            type, false);
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    const onSubmit = async (data) => {
        var responseJson;
        let fullUrl = modalVisibility.modalType == _CATEGORY_MODAL ?
            ApiUrl._API_FEEDBACK_CATEGORY : ApiUrl._API_FEEDBACK_OPTION
        let params = {
            status: Status._ENABLED,
            [`${modalVisibility.modalType == _OPTION_MODAL ? 'text' : 'name'}`]: JSON.stringify(supportedLanguage.map(item => ({ languageId: item.id, value: data[`${item.id}`] })))
        }

        if (modalVisibility.modalType == _OPTION_MODAL) {
            params['categoryId'] = selectedCategory;
        }

        if (isObjectEmpty(modalVisibility.data)) {
            responseJson = await ApiEngine.post(fullUrl, createFormBody(params));
        }
        else {
            responseJson = await ApiEngine.put(fullUrl + '/' + modalVisibility.data.id, createFormBody(params));
        }

        showResponse(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY],
            modalVisibility.modalType, true);
    };

    /// <summary>
    /// Author: Wind
    /// </summary>
    function showResponse(status, message, type, dimissModal = false) {
        _dispatch(showMessage({
            type: status ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(message),
            onConfirm: () => {
                if (status) {
                    if (type == _CATEGORY_MODAL) {
                        getFeedbackCategory();
                    }
                    else {
                        getFeedbackOption();
                    }

                    if (dimissModal) {
                        closeModal();
                    }
                }
            }
        }));
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    function openModal(type, data = {}) {
        if (type == _OPTION_MODAL) {
            register({ name: 'categoryOptionId' }, { required: "PLEASE_SELECT_CATEGORY" });

            if (!isObjectEmpty(data)) {
                setSelectedCategory(data.categoryId);
                setValue('categoryOptionId', data.categoryId);
            }
            else {
                setSelectedCategory(categoryOption[0].value);
            }
        }

        setModalVisibility({
            modalType: type,
            isOpen: true,
            data: data
        });
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    function closeModal() {
        setModalVisibility({
            modalType: 0,
            isOpen: false,
            data: {}
        });

        unregister({ name: 'categoryOptionId' });
        setSelectedCategory();
    }

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("MANAGE_FEEDBACK_SETTINGS")}</h1>
            </div>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelHeader noButton>
                            {t("FEEDBACK_CATEGORY")}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {checkIfPermissionExist(AccessRight._EVENT_CONTROL_PERMISSION, true) && <button type="button" className="btn btn-primary"
                                onClick={() => openModal(_CATEGORY_MODAL)}>
                                <i className="fa fa-plus-circle m-r-10"></i>
                                {t("ADD_CATEGORY")}
                            </button>}
                        </PanelHeader>
                        <PanelBody>
                            <ReactTable columns={_feedbackCategoryColumn} data={feedbackCategory} />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelHeader noButton>
                            {t("FEEDBACK_OPTION")}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {checkIfPermissionExist(AccessRight._EVENT_CONTROL_PERMISSION, true) && <button type="button" className="btn btn-primary"
                                onClick={() => openModal(_OPTION_MODAL)}>
                                <i className="fa fa-plus-circle m-r-10"></i>
                                {t("ADD_OPTION")}
                            </button>}
                        </PanelHeader>
                        <PanelBody>
                            <ReactTable columns={_feedbackOptionColumn} data={feedbackOption} />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <Modal isOpen={modalVisibility.isOpen} centered toggle={() => { closeModal(); }} size="lg">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={() => { closeModal(); }}>
                        {
                            modalVisibility.modalType == _CATEGORY_MODAL ?
                                (isObjectEmpty(modalVisibility.data) ? t('ADD_NEW_FEEDBACK_CATEGORY') : t('EDIT_FEEDBACK_CATEGORY'))
                                :
                                (isObjectEmpty(modalVisibility.data) ? t('ADD_NEW_FEEDBACK_OPTION') : t('EDIT_FEEDBACK_OPTION'))
                        }
                    </ModalHeader>
                    <ModalBody>
                        {
                            modalVisibility.modalType == _OPTION_MODAL &&
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("CATEGORY")}</label>
                                    <Select
                                        name="categoryOptionId"
                                        options={categoryOption}
                                        value={categoryOption.filter(option => option.value == selectedCategory)}
                                        onChange={(e) => {
                                            setSelectedCategory(e.value);
                                            setValue('categoryOptionId', e.value);
                                        }} />
                                    {errors.categoryOptionId && <div className="invalid-feedback">{t(errors.categoryOptionId.message)}</div>}
                                </div>
                            </div>
                        }
                        {
                            supportedLanguage && supportedLanguage.map((item, index) => {
                                return (
                                    <div className="col-lg-12" key={index}>
                                        <div className="form-group">
                                            <label>{t("NAME") + " (" + item.name + ")"}</label>
                                            <input
                                                className="form-control form-control-lg"
                                                id={item.id}
                                                name={item.id}
                                                defaultValue={isObjectEmpty(modalVisibility.data) ? '' :
                                                    modalVisibility.data[`${modalVisibility.modalType == _OPTION_MODAL ? 'textLocalizations' : 'nameLocalizations'}`].find(x => x.languageId == item.id).value}
                                                placeholder={t("ENTER_NAME") + " (" + item.name + ")"}
                                                ref={register({ required: "PLEASE_ENTER_NAME" })} />
                                            {errors[`${item.id}`] && <div className="invalid-feedback">{t(getValidationMessage(errors[`${item.id}`]))}</div>}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-primary btn-action">{t("BTN_SUBMIT")}</button>
                        <button type="button" className="btn btn-danger" onClick={() => { closeModal(); }}>{t('BTN_CANCEL')}</button>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    )
}

export default ManageFeedbackSettings;