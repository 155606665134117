import React, { useMemo, useEffect, useState } from 'react';
import { Panel, PanelHeader, PanelBody } from 'components/panel/panel.jsx';
import { useHistory, useLocation } from 'react-router-dom';
import NavigationButton from '../../../components/custom/NavigationButton';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, Card, CardHeader, CardBody } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import ReactTable from 'components/custom/ReactTable';
import { FilterStatus, WebUrl, ApiKey, ApiUrl, Role, AccessRight, AlertTypes, Status } from 'util/Constant';
import { navigateTo, createMultiPartFormBody, stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../../util/Util";
import { useTranslation } from 'react-i18next';
import ResetPasswordModal from '../../../components/custom/ResetPasswordModal';
import ApiEngine from '../../../util/ApiEngine.js';
import SetShopLimitModal from '../../../components/custom/SetShopLimitModal';
import SetShopUpperLimitModal from '../../../components/custom/SetShopUpperLimitModal';
import { showMessage, showResponseMessage } from '../../../redux/AppAction';
import CopyAccountDetailModal from '../../../components/custom/CopyAccountDetailModal';
import { useDispatch, useSelector } from "react-redux";

/// <summary>
/// Author: Chris
/// Edited: Wind - Added resetPasswordAfterLogin
/// </summary> 
const MassAssignSettingsComponent = ({ selectedShops, massAssignHandler }) => {
    const { t } = useTranslation();
    const { register, handleSubmit } = useForm();
    var _dispatch = useDispatch();

    const [checkShopSettings, setCheckShopSettings] = useState({
        canCreateMember: false,
        canCreatePlayer: false,
        canReload: false,
        canDeposit: false,
        resetPassword: false,
        agentReload: false,
        onlineWithdrawalShop: false,
        canClaimReward: false,
        canCreatePlayerWithoutMember: false,
        canBlockMember: false,
        canUnblockMember: false
    });

    /// <summary>
    /// Author: Chris
    /// </summary>
    const resetValues = () => {
        setCheckShopSettings({
            canCreateMember: false,
            canCreatePlayer: false,
            canReload: false,
            canDeposit: false,
            resetPassword: false,
            agentReload: false,
            onlineWithdrawalShop: false,
            canClaimReward: false,
            canCreatePlayerWithoutMember: false,
            canBlockMember: false,
            canUnblockMember: false
        });
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    const onSubmit = (shopSettingsData) => {
        let selectedShopIds = [];

        Object.keys(selectedShops).map((key) => {
            if (selectedShops[key]) {
                selectedShopIds.push(key);
            }
        });

        if (selectedShopIds.length > 0) {
            _dispatch(showMessage({
                type: AlertTypes._WARNING,
                content: t('MASS_ASSIGN_SETTINGS_WARNING'),
                showCancel: true,
                onConfirm: async () => {
                    massAssignHandler(selectedShopIds, shopSettingsData);
                    resetValues();
                }
            }));
        }
        else {
            _dispatch(showMessage({ type: AlertTypes._WARNING, content: t('PLEASE_SELECT_A_SHOP') }));
        }
    }

    return (
        <Panel>
            <PanelHeader className='bg-white pointer-cursor' hideExpandButton hideRedoButton hideRemoveButton>
                {t("MASS_ASSIGN_SHOP_SETTINGS")}
            </PanelHeader>
            <PanelBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="checkbox checkbox-css checkbox-inline">
                        <input id="memberCheckbox" type="checkbox" name="createMemberEnable" checked={checkShopSettings.canCreateMember}
                            onChange={(e) => setCheckShopSettings({ ...checkShopSettings, canCreateMember: e.target.checked })}
                            ref={register} />
                        <label htmlFor="memberCheckbox">{t("CAN_CREATE_MEMBER")}</label>
                    </div>
                    <div className="checkbox checkbox-css checkbox-inline">
                        <input id="playerCheckbox" type="checkbox" name="createPlayerEnable" checked={checkShopSettings.canCreatePlayer}
                            onChange={(e) => setCheckShopSettings({ ...checkShopSettings, canCreatePlayer: e.target.checked })}
                            ref={register} />
                        <label htmlFor="playerCheckbox">{t("CAN_CREATE_PLAYER")}</label>
                    </div>
                    <div className="checkbox checkbox-css checkbox-inline">
                        <input id="reloadCheckbox" type="checkbox" name="reloadEnable" checked={checkShopSettings.canReload}
                            onChange={(e) => setCheckShopSettings({ ...checkShopSettings, canReload: e.target.checked })}
                            ref={register} />
                        <label htmlFor="reloadCheckbox">{t("CAN_RELOAD")}</label>
                    </div>
                    <div className="checkbox checkbox-css checkbox-inline">
                        <input id="depositCheckbox" type="checkbox" name="depositEnable" checked={checkShopSettings.canDeposit}
                            onChange={(e) => setCheckShopSettings({ ...checkShopSettings, canDeposit: e.target.checked })}
                            ref={register} />
                        <label htmlFor="depositCheckbox">{t("CAN_DEPOSIT")}</label>
                    </div>
                    <div className="checkbox checkbox-css checkbox-inline">
                        <input id="resetPasswordCheckbox" type="checkbox" name="passwordResetAfterLogin" checked={checkShopSettings.resetPassword}
                            onChange={(e) => setCheckShopSettings({ ...checkShopSettings, resetPassword: e.target.checked })}
                            ref={register} />
                        <label htmlFor="resetPasswordCheckbox">{t("RESET_PASSWORD_AFTER_LOGIN")}</label>
                    </div>
                    <div className="checkbox checkbox-css checkbox-inline">
                        <input id="setLimitApprovalCheckbox" type="checkbox" name="isRequiredSetLimitApproval" checked={checkShopSettings.isRequiredSetLimitApproval}
                            onChange={(e) => setCheckShopSettings({ ...checkShopSettings, isRequiredSetLimitApproval: e.target.checked })}
                            ref={register} />
                        <label htmlFor="setLimitApprovalCheckbox">{t("SET_LIMIT_APPROVAL")}</label>
                    </div>
                    <div className="checkbox checkbox-css checkbox-inline">
                        <input id="agentReloadCheckbox" type="checkbox" name="agentReload" checked={checkShopSettings.agentReload}
                            onChange={(e) => setCheckShopSettings({ ...checkShopSettings, agentReload: e.target.checked })}
                            ref={register} />
                        <label htmlFor="agentReloadCheckbox">{t("CAN_RELOAD_AGENT")}</label>
                    </div>
                    <div className="checkbox checkbox-css checkbox-inline">
                        <input id="onlineWithdrawalShopCheckbox" type="checkbox" name="onlineWithdrawalShop" checked={checkShopSettings.onlineWithdrawalShop}
                            onChange={(e) => setCheckShopSettings({ ...checkShopSettings, onlineWithdrawalShop: e.target.checked })}
                            ref={register} />
                        <label htmlFor="onlineWithdrawalShopCheckbox">{t("ONLINE_WITHDRAWAL")}</label>
                    </div>
                    <div className="checkbox checkbox-css checkbox-inline">
                        <input id="canClaimRewardCheckbox" type="checkbox" name="canClaimReward" checked={checkShopSettings.canClaimReward}
                            onChange={(e) => setCheckShopSettings({ ...checkShopSettings, canClaimReward: e.target.checked })}
                            ref={register} />
                        <label htmlFor="canClaimRewardCheckbox">{t("CAN_CLAIM_REWARD")}</label>
                    </div>
                    <div className="checkbox checkbox-css checkbox-inline">
                        <input id="createPlayerWithoutMemberCheckbox" type="checkbox" name="createPlayerWithoutMemberCheckbox" checked={checkShopSettings.canCreatePlayerWithoutMember}
                            onChange={(e) => setCheckShopSettings({ ...checkShopSettings, canCreatePlayerWithoutMember: e.target.checked })}
                            ref={register} />
                        <label htmlFor="createPlayerWithoutMemberCheckbox">{t("CAN_CREATE_PLAYER_WITHOUT_MEMBER")}</label>
                    </div>
                    <div className="checkbox checkbox-css checkbox-inline">
                        <input id="blockMemberCheckbox" type="checkbox" name="canBlockMember" checked={checkShopSettings.canBlockMember}
                            onChange={(e) => setCheckShopSettings({ ...checkShopSettings, canBlockMember: e.target.checked })}
                            ref={register} />
                        <label htmlFor="blockMemberCheckbox">{t("CAN_BLOCK_MEMBER")}</label>
                    </div>
                    <div className="checkbox checkbox-css checkbox-inline">
                        <input id="unblockMemberCheckbox" type="checkbox" name="canUnblockMember" checked={checkShopSettings.canUnblockMember}
                            onChange={(e) => setCheckShopSettings({ ...checkShopSettings, canUnblockMember: e.target.checked })}
                            ref={register} />
                        <label htmlFor="unblockMemberCheckbox">{t("CAN_UNBLOCK_MEMBER")}</label>
                    </div>
                    <div className="row m-t-20">
                        <div className="col-lg-3">
                            <button type="submit" className="btn btn-primary">{t("MASS_ASSIGN_SETTINGS")}</button>
                        </div>
                    </div>
                </form>
            </PanelBody>
        </Panel>
    );
}

/// <summary>
/// Author: Chris
/// Edited: Wind - Added resetPasswordAfterLogin
/// </summary>
const ManageShop = props => {
    const _dispatch = useDispatch();
    var _history = useHistory();
    var _location = useLocation();
    const { t } = useTranslation();
    const { register, handleSubmit, watch, setValue } = useForm();
    const [shops, setShops] = useState([]);
    const [isResetPasswordModalVisible, setIsResetPasswordModalVisible] = useState(false);
    const [regionOptions, setRegionOptions] = useState([]);
    const [userId, setUserId] = useState('');
    const [isSetShopLimitModalVisible, setIsSetShopLimitModalVisible] = useState(false);
    const [isSetUpperLimitModalVisible, setIsSetUpperLimitModalVisible] = useState(false);
    const [userShopLimitDetails, setUserShopLimitDetails] = useState({
        userFullName: '',
        currentCreditLimit: 0
    });
    const [checkAll, setCheckAll] = useState(false);
    const [selectedShops, setSelectedShops] = useState({});
    const [shopApiUrl, setShopApiUrl] = useState('');
    var [count, setCount] = useState(0);
    const [selectedShopId, setSelectedShopId] = useState('');
    const [modalVisibility, setModalVisibility] = useState(false);
    const _userData = useSelector(state => state.authState.userData);

    const _tableColumns = useMemo(() => [
        {
            id: 'checkbox',
            Header: ({ data }) => {
                return (
                    <div className="checkbox checkbox-css checkbox-inline m-b-20">
                        <input id="checkboxSelectAll" type="checkbox" onChange={() => {
                            setShops(data);
                            setCheckAll(!checkAll);
                        }} checked={checkAll} />
                        <label htmlFor="checkboxSelectAll"></label>
                    </div>
                )
            },
            Cell: ({ row }) => {
                return (
                    <div className="checkbox checkbox-css checkbox-inline">
                        <input
                            id={`checkbox${row.id}`}
                            type="checkbox"
                            onChange={() => setSelectedShops({ ...selectedShops, [row.original.id]: !selectedShops[row.original.id] })}
                            checked={selectedShops[row.original.id]} />
                        <label htmlFor={`checkbox${row.id}`}></label>
                    </div>
                )
            },
            width: 30,
            disableSortBy: true,
        },
        {
            Header: t('NAME'),
            accessor: 'userFullName',
            disableSortBy: true
        },
        {
            Header: t('USERNAME'),
            accessor: 'username',
            disableSortBy: true
        },
        {
            Header: t('REGION'),
            accessor: 'regionName',
            disableSortBy: true
        },
        {
            Header: t('CURRENT_LIMIT'),
            accessor: 'creditLimit',
            Cell: ({ row }) => {
                return (
                    <span className="d-flex">
                        <div className="clickable-link" onClick={() => {
                            setUserShopLimitDetails({
                                id: row.original.id,
                                userFullName: row.original.userFullName,
                                currentCreditLimit: row.original.shopProfile.creditLimit,
                                isRequiredSetLimitApproval: row.original.shopProfile.isRequiredSetLimitApproval
                            });
                            setIsSetShopLimitModalVisible(true)
                        }}>
                            {numberWithCurrencyFormat(row.original.shopProfile?.creditLimit)}
                        </div>
                        {
                            !stringIsNullOrEmpty(row.original.shopProfile?.shopUpperLimit) &&
                            <>
                                <span className="mx-2 clickable-link">/</span>
                                <div className="clickable-link" onClick={() => {
                                    setUserShopLimitDetails({
                                        id: row.original.id,
                                        userFullName: row.original.userFullName,
                                        currentCreditLimit: row.original.shopProfile.creditLimit
                                    });
                                    setIsSetUpperLimitModalVisible(true)
                                }}>
                                    {numberWithCurrencyFormat(row.original.shopProfile?.shopUpperLimit)}
                                </div>
                            </>
                        }
                    </span >
                )
            },
            disableSortBy: true
        },
        {
            Header: t('STATUS'),
            accessor: 'status',
            Cell: ({ row }) => {
                return (
                    <span>
                        {
                            row.original.status == FilterStatus._ACTIVE ?
                                <span className="badge badge-secondary badge-green">{t("ACTIVE")}</span> :
                                <span className="badge badge-secondary badge-danger">{t("SUSPENDED") + " (" + row.original.invalidLoginCount + ")"}</span>
                        }
                    </span>
                )
            },
            disableSortBy: true
        },
        {
            Header: t('SETTINGS'),
            Cell: ({ row }) => {
                return <>
                    {row.original.shopProfile.createPlayerEnable && <>- {t('ADD_PLAYER')}<br /></>}
                    {row.original.shopProfile.createMemberEnable && <>- {t('CREATE')} {t('MEMBER')}<br /></>}
                    {row.original.shopProfile.reloadEnable && <>- {t('RELOAD')}<br /></>}
                    {row.original.shopProfile.depositEnable && <>- {t('CAN_DEPOSIT')}<br /></>}
                    {row.original.shopProfile.isRequiredSetLimitApproval && <>- {t('SET_LIMIT_APPROVAL')}<br /></>}
                    {row.original.shopProfile.passwordResetAfterLogin && <>- {t('RESET_PASSWORD_AFTER_LOGIN')}<br /></>}
                    {row.original.shopProfile.agentReload && <>- {t('CAN_RELOAD_AGENT')}<br /></>}
                    {row.original.shopProfile.onlineWithdrawalShop && <>- {t('ONLINE_WITHDRAWAL')}<br /></>}
                    {row.original.shopProfile.canClaimReward && <>- {t('CAN_CLAIM_REWARD')}<br /></>}
                    {!props.isCustomerService && row.original.shopProfile.createPlayerWithoutMemberEnable && <>- {t('CAN_CREATE_PLAYER_WITHOUT_MEMBER')}<br /></>}
                    {!props.isCustomerService && row.original.shopProfile.canBlockMember && <>- {t('CAN_BLOCK_MEMBER')}<br /></>}
                    {!props.isCustomerService && row.original.shopProfile.canUnblockMember && <>- {t('CAN_UNBLOCK_MEMBER')}<br /></>}
                </>
            },
            disableFilters: true,
            disableSortBy: true,
        },
        {

            id: "action",
            Header: "",
            Cell: ({ row }) => {
                var suspended = row.original.status === FilterStatus._SUSPENDED;

                return (
                    checkIfPermissionExist(props.isCustomerService ? AccessRight._CUSTOMER_SERVICE_CONTROL_PERMISSION : AccessRight._SHOP_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                            <DropdownMenu>
                                {_userData.isAdminLevel &&
                                    <DropdownItem onClick={() => {
                                        navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_SHOP_API, row.original)
                                    }}><span>{t("VENDOR_API_DETAILS")}</span></DropdownItem>
                                }
                                <DropdownItem onClick={() => {
                                    setUserShopLimitDetails({
                                        id: row.original.id,
                                        userFullName: row.original.userFullName,
                                        currentCreditLimit: row.original.shopProfile.creditLimit,
                                        isRequiredSetLimitApproval: row.original.shopProfile.isRequiredSetLimitApproval
                                    });
                                    setIsSetShopLimitModalVisible(true)
                                }}><span>{t("SET_SHOP_LIMIT")}</span></DropdownItem>
                                <DropdownItem onClick={() => {
                                    setUserShopLimitDetails({
                                        id: row.original.id,
                                        userFullName: row.original.userFullName,
                                        currentCreditLimit: row.original.shopProfile.creditLimit,
                                        isRequiredSetLimitApproval: row.original.shopProfile.isRequiredSetLimitApproval
                                    });
                                    setIsSetUpperLimitModalVisible(true)
                                }}><span>{t("SET_SHOP_UPPER_LIMIT")}</span></DropdownItem>
                                <DropdownItem onClick={() => {
                                    setUserId(row.original.id);
                                    setIsResetPasswordModalVisible(true);
                                }}><span>{t("RESET_PASSWORD")}</span></DropdownItem>
                                <DropdownItem onClick={() => {
                                    _dispatch(showMessage({
                                        type: AlertTypes._WARNING,
                                        content: `${t(!suspended ? "BLOCK_SHOP_ALERT" : "UNBLOCK_SHOP_ALERT")} ${row.original.userFullName}?`,
                                        showCancel: true,
                                        confirmBtnText: t(!suspended ? "BLOCK_SHOP" : "UNBLOCK_SHOP"),
                                        onConfirm: () => updateShopHandler(
                                            row.original.id,
                                            (suspended) ? FilterStatus._ACTIVE : FilterStatus._SUSPENDED
                                        )
                                    }));
                                }}>
                                    <span>
                                        {t(!suspended ? "BLOCK_SHOP" : "UNBLOCK_SHOP")}
                                    </span>
                                </DropdownItem>
                                {checkIfPermissionExist(AccessRight._CUSTOMER_SERVICE_CONTROL_PERMISSION, true) && checkIfPermissionExist(AccessRight._SHOP_CONTROL_PERMISSION, true) &&
                                    (_userData.userRoleId == Role._SUPER_ADMIN || _userData.userRoleId > Role._MEMBER) &&
                                    <DropdownItem onClick={() => {
                                        _dispatch(showMessage({
                                            type: AlertTypes._WARNING,
                                            title: t("ARE_YOU_SURE"),
                                            content: t("ARE_YOU_SURE_YOU_WANT_TO_PROMO_THIS_SHOP"),
                                            showCancel: true,
                                            onConfirm: () => promoteShop(row.original.id)
                                        }));
                                    }}><span>{t("PROMOTE_USER")}</span></DropdownItem>
                                }
                                {(_userData.isAdminLevel && checkIfPermissionExist(AccessRight._SHOP_CONTROL_PERMISSION, true)) &&
                                    <>
                                        <DropdownItem onClick={() => { navigateTo(_history, (props.isCustomerService ? WebUrl._URL_ADMIN_CREATE_OR_UPDATE_CUSTOMER_SERVICE : WebUrl._URL_ADMIN_CREATE_OR_UPDATE_SHOP), row.original) }}><span>{t("EDIT")}</span></DropdownItem>
                                        <DropdownItem onClick={() => {
                                            _dispatch(showMessage({
                                                type: AlertTypes._WARNING,
                                                title: t("ARE_YOU_SURE"),
                                                content: t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SHOP"),
                                                showCancel: true,
                                                onConfirm: () => updateShopHandler(row.original.id, Status._DELETED)
                                            }));
                                        }}><span className="text-red">{t("DELETE_SHOP")}</span></DropdownItem>
                                        <DropdownItem onClick={() => {
                                            setSelectedShopId(row.original.id);
                                            setModalVisibility(true);
                                        }}><span>{t("COPY")}</span>
                                        </DropdownItem>
                                    </>
                                }
                                <DropdownItem onClick={() => {
                                    const win = window.open(WebUrl._URL_VIEW_USER_ACTION_LOG + "?username=" + row.original.username
                                        , "_blank");
                                    win.focus();
                                }}>
                                    <span>{t('VIEW_USER_ACTION_LOG')}</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div>
                )
            },
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible", width: '1%' }
        }
    ], [checkAll, selectedShops, count]);

    /// <summary>
    /// Author: Chris
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>      
    async function promoteShop(userId) {
        let params = {
            id: userId,
            roleId: Role._SHOP,
            isCustomerService: true
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_USER_ACCOUNT, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            init();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function saveSession() {
        var data = watch();
        sessionStorage.setItem("shopFullname", data.name);
        sessionStorage.setItem("shopUsername", data.username);
        sessionStorage.setItem("shopRegionId", data.regionId);
        sessionStorage.setItem("shopStatus", data.status);
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_REGION)

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var regionList = [];
            regionList.push({ label: t("ALL"), value: "" });
            responseJson[ApiKey._API_DATA_KEY].forEach(function (region) {
                regionList.push({ label: region.name, value: region.id });
            });

            setRegionOptions(regionList);
        }

        setValue("regionId", sessionStorage.getItem("shopRegionId"));
        setValue("status", sessionStorage.getItem("shopStatus"));
        handleSubmit(onSubmit)();
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    const onSubmit = async (data) => {
        var rawUrl = ApiUrl._API_GET_USERS + "?RoleId=" + Role._SHOP + "&v=" + (++count) + "&IsCustomerService=" + props.isCustomerService;
        setCount(count);

        if (data) {
            rawUrl += "&FullName=" + data.name + "&RegionId=" + data.regionId + "&Username=" + data.username + "&Status=" + data.status;
        }

        saveSession();
        setShopApiUrl(rawUrl);
        setCheckAll(false);
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    const updateShopHandler = async (userId, status) => {
        let params = {
            id: userId,
            status: status
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_USER_ACCOUNT, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleSubmit(onSubmit)();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    useEffect(() => {
        let checkedList = {};
        shops.map((shop) => {
            checkedList[shop.id] = checkAll;
        });

        setSelectedShops(checkedList);
    }, [checkAll]);

    /// <summary>
    /// Author: Chris
    /// </summary>
    const massAssignHandler = async (selectedShopIds, shopSettingsData) => {
        let params = {
            ids: selectedShopIds,
            ...shopSettingsData
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_BULK_ASSIGN_SHOP_SETTINGS, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleSubmit(onSubmit)();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    return <>
        <CopyAccountDetailModal
            refId={selectedShopId}
            refRole={Role._SHOP}
            isCustomerService={props.isCustomerService}
            onFinish={(refreshRequired) => {
                if (refreshRequired) {
                    init();
                }
                setModalVisibility(false);

            }}
            visible={modalVisibility} />
        <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
            <h1 className="page-header">{props.isCustomerService ? t("MANAGE_CUSTOMER_SERVICE") : t("MANAGE_SHOP")}</h1>
            {
                (checkIfPermissionExist(props.isCustomerService ? AccessRight._CUSTOMER_SERVICE_CONTROL_PERMISSION : AccessRight._SHOP_CONTROL_PERMISSION, true) && _userData.isAdminLevel) &&
                <NavigationButton history={_history} url={props.isCustomerService ? WebUrl._URL_ADMIN_CREATE_OR_UPDATE_CUSTOMER_SERVICE : WebUrl._URL_ADMIN_CREATE_OR_UPDATE_SHOP}
                    buttonText={props.isCustomerService ? t("ADD_NEW_CUSTOMER_SERVICE") : t("ADD_NEW_SHOP")} />
            }
        </div>
        <Panel>
            <PanelBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("NAME")}</label>
                                <input className="form-control form-control-lg" id="name" name="name" type="text" defaultValue={sessionStorage.getItem("shopFullname")}
                                    placeholder={t("FULL_OR_PART_OF_THE_NAME")} ref={register} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t('USERNAME')}</label>
                                <input className="form-control form-control-lg" id="username" name="username" type="text" defaultValue={_location.state ? _location.state.shopName : sessionStorage.getItem("shopUsername")}
                                    placeholder={t("FULL_OR_PART_OF_THE_USERNAME")} ref={register} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("REGION")}</label>
                                <select name="regionId" ref={register}
                                    className="form-control form-control-lg">
                                    {regionOptions.map((region, key) => <option key={key} value={region.value}>{region.label}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t('STATUS')}</label>
                                <select className="form-control form-control-lg" id="status" name="status" ref={register}>
                                    <option value="">{t("ALL")}</option>
                                    <option value={FilterStatus._ACTIVE}>{t("ACTIVE")}</option>
                                    <option value={FilterStatus._SUSPENDED}>{t("SUSPENDED")}</option>
                                    <option value={FilterStatus._DELETED}>{t("DELETED")}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="form-group form-button">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBody>
        </Panel>
        {!props.isCustomerService && <MassAssignSettingsComponent selectedShops={selectedShops} massAssignHandler={massAssignHandler} />}
        <Panel>
            <PanelBody>
                <ReactTable columns={_tableColumns} fetchUrl={shopApiUrl} />
            </PanelBody>
        </Panel>
        <ResetPasswordModal
            onFinish={() => {
                setIsResetPasswordModalVisible(false);
            }}
            resetShopPassword={true}
            targetUserId={userId}
            visible={isResetPasswordModalVisible} />
        <SetShopLimitModal
            onFinish={(isSuccess) => {
                if (isSuccess) {
                    handleSubmit(onSubmit)();
                }

                setIsSetShopLimitModalVisible(false);
            }}
            shopLimitDetails={userShopLimitDetails}
            visible={isSetShopLimitModalVisible} />
        <SetShopUpperLimitModal
            onFinish={(isSuccess) => {
                if (isSuccess) {
                    handleSubmit(onSubmit)();
                }

                setIsSetUpperLimitModalVisible(false);
            }}
            shopLimitDetails={userShopLimitDetails}
            visible={isSetUpperLimitModalVisible} />
    </>;
}

export default ManageShop;